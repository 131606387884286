@import "../_searchkit_vars.scss";

@import "~searchkit/theming/components/_arrows.scss";
@import "~searchkit/theming/components/_actions.scss";
@import "~searchkit/theming/components/_loader.scss";

@import "~searchkit/theming/components/_search-box.scss";

@import "~searchkit/theming/components/_hits-stats.scss";
@import "~searchkit/theming/components/_no-hits.scss";

@import "~searchkit/theming/components/_filter-group.scss";

@import "~searchkit/theming/components/_filter-item.scss";
@import "~searchkit/theming/components/_item-list.scss";
@import "~searchkit/theming/components/_select.scss";
@import "~searchkit/theming/components/_tabs.scss";
@import "~searchkit/theming/components/_tag-cloud.scss";
@import "~searchkit/theming/components/_toggle.scss";

@import "~searchkit/theming/components/_range-input.scss";
@import "~searchkit/theming/components/_range-histogram.scss";
@import "~searchkit/theming/components/_range-slider.scss";

@import "~searchkit/theming/components/_hierarchical-menu-filter.scss";
@import "~searchkit/theming/components/_hierarchical-refinement-filter.scss";
@import "~searchkit/theming/components/_input-filter.scss";
@import "~searchkit/theming/components/_numeric-refinement-list-filter.scss";
@import "~searchkit/theming/components/_refinement-list-filter.scss";
@import "~searchkit/theming/components/_selected-filters.scss";
@import "~searchkit/theming/components/_range-filter.scss";
@import "~searchkit/theming/components/_reset-filters.scss";
@import "~searchkit/theming/components/_menu-filter.scss";
@import "~searchkit/theming/components/_tag-filter.scss";

@import "~searchkit/theming/components/_pagination.scss";

@import "~searchkit/theming/components/_table.scss";

@import "~searchkit/theming/components/_panel.scss";

.cr-content {
  $pagination-font-size: 13px;
  .sk-pagination-navigation {
    >.sk-toggle {
      display: block;
      text-align: center;
    }

    .sk-toggle-option.sk-toggle__item.is-active{
      pointer-events: none !important;
    }

    .sk-toggle-option {
      flex: none;
      display: inline-block;
      background-color: transparent;
      color: $gray-75;
      border: none;
      padding: 5px 10px;
      font-size: $pagination-font-size;
      line-height: $pagination-font-size + 7px;
      @media (max-width:750px) {
        padding: 5px 7px;
      }
      > .sk-toggle-option__text {
        position: inherit;
        display: inline;
        padding: 0;
        margin: 0;
        line-height: inherit;
        color: inherit;
        border: none;
        background: transparent;
      }
      &.sk-toggle-input-text {
        padding: 5px 0px;
      }
      > input.goto-text {
        height: 20px!important;
        width: 50px;
        padding: 1px 2px;
        font-size: $pagination-font-size!important;
        line-height: $pagination-font-size + 7px!important;
        background: transparent;
        color: $gray-75;
        border: 1px solid $gray-700;
        text-align: center;
        -moz-appearance: textfield;
        &::placeholder {
          font-size: $pagination-font-size - 3!important;
          line-height: $pagination-font-size + 7px!important;
          padding: 0px;
          text-align: center;
          vertical-align: middle;
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
      // &[data-key="previous"]::before,
      // &[data-key="next"]::after {
      //   content: ' ';
      //   display: inline-block;
      //   font-size: 0;
      //   line-height: 0;
      //   height: 0;
      //   border: ($pagination-font-size - 1)/2 solid $gray-75;
      //   margin-top: 4px;
      //   margin-bottom: 4px;
      //   vertical-align: top;
      // }
      // &[data-key="previous"]::before {
      //   border-left: none;
      //   margin-right: $pagination-font-size/3;
      //   border-top: ($pagination-font-size - 1)/2 solid transparent;
      //   border-bottom: ($pagination-font-size - 1)/2 solid transparent;
      // }
      // &[data-key="next"]::after {
      //   border-right: none;
      //   margin-left: $pagination-font-size/3;
      //   border-top: ($pagination-font-size - 1)/2 solid transparent;
      //   border-bottom: ($pagination-font-size - 1)/2 solid transparent;
      // }
      &[data-key="previous"]{
        background-color: #242529;
        padding: 3px 8px;
        color: #6B7883;
        margin-right: 18px;
        @media (max-width:750px) {
          margin-right: 7px;
        }
      }
      &[data-key="next"]{
        background-color: #242529;
        padding: 3px 8px;
        color: #6B7883;
        margin-left: 18px;
        @media (max-width:750px) {
          margin-left: 0;
        }
      }
      &.is-disabled {
        padding: 3px 8px;
        pointer-events:none;
        // background-color: #242529;
        color: #6B7883;
        > .sk-toggle-option__text {
          cursor: default;
        }
        &:hover {
          color: $gray-75;
        }
      }
      &.is-active, &:hover {
        color: $white;
      }
      &.is-active{
        background-color: #4F7BE9;
        border-radius: 2px;

      }
    }
  }
}
