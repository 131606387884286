body .zd-allpack-lists {
  // .zd-releases-view {
  //   padding-left: 13px !important;
  //   padding-right: 13px !important;
  // }
  .zd-width-48 {
    width: 48px;
  }
}
.pack-wrap {
  z-index: 333;
  .p {
    margin: 0 0 1rem;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
}
.link-style {
  color: #4978ef !important;
  font-size: 12px;
  // text-decoration: underline;
  background-color: transparent;
  cursor: pointer;
}

.link-style:hover {
  color: #2d5dc8 !important;
}

.parent-pack-wrap {
  @media only screen and (max-width: 750px) {
    .zipdj-packs_new {
      margin-right: 0 !important;
    }
  }
  padding: 15px 0px;
  .card-body {
    padding: 0px;
  }

  .title-style {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #dddddd;
  }

  .content-head {
    right: 0;
    left: 56px;
    top: 60px;
    z-index: 999;
    padding-bottom: 48px;
  }
  .head-line {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    position: relative;
    margin-bottom: 17px;
  }
  .head-line .title-links {
    font-style: normal;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 1.6rem;
    line-height: 16px;
    display: inline;
    -webkit-box-align: baseline;
    align-items: center;
    margin-bottom: 0;
    @media only screen and (max-width: 750px) {
      .zipdj-packs_new {
        margin-right: 0 !important;
      }
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 54%;
    }
  }

  .title-style {
    text-transform: none;
  }
  .line {
    content: '';
    background: #373737;
    width: 100%;
    height: 1px;
    position: absolute;
    top: 27px;
    box-sizing: border-box;
    margin: 0px;
    border: 0px;
  }
  .card-deck-style {
    display: flex;
    margin-top: 22px;
    width: 100%;
    user-select: none;

    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    box-sizing: border-box;
  }
  .card-deck-style-one {
    transform: translateZ(0);
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    position: relative;
    display: block;
  }
  .card-deck-style-two {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
    height: 100%;
    left: 0;
    top: 0;
    display: block;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
  .card-image-style {
    //border-radius: 10px;
  }
  .card-image-text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;

    -webkit-box-align: end;
    align-items: flex-end;
    text-align: center;
    color: $white;
    margin-top: 9px;
    // padding-left: 2px;
    width: 150px;
  }

  .parent {
    text-align: center;
  }

  .last-image {
    height: 140px;
    width: 140px;
  }

  .animate {
    z-index: 9999;
    transition: all 0.2s ease-in;
  }
  .animate:hover {
    transform: translateY(-10px);
  }
  .card-gap {
    padding-right: 30px !important;
    float: left;
    border: none;
  }
}

.pack-wrap {
  // margin-bottom: 40px;
  .pack-section-list {
    padding-top: 10px;
  }
  .zd-packs-featured-name {
    padding: 0px 20px;
  }
  .slick-list {
    // padding-top: 10px;
    // padding-left: 20px;
    .slick-track {
      min-width: 100%;
      // margin-left: -14px;
    }
    .slick-slide {
      min-width: 164px;
      max-width: 164px;
      .card-body {
        height: 56px;
        padding-top: 1rem;
        padding-bottom: 1rem;
        background-color: #242529;
        .card-title {
          margin: 0;
        }
        // &:hover{
        //   background-color:#28292E ;
        // }
      }
    }
  }
  .zd-slick-navs {
    overflow: hidden;
    line-height: 20px;
    i {
      font-size: 10px;
    }
    @media (max-width: 750px) {
      padding: 0 !important;
    }
    .link-style {
      margin-right: 14px;
    }
    button {
      height: 24px;
      width: 24px;
      text-align: center;
      display: inline-block;
      // background-color: #242529;
      background-color: #242529;
      color: #6b7883;
      border-radius: 2px;
      &:hover {
        background-color: #242529;
        color: #4f7be9;
        display: inline-block;
      }
      &.zd-slick-prev {
        margin-right: 10px;
      }
    }
    button:focus,
    button:hover,
    button:active,
    button {
      border: none;
    }
    // .zd-slick-next {
    //   margin-right: 10px;
    // }
  }
}

.zd-triangle {
  position: absolute;
  top: -18px;
  right: -2px;
  width: 80px;
}

.cpackard-deck-style {
  padding-right: 56px;
  @media (max-width: 750px) {
    padding: 0;
  }
}

.pack-wrap {
  .head-line {
    // padding-right: 20px;
    // margin-right: 30px;
    @media (max-width: 750px) {
      padding-right: 0px;
      margin-right: 0;
    }
  }
}

/* Trending packs CSS for ZDV4*/
.zdv4-trending-packs-card {
  margin-right: 13px;
  max-width: 146px;
  min-width: 146px;
  height: 146px;
  background-color: #141414 !important;
  border-radius: 2px !important;

  @media (min-width: 1440px) {
    margin-right: 14px;
    max-width: 154px;
    min-width: 154px;
    height: 154px;
  }

  @media (min-width: 1920px) {
    margin-right: 14px;
    max-width: 167px;
    min-width: 167px;
    height: 167px;
  }

  @media (min-width: 2560px) {
    margin-right: 15px;
    max-width: 174px;
    min-width: 174px;
    height: 174px;
  }
}
#mutate {
  margin-right: 50px;
  .head-line {
    margin-top: 48px;
    border-bottom: 1px solid #242529;
  }
  .cpackard-deck-style.slider-icon {
    padding-right: 0px !important;
  }
}
.zd-card-pack {
  height: 212px !important;
  margin-bottom: 0px !important;
}
.zd-card-pack-c {
  margin-bottom: 3px !important;
  width: 159px !important;
}
.zd-packs-expand {
  background-color: #242529;
  width: Hug (101px);
  height: Fixed (36px);
  padding: 4px 8px 4px 8px !important;
  gap: 8px;
  color: #6d7882;
  margin: 0 0.5rem 0px 0px;
}
.zd-pack-dropdown {
  margin-left: 3px;
  color: #757575;
}
.zd-packs-expand-parent {
  cursor: pointer;
  :hover {
    color: #ddd;
  }
}

.trending_packs {
  .slick-list {
    margin-right: 0px !important;
  }
}
.zipdj-packs_new .trendingpacks {
  .dualtrack__navigation {
    @media only screen and (max-width: 750px) {
      margin-right: 0;
    }
  }

  button {
    padding-top: 3px;
  }
  i {
    font-size: 10px;
    color: #6b7883;
    :hover {
      color: #4f7be9;
    }
  }
}
.trendingpacks {
  button {
    padding: 3px 0 0 0 !important;
  }
}
.zipdjpacks__essentials i {
  font-size: 10px;
  color: #6b7883;

  :hover {
    color: #4f7be9;
  }
}
.zipdjpacks__essentials button {
  padding: 3px 0 0 0 !important;
}
.zd-column {
  .cpackard-deck-style {
    padding-right: 12px !important;
  }
}

.m-48 {
  margin-top: 48px !important;
}
.zd-category {
  .zd-card {
    margin-bottom: 0px !important;
  }
  .zd-card-c {
    margin-bottom: 0px !important;
  }
}
