@font-face {
  font-family: 'zdv3-icon';
  src: url('zdv3-icon_bkp.eot?t=1633519283086'); /* IE9*/
  src: url('zdv3-icon_bkp.eot?t=1633519283086#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('zdv3-icon_bkp.woff2?t=1633519283086') format('woff2'),
    url('zdv3-icon_bkp.woff?t=1633519283086') format('woff'),
    url('zdv3-icon_bkp.ttf?t=1633519283086') format('truetype'),
    /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
      url('zdv3-icon_bkp.svg?t=1633519283086#zdv3-icon') format('svg'); /* iOS 4.1- */
}

[class^='zdv3-icon-'],
[class*=' zdv3-icon-'] {
  font-family: 'zdv3-icon' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.zdv3-icon-AddPlaylist:before {
  content: '\ea01';
}
.zdv3-icon-Crates:before {
  content: '\ea02';
}
.zdv3-icon-Instagram:before {
  content: '\ea03';
}
.zdv3-icon-Linkedin:before {
  content: '\ea04';
}
.zdv3-icon-SoundClord:before {
  content: '\ea05';
}
.zdv3-icon-Tweeter:before {
  content: '\ea06';
}
.zdv3-icon-Web:before {
  content: '\ea07';
}
.zdv3-icon-Youtube:before {
  content: '\ea08';
}
.zdv3-icon-backward:before {
  content: '\ea09';
}
.zdv3-icon-check-box:before {
  content: '\ea0a';
}
.zdv3-icon-circle:before {
  content: '\ea0b';
}
.zdv3-icon-close-player:before {
  content: '\ea0c';
}
.zdv3-icon-close:before {
  content: '\ea0d';
}
.zdv3-icon-cloud-download:before {
  content: '\ea0e';
}
.zdv3-icon-crate-drag-drop:before {
  content: '\ea0f';
}
.zdv3-icon-djinfo-add:before {
  content: '\ea10';
}
.zdv3-icon-djinfo-close:before {
  content: '\ea11';
}
.zdv3-icon-djinfo-edit:before {
  content: '\ea12';
}
.zdv3-icon-download-manager:before {
  content: '\ea13';
}
.zdv3-icon-download:before {
  content: '\ea14';
}
.zdv3-icon-drop-down:before {
  content: '\ea15';
}
.zdv3-icon-edit:before {
  content: '\ea16';
}
.zdv3-icon-facebook:before {
  content: '\ea17';
}
.zdv3-icon-favourite:before {
  content: '\ea18';
}
.zdv3-icon-filled-downWard-arrow:before {
  content: '\ea19';
}
.zdv3-icon-filled-favourite:before {
  content: '\ea1a';
}
.zdv3-icon-forward:before {
  content: '\ea1b';
}
.zdv3-icon-genre-filter:before {
  content: '\ea1c';
}
.zdv3-icon-genres:before {
  content: '\ea1d';
}
.zdv3-icon-heart:before {
  content: '\ea1e';
}
.zdv3-icon-home:before {
  content: '\ea1f';
}
.zdv3-icon-key-shortcut:before {
  content: '\ea20';
}
.zdv3-icon-keyboard:before {
  content: '\ea21';
}
.zdv3-icon-labels:before {
  content: '\ea22';
}
.zdv3-icon-left-arrow:before {
  content: '\ea23';
}
.zdv3-icon-menu-after:before {
  content: '\ea24';
}
.zdv3-icon-menu:before {
  content: '\ea25';
}
.zdv3-icon-mute:before {
  content: '\ea26';
}
.zdv3-icon-new-releases:before {
  content: '\ea27';
}
.zdv3-icon-next:before {
  content: '\ea28';
}
.zdv3-icon-packs:before {
  content: '\ea29';
}
.zdv3-icon-pause:before {
  content: '\ea2a';
}
.zdv3-icon-play:before {
  content: '\ea2b';
}
.zdv3-icon-player-mute:before {
  content: '\ea2c';
}
.zdv3-icon-player-volume:before {
  content: '\ea2d';
}
.zdv3-icon-previous:before {
  content: '\ea2e';
}
.zdv3-icon-profile:before {
  content: '\ea2f';
}
.zdv3-icon-release-view-btn:before {
  content: '\ea30';
}
.zdv3-icon-reset:before {
  content: '\ea31';
}
.zdv3-icon-right-arrow:before {
  content: '\ea32';
}
.zdv3-icon-search:before {
  content: '\ea33';
}
.zdv3-icon-setting:before {
  content: '\ea34';
}
.zdv3-icon-settings-icon:before {
  content: '\ea35';
}
.zdv3-icon-shortcut:before {
  content: '\ea36';
}
.zdv3-icon-spinvault:before {
  content: '\ea37';
}
.zdv3-icon-star:before {
  content: '\ea38';
}
.zdv3-icon-top-charts:before {
  content: '\ea39';
}
.zdv3-icon-top-downloads:before {
  content: '\ea3a';
}
.zdv3-icon-track-view-btn:before {
  content: '\ea3b';
}
.zdv3-icon-trending-charts:before {
  content: '\ea3c';
}
.zdv3-icon-up-arrow:before {
  content: '\ea3d';
}
.zdv3-icon-vector:before {
  content: '\ea3e';
}
.zdv3-icon-vol:before {
  content: '\ea3f';
}
.zdv3-icon-volume:before {
  content: '\ea40';
}
.zdv3-icon-zdfavourite:before {
  content: '\ea41';
}

$zdv3-icon-AddPlaylist: '\ea01';
$zdv3-icon-Crates: '\ea02';
$zdv3-icon-Instagram: '\ea03';
$zdv3-icon-Linkedin: '\ea04';
$zdv3-icon-SoundClord: '\ea05';
$zdv3-icon-Tweeter: '\ea06';
$zdv3-icon-Web: '\ea07';
$zdv3-icon-Youtube: '\ea08';
$zdv3-icon-backward: '\ea09';
$zdv3-icon-check-box: '\ea0a';
$zdv3-icon-circle: '\ea0b';
$zdv3-icon-close-player: '\ea0c';
$zdv3-icon-close: '\ea0d';
$zdv3-icon-cloud-download: '\ea0e';
$zdv3-icon-crate-drag-drop: '\ea0f';
$zdv3-icon-djinfo-add: '\ea10';
$zdv3-icon-djinfo-close: '\ea11';
$zdv3-icon-djinfo-edit: '\ea12';
$zdv3-icon-download-manager: '\ea13';
$zdv3-icon-download: '\ea14';
$zdv3-icon-drop-down: '\ea15';
$zdv3-icon-edit: '\ea16';
$zdv3-icon-facebook: '\ea17';
$zdv3-icon-favourite: '\ea18';
$zdv3-icon-filled-downWard-arrow: '\ea19';
$zdv3-icon-filled-favourite: '\ea1a';
$zdv3-icon-forward: '\ea1b';
$zdv3-icon-genre-filter: '\ea1c';
$zdv3-icon-genres: '\ea1d';
$zdv3-icon-heart: '\ea1e';
$zdv3-icon-home: '\ea1f';
$zdv3-icon-key-shortcut: '\ea20';
$zdv3-icon-keyboard: '\ea21';
$zdv3-icon-labels: '\ea22';
$zdv3-icon-left-arrow: '\ea23';
$zdv3-icon-menu-after: '\ea24';
$zdv3-icon-menu: '\ea25';
$zdv3-icon-mute: '\ea26';
$zdv3-icon-new-releases: '\ea27';
$zdv3-icon-next: '\ea28';
$zdv3-icon-packs: '\ea29';
$zdv3-icon-pause: '\ea2a';
$zdv3-icon-play: '\ea2b';
$zdv3-icon-player-mute: '\ea2c';
$zdv3-icon-player-volume: '\ea2d';
$zdv3-icon-previous: '\ea2e';
$zdv3-icon-profile: '\ea2f';
$zdv3-icon-release-view-btn: '\ea30';
$zdv3-icon-reset: '\ea31';
$zdv3-icon-right-arrow: '\ea32';
$zdv3-icon-search: '\ea33';
$zdv3-icon-setting: '\ea34';
$zdv3-icon-settings-icon: '\ea35';
$zdv3-icon-shortcut: '\ea36';
$zdv3-icon-spinvault: '\ea37';
$zdv3-icon-star: '\ea38';
$zdv3-icon-top-charts: '\ea39';
$zdv3-icon-top-downloads: '\ea3a';
$zdv3-icon-track-view-btn: '\ea3b';
$zdv3-icon-trending-charts: '\ea3c';
$zdv3-icon-up-arrow: '\ea3d';
$zdv3-icon-vector: '\ea3e';
$zdv3-icon-vol: '\ea3f';
$zdv3-icon-volume: '\ea40';
$zdv3-icon-zdfavourite: '\ea41';
