.zd-download-manager-sidebar {
  width: 0;
  padding: 20px 10px 10px;
  position: fixed;
  right: -250px;
  top: 60px;
  bottom: 0;
  height: calc(100vh - 60px);
  background: $gray-800;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  overflow: hidden;
  z-index: 91;

  span.no-data {
    color: #919191;
    display: block;
  }

  h5.zd-download-manager-title {
    margin: -20px -10px 0;
    padding: 10px;
    background: $gray-1400;
    font-size: 12px;
    color: $gray-500;
  }
  .zd-info-msg {
    padding: 0 10px;
  }
}

.zd-download-manager-tags {
  .nav-tabs {
    //ul
    border-bottom: 0;
    margin-bottom: 20px;
    -webkit-box-align: center;
    align-items: center;
    display: -webkit-box;
    display: flex;
    display: -ms-flexbox;
    display: -webkit-flex;
    -ms-flex-align: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    .nav-item {
      //li
      margin-left: 5px;
      margin-right: 5px;
      padding: 0;

      .nav-link {
        border: 0;
        text-transform: none;
        font-weight: 600;
        padding: 0;
        cursor: pointer;
        border-radius: 0;

        &.active {
          opacity: 0.6;
          background-color: transparent;
        }
      }

      &.zd-tags-tab {
        .nav-link {
          color: #7685b3;
          padding: 1px 6px;
          background: #2a3043;
          text-transform: uppercase;

          &.active {
            background-color: #2a3043;
          }
        }
      }
    }
  }
}

.zd-slide-open .zd-download-manager-sidebar {
  opacity: 1;
  right: 0;
  width: 250px;
  max-width: 250px;
}

// .zd-slide-open {
//   .cr-page {
//     width: calc(100% - 250px);
//     -webkit-transition: all 0.2s ease-in-out;
//     transition: all 0.2s ease-in-out;
//     position: relative;
//   }
// }

.zd-download-manager-sidebar {
  .zd-download-widget {
    margin-top: 10px;
    height: calc(100vh - 165px);
    max-height: calc(100vh - 165px);
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .tag-list {
      margin-bottom: 10px;

      label.zd-tag {
        color: #7685b3;
        padding: 1px 6px;
        background: #2a3043;
        margin: 0 6px 3px 0;
        height: 20px;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .zd-download-widget-section {
      margin: 0;
      padding: 0;
      list-style: none;
      border: none;
      flex: 1 1 100%;
      background: none;
      &.card-collapsed {
        flex: auto;
      }
      .zd-primary-dropdown{
        &:hover{
          color: #4f7be9 !important;
        }
        svg{
          font-size: 18px;
        }
      }

      .card-header {
        padding: 10px;
        font-size: 12px;
        line-height: 16px;
        text-transform: none;
        border-bottom: 1px solid $gray-1100;
        cursor: pointer;
        color: #eaeaea;
       
      }
      .zd-download-background{
        background:#4f7be9 !important;
        color:#eaeaea ;
        &:hover{
          color:#eaeaea !important;
        }
        svg{
          font-size: 18px;
        }
      }

      .card-body {
        padding: 10px 0;
      }

      &.close {
        display: none;
      }

      li {
        position: relative;
        min-height: 27px;
        overflow: hidden;
        margin-bottom: 17px;
        margin-top: 0;
        border-radius: 0;
      }

      .album-image {
        width: 27px;
        height: 27px;
        display: inline-block;
        position: absolute;
        left: 5px;
        cursor: pointer;

        a {
          line-height: 27px;
          font-size: 15px;
          color: map-get($map: $theme-colors, $key: primary);

          .zd-icon-repeat-track {
            color: $gray-60;

            &:hover {
              color: $white;
            }
          }

          &:hover {
            color: $white;
          }
        }
      }

      .album-title {
        padding-left: 30px;
        padding-right: 60px;
        display: block;
        font-size: 12px;
        cursor: pointer;
        line-height: 15px;

        span.track-name {
          color: #aeaeaf;
          display: block;
        }

        span.artist-name {
          // color: #4f5368;
          color: #DDDDDD !important;
          display: block;
        }
      }

      .zd-actions {
        right: 12px;
        position: absolute;
        top: 0;
        display: block;
        flex-direction: unset;
        list-style: none;
        border: none;

        li {
          display: inline-block;
          margin: 0 2px;
          border-radius: 0;

          a {
            font-size: 22px;
            color: $white;
            text-decoration: none;
            display: inline-block;
            cursor: pointer;
          }
        }
      }
    }

    .zd-batch-reset-items {
      // display: none;
      -webkit-transition: all 1s ease;
      transition: all 1s ease;

      &.open {
        display: block;
      }

      li {
        cursor: default;
      }
    }
  }

  .zd-download-footer {
    background-color: $table_tr;
    padding: 5px 0 10px;
    position: absolute;
    left: 10px;
    right: 10px;
    bottom: 5px;
    font-size: 12px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;

    .zd-cart-size {
      min-width: 35%;
      display: inline-block;
      color: $white;
    }

    .zd-batch-download-btn {
      letter-spacing: 0.03em;
      float: right;
      font-size: 12px;
    }

    .zd-batch-reset-btn {
      margin-bottom: 10px;

      span {
        cursor: pointer;

        &:hover {
          color: $link-hover-color;
        }

        &:after {
          font-family: "zipdj" !important;
          font-style: normal !important;
          font-weight: normal !important;
          font-variant: normal !important;
          text-transform: none !important;
          padding-right: 3px;
          content: '\52';
        }
      }

      &.open {
        span {
          color: $link-hover-color;

          &:hover {
            color: $white;
          }

          &:after {
            content: '\55';
          }
        }
      }
    }
  }
}

.zd-batch-download-section {
  position: unset;
  bottom: 80px;
  // width: calc(100vw - 96px);
  left: 70px;
  right: 16px;
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $gray-1100;
  z-index: 35;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;

  span {
    color: $white;
    float: left;
    display: inline-block;
  }

  button {
    float: right;
    font-size: 12px;
    background-color: #507de5;
    color: $white;
  }
}

.download-manager-page {
  .infinite-scroll-component {
    min-height: 350px!important;
  }
}

body {
  &.zd-sidebar-open {
    .zd-batch-download-section {
      // width: calc(100vw - 266px);
      left: 240px;
    }
  }

  &.zd-slide-open {
    .zd-batch-download-section {
      // width: calc(100vw - 346px);
      left: 70px;
      right: 266px;
    }
  }

  &.zd-sidebar-open.zd-slide-open {
    .zd-batch-download-section {
      // width: calc(100vw - 516px);
      left: 240px;
      right: 266px;
    }
  }

  &.player-active {
    .zd-download-manager-sidebar {
      .zd-download-widget {
        max-height: calc(100vh - 290px);
      }
    }
  }
}
