.cr-sidebar {
  // @include media-breakpoint-down(md) {
  //   display: none;
  // }
  .zd-hamburger-menu-parent {
    display: block;
    text-align: center;
  }
  .zd-hamburger-menu {
    margin-left: 2px;
    margin-top: 5px;
    border: none;
    outline: none;
    box-shadow: none;
    border-radius: 0;
    background: none !important;
    &:active,
    &:focus,
    &:hover,
    &:not(:disabled):not(.disabled):active:focus {
      border: none;
      outline: none;
      background: none !important;
      color: $white !important;
      box-shadow: none;

      .icon-ico {
        color: $link-hover-color;
      }
    }

    &:active,
    &:focus {
      .icon-ico {
        color: theme-color('primary');
      }
    }
    @media (min-width: 751px) {
      // visibility: hidden;
      &.mobile {
        display: none;
      }
    }
    @media (max-width: 750px) {
      margin-left: 0px !important;
      margin-top: -1px !important;
      position: fixed;
      top: 0;
      left: 0;
      &.desktop {
        display: none;
      }
    }
  }

  .navbar {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    margin: 0;

    .navbar-brand {
      padding: 0;
    }
  }
  position: fixed;
  top: 64px;
  bottom: 0;
  left: 0;
  z-index: 1004;
  width: $cr-sidebar-minimized-width;
  font-weight: 200;
  color: $white;
  background-position: center center;
  background-size: cover;
  background: #191b1f;
  border-right: 1px solid #242529;
  transition: width 0.1s ease-in;
  overflow: visible;
  // margin-top: 62px;
  // box-shadow: 8px 8px 20px 0 rgba(0, 0, 0, 0.3);
  @media (max-width: 750px) {
    width: 0;
  }
  + .cr-content {
    transition: margin 0.1s ease-in;
    margin-left: $cr-sidebar-minimized-width;
    @media (max-width: 750px) {
      margin-left: 0;
    }
  }

  &::after,
  &::before {
    // position: absolute;
    // top: 0;
    // left: 0;
    // z-index: 2;
    // display: block;
    // width: 100%;
    // height: 100%;
    // content: '';
    display: none;
  }

  .zd-logo {
    margin: 3px auto;
  }

  &::before {
    // background: #000;
    // opacity: 0.33;
  }

  &::after {
    z-index: 3;
    // sublime light
    // background: #fc5c7d; /* fallback for old browsers */
    // background: linear-gradient(to bottom, #6a82fb, #fc5c7d);
    background: #fff;
    opacity: 1;
  }

  &[data-image]:after {
    opacity: 0.77;
  }

  &__content {
    position: relative;
    z-index: 4;
    height: 100vh;
    // overflow-x: hidden;
    // overflow-y: auto;
    // box-shadow: inset 0 2px 6px 0 rgba(0, 0, 0, 0.36);
    overflow: visible;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0;
    }

    .navbar {
      margin-bottom: $spacer * 0.5;
      border-bottom: $border-width solid $border-color;
      overflow: hidden;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
    display: none;
  }

  &__nav-item-collapse {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  &__nav-item-icon {
    // @include media-breakpoint-down(md) {
    //   padding: 0;
    //   margin: 0 auto;
    // }
    width: 32px;
    min-width: 32px;
    height: 32px;
    min-height: 32px;
    padding-left: 0;
    padding-right: 0;
    margin-left: 8px;
    margin-right: 16px;
    color: theme-color('primary');
  }

  .nav {
    // margin-top: 15px;
    @media (max-width: 750px) {
      display: none;
    }
    .nav-item {
      margin: 0;
      color: theme-color(dark);
      border-radius: 4px;
      opacity: 1;
      font-size: 12px;
      white-space: pre;
      margin-top: 24px;
      @media (min-width: 1800px) {
        margin-top: 24px;
      }
      @media (min-width: 1368px) {
        margin-top: 22px;
      }
      @media (max-width: 1368px) {
        margin-top: 14px;
      }
      @media (max-width: 1200px) {
        margin-top: 22px;
      }
      @media (max-width: 750px) {
        // margin: 17px 0;
        margin-top: 0px;
        &:last-child {
          display: none;
        }
      }
    }

    .nav-link {
      // @include media-breakpoint-down(md) {
      //   padding: .5rem;
      // }
      padding: 3px 12px 3px 6px;
      color: #6b7883;
      width: 54px;
      overflow: hidden;
      .zd-sidebar-icon {
        font-size: 23px;
        vertical-align: middle;
        margin-left: 10px;
        margin-right: 12px;
        color: #6b7883;
        // @media (max-width: 750px) {
        //   display: none;
        // }
      }

      @media (max-width: 750px) {
        padding: 10px 4px 10px 4px;
      }
      > span {
        visibility: hidden;
        opacity: 0;
        font-weight: 500;
        // text-transform: uppercase;
      }

      &:hover {
        background: #303139;
        width: auto;
        // box-shadow: 1px 0 5px rgba(0, 0, 0, 0.3);
        overflow: visible;
        // display: block;
        border-radius: 2px;
        @media (max-width: 750px) {
          width: 100%;
          background-color: transparent;
        }
        .zd-sidebar-icon {
          color: #d8d8d8;
        }
        > span {
          color: #d8d8d8;
          visibility: visible;
          opacity: 1;
        }
      }

      &.active {
        background: #222327;
        // -webkit-box-shadow: 1px 0 5px rgba(0, 0, 0, 0.3);
        // box-shadow: 1px 0 5px rgba(0, 0, 0, 0.3);
        position: relative;
        overflow: hidden;
        .zd-sidebar-icon {
          color: #4f7be9;
          @media (max-width: 750px) {
            color: #fff;
          }
        }
        &::before {
          @media (min-width: 750px) {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 4px;
            background: theme-color('primary');
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
          }
        }
        // color: theme-color('primary');

        &:hover {
          background: #303139 !important;
          color: #4f7be9;
          overflow: visible;
          // display: block;
          .zd-sidebar-icon {
            color: #4f7be9;
          }
          @media (max-width: 750px) {
            color: #4f7be9;
            background: transparent;
          }
        }

        .zd-home-icon,
        .zd-icon {
          color: theme-color('primary');
        }
        span {
          color: theme-color('primary');
        }
        .cr-sidebar__nav-item-icon {
          color: $white;
        }
        @media (max-width: 750px) {
          background: transparent;
          svg {
            path {
              stroke: #d8d8d8;
            }
          }
          span {
            color: #4f7be9;
          }
        }
      }
    }
  }
  .cr-sidebar__nav-item {
    .active {
      background: linear-gradient(
        90deg,
        rgba(73, 120, 239, 0.09) 0.84%,
        rgba(73, 120, 239, 0.03) 55.66%,
        rgba(73, 120, 239, 0) 99.46%
      ) !important;
    }
  }
  .nav .nav-link .zd-home-icon {
    overflow: visible;
  }

  .icon-ico,
  .nav .nav-link .zd-home-icon,
  .nav .nav-link .zd-icon {
    font-size: 26px;
    color: theme-color('grey2');
    opacity: 0.8;
    vertical-align: middle;
    margin-left: 11px;
    margin-right: 12px;
    transition: color 0.4s ease;
    z-index: 31;
    position: relative;
  }

  .nav .nav-link:hover .zd-home-icon,
  .nav .nav-link:hover .zd-icon {
    color: theme-color('grey');
  }

  .icon-ico {
    margin-left: 0;
    margin-right: 0;
  }

  .zd-logo-46 {
    display: block;
  }

  .zd-logo-200 {
    display: none;
  }

  &--open {
    left: 0;
    width: $cr-sidebar-width;
    transition: left 0.2s ease-in;
    @media (max-width: 750px) {
      width: 100vw;
      height: calc(100vh - 48px);
      // margin-top: 48px;
    }
    + .cr-content {
      margin: 0 0 0 $cr-sidebar-width;
      // margin: 0 0 0 $cr-sidebar-minimized-width;
      transition: margin 0.2s ease-in;

      .cr-header {
        margin-left: 0; //$cr-sidebar-width;
      }

      .zd-content-head.zd-fixed-header {
        left: $cr-sidebar-width;
      }
    }

    .nav {
      .nav-link {
        width: auto;
        @media (max-width: 750px) {
          // justify-content: center;
        }
        .cr-sidebar__nav-item-icon {
        }

        > span {
          visibility: visible;
          font-size: 12px;
          font-family: 'Poppins', 'Roboto', sans-serif;
          font-weight: 500;
          opacity: 1;
          color: #6b7883;
          // text-transform: uppercase;

          @media (max-width: 750px) {
            color: #6b7883;
          }
        }
      }
    }

    .zd-logo-46 {
      display: none;
    }

    .zd-logo-200 {
      display: block;
    }
  }
}
body {
  .zd-footer {
    padding-left: 20px;
    @media (max-width: 750px) {
      justify-content: center;
    }
    span {
      margin-left: 16px;
    }
  }
}
.zd-is-safari {
  .cr-sidebar .nav {
    .nav-item {
      .nav-link:hover {
        min-width: 140px;
      }

      &.zd-download-manager-btn {
        .nav-link:hover {
          min-width: 210px;
        }
      }

      &.zipdjpacks {
        .nav-link:hover {
          min-width: 160px;
        }
      }

      &.top-downloads {
        .nav-link:hover {
          min-width: 190px;
        }
      }
    }
  }
}
.zd-hamburger-menu {
  @media (max-width: 750px) {
    margin-top: 7px !important;
  }
}
.cr-sidebar--open.cr-sidebar {
  .nav {
    // margin-top: 15px;
    @media (max-width: 750px) {
      display: block !important;
    }
  }

  .zd-hamburger-menu {
    @media (min-width: 750px) {
      transition: all 0.2s ease-in;
      margin-left: 158px;
    }
    @media (max-width: 750px) {
      // display: none;
    }
  }
}
.zd-sm-sidebar-profile {
  display: none;
  @media (max-width: 750px) {
    display: block;
    height: 104px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .zd-sm-sidebar-profile-img {
    padding: 4px;
    width: 60vw;
    text-align: center;
    i {
      font-size: 80px;
      border: 1px solid #ddd;
      border-radius: 40px;
      color: #4f7be9;
    }
  }
  .zd-sm-sidebar-profile-img img {
    width: 80px;
    height: 80px;
    border-radius: 80px;
  }
  .zd-sm-sidebar-profile-name {
    padding-left: 12px;
  }

  .zd-sm-sidebar-download,
  .zd-sm-sidebar-profile-setting {
    width: 20vw;
    a {
      text-decoration: none !important;
    }
    i {
      font-size: 20px;
      color: #c4c4c4;
    }
  }
  .zd-sm-sidebar-download {
    padding-left: 50px;
  }
  .zd-sm-sidebar-profile-setting {
    padding-right: 50px;
    text-align: right;
  }
}
.zd-sm-sidebar-profile-name {
  display: none;
  @media (max-width: 750px) {
    display: block;
    text-align: center;
    .zd-sm-sidebar-profile-name h3 {
      line-height: 0;
      font-size: 16px;
      margin-top: 17px;
      color: #d8d8d8;
      font-weight: 500;
    }
    .zd-sm-sidebar-profile-artist {
      margin-top: 9px;
      font-size: 12px;
      color: #47484a;
      margin-bottom: 50px;
    }
  }
}
.zd-hamburger-menu {
  i {
    color: #6b7883;
    font-size: 28px;
    text-align: center;
    @media screen and (min-width: 750px) {
      background-color: #242529 !important;
    }
  }
  &:hover {
    i {
      color: #dddddd;
    }
    svg {
      path {
        stroke: #dddddd;
      }
    }
  }
}
.zd-lg-sidebar-nav-item {
  @media (max-width: 750px) {
    display: none !important;
  }
}

.zd-sm-sidebar-nav-item {
  @media (min-width: 751px) {
    display: none !important;
  }
}
