.react-jinke-music-player-main ul,
.react-jinke-music-player-main li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.react-jinke-music-player-main * {
  box-sizing: border-box;
}
.react-jinke-music-player-main .text-center {
  text-align: center;
}
.react-jinke-music-player-main .hidden {
  display: none !important;
}
.react-jinke-music-player-main .loading {
  display: -ms-inline-flexbox;
  display: inline-flex;
  animation: audioLoading 1s linear infinite;
}
.react-jinke-music-player-main .loading svg {
  font-size: 24px;
  color: #fff;
}
.react-jinke-music-player-main .translate {
  animation: translate 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
.react-jinke-music-player-main .scale {
  animation: scaleTo 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
@keyframes playing {
  to {
    transform: rotateX(360deg);
  }
}
@keyframes audioLoading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes scale {
  from {
    transform: scale(0);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.5);
  }
  to {
    opacity: 0;
    transform: scale(2);
  }
}
@keyframes scaleTo {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes scaleFrom {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0);
    opacity: 0;
  }
}
@keyframes imgRotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes fromTo {
  from {
    transform: scale(1) translate3d(0, 110%, 0);
  }
  to {
    transform: scale(1) translate3d(0, 0, 0);
  }
}
@keyframes fromOut {
  from {
    transform: scale(1) translate3d(0, 0, 0);
  }
  to {
    transform: scale(1) translate3d(0, 110%, 0);
  }
}
@keyframes fromDown {
  from {
    transform: scale(1) translate3d(0, -110%, 0);
  }
  to {
    transform: scale(1) translate3d(0, 0, 0);
  }
}
@keyframes translate {
  from {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes remove {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  to {
    transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }
}
.react-jinke-music-player-main .img-rotate-pause {
  animation-play-state: paused !important;
}
.react-jinke-music-player-mobile {
  width: 100%;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  color: #fff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #131213;
}
.react-jinke-music-player-mobile > .group {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.react-jinke-music-player-mobile .show {
  animation: mobile-bg-show 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
.react-jinke-music-player-mobile .hide {
  animation: mobile-bg-hide 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
.react-jinke-music-player-mobile-play-model-tip {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 35px;
  line-height: 35px;
  background-color: #3b49af;
  color: #fff;
  padding: 0 20px;
  z-index: 1000;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  transform: translate3d(0, -105%, 0);
  transition: transform 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-mobile-play-model-tip .title {
  margin-right: 12px;
}
.react-jinke-music-player-mobile-play-model-tip .title svg {
  animation: none !important;
  vertical-align: text-bottom !important;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player-mobile-play-model-tip .title svg {
    font-size: 19px;
    color: #fff !important;
  }
}
.react-jinke-music-player-mobile-play-model-tip .text {
  font-size: 14px;
}
.react-jinke-music-player-mobile-play-model-tip.show {
  transform: translate3d(0, 0, 0);
}
.react-jinke-music-player-mobile-header {
  width: 100%;
  top: 0;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  animation: fromDown 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
.react-jinke-music-player-mobile-header .title {
  -ms-flex-preferred-size: 80%;
  flex-basis: 80%;
  text-align: center;
  font-size: 20px;
  transition: color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.react-jinke-music-player-mobile-header .item {
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 50px;
}
.react-jinke-music-player-mobile-header .right {
  -ms-flex-pack: end;
  justify-content: flex-end;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
}
.react-jinke-music-player-mobile-header .right svg {
  font-size: 25px;
}
.react-jinke-music-player-mobile-singer {
  animation: fromDown 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
  padding: 12px 0;
}
@media screen and (max-width: 320px) {
  .react-jinke-music-player-mobile-singer {
    padding: 0;
  }
}
.react-jinke-music-player-mobile-singer .name {
  position: relative;
  font-size: 14px;
  transition: color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-mobile-singer .name:before,
.react-jinke-music-player-mobile-singer .name:after {
  content: '';
  position: absolute;
  width: 16px;
  height: 1px;
  top: 9px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.6);
  transition: background-color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-mobile-singer .name:after {
  left: -25px;
}
.react-jinke-music-player-mobile-singer .name:before {
  right: -25px;
}
.react-jinke-music-player-mobile-cover {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 5px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1px 3px rgba(0, 0, 0, 0.1);
  margin: 15px auto;
  animation: fromTo 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
  overflow: hidden;
  transition: box-shadow, border 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
@media screen and (max-width: 320px) {
  .react-jinke-music-player-mobile-cover {
    width: 230px;
    height: 230px;
    margin: 10px auto;
  }
}
.react-jinke-music-player-mobile-cover .cover {
  width: 100%;
  animation: imgRotate 30s linear infinite;
}
.react-jinke-music-player-mobile-progress {
  -ms-flex-pack: distribute;
  justify-content: space-around;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.react-jinke-music-player-mobile-progress .current-time,
.react-jinke-music-player-mobile-progress .duration {
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 55px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  transition: color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-mobile-progress .duration {
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.react-jinke-music-player-mobile-progress .progress-bar {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.react-jinke-music-player-mobile-progress .rc-slider-rail {
  background-color: rgba(255, 255, 255, 0.6);
}
.react-jinke-music-player-mobile-switch {
  animation: fromDown 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
.react-jinke-music-player-mobile-toggle {
  padding: 17px 0;
}
.react-jinke-music-player-mobile-toggle .group {
  cursor: pointer;
}
.react-jinke-music-player-mobile-toggle .group svg {
  font-size: 40px;
}
.react-jinke-music-player-mobile-toggle .play-btn {
  padding: 0 40px;
}
.react-jinke-music-player-mobile-toggle .play-btn svg {
  font-size: 60px;
}
@media screen and (max-width: 320px) {
  .react-jinke-music-player-mobile-toggle {
    padding: 10px 0;
  }
  .react-jinke-music-player-mobile-toggle > .group svg {
    font-size: 32px;
  }
  .react-jinke-music-player-mobile-toggle .play-btn svg {
    font-size: 50px;
  }
}
.react-jinke-music-player-mobile-toggle,
.react-jinke-music-player-mobile-progress {
  animation: fromTo 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
.react-jinke-music-player-mobile-operation {
  animation: fromTo 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
.react-jinke-music-player-mobile-operation .items {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-align: center;
  align-items: center;
}
.react-jinke-music-player-mobile-operation .items .item {
  cursor: pointer;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
}
.react-jinke-music-player-mobile-operation .items .item svg {
  color: rgba(255, 255, 255, 0.6);
  font-size: 25px;
}
@media screen and (max-width: 320px) {
  .react-jinke-music-player-mobile-operation .items .item svg {
    font-size: 20px;
  }
}
@keyframes mobile-bg-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes mobile-bg-hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.audio-lists-panel-drag-line {
  border: 1px solid #3b49af !important;
}
.audio-lists-panel {
  display: block;
  overflow: hidden;
  position: fixed;
  right: 33px;
  bottom: 56px;
  z-index: 999;
  width: 480px;
  height: 410px;
  color: rgba(255, 255, 255, 0.8);
  transform-origin: right bottom;
  background-color: rgba(0, 0, 0, 0.7);
  transform: scale(0);
  display: none\9;
  transition: background-color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.audio-lists-panel svg {
  font-size: 24px;
}
.audio-lists-panel.show {
  animation: scaleTo 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
  display: block\9;
}
.audio-lists-panel.hide {
  animation: scaleFrom 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
  display: none\9;
}
@media screen and (max-width: 767px) {
  .audio-lists-panel {
    width: 100% !important;
    top: 64px;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto !important;
    transform-origin: bottom center;
    z-index: 98;
    background: #1b1b1e;
  }
  body .hot-keys-panel {
    background: #1b1b1e;
  }
  .audio-lists-panel.show {
    animation: fromTo 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
    display: block\9;
  }
  .audio-lists-panel.hide {
    animation: fromOut 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
    display: none\9;
  }
}
@media screen and (max-width: 767px) {
  .audio-lists-panel .audio-item {
    background-color: rgba(64, 68, 75, 0.5) !important;
  }
  .audio-lists-panel .audio-item.playing {
    background-color: rgba(64, 68, 75, 0.75) !important;
  }
}
.audio-lists-panel-header {
  border-bottom: 1px solid rgba(3, 3, 3, 0.7);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  transition: background-color,
    border-bottom 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.audio-lists-panel-header .close-btn,
.audio-lists-panel-header .delete-btn {
  float: right;
  cursor: pointer;
}
.audio-lists-panel-header .delete-btn svg {
  font-size: 21px;
}
@media screen and (max-width: 768px) {
  .audio-lists-panel-header .delete-btn svg {
    font-size: 19px;
  }
}
.audio-lists-panel-header .close-btn:hover svg {
  animation: imgRotate 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.audio-lists-panel-header .line {
  width: 1px;
  height: inherit;
  background: #fff;
  float: right;
  height: 20px;
  margin: 18px 10px 0 10px;
}
.audio-lists-panel-header .title {
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  font-size: 16px;
  margin: 0;
  text-align: left;
  font-weight: 500;
  transition: color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.audio-lists-panel-content {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 60px);
}
@media screen and (max-width: 767px) {
  .audio-lists-panel-content {
    width: 100% !important;
    height: calc(100% - 60) !important;
    transform-origin: bottom center;
  }
}
.audio-lists-panel-content.no-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}
.audio-lists-panel-content .no-data {
  margin-left: 10px;
}
.audio-lists-panel-content .audio-item {
  background-color: #40444b;
  border-bottom: #40444b;
  padding: 3px 20px;
  line-height: 40px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.audio-lists-panel-content .audio-item.playing {
  background-color: #454951;
}
.audio-lists-panel-content .audio-item.playing svg {
  color: #fff;
}
.audio-lists-panel-content .audio-item.remove {
  animation: remove 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
.audio-lists-panel-content .audio-item .player-icons {
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 30px;
}
.audio-lists-panel-content .audio-item .player-icons .loading {
  animation: audioLoading 0.9s linear infinite;
}
.audio-lists-panel-content .audio-item .player-delete:hover svg {
  animation: imgRotate 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.audio-lists-panel-content .audio-item:hover,
.audio-lists-panel-content .audio-item:active {
  background-color: #454951;
}
.audio-lists-panel-content
  .audio-item:hover
  .group:not([class='.player-delete'])
  svg,
.audio-lists-panel-content
  .audio-item:active
  .group:not([class='.player-delete'])
  svg {
  color: #3b49af;
}
.audio-lists-panel-content .audio-item .group {
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.audio-lists-panel-content .audio-item .player-name {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0 20px 0 10px;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.audio-lists-panel-content .audio-item .player-time {
  font-size: 12px;
  color: rgba(217, 217, 217, 0.45);
  transition: color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.audio-lists-panel-content .audio-item .player-delete {
  padding-left: 2%;
}
.react-jinke-music-player-main {
  -ms-touch-action: none;
  touch-action: none;
  font-family: inherit;
}
.react-jinke-music-player-main ::-webkit-scrollbar-thumb {
  height: 20px;
  background-color: #fff;
  opacity: 0.5;
}
.react-jinke-music-player-main ::-webkit-scrollbar {
  width: 10px;
  background-color: #f7f8fa;
}
.react-jinke-music-player-main .lyric-btn-active {
  color: #3b49af;
}
.react-jinke-music-player-main .music-player-lyric {
  position: fixed;
  color: #3b49af;
  font-size: 36px;
  cursor: move;
  z-index: 999;
  bottom: 100px;
  padding: 20px;
  width: 100%;
  left: 0;
  text-align: center;
  transition: box-shadow 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
  background: transparent;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.05);
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player-main .music-player-lyric {
    font-size: 16px;
  }
  .react-jinke-music-player-main .music-player-lyric:hover {
    box-shadow: 2px 2px 20px #d9d9d9;
  }
}
.react-jinke-music-player-main .play-mode-title {
  display: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  right: 72px;
  bottom: 80px;
  padding: 5px 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  line-height: 1.5;
  text-align: center;
  transform: translate3d(100%, 0, 0);
  transform-origin: bottom center;
  z-index: 88;
  opacity: 0;
  transition: all 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main .play-mode-title.play-mode-title-visible {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.react-jinke-music-player-main .glass-bg-container {
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 300%;
  height: 300%;
  left: 0;
  top: 0;
  filter: blur(80px);
  z-index: -1;
}
.react-jinke-music-player-main .glass-bg {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.react-jinke-music-player-main svg {
  font-size: 24px;
  transition: color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main svg:hover,
.react-jinke-music-player-main svg:active {
  color: #3b49af;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player-main svg {
    font-size: 22px;
  }
}
.react-jinke-music-player-main .react-jinke-music-player-play-icon,
.react-jinke-music-player-main .react-jinke-music-player-pause-icon {
  animation: imgRotate 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main .react-jinke-music-player-mode-icon {
  animation: scaleTo 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main .music-player-panel {
  box-shadow: 0 0 3px #403f3f;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  color: #fff;
  height: 80px;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  transition: background-color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main .music-player-panel .panel-content {
  height: 100%;
  padding: 0 30px;
  position: relative;
  /* overflow: hidden; */
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}
.react-jinke-music-player-main .music-player-panel .panel-content .img-content {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  background-size: 100%;
  background-repeat: no-repeat;
  box-shadow: 0 0 10px rgba(0, 34, 77, 0.05);
}
@media screen and (max-width: 768px) {
  .react-jinke-music-player-main
    .music-player-panel
    .panel-content
    .img-content {
    width: 40px;
    height: 40px;
  }
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .img-content
  img {
  width: 100%;
}
.react-jinke-music-player-main .music-player-panel .panel-content .img-rotate {
  animation: imgRotate 15s linear infinite;
}
.react-jinke-music-player-main .music-player-panel .panel-content .hide-panel,
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .upload-music {
  -ms-flex-preferred-size: 10%;
  flex-basis: 10%;
  cursor: pointer;
  margin-left: 15px;
}
@media screen and (max-width: 768px) {
  .react-jinke-music-player-main
    .music-player-panel
    .panel-content
    .progress-bar-content {
    /* display: none !important; */
  }
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content {
  -ms-flex: 1;
  flex: 1;
  padding: 0 20px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .audio-title {
  transition: color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  max-width: 400px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .audio-main {
  width: 100%;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 6px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .audio-main
  .current-time,
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .audio-main
  .duration {
  font-size: 12px;
  -ms-flex-preferred-size: 5%;
  flex-basis: 5%;
  transition: color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .progress-bar {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 100%;
  margin: 2px 20px 0 5px;
  position: relative;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .progress-bar
  .progress {
  position: absolute;
  display: inline-block;
  height: 5px;
  left: 0;
  top: 0;
  transition: width 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 31%,
    rgba(0, 0, 0, 0.05) 33%,
    rgba(0, 0, 0, 0.05) 67%,
    rgba(0, 0, 0, 0) 69%
  );
  background-color: #3b49af;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .progress-bar
  .progress
  .progress-change {
  position: absolute;
  width: 10px;
  height: 10px;
  right: 0;
  bottom: -2px;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
  background-color: #fff;
  cursor: pointer;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .progress-bar
  .progress-load-bar {
  position: absolute;
  width: 0%;
  height: 4px;
  background-color: rgba(0, 0, 0, 0.06);
  transition: width, background-color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
  left: 0;
  top: 5px;
  z-index: 77;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .progress-bar
  .rc-slider-track {
  z-index: 78;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .progress-bar
  .rc-slider-handle {
  z-index: 79;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content {
  padding-left: 2%;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-preferred-size: 15%;
  flex-basis: 15%;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  > .group {
  text-align: center;
  -ms-flex: 1;
  flex: 1;
  margin: 0 10px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  > .group,
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  > .group
  > svg {
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player-main
    .music-player-panel
    .panel-content
    .player-content
    > .group {
    margin: 0 6px;
  }
}
@media screen and (max-width: 320px) {
  .react-jinke-music-player-main
    .music-player-panel
    .panel-content
    .player-content
    > .group {
    margin: 0 4px;
  }
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  > .group
  > i {
  cursor: pointer;
  color: #fff;
  font-size: 20px;
  vertical-align: middle;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .theme-switch
  .rc-switch {
  background-color: transparent;
}
@media screen and (max-width: 768px) {
  .react-jinke-music-player-main
    .music-player-panel
    .panel-content
    .player-content
    .play-sounds {
    /* display: none !important; */
  }
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .prev-audio
  svg,
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .next-audio
  svg {
  font-size: 35px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .play-btn {
  padding: 0 18px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .play-btn
  svg {
  font-size: 26px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .loop-btn.active {
  color: #3b49af;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .play-sounds {
  -ms-flex-align: center;
  align-items: center;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .play-sounds
  svg {
  font-size: 28px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .play-sounds
  .sounds-icon {
  margin-right: 15px;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .play-sounds
  .sound-operation {
  width: 100px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .audio-lists-btn {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-width: 60px;
  height: 23px;
  border-radius: 40px;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 0 10px;
  box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.02);
  position: relative;
  transition: color, background-color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .audio-lists-btn
  > .group:hover,
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .audio-lists-btn
  > .group:hover
  > svg {
  color: #3b49af;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .audio-lists-btn
  .audio-lists-num {
  margin-left: 8px;
}
.react-jinke-music-player-main .music-player-panel .rc-switch-inner svg {
  font-size: 13px;
}
.react-jinke-music-player-main .rc-slider-rail {
  background-color: #626162 !important;
  transition: background-color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main.light-theme svg {
  color: #3b49af;
}
.react-jinke-music-player-main.light-theme svg:hover,
.react-jinke-music-player-main.light-theme svg:active {
  color: #3ece89;
}
.react-jinke-music-player-main.light-theme .rc-slider-rail {
  background-color: rgba(0, 0, 0, 0.09) !important;
}
.react-jinke-music-player-main.light-theme .music-player-panel {
  box-shadow: 0 1px 2px 0 rgba(0, 34, 77, 0.05);
  background-color: #fff;
  color: #7d7d7d;
}
.react-jinke-music-player-main.light-theme .music-player-panel .img-content {
  box-shadow: 0 0 10px #dcdcdc;
}
.react-jinke-music-player-main.light-theme .rc-switch {
  color: #fff;
}
.react-jinke-music-player-main.light-theme .rc-switch::after {
  background-color: #fff;
}
.react-jinke-music-player-main.light-theme .rc-switch-checked {
  background-color: #3b49af !important;
  border: 1px solid #3b49af;
}
.react-jinke-music-player-main.light-theme .rc-switch-inner {
  color: #fff;
}
.react-jinke-music-player-main.light-theme .audio-lists-btn {
  background-color: #f7f8fa !important;
}
.react-jinke-music-player-main.light-theme .audio-lists-btn:hover,
.react-jinke-music-player-main.light-theme .audio-lists-btn:active {
  color: #444;
  background-color: #fdfdfe;
}
.react-jinke-music-player-main.light-theme .audio-lists-btn > .group:hover,
.react-jinke-music-player-main.light-theme
  .audio-lists-btn
  > .group:hover
  > svg {
  color: #444;
}
.react-jinke-music-player-main.light-theme .audio-lists-panel {
  color: #444;
  background-color: #fff;
  box-shadow: 0 0 2px #dcdcdc;
}
.react-jinke-music-player-main.light-theme .audio-lists-panel .audio-item {
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player-main.light-theme .audio-lists-panel .audio-item {
    background-color: #fff !important;
  }
  .react-jinke-music-player-main.light-theme
    .audio-lists-panel
    .audio-item.playing {
    background-color: #fafafa !important;
  }
  .react-jinke-music-player-main.light-theme
    .audio-lists-panel
    .audio-item.playing
    svg {
    color: #3b49af;
  }
}
.react-jinke-music-player-main.light-theme .audio-lists-panel-header {
  color: #444;
  background-color: #fff;
  border-bottom: 1px solid #f4f4f7;
}
.react-jinke-music-player-main.light-theme .audio-lists-panel-header .line {
  background-color: #f4f4f7;
}
.react-jinke-music-player-main.light-theme .audio-item {
  box-shadow: 0 0 2px transparent !important;
  border-bottom: 1px solid rgba(220, 220, 220, 0.26);
  background-color: rgba(64, 68, 75, 0.65);
}
.react-jinke-music-player-main.light-theme .audio-item:hover,
.react-jinke-music-player-main.light-theme .audio-item:active {
  background-color: #fafafa !important;
}
.react-jinke-music-player-main.light-theme .audio-item:hover svg,
.react-jinke-music-player-main.light-theme .audio-item:active svg {
  color: #3b49af;
}
.react-jinke-music-player-main.light-theme .audio-item.playing {
  background-color: #fafafa !important;
}
.react-jinke-music-player-main.light-theme .audio-item.playing svg {
  color: #3b49af;
}
.react-jinke-music-player-main.light-theme .player-time {
  color: rgba(162, 162, 162, 0.45) !important;
}
.react-jinke-music-player-main.light-theme .react-jinke-music-player-mobile {
  background-color: #fff;
  color: #444;
}
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile-cover {
  border: 5px solid transparent;
  box-shadow: 0 0 30px 2px rgba(0, 0, 0, 0.2);
}
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile
  .current-time,
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile
  .duration {
  color: #444;
}
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile
  .rc-slider-rail {
  background-color: #e9e9e9;
}
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile-operation
  svg {
  color: #444;
}
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile-tip
  svg {
  color: #fff !important;
}
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile-singer
  .name {
  color: #444;
  transition: color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile-singer
  .name::before,
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile-singer
  .name::after {
  background-color: #444;
}
.react-jinke-music-player-main.light-theme .play-mode-title {
  background-color: #fff;
  color: #3b49af;
}
.react-jinke-music-player {
  position: fixed;
  width: 80px;
  height: 80px;
  z-index: 999;
}
.react-jinke-music-player .music-player {
  cursor: pointer;
  position: relative;
  width: 80px;
  height: 80px;
}
.react-jinke-music-player .music-player-audio {
  display: none !important;
}
.react-jinke-music-player .music-player-controller {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid #fff;
  background-color: #fff;
  color: #3b49af;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 20px;
  text-align: center;
  transition: all 0.3s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  z-index: 99;
  padding: 10px;
  position: fixed;
  background-repeat: no-repeat;
  background-size: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}
.react-jinke-music-player
  .music-player-controller.music-player-playing::before {
  content: '';
  position: absolute;
  width: 80px;
  height: 80px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  z-index: 88;
  animation: scale 5s linear infinite;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player .music-player-controller,
  .react-jinke-music-player .music-player-controller::before {
    width: 60px;
    height: 60px;
  }
}
.react-jinke-music-player .music-player-controller i {
  font-size: 28px;
}
.react-jinke-music-player .music-player-controller:active {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}
.react-jinke-music-player .music-player-controller:hover {
  font-size: 16px;
}
.react-jinke-music-player
  .music-player-controller:hover
  .music-player-controller-setting {
  transform: scale(1);
}
.react-jinke-music-player .music-player-controller .controller-title {
  font-size: 14px;
}
@media screen and (max-width: 768px) {
  .react-jinke-music-player .music-player-controller i {
    font-size: 20px;
  }
  .react-jinke-music-player .music-player-controller:hover,
  .react-jinke-music-player .music-player-controller:active {
    font-size: 12px;
  }
  .react-jinke-music-player
    .music-player-controller:hover
    .music-player-controller-setting,
  .react-jinke-music-player
    .music-player-controller:active
    .music-player-controller-setting {
    transform: scale(1);
  }
}
.react-jinke-music-player
  .music-player-controller
  .music-player-controller-setting {
  width: 100%;
  height: 100%;
  background: rgba(49, 194, 124, 0.3);
  transform: scale(0);
  border-radius: 50%;
  transition: all 0.4s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}
.react-jinke-music-player .audio-circle-process-bar {
  strokewidth: 3px;
  strokelinejoin: round;
  pointer-events: none;
  width: 80px;
  height: 80px;
  position: absolute;
  left: 0;
  top: -80px;
  z-index: 100;
  animation: scaleTo 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player .audio-circle-process-bar circle[class='bg'] {
  stroke: #fff;
}
.react-jinke-music-player .audio-circle-process-bar circle[class='stroke'] {
  stroke: #3b49af;
}
.react-jinke-music-player .audio-circle-process-bar,
.react-jinke-music-player .audio-circle-process-bar circle {
  transition: stroke-dasharray 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
  transform: matrix(0, -1, 1, 0, 0, 80);
}
@media screen and (max-width: 768px) {
  .react-jinke-music-player .audio-circle-process-bar,
  .react-jinke-music-player .audio-circle-process-bar circle {
    transform: matrix(0, -1, 1, 0, 0, 60);
  }
}
