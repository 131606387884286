// body tbody tr:hover {
//   background-color: $table_tr_hover;
//   border-right: 12px solid #4F7BE9;
//   border-top-right-radius: 12px;
//   border-bottom-right-radius: 12px;
// }
.zd-icon-mr {
  margin-right: 16px !important;
}
.crate-profile-header {
  margin-bottom: 15px;
  margin-left: 41px;
  h2 {
    font-size: 16px;
    padding-bottom: 2px;
    display: inline-block;
  }
}
.crateModal {
  width: 360px;
  @media (max-width: 750px) {
    width: 96%;
  }
  .modal-content {
    height: 253px;
    background: #242529 !important;
    .crate-label {
      position: absolute;
      height: 24px;
      left: 109px;
      top: 54px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      // display: none;
      margin-bottom: 0;
    }
    .crate-text {
      width: 280px;
      height: 40px;
      position: absolute;
      top: 97px;
      left: 40px;
      background: #1c1e22;
      border-radius: 2px !important;
      color: white;
    }
    .modalErrorText {
      position: absolute;
      top: 148px;
      left: 40px;
      color: #ff4433;
    }
    .modal-cancel-button {
      position: absolute;
      left: 40px;
      top: 184px;
      width: 91px;
      height: 38px;
      background: #242529;
      border-color: #242529;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
    }
    .modal-create-button {
      position: absolute;
      top: 184px;
      left: 230px;
      width: 91px;
      height: 38px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
    }
  }
}
.show-child {
  margin-top: -28px;
  left: -161px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
#child_dropdown__2 {
  left: -175px !important;
}
.zd-tertiary-header {
  margin-top: 12px;

  .zd-primary-dropdown {
    &:hover {
      background: #25262a !important;
    }
  }
}
.all-packs-crate {
  .zd-release-play {
    display: table-cell !important;
  }
  .zd-release-crates {
    @media (max-width: 750px) {
      width: 24px !important;
    }
  }
}

.zd-release-crates {
  padding-top: 8px;
  vertical-align: -webkit-baseline-middle !important;
  width: 24px;
  height: 20px;
  i {
    color: #6b7883;
  }
  // .zd_tracklist_create {
  //   position: absolute !important;
  //   overflow: unset !important;
  // }
  .crate-dropdown-menu {
    position: absolute;
    width: 161px;
    // position: absolute;
    // will-change: transform;
    top: 20px !important;
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px !important;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));
    transform: translate3d(-129px, 7px, 0px) !important;
    .dropdown-item {
      // padding-left: 54px;
      .dropdown-right-icon {
        // padding-left: 54px !important;
        font-size: 10px !important;
      }
      .artist-arrow {
        padding-left: 54px;
      }
      .playlist-arrow {
        padding-left: 41px;
      }
    }

    .follow-crate-dropdown {
      // will-change: transform;margin: 0px 0px 0px 0px !important;
      padding: 0px 0px !important;
      transform: translate3d(-165px, -0px, 0px) !important;
    }
    .crate-playlist-dropdown {
      width: 161px;
      max-height: 200px;
      overflow-y: auto;

      transform: translate3d(-165px, -2px, 0px) !important;
      .dropdown-playlist {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .dropdown-add-icon {
        font-size: 11px !important;
      }
    }
  }
  .bottom-crate {
    position: absolute;
    will-change: transform;
    top: -112px !important;
    .crate-playlist-dropdown {
      transform: translate3d(-165px, -97px, 0px) !important;
    }
  }
}
.zd-megagenre-parent {
  .favourite-search-bar-form {
    @media (max-width: 750px) {
      display: none;
    }
  }
}

.favourite-search-bar-form {
  width: 240px;
  height: 32px;
  margin-bottom: 17px;
  @media (max-width: 750px) {
    //css changed
    display: block;
    margin-left: auto;
    margin-right: 33px;
  }
  .favourite-search-label {
    height: 32px;
    background-color: #242529;
    div {
      display: inline-block;
      margin: 3px 8px 0px 9px;
      i {
        color: #6b7883 !important;
        font-size: 16px;
      }
    }
    .favourite-search-box {
      width: 240px;
      height: 32px;
      position: absolute;
      color: #ddd;
      background-color: #242529;
      border: none;
      padding-bottom: 2px;
    }
    .favourite-search-box:focus {
      background-color: #242529;
      color: white;
    }
  }
  .favourite-search-label_packs {
    height: 32px;
    background-color: #242529;
    div {
      display: inline-block;
      margin: 3px 8px 0px 9px;
      i {
        color: #6b7883 !important;
        font-size: 16px;
      }
    }
    .favourite-search-box {
      width: 208px;
      height: 32px;
      position: absolute;
      color: #ddd;
      background-color: #242529;
      border: none;
      padding-bottom: 2px;
    }
    .favourite-search-box:focus {
      background-color: #242529;
      color: white;
    }
  }
}

.favourite-search-bar-form {
  &:hover {
    .favourite-search-box {
      color: #ddd;
    }
    .zdv3-icon-search {
      color: #ddd !important;
    }
  }
  &:focus-within {
    .favourite-search-box {
      color: #ddd;
    }
    .zdv3-icon-search {
      color: #ddd !important;
    }
  }
}

.zd-album-table {
  border-spacing: 0 2px;
  text-align: left;
  width: 100%;
  table-layout: fixed;
  // border-right: 12px solid transparent;
  .zd-small-album-cell {
    // background-color: #1c1e23 !important;
    border-radius: 2px !important;
  }
  &.zd-table-hover {
    border-right: 2px solid transparent;
    tbody tr:hover {
      // border-right: 2px solid #4F7BE9;
      background-color: $table_tr_hover;
      // border-right: 12px solid #4F7BE9;
      // border-top-right-radius: 12px;
      // border-bottom-right-radius: 12px;
      .zd-item-play {
        color: $white;
        .zd-icon-repeat-track {
          color: $white;
        }
      }
      // .zdv3-icon-drop-down {
      //   color: $white !important;
      // }
    }
    //to add color in favourite icon in favourite history
    .zd-favourite-history {
      .zd-album-favourite {
        i {
          color: #4f7be9 !important;
        }
      }
    }

    .zd-table-hover-color {
      .zd-selected-row {
        background-color: $zd_selected !important;
        border-bottom: 0px !important;
        // border-right: 2px solid #4F7BE9;
        // border-radius: 2px;
      }
      tbody tr {
        background-color: #3a3c3e87;
        .zd-item-play {
          color: #4d7ce8 !important;
          .zd-icon-repeat-track {
            color: $gray-60;
          }
        }
      }
      tbody tr:hover {
        background-color: $table_tr_hover;
        .zd-item-play {
          color: $white !important;
          .zd-icon-repeat-track {
            color: $white;
          }
        }
      }
    }
  }

  .zd-single-release-hover:hover {
    background-color: #303139;
    .zd-item-play {
      color: $white;
      .zd-icon-repeat-track {
        color: $white;
      }
    }
  }
  tr {
    display: table-row;
    &:focus {
      outline: none;
    }
  }
  td {
    display: table-cell;
  }
  td,
  th {
    border-top: none;
    padding: 0 0.45rem;
    vertical-align: middle;
  }
  thead {
    background-color: $table_thead;
    th,
    td {
      border: none;
    }
    th {
      text-transform: uppercase;
      padding: 1.15rem 0.75rem;
      vertical-align: top;
    }
  }
  tbody {
    tr {
      background-color: $table_tr;
      border-bottom: 1px solid $table_tr_border;
      -webkit-transition: background-color 0.02s ease-in-out;
      transition: background-color 0.02s ease-in-out;
      td {
        a {
          vertical-align: middle;
          cursor: pointer;
          &:hover {
            // text-decoration: underline;
          }
        }
      }
    }
    .zd-top-download-count {
      margin-right: 0;
      display: inline-block;
      color: $gray-80;
      // float: left;
      display: block;
      min-width: 20px;
      text-align: center;
    }
    .zd-count {
      margin-right: 0;
      display: inline-block;
      color: $gray-80;
      float: left;
      display: block;
      min-width: 20px;
      text-align: center;
      display: none;
    }
    .zd-item-play {
      color: map-get($map: $theme-colors, $key: primary);
      font-size: 1.4rem;
      line-height: 0rem;
      // padding: 0 4px;
      float: left;
      display: block;
      .zd-icon-repeat-track {
        color: $gray-60;
      }
      &:hover {
        color: $white !important;
        text-decoration: none;
        .zd-icon-repeat-track {
          color: $gray-60;
        }
      }
    }
    .zd-icons {
      a {
        color: $white;
        &:hover {
          text-decoration: none;
          // opacity: 0.7;
        }
      }
    }
    .zd-double-icons {
      text-align: center;
      padding-right: 17px;
      span {
        padding-right: 4px;
      }
      a {
        font-size: 24px;
        @media (min-width: 750px) {
          padding: 10px 6px;
        }
      }
      a:hover {
        i {
          color: $white;
        }
      }
    }
    .zd-tag-btn {
      font-size: 16px;
      color: $white;
      display: none;
      &:hover {
        color: map-get($map: $theme-colors, $key: primary) !important;
      }
    }
    @media (max-width: 1024px) and (min-width: 0px) {
      .zd-download-btn {
        font-size: 1rem;
        line-height: 1rem;
        padding-right: 0px;
      }
    }
    @media (min-width: 1024px) {
      .zd-download-btn {
        font-size: 1rem;
        line-height: 1rem;
        padding-right: 13px;
      }
    }
    .zd-item-favourite {
      color: $white;
      // line-height: 1.2rem;
      font-size: 0.9rem;
      line-height: 1.5rem;

      float: left;
      display: block;
      &:hover {
        color: $white;
        text-decoration: none;
      }
    }
    .zd-item-plus {
      color: $white;
      // line-height: 1.2rem;
      font-size: 0.9rem;
      line-height: 1.5rem;

      float: left;
      display: block;
      &:hover {
        color: $white;
        text-decoration: none;
      }
    }
    .zd-album-title {
      padding-left: 10px;
      font-weight: 400;
      padding: 0;
      line-height: 1.6rem;
      color: #eaeaea;
      // width: calc(100% - 88px);
      // float: left;
      display: block;
      cursor: default;
      span {
        cursor: pointer;
        padding-right: 2px;
      }
      &:hover {
        text-decoration: none;
      }
    }

    .text-muted {
      color: $gray-75 !important;
    }
    .zd-filter-text {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.zd-new-newreleases-table {
  .zd-album-table {
    .zd-album-title {
      @media (max-width: 414px) {
        margin-left: 6px;
      }
    }
  }
}

.zd-album-column {
  overflow: hidden;
  padding: 0 !important;
}
.zd-display-table {
  display: table;
}

.zd-fixed-table {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
  width: 100%;
  table-layout: fixed;
  display: table;
  margin: 0;
  tbody {
    tr {
      -webkit-transition: background-color 0.02s ease-in-out;
      transition: background-color 0.02s ease-in-out;
      display: table-row;
      border: none;
      td {
        display: table-cell;
        border-top: none;
        padding: 0 0.5rem;
        vertical-align: middle;
        border-collapse: collapse;
      }
    }
  }
}

.zd-tag-label {
  color: #7685b3;
  padding: 1px 6px;
  background: #2a3043;
  margin: 0.5rem 0.2rem 0.5rem 0;
  cursor: pointer;
}
.zd-selected {
  background-color: $zd_selected !important;
}
.zd-selected-row {
  .zd-album-table tbody tr {
    background-color: $zd_selected !important;
  }
  &.zd-single-release-hover {
    background-color: $zd_selected !important;
  }
  &.zd-parent-table {
    background-color: $zd_selected !important;
  }
  .zd-item-play,
  .zd-item-plus .zd-item-favourite {
    color: $white;
    .zd-icon-repeat-track {
      color: $white;
    }
  }
}

.dmw-table,
.zd-trending-table {
  .zd-inner-table {
    tbody {
      tr {
        td {
          padding: 0.05rem 0.45rem;
          &:first-child {
            padding-left: 15px;
          }
        }
      }
    }
  }
}

.zd-table-head {
  background: $gray-900;
  .zd-fixed-table {
    tbody {
      tr {
        td {
          vertical-align: top;
        }
      }
    }
  }
  .zd-album-column {
    .zd-album-table {
      background: $table_thead;
      tbody {
        tr {
          background: $gray-900;
          &:hover {
            // background-color: transparent !important;
          }
          td {
            padding: 0.5rem 0.45rem;
            color: $white;

            &.zd-pl-88 {
              padding-left: 88px;
            }
            &.zd-pl-78 {
              padding-left: 62px;
            }
            &.zd-pl-18 {
              padding-left: 18px;
            }
            .zd-filter-text {
              cursor: pointer;
              &:hover {
                text-decoration: none;
                cursor: pointer;
              }
            }
            a {
              // color: #47484A;
              // color: #434b52; //abinash change
              color: #4a535a;
              padding: 0;
              font-weight: 500;
              &:hover {
                text-decoration: none;
                // opacity: 0.7;
              }
              &.sort-active {
                border-bottom: 2px solid $zd_selected;
                padding-bottom: 9px;
                margin-bottom: -11px;
              }
            }
          }
        }
      }
    }
  }
}

.zd-inner-table {
  margin: 0;
}

.zd-album-table {
  font-size: 12px;
  font-weight: 300;
}

// .zd-feedback-release-highlight-border {
//   position: unset !important;
// }

.zd-text-d8d8d8 {
  color: #d8d8d8 !important;
}
.zd-btn-4f7be9 {
  background-color: #4f7be9 !important;
  color: #ddd;
  &:hover {
    color: #ddd !important; //css changed
  }
}

.zt-btn-25262a {
  background-color: #25262a !important;
}
.zd-text-6b7883 {
  // color: #6b7883 !important; abinash change
  color: #768492 !important;
  // white-space: nowrap;
}
.zd-text-4f7be9 {
  color: #4f7be9 !important;
}
#zd-datelist-span {
  margin-left: 8px;
  margin-top: 3px;
}

.zd-text-47484a {
  color: #47484a !important;
}

.zd-text-434b52 {
  color: #434b52 !important;
}

.zd-text-dddddd {
  color: #dddddd !important;
}
.zd-text-8e8e8e {
  color: #768492 !important; // abinash change
}

.zd-text-4d7ce8 {
  color: #4d7ce8 !important;
}

.zd-text-ffffff {
  color: #ffffff !important;
}

.release-name {
  color: #dddddd;
  font-weight: 500 !important;
}

.zd-breadcrumb {
  margin-top: -6px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
}
body .zd-pl-120 {
  padding-left: 120px !important;
}
body .zd-pl-50 {
  padding-left: 50px !important;
}
body .zd-pl-40 {
  padding-left: 40px !important;
}
body .zd-pl-30 {
  padding-left: 30px !important;
}
body .zd-pl-25 {
  padding-left: 25px !important;
}
body .zd-pl-20 {
  padding-left: 20px !important;
}
body .zd-pl-15 {
  padding-left: 15px !important;
}
body .zd-pl-10 {
  padding-left: 10px !important;
}

.zd-font-10 {
  font-size: 10px;
}
.zd-font-12 {
  font-size: 12px;
}
.zd-font-15 {
  font-size: 15px;
}
.zd-font-18 {
  font-size: 18px;
}
.zd-font-22 {
  font-size: 22px;
}
.zd-font-24 {
  font-size: 24px;
}
.zdv3-icon-play {
  font-size: 1.4rem;
}
.zd-favourite-blue {
  color: blue !important;
}

.zdv3-icon-download:hover {
  color: #dddddd !important;
}
.zd-body-row-table {
  background-color: #1d1b22;
  tbody {
    tr {
      td {
        // border-bottom: 1px solid $table_tr_border;
        // border-bottom: 1px solid #1f1f21;
        height: 40px;
      }
    }
  }
}

.zd-packs-page {
  .zd-fixed {
    position: inherit;
  }
}

.zd-fixed {
  top: 185px !important;
  //position: fixed;
  line-height: normal;
  padding: 0;
  z-index: 999;
}
.zd-no-header {
  .zd-fixed {
    top: 94px !important;
  }
}

.download-manager-page {
  .zd-table-head,
  .zd-table-head .zd-album-column .zd-album-table tbody tr {
    background: $gray-1400;
  }
  .zd-content-head.zd-fixed-header {
    padding-bottom: 2px; //58px;
  }
}

.zd-packs-page {
  .zd-content-head.zd-fixed-header {
    padding-bottom: 35px;
  }
}

.zd-packs-page {
  .zd-content-head.zd-fixed-header {
    padding-bottom: 2px; // added new css by pramod for header scroll
  }
}

/* Release View  */
.zd-releases-view {
  .zd-newreleases-table {
    .zd-border-10 {
      border-bottom: 8px solid $gray-1400;
    }
    .zd-selected-row {
      background-color: $zd_selected !important;
      .zd-item-play,
      .zd-item-plus,
      .zd-item-favourite {
        color: $white;
        .zd-icon-repeat-track {
          color: $white;
        }
      }
    }
    tbody tr:hover,
    tbody tr {
      background-color: transparent;
      .zd-item-play {
        color: map-get($map: $theme-colors, $key: primary);
        .zd-icon-repeat-track {
          color: $gray-60;
          &:hover {
            color: $white;
          }
        }
      }
    }
  }
  .zd-text-47484A {
    color: #47484a !important;
  }
  .zd-float-icons {
    position: relative;
  }
  .zd-action-list {
    position: absolute;
    top: 0;
    right: 26px;
    height: 100%;
    padding: 3px 7px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    visibility: hidden;
    a.feedback,
    a.favourite {
      padding-right: 5px;
      .active {
        color: $link-color !important;
      }
    }
  }
  .zd-toggle-table {
    // position: absolute;
    // top: 0;
    // right: 0;
    // height: 100%;
    // padding: 3px 7px;
    // border-left: 1px solid #2c2d31;
    padding-top: 2px;
    vertical-align: middle;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    // &:hover a i{
    // color: #dddddd !important;
    // }
  }
}

.zd-table-hover {
  .zd-group-tr {
    &:hover {
      .zd-group-table tbody tr,
      .zd-group-items-table tbody tr {
        background-color: #28292e !important;
        &.zd-selected-row {
          background-color: $zd_selected !important;
        }
      }
    }
    .zd-group-single-row {
      &:hover {
        background-color: $group_table_tr_hover !important;
      }
    }
    .zd-group-items-table {
      .zd-table-body-row {
        border-right: 4px solid transparent;
        &:hover {
          border-right: 4px solid #4f7be9;
          background-color: $group_table_tr_hover !important;
          .zd-play-col a i {
            color: #dddddd !important;
          }
        }
      }
    }
    &:hover {
      .zd-group-single-row {
        &:hover {
          background-color: $group_table_tr_hover !important;
        }
      }
    }
  }
}

// .zd-group-items-table,
.zd-group-table {
  background-color: $group_table_tr;
  tbody tr {
    background-color: $group_table_tr !important;
    height: 48px;
    border-radius: 20px !important;
    overflow: hidden !important;
    &:hover {
      // background-color: $group_table_tr_hover!important;
      .zd-item-play {
        color: $white !important;
        .zd-icon-repeat-track {
          color: $white;
        }
      }
      .zd-action-list {
        visibility: visible;
      }
    }
    td {
      border-bottom: 0px solid $table_tr_border;
    }
  }
}

.zd-group-items-table {
  tbody tr {
    // border-bottom: 2px solid $table_tr_border;
    td {
      // border-bottom: 2px solid $table_tr_border;
    }
  }
}

.zd-group-table {
  tbody {
    .album-title .zd-album-title {
      font-weight: 700;
    }
    .album-title .zd-album-title-lm-0 {
      font-weight: 700;
    }
    .album-name {
      // margin-top: 6px;
      color: #b2b2b2;
    }
    .zd-album-name {
      margin-left: 10px;
      font-weight: 400;
      color: #b2b2b2;
      width: calc(100% - 88px);
      float: left;
      display: block;
      cursor: default;
      span {
        cursor: pointer;
      }
    }
  }
}

.zd-group-items-table {
  tbody tr {
    height: 40px;
  }
}

.zd-album-table {
  .zd-info-row {
    background-color: #2c2c2e !important;
    -webkit-transition: all 2s linear;
    transition: all 2s linear;
    .zd-info-cell {
      padding: 10px 15px;
    }
    .track-info-block {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding: 5px 0;
      .track-info-img {
        width: 200px;
        img {
          width: 100%;
        }
      }
      .track-info-desc {
        width: calc(100% - 200px);
        padding: 5px 0 5px 15px;
        .album-title {
          color: $white;
          font-weight: 700;
          cursor: pointer;
          &:hover {
            color: $link-color;
          }
        }
        .album-name {
          margin-top: 8px;
          color: #b2b2b2;
        }
        .album-description {
          white-space: normal;
          margin-top: 8px;
          line-height: normal;
          color: $gray-80;
        }
      }
    }
  }
}

.zd-action-visible {
  visibility: visible !important;
  a.zd-info-toggle {
    color: $link-color !important;
  }
}
.zd-open-row {
  display: table-row !important;
}

.zd-child-album-art {
  opacity: 0.5;
  border-radius: 2px;
}

body {
  .zd-album-column {
    .zd-rank-column {
      // float: left;
      // width: 40px;
      font-size: 14px;
      color: #6b7883;
      text-align: center;
      font-weight: 400;
      // padding-top: 10px;
    }
  }

  .tracking {
    position: relative;
    .zd-album-art {
      position: relative;
    }
    .zd-player-thumb {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 44px;
      color: $white;
      z-index: 1;
      background: rgba(0, 0, 0, 0.5);
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      svg {
        margin-top: -10px;
      }
    }
    .zd-release-thumb {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 25px;
      color: $white;
      max-height: 40px;
      min-height: 40px;
      width: 40px;
      z-index: 1;
      background: rgba(0, 0, 0, 0.6);
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      svg {
        margin-top: 0;
      }
    }
    &:hover {
      .zd-player-thumb,
      .zd-release-thumb {
        opacity: 1;
      }
    }
  }
  .zd-player-tr {
    &:hover {
      .zd-player-thumb,
      .zd-release-thumb {
        opacity: 1;
      }
    }
  }
}

.zd-otherinfo-table {
  border-spacing: 0px 2px;
  border-collapse: separate;
  .zdv4-djinfo-icons {
    i {
      font-size: 10px;
      color: #6b7883;
    }
    span {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #6b7883;
    }
  }
  .zd-djinfo-action {
    padding: 12px 12px 12px 0px;
    div {
      margin-right: 10px;
    }
  }

  td {
    border-top: unset;
  }
  .table-head {
    background: transparent !important;
    td {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #434b52;
    }
  }
  .table-content {
    td {
      &:first-child,
      &:last-child {
        border-radius: 2px 0px 0px 2px;
      }
    }
    background: #242529 !important;
    .zd-djinfo-name,
    .zd-djinfo-type,
    .zd-djinfo-city,
    .zd-djinfo-radio-type,
    .zd-djinfo-show-name,
    .zd-djinfo-url {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #dddddd;
    }
    .zd-djinfo-date,
    .zd-djinfo-nights,
    .zd-djinfo-capacity,
    .zd-djinfo-radio-station,
    .zd-djinfo-show-type {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #6b7883;
    }
  }
  .zd-djinfo-name,
  .zd-djinfo-radio-type {
    width: 45%;
  }

  .zd-djinfo-nights,
  .zd-djinfo-type,
  .zd-djinfo-show-name,
  .zd-djinfo-radio-station {
    width: 15%;
  }

  .zd-djinfo-columnist,
  .zd-djinfo-reviewer {
    width: 16.5%;
  }
  .zd-djinfo-city,
  .zd-djinfo-capacity,
  .zd-djinfo-url,
  .zd-djinfo-show-type {
    width: 10%;
  }
  .zd-hide-mob {
    @media (max-width: 768px) {
      display: none;
    }
  }
}

body {
  .zd-info-table {
    background-color: transparent;
    .table-head {
      // background: $zd_profile;
      background: #191b1f !important;
      td,
      th {
        border-top: none;
      }
    }
    tr {
      background: transparent;
      &:hover {
        background: #252831;
      }
    }
    .table-footer {
      border-bottom: 1px solid #444;
    }
    .zd-update,
    .zd-delete {
      font-size: 14px;
      float: right;
      margin-right: 28%;
      margin-top: -22px;
    }
    .zd-add-btn {
      font-size: 14px;
      line-height: 20px;
      padding-right: 10px;
    }
    .zd-print-icon-link {
      font-size: 24px;
      line-height: 20px;
      cursor: pointer;
    }
  }
}
.zd-track-info-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 5px 0;
  .zd-track-info-img {
    width: 170px;
    height: 170px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .zd-track-info-desc {
    width: calc(100% - 170px);
    padding: 0px 0 5px 15px;
    .artistName,
    .labelName {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .zd-back-url {
    color: $white;
    position: relative;
    cursor: pointer;
    margin-right: 5px;
    float: right;
    font-size: 18px;
    &:hover {
      color: map-get($map: $theme-colors, $key: primary);
    }
  }
}
.zd-releases-view {
  .zd-release-view-tab {
    // padding-left: 13px !important;
    // padding-right: 13px !important;
    // padding-top: 1rem;
    // @media (min-width: 1440px) {
    //   padding-top: 0.9rem !important;
    // }
  }
}
.zd-track-view {
  &.zd-search-track-view {
    @media (min-width: 1440px) {
      padding-top: 1rem !important;
    }
  }
}
.zd-track-view {
  .all-packs-crate {
    @media (max-width: 750px) {
      .zd-dropdown-crate {
        margin-left: 17px;
      }
    }
  }
  .zd-album-table {
    @media (max-width: 750px) {
      .zd-table-head {
        .zd-filter-text {
          display: none;
        }
        .zd-artist {
          display: none;
        }
      }
    }
  }
}

.zd-chrome-track-header,
.sk-results-list,
.release-view-page {
  .zd-release-view-tab {
    // padding-left: 13px !important;
    // padding-right: 13px !important;
    // padding-top: 1.25rem;
    // @media (min-width: 1440px) {
    //   padding-top: 1.2rem !important;
    // }
  }
  .zd-release-space {
    @media (min-width: 1440px) {
      margin-top: 3.5px !important;
    }
  }
  .zd-track-view {
    // padding-left: 13px !important;
    // padding-right: 13px !important;
    // padding-top: 15px;
    .zd-album-table tr,
    .zd-table-head .zd-album-column .zd-album-table tbody tr {
      // background: $table_tr;
      &:hover {
        // background: $table_tr_hover !important;
      }
    }
    .zd-album-table tbody tr {
      // border-bottom: none;
    }
    .zd-body-row-table {
      tbody tr {
        // border-bottom: 1px solid $group_table_border;
        td {
          // border-bottom: none;
        }
      }
    }
  }
}
.zd-release-space {
  .zd-favourite {
    .zd-album-favourite {
      @media (min-width: 750px) {
        // display: none !important;
      }
    }
  }
}

.zd-track-view-row {
  &:hover {
    background-color: $group_table_tr_hover !important;
  }
}
.zd-search-table {
  background-color: transparent !important;
}
.zd-tr-color {
  // background: $gray-1400 !important;
  background: #191b1f !important;
  // &:hover {
  //   background: $gray-1400 !important;
  // }
}
.zd-search-minimal {
  .zd-search-layout {
    margin-bottom: 10px;
  }
  table.zd-minimal-table {
    margin: 10px 0px;
    tbody {
      tr {
        border-bottom: none;
        td {
          padding: 0px;
          height: 40px;
          border-bottom: none;
        }
      }
    }
    .zd-icon {
      font-size: 0.7em;
      line-height: 1.43em;
    }
    .zd-item-play {
      // color: $white;
    }
    .zd-player-icon-download {
      font-size: 16px;
      line-height: 22px;
    }
    .zd-album-table tbody {
      .zd-album-title {
        width: calc(100% - 32px);
        margin-left: 6px;
      }
      .zd-icons {
        width: 24px !important;
        @media (max-width: 750px) {
          width: 100% !important;
          display: table-cell !important;
        }
        .zd-download-btn {
          @media (max-width: 750px) {
            margin-top: -5px;
          }
        }
      }
      tr {
        border-bottom: none;
      }
      tr:hover {
        background-color: $group_table_tr;
      }
      .zd-release-favourite {
        @media (max-width: 750px) {
          display: none !important;
        }
      }
    }
  }
}

.zd-genre-container {
  // background-color: $gray-1100 !important;
  margin-bottom: 40px !important;
  // padding-top: 10px !important;
  padding-left: 13px !important;
  padding-right: 13px !important;
  border-left: 3px solid map-get($map: $theme-colors, $key: primary);
  // border-bottom: 1px solid #1a1a1c !important;
  .zd-border-10 {
    &:last-child {
      border: none !important;
    }
  }
  //
  //   border-bottom: 10px solid $gray-1100 !important;
  // }
}
.zd-table-no-bg {
  background-color: transparent !important;
}

.zd-table-hover-color {
  .zd-selected-row {
    background-color: $zd_selected !important;
  }
  tbody tr {
    background-color: #28292e !important;
  }
  tbody tr:hover {
    background-color: $table_tr_hover !important;
    .zd-item-play {
      color: $white !important;
      .zd-icon-repeat-track {
        color: $white;
      }
    }
  }
}

.zd-feedback-row {
  border-bottom: 2px solid $zd_selected !important;
  background-color: #28292e !important;
  &.zd-table-body-row {
    background-color: rgba(58, 60, 62, 0.45) !important;
    &:hover {
      background-color: rgba(58, 60, 62, 0.45) !important;
      border-right: 2px solid $zd_selected #4f7be9;
    }
  }
  &:hover {
    background-color: rgba(58, 60, 62, 0.45) !important;
  }
}

.zd-selected-row .zd-album-table tbody {
  .zd-feedback-row {
    border-bottom: 2px solid $zd_selected !important;
    background-color: #28292e !important;
    &.zd-table-body-row {
      background-color: #28292e !important;
    }
    &:hover {
      background-color: #28292e !important;
    }
  }
}

// .zd-feedback-release-highlight {
//   border: 2px solid $zd_selected !important;
//   border-bottom: none !important;
// }

.zd-feedback-release-highlight-border {
  // border: 2px solid $zd_selected !important;
  border-top: none !important;
}

.zd-pack-feedback-release-highlight-border {
  border: 2px solid $zd_selected !important;
  border-top: none !important;
}

// .zd-feedback-row-highlight-border {
//   // border-left: 2px solid $zd_selected !important;
//   // border-right: 2px solid $zd_selected !important;
//   &::after {
//     content: "";
//     position: absolute;
//     top: 0;
//     right: 0;
//     height: 100%;
//     width: 4px;
//     background-color: #4f7be9;
//     border-top-right-radius: 2px;
//     border-bottom-right-radius: 2px;
//   }
// }

.zd-track-view {
  .zd-play-col {
    width: 40px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .zd-track-cover-col {
    width: 40px;
  }
  .trending-chart-album {
    max-width: 83px !important;
    width: 83px !important;
  }
  .zd-new-newreleases-table {
    .zd-tr-color {
      .zd-release-play {
        display: none !important;
      }
    }
  }
  .zd-new-newrelease-track-view {
    .zd-tr-color {
      .zd-play-col {
        display: table-cell;
        @media (max-width: 961px) {
          display: table-cell !important;
        }
      }
    }
  }
  .zd-new-newrelease-track-view {
    .zd-title {
      @media (max-width: 414px) {
        width: 47% !important;
      }
    }
  }

  .zd-trendingchart-tbody {
    .zd-tr-color {
      // .zd-release-play {
      //   display: none;
      // }

      .zd-play-col {
        display: none;
        @media (max-width: 961px) {
          display: none !important;
        }
      }
    }
  }

  .zd-top-charts-table {
    .zd-tr-color {
      .zd-play-col {
        display: table-cell;
      }
    }
    .zd-width-48 {
      width: 48px;
    }
  }

  .zd-release-cover-col {
    padding: 0;
    background-color: #242529;
  }
  .zd-topdownloads-count {
    width: 40px;
    text-align: center;
    background-color: #242529;
  }
}

.zd-release-play {
  width: 48px;
}

.zd-width-48 {
  width: 73px;
}
.zd-width-40 {
  width: 40px;
}

.top-downloads-filter-btn {
  padding-left: 8px !important;
  padding-right: 8px !important;
  &.zd_v5 {
    padding-left: 12px !important;
  }
  &.dropdown_key {
    padding-left: 12px !important;
  }
}

.zd-release-play {
  padding-left: 14px !important;
}

.zd-filter-text {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.zd-album-table {
  .zd-table-body-row {
    background-color: #242529;
    border-bottom: 2px solid #1c1e22 !important;
    transition: background-color 0.02s ease-in-out;
  }
}

.zd-album-table tbody tr {
  border-bottom: 0px;
}

@media (min-width: 750px) and (max-width: 960px) {
  .zd-album-table .zd-topchart-list .zd-double-icons a {
    padding: 0 20px;
    font-size: 24px;
  }
}
//all style for single release view
.zd-single-release-page-view {
  tbody {
    tr {
      .zd-single-release-favourite {
        width: 24px;
        height: 24px;
        i {
          display: none;
        }
        .zd-single-release-favourite {
          .zdv3-icon-filled-favourite {
            display: block;
            color: #4d7ce8 !important;
          }
        }
      }
      &:hover {
        .zd-single-release-favourite a i {
          display: inline-block;
          // color: #6b7883 !important;
        }
      }
      .zd-single-release-favourite {
        .zdv3-icon-filled-favourite {
          display: block;
          color: #4d7ce8 !important;
        }
      }
    }
  }
}
.zd-singlepack-tbody {
  tr {
    .zd-single-release-favourite {
      i {
        display: none;
      }
    }
    &:hover {
      i {
        display: block;
      }
    }
  }
}

tbody {
  tr {
    .zd-singlepack-tbody {
      .zd-single-release-favourite {
        .zdv3-icon-filled-favourite {
          display: block;
          color: #4d7ce8 !important;
        }
      }
    }
  }
}

// All Styles for New Releases Table
table.zd-new-newreleases-page {
  tbody {
    tr {
      td {
        table.zd-new-newreleases-page-main-table {
          position: relative;
          background-color: transparent !important;
          tr {
            background-color: transparent !important;
            td {
              background-color: #242529 !important;
              &:first-child {
                border-top-left-radius: 2px;
                border-bottom-left-radius: 2px;
                overflow: hidden;
              }
              &:last-child {
                position: relative;
                border-top-right-radius: 2px;
                border-bottom-right-radius: 2px;
                overflow: hidden;
                &::after {
                  content: '';
                  position: absolute;
                  top: 0;
                  right: 0;
                  height: 100%;
                  width: 4px;
                  background-color: transparent;
                  border-top-right-radius: 2px;
                  border-bottom-right-radius: 2px;
                }
              }
            }
          }
          &:hover {
            tr {
              background-color: transparent !important;
              .zd-toggle-table a i {
                color: #dddddd !important;
              }
              td {
                background-color: #303139 !important;
                &:last-child {
                  &::after {
                    background-color: #4f7be9;
                  }
                }
              }
            }
          }
        }
        .zd-new-newreleases-page-child-table {
          background-color: transparent !important;
          border-collapse: separate;
          border-spacing: 0 2px;
          tr {
            background-color: transparent !important;
            border-right: none !important;
            border-bottom: 0 !important;
            td {
              background-color: #242529;
              &:first-child {
                border-top-left-radius: 2px;
                border-bottom-left-radius: 2px;
                overflow: hidden;
              }
              &.zd-release-favourite {
                width: 24px;
                height: 24px;
                i {
                  display: none;
                }
              }
              &.zd-release-favourite {
                .zdv3-icon-filled-favourite {
                  display: block;
                  color: #4d7ce8 !important;
                }
              }
              // &.zd-release-crates {
              //   width: 20px;
              //   height: 20px;
              //   i {
              //     color: #6b7883;
              //   }
              //   .crate-dropdown-menu {
              //     position: absolute;
              //     width: 161px;
              //     // position: absolute;
              //     // will-change: transform;
              //     top: 20px !important;
              //     margin: 0px 0px 0px 0px !important;
              //     padding: 0px 0px !important;
              //     transform: translate3d(-144px, 7px, 0px) !important;
              //     .dropdown-item {
              //       // padding-left: 54px;
              //       .dropdown-right-icon {
              //         padding-left: 54px !important;
              //         font-size: 10px !important;
              //       }
              //     }
              //     .follow-crate-dropdown {
              //       // will-change: transform;margin: 0px 0px 0px 0px !important;
              //       padding: 0px 0px !important;
              //       transform: translate3d(-165px, -2px, 0px) !important;
              //     }
              //     .crate-playlist-dropdown {
              //       width: 161px;
              //       height: 200px;
              //       overflow-y: auto;
              //       transform: translate3d(-165px, -2px, 0px) !important;
              //       .dropdown-add-icon {
              //         font-size: 11px !important;
              //       }
              //     }
              //   }
              // }

              &:last-child {
                position: relative;
                border-top-right-radius: 2px;
                border-bottom-right-radius: 2px;
                // overflow: hidden;
                &::after {
                  content: '';
                  position: absolute;
                  top: 0;
                  right: 0;
                  height: 100%;
                  width: 4px;
                  background-color: transparent;
                  border-top-right-radius: 2px;
                  border-bottom-right-radius: 2px;
                }
              }
            }
            &:hover {
              .zd-play a i {
                color: #dddddd !important;
              }
              td {
                background-color: #303139 !important;
                &:last-child {
                  &::after {
                    background-color: #4f7be9;
                  }
                }
              }
              .zd-release-play a i {
                color: #dddddd !important;
              }
              .zd-release-favourite a i {
                display: inline-block;
                color: #6b7883 !important;
              }
              .zd-release-favourite {
                .zdv3-icon-filled-favourite {
                  display: block;
                  color: #4d7ce8 !important;
                }
              }
              .zd-release-crates {
                .zdv3-icon-Crates {
                  // color: #4f7be9;
                  color: #dddddd;
                }
              }
            }
          }
        }

        &:hover {
          table.zd-new-newreleases-page-main-table {
            tr {
              td {
                background-color: #28292e !important;
              }
            }
            &:hover {
              tr {
                td {
                  background-color: #303139 !important;
                }
              }
            }
          }
          .zd-new-newreleases-page-child-table {
            tr {
              td {
                background-color: #28292e;
              }
              &:hover {
                td {
                  background-color: #303139 !important;
                }
              }
            }
          }
        }
      }
      td.zd-feedback-release-highlight {
        table {
          tbody {
            tr {
              td {
                &:last-child::after {
                  content: '';
                  position: absolute;
                  top: 0;
                  right: 0;
                  height: 100%;
                  width: 4px;
                  background-color: #4f7be9;
                  border-top-right-radius: 2px;
                  border-bottom-right-radius: 2px;
                }
                background-color: #28292e !important;
              }
              &:hover {
                background-color: #28292e !important;
              }
              &.zd-feedback-row {
                td {
                  &:last-child::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 100%;
                    width: 4px;
                    background-color: transparent !important;
                    border-top-right-radius: 2px;
                    border-bottom-right-radius: 2px;
                  }
                  background-color: #28292e !important;
                }
                &:hover {
                  background-color: #28292e !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
// All Styles for TOP DOWNLOADS
.zd-new-top-downloads-main-table {
  background: transparent;
  border-right: 0 !important;
  tbody {
    tr {
      background: transparent;
      td {
        background: transparent !important;
        border: 0 !important;
        table.zd-new-top-downloads-parent-table {
          // background-color: transparent !important;
          border-collapse: separate;
          border-spacing: 0 1.5px;
          background-color: transparent;
          cursor: pointer;
          tbody {
            tr {
              // background-color: transparent !important;
              border-bottom: 0 !important;
              .zd-topdownloads-count {
                background-color: transparent;
              }
              td {
                background-color: #242529 !important;
                &:first-child {
                  border-top-left-radius: 2px;
                  border-bottom-left-radius: 2px;
                  overflow: hidden;
                }
                &:last-child {
                  position: relative;
                  border-top-right-radius: 2px;
                  border-bottom-right-radius: 2px;
                  overflow: hidden;
                  &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 100%;
                    width: 4px;
                    background-color: transparent;
                    border-top-right-radius: 2px;
                    border-bottom-right-radius: 2px;
                  }
                }
                .zd-album-favourite {
                  width: 24px;
                  i {
                    display: none;
                  }
                }
                .zd-album-favourite {
                  .zdv3-icon-filled-favourite {
                    display: block;
                    color: #4d7ce8 !important;
                  }
                }
              }
            }
            &:hover {
              td {
                background-color: #303139 !important;
                span {
                  .crate-icon {
                    i {
                      color: #dddddd !important;
                    }
                  }
                }
                &:last-child {
                  &::after {
                    background-color: #4f7be9;
                  }
                }
                .zd-album-favourite {
                  i {
                    display: block;
                    color: #6b7883 !important;
                  }
                }
                .zd-album-favourite {
                  .zdv3-icon-filled-favourite {
                    display: block;
                    color: #4d7ce8 !important;
                  }
                }
              }
            }
          }
        }
        table.zd-new-top-downloads-child-table {
          background-color: transparent !important;
          border-collapse: separate;
          border-spacing: 0 2px;
          margin-top: -1px;
          margin-bottom: -1px;
          tbody {
            tr {
              background-color: transparent !important;
              user-select: none;
              .zd-topdownloads-count {
                background-color: transparent;
                @media (max-width: 750px) {
                  display: none !important;
                }
              }
              td {
                background-color: #242529 !important;
                // color: #8e8e8e !important;

                &:first-child {
                  border-top-left-radius: 2px;
                  border-bottom-left-radius: 2px;
                  overflow: hidden;
                }
                &:last-child {
                  position: relative;
                  border-top-right-radius: 2px;
                  border-bottom-right-radius: 2px;
                  overflow: hidden;
                  &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 100%;
                    width: 4px;
                    background-color: transparent;
                    border-top-right-radius: 2px;
                    border-bottom-right-radius: 2px;
                  }
                }
                .zd-album-favourite {
                  width: 24px;
                  i {
                    display: none;
                  }
                }
                .zd-album-favourite {
                  .zdv3-icon-filled-favourite {
                    display: block;
                    color: #4d7ce8 !important;
                  }
                }
              }
              &:hover {
                td {
                  background-color: #303139 !important;
                  &:last-child {
                    &::after {
                      background-color: #4f7be9;
                    }
                  }
                  .zd-album-favourite {
                    i {
                      display: block;
                      color: #6b7883 !important;
                    }
                  }
                  .zd-album-favourite {
                    .zdv3-icon-filled-favourite {
                      display: block;
                      color: #4d7ce8 !important;
                    }
                  }
                }
              }
            }
          }
        }

        &:hover {
          table.zd-new-top-downloads-parent-table {
            tr {
              td {
                background-color: #28292e !important;
              }
            }
            &:hover {
              tr {
                td {
                  background-color: #303139 !important;
                }
                // .feedback-td {
                //   background-color: #28292e !important ;
                // }
              }
            }
          }
          .zd-new-top-downloads-child-table {
            tr {
              td {
                background-color: #28292e !important;
              }
              &:hover {
                td {
                  background-color: #303139 !important;
                }
                .zd-child-album-art {
                  opacity: 1 !important;
                }
              }
            }
          }
        }
      }
      td.zd-feedback-row-highlight-border {
        table {
          tbody {
            tr {
              td {
                &:last-child::after {
                  content: '';
                  position: absolute;
                  top: 0;
                  right: 0;
                  height: 100%;
                  width: 4px;
                  background-color: #4f7be9;
                  border-top-right-radius: 2px;
                  border-bottom-right-radius: 2px;
                }
                background-color: #303139 !important;
              }
              &:hover {
                background-color: #303139 !important;
              }
              &.zd-feedback-row {
                td {
                  &:last-child::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 100%;
                    width: 4px;
                    background-color: transparent !important;
                    border-top-right-radius: 2px;
                    border-bottom-right-radius: 2px;
                  }
                  background-color: #303139 !important;
                }
                &:hover {
                  background-color: #303139 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

// All Styles for NEW RELEASES TRACK VIEW
.zd-new-newrelease-track-view {
  border-right: 0 !important;
  background-color: transparent;
  tbody {
    tr.zd-table-body-row {
      background-color: transparent !important;
      border-bottom: 0 !important;
    }
  }
}
table.zd-new-newreleases-page
  tbody
  tr
  td
  .zd-new-newreleases-page-child-table
  tr.currently-playing-track,
table.dualtrack__table tbody tr.currently-playing-track,
table tbody tr.hotkey-bg.currently-playing-track,
table tbody tr.currently-playing-track {
  background-color: #394671 !important;
  td {
    background-color: #394671 !important;
    // color: #8e8e8e !important;
    .zd-album-title {
      .release-name {
        // color: #8e8e8e !important;
      }
    }
  }
  &:hover {
    background-color: #878b96 !important;
    td {
      background-color: #354c85 !important;
    }
  }
}
.zd-new-top-downloads-main-table
  tbody
  tr
  td
  table.zd-new-top-downloads-parent-table
  tbody
  tr.currently-playing-track,
table.dualtrack__table tbody tr.currently-playing-track {
  background-color: #394671 !important;
  td {
    background-color: #394671 !important;
  }
  &:hover {
    background-color: #354c85 !important;
    td {
      background-color: #354c85 !important;
    }
  }
}

.zd-new-top-downloads-main-table
  tbody
  tr
  td
  table.zd-new-top-downloads-child-table
  tbody
  tr.currently-playing-track,
table.dualtrack__table tbody tr.currently-playing-track {
  background-color: #394671 !important;
  td {
    background-color: #394671 !important;
  }
  &:hover {
    background-color: #354c85 !important;
    td {
      background-color: #354c85 !important;
    }
  }
}

.zd-new-newrelease-track-view {
  .zd-new-newreleases-page-child-table {
    border-spacing: 0 1px !important;
  }
}

.currently-playing-track {
  .zd-album-title {
    .release-name {
      color: #dddddd !important;
    }
  }
  .zd-artist-name {
    color: #dddddd !important;
  }
  .trendingcharttrack__bpm span {
    color: #8e8e8e !important;
  }
  .trendingcharttrack__key span {
    color: #8e8e8e !important;
  }
  .trendingcharttrack__release span {
    color: #8e8e8e !important;
  }
  .zd-text-6b7883 {
    color: #8e8e8e !important;
  }
}

.zd-inner-table.zd-new-newreleases-page-child-table
  tbody
  tr.zd-row-newrelease.hotkey-bg {
  background-color: #303139 !important;
  td {
    background-color: #303139 !important;
  }
}

.zd-album-table.zd-inner-table.zd-new-newreleases-page-child-table
  tbody
  tr.hotkey-bg.currently-playing-track
  td {
  background: #394671 !important;
  &:hover {
    background-color: #354c85;
  }
}
.zd-album-table.zd-new-newrelease-track-view.zd-new-top-downloads-main-table
  .zd-new-top-downloads-parent-table
  tbody {
  tr.zd-row-newrelease.hotkey-bg,
  tr.zd-selectable-row.hotkey-bg {
    background-color: #303139 !important;
    td {
      background-color: #303139 !important;
    }
  }
}

.zd-album-table.zd-new-newrelease-track-view.zd-new-top-downloads-main-table
  .zd-new-top-downloads-parent-table
  tbody {
  tr.zd-row-newrelease.hotkey-bg.currently-playing-track td,
  tr.zd-selectable-row.hotkey-bg.currently-playing-track td {
    background: #394671 !important;
    &:hover {
      background-color: #354c85;
    }
  }
}

.zd-new-top-downloads-parent-table.zd-topdownloads-table {
  .zd-selectable-row.zd-row-topdownload.hotkey-bg {
    background-color: #303139 !important;
    td {
      background-color: #303139 !important;
    }
  }
  .zd-selectable-row.zd-row-topdownload.hotkey-bg.currently-playing-track td {
    background: #394671 !important;
    &:hover {
      background-color: #354c85;
    }
  }
}

.zd-new-top-downloads-child-table.zd-topdownloads-table.top_charts_child_visible {
  .zd-single-release-hover.zd-selectable-row.zd-row-topdownload.hotkey-bg {
    background-color: #303139 !important;
    td {
      background-color: #303139 !important;
    }
  }
  .zd-single-release-hover.zd-selectable-row.zd-row-topdownload.hotkey-bg.currently-playing-track
    td {
    background: #394671 !important;
    &:hover {
      background-color: #354c85;
    }
  }
}

.zd-new-top-downloads-parent-table.zd-trending-table {
  .zd-row-trendingcharts.zd-parent-table.hotkey-bg {
    background-color: #303139 !important;
    td {
      background-color: #303139 !important;
    }
  }
  .zd-row-trendingcharts.zd-parent-table.hotkey-bg.currently-playing-track td {
    background: #394671 !important;
    &:hover {
      background-color: #354c85;
    }
  }
}

.zd-new-newreleases-page-child-table.top_charts_child_visible {
  .zd-selectable-row.zd-row-topcharts.hotkey-bg {
    background-color: #303139 !important;
    td {
      background-color: #303139 !important;
    }
  }

  .zd-selectable-row.zd-row-topcharts.hotkey-bg.currently-playing-track {
    background: #394671 !important;
    &:hover {
      background-color: #354c85;
    }
    td {
      background: #394671 !important;
      &:hover {
        background-color: #354c85;
      }
    }
  }
}

.zd-display-table.zd-album-table {
  .zd-row-downloadhistory.zd-selectable-row.hotkey-bg {
    background-color: #303139 !important;
    td {
      background-color: #303139 !important;
    }
  }

  .zd-row-downloadhistory.zd-selectable-row.hotkey-bg.currently-playing-track {
    background: #394671 !important;
    &:hover {
      background-color: #354c85;
    }
    td {
      background: #394671 !important;
      &:hover {
        background-color: #354c85;
      }
    }
  }
}
.zd-display-table.zd-album-table {
  .zd-row-singlerelease.hotkey-bg {
    background-color: #303139 !important;
    td {
      background-color: #303139 !important;
    }
  }

  .zd-row-singlerelease.hotkey-bg.currently-playing-track {
    background: #394671 !important;
    &:hover {
      background-color: #354c85;
    }
    td {
      background: #394671 !important;
      &:hover {
        background-color: #354c85;
      }
    }
  }
}
.zd-display-table.zd-album-table {
  .zd-row-singlepacks-list.hotkey-bg {
    background-color: #303139 !important;
    td {
      background-color: #303139 !important;
    }
  }

  .zd-row-singlepacks-list.hotkey-bg.currently-playing-track {
    background: #394671 !important;
    &:hover {
      background-color: #354c85;
    }
    td {
      background: #394671 !important;
      &:hover {
        background-color: #354c85;
      }
    }
  }
}

.zd-display-table.zd-album-table {
  .zd-row-trending-packs.hotkey-bg {
    background-color: #303139 !important;
    td {
      background-color: #303139 !important;
    }
  }

  .zd-row-trending-packs.hotkey-bg.currently-playing-track {
    background: #394671 !important;
    &:hover {
      background-color: #354c85;
    }
    td {
      background: #394671 !important;
      &:hover {
        background-color: #354c85;
      }
    }
  }
}

.zd-display-table.zd-album-table {
  .zd-row-track-search-result.hotkey-bg {
    background-color: #303139 !important;
    td {
      background-color: #303139 !important;
    }
  }

  .zd-row-track-search-result.hotkey-bg.currently-playing-track {
    background: #394671 !important;
    &:hover {
      background-color: #354c85;
    }
    td {
      background: #394671 !important;
      &:hover {
        background-color: #354c85;
      }
    }
  }
}
.zd-display-table.zd-album-table {
  .zd-row-search-result.hotkey-bg {
    background-color: #303139 !important;
    td {
      background-color: #303139 !important;
    }
  }

  .zd-row-search-result.hotkey-bg.currently-playing-track {
    background: #394671 !important;
    &:hover {
      background-color: #354c85;
    }
    td {
      background: #394671 !important;
      &:hover {
        background-color: #354c85;
      }
    }
  }
}

.zd-new-top-downloads-child-table.zd-trending-table.trending_charts_child_visible {
  .zd-single-release-hover.zd-row-trendingcharts.hotkey-bg {
    background-color: #303139 !important;
    td {
      background-color: #303139 !important;
    }
  }
  .zd-single-release-hover.zd-row-trendingcharts.hotkey-bg.currently-playing-track {
    background: #394671 !important;
    &:hover {
      background-color: #354c85;
    }
    td {
      background: #394671 !important;
      &:hover {
        background-color: #354c85;
      }
    }
  }
}

.top_charts_child_invisible,
.top_downloads_child_invisible,
.trending_charts_child_invisible {
  display: none;
}

.top_charts_child_visible,
.top_downloads_child_visible,
.trending_charts_child_visible {
  display: table;
}

.zd-feedback-release-highlight
  .hide-active-border-p
  .zd-feedback-active-border {
  right: 0 !important;
}

.zd-home-sm-hide {
  @media (max-width: 750px) {
    display: none !important;
  }
}
.zd-home-tab-hide {
  @media (max-width: 1023px) {
    display: none !important;
  }
}
// .tab-header-hide {
//   @media (max-width: 961px) {
//     display: none;
//   }
// }

.zd-home-lg-hide {
  @media (min-width: 750px) {
    display: none !important;
  }
}
.zd-md-show {
  display: none;
  @media (min-width: 768px) and (max-width: 1224px) {
    display: block;
  }
}
.zd-md-hide {
  @media (min-width: 768px) and (max-width: 1224px) {
    display: none;
  }
}
@media only screen and (min-width: 0px) and (max-width: 1224px) {
  .zd-track-view .zd-title-new {
    width: 40%;
  }
}

.zd-profile-icon {
  font-size: 20px;
  margin-right: 12px;
  color: #6b7883;
}

.zdv3-icon-Instagram {
  font-size: 20px;
  margin-right: 12px;
  color: #6b7883;
}
.zdv3-icon-SoundClord {
  font-size: 20px;
  margin-right: 12px;
  color: #6b7883;
}
.zdv3-icon-Tweeter {
  font-size: 20px;
  margin-right: 12px;
  color: #6b7883;
}
.zdv3-icon-Linkedin {
  font-size: 20px;
  margin-right: 12px;
  color: #6b7883;
}
.zdv3-icon-Web {
  font-size: 20px;
  margin-right: 12px;
  color: #6b7883;
}
.zdv3-icon-Youtube {
  font-size: 20px;
  margin-right: 12px;
  color: #6b7883;
}
.zd-pre-page {
  display: flex;
  .form-check {
    margin-right: 30px;
    margin-bottom: 0;
    .form-check-input {
      margin-top: 0.1rem;
    }
  }
}

//top-chart page fevourite icon
.zd-top-charts-table {
  .zd-new-newreleases-page-header-table {
    .zd-large-album-cell {
      .zd-filter-text {
        @media (max-width: 750px) {
          display: table-cell !important;
        }
      }
    }
  }

  tbody {
    tr {
      .zd-new-newreleases-page-child-table {
        tbody tr {
          .zd-download-favourtie {
            .zd-album-favourite i {
              display: none;
            }
            .zd-album-favourite {
              .zdv3-icon-filled-favourite {
                display: block;
                color: #4d7ce8 !important;
              }
            }
          }

          &:hover {
            .zd-download-favourtie {
              .zd-album-favourite {
                i {
                  display: block;
                  color: #6b7883 !important;
                }
              }
              .zd-album-favourite {
                .zdv3-icon-filled-favourite {
                  display: block;
                  color: #4d7ce8 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
// track search result page table
.zd-search-result {
  tbody {
    tr {
      .zd-download-favourtie {
        text-align: center;
        .zd-album-favourite i {
          display: none;
        }
        .zd-album-favourite {
          .zdv3-icon-filled-favourite {
            display: block;
            color: #4d7ce8 !important;
          }
        }
      }
      &:hover {
        .zd-download-favourtie {
          .zd-album-favourite {
            i {
              display: block;
              color: #6b7883 !important;
            }
          }
          .zd-album-favourite {
            .zdv3-icon-filled-favourite {
              display: block;
              color: #4d7ce8 !important;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 750px) {
  .zd-search-page{
    .zd-title-new{
      .zd-v5_tack_name{
        display: none;
      }
      
    }
    .zd-hide{
      display: block !important;
    }
  }
}
//css for seach page
.zd-search-page {
  tbody {
    tr {
      .zd-download-favourtie {
        text-align: center;
        .zd-album-favourite i {
          display: none;
        }
        .zd-album-favourite {
          .zdv3-icon-filled-favourite {
            display: block;
            color: #4d7ce8 !important;
          }
        }
      }
      &:hover {
        .zd-download-favourtie {
          .zd-album-favourite {
            i {
              display: block;
              color: #6b7883 !important;
            }
          }
          .zd-album-favourite {
            .zdv3-icon-filled-favourite {
              display: block;
              color: #4d7ce8 !important;
            }
          }
        }
      }
    }
  }
}
//css for download History
.zd-downloadhistory-table {
  tbody {
    tr {
      .zd-release-favourite {
        text-align: center;
        .zd-album-favourite i {
          display: none;
        }
        .zd-album-favourite {
          .zdv3-icon-filled-favourite {
            display: block;
            color: #4d7ce8 !important;
          }
        }
      }
      &:hover {
        .zd-release-favourite {
          .zd-album-favourite {
            i {
              display: block;
              color: #6b7883 !important;
            }
          }
          .zd-album-favourite {
            .zdv3-icon-filled-favourite {
              display: block;
              color: #4d7ce8 !important;
            }
          }
        }
      }
    }
  }
}
//to shift the crate position and to display it over the component
.zd-tracklist-dropdown-box {
  position: absolute;

  // right: 23px;
  .zd-dropdown-crate {
    margin-top: 8px;
    @media (max-width: 750px) {
      margin-top: 13px;
    }
  }
}
.zd-download-history {
  position: absolute;

  // right: 23px;
  .zd-dropdown-crate {
    margin-top: 13px;
  }

  @media (min-width: 740px) and (max-width: 950px) {
    .zd-dropdown-crate {
      margin-top: 8px;
    }
  }
}
.zd-topdownload-dropdown-box {
  position: absolute;

  // right: 23px;
  .zd-dropdown-crate {
    margin-top: 19px;
    @media (max-width: 750px) {
      margin-top: 11px;
    }
  }
}
.zd-trending-chart-dropdown-box {
  position: absolute;

  // right: 23px;
  .zd-dropdown-crate {
    margin-top: 13px;
  }
}
.zd-top-chart-dropdown-box {
  position: absolute;
  // right: 29px;
  .zd-dropdown-crate {
    margin-top: 8px;
  }
}
.zd-home-trending-dropdown-box {
  position: absolute;
  // right: 43px;
  .zd-dropdown-crate {
    margin-top: 8px;
    @media (max-width: 750px) {
      margin-top: 11px;
    }
  }
}

@media (max-width: 750px) {
  .zd-singlepack-tbody {
    .zd-release-crates {
      .zd-dropdown-crate {
        margin-top: 7px;
      }
    }
    @media (min-width: 400px) {
      .zd-double-icons {
        padding-right: 8px !important;
      }
    }
  }
}

.zd-album-table .zd-trendingcharts-tbody .zd-double-icons a {
  padding: 4px 4px;
  font-size: 24px;
}

.zd-album-table
  .zd-trendingcharts-tbody
  .zd-download-favourtie
  .zd-album-favourite {
  display: block !important;
  margin-bottom: -4px !important;
}
.newrelease {
  .zd-home-trending-dropdown-box {
    position: absolute;
    // right: 43px;
    .zd-dropdown-crate {
      @media (max-width: 750px) {
        margin-top: 8px;
      }
    }
  }
}

@media (min-width: 0px) and (max-width: 750px) {
  .zd-favourite-dropdown-box {
    position: absolute;
    // right: 18px;
    .zd-dropdown-crate {
      margin-top: 9px;
    }
  }
}
.zd-favourite-dropdown-box {
  .zd-dropdown-crate {
    position: absolute;
  }
  .zd-dropdown-crate .crate-dropdown-menu {
    transform: translate3d(-129px, 7px, 0px);
  }
}
@media (min-width: 750px) and (max-width: 10000px) {
  .zd-favourite-dropdown-box {
    position: absolute;
    // right: 30px;
    .zd-dropdown-crate {
      margin-top: 13px;
    }
    @media (min-width: 750px) and (max-width: 950px) {
      .zd-dropdown-crate {
        margin-top: 8px;
      }
    }
  }
}

@media (min-width: 0px) and (max-width: 740px) {
  .zd-crate-dropdown-box {
    position: absolute;
    right: 18px;
    .zd-dropdown-crate {
      margin-top: 13px;
    }
  }
}

@media (min-width: 740px) and (max-width: 10000px) {
  .zd-crate-dropdown-box {
    position: absolute;
    right: 30px;
    .zd-dropdown-crate {
      margin-top: 13px;
    }
    @media (min-width: 740px) and (max-width: 950px) {
      .zd-dropdown-crate {
        margin-top: 8px;
      }
    }
  }
}

// .zd-release-dropdown-box{

//   right: 4px;
// }

// .crate-dropdown-menu {
//   transform: translate3d(-133px, 11px, 0px) !important;
// }

//modal for deleting playlist
.deleting_playlist_modal {
  width: 360px;
  height: 130px;
  .modal-footer {
    background: none !important;
  }
}
.zd-favourite {
  display: table-cell !important;
}

.zd-v5-single-packs {
  .head-line {
    display: flex;
    justify-content: space-between;
    .zd-slick-navs {
      margin-right: 33px;
    }
  }
}
.zd-v5-single-packs-inner {
  .zd-release-back {
    margin-right: 56px !important;
  }
}
.zd-topdownloads-count {
  @media (max-width: 750px) {
    display: none !important;
  }
}
