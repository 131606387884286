@font-face {
  font-family: 'zd-player-icon';
  src: url('../assets/fonts/zd-player-icon.eot?l09pg0');
  src: url('../assets/fonts/zd-player-icon.eot?l09pg0#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/zd-player-icon.ttf?l09pg0') format('truetype'),
    url('../assets/fonts/zd-player-icon.woff?l09pg0') format('woff'),
    url('../assets/fonts/zd-player-icon.svg?l09pg0#zd-player-icon')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='zd-player-icon-'],
[class*=' zd-player-icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'zd-player-icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.zd-player-icon-volume:before {
  content: '\e900';
  color: #fff;
}
.zd-player-icon-skip-previous:before {
  content: '\e901';
  color: #fff;
}
.zd-player-icon-skip-next:before {
  content: '\e902';
  color: #fff;
}
.zd-player-icon-shuffle-tracks:before {
  content: '\e903';
  color: #fff;
}
.zd-player-icon-repeat-track:before {
  content: '\e904';
  color: #fff;
}
.zd-player-icon-playlist-add:before {
  content: '\e905';
  color: #fff;
}
.zd-player-icon-keyboard:before {
  content: '\e906';
  color: #fff;
}
.zd-player-icon-play:before {
  content: '\e907';
  color: #fff;
}
.zd-player-icon-pause:before {
  content: '\e908';
  color: #fff;
}
.zd-player-icon-download:before {
  content: '\e909';
  color: #6b7883;
}

.zd-player-icon-download-newhomepage:before {
  content: "\e909";
  color: #575F66
}

.zd-player-feedback-icon-download:before {
  content: '\e909';
  color: #757372 !important;
}
