@font-face {
  font-family: icomoon;
  src: url(../assets/fonts/icomoon.eot?uve0uv);
  src: url(../assets/fonts/icomoon.eot?uve0uv#iefix) format("embedded-opentype"), url(../assets/fonts/icomoon.ttf?uve0uv) format("truetype"), url(../assets/fonts/icomoon.woff?uve0uv) format("woff"), url(../assets/fonts/icomoon.svg?uve0uv#icomoon) format("svg");
  font-weight: 400;
  font-style: normal
}

[class*=" ico-"], [class^=ico-] {
  font-family: icomoon !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.ico-music-16:before {
  content: "\e900"
}

.ico-music-15:before {
  content: "\e901"
}

.ico-commerce:before {
  content: "\e902"
}

.ico-shopping:before {
  content: "\e903"
}

.ico-new:before {
  content: "\e904"
}

.ico-music-14:before {
  content: "\e905"
}

.ico-multimedia:before {
  content: "\e906"
}

.ico-music-13:before {
  content: "\e907"
}

.ico-music-12:before {
  content: "\e908"
}

.ico-music-11:before {
  content: "\e909"
}

.ico-music-10:before {
  content: "\e90a"
}

.ico-music-9:before {
  content: "\e90b"
}

.ico-music-8:before {
  content: "\e90c"
}

.ico-music-7:before {
  content: "\e90d"
}

.ico-music-6:before {
  content: "\e90e"
}

.ico-music-5:before {
  content: "\e90f"
}

.ico-music-4:before {
  content: "\e910"
}

.ico-music-3:before {
  content: "\e911"
}

.ico-music-2:before {
  content: "\e912"
}

.ico-music-1:before {
  content: "\e913"
}

.ico-music:before {
  content: "\e914"
}

.ico-users:before {
  content: "\e915"
}

.ico-user-1:before {
  content: "\e916"
}

.ico-user:before {
  content: "\e917"
}

.ico-avatar:before {
  content: "\e918"
}

.ico-mail:before {
  content: "\e919"
}

.ico-envelope:before {
  content: "\e91a"
}

.ico-music-player:before {
  content: "\e91b"
}

.ico-alarm-1:before {
  content: "\e91c"
}

.ico-alarm:before {
  content: "\e91d"
}

.ico-speedometer:before {
  content: "\e91e"
}

.ico-dashboard:before {
  content: "\e91f"
}

.ico-arrow-download:before {
  content: "\e920"
}

.ico-keyboard:before {
  content: "\e921"
}

.ico-playlist:before {
  content: "\e922"
}

.ico-add:before {
  content: "\e923"
}

.ico-comment:before {
  content: "\e924"
}

.ico-delete:before {
  content: "\e925"
}

.ico-downloads:before {
  content: "\e926"
}

.ico-drop-menu-down .path1:before {
  content: "\e927";
  color: #fff
}

.ico-drop-menu-down .path2:before {
  content: "\e928";
  margin-left: -1em;
  color: #000
}

.ico-favorite:before {
  content: "\e929"
}

.ico-flag:before {
  content: "\e92a"
}

.ico-info:before {
  content: "\e92b"
}

.ico-maximize:before {
  content: "\e92c"
}

.ico-maximize-single:before {
  content: "\e92d"
}

.ico-new2:before {
  content: "\e92e"
}

.ico-pagination-left:before {
  content: "\e92f"
}

.ico-pagination-right:before {
  content: "\e930"
}

.ico-play-music:before {
  content: "\e931"
}

.ico-repeat-music:before {
  content: "\e932"
}

.ico-tag:before {
  content: "\e933"
}

.ico-arrow-down:before {
  content: "\e934"
}

.ico-arrow-left:before {
  content: "\e935"
}

.ico-arrow-right:before {
  content: "\e936"
}

.ico-arrow-up:before {
  content: "\e937"
}

.ico-music-mac:before {
  content: "\e938"
}

.ico-mute:before {
  content: "\e939"
}

.ico-pause:before {
  content: "\e93a"
}

.ico-play:before {
  content: "\e93b"
}

.ico-playlist2:before {
  content: "\e93c"
}

.ico-playlist-option:before {
  content: "\e93d"
}

.ico-playlist-repeat:before {
  content: "\e93e"
}

.ico-playlist-shuffle:before {
  content: "\e93f"
}

.ico-volume-full:before {
  content: "\e940"
}

.ico-volume-low:before {
  content: "\e941"
}

.ico-download-manager:before {
  content: "\e942"
}

.ico-labels:before {
  content: "\e943"
}

.ico-menu:before {
  content: "\e944"
}

.ico-music-charts:before {
  content: "\e945"
}

.ico-new-releases:before {
  content: "\e946"
}

.ico-radar:before {
  content: "\e947"
}

.ico-release-archive:before {
  content: "\e948"
}

.ico-top-downloads:before {
  content: "\e949"
}

.ico-zipdj-packs:before {
  content: "\e94a"
}

.ico-download-controller:before {
  content: "\e94b"
}

.ico-search:before {
  content: "\e94c"
}
