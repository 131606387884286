@import '_autocomplete';

.cr-search-form {
  position: relative;

  &__icon-search {
    position: absolute;
    left: 10px;
  }

  &__input {
    /* stylelint-disable-next-line */
    padding-left: 30px !important;
    padding-right: 8px !important;
    @media screen and (max-width: 750px) {
      padding-left: 34px !important;
      padding-right: 8px !important;
    }
  }
}
