.rightbar {
  width: 29.5%;
  /* margin-right: 6px; */
  padding-left: 4px;
  z-index: 3;
  opacity: 1;
}
@media screen and (max-width: 1368px) and (min-width: 1225px) {
  .rightbar {
    width: 33%;
  }
}
@media screen and (max-width: 1224px) and (min-width: 750px) {
  .rightbar {
    margin-right: 0;
    margin-top: 6px;
    left: 0px;
    top: auto;
    padding-left: 0 !important;
    height: auto;
    width: calc(64vw - 56px);
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto 35px 35px 35px 35px 35px 35px;
    grid-auto-rows: auto;
    grid-column-gap: 6px;
  }

  .rightbarcomponent:not(:last-child) {
    margin-bottom: 6px;
  }
  .rightbarcomponent .rightbarcomponent__subtitle {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .rightbarcomponent {
    grid-template-columns: auto;
  }
  .rightbarcomponent:nth-child(1) {
    grid-column: span 2;
  }
  .rightbarcomponent:nth-child(2) {
    grid-column: span 2;
  }
  .rightbarcomponent:nth-child(3) {
    grid-row: 3 /6;
    align-content: center;
  }
  .rightbarcomponent:nth-child(4) {
    align-content: center;
    grid-row: 3 /5;
  }
  .rightbarcomponent:nth-child(5) {
    padding-top: 10px;
    grid-row: 6 /9;
    align-content: center;
    margin-bottom: 0px !important;
  }
  .rightbarcomponent:nth-child(6) {
    align-content: center;
    grid-row: 5 /7;
  }
  .rightbarcomponent:nth-child(7) {
    align-content: center;
    grid-row: 7 /9;
  }

  .rightbarcomponent .rightbarcomponent__title {
    padding-left: 0;
    margin: auto 0;
  }
}

@media screen and (max-width: 750px) {
  .rightbar {
    position: relative;
    width: 100vw;
    top: 0;
    padding-left: 0;
    height: auto;
    z-index: 10;
  }
  .rightbarcomponent {
    padding: 12px 15px;
  }
  #rightbarLatin {
    background: linear-gradient(
        0deg,
        rgba(34, 35, 39, 0.95),
        rgba(34, 35, 39, 0.95)
      ),
      url('../../assets/img/Latin.png');
  }
  #rightbarUrban {
    background: linear-gradient(
        0deg,
        rgba(34, 35, 39, 0.95),
        rgba(34, 35, 39, 0.95)
      ),
      url('../../assets/img/rightbar/Urban.png');
  }
  .rightbarcomponent.activeAcc {
    display: flex;
    flex-direction: column;
    background: #1c1e22 !important;
    z-index: 10;
    width: 100vw;
  }
  .rightbarcomponent:not(:last-child) {
    margin-bottom: 0;
  }
  .rightbarcomponent .rightbarcomponent__subtitle {
    display: none;
    padding-left: 4px;
  }
  .rightbarcomponent.activeAcc .rightbarcomponent__subtitle {
    display: block;
    /* background: ; */
  }
  .rightbarcomponent.activeAcc .rightbarcomponent__subtitle p {
    padding-top: 15px;
  }
  .rightbarcomponent .rightbarcomponent__title {
    padding-left: 4px;
    display: block;
  }
  .rightbarcomponent.activeAcc .rightbarcomponent__title {
    background: linear-gradient(
        0deg,
        rgba(34, 35, 39, 0.095),
        rgba(34, 35, 39, 0.095)
      ),
      url('/app/static/media/Urban.93ff78d5.png');
    background-position: center;
    background-size: cover;
  }
  .sm-title {
    padding-top: 15px;
    font-weight: 500;
    text-transform: uppercase;
  }
  .rightbarcomponent .rightbarcomponent__title h3:hover {
    color: white;
  }
  .rightbarcomponent .rightbarcomponent__title h3 {
    font-size: 15px;
    letter-spacing: 0.1em;
  }
}
@media screen and (max-width: 2200px) and (min-width: 1560px) {
  .rightbar {
    width: 37%;
  }
}
@media screen and (min-width: 2200px) {
  .rightbar {
    margin-top: 4px;
    width: 60%;
    display: grid;
    grid-template-columns: 1fr 0.65fr;
    grid-template-rows: 49px 49px 49px 49px 49px 49px 49px 49px 49px 49px;
    grid-row-gap: 6px;
    grid-column-gap: 10px;
  }
  .rightbarcomponent:not(:last-child) {
    margin-bottom: 0px !important;
  }
  .rightbarcomponent:nth-child(1) {
    grid-row: 1 / 8;
    padding-left: 134px;
    grid-template-columns: 0.48fr 1fr;
  }
  .rightbarcomponent:nth-child(2) {
    grid-row: 8 / 11;
    padding-left: 134px;
    grid-template-columns: 0.48fr 1fr;
  }
  .rightbarcomponent:nth-child(3) {
    grid-row: 1 / 3;
  }
  .rightbarcomponent:nth-child(4) {
    grid-row: 3 / 5;
  }
  .rightbarcomponent:nth-child(5) {
    grid-row: 5 / 7;
  }
  .rightbarcomponent:nth-child(6) {
    grid-row: 7 / 9;
  }
  .rightbarcomponent:nth-child(7) {
    grid-row: 9 / 11;
  }
  .rightbarcomponent .rightbarcomponent__subtitle p {
    font-size: 16px;
  }
}
