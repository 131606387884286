body {
  .sk-results-list {
    .zd-card .card-img {
      border-radius: 0;
    }
  }

  .zd-cards-hits {
    .card-img {
      border-radius: 0;
    }
  }

  div.zd-square-image-wrap {
    width: 100%;
    padding-top: 100%;
    position: relative;
    line-height: 0px;
    overflow: hidden;
    img.card-img {
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
      height: 100%;
      width: 100%;
      border-radius: 0px !important;
    }
  }
  .zd-card {
    border-radius: 0px !important;
    overflow: hidden;
  }
}

.zd-cards .zd-card-c .card.zd_v5_card_search {
  flex-direction: column !important;
}

.zd-cards {
  .zd-card-c {
    width: 12.5%;
    float: left;
    transition: all 0.1s ease-out;
    @media (max-width: 1224px) {
      margin-bottom: 16px;
    }
    &:hover {
      // transform: translateY(-5px);
      .customslider__playbtn span i {
        color: #ddd !important;
      }
      .card-body {
        background-color: #28292e;
      }
    }
    .zd-card {
      text-decoration: none;
      color: #ddd;
      margin: 0 0.5rem 16px 0px;
      height: calc(100% - 2.8rem);
      // background: #2d2e33;
      border-color: #222224;
      -webkit-transition: box-shadow 0.5s ease;
      transition: box-shadow 0.5s ease;
      -webkit-transition: 0.6s;
      transition: 0.6s;
      @media (max-width: 1224px) {
        margin: 0 8px;
      }
      // border-radius: 0px !important;
      .zd-square-image-wrap {
        border-radius: 2px !important;
      }
    }
    .zd-label-card {
      &:hover {
        opacity: 1;
        visibility: visible;
        cursor: pointer;
      }
    }
    &.zd-essential-card {
      .zd-card {
        position: relative;
        .card-body {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 101%;
          width: 100%;
          background-color: rgba(93, 101, 136, 0.166);
          transition: 0.3s ease-in;
          &:hover {
            background-color: rgba(101, 112, 159, 0.086);
          }
        }
      }
    }
  }
}
.scroller {
  height: 628px;
  width: 224px;
  overflow-y: auto;
  @media (min-width: 0px) and (max-width: 820px) {
    height: 126px;
  }
}
.scroller::-webkit-scrollbar {
  width: 8px;
}
// .scroller::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
// }

// .scroller::-webkit-scrollbar-thumb {
//   background-color: darkgrey;
//   outline: 1px solid slategrey;
// }
/* Track */
.scroller::-webkit-scrollbar-track {
  background: #28292e;
  background-clip: content-box;
  border-radius: 4px;
}

/* Handle */
.scroller::-webkit-scrollbar-thumb {
  background: #6c6e71;
  border: 2px solid #28292e;
  border-radius: 4px;
}

/* Handle on hover */
.scroller::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.crate-vector {
  color: #4f7be9 !important;
  // font-size: 12px !important;
  .playlist-icon {
    display: inline-block !important;
  }
}

.zd-profile-content {
  .zd-labels {
    .zd-card-c {
      width: 25%;
      float: left;
    }
  }
}

.zd-labels .card-img {
  height: 170px;
  min-height: 100px;
  border-radius: 0px !important;
}

.zd-label-card:hover {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
  -webkit-transform: translateY(-0.8rem);
  transform: translateY(-0.8rem);
}

.zd-cards-hits {
  .zd-cards {
    height: 200px;

    .zd-card-c {
      width: 12.5%;
      .card-body {
        height: 50px !important;
        padding-top: 1rem !important;
      }
    }
  }

  .sk-hits-grid {
    height: auto;
  }
}

.mini-tabs {
  align-items: self-start;
  .nav-item {
    max-width: 110px;
    flex: 1;
    overflow: hidden;
    .tab-text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
    }
  }
}

/* Lables Style */
@media only screen and (min-width: 991px) and (max-width: 1200px) {
  body {
    .zd-cards {
      .zd-card-c {
        width: 16.66666%;
      }
    }
  }
  .zd-cards-hits {
    .zd-cards {
      .zd-card-c {
        width: 16.66666%;
      }
    }
  }
}

/* Lables Style */
@media only screen and (min-width: 768px) and (max-width: 990px) {
  body {
    .zd-cards {
      .zd-card-c {
        width: 20%;
      }
    }
  }
  .zd-cards-hits {
    .zd-cards {
      height: 260px;
      .zd-card-c {
        width: 20%;
      }
    }
    .sk-hits-grid {
      height: auto;
    }
  }
}

@media only screen and (min-width: 660px) and (max-width: 767px) {
  body {
    .zd-cards {
      .zd-card-c {
        width: 25%;
      }
    }
  }
  .zd-cards-hits {
    .zd-cards {
      .zd-card-c {
        width: 25%;
      }
    }
  }
}

@media only screen and (min-width: 541px) and (max-width: 660px) {
  body {
    .zd-cards {
      .zd-card-c {
        width: 33.333%;
      }
    }
  }
  .zd-cards-hits {
    .zd-cards {
      .zd-card-c {
        width: 33.333%;
      }
    }
  }
}

@media only screen and (min-width: 0) and (max-width: 540px) {
  body {
    .zd-cards {
      .zd-card-c {
        width: 50%;
        .card-img {
          height: 100%;
          border-radius: 0px !important;
        }
      }
    }
  }

  .zd-cards-hits {
    .zd-cards {
      .zd-card-c {
        width: 50%;
      }
    }
  }
}

@media only screen and (min-width: 0px) and (max-width: 414px) {
  .zd-cards-hits {
    .zd-cards {
      .zd-card-c {
        width: 50%;
        .zd-card {
          margin: 5px;
        }
      }
    }
  }
}

#zdv4-labels-search-result {
  // body {
  //   background-color: #1c1e22;
  // }
  .sk-layout__body {
    margin: 0 -13px;
    @media (max-width: 1224px) {
      margin: 0 -9px;
    }
    @media (max-width: 750px) {
      margin: 0 -13px;
    }
  }
  .zd-cards {
    .zd-card-c {
      margin-bottom: 26px;
      @media (max-width: 1224px) {
        margin-bottom: 16px;
      }
      @media (max-width: 540px) {
        width: 100%;
      }
      .zd-card {
        margin: 0 13px;
        @media (max-width: 1224px) {
          margin: 0 8px;
        }
        @media (max-width: 540px) {
          margin: 0 12px;
        }

        &.card {
          @media (max-width: 540px) {
            flex-direction: row;
            height: 80px;
            align-items: center;
          }
          .zd-square-image-wrap {
            @media (max-width: 540px) {
              height: 100%;
              width: auto;
              padding-top: 0;
            }
            img.card-img {
              @media (max-width: 540px) {
                position: static;
                height: 100%;
                width: auto;
              }
            }
          }
          .card-body {
            @media (max-width: 540px) {
              padding: 0 0 0 24px;
            }
            .card-title {
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              text-align: center;
              line-height: 18px;
              color: #dddddd !important;
              @media (max-width: 540px) {
                text-align: left !important;
              }
            }
          }
        }
      }
    }
  }
}

//search result
#zd-search-h-artists,
#zd-search-h-labels {
  .zd-card {
    margin: 0 0.5rem 10px 0px;
  }
}
#zd-search-h-artists {
  @media (max-width: 1224px) {
    .zd-cards .zd-card-c {
      margin-bottom: 0;
    }
  }
}

#zd-search-h-releases {
  .sk-layout__results {
    @media (max-width: 1224px) {
      margin: 0 -8px;
    }
    @media (max-width: 540px) {
      .zd-cards .zd-card-c {
        width: 100%;
      }
      @media (max-width: 540px) {
        .zd-cards .zd-card-c .zd-card.card {
          flex-direction: row;
          height: 80px;
          align-items: center;
        }
      }
      @media (max-width: 540px) {
        .zd-cards .zd-card-c .zd-card.card .zd-square-image-wrap {
          height: 100%;
          width: 100px;
          padding-top: 0;
        }
        .zd-text_search-ellipsis {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          text-align: left;
          max-width: 190px;
        }
      }
      @media (max-width: 540px) {
        .zd-cards .zd-card-c .zd-card.card .card-body {
          padding: 14px 24px;
        }
      }
      @media (max-width: 540px) {
        .zd-cards .zd-card-c .zd-card.card .zd-square-image-wrap img.card-img {
          position: static;
          height: 100%;
          width: auto;
        }
        .card-body div {
          text-align: left !important;
        }
      }
    }
  }
}
.crate-playlist-view {
  // width: 187px !important;
  width: 224px !important;
  // margin-left: 56px;
  margin-left: 17px;

  .playlist-heading {
    font-size: 12px;
    color: #434b52;
    // font-weight: 500;
    font-style: normal;
    font-family: 'Poppins';
    margin-bottom: 16px;
  }
  .crate-main-btn {
    width: 224px;
    height: 40px;
    background-color: #303139;
    border-radius: 2px;
    color: #8e8e8e;
    font-size: 12px;
    border: #303139;
    margin-bottom: 4px;
    i {
      font-size: 11px;
    }
  }
  .playlist-container {
    margin-bottom: 2px;

    border: none;
    background: #1c1e22 !important;
    .card-body {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      // width: 175px;
      background: #242529;
      color: #8e8e8e;
      cursor: pointer;
      // text-align: center;
      .main-crate {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        // text-align: center;
        .playlist-value {
          // position: absolute;
          padding-left: 10px;
          // width: 90px;
          width: 137px;
          top: 10px;
          font-size: 12px !important;
          height: 18px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .playlist-icon {
        display: none;
        position: absolute;
        left: 168px;
      }
      .playlist-drag-drop {
        display: none;
      }
      .crate-icon {
        // display: none;
        padding-left: 15px;
      }
      &:hover {
        .playlist-drag-drop {
          display: inline-block;
        }
        // .crate-icon{
        //   display: inline-block;
        // }
      }
    }
  }
}
.demotest {
  display: inline-block !important;
}
.zd_crate_table {
  .zd-album-column {
    .zd-release-favourite {
      i {
        display: none;
      }
      &:hover {
        i {
          display: block;
        }
      }
      .zdv3-icon-filled-favourite {
        display: block;
        color: #4d7ce8 !important;
      }
    }
  }
  .zd-release-crates .crate-dropdown-menu {
    transform: translate3d(-129px, 7px, 0px) !important;
    width: 171px;
    left: -14px !important;
  }
}
