/* width */
::-webkit-scrollbar {
  width: 10px;
}

/*!* Track *!*/
::-webkit-scrollbar-track {
  background: #131213;
}
/**/
/*!* Handle *!*/
::-webkit-scrollbar-thumb {
  background: #33343a;
}

/*!* Handle on hover *!*/
::-webkit-scrollbar-thumb:hover {
  background: #33343a;
}

body {
  &.zd-profile {
    background: #18181b;
    .cr-page {
      background-color: #18181b;
    }
  }
}
.zd-blue {
  color: #7685b3 !important;
  &:hover {
    color: $white !important;
    opacity: 1 !important;
  }
}

.zd-blue-active {
  color: #4f7be9 !important;
  &:hover {
    color: $white !important;
    opacity: 1 !important;
  }
}
.zd-key-blue {
  color: #4f7be9 !important;
  padding-right: 8px;
  &:hover {
    color: $white !important;
    opacity: 1 !important;
    cursor: pointer;
  }
}

.zd-red {
  color: $red-100 !important;
}

:focus,
button:focus {
  outline: none;
}
.zd-show {
  display: block !important;
}
.zd-hide,
.is-hidden {
  display: none !important;
}
.zd-hide-spinner {
  display: none;
  margin-right: 3px;
}
.infinite-scroll-component {
  min-height: 200px !important;
}

.zd-cursor-pointer {
  cursor: pointer;
}

.zd-loader,
.cr-page-spinner {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.zd-page-loader {
  display: block;
  position: fixed;
  z-index: 99999;
  // background-color: rgba(34, 34, 34, 0.88);
  // background-color: #1C1E22;
  background-color: #191b1f;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}
.track-info-block {
  position: relative;
}
.zd-info-loader {
  display: block;
  position: absolute;
  z-index: 99999;
  background-color: rgba(34, 34, 34, 0.88);
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}
.zd-widget-loader {
  position: absolute;
  top: -10%;
}
.zd-vspace-20 {
  height: 20px;
}
.zd-vspace-40 {
  height: 40px;
}
.zd-width-200 {
  width: 200px;
}
.zd-width-300 {
  width: 300px;
}
.zd-infinite-loader {
  position: unset;
  margin-top: 70px;
}
body .zd-init-loader {
  background-color: transparent;
}
.zd-infinite-loader {
  position: relative;
  margin-top: 0px;
  padding: 20px 0;
  height: auto;
}
.zd-trending-loader {
  margin-top: 50px;
  height: 100px;
}
.zd-tag-selected,
.zd-menu-tag-selected {
  background: orange !important;
  color: black !important;
}
.zd-text-muted {
  color: $gray-75 !important;
}
.zd-section-title {
  font-size: $font-size-base;
  span {
    border-bottom: 2px solid map-get($map: $theme-colors, $key: primary);
    padding-bottom: 2px;
  }
}
.release-data {
  .zd-section-title {
    margin: 15px 10px 5px 10px;
  }
}
table {
  .zd-small-album-cell {
    width: 40px;
    max-width: 40px;
    padding: 0;
    overflow: hidden;
    margin: 0;
    vertical-align: top;

    .zd-album-art {
      width: 40px;
      max-height: 40px;
      min-height: 40px;
      border-radius: 2px;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
  }
  .zd-medium-album-cell {
    width: 48px;
    max-width: 48px;
    .zd-album-art {
      border-radius: 2px;
      width: 48px;
    }
  }
  .zd-large-album-cell {
    max-width: 48px;

    width: 48px;
    vertical-align: top;
    .tracking {
      // max-width: 63px;
      // width: 63px;
    }
    .zd-album-art {
      width: 48px;
      max-height: 48px;
      border-radius: 2px;
      min-height: 48px;
    }
  }
}

.zd-trending-tags-lists-btn {
  display: block;
  // width: 100px;
  .list-group-item {
    text-align: center;
    border: 1px solid #323743;
    border-radius: 0px !important;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 4px 8px;
    width: 18%;
    display: block;
    float: left;
    cursor: pointer;
    color: #eaeaea !important;
    &:hover,
    :focus {
      background-color: $link-hover-color;
    }
    // &:first-child {
    //
    // }
    // &:last-child {
    //   border-radius: 0px !important;
    // }
  }
}

.zd-tags-lists-btn {
  display: block;
  .list-group-item {
    border: 1px solid #323743;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 3px 2px;
    // border-radius: 0.25rem !important;
    width: 23.9%;
    display: block;
    float: left;
    cursor: pointer;
    color: #eaeaea !important;
    &:hover,
    :focus {
      background-color: $link-hover-color;
    }
    &:first-child {
      border-radius: 0px !important;
    }
    &:last-child {
      border-radius: 0px !important;
    }
  }
}
.text-muted {
  color: $gray-75 !important;
}
.zd-text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  color: #6b7883 !important;
}

.zd-trending-text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.zd-artist-list {
  .zd-artist-name {
    &:after {
      content: ', ';
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}
.zd-cursor {
  cursor: pointer;
}

.zd-cursor-default {
  cursor: default;
}

.zd-search-track-view {
  position: relative;
}
body .zd-section-loader {
  position: fixed;
}

body .zd-player-icon-download {
  font-size: 22px;
}

body .zd-player-icon-download-newhomepage {
  color: #575f66 !important;
}

body .zd-error-row:hover,
body .zd-error-row {
  background-color: transparent !important;
  border-bottom: none !important;
  td {
    padding: 20px;
  }
}

body .zd-reprot-modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  .modal-content {
    width: 360px;
    margin-left: auto;
    margin-right: auto;
  }
}

.zd-btn-disable:hover,
.zd-btn-disable:focus,
.zd-btn-disable {
  opacity: 0.6;
  cursor: auto;
  background: #6a7fb1;
  border-color: #6a7fb1;
}
.past-download {
  background-color: $table_tr;
}
.zd-download-title {
  font-size: $font-size-base;
  align-self: center;
  span {
    border-bottom: 2px solid map-get($map: $theme-colors, $key: primary);
    padding-bottom: 2px;
  }
}
.zd-search-back {
  color: $white;
  border-radius: 0px !important;
  cursor: pointer;
  margin-right: 8px;
  float: left;
  height: 31px;
  &:hover {
    color: map-get($map: $theme-colors, $key: primary);
  }
}
.zd-text-434B52 {
  color: #434b52 !important;
}
.zd-release-back {
  color: $white !important;
  cursor: pointer;
  float: right;
  margin-left: 20px;
  // margin-right: 8px;
  &:hover {
    color: map-get($map: $theme-colors, $key: primary);
  }
}
.player-close {
  svg {
    font-size: 18px;
  }
}
.zd-exceeded-msg {
  color: #fff !important;
  &:hover {
    color: #fff;
  }
}

.zd-sub-genre-track-view {
  .zd-track-view,
  .zd-search-track-view {
    padding-top: 0px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.zd-rotate {
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.zd-rotate.up {
  color: #4f7be9;
  -ms-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

// don't use this class elsewhere
.zd-modal-index-color {
  -moz-transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
  font-size: 12px;

  &:hover {
    transform: scale(1.5);
    color: #4f7be9;
  }

  &.index-selected {
    color: #4f7be9;
  }
}

body {
  position: relative;
  .zd-email-popup {
    position: absolute;
    min-width: 100vw !important;
    // top: calc(50% - 25px); // half of width
    // left: calc(50% - 50px);
    left: 0;
  }
}

.cancel-btn {
  background: #808080;
  &:hover {
    background: #8d8d8d;
  }
}

.zd_trending_packs_card_svg {
  background-color: '#141414';
  height: 100%;
  width: 100%;
  .zd_trending_packs_card_text {
    // width: 50px;
  }
  .randersvg {
    margin-left: 70px;
    position: relative;
    top: -19px;
    svg {
      height: 90px;
      width: 100px;
    }
  }
  p {
    width: 138px;
    font-size: 12px !important;
  }
}
.zd_v5 {
  white-space: nowrap;
}
.zd_new_header {
  display: flex;
  .zd_v5_tab {
    padding-top: 24px;
    padding-bottom: 6px;
    span {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      color: #6d7882;
      padding: 0px 6px 0 6px;
    }
    // margin-right: 32px;
    cursor: pointer;
  }
  .active {
    color: #4978ef !important;
    border-bottom: 1px solid #4978ef !important;
    span {
      color: #4978ef;
    }
  }

  justify-content: space-between;
}
.zd_v5_tab {
  &:hover {
    color: #ddd !important;
    border-bottom: 1px solid #ddd !important;

    span {
      color: #ddd !important;
    }
  }
}

.zd_v5_filter {
  background-color: #32333a;
  border: 1px solid #4a4b54;
  padding: 5.5px;
  // margin-bottom: 3px;
}

@media (max-width: 750px) {
  .zd-new_filter_v5 {
    top: 90px !important;
  }
}

.trendingpacks__title {
  font-size: 14px;
  color: #ddd;
  font-weight: 500;
}
.zd-new-newreleases-page {
  .zd-v5-artist {
    span {
      margin-left: 3px;
    }
  }
}

.zd-v5_Artist {
  color: #6b7883 !important;
  @media (min-width: 750px) {
    display: none !important;
  }
}
.zd-v5-home-text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inherit !important;
}
.zd-v5-trnd-artist {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inherit !important;
}
.zd-v5-artist {
  &.zd-text-ellipsis {
    display: block;
  }
  @media (min-width: 750px) {
    display: none !important;
  }
  @media screen and (max-width: 416px) {
    margin-left: 0px;
  }
  .zd-text-dddddd {
    color: #6d7882 !important;
  }
  margin-top: -2px;
  .zd-artist-name {
    font-size: 12px;
    color: #6d7882;
  }
}

.zd-track-view {
  @media (max-width: 750px) {
    .zd-album-table tbody .zd-album-title {
      line-height: 1.4;
    }
    .zd-new-top-downloads-main-table
      .zd-new-top-downloads-parent-table
      tbody
      tr
      .zd-small-album-cell
      img {
      width: 68px;
      height: 68px;
      max-height: 68px;
      min-height: 68px;
      max-width: 68px;
      min-width: 68px;
    }
    .zd-double-icons {
      width: 8%;
    }
    .zd-new-top-downloads-parent-table tbody tr td.zd-small-album-cell {
      width: 68px !important;
      min-width: 68px !important;
    }

    .zd-new-top-downloads-main-table
      .zd-new-top-downloads-parent-table
      tbody
      tr
      td.zd-release-crates {
      width: 4%;
      .zd-dropdown-crate {
        margin-top: 22px !important;
      }
    }
    .zd-play-col {
      width: 56px !important;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}
.zd-new-top-downloads-parent-table {
  @media (max-width: 750px) {
    .zd-title {
      width: 100% !important;
    }
  }
}

.top-downloads-page .zd-body-row-table tbody tr td.zd-double-icons {
  @media (max-width: 750px) {
    height: 68px !important;
    &.zd-icons {
      display: table-cell !important;
      a {
        margin-top: 0;
      }
    }
  }
}

.top-downloads-page .zd-body-row-table tbody tr td.zd-double-icons.zd-icons {
  @media (max-width: 750px) {
    display: table-cell !important;
    a {
      margin-top: 0;
      display: table-cell !important;
    }
  }
}


.track-name{
  cursor: inherit !important
}
.zd_v5_cursor_hide {
  color: red !important;
  // cursor:wait !important;
}

.zd-hide-cursor{
  cursor: inherit !important;
  a{
  cursor: inherit !important;
  }
}