@charset "UTF-8";
@font-face {
  font-family: "zipdj";
  src: url("../assets/fonts/zipdj.eot");
  src: url("../assets/fonts/zipdj.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/zipdj.woff") format("woff"), url("../assets/fonts/zipdj.ttf") format("truetype"), url("../assets/fonts/zipdj.svg#zipdj") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class*=" zd-icon-"], [class^=zd-icon-] {
  font-family: "zipdj" !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}
[data-icon]:before {
  font-family: "zipdj" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="zd-icon-"]:before,
[class*=" zd-icon-"]:before {
  font-family: "zipdj" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.zd-icon-menu:before {
  content: "\e944";
}
.zd-icon-next-track:before {
  content: "\e902";
  color: #fff;
}
.zd-icon-prev-track:before {
  content: "\e903";
  color: #fff;
}

.zd-icon-maximize-single:before {
  content: "\61";
}

.zd-icon-shuffle:before {
  content: "\62";
}

.zd-icon-list-thumbnails:before {
  content: "\63";
}

.zd-icon-list-number:before {
  content: "\64";
}

.zd-icon-pause:before {
  content: "\65";
}

.zd-icon-loop:before {
  content: "\66";
}

.zd-icon-download:before {
  content: "\67";
}

.zd-icon-loop-1:before {
  content: "\68";
}

.zd-icon-shuffle-1:before {
  content: "\69";
}

.zd-icon-download-1:before {
  content: "\6a";
}
.zd-icon-download-2:before {
  content: "\4e";
}
.zd-icon-dropbox:before {
  content: "\6b";
}

.zd-icon-android-sync:before {
  content: "\6c";
}

.zd-icon-001-interface:before {
  content: "\6d";
}

.zd-icon-002-download-arrow:before {
  content: "\6e";
}

.zd-icon-003-download-button:before {
  content: "\6f";
}

.zd-icon-downloadmanager:before {
  content: "\70";
}

.zd-icon-downloadmanager-1:before {
  content: "\72";
}

.zd-icon-android-bulb:before {
  content: "\71";
}

.zd-icon-facebook:before {
  content: "\73";
}

.zd-icon-linkedin:before {
  content: "\74";
}

.zd-icon-twitter-alt:before {
  content: "\75";
}

.zd-icon-ios-trash-outline:before {
  content: "\76";
}

.zd-icon-002-rubbish-bin:before {
  content: "\77";
}

.zd-icon-001-draw:before {
  content: "\78";
}

.zd-icon-zipdjpacks:before {
  content: "\79";
}

.zd-icon-favourite:before {
  content: "\7a";
}

.zd-icon-info:before {
  content: "\41";
}

.zd-icon-labels:before {
  content: "\42";
}

.zd-icon-new-releases:before {
  content: "\43";
}

.zd-icon-pauseicon:before {
  content: "\44";
}

.zd-icon-playlist:before {
  content: "\45";
}

.zd-icon-print:before {
  content: "\46";
}

.zd-icon-radar:before {
  content: "\47";
}

.zd-icon-release-archive:before {
  content: "\48";
}

.zd-icon-shuffle-2:before {
  content: "\49";
}

.zd-icon-tag:before {
  content: "\4a";
}

.zd-icon-top-downloads:before {
  content: "\4b";
}

.zd-icon-downloadicon:before {
  content: "\4c";
}

.zd-icon-download-manager:before {
  content: "\4d";
}

.zd-icon-download-2:before {
  content: "\4e";
}

.zd-icon-delete:before {
  content: "\4f";
}
.zd-icon-delete-2:before {
  content: "\2715";
}
.zd-icon-comment:before {
  content: "\50";
}

.zd-icon-charts:before {
  content: "\51";
}

.zd-icon-angle-down:before {
  content: "\52";
  color: #fff;
}

.genre_title .zd-icon-angle-down:before {
  color: #507de5;
}

.zd-icon-angle-left:before {
  content: "\53";
}

.zd-icon-angle-right:before {
  content: "\54";
}

.zd-icon-angle-up:before {
  content: "\55";
}

.zd-icon-refresh:before,
.zd-icon-repeat-track:before {
  content: "\56";
}

.zd-icon-pause-1:before {
  content: "\57";
}

.zd-icon-play:before {
  content: "\58";
}

.zd-icon-playback-play:before {
  content: "\59";
}

.zd-icon-playback-pause:before {
  content: "\5a";
}

.zd-icon-genres:before {
  content: "\e900";
}

.zd-icon-step-backward:before {
  content: "\31";
}

.zd-icon-step-forward:before {
  content: "\32";
}

.zd-icon-jump-right:before {
  content: "\33";
}

.zd-icon-jump-left:before {
  content: "\34";
}
