.react-autosuggest__container {
  position: static;
  @media (max-width: 768px) {
    background: transparent;
  }
}

.react-autosuggest__input {
}

.react-autosuggest__input--focused {
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block !important;
  position: absolute;
  top: 35px;
  width: 100%;
  border: none;
  background-color: #292a2f;
  font-size: 0.7rem;
  line-height: 1.5;
  border-radius: 4px;
  z-index: 20000000;
  padding: 0 0;
  .react-autosuggest__section-container {
    border-bottom: 1px solid #1f2024;
  }
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  padding: 7px 12px 7px;
  color: #6b7883;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #303139 !important;
  color: #dddddd;
}

.react-autosuggest__section-container {
  border-top: 1px solid $gray-1200;
}

.react-autosuggest__section-container--first {
  border-top: 0;
}

.react-autosuggest__section-title {
  padding: 10px 12px 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #dddddd;
  text-transform: capitalize;
  strong {
    font-weight: 500;
  }
}
