.rightbarcomponent {
  padding: 8.8px 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-size: cover !important;
  background-position: center;
}
.rightbarcomponent:not(:last-child) {
  margin-bottom: 4px;
}
@media screen and (max-width: 1559px) and (min-width: 1225px) {
  /* Player Active */
  .player-active .rightbarcomponent {
    padding: 4px 12px;
  }
  .player-active
    .rightbarcomponent:first-child
    .rightbarcomponent__subtitle
    p:first-child {
    padding-top: 9.5px;
  }
  .player-active
    .rightbarcomponent:first-child
    .rightbarcomponent__subtitle
    p:last-child {
    padding-bottom: 9.5px;
  }
  .player-active .rightbarcomponent .rightbarcomponent__subtitle p {
    padding: 6.1px 0;
  }
}
@media screen and (min-width: 1224px) {
  .rightbarcomponent:first-child {
    grid-template-columns: 1fr 1fr;
  }
  .rightbarcomponent:nth-child(2) {
    grid-template-columns: 1fr 1fr;
  }
  .rightbarcomponent:first-child .rightbarcomponent__subtitle p:first-child {
    padding-top: 20px;
  }
  .rightbarcomponent:first-child .rightbarcomponent__subtitle p:last-child {
    padding-bottom: 20px;
  }
  .rightbarcomponent:nth-child(2) .rightbarcomponent__subtitle p:first-child {
    padding-top: 10px;
  }
  .rightbarcomponent:nth-child(2) .rightbarcomponent__subtitle p:first-child {
    padding-bottom: 9px;
  }
}

.rightbarcomponent .rightbarcomponent__title {
  display: flex;
  align-items: center;
  padding-left: 12px;
  color: #ddd;
  align-items: center;
  font-size: 15px;
  letter-spacing: 1.3px;
  text-transform: uppercase;
}
.rightbarcomponent .rightbarcomponent__title h3 {
  padding: 17px 0 17px;
  font-size: 16px;
  letter-spacing: 0.14em;
  font-weight: 500;
  white-space: nowrap;
  font-family: 'Poppins', 'Roboto', sans-serif;
  margin-bottom: 0 !important;
  line-height: 0;
  font-style: normal;
  text-transform: uppercase;
}
.rightbarcomponent .rightbarcomponent__title h3:hover {
  color: #4f7be9;
  cursor: pointer;
}

.rightbarcomponent .rightbarcomponent__subtitle {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.rightbarcomponent .rightbarcomponent__subtitle p {
  padding: 7px 0;
  font-style: normal;
  font-size: 12px;
  font-family: 'Poppins', 'Roboto', sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: #ddd;
  margin-bottom: 0 !important;
  transition: 0.3s ease-in;
}
.rightbarcomponent .rightbarcomponent__subtitle p span:hover {
  color: #4f7be9;
  cursor: pointer;
}
@media screen and (max-width: 1368px) and (min-width: 1225px) {
  .rightbarcomponent .rightbarcomponent__title h3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.1em;
  }
  .rightbarcomponent .rightbarcomponent__subtitle p {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
  }
  .rightbarcomponent {
    padding: 4.93px 12px;
  }
  .rightbarcomponent:first-child .rightbarcomponent__subtitle p:first-child {
    padding-top: 20px;
  }
  .rightbarcomponent:first-child .rightbarcomponent__subtitle p:last-child {
    padding-bottom: 13px;
  }
  .rightbarcomponent:nth-child(2) .rightbarcomponent__subtitle p:first-child {
    padding-top: 6px;
  }
  .rightbarcomponent:nth-child(2) .rightbarcomponent__subtitle p:first-child {
    padding-bottom: 6px;
  }

  /* Player Active */
  .player-active .rightbarcomponent {
    padding: 3px 12px;
  }
  .player-active .rightbarcomponent .rightbarcomponent__subtitle p {
    padding: 5.2px 0;
  }
  .player-active
    .rightbarcomponent:first-child
    .rightbarcomponent__subtitle
    p:first-child {
    padding-top: 10px;
  }
  .player-active
    .rightbarcomponent:first-child
    .rightbarcomponent__subtitle
    p:last-child {
    padding-bottom: 6px;
  }
}
@media screen and (max-width: 2200px) and (min-width: 1560px) {
  .rightbarcomponent {
    padding: 14.4px 12px;
    padding-left: 36px;
  }
  .player-active .rightbarcomponent {
    padding: 11px 12px;
    padding-left: 36px;
  }
  .rightbarcomponent .rightbarcomponent__subtitle p {
    padding: 8px 0 !important;
    font-size: 14px !important;
  }
}
@media screen and (min-width: 1368px) {
  .rightbarcomponent .rightbarcomponent__subtitle p {
    padding: 5.2px 0;
  }
  .menu:not(:first-child) {
    margin-top: 8px;
  }
  .rightbar {
    padding-left: 8px !important;
  }
  .rightbarcomponent:not(:last-child) {
    margin-bottom: 8px;
  }

  .bottombar {
    margin-top: 8px;
  }
  #rightbarUrban:hover {
    background: linear-gradient(
        0deg,
        rgba(34, 35, 39, 0.91),
        rgba(34, 35, 39, 0.91)
      ),
      url('../../../assets/img/rightbar/Urban.png') !important;
    background-size: cover !important;
  }
  #rightbarLatin:hover {
    background: linear-gradient(
        0deg,
        rgba(34, 35, 39, 0.91),
        rgba(34, 35, 39, 0.91)
      ),
      url('../../../assets/img/rightbar/Latin.png') !important;
    background-size: cover !important;
  }
  #rightbarClassics:hover {
    background: linear-gradient(
        0deg,
        rgba(34, 35, 39, 0.91),
        rgba(34, 35, 39, 0.91)
      ),
      url('../../../assets/img/rightbar/Classics.png') !important;
    background-size: cover !important;
  }
  #rightbarCountry:hover {
    background: linear-gradient(
        0deg,
        rgba(34, 35, 39, 0.91),
        rgba(34, 35, 39, 0.91)
      ),
      url('../../../assets/img/rightbar/Country.png') !important;
    background-size: cover !important;
  }
  .rightbarcomponent:last-child:hover {
    background: linear-gradient(
        0deg,
        rgba(34, 35, 39, 0.91),
        rgba(34, 35, 39, 0.91)
      ),
      url('../../../assets/img/rightbar/RockAlternative.png') !important;
    background-size: cover !important;
  }
  #rightbarMiscellaneous:hover {
    background: linear-gradient(
        0deg,
        rgba(34, 35, 39, 0.91),
        rgba(34, 35, 39, 0.91)
      ),
      url('../../../assets/img/rightbar/Miscellaneous.png') !important;
    background-size: cover !important;
  }
  #rightbarPop:hover {
    background: linear-gradient(
        0deg,
        rgba(34, 35, 39, 0.91),
        rgba(34, 35, 39, 0.91)
      ),
      url('../../../assets/img/rightbar/Pop.png') !important;
    background-size: cover !important;
  }
}
.rightbarcomponent__subtitle p span {
  /* border-left: 2px solid #4f7be9; */
  padding-left: 4px;
}
