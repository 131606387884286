.chart-modal-body {
  background-color: #242529 !important;
  .chart-modal-container {
    .chart-modal-header {
      display: flex;
      justify-content: space-between;
      // padding-top: 16px;

      .chart-input-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        // line-height: 24px;
        color: #dddddd;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
      }

      .chart-genre-dropdown {
        width: 118px;
        .zd-dropdown {
          width: 118px;
          height: 40px;
          background: #2a3143;
          border-radius: 2px;
          @media (max-width: 1024px) {
            height: 31px;
          }
        }
      }

      .chart-searchbox {
        width: 60%;
        input {
          height: 40px;
          background: #303139;
          border-radius: 2px;
          color: #8e8e8e;
          box-shadow: none;
          border: none;
        }
        @media (max-width: 1024px) {
          width: 50%;
          input {
            height: 31px;
          }
        }
        @media (max-width: 768px) {
          width: 40%;
        }
      }

      .chart-reset-button {
        width: 100px;
        .chart-btns {
          width: 100px;
          height: 40px;
          background: #303139;
          border-radius: 2px !important;
          box-shadow: none;
          border: none;
          @media (max-width: 1024px) {
            height: 31px;
          }
        }
      }
    }

    .chart-modal-instruction {
      padding-top: 16px;
      @media (max-width: 768px) {
        padding-top: 20px;
      }
      @media (max-width: 1024px) {
        padding-top: 30px;
      }

      span {
        font-family: 'Poppins';
        font-style: normal;
        // font-weight: 500;
        font-size: 14px;
        // line-height: 21px;
        color: #8e8e8e;
      }
    }
    @media (max-width: 1366px) {
      .chart-modal-instruction {
        span {
          font-size: 12px;
        }
      }
    }
    .chart-modal-release-container {
      padding-top: 16px;
      @media (max-width: 768px) {
        padding-top: 20px;
      }
      @media (max-width: 1024px) {
        padding-top: 30px;
      }
      display: flex;
      justify-content: space-between;

      .chart-container {
        width: 45%;
        .chart-header {
          background-color: #242529;
          border: 1px solid #303139;
          box-sizing: border-box;
          border-radius: 4px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          @media (max-width: 1024px) {
            height: 54px;
          }
          span {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            // line-height: 24px;
            color: #dddddd;
          }
        }
        .chart-item-container {
          background-color: #303139;
          width: 100%;
          .chart-list-item {
            display: flex;
            align-items: center;
            background-color: #222327;
            font-family: 'Poppins';
            font-size: 12px;
            color: #dddddd;
            // width: 90%;
            list-style-type: none;
            text-overflow: ellipsis;
            cursor: pointer;
            white-space: nowrap;
            overflow: hidden;
            &.chart-disable {
              background-color: #28292e !important;
              cursor: not-allowed;
              .zd_chart_add{
                color: #28292e !important;
              }
            }
            .zd-chart-input-release-text {
              font-size: 12px;
              color: #6b7883;
              overflow: hidden;
              text-overflow: ellipsis;
              padding-left: 10px !important;
            }
            .zd-chart-input-drag-drop {
              padding: 0px 5px;
              margin-left: auto !important;
              margin-right: 10px !important;
              .zdv3-icon-crate-drag-drop {
                color: #dddddd;
                font-size: 12px;
              }
            }
            .zd-chart-input-artist-text {
              max-width: 50%;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 12px;
              color: #dddddd;
            }
          }
        }
      }

      .top-chart-container {
        width: 52%;
        .top-chart-container-tab {
          // justify-content: space-around;
          display: flex;
          width: 100%;
          height: 48px;
          background-color: #242529;
          border: 1px solid #303139;
          box-sizing: border-box;
          border-radius: 4px;
          @media (max-width: 1024px) {
            height: 54px;
          }
          .chart-header {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Poppins';
            font-weight: 500;
            font-size: 16px;
            cursor: pointer;
            // line-height: 24px;
            color: #dddddd;
            @media (max-width: 768px) {
              font-size: 14px;
              text-align: center;
            }
            @media (max-width: 1024px) {
              text-align: center;
            }
            ////tablet view responsive
            @media (max-width: 1366px) {
              font-size: 14px;
            }

            &.zd-active {
              background-color: #4f7be9;
            }
          }
        }

        .top-chart-container-data {
          width: 100%;
          background-color: #303139;

          .top-chart-containers {
            width: 100%;
            .chart-item-right-container {
              background-color: #303139;
              .zd-text-ellipsis {
                list-style-type: none;
              }
              .chart-list-item {
                background-color: #28292e;
                font-family: 'Poppins';
                font-size: 12px;
                // line-height: 18px;
                color: #dddddd;
              }
            }
          }

          .last-chart-containers {
            width: 100%;
            .chart-item-right-container {
              background-color: #303139;
              .zd-text-ellipsis {
                list-style-type: none;
              }
              .chart-list-item {
                background-color: #222327;
                font-family: 'Poppins';
                font-size: 12px;
                // line-height: 18px;
                color: #dddddd;
              }
            }
          }
        }
      }
    }

    .chart-footer {
      padding-top: 28px;
      display: flex;
      justify-content: space-between;
      @media (max-width: 1024px) {
        padding-top: 30px;
      }
      @media (max-width: 768px) {
        padding-top: 20px;
      }
      .chart-logout-btn {
        padding-left: 32px;
        // width: 55px;
        // height: 24px;
        font-family: 'Poppins';
        font-style: normal;
        // font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-decoration-line: underline;
        color: #8e8e8e;
        cursor: pointer;
      }
      .chart-skip-btn {
        // width: 55px;
        // height: 24px;
        font-family: 'Poppins';
        font-style: normal;
        // font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-decoration-line: underline;
        color: #8e8e8e;
        cursor: pointer;
      }
      .chart-submit-btn {
        width: 90px;
        height: 40px;
        background: #4f7be9;
        border-radius: 2px;
        @media (max-width: 1024px) {
          height: 31px;
        }
      }
    }
  }
}

.chart-modal {
  height: 80vh !important;
  max-width: 80% !important;

  @media (max-width: 1024px) {
    max-width: 90% !important;
    height: 90vh !important;
  }
  @media (max-width: 768px) {
    max-width: 90% !important;
    height: 95vh !important;
  }
  // overflow-y: auto;
  // .chart-modal-body {
  //   overflow-y: auto;
  // }
}

.chart-container {
  background-color: #222327;
  width: 100%;
}

// .chart-right-containers {
//   height: 400px;
// }
.chart-item-container,
.chart-right-containers {
  max-height: 55vh;
  overflow-y: auto;
  white-space: nowrap;
  width: inherit;

  @media (max-width: 1024px) {
    height: 900px !important;
  }
  @media (max-width: 1366px) {
    height: 375px !important;
  }
  @media (max-width: 768px) {
    height: 660px !important;
  }
}

.chart-item-right-container {
  height: 580px;
  overflow-y: auto;
  white-space: nowrap;
  width: inherit;

  @media (max-width: 1024px) {
    height: 900px !important;
  }
  @media (max-width: 768px) {
    height: 660px !important;
  }
}
// .modal-content {
//   @media (max-width: 1024px) {
//     height: 100%;
//   }
// }

// .chart-header {
//   background-color: #28292E;
//   text-align: center;
//   font-size: 16px;
//   font-weight: 500;
//   color: #dddddd;
//   height: 48px;
//   line-height: 24px;
// }

// .chart-list-item {
//   &.chart-disable {
//     background-color: #222327;
//     cursor: not-allowed;
//   }
//   background-color: #2c2e33;
//   color: #eaeaea;
//   width: 90%;
//   list-style-type: none;
//   text-overflow: ellipsis;
//   cursor: pointer;
//   white-space: nowrap;
//   overflow: hidden;
// }

.zd-cursor-move {
  cursor: move;
}

// .chart-btns {
//   background: #2c2e33;
//   border: none;
// }
.zd_pointer {
  cursor: pointer;
}
.zd_plus_icon {
  &:hover {
    color: rgb(185, 179, 240);
  }
}

.lastchart-disable{
  background-color: #28292e !important;
  .zd_chart_add{
    color: #28292e !important;
  }
}