// source: https://github.com/daneden/animate.css
/* LOADER */
svg.zd-wave-loader {
  height: 40px;
  width: 40px;
  fill: $white;
  path {
    fill: $white;
    &.zd-wave-loader-l1 {
      -webkit-animation: pulse 1s infinite;
      animation: pulse 1s infinite;
      -webkit-animation-delay: 0.15s;
      animation-delay: 0.15s;
    }
    &.zd-wave-loader-l2 {
      -webkit-animation: pulse 1s infinite;
      animation: pulse 1s infinite;
      -webkit-animation-delay: 0.3s;
      animation-delay: 0.3s;
    }

    &.zd-wave-loader-l3 {
      -webkit-animation: pulse 1s infinite;
      animation: pulse 1s infinite;
      -webkit-animation-delay: 0.45s;
      animation-delay: 0.45s;
    }

    &.zd-wave-loader-l4 {
      -webkit-animation: pulse 1s infinite;
      animation: pulse 1s infinite;
      -webkit-animation-delay: 0.6s;
      animation-delay: 0.6s;
    }

    &.zd-wave-loader-l5 {
      -webkit-animation: pulse 1s infinite;
      animation: pulse 1s infinite;
      -webkit-animation-delay: 0.75s;
      animation-delay: 0.75s;
    }

    &.zd-wave-loader-l6 {
      -webkit-animation: pulse 1s infinite;
      animation: pulse 1s infinite;
      -webkit-animation-delay: 0.9s;
      animation-delay: 0.9s;
    }

    &.zd-wave-loader-l7 {
      -webkit-animation: pulse 1s infinite;
      animation: pulse 1s infinite;
      -webkit-animation-delay: 1.05s;
      animation-delay: 1.05s;
    }

    &.zd-wave-loader-l8 {
      -webkit-animation: pulse 1s infinite;
      animation: pulse 1s infinite;
      -webkit-animation-delay: 1.2s;
      animation-delay: 1.2s;
    }

    &.zd-wave-loader-l9 {
      -webkit-animation: pulse 1s infinite;
      animation: pulse 1s infinite;
      -webkit-animation-delay: 1.35s;
      animation-delay: 1.35s;
    }

  }
}
/* LOADER */


@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}



@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }

  50% {
    -webkit-transform: scaleY(0.7);
    transform: scaleY(0.7);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }

  50% {
    -webkit-transform: scaleY(0.7);
    transform: scaleY(0.7);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
}


.swing {
  transform-origin: top center;
  animation-name: swing;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.rubberBand {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

@keyframes rubberBand {
  0% {transform: scale(1);}
  30% {transform: scaleX(1.25) scaleY(0.75);}
  40% {transform: scaleX(0.75) scaleY(1.25);}
  60% {transform: scaleX(1.15) scaleY(0.85);}
  100% {transform: scale(1);}
}
