body {
  .zd-profile-page {
    .profile-container {
      margin-top: 10px;
      #zd-cancel-form html.sfm-desktop {
        margin-top: 0px !important;
      }

      .zd-profile-left {
        margin-bottom: 20px;
      }
      .zd-profile-right {
        margin-bottom: 20px;
      }
    }
    .zd-profile-card {
      background: $zd_profile;
      // border-radius: 5px;
      padding: 15px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.36);
      overflow: hidden;
    }
    .zd-profile-top {
      margin-bottom: 42px;
      position: relative;
      .top-profile {
        &:hover {
          .side_profile_hover {
            opacity: 1;
          }
          .img_cls {
            opacity: 0.4;
          }
        }
      }

      .side_profile_hover {
        position: absolute;
        left: 13%;
        top: 31%;
        display: none;
        height: 42px;
        width: 31px;
        /* right: auto; */
        color: #959aa2;
        &:hover {
          opacity: 1;
          .img_cls {
            opacity: 0.4;
          }
        }
      }

      .zd-profile-thumb {
        height: 105px;
        width: 105px;
        border-radius: 100%;
        margin-right: 15px;
        background-color: #2e2e31;
        cursor: pointer;
        @media (max-width: 750px) {
          margin: 0 auto;
          height: 80px;
          width: 80px;
        }
        &:hover {
          .side_profile_hover {
            display: block;
          }
        }
      }
      @media (max-width: 750px) {
        margin-bottom: 32px;
      }
      .zd-profile-thumb img {
        height: 105px;
        width: 105px;
        object-fit: cover;
        object-position: center;
        @media (max-width: 750px) {
          height: 80px;
          width: 80px;
        }
      }
      .zd-profile-summary {
        color: #b2b2b2;
        overflow: hidden;
        @media (max-width: 750px) {
          text-align: center;
        }
        .zd-u-name {
          font-family: Poppins;
          margin-top: 8px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #dddddd;
          text-overflow: ellipsis;
          overflow: hidden;
          margin-bottom: 0;
        }
        .zd-u-web {
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 15px;
          color: #6b7883;
        }
        .zd-profile-button {
          width: 135px;
          height: 25px;
          background: #242529;
          border-radius: 2px;
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 15px;
          text-align: center;
          text-transform: uppercase;
          color: #dddddd;
          border: none;
          outline: none;
          margin-top: 16px;
          cursor: auto;
          @media (max-width: 750px) {
            text-transform: uppercase;
          }
        }
      }
    }
    .zd-profile-middle {
      display: flex;
      padding-bottom: 24px;
      border-bottom: 1px solid #242529;
      @media (max-width: 750px) {
        justify-content: space-evenly;
      }
      .zd-profile-middle-content {
        margin-right: 40px;
        @media (max-width: 750px) {
          margin-right: 0;
        }
        .zd-u-name {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #dddddd;
          @media (max-width: 750px) {
            text-align: center;
          }
        }
        .zd-u-web {
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 15px;
          color: #6b7883;
          @media (max-width: 750px) {
            text-align: center;
          }
        }
      }
    }
    .zd-profile-bottom {
      padding: 0;
      margin-top: 40px;
      @media (max-width: 750px) {
        margin-top: 32px;
        text-align: center;
        text-transform: uppercase;
      }
    }
    .zd-profile-links {
      .list-group {
        .list-group-item {
          padding: 0;
          border: 1px solid transparent;
          border-left: none;
          border-left: none;
          background-color: transparent;
          a {
            border: 0;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #6b7883;
            padding: 6px 0px;
            display: block;
            line-height: normal;
            cursor: pointer;
            text-decoration: none;
            &.active,
            &:hover {
              color: #4f7be9;
            }
            @media (max-width: 750px) {
              padding: 12px 0px;
            }
          }
        }
      }
    }
    .zd-profile-content {
      .profile-header {
        margin-bottom: 15px;
        h2 {
          font-size: 14px;
          padding-bottom: 2px;
          display: inline-block;
        }
        .section-title {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #dddddd;
          margin-top: 5px;
        }
        .edit-link {
          float: right;
          display: block;
        }
      }
    }
  }
  .form-group-title {
    display: block;
    text-transform: uppercase;
    color: $white;
    padding: 8px 4px 8px 0;
    margin-bottom: 10px;
  }

  .form-text {
    font-size: 12px;
    color: #404043;
    padding: 8px 4px;
    &.text-muted {
      color: #96969a !important;
    }
  }
}

.zdv4-djinfo-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #dddddd;
  padding-top: 50px;
}

.zd-profile-form {
  color: #b2b2b2;
  .req {
    color: map-get($map: $theme-colors, $key: primary);
  }
  .form-group {
    margin-bottom: 0;
  }
  .form-control {
    background-color: #1f2024;
    border-color: $zd_profile;
    color: #b2b2b2;
  }
  .zd-checkbox-row {
    margin-bottom: 15px;
    .form-check {
      padding-left: 0;
    }
  }
  .text-danger {
    .form-control {
      border-color: #dc3545;
    }
    .invalid-feedback {
      font-size: 11px;
    }
  }
  .zd-card-block {
    background: $zd_profile;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.36);
    padding: 15px;
    overflow: hidden;
  }
  .zd-label-block {
    .form-checkbox {
      display: block;
      margin-bottom: 4px;
      color: #6b7883;
      &:last-child {
        margin-bottom: 12px;
      }
    }
  }
  .zd-profile-thumb img {
    width: 100%;
  }
}
.zd-modal-form {
  .form-group {
    margin-bottom: 10px;
  }
}
.btn.zd-full {
  width: 100%;
}
.form-checkbox,
.form-radio {
  cursor: pointer;
  display: inline-block;
  line-height: 18px;
  padding: 2px 16px;
  position: relative;
  color: #b2b2b2;
}

.form-checkbox .form-icon,
.form-radio .form-icon {
  border: 1px solid #ccc;
  display: inline-block;
  font-size: 11px;
  height: 11px;
  left: 0;
  line-height: 24px;
  outline: none;
  padding: 0;
  position: absolute;
  top: 5px;
  transition: all 0.2s ease;
  vertical-align: top;
  width: 11px;
}
.form-checkbox .form-icon-pre,
.form-radio .form-icon-pre {
  border: 1px solid #6b7883;
  display: inline-block;
  font-size: 11px;
  height: 11px;
  left: 0;
  line-height: 24px;
  outline: none;
  padding: 0;
  position: absolute;
  top: 5px;
  transition: all 0.2s ease;
  vertical-align: top;
  width: 11px;
}

/////// genre preferance
.zd_preferance_page {
  .form-checkbox .form-icon-pre,
  .form-checkbox .zdv3-icon-check-box,
  .form-radio .form-icon-pre {
    border: 1px solid #6b7883;
    display: inline-block;
    font-size: 11px;
    height: 20px;
    left: 0;
    line-height: 24px;
    outline: none;
    padding: 0;
    position: absolute;
    top: 3px;
    transition: all 0.2s ease;
    vertical-align: top;
    width: 20px;
    @media (max-width: 1360px) {
      height: 14px;
      width: 14px;
      top: 5px;
    }
  }
  .form-checkbox input:checked + .zdv3-icon-check-box,
  .form-radio input:checked + .zdv3-icon-check-box {
    background-color: white;
    border-color: #4f7be9;
    color: #4f7be9;
    line-height: 12px;
    transition: none;
    border: none;
    font-size: 11px;

    &::before {
      font-size: 20px;
      line-height: 21px;
      @media (max-width: 1360px) {
        font-size: 14px !important;
        line-height: 14px !important;
      }
    }
  }
}
.form-checkbox input:checked + .form-icon-pre,
.form-radio input:checked + .form-icon-pre {
  background-color: #4d7ce8;
  border-color: #3d6edd;
}

.form-checkbox input:checked + .form-icon,
.form-radio input:checked + .form-icon {
  background-color: #4d7ce8;
  border-color: #3d6edd;
}
.zd_genre_title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}
.form-checkbox input,
.form-radio input,
.form-switch input {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}
.form-radio .form-icon {
  border-radius: 0.7rem;
}
.form-checkbox .form-icon {
  border-radius: 2px;
}
.btn-grey {
  background: #35383f;
}
.btn-grey:hover,
.btn-grey:focus,
.btn-grey:active {
  background: #35383f;
  opacity: 0.9;
}
.zd-label-message {
  padding-top: 20px;
  padding-left: 20px;
  color: #919191;
  text-transform: uppercase;
  &.blue-box {
    background: map-get($map: $theme-colors, $key: primary);
    border-color: map-get($map: $theme-colors, $key: primary);
    color: $white;
    display: block;
    padding: 12px;
    border-radius: 2px;
    text-transform: none;
  }
}
.zd-profile-inner-content {
  .nav-tabs {
    border-bottom: 1px solid #6b7783 !important;
  }
}
.zd-line-tab {
  display: -webkit-inline-box;
  margin-bottom: 33px;
  .nav-item {
    .nav-link {
      padding-left: 11px !important;
      padding-right: 11px !important;
      margin-right: 0px;
      color: #6b7783;
      font-size: 14px;
      padding: 6px 0px;
      border: none;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      &.active,
      &:hover {
        // padding-right: 0 !important;
        color: #4f7be9;
        background: transparent;
        border: none;
        border-bottom: 2px solid map-get($map: $theme-colors, $key: primary);
      }
    }
  }
}

body .zd-datepicker {
  position: relative;
  display: inline-block;
  color: #b2b2b2;
  .datepicker__triangle {
    margin-left: 100px;
  }
  input {
    background: #242529;
    border-color: transparent;
    color: #6b7883;
    border-radius: 3px;
    width: 135px;
    padding: 7px 14px;
  }
  span.zd-cal-icon {
    position: absolute;
    top: 8px;
    right: 7px;
    z-index: 1;
    color: #6b7883;
    cursor: pointer;
    &:hover {
      color: #868585;
    }
  }
  .zd-last-login-date {
    color: #6b7883 !important;
  }
  .react-datepicker {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.36);
  }

  .react-datepicker__tab-loop {
    position: absolute;
    width: 230px;
    top: 0;
    line-height: 24px;
  }
  .react-datepicker,
  .react-datepicker__header {
    border-color: transparent;
  }
  .react-datepicker__header,
  .react-datepicker {
    background-color: $gray-1100;
    color: #e1e1e1;
  }
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    border-bottom-color: $gray-1100;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header,
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: #e1e1e1;
  }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range {
    background-color: map-get($map: $theme-colors, $key: primary);
  }
}

body {
  .zd-custom-radio,
  .zd-custom-checkbox {
    width: 15px;
    height: 15px;
    background: transparent;
    border-width: 2px !important;
    border-color: map-get($map: $theme-colors, $key: primary);
    border-style: solid;
    border-width: 0;
    padding: 3px;
    margin: 0 10px 0 0;
    -webkit-appearance: none;
    box-sizing: border-box;
    border-style: solid;
    border-width: 0;
    outline: 0;
    vertical-align: text-bottom;
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      display: block;
    }
    &:checked:before {
      background: map-get($map: $theme-colors, $key: primary);
      transition: all 0.25s linear 0s;
    }
  }
  .zd-custom-radio,
  .zd-custom-radio:before {
    border-radius: 100%;
  }
}
.react-datepicker-popper {
  transform: none !important;
  margin-top: 45px !important;
  .react-datepicker__triangle {
    margin-left: 100px;
  }
}
.zd-none {
  @media (max-width: 750px) {
    display: none !important;
  }
}
.zd-none.zd-side-bar {
  @media (max-width: 750px) {
    display: none !important;
  }
}

.zd-none.zd-right-column {
  @media (max-width: 750px) {
    display: none !important;
  }
}

.zd-profile-content.zd-my-profile {
  // @media (max-width: 750px) {
  //   display: none;
  // }
}
.profile-sm-hide {
  @media (max-width: 750px) {
    display: none !important;
  }
}
.profile-md-hide {
  @media (min-width: 750px) {
    display: none !important;
  }
}
.zd-profile-inner-content {
  @media (max-width: 750px) {
    margin-top: 12px;
  }
}

.profile-back-button {
  display: flex;
  align-items: center;
  color: #ddd;
  font-size: 12px;
}

.zd-side-bar {
  width: 252px;
  padding-right: 10px;
  min-width: 292px;
  @media (max-width: 750px) {
    width: 100%;
    min-width: 100%;
    padding-right: 10px;
  }
}
.zd-left-column {
  width: 252px;
  padding-right: 10px;
  min-width: 292px;
  @media (max-width: 750px) {
    width: 100%;
    min-width: 100%;
  }
}
.zd-right-column {
  width: 100%;
}

.zd-table-head {
  background-color: #1c1e22 !important;
  color: #434b52 !important;
}
.zd-table-body-row {
  background-color: #242529 !important;
  border-bottom: 2px solid #1c1e22 !important;
  transition: background-color 0.02s ease-in-out;
  border-radius: 2px;
  td {
    color: #ddd;
    border-color: transparent;
    vertical-align: middle;
  }
  &:hover {
    background-color: #28292e !important;
  }
}
.zd-table-body-active-plan-row {
  background-color: #2a3143 !important;

  border-bottom: 2px solid #1c1e22 !important;
  transition: background-color 0.02s ease-in-out;
  border-radius: 2px;
  td {
    color: #ddd !important;
    border-color: transparent;
    vertical-align: inherit !important;
  }
  &:hover {
    background-color: #28292e !important;
  }
}
.zd-blue-faded {
  color: #394671;
}
@media only screen and (min-width: 767px) and (max-width: 1224px) {
  body {
    .zd-cancellation-res {
      float: left;
      margin-left: 20px;
    }
    .zd-title-width {
      width: 48%;
    }
  }
}
@media only screen and (min-width: 0px) and (max-width: 750px) {
  body {
    .zd-cancellation-res {
      float: left;
      margin-left: 20px;
    }
    .zd-title-width {
      width: 58%;
    }
  }
}

.zd-home-sm-hide {
  @media (max-width: 750px) {
    display: none !important;
  }
}
.zd-home-lg-hide {
  @media (min-width: 750px) {
    display: none !important;
  }
}

.zd-home-md-show {
  @media (min-width: 1224px) {
    display: none !important;
  }
}
.zd-home-md-hide {
  @media (max-width: 1224px) {
    display: none !important;
  }
}
.zd-home-sm-show {
  display: block !important;
}
.zd-font {
  font-size: 12px;
}
.zd_table {
  tbody {
    tr {
      background-color: transparent !important;
      td {
        color: #434b52;
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 10000px) {
  .zd-album-table .zd-title-d-h {
    width: 50%;
  }
}
.zd-block {
  display: none !important;
  @media (max-width: 750px) {
    display: block !important;
  }
}

@media (max-width: 750px) {
  .zd_table {
    .zd-title {
      width: 52%;
    }
  }
  .zd-fixed-table {
    .zd-title {
      width: 56%;
    }
  }
}
@media only screen and (min-width: 0px) and (max-width: 10000px) {
  .zd-track-view .zd-releasee {
    width: 80px !important;
  }
}

@media only screen and (min-width: 0px) and (max-width: 440px) {
  .zd-track-view .zd-title-d-h {
    width: 100% !important;
  }
  .zd-track-view .zd-icons-d-h {
    width: 48px !important;
  }
  .zd-track-view .zd-artis-d-h {
    width: 53% !important;
    color: #434b52 !important;
  }
}

@media only screen and (min-width: 440px) and (max-width: 10000px) {
  .zd-track-view .zd-title-d-h {
    width: 100% !important;
  }
  .zd-track-view .zd-icons-d-h {
    width: 60px !important;
  }
  .zd-track-view .zd-artis-d-h {
    width: 53% !important;
    color: #434b52 !important;
  }
}
.new-ticket-btn {
  width: 193px;
  height: 28px;
  box-sizing: border-box;
  background: #4f7be9;
  border-radius: 2px;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0px;
  border: 0px;
}

.profile-sub-header {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #dddddd;
}

.profile-msg {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #6b7883;
}

.toggle-btn {
  margin-top: 0.6rem;
}
.change-plan-btn {
  background: #4f7be9 !important;
  border-radius: 2px !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}
.landing-page-container input[type='radio'] {
  display: none;
}

.landing-page-container input[type='radio'] + *::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 0.9rem;
  height: 0.9rem;
  margin-right: 0.3rem;
  border-radius: 50%;
  border-style: solid;
  border: 1px solid #6b7883;
}
.landing-page-container .child-cls input[type='radio'] + *::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 0.7rem;
  height: 0.7rem;
  margin-right: 0.3rem;
  border-radius: 50%;
  border-style: solid;
  border: 1px solid #6b7883;
}

.landing-page-container input[type='radio']:checked + * {
  color: #4d7ce8;
}

.landing-page-container input[type='radio']:checked + *::before {
  background: #4d7ce8;
  border-color: #4d7ce8;
}

.landing-page-container input[type='radio'] + * {
  display: inline-block;
}

@media only screen and (max-width: 480px) {
  .landing-page-container .landing-page-layout {
    grid-template-columns: 1fr -1fr;
    display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
    grid-gap: 7px;

    label {
      margin-right: 0px;
      padding-left: 0px;
      i {
        cursor: pointer;
      }
      span {
        margin-left: 5px;
        font-size: 12px;
        line-height: 18px;
        // color: #6b7883;
        color: #ddd;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (min-width: 480px) and (max-width: 882px) {
  .landing-page-container .landing-page-layout {
    grid-template-columns: 0.7fr 0.5fr;
    display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
    grid-gap: 15px;

    label {
      margin-right: 0px;
      padding-left: 0px;
      i {
        cursor: pointer;
      }
      span {
        margin-left: 5px;
        font-size: 12px;
        line-height: 18px;
        // color: #6b7883;
        color: #ddd;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (min-width: 883px) {
  .landing-page-container .landing-page-layout {
    grid-template-columns: 1fr 1fr;
    display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
    grid-gap: 15px;
    label {
      margin-right: 0px;
      padding-left: 0px;
      i {
        cursor: pointer;
      }
      span {
        margin-left: 5px;
        font-size: 12px;
        line-height: 18px;
        // color: #6b7883;
        color: #ddd;
        cursor: pointer;
      }
    }
  }
}

.zd_fav_artist_parent,
.zd_fav_label_parent {
  cursor: pointer;
  .zd_cross {
    display: flex;
    justify-content: flex-end;
    color: #1c1e22;
    margin-right: 14px;
  }

  @media only screen and (max-width: 768px) {
    .zd_cross_mob {
      display: block !important;
      cursor: pointer;
      justify-content: flex-end;
      color: #4f7be9 !important;
      position: absolute;
      z-index: 2;
      position: absolute;
      right: 0;
      top: 0;
    }
    .zd_cross {
      display: none;
    }
  }

  &:hover {
    .zd_cross {
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      color: #4f7be9 !important;
      margin-right: 8px;
    }
  }
}

.zd_fav_artist_parent {
  @media only screen and (max-width: 350px) {
    .zd_cross {
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      color: #4f7be9 !important;
      font-size: 18px;
      position: absolute;
      z-index: 2;
      margin-left: 270px;
      margin-top: 1px;
    }
  }
}
.zd_cross_mob {
  display: none !important;
}
.zd_fav_card {
  &:hover {
    .zd_cross {
      display: block;
    }
  }
  cursor: pointer;
  width: 138px;

  margin-right: 16px;
  margin-bottom: 16px;
  text-align: center;
  color: #ddd;
  .card {
    .card-body {
      display: flex;
      margin: 0 auto;
      height: 64px;
      padding: 14px 11px;
      span {
        display: inline-flex;
        align-items: center;
        margin-bottom: 0;
      }
    }
  }
}
.zd-fav-label {
  width: 143px;
  height: 199px;
  margin-right: 16px;
  margin-bottom: 16px;
  .cardody {
    padding: 0 !important;
  }
  .card-footer {
    text-align: center;
    color: #ddd;
    background-color: #242529;
    padding: 14px 10px;
    height: 56px;
  }
}

.page-link:hover {
  z-index: 2;
  color: #dddddd;
  text-decoration: none;
  background-color: #4f7be9;
  border-color: transparent;
}
.page-item.active .page-link {
  z-index: 1;
  color: #dddddd;
  background-color: #4f7be9;
  border-color: transparent;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: 0;
  line-height: 1.25;
  color: #dddddd;
  background-color: #1c1e22;
  border: 0 solid transparent;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.profilemodal {
  .update-profile-header {
    .update-profile-header-text {
      padding-top: 40px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #dddddd;
    }
  }

  .update-profile-body {
    padding-top: 25px !important;
    .update-profile-body-section {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      height: 250px;
      .update-profile-body-image {
        text-align: center;
        .rounded-circle {
          width: 150px;
          cursor: pointer;
        }
      }
    }
    input[type='file'] {
      display: none;
    }
    text-align: center;
    .custom-file-upload-section {
      display: flex;
      justify-content: center;
    }
    .custom-file-upload {
      display: flex;
      justify-content: center;
      width: 106px;
      height: 40px;
      background: #4f7be9;
      cursor: pointer;
      border-radius: 2px;

      span {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #ffffff;
        padding-top: 11px;
      }
    }
  }

  .update-profile-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    .modal-remove-button,
    .modal-cancel-button {
      width: 140px;
      height: 38px;
      border: 1px solid #303139;
      background: transparent;
      box-sizing: border-box;
      border-radius: 2px !important;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      cursor: pointer;
    }

    .modal-create-button {
      width: 78px;
      height: 38px;
      background: #4f7be9;
      border-radius: 2px !important;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .modal-header {
    justify-content: center;
  }
  .editor-canvas {
    width: 150px !important;
    border-radius: 50%;
    height: 150px !important;
  }

  width: 413px;
  @media (max-width: 750px) {
    width: 96%;
  }

  .modal-content {
    height: 490px;
    background: #242529 !important;
  }
  .top {
    top: unset !important;
  }
}

.zd_text_47484A {
  color: #47484a;
}
.zd_text_6F747 {
  color: #6f747e;
}
.zd-border-right-303239 {
  border-right: 1px solid #303239;
  @media (max-width: 750px) {
    border-right: none;
  }
}
#zd_download_view .infinite-scroll-component {
  min-height: 500px !important;
}
