.bottombar {
  width: calc(100vw - 66px);
  background: #1f2024;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 2;
  /* margin-left: 4px; */
  display: grid;
  margin-top: 4px;
  grid-template-columns: 0.65fr 2fr;
  height: 175px;
}

.bottombar.submenu {
  /* height: calc(100vh - 46px); */
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #242529;
}
.bottombar__subgeners .favourite-search-bar-form .favourite-search-box {
  width: 223px !important;
}
@-moz-document url-prefix() {
  .bottombar__subgeners .favourite-search-bar-form {
    color: #ddd;
  }
  .bottombar__subgeners .favourite-search-bar-form::placeholder:focus {
    color: #ddd;
  }
  .bottombar__subgeners
    .favourite-search-bar-form
    .favourite-search-box::placeholder {
    color: #6b7883;
    opacity: 1;
  }
}

.zdv3-icon-search:hover {
  color: #ffff !important;
}
.favourite-search-box::placeholder {
  color: #6b7883;
}

.favourite-search-box:hover::placeholder {
  color: #dddddd;
}
.favourite-search-box:focus::placeholder {
  color: #dddddd !important;
}

from:hover {
  color: #ffff;
}

.bottombar__subgeners {
  display: flex;
  align-items: center;
  padding: 15px;
  padding-left: 24px;
  justify-content: space-between;
  margin-right: 24px;
}
.bottombar__genre {
  margin-right: 24px;
}

.bottombar__subgeners h3 {
  color: #ddd;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.24em;
  font-family: 'Poppins', 'Roboto', sans-serif;
  margin-bottom: 0 !important;
}
.bottombar__subgeners button {
  font-size: 10px;
  background: transparent;
  border: none;
  outline: none;
  color: rgb(79, 123, 233, 1);
  font-family: 'Poppins', 'Roboto', sans-serif;
  cursor: pointer;
  padding: 0 !important;
  transition: 0.4s ease;
  font-weight: 600;
}
.bottombar__subgeners button:hover {
  color: rgb(59, 107, 228);
  text-decoration: underline;
  cursor: pointer;
}
.selector .letter {
  padding-right: 26px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 35px;
}
.selector .letter h5 {
  padding: 7px 9px 0;
  font-size: 14px;
  border-top: 2px solid transparent;
  font-weight: 500;
  font-family: 'Poppins', 'Roboto', sans-serif;

  color: #47484a;
}
.selector .letter h5.hastag:hover {
  border-top: 2px solid#4F7BE9;
  cursor: pointer;
  color: #4f7be9;
  font-weight: 500;
}
.selector .letter h5.active {
  border-top: 2px solid #4f7be9;
  color: #4f7be9;
  font-weight: 500;
}
.bottombar__tags {
  display: flex;
  flex-wrap: wrap;
}
.bottombar__tag p {
  font-size: 12px;
  margin-right: 8px;
  /* padding: 6px 12px; */
  padding: 6px 8px 6px 0px;
  background: #242529;
  color: #d8d8d8;
  font-family: 'Poppins', 'Roboto', sans-serif;
  font-weight: 500;
  border-radius: 2px;
}
.border_bottom_bar_left {
  border-left: 4px solid #4f7be9;
}
.bottombar__tag p span span {
  padding-left: 8px;
}
.bottombar__tag p:hover {
  color: #4f7be9;
}

/* Player Active */

@media screen and (max-width: 1224px) and (min-width: 750px) {
  .bottombar {
    /* position: fixed; */
    /* width: calc(34.5vw - 6px); */
    margin-left: 6px;
    display: block;
    top: 64px;
    /* background: linear-gradient(rgba(34, 35, 39, 0.95), rgba(34, 35, 39, 0.95)); */
    left: unset;
    right: 10px !important;
    height: auto;
    margin-top: 0;
  }
  .bottombar__subgeners {
    padding-left: 15px;
  }
  .alltag {
    display: block;
    padding: 5px 8px;
  }
  .tag-container {
    width: 68%;
    display: flex;
    flex-wrap: wrap;
    max-height: 96rem;
    flex-direction: column;
  }
  .tag-container::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  .alltag_sm {
    position: fixed !important;
    top: 0;
    right: 5px;
    height: calc(100vh - 19vh);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .alltag__container {
    width: 26%;
    padding-top: 5px;
  }
  .alltag__tag p {
    padding: 6px 8px;
    font-size: 10px;
    /* background: #1c1d20; */
  }
  /* .alltag__tag {
    padding: 5px;
  } */
  .septags {
    margin-top: 12px;
  }
  .alltag_az_sm {
    color: rgb(214, 214, 236);
    opacity: 0.5;
    font-size: 14px;
    padding: 0;
    font-weight: bold;
  }
  .alltag_az_sm p {
    margin-bottom: 0 !important;
  }
  .alltag_az_sm.active {
    color: rgb(59, 150, 235);
    opacity: 1;
  }
}
@media screen and (max-width: 1224px) and (min-width: 750px) and (max-height: 1100px) {
  .alltag_sm {
    position: fixed !important;
    top: 0;
    right: 5px;
    height: calc(100vh - 15vh);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}
@media screen and (max-width: 750px) {
  .bottombar__subgeners h3 {
    font-size: 15px;
    letter-spacing: 0.1em;
  }
  .bottombar {
    position: relative;
    width: 100vw;
    top: 0;
    margin-top: 0;
    margin-left: 0px !important;
    height: 64px;
    grid-template-columns: none;
    background-position: center;
    background-size: cover;
    /* background: linear-gradient(
        0deg,
        rgba(34, 35, 39, 0.9),
        rgba(34, 35, 39, 0.9)
      ),
      url('../../assets/img/All-sub-genres.png'); */
  }
  .showTag.bottombar {
    /* background: linear-gradient(rgba(0, 0, 0, 0.322), rgba(0, 0, 0, 0.363)),
      url('../../assets/img/All-sub-genres.png'); */
    position: fixed;
    top: 48px;
    width: 100vw;
    z-index: 120;
    background-size: cover;
    background-position: center;
  }
  .bottombar__subgeners {
    display: block;
    padding: 24px 0;
    padding-left: 20px;
  }
  .alltag_az_sm p {
    margin-bottom: 0 !important;
    font-size: 10px;
  }
}
@media screen and (max-height: 640px) {
  .alltag_az_sm p {
    font-size: 8px;
  }
}
@media screen and (max-width: 1224px) {
  .bottombar__subgeners .favourite-search-bar-form {
    display: none;
  }
  .genre_heading {
    display: none;
  }
}
@media screen and (max-width: 1368px) and (min-width: 1225px) {
  .bottombar__subgeners h3 {
    font-weight: 500;
    font-size: 14px;
    font-style: normal;
    letter-spacing: 0.14em;
  }
  .selector .letter h5 {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
  }
  .bottombar__tag p {
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
  }
  .bottombar {
    height: 125px;
    margin-top: 4px;
    grid-template-columns: 0.62fr 2fr;
  }
  .alltag__tag p {
    font-size: 10px;
  }
  /* .tag-container {
    max-height: 44rem !important;
  } */
  .alltag__heading {
    font-size: 12px;
  }

  /* Player Active */
  .player-active .bottombar {
    height: 130px;
  }
}
@media screen and (min-width: 1368px) {
  .player-active .bottombar {
    height: 140px;
  }

  .player-active .selector .letter {
    margin-bottom: 23px;
  }
}
@media screen and (max-width: 2000px) and (min-width: 1560px) {
  .bottombar__subgeners {
    padding-left: 136px;
  }

  .bottombar {
    grid-template-columns: 0.58fr 2fr;
    height: 220px;
  }
  .bottombar__tag p {
    font-size: 12px;
  }
  .alltag {
    padding: 20px 20px 80px 132px;
  }
  /* .tag-container {
    max-height: 55rem;
  } */
  .selector .letter h5 {
    font-size: 16px;
  }
  .player-active .bottombar {
    grid-template-columns: 0.6fr 2fr;
    height: 220px;
  }
  .player-active .selector .letter {
    margin-bottom: 66px;
  }
}

@media screen and (min-width: 2200px) {
  .bottombar {
    height: 93%;
    display: block;
    width: 39%;
    position: fixed;
    top: 50px;
    right: 5px;
    margin-top: 13px;
  }
  .bottombar__subgeners h3 {
    font-size: 20px;
  }
  .player-active .bottombar {
    height: 88.5%;
  }
  .alltag {
    width: calc(100vw - 61vw);
  }

  .alltag__container {
    width: 35%;
  }
  .alltag__tag {
    margin-bottom: 14px;
  }
  .alltag__tag p {
    font-size: 14px;
  }
  .septags {
    margin-top: 19px;
  }
  .tag-container {
    max-height: 85vh;
  }
  .alltag_sm {
    color: white;
    position: absolute;
    right: 21px;
    top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: calc(100vh - 220px);
  }
  .alltag_az_sm {
    color: rgb(214, 214, 236);
    opacity: 0.7;
    font-size: 16px;
    padding: 2px 0;
  }
  .alltag_az_sm.active {
    color: rgb(59, 150, 235);
    opacity: 1;
  }
  .alltag_az_sm.active p:hover,
  .alltag_az_sm.active p:focus {
    color: rgb(15, 139, 255);
    font-weight: bold;
    cursor: pointer;
  }
  .bottombar__subgeners {
    padding: 25px;
  }
  .player-active .tag-container {
    max-height: 85vh;
  }
}
