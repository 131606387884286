* {
  --megaWidth: calc(100vw - 54px);
}
.zd-dropdown {
  font-weight: 500;
  line-height: 1.2rem !important;
  border-color: transparent;
  border-radius: 0px !important;
  letter-spacing: 0.04rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Poppins';
  &:hover,
  &:focus,
  &:active {
    border-color: transparent !important;
    box-shadow: none !important;
    color: #dddddd;
  }
  .zip-angle-down {
    &::after {
      content: '\52';
      color: $white;
      font-family: 'zipdj' !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      speak: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: $gray-70 !important;
      border: none;
      font-size: 20px;
    }
  }
}

.zd-default-list {
  &.text-uppercase {
    .dropdown-item {
      text-transform: uppercase;
    }
  }
}
.dropdown-menu {
  background: #242529;
  border: none;
  color: #6b7883;
  border-radius: 0px !important;
  min-width: 100%;
  .dropdown-item {
    color: #6b7883;
    padding-left: 12px !important;
    height: 32px;
    border-radius: 2px;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;
    &:hover,
    &:focus,
    &:active {
      background: #28292e;
      color: $white;
      // text-decoration: underline;
      outline: none;
    }
  }
}

.zd-dropdown-crate {
  .crate-icon {
    width: 15px;
  }
  .dropdown-menu {
    background: #2e3035 !important;
    border: none;
    color: #6b7883;
    border-radius: 0px !important;
    min-width: 100%;
    padding: 0;
    margin: 0;
    .dropdown-item {
      color: #6b7883;
      padding-left: 12px !important;
      height: 32px;
      border-radius: 2px;
      font-weight: 500;
      font-size: 12px;
      cursor: pointer;
      &:hover,
      &:focus,
      &:active {
        background: #28292e;
        color: $white;
        // text-decoration: underline;
        outline: none;
        i {
          color: $white;
        }
      }
    }
    .dropdown-divider {
      margin: 0;
      border-top: 1px solid #1f2024;
    }
  }
}
.zd-nested-dropdown {
  .all-packs {
    font-weight: 500 !important;
  }
}
.zd-key-title {
  margin-left: 17px !important;
}
.zd-key-drop-menu {
  width: 196px;
  height: 447px;
  transform: translate3d(-123px, 37px, 0px) !important;
  .form-checkbox {
    padding: 7px 2px;
  }
  .form-icon-pre {
    margin: 6px 16px;
  }
  .right_col {
    border-left: 1px solid #242529;
    // padding-left: 10px !important;
  }
  .zd-key-reset {
    color: #6b7883 !important;
    padding-right: 8px;
  }
  .zd-label-left {
    padding-left: 36px;
    width: 100%;
    border-radius: 2px;
    margin-bottom: 0 !important;
    &:hover {
      background-color: #28292e !important;
    }
  }
  .zd-icon-left {
    margin-left: 17px !important;
  }
}
.zd-genre-filter-drop-menu {
  width: 306px;
  height: 447px;
  transform: translate3d(-191px, 37px, 0px) !important;
  background-color: #191b1f;
  border: 1px solid #32333a;

  @media (max-width: 750px) {
    transform: translate3d(0px, 38px, 0px) !important;
  }
}
.zd-genre-packs-drop {
  background: #191b1f !important;
  border-radius: 4px;
  border: 1px solid var(--Secondary-Border, #32333a);
  background: var(--Secondary-Background, #191b1f);
}
.zd-primary-dropdown {
  color: $gray-70;
  background: #25262a;
  font-weight: 600;
  font-family: 'Poppins', 'Roboto', sans-serif;
  color: #6b7883;

  &:hover {
    color: #dddddd !important;
    .zip-angle-down {
      &::after {
        color: #dddddd !important;
      }
    }
    .chart-genre-text {
      color: #dddddd !important;
    }
  }
  .zip-angle-down {
    &::after {
    }
  }
}
.zd-all-packs-filter-button {
  margin-right: 32px !important;
  .zd-primary-dropdown {
    max-width: 158px !important;
    width: 158px !important;
    .right-icon {
      font-size: 10px !important;
    }
  }
}
.zd-primary-dropdown-disabled {
  color: #374571;
  background: #2a3143;
  font-weight: 900;
  &:hover,
  &:focus {
    background: #23262d !important;
  }
  .zip-angle-down {
    color: #374571 !important;
    &::after {
      color: #374571 !important;
    }
  }
}

.zd-secondary-dropdown {
  color: #6b7883;
  background: $gray-1100;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;

  &:hover,
  &:focus {
    background: $gray-1100 !important;
  }
  .zip-angle-down {
    &::after {
    }
  }
}
@media (max-width: 750px) {
  .zd_v5_top_filter {
    display: none;
  }
}

.zd-dropdown {
  &.zd-text-ellipsis {
    height: 100%; // New css added
    .zip-angle-down {
      top: 9px;
      right: 10px;
      font-size: 12px;
    }
  }
}

// New CSS for Genre dropdown
.zd-nested-dropdown {
  height: 100% !important;
  display: flex;
  align-items: center;
  .zd-primary-dropdown {
    .zip-angle-down {
      top: 17px !important;
    }
  }
}
.zd-nested-dropdown {
  .zd-primary-dropdown,
  .zd-search-genre {
    .zdv3-icon-genre-filter {
      font-size: 14px;
      padding-right: 9px;
      padding-left: 4px; //biswajit change
    }
    @media (min-width: 1224px) {
      // width: 124px;
      // max-width: 124px;//- css changed
      max-width: 150px;
      // height: auto !important;
      max-height: 32px;
      height: 32px;
      border-top-left-radius: 2px !important;
      border-bottom-left-radius: 2px !important;
    }

    @media (max-width: 1224px) {
      padding: 6px 7px;
      // width: 110px;
      width: 150px; //-css changed
      border-top-left-radius: 2px !important;
      border-bottom-left-radius: 2px !important;
    }
    // @media (max-width: 751px) {
    //   width: 39px !important;
    //   display: none;
    // }
    line-height: 1rem !important;
    // border-top-right-radius: 0px !important;
    // border-bottom-right-radius: 0px !important;
    // border-radius: 0px !important;
    border: none;
    .zip-angle-down {
      top: 6px !important;
      right: 6px !important;
    }
    @media (max-width: 750px) {
      // display: none;
      position: fixed;
      // left: 10px;
      height: 36px;
      background: #25262a;
      border: 1px solid var(--Secondary-Border, #32333a) !important;

      border-radius: 4px !important;
      width: 36px !important;
      right: 85px;
      // top: 69px;
      svg {
        path {
          fill: #4d7ce8 !important;
        }
      }
    }
    .zdv3-icon-drop-down,
    .zdv3-icon-up-arrow {
      @media (min-width: 1224px) {
        padding-right: 9px; //biswajit change
        padding-left: 17px; //css changed
      }
    }
  }
}

.zd-secondary-header {
  .zd-nested-dropdown {
    .zd-dropdown {
      border-radius: 0 !important;
    }
    .zd-parent-menu {
      margin-top: -1px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
      border-radius: 0;
      height: calc(100vh - 150px);
      .dropdown-item {
        padding: 1px 5px;
      }
    }
  }
}

// - custom override
.zd-header-search-wrap.w-50 {
  width: 240px !important;
  // margin: 0 auto;
  @media (min-width: 1224px) {
    margin-right: 24px;
  }
  @media (min-width: 750px) {
    // margin-left: auto !important;
    // margin-right: 33px;
    margin-right: 85px;
  }
}
.zd-logo-margin {
  margin-right: auto;
}
.zd-bpm-dropdown {
  transform: translate3d(-240px, 37px, 0px) !important;

  @media (max-width: 750px) {
    transform: translate3d(-150px, 38px, 0px) !important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
.zd-btn-clr {
}
.key-btn-clr {
  background-color: transparent !important;
}
.btn-drop {
  &:hover {
    background-color: transparent !important;
  }
}
/* Mega Menu Style */
.zd-megagenre-parent::-webkit-scrollbar {
  display: block;
}
body {
  .zd-megagenre-parent {
    border-radius: 0;
    top: 64px !important;
    // height: calc(100vh - 120px);
    // transform: unset !important;
    // left: 54px !important;
    @media (min-width: 1224px) {
      // transform: translate3d(-380px, 0px, 0px) !important;
    }
    // padding: 20px;
    padding-bottom: 0 !important;
    // padding-top: 5px !important;
    color: #fcfefd;
    background: #1b1c20;
    // New Changes
    position: fixed;
    width: calc(100vw - 56px) !important;
    height: calc(100vh - 41px);
    left: 56px;
    overflow-y: scroll;
    @media (max-width: 1224px) {
      overflow: hidden;
      top: 64px !important;
      left: 56px !important;
      // transform: translate3d(0, 0, 0) !important;
    }
    @media (max-width: 750px) {
      overflow-y: hidden !important;
      height: calc(100vh - 48px) !important;
      top: 48px !important;
      width: 100vw !important;
      left: 0 !important;
    }
    .zd-megamenu-row {
      // max-width: 1260px;
      height: calc(100vh - 56px);
      @media (max-width: 1300px) {
        max-width: 1150px;
      }
    }
    [class^='col-'] {
      padding: 0;
      margin-bottom: 0;
    }
    .zd-megagenre-house {
      padding-left: 15px;
      padding-right: 30px;
      // width: 50% !important;
      // float: left !important;
    }
    .zd-megagenre-dance,
    .zd-megagenre-other {
      // width: 25% !important;
      // float: left !important;
    }
    .zd-megagenre-house .list-group .zd-megagenre-innre-ul {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-flow: wrap column;
      flex-flow: wrap column;
      // max-height: calc(100vh - 220px);
      max-height: 500px;
      width: 50%;
    }
    // .zd-megagenre-dance,
    // .zd-megagenre-other,
    .zd-megagenre-house {
      border-left: 1px solid #2d3133;
      border-right: 1px solid #2d3133;
    }
    .urban-section,
    .latin-section,
    .country-section {
      padding-left: 20px;
      padding-bottom: 10px;
    }
    .classics-section {
      border-top: 1px solid #2d3133;
      padding-top: 10px;
      height: 100%;
    }
    .country-section {
      border-top: 1px solid #2d3133;
      padding-top: 10px;
      height: 100%;
    }
    .latin-section {
      border-bottom: 1px solid #2d3133;
      padding-bottom: 10px;
    }
    .megamenu-logo-section {
      height: 250px;

      img {
        height: 225px;
      }
    }
    .megamenu-logo-icon {
      height: 30px !important;
    }

    .zd-megamenu-genre-title-a {
      font-size: 16px;
      font-weight: 600;
      color: #eaeaea !important;
    }
    .zd-megamenu-genre-title-a {
      &.zd-megamenu-tag-modal {
        color: #4f7be9 !important;
      }
    }

    .zd-megamenu-subgenre-title-a {
      font-size: 13px;
      color: #eaeaea !important;
    }

    .zd-megamenu-tag-title-a {
      background-color: #2c3343;
      color: #eaeaea !important;
      padding: 3px 8px;
      border-radius: 0px !important;
      margin-left: 10px;
      margin-top: 0;
      display: inline-block;
      font-size: 11px;
    }

    .list-group-item {
      border: none !important;
      padding: 5px 0px 5px 18px !important;
      background-color: transparent !important;
    }

    .list-group {
      padding-top: 2px !important;
      a:hover {
        opacity: 0.7;
      }
    }

    .zd-megamenu-subgenre-title-a:hover,
    .zd-megamenu-genre-title-a:hover,
    .zd-megamenu-tag-title-a:hover {
      color: $white;
      outline: none;
      text-decoration: underline;
    }

    .zd-megamenu-tag-title-a h3 span {
      cursor: pointer;
    }
  }
  &.player-active {
    .zd-megagenre-parent {
      margin-bottom: 56px;
      height: calc(100vh - 118px);
      overflow-x: hidden;
    }
  }
}

.zd-tertiary-header {
  .zd-nested-dropdown {
    .zd-primary-dropdown {
      .zip-angle-down {
        top: 8px !important;
      }
      @media (max-width: 750px) {
        margin-left: 25px;
      }
    }
  }
}
.close-Text-Mega {
  color: #4f7be9 !important;
  position: relative;
  bottom: 3px;
}
#megamenuclose {
  i {
    color: #4f7be9 !important;
  }
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;

  svg {
    @media (max-width: 1224px) {
      stroke-width: 70 !important;
    }
  }
  @media (max-width: 1224px) {
    top: 18px;
    right: 12px;
  }
  @media (max-width: 750px) {
    // position: fixed;
    top: 12px;
    right: 11px;
    z-index: 1010;
  }
}
.zd-search-open {
  .zd-search-genre {
    @media (max-width: 750px) {
      display: none !important;
    }
  }
}
#zd-lg-filter {
  @media (max-width: 750px) {
    display: none !important;
  }
}
#zd-sm-filter {
  @media (min-width: 1224px) {
    display: none;
  }
}
body.player-active {
  .zd-megagenre-parent {
    .zd-megamenu-row {
      height: calc(100vh - 140px);
    }
  }
}
.body-overflow-hidden .zdv3-icon-drop-down {
  transition: transform 0.3s ease-in-out;
  transform: rotate(180deg);
}
.body-overflow-hidden .zd-mega-dropdown {
  @media (min-width: 750px) {
    color: #ddd !important;
    background-color: #4f7be9;
    &:hover {
      color: #ddd !important;
      background-color: #2d5dc8;
    }
  }
}

#search-genres {
  .filter-applied {
    color: #ddd !important;
  }
  .zd-search-genre {
    &:hover {
      .filter-applied {
        color: #ddd !important;
      }
    }
  }
  .zdv3-icon-reset {
    font-size: 16px;
   
    @media (max-width: 750px) {
      margin-right: 24px !important;
    }
    align-self: center;
    // color: #fbba3e;
    &:hover {
      color: #ddd;
    }
  }
}
.show {
  .genre-preference-menu {
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 5px;
    grid-gap: 4px;
    padding: 6px;

    .dropdown-item {
      border: 1px solid;
      padding-right: 6px;
    }
  }
}

.zd-history-drop-down {
  top: -4px !important;
  border-radius: 2px !important;

  .zd-key-reset {
    cursor: pointer;
  }

  .zd-bpm-alert {
    background: none;
    padding-left: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    color: #4f7be9;
  }

  .row {
    flex-wrap: inherit !important;
    .left_col {
      // padding-left: 30px;
      padding-right: 0;
      div {
        color: #4f7be9;
      }
      .form-checkbox {
        &:hover {
          background-color: #28292e;
        }
      }
    }
    .right_col {
      // padding-right: 13px;
      padding-left: 0;
      div {
        color: #4f7be9;
      }
    }
  }
}
.zdv3-icon-filled-downWard-arrow:before {
  content: '\ea19';
  // font-size: 9px;
  font-size: 14px;
}
.zd-bpm-reset {
  cursor: pointer;
  &:hover {
    color: #ddd !important;
  }
}
.bpm_dropdown {
  min-width: 308px !important;
  padding: 10px;
  .form-control {
    display: block;
    width: 97px;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.3rem 0.5rem;
    font-size: 0.7rem;
    font-weight: 400;
    line-height: 1.5;
    color: #ddd;
    background-color: #242529;
    background-clip: padding-box;
    border: 1px solid #47484a;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}

.zd-top-dropdown {
  color: #6b7883;
  border: 1px solid #32333a;
  background: $gray-1100;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;

  &:hover,
  &:focus {
    background: $gray-1100;
  }
  .zip-angle-down {
    &::after {
    }
  }
}
.zd-text-genre-filter {
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 400;
  padding-left: 8px;
}
// ...Css changed
.zd-filter-dates {
  .zd-nested-dropdown {
    .zd-dropdown {
      color: #eaeaea;
      &:hover {
        color: #4f7be9;
      }
    }
  }
}
.zd-dropp {
  margin-right: 14px !important;
}
.zd_new_top_genre {
  color: #ddd !important;
}
