.react-jinke-music-player-main {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  td,
  button,
  span,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-family: 'Poppins', sans-serif !important;
  }
}

body {
  &.player-active {
    .cr-page {
      padding-bottom: 50px;
    }
    .zd-download-manager-sidebar .zd-download-footer {
      bottom: 85px;
    }
  }
  .react-jinke-music-player-main {
    .music-player-panel {
      box-shadow: none;
      background-color: $media-player-bg;
      height: 64px;
      z-index: 1018;
      display: none !important;
      &.active {
        display: block !important;
      }
      svg,
      // a,
      i {
        color: #6b7883;
      }

      svg:active {
        color: #4f7be9 !important;
      }
      .duration,
      .reload-btn {
        display: none !important;
      }
      .current-time {
        align-self: center;
        padding-left: 1rem;
        font-weight: 300;
        font-size: 12px;
      }
      .panel-content {
        padding: 0;
        .img-content {
          animation: unset;
          width: 64px;
          height: 64px;
          border-radius: 0;
          padding: 4px 0;
          border-radius: 2px !important;
        }
        .progress-bar-content {
          padding: 10px 20px 10px 20px;
          height: 100%;
          @media (max-width: 750px) {
            padding: 10px 5px 10px 10px;
          }
          .audio-title {
            display: block;
            float: left;
            padding-top: 2px;
            max-width: 184px;
            width: 184px;
            @media (max-width: 750px) {
              max-width: 180px;
              width: 180px;
            }
            @media (max-width: 450px) {
              max-width: 150px;
              width: 150px;
            }
            @media (max-width: 400px) {
              max-width: 120px;
              width: 120px;
            }
            @media (max-width: 375px) {
              max-width: 85px;
              width: 85px;
            }
            @media (max-width: 330px) {
              max-width: 50px;
              width: 50px;
            }
            @media (max-width: 330px) {
              display: none;
            }
            .the-name {
              display: block;
              color: $white;
              font-weight: 500;
              font-size: 12px;
              // margin-bottom: 5px;
              line-height: 18px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .the-mix {
              display: block;
              em {
                font-style: normal;
                color: #757575;
                line-height: 12.1px;
              }
            }
            .the-artist {
              font-size: 12px;
              font-weight: 300;
              color: #ffffff !important;
              em {
                font-style: normal;
                // color: #757575;
                line-height: 12.1px;
              }
            }
          }
          .audio-main {
            float: right;
            width: calc(100% - 200px);
            margin-top: 0;
            height: 100%;
            @media (max-width: 750px) {
              float: none;
            }
          }
          .progress-bar {
            height: 100%;
            width: 100%;
            padding-top: 2px;
            padding-bottom: 2px;
            // background: url(../assets/img/player-wave.png);
            background-repeat: no-repeat;
            background-position: left center;
            background-size: contain;
            background-color: transparent;
            margin-right: 0;
            margin-top: 0;
            .zd-manage-track-player {
              margin-left: 24px;
              margin-right: 24px;
              @media (max-width: 750px) {
                margin-left: 10px;
                margin-right: 10px;
              }
            }
            .zd-wave-preview {
              height: 100%;
              width: 100%;
              max-width: none;
              position: absolute;
              transition-property: visibility, opacity, transform;
              transition-duration: 0.3s;
              transition-timing-function: ease-out;
              -moz-transition-property: visibility, opacity, transform;
              -moz-transition-duration: 0.3s;
              -moz-transition-timing-function: ease-out;
              -webkit-transition-property: visibility, opacity, transform;
              -webkit-transition-duration: 0.3s;
              -webkit-transition-timing-function: ease-out;
              -o-transition-property: visibility, opacity, transform;
              -o-transition-duration: 0.3s;
              -o-transition-timing-function: ease-out;
              -ms-transition-property: visibility, opacity, transform;
              -ms-transition-duration: 0.3s;
              -ms-transition-timing-function: ease-out;
            }
            .progress-load-bar {
              display: none;
            }
          }
          // .rc-slider {
          //   height: 100%;
          //   padding: 0;
          //   .rc-slider-track {
          //     opacity: 0.4;
          //     height: 100%;
          //     border-radius: 0;
          //     background-color: transparent !important;
          //   }
          //   .rc-slider-handle {
          //     display: none;
          //   }
          //   .rc-slider-rail {
          //     display: none;
          //   }
          // }
        }
        .player-content {
          padding-left: 20px;
          // margin-right: -13px;
          .play-sounds {
            margin-left: 0px;
            .sounds-icon {
              margin-right: 10px;
              @media (max-width: 1224px) and (min-width: 750px) {
                flex: none;
              }
              i {
                font-size: 22px;
              }
            }

            .sound-operation {
              width: 80px;
              .rc-slider-handle {
                width: 12px;
                height: 12px;
                background-color: #909090 !important;
                &:focus {
                  box-shadow: none;
                  border-color: none;
                }
              }
            }
          }
          .player-close {
            // width: 20px;
            max-width: 20px;
            margin-right: 32px;
            @media (max-width: 750px) {
              margin-left: 20px;
            }
            @media (max-width: 750px) {
              margin-left: 10px;
            }
          }
          .group {
            padding: 0;
          }
          .volume-icon {
            font-size: 24px !important;
          }

          .heart-icon {
            padding-right: 20px;
          }
          .play-btn {
            padding: 0 12px;
            margin: 0 10px;
            svg {
              font-size: 40px;
            }
          }
          .audio-keys-btn {
            max-width: 30px;
            margin-right: 20px;
          }
          .audio-keys-icon {
            i {
              font-size: 24px;
            }
            svg {
              font-size: 22px;
              margin-top: 3px;
            }
          }
          .audio-lists-btn {
            min-width: unset;
            margin-right: 10px !important;
            margin-left: 15px !important;
            box-shadow: none !important;
            background-color: transparent !important;
          }
          .audio-download {
            i {
              color: $white;
              font-size: 16px;
            }
            margin-right: 8px;
          }
          .prev-audio,
          .next-audio,
          .audio-download,
          .audio-lists-icon,
          .play-sounds {
            svg,
            i {
              font-size: 16px;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
body
  .react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .play-sounds
  .sound-operation
  .rc-slider-track {
  background-color: #4f7be9 !important;
}

body .react-jinke-music-player-main {
  .rc-slider-track,
  .rc-slider-rail {
    height: 4px;
  }
}

body .hot-keys-panel {
  background-color: #1f2024;
  height: 370px;
  width: 380px;
}
body .zd-media-panel-content {
  padding: 10px;
  padding-top: 0px;
  color: #919191;
  font-size: 13px;
  line-height: 20px;

  .panel-content-bg {
    background-color: #242529;
    padding: 10px;
  }
}

.zd-hot-keys-content {
  h4 {
    font-size: 16px;
    color: #e7e7e7;
    margin: 0 0 10px 0;
  }
  ul {
    margin: 0 0 20px 0;
  }
}

body .react-jinke-music-player-main {
  .react-jinke-music-player-mobile {
    top: auto;
    padding: 15px;
    .react-jinke-music-player-mobile-play-model-tip {
      display: none !important;
    }
    .play-btn {
      svg {
        color: $white;
      }
    }
    .audio-download {
      color: #6b7883;
      font-size: 20px;
    }
    .audio-download-icon {
      color: #6b7883 !important;
    }
    svg,
    a,
    i {
      color: #6b7883;
    }
    svg:hover,
    svg:active,
    a:hover,
    i:hover {
      color: #b7b7b7 !important;
    }
    .rc-slider-handle:focus {
      box-shadow: none;
      border-color: none;
      background-color: #4f7be9;
    }
    .rc-slider-track,
    .rc-slider-handle {
      background-color: #909090 !important;
    }
  }
  .react-jinke-music-player-mobile-toggle {
    padding: 2px 0 0 0;
    .play-btn {
      padding: 0 20px;
    }
    .next-audio,
    .prev-audio {
      svg {
      }
    }
  }
}

body .audio-lists-panel-content .audio-item .player-time {
  overflow: hidden;
  line-height: 17px;
}

.circle {
  width: 32px;
  height: 32px;
  border: 1px solid #2e2e2e;
  border-radius: 50%;
}
.clear-all {
  color: #5c656d;
  font-size: 12px;
  cursor: pointer;
  padding-left: 5px;
}

.vertical-sound {
  padding-left: 13px !important;
  position: relative;
  // transform: translateX(0);
  margin-right: 0px !important;
}

.vertical-sound:hover {
  .vertical-sound-bar {
    display: block;
  }
}
@media (min-width: 1224px) {
  .vertical-sound-bar {
    position: absolute;
    display: none;
    bottom: 32px;
    right: 10px;
    height: 90px;
    width: 18px;
    // padding: 10px;
    background-color: #1f2024;
    &:hover {
      display: inline-block;
    }
  }
  .vertical-sound-bar:hover {
    display: block;
  }
  .horaizonal-sound-bar {
    display: none;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1224px) {
  .horaizonal-sound-bar {
    position: relative;
    // display: none;
    bottom: 0px;
    right: 0px;
    height: 12px;
    width: 100px;
    // padding: 10px;
    background-color: #1f2024;
    &:hover {
      display: inline-block;
    }
  }
}

.rc-slider-vertical .rc-slider-handle {
  margin-left: -4px !important;
}

.audio-lists-panel {
  transition: max-height 1s;
  background: #1f2024;
  .audio-lists-panel-header {
    border: none;
    box-shadow: none;
  }
  .audio-lists-panel-content {
    padding-left: 20px;
    padding-right: 20px;

    .audio-item {
      background: #242529 !important;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 2px;
      height: 48px;
      line-height: 18px !important;

      .audio-item-song-details {
        flex-grow: 1;
        font-size: 12px;

        .audio-item-name {
          color: #ffffff;
          font-weight: 500;
        }

        .audio-item-singer {
          color: #6b7883;
          font-weight: 400;
        }
      }
      .player-icons {
        color: #4f7be9;
        i {
          font-size: 18px !important;
        }
      }
      &.playing {
        background: #394671 !important;
        .audio-item-singer {
          color: #8e8e8e;
        }

        .player-delete svg {
          color: #8e8e8e !important;
        }
      }
    }

    .player-delete {
      color: #6b7883;
    }
  }
}

.audio-lists-panel.expand {
  min-height: 80vh;
  transition: min-height 1s;
}
.mouse-interactions {
  i,
  svg {
    &:hover {
      color: #dddddd !important;
    }
    &:active {
      color: #4f7be9 !important;
    }

    &:focus {
      color: #4f7be9 !important;
    }
    &:disabled {
      color: #47484a !important;
    }
  }
}

.circle-play {
  position: relative;
  height: 40px;
  width: 40px;
  .play-icons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .zdv3-icon-play {
      margin-left: 3px;
    }
  }
}
.expand-arrow {
  color: #4f7be9;
  transform: scaleY(-1);
}

.zdv3-icon-close-player {
  font-size: 16px !important;
  color: #6b7883 !important;
}

.circle-play {
  .zdv3-icon-play {
    font-size: 1.2rem !important;
  }
  .zdv3-icon-pause {
    font-size: 1rem !important;
  }
}

.zd-player-section-2 {
  margin-right: -13px;
  margin-left: -15px;
  padding-left: 22px !important;
  @media (max-width: 750px) {
    padding-left: 10px !important;
    margin-right: -4px;
  }
}
