.zd-wave-loader {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
svg.zd-wave-loader-relative {
  position: relative;
  top: auto;
  left: auto;
  display: inline-block;
}
svg.zd-wave-loader-iframe {
  position: absolute;
  top: auto;
  left: 0;
  margin: 50%;
  display: block;
  right: 0;
  z-index: 0;
}
body svg.zd-wave-loader-small {
  width: 20px;
  height: 20px;
  position: relative;
  top: auto;
  left: auto;
  display: inline-block;
}
body .zd-active-loader {
  position: relative;
  margin: 0px;
  height: 32px;
  width: 32px;
  padding: 6px;
  flex-basis: 32px;
}
body .zd-active-loader svg.zd-wave-loader-small {
  position: relative;
  left: 10px;
  top: 10px;
  display: block;
}
