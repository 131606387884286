@import url(https://fonts.googleapis.com/css?family=Noto+Sans:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i);
// @import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i);

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Khula:wght@300;400;600;700;800&display=swap');

/* Font Smoothing */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
td,
button,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.display-1,
.display-2,
.display-3,
.display-4,
.navbar,
.brand,
.alert {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: unset;
  font-family: "Poppins", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: $font-weight-normal;
}
// html{
//   overflow: auto;
//   // hides scroll bar in IE and Edge
//   -ms-overflow-style: none;
//   // hides scroll bar in Firefox
//   scrollbar-width: none;
// }
// // Hides scrollbar in Chrome, Safari, Opera
// body::-webkit-scrollbar {
//   display: none;
// }
.cr-app {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.clearfix {
  clear: both;
}

.btn {
  border-radius: 0px !important;
}
