$sk-bold-weight: 700 !default;
$sk-medium-weight: 500 !default;
$sk-light-weight: 300 !default;

$sk-font-family: $font-family-sans-serif;

$sk-layout-background: $body-bg !default;
$sk-layout-panel-background: $body-bg !default;
$sk-primary-theme-color: #2a4466 !default;
$sk-active-bg-color: $blue !default;
$sk-border-color: $list-group-border-color !default;
$sk-title-color: $body-color !default;
$sk-text-color: $body-color !default;
$sk-text-secondary-color: #777 !default;
$sk-list-text-color: #666 !default;
$sk-list-secondary-text-color: $group_table_border !default;
$sk-action-text-color: $blue !default;
$sk-action-active-color: #000 !default;
$sk-disabled-text-color: $navbar-light-disabled-color !default;
$sk-remove-action-color: $red-100 !default;

$sk-search-color: #fff !default;
$sk-search-placeholder-color: rgba(255,255,255,1) !default;

$sk-selector-background: $body-bg !default; 
$sk-selector-active-background: $blue !default; 
$sk-selector-border-color: $group_table_border !default; 
$sk-selector-text-color: $body-color !default; 
$sk-selector-active-text-color: $body-color !default; 

$sk-selected-filters-background: $body-bg !default;

$sk-heading-font-size: $font-size-base !default;
$sk-text-font-size: $font-size-base !default;
$sk-list-font-size: $font-size-base !default;
$sk-action-font-size: $font-size-base !default;
$sk-list-secondary-font-size: $font-size-base * 0.85 !default;

$sk-border-radius: 3px !default;
