// Darkly 4.3.1
// Bootswatch

// Variables ===================================================================

$web-font-path: 'https://fonts.googleapis.com/css?family=Noto+Sans:400,700,400italic' !default;
@import url($web-font-path);

body,
body a {
  -webkit-font-smoothing: unset;
  text-rendering: optimizeLegibility;
}
a {
  color: $link-color;
}
// Navbar ======================================================================

.gray-text {
  color: $gray-90;
}
.bg-primary {
  .navbar-nav .active > .nav-link {
    color: $white !important;
  }
}

.bg-light {
  &.navbar {
    background-color: $black !important;
  }

  &.navbar-light .navbar-nav {
    .nav-link:focus,
    .nav-link:hover,
    .active > .nav-link {
      color: $white !important;
    }
  }
}

// Buttons =====================================================================

// Typography ==================================================================

.blockquote {
  &-footer {
    color: $gray-600;
  }
}

// Tables ======================================================================

.table {
  &-primary {
    &,
    > th,
    > td {
      background-color: $primary;
    }
  }

  &-secondary {
    &,
    > th,
    > td {
      background-color: $secondary;
    }
  }

  &-light {
    &,
    > th,
    > td {
      background-color: $light;
    }
  }

  &-dark {
    &,
    > th,
    > td {
      background-color: $dark;
    }
  }

  &-success {
    &,
    > th,
    > td {
      background-color: $success;
    }
  }

  &-info {
    &,
    > th,
    > td {
      background-color: $info;
    }
  }

  &-danger {
    &,
    > th,
    > td {
      background-color: $danger;
    }
  }

  &-warning {
    &,
    > th,
    > td {
      background-color: $warning;
    }
  }

  &-active {
    &,
    > th,
    > td {
      background-color: $table-active-bg;
    }
  }

  &-hover {
    .table-primary:hover {
      &,
      > th,
      > td {
        background-color: darken($primary, 5%);
      }
    }

    .table-secondary:hover {
      &,
      > th,
      > td {
        background-color: darken($secondary, 5%);
      }
    }

    .table-light:hover {
      &,
      > th,
      > td {
        background-color: darken($light, 5%);
      }
    }

    .table-dark:hover {
      &,
      > th,
      > td {
        background-color: darken($dark, 5%);
      }
    }

    .table-success:hover {
      &,
      > th,
      > td {
        background-color: darken($success, 5%);
      }
    }

    .table-info:hover {
      &,
      > th,
      > td {
        background-color: darken($info, 5%);
      }
    }

    .table-danger:hover {
      &,
      > th,
      > td {
        background-color: darken($danger, 5%);
      }
    }

    .table-warning:hover {
      &,
      > th,
      > td {
        background-color: darken($warning, 5%);
      }
    }

    .table-active:hover {
      &,
      > th,
      > td {
        background-color: $table-active-bg;
      }
    }
  }
}

// Forms =======================================================================

.input-group-addon {
  color: $white;
}

// Navs ========================================================================

.nav-tabs,
.nav-pills {
  .nav-link,
  .nav-link.active,
  .nav-link.active:focus,
  .nav-link.active:hover,
  .nav-item.open .nav-link,
  .nav-item.open .nav-link:focus,
  .nav-item.open .nav-link:hover {
    color: $white;
  }
}

.breadcrumb a {
  color: $white;
}

.pagination {
  a:hover {
    text-decoration: none;
  }
}

// Indicators ==================================================================

.close {
  opacity: 0.4;

  &:hover,
  &:focus {
    opacity: 1;
  }
}

.alert {
  border: none;
  color: $white;

  a,
  .alert-link {
    color: $white;
    text-decoration: underline;
  }

  @each $color, $value in $theme-colors {
    &-#{$color} {
      @if $enable-gradients {
        background: $value
          linear-gradient(180deg, mix($white, $value, 15%), $value)
          repeat-x;
      } @else {
        background-color: $value;
      }
    }
  }
}

// Progress bars ===============================================================

// Containers ==================================================================

.list-group-item-action {
  color: $white;

  &:hover,
  &:focus {
    background-color: $gray-700;
    color: $white;
  }

  .list-group-item-heading {
    color: $white;
  }
}

.card {
  border-radius: 4px !important;
  background: #292A2F;
  .list-group:last-child .list-group-item:last-child {
    border-radius: 0px !important;
  }
 
}
.zd-trending-packs-view-cover-text{
    padding-bottom: 9px !important;
    border-bottom: 2px solid;
    font-size: 8px !important;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    border-image-slice: 2;
    line-height: 9px !important;
}
.zd-trending-pack-tags {
  padding-bottom: 5px;
  border-bottom: 2px solid;
  font-size: 12px;
  // font-weight: 600;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  border-image-slice: 2;
}

.zd-feedback-blue-text {
  color: #4f7be9 !important;
}

.feedback-td {
  // display: table-cell !important;
  // float: left !important;
  width: 100% !important;
  height: 100% !important;
}

.zd-icon.zdv3-icon-play.zd-icon-repeat-track {
  color: #666;
}
