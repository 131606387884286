.genre_cont {
  display: flex;
  height: 100vh; /* Adjusts container height to the full screen height */
  max-width: 100% !important;
  background-color: #1f2126;
  padding-bottom: 8vh;
  .favourite-search-bar-form .favourite-search-label .favourite-search-box {
    width: 192px;
    height: 29px;
    position: absolute;
    color: #ddd;
    background-color: #242529;
    border: none;
    padding-bottom: 2px;
  }
  .favourite-search-bar-form .favourite-search-label {
    height: 0px;
    background-color: #242529;
  }
  .favourite-search-bar-form {
    width: 252px;
    height: 32px;
    margin-bottom: 12px !important;
  }
}

.left-column {
  flex-basis: 20%;
  border-right: 1px solid #32333a;
  padding: 15px;
}

.right-column {
  flex-basis: 80%;
  padding: 15px;
  // div p{
  //   display: none;
  // }
}

.zd-v4-genere-search {
  border: 1px solid #32333a;
}
.zd-main-genre {
  border-bottom: 1px solid #32333a;
  padding: 10px 10px 10px 0px;
  color: #dddddd;
  span {
    font-size: 13px;
    line-height: 21px;
    font-weight: 500;
    cursor: pointer;
    &:hover {
      color: #4f7be9;
    }
  }
}
.genre_cont {
  .zd-v4-genere-search {
    background-color: #2e2f34 !important;
  }
  .favourite-search-box {
    background-color: #2e2f34 !important;
  }
}
.zd-cat {
  border-bottom: 1px solid #32333a;
  // text-transform: uppercase;
  font-size: 13px;
  padding: 2px 0px 10px 0px;
  color: #4978ef;
}
.zd-bot-card {
  margin-top: 25px;
  border-radius: 5px;
  padding: 10px;
  background-color: #32333a;
  h5 {
    font-size: 16px;
    color: #dddd;
  }
  p {
    font-size: 12px;
    margin-top: 10px;
    color: #ddd;
    span {
      color: #4978ef;
    }
  }
  a {
    color: #4978ef !important;
  }
}
.genre_cont {
  height: auto;
}
.containes {
  display: flex;
  flex-wrap: wrap;
  span {
    cursor: pointer;
    &:hover {
      color: #4f7be9;
    }
  }
}

.column {
  margin-right: 25px;
  color: #ddd;
  flex-basis: 28%;
  box-sizing: border-box;
  padding: 12px 10px 12px 0px;
  border-bottom: 1px solid #32333a;
}

@media (min-width: 1440px) {
  .column {
    margin-right: 20px;
    color: #ddd;
    flex-basis: 23%;
    box-sizing: border-box;
    padding: 12px 10px 12px 0px;
    border-bottom: 1px solid #32333a;
  }
}

@media (max-width: 1440px) and (min-width: 1240px) {
  .column {
    margin-right: 20px;
    color: #ddd;
    flex-basis: 28%;
    box-sizing: border-box;
    padding: 12px 10px 12px 0px;
    border-bottom: 1px solid #32333a;
  }
}
@media (max-width: 1240px) {
  .column {
    margin-right: 20px;
    color: #ddd;
    flex-basis: 29%;
    box-sizing: border-box;
    padding: 12px 10px 12px 0px;
    border-bottom: 1px solid #32333a;
  }
}
@media (max-width: 750px) {
  .left-column {
    border-right: none;
  }
  .right-column {
    div p {
      display: block;
      border-bottom: 1px solid #32333a;
      margin-bottom: 0;
      padding-bottom: 10px;
      color: #4978ef;
    }
  }
  .genre_cont {
    .zd-v4-genere-search{
      display: block !important;
      margin-left: 0;
    }
    padding-bottom: 312vh;
    display: block !important;
    .column {
      margin-right: 20px;
      color: #ddd;
      flex-basis: 100%;
      box-sizing: border-box;
      padding: 12px 10px 12px 0px;
      border-bottom: 1px solid #32333a;
    }
  }
}
