.modal {
  z-index: 1111;

  &.show {
    display: -webkit-box;
    display: flex;
    display: -ms-flexbox;
    display: -webkit-flex;
    opacity: 1;
  }
  &.fade {
    .modal-dialog {
      transition: none !important; //ransform 0.3s ease-out;
    }
  }
  .modal-dialog {
    -webkit-animation: none !important; //slide-down 0.2s;
    animation: none !important; //slide-down 0.2s;

    .modal-header {
      background: #222327;
      padding: 1rem;
      border: none;

      .modal-title {
        color: #eaeaea;
        font-size: 1rem;
        margin: 0;
        font-weight: bold;
      }

      .close {
        color: #eaeaea;
        opacity: 1;
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
      }
    }

    .modal-footer {
      background: #222327;
      padding: 1rem;
      border: none;
    }

    .modal-body {
      max-height: none;
      overflow: hidden;
      padding: 1.1rem;
    }
  }

  .modal-content {
    background-color: #1c1e23;
    border: none;
    letter-spacing: 0.05em;
    // border-radius: 5px;
    color: $white;
    overflow: hidden;

    .zd-tag-list-wrap {
      .zd-head-info-wrap {
        background: #1c1e23;
        position: absolute;
        top: 10px;
        width: 92%;
        margin-bottom: 10px;
        z-index: 2;
        min-height: 300px;
      }
    }

    .zd-tags-group-content {
      padding-top: 40px;
      overflow: hidden;
    }

    .content {
      max-height: 70vh;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

.modal-backdrop {
  &.show {
    background: rgba(60, 59, 59, 0.94);
    opacity: 0.96;
  }
}

.zd-flash-modal {
  position: fixed;
  display: none;
  top: 50%;
  left: calc(50% + 56px);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  margin-left: -25px;
  width: 300px;
  text-align: center;
  padding: 45px 20px;
  color: #eaeaea;
  font-weight: bold;
  background: #0d0d0d;
  z-index: 99999;
  font-size: 110%;
  background: #19191a;
  -webkit-box-shadow: 0 3px 6px #171717;
  box-shadow: 0 3px 6px #171717;
  border: 1px solid #232323;

  &.zd-open-modal {
    display: block !important;
  }

  i {
    display: block;
    font-size: 50px;
    color: #4d7ce8;
  }

  span.zd-message {
    margin-top: 15px;
    display: block;
    font-size: 14px;
    font-weight: normal;
    color: #e1e1e1;
  }
}
@media (min-width: 576px) {
  // .modal-dialog {
  //   max-width: 620px;
  // }

  .modal-sm {
    max-width: 320px;
  }
}

.modal {
  .zd-modal-form-error-msg {
    line-height: 1.1em;
    margin: 8px 0px 10px;
  }
}

.zd-modal-content {
  height: 80px;
}
.zd-feedback-modal-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  .zd-feedback-modal-body-content {
    .zd-modal-content {
      height: 80px;
      padding: 0 20px;
      line-height: 2rem;
    }
  }

  i {
    display: block;
    font-size: 50px;
    color: #4d7ce8;
  }

  span.zd-message {
    margin-top: 15px;
    display: block;
    font-size: 14px;
    font-weight: normal;
    color: #e1e1e1;
  }

  .form-group {
    margin-bottom: 0.5rem;
  }

  .zd-reviews-star-hover1 {
    color: red;
  }

  .zd-reviews-star-hover2 {
    color: orange;
  }

  .zd-reviews-star-hover3 {
    color: yellow;
  }

  .zd-reviews-star-hover4 {
    color: green;
  }

  .zd-reviews-star-hover5 {
    color: #4f7be9;
  }

  .zd-feedback-span-message {
    line-height: 1.1em;
    min-height: 2.2em;
    font-size: 10px;
  }

  .zd-feedback-span-message1,
  .zd-feedback-span-message2,
  .zd-feedback-span-message3,
  .zd-feedback-span-message4,
  .zd-feedback-span-message5 {
    display: none;
  }

  .active-span {
    color: white; // #c7c0c0;// #606060;
    display: block;
    margin: 8px 0px 0px;
  }

  .form-label {
    color: #eaeaea;
  }
}

span.zd-message {
  margin-top: 15px;
  display: block;
  font-size: 14px;
  font-weight: normal;
  color: #e1e1e1;
}

.form-group {
  margin-bottom: 0.5rem;
}

.zdv3-icon-star {
  color: #6b7883;
  padding-left: 0.2rem !important;
}

// .zd-reviews-star-hover1 {
//   color: red;
// }

// .zd-reviews-star-hover2 {
//   color: orange;
// }

// .zd-reviews-star-hover3 {
//   color: yellow;
// }

// .zd-reviews-star-hover4 {
//   color: green;
// }

.zd-reviews-star-hover {
  color: #4f7be9 !important;
}
.zd-feedback-rating-comment-section {
  width: 56%;
}

.zd-feedback-form {
  .form-group {
    margin-bottom: 0;
  }
  .form-control {
    background-color: #1f2024;
    /* border-color: #212226; */
    border: none;
    border-radius: 2px !important;
  }
}
.zd-change-icon {
  color: #4f7be9 !important;
}
.zd-feedback-comment {
  width: 100% !important;
  // margin-right: 16px;
  padding-left: 16px;
  position: relative;
  input {
    // color: #ddd !important;
    height: 32px !important;
  }
  input::placeholder {
    color: #6b7883;
  }
  i {
    position: absolute;
    top: 6px;
    right: 8px;
    color: #303139;
    font-size: 14px;
  }
  &:hover {
    i {
      color: #dddddd;
    }
  }
  input:hover,
  input:focus {
    cursor: pointer;
    color: #dddddd;
    box-shadow: none;
    &::placeholder {
      color: #dddddd;
    }
  }

  // input:focus {
  //   box-shadow: none;
  // }
  // i:hover {
  //   color: #4F7BE9;
  // }
}
.zd-feedback-dropdown-btn {
  height: 32px;
  width: 200px;
  float: right;
  border-radius: 2px !important;
  padding-left: 12px !important;
  padding-right: 8px !important;
  padding-top: 7px;
  padding-bottom: 7px;
  color: #6b7883;
  background: #1f2024;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.2rem !important;
  border-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: left;
  font-family: 'Poppins';
  .zd-feedback-favourite-mix-value {
    text-align: left;
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
  }

  &.btn-secondary:hover,
  &.btn-secondary:focus {
    border: none;
    background: #1f2024;
    color: #dddddd;
    box-shadow: 0 0 0 0;
  }
  &.btn-secondary:focus {
    .zdv3-icon-drop-down {
      color: #4f7be9 !important;
    }
  }
  &.btn-secondary:not(:disabled):not(.disabled):active {
    border: none;
    background: #303139;
    color: #dddddd;
    box-shadow: 0 0 0 0;
  }
}

.zd-feedback-release-highlight {
  .zd-feedback-active-border {
    right: 17px !important;
  }
}
.zd-feedback-active-border {
  position: absolute;
  background: #4f7be9;
  height: 80px;
  width: 4px;
  right: 0;
  border-radius: 0 2px 2px 0;
}

.zd-feedback-drop-down {
  top: -1px !important;
  // border-radius: 2px !important;
  background: #242529;
  border: none;
  color: #6b7883;
  padding: 0;
  // border-radius: 0px !important;
  min-width: 200px;
  button {
    // padding-left: 12px !important;
    // padding-right: 8px !important;
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
    :hover {
      background-color: #303139;
    }
  }
}

@media (min-width: 1366px) {
  .zd-feedback-drop-down.dropdown-menu.show {
    position: absolute !important;
    will-change: transform !important;
    overflow: hidden scroll !important;
    height: 200px !important;
    width: 200px !important;
    left: 78px !important;
    pointer-events: all;
    // transform: unset !important;
    transform: translate3d(49px, 31px, 0px) !important;
    button {
      pointer-events: all;
    }
  }
}
@media (min-width: 1230px) and (max-width: 1366px) {
  .zd-feedback-drop-down.dropdown-menu.show {
    transform: translate3d(81px, 31px, 0px) !important;
    button {
      pointer-events: all;
    }
  }
}
@media (min-width: 750px) and (max-width: 1224px) {
  .zd-feedback-drop-down.dropdown-menu.show {
    position: absolute !important;
    will-change: transform !important;
    overflow: hidden scroll !important;
    height: 192px !important;
    width: 200px !important;
    left: 19px !important;
    transform: translate3d(-4px, 32px, 0px) !important;
  }
}

@media (min-width: 0px) and (max-width: 750px) {
  .zd-feedback-drop-down.dropdown-menu.show {
    position: absolute !important;
    will-change: transform !important;
    overflow: hidden scroll !important;
    height: 200px !important;
    width: 200px !important;
    left: 0px !important;
    transform: unset !important;
  }
}

.zd-feedback-favourite-mix-dropdown {
  position: absolute !important;
  padding-left: 7%;
  // width: 200px;
}
.zd-feedback-favorite-mix-section {
  width: 24% !important;
  position: unset;
  // z-index: 10000;
  .zd-feedback-favorite-mix {
    display: inline-block;
    margin-left: 10px;
    width: 65%;
    height: 32px;
    color: #6b7883;
    padding-left: 12px;
    &:focus {
      box-shadow: none;
    }
  }
  .form-label {
    padding-top: 7px;
  }
}

.zd-feedback-header {
  padding-top: 12px;
  padding-bottom: 12px;
}
.zd-feedback-rating-section {
  // width: 13% !important;
  .form-label {
    padding-top: 7px;
  }
  .zd-feedback-rating {
    padding-top: 4px;
    margin-left: 8px;
  }
}
.zd-feedback-span-message {
  line-height: 1.1em;
  min-height: 2.2em;
  font-size: 10px;
}

.zd-feedback-button {
  // width: 17.5% !important;
  // .zd-feedback-cancel-button {
  //   padding-left: 8px;
  // }
  .zd-feedback-submit-button {
    padding-left: 12px;
  }
  .zd-cancel-btn,
  .zd-submit-btn {
    height: 32px;
  }
  // @media (max-width:1280px) {
  //   .zd-submit-btn {
  //     font-size: 9px;
  //   }
  // }
}

.zd-feedback-span-message1,
.zd-feedback-span-message2,
.zd-feedback-span-message3,
.zd-feedback-span-message4,
.zd-feedback-span-message5 {
  display: none;
}

.active-span {
  color: white; // #c7c0c0;// #606060;
  display: block;
  margin: 8px 0px 0px;
}

.form-label {
  color: #eaeaea;
}

.modal-dialog {
  &.zd-feedback-modal-content {
    width: 450px !important;
  }
}

.modal-content {
  border-radius: 0px !important;
}

.small-screen-center {
  & .btn {
    font-size: 12px;
    padding: 7px 16px;
    height: 32px;
  }
}

.zd-cancel-btn,
.zd-cancel-btn:hover {
  border: 2px solid #414249;
  box-sizing: border-box;
  border-radius: 2px !important;
  background: transparent;
  color: #dddddd;
}

.zd-submit-btn {
  background: #4f7be9;
  border-radius: 2px !important;
  color: #dddddd;
}
.zd-submit-btn:hover {
  background: #2d5dc8;
}

/* My DJ information CSS */
.zdv4-djinfo-inner-content {
  .nav-tabs {
    border-bottom: 0px !important;
  }
  .zd-line-tab {
    margin-bottom: 10px;
    float: right;
  }
  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    background-color: #4f7be9;
    border-color: #303139;
  }
  .btn {
    color: #ffffff;
    background: transparent;
    font-family: 'Poppins';
    height: 32px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    border-radius: 2px !important;
    border: 1px solid #303139;
    margin-left: 20px;
    &.active {
      background: #4f7be9;
    }
    &:focus {
      box-shadow: unset !important;
    }
  }
}
.zdv4-djinfo-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  .col {
    padding: 0px;
  }
  .row {
    margin-right: 0px;
    margin-left: 0px;
  }
  .form-control:focus {
    color: #dddddd;
    border-color: #1c1e22;
    outline: 0;
    box-shadow: unset;
  }

  &.zdv4-djinfo-club-modal {
    max-width: 900px !important;
    .modal-content {
      // height: 450px;
      width: 900px;
      background-color: #242529;
      @media (max-width: 991px) {
        width: 728px;
      }
      @media (max-width: 767px) {
        width: 343px;
      }
    }
    .form-label {
      // width: 26%;
    }
    .zd-club-night-col,
    .zd-club-type-col {
      padding: 0px;
      @media (min-width: 991px) {
        margin-right: 27px;
      }
      .form-label {
        // width: 13%;
      }
    }
    .zd-club-week {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(89px, 1fr));
      @media (max-width: 834px) {
        grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
      }
      @media (max-width: 767px) {
        grid-template-columns: repeat(auto-fill, minmax(89px, 1fr));
      }
    }
    .zd-club-type-sub-col {
      @media (min-width: 768px) {
        margin-right: 1.5rem;
      }
    }
    div {
      padding: 0px;
    }
    .zd-djinfo-second-col {
      justify-content: flex-end;
    }
  }
  &.zdv4-djinfo-magazine-modal {
    max-width: 700px !important;
    .modal-content {
      // height: 450px;
      width: 700px;
      background-color: #242529;
      @media (max-width: 991px) {
        width: 700px;
      }
      @media (max-width: 767px) {
        width: 343px;
      }
    }
    .form-label {
      // max-width: 20%;
      // width: 20%;
    }
    div {
      padding: 0px;
    }
    .zd-magazine-type-col {
      padding: 0px;
      @media (min-width: 991px) {
        margin-right: 40px;
      }
      .form-label {
        // width: 13%;
        white-space: nowrap;
      }
      &.zd-magazine-published {
        .form-checkbox {
          margin-right: 20px;
        }
      }
    }
  }
  &.zdv4-djinfo-radio-modal {
    max-width: 900px !important;
    .modal-content {
      // height: 450px;
      width: 900px;
      background-color: #242529;
      @media (max-width: 991px) {
        width: 728px;
      }
      @media (max-width: 767px) {
        width: 343px;
      }
    }
    .form-label {
      // width: 30%;
    }
    .col {
      display: flex;
    }

    .zd-djinfo-second-col {
      justify-content: flex-end;
    }
    .zd-radio-type-col {
      padding: 0px;
      @media (min-width: 991px) {
        margin-right: 40px;
      }
      .form-label {
        // width: 13%;
        white-space: nowrap;
      }
    }
    div {
      padding: 0px;
    }
  }

  .modal-header {
    background-color: #242529 !important;
    padding-left: 32px !important;
    padding-top: 24px !important;
    .close {
      display: none;
      @media (max-width: 767px) {
        display: block;
      }
    }
    .modal-title {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      color: #dddddd !important;
    }
  }
  .modal-body {
    padding: 24px 32px !important;
  }

  .zdv4-djinfo-modal-content {
    .zdv4-djinfo-modal-form {
      .form-group {
        margin-bottom: 15px !important;
      }
      .form-label {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #dddddd;
        .req {
          color: #4f7be9;
        }
      }
      .form-input {
        // width: 280px;
        height: 40px;
        left: 400px;
        top: 384px;
        background: #1c1e22;
        border-radius: 2px !important;
      }

      .zdv4-cancel-btn {
        width: 78px;
        height: 38px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #ffffff;
        border-radius: 2px !important;
        border: 1px solid #303139;
        margin-right: 24px;
      }
      .zdv4-save-btn {
        padding-right: 32px;
        width: 78px;
        height: 38px;
        background: #4f7be9;
        border-radius: 2px !important;
        padding: 10px 24px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #ffffff;
      }
      .zd-magazine-published {
        .form-checkbox {
          margin-right: 20px;
        }
      }

      .form-checkbox {
        margin-right: 25px;
        .form-icon {
          width: 16px;
          height: 16px;
          border: 1px solid #6b7883;
          box-sizing: border-box;
          border-radius: 2px;
        }
      }
      .form-radio {
        // margin-right: 25px;
        .form-icon {
          width: 16px;
          height: 16px;
          border: 1px solid #6b7883;
          box-sizing: border-box;
          border-radius: 50%;
        }
      }
      .zdv4-checkbox-label {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #6b7883;
        padding-left: 14px;
        text-align: center;
        vertical-align: middle;
      }

      .zd-club-form-label,
      .zd-radio-form-label,
      .zd-magazine-form-label {
        white-space: nowrap;
        @media (min-width: 991px) {
          margin-right: 40px;
        }
        .ws-wrap {
          white-space: normal;
        }
      }
      .zd-club-form-input,
      .zd-radio-form-input,
      .zd-magazine-form-input {
        margin-right: 27px;
      }
      .zdv4-club-btn-container {
        margin-right: 27px;
        @media (max-width: 767px) {
          justify-content: space-between !important;
        }
      }
      .zd-radio-type {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(118px, 1fr));
        @media (max-width: 834px) {
          grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
        }
        @media (max-width: 767px) {
          grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
        }
      }
      .zd-is-md {
        margin-right: 27px;
      }
      .zd-magazine-type {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(118px, 1fr));
        @media (max-width: 834px) {
          grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
        }
        @media (max-width: 767px) {
          grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
        }
      }
    }
  }
}
