.cr-header {
  // #zd-lg-filter {
  //   display: none; // abinash changed
  // }
  // .zdv3-icon-reset {
  //   display: none;
  // }

  // .zdv3-icon-reset {
  //   display: none;
  // }
  border-bottom: 1px solid #242529;
  margin-bottom: 0; //$spacer * .5;
  position: fixed;
  top: 0;
  left: 54px;
  right: 0px;
  padding: 11.5px 20px;
  margin-left: 0; //$cr-sidebar-minimized-width;
  z-index: 999;
  // box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.36);
  // background-color: #1c1e22 !important;
  background-color: #191b1f !important;

  @media (max-width: 750px) {
    height: 48px;
    left: 0;
    padding: 0px 0px;
    background-color: #191b1f !important;
  }
  &__nav-right {
    flex-direction: row;
    justify-content: flex-end;
    // margin-left: auto;
  }

  .zd-page-title-wrap {
    width: 160px;

    h1 {
      margin-top: 10px;
      font-size: 11.5px;
      font-weight: 600;
      letter-spacing: 0.03em;
      text-transform: uppercase;
    }
  }

  &.navbar-light {
    .navbar-brand {
      width: 130px;
    }
    .navbar-brand {
      img {
        width: 120px;
      }
    }
  }
  // &__notification-popover {
  //   &.popover {
  //     width: 320px;
  //     max-width: 320px;
  //     max-height: 100vh;
  //     overflow-y: scroll;
  //     cursor: pointer;
  //   }
  // }
  .cr-search-form__input {
    background-color: $body-bg;
    color: $white;
    @media (max-width: 750px) {
      width: calc(100% - 112px) !important;
      // margin: 0 auto;
      display: block;
      margin-left: 52px;
    }
    @media (max-width: 350px) {
      margin-left: 10vw;
    }
  }

  .cr-search-form__icon-search {
    color: #6b7883 !important;
    font-size: 14px;
    padding: 10px 0;
    cursor: pointer;
    @media (max-width: 750px) {
      padding: 0 !important;
      margin-left: 2px;
    }
  }
  .searchbar-focused {
    .cr-search-form__icon-search {
      color: #dddddd !important;
    }
  }

  .navbar-nav {
    align-items: center;
    a.zd-v5-mob-search {
      @media (max-width: 750px) {
        border: 1px solid var(--Secondary-Border, #32333a) !important;
        border-radius: 4px;
        background: #25262a;
        height: 36px;
        width: 36px;
        display: flex !important;
        padding-left: 6px !important;
      }
    }
    a.zd-nav-search-btn {
      color: $gray-400;
      font-size: 24px;
      margin-top: -2px;
      cursor: pointer;
      display: none;

      &:hover {
        color: $white !important;
      }
      @media (max-width: 750px) {
        margin-top: 0px;
        svg {
          fill: #6b7883;
        }
      }
    }
  }
}

body {
  .zd-header-search-wrap {
    position: relative;
    top: 0;
    z-index: 99;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    background-color: #2a3143;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-radius: 5px;
    @media (max-width: 750px) {
      margin-left: 0 !important;
      width: 100%;
      background: #222327;
      box-shadow: none !important;
    }
  }
}

.body-overflow-hidden .zd-header-search-wrap {
  @media (min-width: 750px) {
    // background-color: #4f7be9 !important;
  }
}
.release-head-page {
  .zd-tertiary-header {
    margin-top: 12px;
    margin-bottom: 0;
    .list-group {
      @media (max-width: 750px) {
        display: none !important ;
      }
    }
  }
  #zd-lg-filter {
    //abiansh changed
    margin-top: -8px;
  }
  .zd-breadcrumb {
    margin-top: 10px;
    @media (max-width: 750px) {
      display: none;
    }
  }
}
@media (max-width: 450px) {
  .zd_v5_w {
    max-width: 80%;
  }
}
.text-nowarp {
  white-space: nowrap;
}

.top-downloads-page {
  .zd-home-breadcrumb {
    margin-top: 7px;
  }
  .zd-tertiary-header {
    margin-top: 12px;
    margin-bottom: 0;
    .list-group {
      .dropdown_key {
        @media (max-width: 750px) {
          display: none !important ;
        }
      }
      .zd-tag-reset {
        @media (max-width: 750px) {
          display: none !important ;
        }
      }
    }
  }
}
//abinash changed
.trending-breadcrumb {
  .zd-tertiary-header {
    margin-top: 8px;
    margin-bottom: 0;
  }
}
body .zd-tertiary-text {
  color: #dddddd !important;
}

.zd-content-head {
  // background: $gray-1400;
  background: #191b1f;

  &.zd-fixed-header {
    position: fixed;
    top: 64px;
    z-index: 2;
    left: 76px;
    right: 20px;
    padding-bottom: 2px; // 43px;
    &.is-small-header {
      padding-bottom: 20px;
    }
  }

  .zd-secondary-header {
    // background: $gray-1100;

    .nav-item {
      margin: 0;
      // background: $secondary_nav_bg;

      .nav-link {
        padding: 5px 13px;
        background: #28292e;
        // border-radius: 2px;
        color: #6d7882;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        &:hover {
          color: #ddd;
          background-color: #303139;
          border-bottom: 1px solid #ddd;
        }

        &.active {
          background: #4f7be9;
        }
      }
    }
  }

  .zd-tertiary-header {
    // padding: 16px 0 16px 0;
    background: #25262a;
  }
}
.allpacks-search-bar {
  margin-bottom: 0px;
  @media (max-width: 750px) {
    display: none;
  }
}
.zd-container {
  // padding: 2px 0px;
}
.zd-search-allpacks {
  .favourite-search-box {
    width: 200px !important;
  }
}

.zd-filter-category-btn {
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  .btn {
    flex: 1;
    // padding: 3px 0 4px;
    margin: 0px 2px;
    text-align: center;
    max-width: 70px;
    background: $outline_btn_bg;
    color: #eaeaea !important; // #ffffff;
    border-radius: 0px !important;
    border-color: $outline_btn_bg !important;
    padding-bottom: 0.5rem !important;
    font-size: 11px;
    line-height: 11px;
    letter-spacing: 0.05em;
    -webkit-font-smoothing: auto;
    &.active {
      background-color: #1b1c1e !important;
      border-color: transparent !important;
      border-bottom-color: #4d7ce8 !important;
      border-bottom-width: 2px !important;
    }
    &.zd-btn-action {
      flex: none;
      padding: 3px 0 4px;
      width: 24px;
      background: $outline_btn_bg_hover;
      border-color: $outline_btn_bg !important;
      color: $white;
    }
    &.disabled {
      visibility: hidden;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &:active {
      outline: none;
    }
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
    &:hover {
      background: $outline_btn_bg_hover;
      border-color: #4452c0;
      color: $white;
    }
  }
}

.zd-trend-tab-btn {
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  height: 28px;
  @media (max-width: 440px) {
    margin-top: 30px;
  }
  .btn {
    flex: 1;
    // padding: 3px 0 4px;
    margin: 2px 6px;
    text-align: center;
    // max-width: 95px;
    background: $outline_btn_bg;
    color: #dddddd !important;
    border-radius: 2px !important;
    border-color: transparent !important;
    // padding-bottom: 0.5rem !important;
    font-size: 12px;
    line-height: 0;
    font-weight: 500;
    height: 28px;
    @media (max-width: 440px) {
      margin: 2px 2px;
      font-size: 10px;
      padding: 0.2rem 0.5rem;
    }
    // letter-spacing: 0.05em;
    -webkit-font-smoothing: auto;
    &.active {
      background-color: #4f7be9 !important;
      border-color: transparent !important;
      // border-bottom-color: #4d7ce8 !important;
      // border-bottom-width: 2px !important;
      border-radius: 2px !important;
    }
    &.zd-btn-action {
      flex: none;
      width: 24px;
      background: $outline_btn_bg_hover;
      border-color: $outline_btn_bg !important;
      color: $white;
    }
    &.disabled {
      visibility: hidden;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &:active {
      outline: none;
    }
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
    &:hover {
      background: #4f7be9;
      // border-color: #4452c0;
      // color: $white;
    }
  }
}

.zd-reset-btn {
  .btn {
    flex: 1;
    padding: 3px 0 4px;
    margin: 0px 4px;
    text-align: center;
    max-width: 70px;
    background: $outline_btn_bg;
    color: $outline_btn_color;
    border-radius: 0.2rem;
    font-size: 11px;
    line-height: 11px;
    letter-spacing: 0.05em;
    -webkit-font-smoothing: auto;
    &.active {
      background-color: #4d7ce8 !important;
    }
    &.zd-btn-action {
      flex: none;
      width: 24px;
      background: $outline_btn_bg_hover;
      border-color: #4452c0;
      color: $white;
    }
    &.disabled {
      visibility: hidden;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background: $outline_btn_bg_hover;
      border-color: #4452c0;
      color: $white;
    }
  }
}
.zd-filter-dates {
  .dropdown {
    border: 0;
    border-radius: 3px;
    position: relative;
    // min-width: 130px;
  }

  //css changed
  #zd-lg-filter {
    background-color: #25262a;
    .zdv3-icon-genre-filter {
      display: none !important;
    }
    .zd-text-ellipsis {
      margin-left: 6px;
    }
    .zdv3-icon-drop-down {
      padding-right: 4px;
      padding-left: 8px;
      &:before {
        content: '\EA19';
      }
    }
  }
}

.zd-default-list {
  li {
    padding: 0;
    border: none;
    background-color: transparent !important;
  }
}

.zd-notification-popover {
  box-shadow: rgba(0, 0, 0, 0.5) -2px 2px 8px 0;
}

.zd-profile-popover {
  // box-shadow: rgba(0, 0, 0, 0.5) -2px 2px 8px 0;
  border-radius: 4px;
  .card-body {
    // background: #292a2f;
    #zd-popover-body-title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #dddddd;
    }
    #zd-popover-body-subtitle {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      color: #dddddd;
    }
  }
  .arrow {
    display: none;
  }
  .list-group {
    font-size: 14px;

    .list-group-item {
      padding: 0;
      border: 0;
      background: #292a2f;
      border: 1px solid #444;
      .nav-link {
        padding: 11px 23px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #6b7883;
        // &.active,
        &:hover {
          color: #dddddd;
          background-color: #303139;
        }
      }
    }
  }

  .card-body {
    padding: 0.8rem 23px;
  }
}
.zd-profile-dropdown {
  a {
    .zd-username-menu {
      display: flex;
      // padding-left: 10px;
      color: #abb1b8;
      img.rounded-circle {
        margin-right: 5px;
      }
    }
    .zd-down-arrow {
      &:after {
        content: '\52';
        font-family: 'zipdj' !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-variant: normal !important;
        text-transform: none !important;
        speak: none;
        padding-left: 7px;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #e6e6e6;
      }
    }
    &:hover {
      .zd-username-menu {
        color: $white;
      }
    }
  }
  @media (max-width: 750px) {
    display: none;
  }
}

.zd-download-manager-btn {
  font-size: 22px;
  color: $white;
  position: relative;
  cursor: pointer;
  a {
    &:hover {
      color: $white !important;
      .rubberBand {
        -webkit-animation-name: rubberBand;
        animation-name: rubberBand;
      }
    }
  }
  &.zd-animate {
    a {
      .rubberBand {
        -webkit-animation-name: rubberBand;
        animation-name: rubberBand;
      }
    }
  }

  .badge {
    left: 3px;
    color: $white;
    top: 0px;
    background: map-get($map: $theme-colors, $key: primary);
    padding: 2px 4px;
    border-radius: 2px;
    font-size: 12px;
  }
  .zd_v5_spinner_wrapper {
    background-color: #4d7ce8;
    width: 17px;
    height: 17px;
    position: absolute;
    top: 18%;
    left: 32%;
    margin-top: -8px;
    margin-left: -8px;
    border-radius: 2px;
  }

  .zd-header-download-size {
    padding-top: 0 !important;
    font-size: 12px;
    // position: absolute;
    left: 0px;
    white-space: nowrap;
    text-align: center;
    color: #6b7883;
    font-weight: 400;
    font-family: 'Poppins', 'Roboto', sans-serif;
    &:hover {
      color: #dddddd;
      svg {
        path {
          fill: #dddddd !important;
        }
      }
    }
  }
}
@media (max-width: 750px) {
  .cr-sidebar {
    top: 50px !important;
  }
}
.cr-sidebar {
  .zd-download-manager-btn {
    .badge {
      left: 21px;
      right: auto;
      top: 2px;
      z-index: 33;
    }
  }
}
body.zd-slide-open {
  .zd-tertiary-header {
    .zd-second-col {
      flex: 3 0 25.33333%;
      max-width: 25.33333%;
    }
    .zd-third-col {
      flex: 3 0 18.33333%;
      // max-width: 18.33333%;
    }
  }
}

body .zd-parent-menu {
  padding: 10px;
  max-height: unset;
  height: auto;
  overflow-x: hidden;
  overflow-y: hidden;
  min-width: 320px;
  font-size: 11px;
  text-transform: unset;
  line-height: 20px;
}

body .zd-parent-menu.show .zd-submenu {
  position: relative !important;
  transform: unset !important;
  font-size: 11px;
  color: #adadad;
  padding-top: 0;
}

.zd-parent-menu .text-uppercase .zd-submenu,
.zd-parent-menu .zd-submenu .text-uppercase .zd-submenu {
  text-transform: capitalize;
}

body .zd-parent-menu .zd-submenu {
  padding-left: 20px;
  width: 100%;
}

body .zd-home-breadcrumb {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  // font-size: 11px;
  padding-top: 0;
  cursor: default;
  .zd-refresh-breadcrumb:hover {
    color: #acbdf3;
  }
}
body .zd-breadcrumb-title {
  color: #acbdf3;
}

.zd-parent-menu .zd-submenu:before {
  content: '';
  position: absolute;
  left: 2px;
  top: 0;
  height: 100%;
  width: 2px;
  background: #424242;
}

.zd-tab-view-action {
  a {
    color: $gray-95;
    border-bottom: 2px solid transparent;
    padding-bottom: 4px;
    display: inline-block;
    font-size: 11px !important;
    &.active,
    &:hover {
      padding-top: 6px;
      text-decoration: none;
      color: $white;
      border-bottom: 2px solid map-get($map: $theme-colors, $key: primary);
    }
  }
}

#zd-release-tab-view {
  i {
    // background-color: #32333A;
    padding: 3px 6px;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;

    border: 1px solid var(--Secondary-Border, #32333a);
  }
  background: #191b1f;
}
#zd-release-tab-view-track {
  i {
    // background-color: #32333A;
    padding: 3px 6px;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;

    border: 1px solid var(--Secondary-Border, #32333a);
  }
  background: #191b1f;
}
#zd-track-tab-view.active i {
  background: #242529;
}
#zd-release-tab-view.active i {
  background: #242529;
}
.dropdown {
  button {
    border-radius: 4px;
    border: 1px solid var(--Secondary-Border, #32333a);
  }
}
.zd-v5-filter-reset {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4d7ce8;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: #2b59c2;
  }

  @media (max-width: 750px) {
    order: 4;
    margin-left: 0.5rem;
  }
}
@media screen and (max-width: 750px) {
  // .zd-reset-before {
  //   display: none;
  // }
  .zd-reset-after {
    display: flex !important;
    margin-left: 4px;
  }
}
#zd-track-tab-view {
  i {
    // background-color: #32333A;
    padding: 3px 6px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid var(--Secondary-Border, #32333a);
  }
  background: #191b1f;
}

.zd-newrelease-tab-view-action {
  #zd-release-tab-view {
    margin-left: 10px;
  }
  float: right;
  height: 36px;
  a {
    color: #6d7882 !important;
    // border-bottom: 2px solid transparent;
    // padding-bottom: 4px;
    display: inline-block;
    font-size: 24px !important;
    &.active,
    &:hover {
      // padding-top: 6px;
      text-decoration: none;
      color: #4f7be9 !important;
    }
  }
}
.zd-electronic-tab {
  a {
    color: #909090 !important;
    border-bottom: 3px solid transparent;
    // padding-bottom: 4px;
    display: inline-block;
    &.electronic-tab {
      margin-right: 85px !important;
    }
    &.airplay-tab {
      margin-right: 85px !important;
    }
    &.active,
    &:hover {
      text-decoration: none;
      color: $white;
      border-bottom: 3px solid map-get($map: $theme-colors, $key: primary);
    }
  }
}

body {
  .zd-content-head {
    .zd-secondary-search-header {
      .nav-item {
        background: none;
      }

      .zd-primary-form {
        padding: 9px 20px;
        .zd-primary-search {
          width: 250px;
          background: #2d2e33;
          box-shadow: none;
          border: 0;
          // border-top-left-radius: 5px;
          // border-bottom-left-radius: 5px;
          // border-top-right-radius: 0;
          // border-bottom-right-radius: 0;
          border-radius: 0 !important;
        }
        .input-group-btn {
          border-radius: 0 5px 5px 0;
          margin-left: -0.1rem;
        }
      }
    }
  }
}

.zd-header-profile-name {
  // width: 95px;
  // position: fixed;
  // display: inline-block;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 2px;
}
.zd-header-profile-icon {
  padding-top: 1px;
}
.zd-header-notification-icon {
  padding: 2px 15px 0;
}
.zd-header-download-size {
  padding-bottom: 2px;
}
.download-size {
  position: absolute;
  top: 34px;
  right: 2px;
  font-size: 9px;
}
.zd-download-manager {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 12px;
}
.zd-header-link {
  font-size: 12px;
  color: #6b7883 !important;
  font-weight: 500;
  text-decoration: none !important;
  padding-left: 0 !important;
  // border-bottom: 2px solid transparent;
  padding-right: 0 !important;
  padding: 0 !important;
  margin: 0 10px;
  position: relative;
  @media (min-width: 1300px) {
    :first-child {
      margin-left: 12px !important;
    }
    margin: 0 12px;
    &:last-child {
      margin-left: 26px;
    }

    &:last-child::before {
      margin-left: 0 !important;
    }
  }

  &.zd-header-link:hover,
  &.zd-header-link:focus {
    color: #4f7be9 !important;
  }
  &.active {
    color: #4f7be9 !important;
    &::before {
      content: '';
      position: absolute;
      top: -23px;
      left: 0;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      height: 4px;
      width: 100%;
      background-color: #4f7be9;
    }
  }
  &.current-active {
    color: #4f7be9 !important;
    &::before {
      content: '';
      position: absolute;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      top: -23px;
      left: 0;
      height: 4px;
      width: 100%;
      background-color: #4f7be9;
      margin-left: 6px;
    }
  }
}
.zd-middle-nav-items {
  @media (max-width: 1224px) {
    display: none;
  }
}

.zd-mega-dropdown {
  background-color: #2a3143;
  color: #6e7da5 !important;
  padding: 12px 0px 12px 8px;
  font-weight: 500;
  font-size: 12px;
  span.no-filter-applied {
    // max-width: 44px; /- css changed
    max-width: 75px;
    min-width: 44px;
    @media (max-width: 1224px) {
      max-width: 75px; //-css changed
      min-width: 52px;
    }
  }
  // --------
  .zdv3-icon-up-arrow,
  .zdv3-icon-drop-down {
    @media (max-width: 1224px) {
      padding-left: 19px;
    }
  }
  span.filter-applied {
    max-width: 75px; //-css changed
    min-width: 44px;
    @media (max-width: 1224px) {
      max-width: 75px; //-css changed
      min-width: 52px;
    }
  }
  &:hover {
    color: #ddd !important;
    .filter-applied {
      color: #ddd !important;
    }
  }

  @media (max-width: 1224px) {
    background: #2a3043;
    color: #7685b3;
    &:hover {
      color: #5d6b91;
      background: rgba(79, 123, 233, 0.22);
    }
  }
  @media (max-width: 750px) {
    path {
      fill: #6b7883;
    }
    background: #202225;
    &:hover {
      color: #8997be;
      background: #202225;
    }
  }
}
.nav-link.zd-header-link.active {
  background: transparent !important;
  color: white;
}
.zd-nav-brand {
  position: fixed;
  left: 24px;
  top: 22px;
  z-index: 1010;
  @media (max-width: 750px) {
    position: fixed;
    top: 16px;
    left: auto;
    margin-left: 25%;
    margin-right: 23px;
    transform: translateX(-50%);
  }
}
.zd-search-open .zd-nav-brand {
  @media (max-width: 750px) {
    z-index: 900;
  }
}

.cr-header .zd-download-manager-btn {
  @media (max-width: 750px) {
    display: none !important;
  }
}
#zd-header-profile-image {
  height: 32px !important;
  width: 32px !important;
}
.zd-header-search-wrap {
}
#search-genres {
  background: transparent;
  height: 36px;
  @media (min-width: 1367px) {
    // margin-left: 207px !important;
  }
  @media (min-width: 1300px) {
    // margin-left: 180px !important;
  }
  @media (max-width: 1300px) {
    margin-left: auto !important;
  }
  // @media (max-width: 1224px) {
  //   margin-left: 30% !important;
  // }
  // @media (max-width: 750px) {
  //   margin-left: auto !important;
  // }
}
.zd-filter-middle {
  @media (min-width: 1224px) {
    margin-left: 0px !important;
  }
}
#Popover2 {
  padding-right: 0px !important;
  padding-top: 0px;
  padding-bottom: 0px;
  .zdv3-icon-profile {
    font-size: 27px;
  }
  &:hover {
    #zd-header-user-name {
      color: #dddddd;
    }
  }
  .zd-username-menu.active {
    color: #4f7be9;
    .zd-header-profile-name {
      // transform: rotate(180deg);
      // transition: 0.3s ease-in;
    }
    &:hover {
      color: #4471e0 !important;
    }
  }
}
.zd-header-link:first-child {
  @media (min-width: 1224px) {
    margin-left: 144px;
  }
}
.zdv3-icon-drop-down,
.zdv3-icon-up-arrow {
  font-size: 10px;
}
.dropdown_key {
  height: 32px;
  width: auto !important;
  float: right;
  border-radius: 2px !important;
  padding: 4px;
  // background-color: transparent !important;
  background-color: #25262a;
  .drop_icon {
    margin-left: 4px;
  }
}

.top-downloads-filter-btn {
  height: 32px;
  max-width: 186px;
  float: right;
  border-radius: 4px !important;
}
.cr-header__nav-right {
  // @media (max-width: 1224px) {
  //   margin-left: auto !important;
  // }
  @media (max-width: 750px) {
    margin-left: 0 !important;
  }
}

.zd-new-header-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #dddddd;
  @media (max-width: 440px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    white-space: nowrap;
  }
}
.zd-search-genre-btn .zd-primary-dropdown-disabled {
  background-color: #2a3143;
  color: #6e7da5 !important;
  padding: 12px 0px 12px 8px;
  font-weight: 500;
  font-size: 12px;
  span.no-filter-applied {
    // max-width: 43px;
    max-width: 75px; //-css changed
    min-width: 43px;
    //height:18px;
    line-height: 18px;
  }

  @media (max-width: 1220px) {
    padding: 6px 7px;
    width: 150px;
  }
  .zdv3-icon-drop-down {
    @media (max-width: 1220px) {
      padding-left: 15px;
    }
  }
}
.zd-slide-open .zd-header-download-size {
  svg {
    path {
      fill: #4f7be9 !important;
    }
  }
  &:hover {
    svg {
      path {
        fill: #2d5dc8 !important;
      }
    }
  }
}
.zd-mega-dropdown.applied-filter-button {
  background-color: #4f7be9 !important;
  color: #ddd !important;

  &:hover {
    color: #ddd !important;
    background-color: #2d5dcb !important;
  }
}
.zd-search-back-new {
  display: flex;
  justify-content: space-between;
  .cr-search-form {
    height: 31px;
  }
  .combobox {
    height: 40px;
  }
}

.zd-v5-secondary-header {
  .nav-item .nav-link {
    background: transparent !important;
    padding: 7px 12px !important;
  }
  .nav-link.active {
    color: #4f7be9 !important;
    padding: 7px 12px !important;
    border-bottom: 1px solid #4f7be9;
  }
}
.zd-content-head {
  border-bottom: 1px solid #242529;
  .favourite-search-bar-form {
    margin-bottom: 0px !important;
  }
}

.zd-v5-search-mobile-close {
  display: none;
  @media (max-width: 750px) {
    font-weight: 500;
    display: block;
    position: fixed;
    right: 0;
    top: 6px;
    padding: 10px;
    color: #dddddd;
    font-size: 14px;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    border: none;

    &:hover {
      color: #dddddd;
    }
  }
}

span.zd-v5_spinner {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid transparent;
  border-top-color: #fff;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  margin-top: 4px;
  &.v5_mr {
    margin-right: 8px;
    width: 16px;
    height: 16px;
    @media (max-width: 950px) {
      margin-right: 26px;
    }
    @media (max-width: 750px) {
      margin-right: 20px;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
