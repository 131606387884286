// override bootstrap 4 variables

// $blue: #2196f3;
// $indigo: #536dfe;
// $purple: #9c27b0;
// $pink: #ff006b;
// $red: #f44336;
// $orange: #ff9800;
// $yellow: #ffeb3b;
// $green: #4caf50;
// $teal: #009688;
// $cyan: #00bcd4;
// $white: #fff;
$theme-colors: (
  'primary': #4d7ce8,
  'secondary': #7685b3,
  'success': #45b649,
  'danger': #f85032,
  'warning': #f8c283,
  'info': #00c9ff,
  'grey': #d8d8d8,
  'grey2': #575f66,
);

$enable-gradients: true;

// card
//
$card-cap-bg: #fff;

// sidebar
$cr-sidebar-width: 208px;
$cr-sidebar-minimized-width: 56px;

// ZipDJ Theme
// Darkly 4.3.1
// Bootswatch

//
// Color system
//
$white: #dddddd !default;
$white-10: #dfdfdf !default;
$gray-60: #666 !default;
// $gray-75: #757575 !default;
$gray-75: #909090 !default;
$gray-70: #7685b3 !default;

$gray-80: #919191 !default;
$gray-90: #b7b7b7 !default;
$gray-95: #adadad !default;
$gray-100: #f8f9fa !default;
$gray-200: #ebebeb !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-450: #c8c8c8 !default;
$gray-500: #adb5bd !default;
$gray-600: #999 !default;
$gray-700: #444 !default;
$gray-800: #202225 !default;
$gray-850: #2c303b !default;
$gray-860: #282d40 !default;
$gray-900: #1d1b21 !default;
$gray-1000: #1c1c1e !default;
$gray-1100: #25262a !default;
$gray-1200: #27282d !default;
$gray-1300: #2a3043 !default;
$gray-1400: #1c1e22 !default;
$media-player-bg: #1f2024 !default;

$link-color: #5764c6;
$link-hover-color: #3b49af;
$black: #000 !default;

$group_table_tr: #242529;
$group_table_tr_hover: #303139;
$group_table_border: #2c2d31;

$table_tr: #242529;
$table_tr_border: #1c1e22; // #1a1a1c;
$table_thead: #1b1b1d;
$table_tr_hover: #28292e;

$outline_btn_bg: $gray-1200;
$outline_btn_bg_hover: $link-color;
$outline_btn_color: $gray-70;

$secondary_nav_bg: #1c1e23;
$secondary_nav_link_color: $gray-95;
$secondary_nav_link_color_hover: #3b49af;
$zd_selected: #303139;
// $zd_selected : #33343a;
$zd_playing_row: #303139;
$zd_profile: #212226;

$blue: #375a7f !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #e74c3c !default;
$orange: #fd7e14 !default;
$yellow: #f39c12 !default;
$green: #00bc8c !default;
$teal: #20c997 !default;
$cyan: #3498db !default;
$red-100: #ff0000 !default;

$primary: $blue !default;
$secondary: $gray-700 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-600 !default;
$dark: $gray-800 !default;

$border-color: lighten($gray-800, 15%) !default;

$yiq-contrasted-threshold: 175 !default;

/*colors used in genre class*/
$orange-gr: linear-gradient(180deg, #cb6e26 0%, #c02e41 100%), #c4c4c4;
$pink-gr: linear-gradient(180deg, #bc4a6e 0%, #723578 100%), #c4c4c4;
$grey-blue-gr: linear-gradient(180deg, #5a93c8 0%, #757bc7 100%), #c4c4c4;
$baby-pink-gr: linear-gradient(180deg, #dc9a72 0%, #cb6f76 100%), #c4c4c4;
$pink-gr: linear-gradient(180deg, #ca3a89 0%, #9a3b8d 100%), #c4c4c4;
$blue-gr: linear-gradient(180deg, #5c5ba0 0%, #2e5692 100%), #c4c4c4;
$sky-blue-gr: linear-gradient(180deg, #22a8b3 0%, #117eaa 100%), #c4c4c4;

/*colors used in zipd-packs*/
$light-black: #1c1e22;
$link-color-blue: #5d83c9;

// Body

$body-bg: $gray-900 !default;
$body-color: $white !default;

// Links

$link-color: $link-color !default;

// Fonts

$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont,
  'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol' !default;

$font-size-base: 0.72rem !default;

$h1-font-size: 2rem !default;
$h2-font-size: 1.5rem !default;
$h3-font-size: 1rem !default;

// Tables

$table-accent-bg: $gray-800 !default;

$table-border-color: $gray-700 !default;

// Forms

$input-border-color: $body-bg !default;

$input-group-addon-color: $gray-500 !default;
$input-group-addon-bg: $gray-700 !default;

$custom-file-color: $gray-500 !default;
$custom-file-border-color: $body-bg !default;

// Dropdowns

$dropdown-bg: $gray-900 !default;
$dropdown-border-color: $gray-700 !default;
$dropdown-divider-bg: $gray-700 !default;

$dropdown-link-color: $white !default;
$dropdown-link-hover-color: $white !default;
$dropdown-link-hover-bg: $primary !default;

// Navs

$nav-link-padding-x: 2rem !default;
$nav-link-disabled-color: $gray-500 !default;

$nav-tabs-border-color: $gray-700 !default;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color $nav-tabs-border-color
  transparent !default;
$nav-tabs-link-active-color: $white !default;
$nav-tabs-link-active-border-color: $nav-tabs-border-color
  $nav-tabs-border-color transparent !default;

// Navbar

$navbar-padding-y: 1rem !default;

$navbar-dark-color: rgba($white, 0.6) !default;
$navbar-dark-hover-color: $white !default;

$navbar-light-color: $white !default;
$navbar-light-hover-color: $link-color !default;
$navbar-light-active-color: $white !default;
$navbar-light-disabled-color: rgba($white, 0.3) !default;
$navbar-light-toggler-border-color: rgba($white, 0.1) !default;

// Pagination

$pagination-color: $white !default;
$pagination-bg: $success !default;
$pagination-border-width: 0 !default;
$pagination-border-color: transparent !default;

$pagination-hover-color: $white !default;
$pagination-hover-bg: lighten($success, 10%) !default;
$pagination-hover-border-color: transparent !default;

$pagination-active-bg: $pagination-hover-bg !default;
$pagination-active-border-color: transparent !default;

$pagination-disabled-color: $white !default;
$pagination-disabled-bg: darken($white, 15%) !default;
$pagination-disabled-border-color: transparent !default;

// Jumbotron

$jumbotron-bg: $gray-800 !default;

// Cards

$card-cap-bg: $gray-700 !default;
$card-bg: $gray-800 !default;

// Popovers

$popover-bg: $gray-800 !default;

$popover-header-bg: $gray-700 !default;

// Modals

$modal-content-bg: $gray-800 !default;
$modal-content-border-color: $gray-700 !default;

$modal-header-border-color: $gray-700 !default;

// Progress bars

$progress-height: 0.625rem !default;
$progress-font-size: 0.625rem !default;
$progress-bg: $gray-700 !default;

// List group

$list-group-bg: $gray-800 !default;
$list-group-border-color: $gray-700 !default;

$list-group-hover-bg: $gray-700 !default;

// Breadcrumbs

$breadcrumb-bg: $gray-700 !default;

// Close

$close-color: $white !default;
$close-text-shadow: none !default;

// Code

$pre-color: inherit !default;
