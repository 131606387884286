#mainMega.mainMega-mobile{
 height: calc(100vh - 134px);
}

.megamenu {
  z-index: 10;
  color: white;
  width: 70.5%;
  /* overflow-y: scroll; */
}
.menu {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 18px 20px 18px 24px;
}
.menu:not(:first-child) {
  margin-top: 4px;
}
.description {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 5.5px 0;
}
.menu .title {
  height: 100%;
  display: flex;
  justify-content: left;
  text-decoration: none !important;
  align-items: center;
}
.title h3 {
  margin-bottom: 0 !important;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 0.25em;
  font-weight: 500;
  font-family: 'Poppins', 'Roboto', sans-serif;
  text-decoration: none !important;
  transition: 0.3s ease-in;
  letter-spacing: 0.25em;
}
.title h3 span:hover {
  color: #4f7be9;
  cursor: pointer;
}
.left-main-insider h6,
.right-main-insider h6 {
  padding-bottom: 0px;
  font-size: 12px;
  font-family: 'Poppins', 'Roboto', sans-serif;
  text-transform: uppercase;
  transition: 0.4s ease;
  font-style: normal;
  letter-spacing: 0.01em;
  font-weight: 500;
  margin-bottom: 0px !important;
}
.menu:first-child {
  background: linear-gradient(
      0deg,
      rgba(34, 35, 39, 0.95),
      rgba(34, 35, 39, 0.95)
    ),
    url('../../assets/img/DanceB.png');
  transition: 0.3s ease-in-out;
}

.menu:last-child {
  background: linear-gradient(
      0deg,
      rgba(34, 35, 39, 0.95),
      rgba(34, 35, 39, 0.95)
    ),
    url('../../assets/img/bg.png');
}
.menu:last-child .left-main-insider h6,
.menu:last-child .right-main-insider h6 {
  margin-bottom: 4px !important;
}
.menu:first-child .left-main-insider .left-insider:not(:first-child),
.menu:first-child .right-main-insider .right-insider:not(:first-child) {
  padding-top: 16px !important;
}
.left-main-insider h6 span:hover,
.right-main-insider h6 span:hover {
  color: #4f7be9;
  cursor: pointer;
}

.tag p {
  color: rgb(142, 142, 142);
  transition: 0.4s ease;
  font-weight: 400;
  font-size: 12px;
  font-family: 'Poppins', 'Roboto', sans-serif;
  margin: 0;
  padding: 4px 0;
}
.tag p span:hover {
  color: rgb(47, 137, 255);
}

@media screen and (max-width: 1559px) and (min-width: 1225px) {
  /* Player Avtive */
  .player-active .menu {
    padding: 10.9px 20px 10.9px 24px;
  }

  .player-active
    .menu:first-child
    .left-main-insider
    .left-insider:not(:first-child),
  .player-active
    .menu:first-child
    .right-main-insider
    .right-insider:not(:first-child) {
    padding-top: 10px !important;
  }
  .player-active .menu:nth-child(2) .right-main-insider {
    display: grid;
    grid-template-rows: 110px 105px 85px 95px;
  }
  .player-active .menu:nth-child(2) .left-main-insider {
    display: grid;
    grid-template-rows: 110px 105px 85px 95px;
  }
}
@media screen and (max-width: 1368px) and (min-width: 1225px) {
  .megamenu {
    width: 68.5%;
  }
  .menu {
    padding: 10px 20px 9px 24px;
  }
  .title h3 {
    font-size: 14px;
  }
  .left-main-insider h6,
  .right-main-insider h6 {
    font-size: 10px;
    font-weight: 500;
  }
  .tag p {
    font-size: 12px;
    font-weight: 400;
  }
  .menu:last-child .left-main-insider h6,
  .menu:last-child .right-main-insider h6 {
    margin-bottom: 4px !important;
  }
  .menu:first-child .left-main-insider .left-insider:not(:first-child),
  .menu:first-child .right-main-insider .right-insider:not(:first-child) {
    padding-top: 12px !important;
  }
  .menu:nth-child(2) .right-main-insider {
    display: grid;
    grid-template-rows: 105px 105px 80px 95px;
  }
  .menu:nth-child(2) .left-main-insider {
    display: grid;
    grid-template-rows: 105px 114px 70px 100px;
  }
  .menu:nth-child(2) .left-main-insider .left-insider:last-child {
    grid-row-start: 4;
    grid-row-end: 5;
  }

  /* Player Avtive */
  .player-active .menu {
    padding: 6px 20px 6px 24px;
  }

  .player-active
    .menu:first-child
    .left-main-insider
    .left-insider:not(:first-child),
  .player-active
    .menu:first-child
    .right-main-insider
    .right-insider:not(:first-child) {
    padding-top: 8px !important;
  }
  .player-active .tag p {
    padding: 2px 0;
  }
  .player-active .menu:nth-child(2) .right-main-insider {
    grid-template-rows: 90px 95px 75px 85px;
  }
  .player-active .menu:nth-child(2) .left-main-insider {
    grid-template-rows: 90px 95px 75px 85px;
  }
}
@media screen and (max-width: 1224px) and (min-width: 750px) {
  .megamenu {
    width: calc(64vw - 56px);
    height: auto;
    position: relative;
  }
  .menu {
    padding: 28px 20px 28px 15px;
    grid-template-columns: none;
  }
  .title h3 {
    font-size: 16px;
    font-family: 'Poppins', 'Roboto', sans-serif;
  }
  .right-insider,
  .left-insider {
    padding-top: 20px;
  }
  .left-main-insider h6,
  .right-main-insider h6 {
    padding-top: 6px;
    font-size: 12px;
  }
  .rightbarcomponent {
    padding: 22px 20px;
  }
}

/* Mobile screen */

@media screen and (max-width: 750px) {
  .megamenu {
    position: relative;
    width: 100vw;
    top: 0;
    height: auto;
    left: 0px;
  }
  .title h3 {
    font-size: 15px;
    letter-spacing: 0.1em;
  }
  .menu {
    grid-template-columns: none;
    margin-top: 0;
    padding: 0;
    display: block;
  }
  .sm-megamenu-heading {
    padding: 10px 0;
    padding-left: 10px;
    text-transform: uppercase;
    /* border-bottom: 3px solid #222327; */
  }
  .description {
    grid-template-columns: none;
    background: #1c1e22;
    display: none;
    padding: 10px;
  }
  .activeAcc .description {
    transition: 0.3s ease-in;
    display: block;
  }
  .menu.activeAcc {
    background: #1c1e22;
    width: 100vw;
    /* left: 10px; */
  }
  .left-main-insider h6,
  .right-main-insider h6 {
    font-size: 14px;
    margin-top: 24px;
    font-weight: 500;
    padding-left: 11px;
    line-height: 17.15px;
  }
  .menu:first-child .left-main-insider .left-insider:not(:first-child),
  .menu:first-child .right-main-insider .right-insider:not(:first-child) {
    padding-top: 0px !important;
  }
  .menu.activeAcc .title {
    width: 100vw;
  }
  #Dance {
    background: linear-gradient(
        0deg,
        rgba(34, 35, 39, 0.9),
        rgba(34, 35, 39, 0.9)
      ),
      url('../../assets/img/Dance.png');
  }
  #House {
    background: linear-gradient(
        0deg,
        rgba(34, 35, 39, 0.9),
        rgba(34, 35, 39, 0.9)
      ),
      url('../../assets/img/House.png');
  }
  .activeAcc #Dance {
    background: linear-gradient(rgba(0, 0, 0, 0.171), rgba(0, 0, 0, 0.192)),
      url('../../assets/img/Dance.png');
    background-size: cover;
  }
  .activeAcc #House {
    background: linear-gradient(rgba(0, 0, 0, 0.192), rgba(0, 0, 0, 0.233)),
      url('../../assets/img/House.png');
    background-size: cover;
  }
  .title {
    height: 64px;
    padding: 23px 18px;
  }
  .menu:not(:first-child) {
    margin-top: 0px;
  }
  .tag p {
    padding-left: 11px;
  }
  /* .left-insider,
  .right-insider {
    border-bottom: 3px solid #222327;
  } */
  .title h3 span:hover {
    color: white;
  }
}
@media screen and (min-width: 1366px) {
  .player-active .bottombar.submenu {
    height: 100% !important;
  }
}
@media screen and (min-width: 1367px) {
  .menu:nth-child(2) .right-main-insider {
    display: grid;
    grid-template-rows: 110px 115px 94px 114px;
  }
  .menu:nth-child(2) .left-main-insider {
    display: grid;
    grid-template-rows: 110px 115px 94px 114px;
  }
  .menu:nth-child(2) .left-main-insider .left-insider:last-child {
    grid-row-start: 4;
    grid-row-end: 5;
  }
  .menu:first-child:hover {
    background: linear-gradient(
        0deg,
        rgba(34, 35, 39, 0.91),
        rgba(34, 35, 39, 0.91)
      ),
      url('../../assets/img/DanceB.png');
  }
  .menu:nth-child(2):hover {
    background: linear-gradient(
        0deg,
        rgba(34, 35, 39, 0.91),
        rgba(34, 35, 39, 0.91)
      ),
      url('../../assets/img/bg.png');
  }
}
@media screen and (max-width: 2000px) and (min-width: 1560px) {
  .megamenu {
    width: 72.5%;
  }
  .menu {
    padding: 38px 20px 38px 136px;
    grid-template-columns: 0.7fr 2fr;
  }
  .title h3 {
    font-size: 18px;
  }
  .left-main-insider h6,
  .right-main-insider h6 {
    font-size: 14px;
    font-weight: 500;
  }
  .tag p {
    font-size: 14px;
    font-weight: 400;
  }
  .menu:last-child .left-main-insider h6,
  .menu:last-child .right-main-insider h6 {
    margin-bottom: 6px !important;
  }
  .menu:first-child .left-main-insider .left-insider:not(:first-child),
  .menu:first-child .right-main-insider .right-insider:not(:first-child) {
    padding-top: 18px !important;
  }
  .menu:nth-child(2) .right-main-insider {
    display: grid;
    grid-template-rows: 120px 120px 100px 110px;
  }
  .menu:nth-child(2) .left-main-insider {
    display: grid;
    grid-template-rows: 120px 120px 100px 110px;
  }
  .menu:nth-child(2) .left-main-insider .left-insider:last-child {
    grid-row-start: 4;
    grid-row-end: 5;
  }

  /* Player Active */

  .player-active .menu {
    padding: 27px 20px 26px 130px;
    grid-template-columns: 0.7fr 2fr;
  }
}

@media screen and (min-width: 2200px) {
  .megamenu {
    width: 60%;
  }
  .menu {
    grid-template-columns: 0.64fr 2fr;
  }
  .menu {
    padding: 38px 20px 38px 24px;
  }
  .title h3 {
    font-size: 20px;
    padding-left: 112px;
  }
  .left-main-insider h6,
  .right-main-insider h6 {
    font-size: 16px;
    font-weight: 500;
  }
  .tag p {
    font-size: 14px;
    font-weight: 400;
  }
  .menu:last-child .left-main-insider h6,
  .menu:last-child .right-main-insider h6 {
    margin-bottom: 8px !important;
  }
  .menu:first-child .left-main-insider .left-insider:not(:first-child),
  .menu:first-child .right-main-insider .right-insider:not(:first-child) {
    padding-top: 20px !important;
  }
  .menu:nth-child(2) .right-main-insider {
    display: grid;
    grid-template-rows: 140px 130px 120px 120px;
  }
  .menu:nth-child(2) .left-main-insider {
    display: grid;
    grid-template-rows: 140px 130px 120px 120px;
  }
  .player-active .menu {
    padding: 20px 20px 20px 24px;
  }
}

.megamenu .right-main-insider .right-insider h6 {
  /* border-left: 2px solid #4f7be9; */
}
.megamenu .right-main-insider .right-insider h6 span {
  margin-left: 3px;
}
.megamenu .right-main-insider .tag p span {
  margin-left: 4px;
}
.megamenu .left-main-insider .left-insider h6 {
  /* border-left: 2px solid #4f7be9; */
}
.megamenu .left-main-insider .left-insider h6 span {
  margin-left: 3px;
}
.megamenu .left-main-insider .tag p span {
  margin-left: 4px;
}
