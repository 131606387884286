@media only screen and (min-width: 1281px) and (max-width: 10000px) {
  body {
    .react-jinke-music-player-main {
      .music-player-panel {
        .panel-content {
          .progress-bar-content {
            .progress-bar {
              background-size: cover;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 961px) and (max-width: 10000px) {
  .zd-track-view {
    .zd-title {
      width: 100% !important;
    }
    // --------css changed---
    .playlist-container {
      .zd-dropdown-crate {
        margin-top: 0;
      }
    }

    .zd-title {
      &.zd-chart-title {
        width: 80% !important;
      }
    }

    .zd-label {
      width: 30% !important;
    }

    .zd-genre {
      width: 40% !important;
    }

    .zd-genre-view-genre {
      width: 100% !important;
    }
    .zd-rotate-icons {
      width: 25px !important;
    }

    .zd-time {
      width: 60px !important;
    }

    .zd-rating {
      width: 60px !important;
    }

    .zd-bpm {
      width: 60px !important;
      color: #768492;
    }

    .zd-key {
      width: 60px !important;
      color: #768492;
    }

    .zd-release {
      width: 80px !important;
    }

    .zd-tag {
      width: 100px !important;

      .zd-tag-label {
        max-width: 96px;
      }
    }
    .zd-favourite {
      width: 24px;
    }
    .zd-icons {
      width: 60px !important;
    }
    .zd-crate {
      width: 24px !important;
    }
    .zd-dropdown-crate {
      margin-top: 8px;
    }
    .zd-last-row {
      width: 4px !important;
      vertical-align: -webkit-baseline-middle;
    }
    // .zd_tracklist_create {
    //   position: absolute !important;
    //   overflow: unset !important;
    // }
    .zd-artist {
      width: 53% !important;
      color: #ddd !important ;
    }

    .zd-position {
      width: 28% !important;
    }
  }

  // body {
  //   .zd-megagenre-parent {
  //     transform: translate3d(-185px, 0, 0) !important;
  //   }
  // }
}
@media (max-width: 340px) {
  .zd_fav_card {
    width: 290px !important;
  }
}
@media (max-width: 750px) {
  .zd-release-icon {
    width: 48px !important;
  }
  table .zd-small-album-cell .zd-album-art {
    width: 50px;
    max-height: 50px;
    min-height: 50px;
    border-radius: 2px;
    cursor: pointer;
  }
  table .zd-small-album-cell {
    width: 50px;
    max-width: 50px;
    padding: 0;
    overflow: hidden;
    margin: 0;
    vertical-align: top;
  }
}
@media (max-width: 416px) {
  .zd-line-tab .nav-item .nav-link {
    padding-left: 4px !important;
    padding-right: 4px !important;

    font-size: 12px;
  }
}
.zd-new-newreleases-page-child-table {
  .zd-new-newreleases-page-child-table-tbody {
    @media (min-width: 750px) and (max-width: 960px) {
      .zd-release-time {
        width: 48px;
      }
      .zd-release-icon {
        width: 10%;
      }
      .zd-favourite {
        width: 24px !important;
      }
      .zd-double-icons a {
        padding: 0 24px;
        font-size: 24px;
      }
    }
  }
}
@media (min-width: 0px) and (max-width: 1224px) {
  .zd-release-time {
    width: 48px;
  }
  .zd-release-icon {
    width: 16%;
  }
  .zd-favourite {
    width: 24px !important;
  }
}
//feedback mobile view responsive
@media only screen and (min-width: 750px) and (max-width: 1224px) {
  body {
    .zd-feedback-comment input {
      height: 97px !important;
    }
    .zd-feedback-active-border {
      height: 216px;
    }
    .zd-feedback-comment {
      padding-left: 94px;
    }
  }
}

@media (max-width: 750px) {
  .zd-feedback-active-border {
    height: 342px;
  }
  .feedback-sm-show {
    display: block;
  }
  .zd-feedback-favourite-mix-dropdown {
    position: absolute !important;
    padding-left: 0%;
  }
  .zd-feedback-rating-section .zd-feedback-rating {
    margin-left: 0px;
  }
  .zd-feedback-comment {
    padding-left: 0px;
  }
  .zd-feedback-comment input {
    height: 116px !important;
  }
  .zd-favourite {
    width: 34px !important;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1279px) {
  body {
    .zd-megagenre-parent {
      // transform: translate3d(-380px, 0px, 0px) !important;
    }
  }
}
@media only screen and (min-width: 1200px) {
  .zd-top-track-list > .row > div:first-child {
    width: 30%;
    flex: 0 0 30%;
    max-width: 30%;
    padding-right: 0;
  }

  .zd-top-track-list > .row > div:last-child {
    width: 70%;
    flex: 0 0 70%;
    max-width: 70%;
    padding-left: 0;
  }
}
/* Table Style */
@media only screen and (max-width: 1024px) {
  body {
    .zd-album-table {
      .zd-album-title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        // .track-name {
        //   display: none;
        // }
        //abinash change
        .track-name {
        }
      }
    }
  }
}

@media only screen and (max-width: 960px) and (min-width: 750px) {
  .zd-album-column {
    .zd-new-top-downloads-header-table {
      .zd-new-top-downloads-header-tbody {
        tr {
          .zd-title {
            width: 38% !important;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 0px) and (max-width: 750px) {
  .zd-new-top-downloads-child-table .zd-trending-pack-child-tbody {
    .zd-favourite {
      padding-top: 0px !important;
    }
  }
}

//Tab View
@media only screen and (max-width: 960px) and (min-width: 750px) {
  body {
    // .zd-album-table {
    //   &.zd-inner-table {
    //     tbody tr {
    //       position: relative;
    //     }

    //     tr {
    //       td {
    //         display: none;
    //       }

    //       .zd-topdownloads-count,
    //       td:last-child,
    //       .zd-small-album-cell,
    //       .zd-title,
    //       .zd-play,
    //       .zd-rotate-icons,
    //       .zd-large-album-cell,
    //       .zd-release-play {
    //         display: table-cell;
    //       }

    //       // td:first-child {
    //       //   width: calc(100% - 56px);
    //       //   display: block;
    //       //   overflow: hidden;
    //       //   float: left;
    //       //   height: 45px;
    //       // }

    //       .zd-title {
    //         width: calc(100% - 56px);
    //         display: block;
    //         overflow: hidden;
    //         float: left;
    //         height: 45px;
    //       }
    //       .zd-release-title,
    //       .zd-rotate-icons,
    //       .zd-release-icon,
    //       .zd-release-genre {
    //         height: 48px !important;
    //       }

    //       td:last-child {
    //         display: block;
    //         float: right;
    //         width: 36px;
    //         padding-left: 2px;
    //         height: 45px;
    //       }
    //       .zd-rotate-icons {
    //         float: left;
    //         width: 20px;
    //         padding-left: 2px;
    //         height: 45px;
    //       }
    //     }
    //   }

    //   .zd-chart-parent-title {
    //     width: calc(100% - 36px) !important;
    //     display: block;
    //     overflow: hidden;
    //     float: left;
    //     height: 65px !important;
    //   }

    //   .zd-chart-parent-icons {
    //     display: block;
    //     float: right;
    //     width: 36px;
    //     padding-left: 2px;
    //     height: 65px !important;
    //   }

    //   .zd-double-icons a.zd-tag-btn {
    //     display: none;
    //   }
    // }

    .zd-album-table {
      .zd-singlepack-header-tbody {
        .zd-rating {
          display: none;
        }
        // .zd-last-row{
        //   width: 0px;
        // }
      }
      .zd-singlepack-tbody {
        .zd-last-row {
          width: 0px;
        }
        .zd-double-icons a {
          padding: 0px 12px;
          font-size: 24px;
        }
        .zd-release {
          white-space: nowrap;
        }
      }
    }

    .zd-new-top-downloads-parent-table {
      .zd-gener-new-release-tbody {
        .zd-last-row {
          width: 10px;
        }
        .zd-double-icons a {
          padding: 0px 20px;
          font-size: 24px;
        }
        .zd-favourite {
          width: 14px !important;
        }
        .zd-tracklist-dropdown-box .zd-dropdown-crate {
          margin-top: 12px;
        }
      }
    }

    .zd-body-row-table tbody tr td {
    }

    .zd-new-newreleases-table {
      .zd-new-newreleases-page-header-table tbody tr {
        // height: 48px !important;
        td {
          display: none;
        }
        td:last-child,
        .zd-width-48,
        .zd-title,
        .zd-genre,
        .zd-rotate-icons,
        .zd-favourite,
        .zd-crate,
        .zd-icons,
        .zd-time {
          display: table-cell;
        }
        .zd-rotate-icons {
          width: 20px;
        }
        .zd-title {
          width: 42% !important;
        }
        .zd-genre {
          width: 19% !important;
        }
      }

      .zd-new-newreleases-page-main-table tbody {
        tr {
          td {
            display: none;
          }

          .zd-genre {
            width: 25% !important;
          }

          td:last-child,
          .zd-title,
          .zd-rotate-icons,
          .zd-large-album-cell,
          .zd-release-play,
          .zd-time,
          .zd-favourite,
          .zd-icons,
          .zd-crate,
          .zd-genre {
            display: table-cell;
          }
          td {
            align-items: center;
          }
          .zd-release-title span {
            &.zd-hide {
              display: block !important;
            }
          }
          .zd-float-icons {
            width: 20px;
          }
          .zd-title {
            width: 42.4% !important;
          }
        }
      }
      .zd-new-newreleases-page-child-table {
        .zd-dropdown-crate {
          margin-top: 8px !important;
        }
      }
      .zd-new-newreleases-page-child-table tbody {
        tr {
          .zd-dropdown-crate {
            margin-top: 1px !important;
          }
          td {
            padding: 10px 0.25rem;
            background-color: #242529;
            align-items: center;
            display: none;
          }
          td:last-child,
          .zd-title,
          .zd-rotate-icons,
          .zd-large-album-cell,
          .zd-release-play,
          .zd-time,
          .zd-release-favourite,
          .zd-release-crates,
          .zd-release-icon,
          .zd-genre {
            display: table-cell;
          }

          .zd-title {
            width: 39% !important;
          }
          .zd-float-icons {
            width: 20px;
          }
          .zd-genre {
            width: 19% !important;
            padding-left: 8px;
          }
        }
      }
    }

    .zd-top-charts-table {
      .zd-new-newreleases-page-header-table tbody tr {
        td {
          display: none;
        }
        td:last-child,
        .zd-width-48,
        .zd-title,
        .zd-genre,
        .zd-large-album-cell,
        .zd-play-col,
        .zd-icons,
        .zd-label {
          display: table-cell;
        }
        .zd-float-icons {
          width: 20px;
        }
        .zd-title {
          width: 25% !important;
        }
        .zd-genre,
        .zd-label {
          width: 15% !important;
        }
      }
      .zd-new-newreleases-page-main-table tbody {
        tr {
          td {
            display: none;
          }

          .zd-genre,
          .zd-label {
            width: 15% !important;
          }

          td:last-child,
          .zd-width-48,
          .zd-title,
          .zd-genre,
          .zd-large-album-cell,
          .zd-play-col,
          .zd-icons,
          .zd-label,
          .zd-time {
            display: table-cell;
          }
          td {
            align-items: center;
          }
          .zd-title span {
            &.zd-hide {
              display: block !important;
            }
          }
          .zd-float-icons {
            width: 20px;
          }
          .zd-title {
            width: 38% !important;
          }
        }
      }
      .zd-new-newreleases-page-child-table tbody {
        tr {
          td {
            // padding: 10px 0.25rem;
            // background-color: #242529;
            align-items: center;
            display: none;
          }
          td:last-child,
          .zd-width-48,
          .zd-title,
          .zd-genre,
          .zd-large-album-cell,
          .zd-play-col,
          .zd-icons,
          .zd-label,
          .zd-crate {
            display: table-cell;
          }
          .zd-genre,
          .zd-label {
            width: 15% !important;
          }
          .zd-float-icons {
            width: 20px;
          }
          .zd-title {
            width: 25% !important;
          }
          @media (min-width: 751px) and (max-width: 960px) {
            .zd-favourite {
              width: 0px !important;
              padding-left: 32px;
            }
            .zd-last-row {
              width: 14px;
            }
          }
        }
      }
    }

    .zd-new-top-downloads-main-table {
      .zd-new-top-downloads-header-table tbody {
        tr {
          td {
            display: none;
          }
          td:last-child,
          .zd-track-cover-col,
          .zd-title,
          .zd-genre,
          .zd-rotate-icons,
          .zd-small-album-cell,
          .zd-time,
          .zd-favourite,
          .zd-crate,
          .zd-icons,
          .zd-play-col {
            display: table-cell;
          }
          .zd-rotate-icons {
            width: 20px;
          }
          .zd-title {
            width: 30% !important;
          }
          .zd-genre,
          .zd-artist {
            width: 15% !important;
          }
          .zd-small-album-cell {
            width: 48px;
          }
        }
      }

      .zd-zipdj-pack-header-tbody {
        .zd-width-48 {
          width: 140px;
        }
      }

      .zd-new-top-downloads-parent-table .zd-zipdj-pack-tbody {
        tr {
          .zd-title {
            width: 48% !important;
          }
        }
      }

      .zd-new-top-downloads-child-table .zd-zipdj-pack-tbody {
        tr {
          .zd-title {
            width: 40% !important;
          }
          .zd-crate .zd-tracklist-dropdown-box .zd-dropdown-crate {
            margin-top: 13px;
          }
          .zd-release-icon {
            width: 10%;
          }
          .zd-genre {
            width: 15% !important;
          }
        }
      }

      .zd-album-table .zd-trending-pack-header-tbody {
        .zd-title {
          width: 26% !important;
        }
      }

      .zd-new-top-downloads-parent-table .zd-trending-pack-parent-tbody,
      .zd-new-top-downloads-child-table .zd-trending-pack-child-tbody {
        .zd-title {
          width: 42% !important;
        }
      }

      .zd-new-top-downloads-child-table .zd-trending-pack-child-tbody {
        .zd-release-favourite {
          padding-left: 30px;
        }
        .zd-double-icons {
          text-align: center;
          padding-right: 32px;
        }
        .zd-dropdown-crate {
          margin-top: 13px;
        }
      }

      .zd-new-top-downloads-parent-table tbody,
      .zd-new-top-downloads-child-table tbody {
        tr {
          height: 48px !important;
          td {
            display: none;
          }
          td:last-child,
          .zd-topdownloads-count,
          .zd-small-album-cell,
          .zd-play-col,
          .zd-title,
          .zd-genre,
          // .zd-artist,
          .zd-favourite,
          .zd-crate,
          .zd-icons,
          .zd-rotate-icons,
          .zd-time {
            display: table-cell;
          }
          .zd-rotate-icons {
            width: 20px;
          }
          .zd-title {
            width: 30% !important;
          }
          .zd-genre,
          .zd-artist {
            width: 15% !important;
            // padding-left: 0px;
          }
          .zd-small-album-cell {
            width: 48px;
            img,
            .zd-release-thumb {
              max-height: 48px;
              min-height: 48px;
              max-width: 48px;
              min-width: 48px;
            }
          }
          .zd-title a {
            &.zd-hide {
              display: block !important;
            }
            .track-name {
              display: none !important;
            }
          }
        }
      }
      .zd-trending-table tbody tr,
      .zd-topdownloads-table tbody tr {
        .zd-title {
          width: 37% !important;
        }
        .zd-genre {
          width: 25%;
        }
        .zd-artist {
          // width: 15% !important;
          padding-left: 0px;
        }
        .zd-last-row {
          width: 1%;
        }
      }
    }

    .zd-tertiary-header {
      .zd-second-col,
      .zd-third-col {
        display: none;
      }
    }

    .zd-table-head .zd-album-column .zd-album-table tbody tr td.zd-pl-78 {
      padding-left: 0;
    }

    .zd-table-head .zd-album-column .zd-album-table .zd-album-tbody tr td {
      padding: 0.5rem 1.85rem;
    }

    .zd-profile-dropdown .zd-down-arrow {
      display: none;
    }

    .zd-nested-dropdown {
      .zd-parent-menu {
        min-width: 220px;
      }
    }
    // .album-details .track-list .table-cell:nth-child(2)
    .zd-genre-view-title {
      width: calc(100% - 46px) !important;
    }
  }
}
.mr_12 {
  margin-right: 12px;
}

// Mobile View
@media only screen and (max-width: 750px) and (min-width: 0px) {
  .vertical-sound {
    display: none !important;
  }
  body {
    .zd-new-newreleases-table {
      .zd-new-newreleases-page-header-table tbody tr {
        td {
          display: none;
        }
        td:last-child,
        .zd-width-48,
        .zd-title,
        .zd-rotate-icons {
          display: table-cell;
        }
        .zd-rotate-icons {
          width: 20px;
        }
        .zd-title {
          width: 45% !important;
        }
      }

      .zd-new-newreleases-page-main-table tbody {
        tr {
          td {
            display: none;
          }

          .zd-release-title span {
            &.zd-hide {
              display: block !important;
            }
          }

          .zd-title,
          .zd-rotate-icons,
          .zd-large-album-cell {
            display: table-cell;
          }
          td {
            align-items: center;
          }
          .zd-rotate-icons {
            width: 10%;
          }

          .zd-title {
            width: 70% !important;
          }
        }
      }

      .zd-new-newreleases-page-child-table tbody {
        tr {
          td {
            padding: 10px 0.25rem;
            background-color: #242529;
            align-items: center;
            display: none;
          }
          td:last-child,
          .zd-title,
          .zd-release-icon,
          .zd-release-crates,
          .zd-favourite,
          .zd-icons,
          .zd-release-play {
            display: table-cell;
          }
          td:last-child {
            width: 10%;
            .zd-tag-btn {
              display: none;
            }
          }
          .zd-double-icons a {
            padding-left: 0px !important;
            padding-right: 18px !important;
          }
          .zd-title {
            width: 70% !important;
          }
        }
      }
    }

    .zd-new-top-downloads-main-table {
      .zd-new-top-downloads-header-table tbody {
        tr {
          td {
            display: none;
          }
          td:last-child,
          .zd-track-cover-col,
          .zd-title,
          .zd-rotate-icons,
          .zd-small-album-cell,
          .zd-release-crates,
          .zd-favourite,
          .zd-icons,
          .zd-play-col {
            display: table-cell;
          }
          .zd-rotate-icons {
            width: 20px;
          }
          .zd-title {
            width: 30% !important;
          }
          .zd-small-album-cell {
            width: 48px;
          }
        }
      }

      .zd-new-top-downloads-parent-table tbody,
      .zd-new-top-downloads-child-table tbody {
        tr {
          height: 48px !important;
          td {
            display: none;
          }
          td:last-child,
          .zd-topdownloads-count,
          .zd-small-album-cell,
          .zd-play-col,
          .zd-favourite,
          .zd-release-crates,
          .zd-icons,
          .zd-rotate-icons,
          .zd-title {
            display: table-cell;
          }
          .zd-title {
            width: 60%;
          }
          .zd-rotate-icons {
            width: 10%;
            @media (max-width: 375px) {
              width: 7px;
            }
          }
          .zd-small-album-cell {
            width: 48px;
            img,
            .zd-release-thumb {
              max-height: 48px;
              min-height: 48px;
              max-width: 48px;
              min-width: 48px;
            }
          }
          .zd-title a {
            &.zd-hide {
              display: block !important;
            }
            .track-name {
              display: none !important;
            }
          }
        }
      }
      .zd-trending-table tbody tr {
        .zd-title {
          width: 53%;
        }
      }
      .zd-topdownloads-table tbody tr {
        .zd-title {
          width: 45%;
        }
      }
    }

    .zd-top-charts-table {
      .zd-new-newreleases-page-header-table tbody tr {
        td {
          display: none;
        }
        td:last-child,
        .zd-width-48,
        .zd-title,
        .zd-large-album-cell,
        .zd-play-col,
        .zd-icons {
          display: table-cell;
        }
        .zd-float-icons {
          width: 20px;
        }
        .zd-title {
          width: 25% !important;
        }
      }
      .zd-new-newreleases-page-main-table tbody {
        tr {
          td {
            display: none;
          }

          // td:last-child,
          .zd-width-48,
          .zd-title,
          .zd-large-album-cell,
          .zd-play-col,
          .zd-icons {
            display: table-cell;
          }
          td {
            align-items: center;
          }
          .zd-title span {
            &.zd-hide {
              display: block !important;
            }
          }
          .zd-float-icons {
            width: 10%;
          }
          .zd-title {
            width: 45% !important;
          }
          // .zd-favourite {
          //   display: none !important;
          // }
        }
      }
      .zd-new-newreleases-page-child-table tbody {
        @media only screen and (max-width: 750px) and (min-width: 375px) {
          .zd-double-icons a {
            padding: 0 0px;
            font-size: 24px;
          }
        }
        tr {
          td {
            // padding: 10px 0.25rem;
            // background-color: #242529;
            align-items: center;
            display: none;
          }
          td:last-child,
          .zd-title,
          .zd-play-col,
          .zd-icons,
          .zd-crate {
            display: table-cell;
          }
          .zd-title {
            padding-left: 8px !important;
          }
          .zd-title {
            width: 55% !important;
          }
          .zd-album-favourite {
            i {
              margin-top: 4px;
            }
          }
        }
      }
    }
  }
  // css changed

  .top-downloads-page {
    .zd-title {
      @media (max-width: 750px) {
        width: 38% !important;
      }
    }

    .zd-icons {
      display: block !important;
      a {
        margin-top: 14px;
        margin-left: -6px;
      }
    }
    .zd-body-row-table tbody tr td {
      height: 48px !important;
    }
    .parent-tr {
      // .zd-icons {
      //   display: none !important;
      // }
      // .zd-album-favourite {
      //   i {
      //     display: none !important;
      //   }
      // }
    }
    .zd-new-top-downloads-parent-table {
      // .zd-icons {
      //   display: none !important;
      // }
    }
  }

  .top-downloads-page {
    .zd-download-favourtie {
      // display: none;
      .zd-album-favourite {
        display: block !important;
      }
    }
    .zd-double-icons {
    }
  }

  .zd-new-top-downloads-parent-table {
    // .zd-album-favourite {
    //   i {
    //     display: none !important;
    //   }
    // }
  }
  .zd-row-trendingcharts {
    .zd-download-btn {
      margin-left: 14px !important;
    }
    .zd-album-favourite {
      // margin-left: 10px;
    }
  }
  .zd_release_view_tab {
    .zd-new-newreleases-page-main-table {
      .zd-album-column {
        .zd-favourite {
          span {
            display: table-cell !important;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1030px) {
  body {
    .zd-profile-dropdown a .zd-username-menu {
      max-height: 40px;
      overflow: hidden;
    }
  }
}
@media only screen and (min-width: 768px) {
  .zd-slide-open {
    .cr-page {
      width: calc(100% - 250px);
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      position: relative;
    }
  }
}
/* Mega Menu Style Start */
@media only screen and (min-width: 0) and (max-width: 750px) {
  // .zd-last-row {
  //   display: none !important;
  // }
  body {
    .zd-float {
      float: left !important;
    }
    .zd-justify-content {
      justify-content: flex-start !important;
    }
    .zd-megagenre-parent {
      pointer-events: auto;
      height: 100vh;

      [class^='col-'] {
        padding: 0;
        margin-bottom: 15px;
      }

      .zd-megamenu-row {
        max-width: unset;
      }

      .zd-megagenre-dance {
        padding-right: 10px;
        height: 100%;
      }

      .zd-megagenre-house .list-group .zd-megagenre-innre-ul {
        max-height: unset;
        width: auto;
      }

      .country-section,
      .latin-section,
      .urban-section,
      .zd-megagenre-house {
        padding-left: 0;
      }
    }
    .zd-search-genre-btn {
      margin-left: 0 !important;
      padding-left: 0 !important;
      width: 32px;
    }

    .zd-header-profile-name {
      color: #6b7883;
      display: none;
    }

    .zd-hide-mobile {
      display: none !important;
    }

    .zd-hide-mobile.d-flex {
      display: none !important;
    }

    .zd-show-mobile {
      display: block !important;
    }
    .zd-page-title-wrap {
      width: 0 !important;
    }

    .cr-header {
      .navbar-nav {
        a.zd-nav-search-btn {
          display: inline-block;
        }
      }
    }

    &.player-active {
      .zd-megagenre-parent {
        margin-bottom: 56px;
        height: calc(100vh - 50px);
      }
    }
  }
  body {
    .zd-header-search-wrap {
      display: none !important;
    }

    &.zd-search-open {
      .zd-header-search-wrap {
        display: block !important;
        z-index: 5;
        box-shadow: 2px -2px 6px 0 rgba(0, 0, 0, 0.42);
        position: absolute;
        margin: 1px 0 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        top: 100%;
        height: 40px;
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        // transform-origin: right top;
        right: 10px;
        left: 52px;
        width: calc(100% - 60px) !important;
        @media only screen and (min-width: 0) and (max-width: 750px) {
          z-index: 1000 !important;
        }

        li {
          position: absolute;
          left: 0;
          right: 0;
        }

        .zd-search-form {
          width: 100%;
          position: relative;

          .cr-search-form__icon-search {
            left: 61px;
            color: #ddd;
            display: block;
            z-index: 1;
            font-size: 15px;
            top: 7px;
          }
        }
      }
    }
    .zd-megagenre-parent {
      // transform: translate3d(-125px, 0px, 0px) !important;
    }
  }
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .zd-hide-mobileTab {
    display: none !important;
  }

  .zd-hide-mobileTab.d-flex {
    display: none !important;
  }
}

@media only screen and (min-width: 1280) {
  .zd-megagenre-parent {
    // transform: translate3d(-380px, 0px, 0px) !important;
  }
}
@media only screen and (min-width: 0) and (max-width: 576px) {
  body .zd-megagenre-parent {
    .zd-megagenre-dance,
    .zd-megagenre-house {
      border: none;
    }
  }
  .small-screen-center {
    text-align: center;
    margin-top: 5px;
  }
  .zd-revert-classic-message {
    display: none !important;
  }
  .zd-electronic-tab {
    display: none;
  }
}
@media screen and (min-height: 900px) {
  body {
    .zd-megagenre-parent {
      height: auto;

      .zd-megagenre-house {
      }
    }
  }
}

@media screen and (min-width: 416px) and (max-width: 768px) {
  .zd-line-tab .nav-item .nav-link {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
}
/* Mega Menu Style  END */
@media only screen and (max-width: 750px) {
  body {
    .react-jinke-music-player-main {
      .music-player-panel {
        height: 88px !important;
      }
    }
  }
}
.w-11 {
  width: 12%;
}
@media only screen and (max-width: 1224px) {
  .playlist-container {
    .zd-dropdown-crate {
      margin-top: 0px !important;
    }
  }
  body {
    .zd-waves {
      display: block;
    }
    .zd-volume-slide-hide {
      display: none !important;
    }
    .player-close {
      // width: 20px;

      margin-right: 0 !important;
    }
    .react-jinke-music-player-main {
      .music-player-panel {
        height: 68px;
        padding-top: 12px;
        .panel-content {
          position: static;
          max-width: 100vw !important;
          padding-right: 20px;
          justify-content: space-between;

          .progress-bar-content {
            // flex: none;

            .audio-title {
              padding-top: 0 !important;
            }

            .progress-bar {
              position: static;
              .player-content {
                .zd-waves {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100% !important;
                  padding-top: 7px;
                  #waveform {
                    & > wave {
                      height: 12px !important;
                      // background-color: rgb(255, 255, 255);
                      & > wave {
                        // background-color: rgb(110, 73, 247) !important;
                        border-right: none !important;
                        position: relative;
                        &::after {
                          content: '';
                          position: absolute;
                          top: 0;
                          right: 0 !important;
                          height: 12px;
                          width: 12px;
                          border-radius: 12px;
                          background-color: #4f7be9;
                        }
                        &::before {
                          content: '';
                          position: absolute;
                          top: 4px;
                          left: 0 !important;
                          height: 4px;
                          width: 4px;
                          background-color: #4f7be9;
                          width: 100%;
                        }
                      }
                      canvas {
                        opacity: 0 !important;
                      }
                      &::after {
                        content: '';
                        position: absolute;
                        top: 4px;
                        left: 0 !important;
                        right: 0 !important;
                        height: 4px;
                        width: 4px;
                        width: 100%;
                        background-color: #47484a;
                        border-radius: 2px;
                      }
                    }
                    // background-color: rgb(208, 255, 0) !important;
                  }
                }
              }
            }

            .rc-slider {
              .rc-slider-track {
              }

              .rc-slider-handle {
              }

              .rc-slider-rail {
              }
            }
          }

          .player-content {
            padding-left: 5px;

            .play-sounds {
              .sounds-icon {
              }

              .sound-operation {
                .rc-slider-handle,
                .rc-slider-track {
                }
              }
            }

            .group {
              padding: 0;
            }

            .audio-lists-icon {
              padding: 0 3px;
            }

            .play-btn {
              padding: 0;

              svg {
                font-size: 42px;
              }
            }
          }
        }
      }
    }
  }
}
.w-0 {
  width: 0 !important;
}
@media only screen and (min-width: 0px) and (max-width: 750px) {
  .playlist-container {
    .zd-dropdown-crate {
      margin-top: 0px !important;
    }
  }
  body {
    .zd-revert-classic-message {
      font-size: 9px;
    }

    .cr-app {
      min-height: unset;
    }

    .cr-header {
      height: 48px;
    }

    .cr-header > .mr-2.navbar-nav {
      width: 90px;
      margin-right: 4px !important;
    }

    .cr-header.navbar-light .navbar-brand {
      width: 85px;
      margin-right: 0 !important;
      img {
        width: 85px;
      }
    }
    // .zd-head-feedback-text {
    //   padding-right: 0 !important;
    //   padding-left: 0 !important;
    // }
    .cr-header {
      .form-control.cr-search-form__input {
        background: #222327;
        height: 64px;
        font-size: 16px;
        width: 78vw;
        &:focus {
          border-color: $black;
        }
      }
    }
    .zd-genre-list-wrap .zd-genre-block {
      width: 100%;
    }

    &.zd-search-open {
      .cr-header {
        .zd-header-search-wrap {
          position: fixed;
          top: 0px;
          left: 0;
          right: 0;
          z-index: 7;
          margin: 0;
          width: 100% !important;
          overflow: visible;
          > li {
            position: relative;
            margin: 0px !important;
            padding: 0px !important;
            .react-autosuggest__container {
              position: relative;
              width: 100%;
            }
          }
        }
        .react-autosuggest__suggestions-container--open {
          height: auto;
          position: absolute;
          top: 40px;
          width: 100%;
          left: 0;
          right: 0;

          .react-autosuggest__section-container {
            position: relative;

            .react-autosuggest__suggestion {
              position: relative;
            }
          }
        }
      }
    }

    .zd-hide-mobile {
      display: none;
    }

    .zd-hide-mobile.d-flex {
      display: none !important;
    }

    .zd-show-mobile {
      display: block;
    }

    .cr-header {
      .navbar-nav {
        a.zd-nav-search-btn {
          display: inline-block;
        }
      }
    }

    .zd-profile-dropdown {
      padding-left: 0.5rem;
      // position: fixed;
      // right: 0;

      a {
        display: block;
        text-align: center;

        .zd-username-menu {
          padding: 0;
          width: 40px;
          padding-top: 7px;
          margin: auto;
        }

        .zd-header-profile-name {
          display: none;
        }

        &:hover {
          color: $white;
        }
      }
    }
  }

  body {
    .zd-header-search-wrap {
      display: none !important;
    }

    &.zd-search-open {
      .zd-header-search-wrap {
        display: block !important;
        z-index: 5;
        box-shadow: 2px -2px 6px 0 rgba(0, 0, 0, 0.42);
        position: absolute;
        margin: 1px 0 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        top: 100%;
        height: 50px;
        border-bottom: 1px solid #3c3d41;
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        // transform-origin: right top;
        right: 10px;
        left: 52px;
        width: calc(100% - 60px) !important;

        li {
          position: absolute;
          left: 0;
          right: 0;
        }

        .zd-search-form {
          width: 100%;
          position: relative;
          background-color: #222327 !important;
          margin-top: 15px;
          @media (max-width: 750px) {
            margin-top: 8px;
          }
        }
      }
    }
  }

  body {
    .zd-content-head {
      .zd-secondary-header {
        .nav-item {
          .nav-link {
            padding: 5px 13px;
          }
        }
      }
    }
  }

  body {
    .zd-profile-page {
      .profile-container {
        .zd-profile-right {
          padding: 0;
        }
      }
    }
  }

  body {
    .zd-megagenre-parent {
      // transform: translate3d(-75px, 0px, 0px) !important;
      height: calc(100vh - 56px);
    }
  }
}
@media only screen and (min-width: 414px) and (max-width: 766px) {
  .zd-m-hide {
    display: none;
  }
}
@media only screen and (min-width: 0px) and (max-width: 480px) {
  .zd-download-manager-btn {
    // display: none !important;
  }
}
@media only screen and (min-width: 0px) and (max-width: 414px) {
  body {
    .zd-sm-hide {
      display: none;
    }

    .react-datepicker-popper {
      // transform: translate3d(25px, 23px, 0px) !important;
    }

    .zd-album-table {
      tbody .zd-count {
        display: none;
      }
    }
    .zd-head-feedback-text {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    .zd-content-head {
      &.zd-fixed-header {
        padding-bottom: 10px;
      }
    }

    .zd-content-wrap {
      &.is-secondary-header {
      }
    }
    .zd-new-newreleases-page {
      .zd-fixed-table {
        .zd-fixed {
          display: none;
        }

        .zd-zipdj-pack-tbody {
          .zd-favourite {
            padding-top: 0px;
            display: block;
            i {
              margin-top: 3px;
            }
          }
        }

        .zd-favourite {
          padding-top: 9px;
          display: block;
          i {
            margin-top: 3px;
          }
          // padding-left: 30px;
        }
        .zd-release-icon {
          width: 40px !important;
        }
      }
    }

    .zd-table-head {
      .zd-album-column .zd-album-table tbody tr {
        td.zd-pl-88 {
          padding-left: 65px;
        }
      }
    }
  }

  body .zd-label-search-header {
    .cr-search-form__input,
    .react-autosuggest__container {
      width: 100%;
    }
  }
  .zd-top-charts-table {
    @media (max-width: 750px) {
      .zd-favourite {
        padding-left: 0 !important;
        padding-top: 0 !important;
        i {
          margin-top: 0 !important;
        }
      }
    }
  }
  .zd-row-topdownload {
    .zd-fixed-table {
      .zd-favourite {
        padding-left: 0 !important;
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  body .zd-body-row-table tbody tr td {
    padding: 0px 0.25rem;
  }
}
.is-secondary-header {
  .zd-release-title {
    padding-left: 1rem !important;
  }
}
.zd-float {
  float: right;
}
.zd-justify-content {
  justify-content: flex-end;
}
.zd-right {
  right: 0 !important;
}
@media (min-width: 1480px) {
  .zd-packs_artist {
    padding-left: 26px !important;
  }
  .zd-packs_genre {
    padding-left: 15px !important;
  }
}
@media (max-width: 750px) {
  body {
    .react-jinke-music-player-main {
      .music-player-panel {
        .panel-content {
          .img-content {
            width: 40px !important;
            height: 40px !important;
            margin-bottom: 7px;
            margin-left: 7px;
          }

          .player-content {
            .audio-download,
            .audio-keys-btn,
            .audio-lists-btn {
              display: none !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 350px) {
  body {
    .react-jinke-music-player-main {
      .music-player-panel {
        .panel-content {
          .img-content {
            display: none;
          }

          .player-content {
            .zd-manage-track-player {
              margin-right: 2px;
              margin-left: 2px;
            }
            .audio-download,
            .audio-keys-btn,
            .audio-lists-btn {
              display: none !important;
            }
          }
        }
      }
    }
  }
}
.zd-head-margin {
  @media (max-width: 750px) {
    margin-top: 1.5rem !important;
  }
}
@media (max-width: 750px) {
  .sub-genre-td {
    width: auto !important;
    height: 48px !important;
    padding-top: 2px !important;
  }
}
.slider-icon {
  .slick-prev,
  .slick-next {
    display: none !important;
  }
}

.zd-newreleases-table {
  .zd-body-row-table {
    tr {
      &:hover {
        background-color: #303139 !important;
      }
    }
  }
}
.zd-album-column {
  .zd-body-row-table {
    tr {
      &:hover {
        background-color: #303139 !important;
      }
    }
  }
}
.zd-social-icon {
  @media (max-width: 750px) {
    text-align: center;
  }
}
.zd_over_unset {
  overflow: unset !important;
}
// Crate history playlist
// @media (max-width: 1440px) {
//   .crate-playlist-view {
//     max-width: 18.7777% !important;
//   }
// }
// @media only screen and (min-width: 1224px) and (max-width: 1440px) {
//   .crate-playlist-view {
//     max-width: 22.433% !important;
//   }
// }
@media only screen and (max-width: 750px) and (min-width: 0px) {
  .zd_track_view .zd-dropdown-crate {
    margin-top: 12px !important;
  }
  .zd-album-favourite i {
    display: block !important;
  }
  .zd-single-head {
    .zd-tr-color {
      .zd-release-play {
        display: table-cell;
      }
    }
  }
  .zd-new-newrelease-track-view {
    .zd-tr-color {
      .zd-title {
        .zd-fixed {
          display: table-cell;
        }
      }
    }
  }
  @-moz-document url-prefix() {
    .zd-new-newrelease-track-view {
      .zd-dropdown-crate {
        margin-top: -11px !important;
      }
    }
    .zd-single-release-page-view {
      .zd-dropdown-crate {
        margin-top: -11px !important;
      }
    }
  }

  .zd-single-release-page-view {
    tbody {
      tr {
        .zd-last-row {
          display: table-cell;
        }
        .zd-title-new {
          width: 66% !important;
        }
        .zd-dropdown-crate {
          margin-top: 7px;
        }
      }
    }
  }
}
@media only screen and (max-width: 441px) and (min-width: 0px) {
  .zd-single-release-page-view tbody tr .zd-title-new {
    width: 48% !important;
    @media (max-width: 750px) {
      width: 60% !important;
    }
  }
}

@-moz-document url-prefix() {
  .zd-home-trending-dropdown-box .zd-dropdown-crate {
    margin-top: -12px !important;
  }
  @media screen and (min-width: 750px) and (max-width: 960px) {
    .zd-track-view .zd-dropdown-crate {
      margin-top: -11px !important;
    }
  }
  .zd-topdownload-dropdown-box .zd-dropdown-crate {
    margin-top: -13px;
  }
  .zd-track-view .zd-dropdown-crate {
    margin-top: -11px;
  }

  .zd-release-res {
    .zd-dropdown-crate {
      margin-top: 4px;
    }
  }
}

.zd-nowrap {
  white-space: nowrap !important;
}

@media (max-width: 750px) {
  .zd-fav-crate {
    padding-top: 4px !important;
  }
  .zd-last-row {
    width: 10px !important;
  }
  .zd-favourite {
    display: table-cell !important;
  }
  .zd-release-crates {
    width: 10px;
  }
  .zd-title {
    width: 43px;
  }
  .zd-release-crates .crate-dropdown-menu {
    transform: translate3d(-137px, 7px, 0px) !important;
  }
}

@media (max-width: 500px) {
  .zd-singlepack-tbody {
    .zd-title {
      width: 60% !important;
    }
  }
  .zd-title {
    width: 50% !important;
  }
}
@media (max-width: 414px) {
  .top-downloads-page .zd-title {
    width: 96px !important;
  }
}
@media (max-width: 960px) and (min-width: 750px) {
  .homemain {
    .zd-topdownload-dropdown-box .zd-dropdown-crate {
      margin-top: 19px;
    }
    .zd-home-trending-dropdown-box .zd-dropdown-crate {
      margin-top: 12px;
    }
  }
  .zd-release-crates .crate-dropdown-menu {
    transform: translate3d(-141px, 7px, 0px) !important;
  }
  .zd-topdownload-dropdown-box .zd-dropdown-crate {
    margin-top: 12px;
  }
  .zd-row-topdownload .zd-last-row {
    width: 10px !important;
  }
}
.zd-eleps {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}
@media (max-width: 560px) {
  .crate-item-main-container {
    display: block;
    #zd_release_view {
      margin-top: 20px;
    }
    .zd-favourite-dropdown-box .zd-dropdown-crate {
      margin-top: 13px;
    }
  }
}
@media (max-width: 750px) and (min-width: 0px) {
  .zd-crete-feedback {
    .zd-desktop {
      display: none !important;
    }
  }
}
@media (max-width: 1366px) {
  .zd-crete-feedback {
    .zd-desktop {
      display: none !important;
    }
    .zd-tablet {
      display: none !important;
    }
    .zd-mobile {
      display: block !important;
    }
  }
}
@media (min-width: 1366px) {
  .zd-crete-feedback {
    .zd-desktop {
      display: block !important;
    }
    .zd-tablet {
      display: none !important;
    }
  }
}
@media (max-width: 1366px) {
  .zd-wave_loader .zd-wave-loader {
    top: 33%;
    left: 70%;
  }
}
@media (max-width: 750px) {
  .zd-wave_loader .zd-wave-loader {
    top: 33%;
    left: 50%;
  }
}
@media (max-width: 1225px) {
  .zd-dmw-table {
    .zd-title {
      width: 33% !important;
    }
    .zd-genre {
      width: 33%;
    }
    .zd-time {
      width: 8%;
    }
  }
}
@media (max-width: 750px) {
  .zd-dmw-table .zd-title {
    width: 52% !important;
  }
}
.zd-icon-delete-2 {
  font-size: 16px;
}
.zd_category_search {
  display: flex;
  justify-content: space-between;
  margin-right: 51px;
  @media (max-width: 750px) {
    margin-right: 0;
  }
}
.zd_v5_cursor_hide span a{
  cursor: inherit !important;
}