@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Khula:wght@300;400;600;700;800&display=swap');
@font-face {
  font-family: 'Gilroy';
  src: url(../../assets/fonts/Gilroy-SemiBold.ttf);
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url(../../assets/fonts/Gilroy-Bold.ttf);
  font-weight: bold;
  font-style: normal;
}
.var-container {
  width: calc(100vw - 66px);
  background: #1c1e22, 100%;
  z-index: 1;
}
.main {
  background: rgb(28 29 32);
  transform: none !important;
  will-change: unset !important;
  font-family: 'Poppins', 'Roboto', sans-serif;
  /* padding-left: 2px; */
}
::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.var-container__top {
  /* padding-left: 4px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 1224px) and (min-width: 750px) {
  .var-container {
    position: fixed;
    overflow: hidden;
    overflow-y: scroll;
    width: calc(100vw - 56px);
    padding-left: 8px;
  }
  .var-container::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  .var-container__top {
    height: calc(100vh - 64px);
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: calc(100vw - 56px);
  }
  .main {
    top: 0;
    left: 0;
    width: 90vw;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .var-container-tablet {
    height: 87.4vh !important;
  }

  .alltag_sm-tablet {
    height: 80vh !important;
  }

  .tag-container-tablet {
    height: 80vh !important;
    overflow-x: hidden !important;
  }
}

@media screen and (max-width: 750px) {
  .var-container {
    position: relative;
    width: 100vw;
    left: 0;
    background: #1c1e22;
  }
  .var-container__top {
    padding-left: 0;
    flex-direction: column;
  }
  .main {
    position: fixed;
    width: 100vw;
    height: calc(100vh - 48px);
    top: 48px;
    left: 0px;
    overflow-y: scroll;
    padding-left: 0 !important;
  }
  .main::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  .zd-primary-dropdown {
    margin-left: 25px;
  }
}

body.body-overflow-hidden {
  overflow: hidden !important;
}
body.body-overflow-hidden .cr-header {
  padding-right: 30px !important;
}
@media screen and (max-width: 750px) {
  body.body-overflow-hidden .cr-header {
    padding-right: 0px !important;
  }
}
.zdv3-icon-close {
  font-size: 16px;
  color: #8e8e8e;
  padding: 3px;
}
@media screen and (min-width: 2200px) {
  .var-container__top {
    display: block;
  }
}
