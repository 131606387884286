@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Khula:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Khula:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans:400,700,400italic);
@font-face {
  font-family: 'zdv3-icon';
  src: url(/app/static/media/zdv3-icon_bkp.c890ef8c.eot);
  /* IE9*/
  src: url(/app/static/media/zdv3-icon_bkp.c890ef8c.eot#iefix) format("embedded-opentype"), url(/app/static/media/zdv3-icon_bkp.af1e95f0.woff2) format("woff2"), url(/app/static/media/zdv3-icon_bkp.d412fab9.woff) format("woff"), url(/app/static/media/zdv3-icon_bkp.7383d14d.ttf) format("truetype"), url(/app/static/media/zdv3-icon_bkp.bb6eb5d2.svg#zdv3-icon) format("svg");
  /* iOS 4.1- */ }

[class^='zdv3-icon-'],
[class*=' zdv3-icon-'] {
  font-family: 'zdv3-icon' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.zdv3-icon-AddPlaylist:before {
  content: '\EA01'; }

.zdv3-icon-Crates:before {
  content: '\EA02'; }

.zdv3-icon-Instagram:before {
  content: '\EA03'; }

.zdv3-icon-Linkedin:before {
  content: '\EA04'; }

.zdv3-icon-SoundClord:before {
  content: '\EA05'; }

.zdv3-icon-Tweeter:before {
  content: '\EA06'; }

.zdv3-icon-Web:before {
  content: '\EA07'; }

.zdv3-icon-Youtube:before {
  content: '\EA08'; }

.zdv3-icon-backward:before {
  content: '\EA09'; }

.zdv3-icon-check-box:before {
  content: '\EA0A'; }

.zdv3-icon-circle:before {
  content: '\EA0B'; }

.zdv3-icon-close-player:before {
  content: '\EA0C'; }

.zdv3-icon-close:before {
  content: '\EA0D'; }

.zdv3-icon-cloud-download:before {
  content: '\EA0E'; }

.zdv3-icon-crate-drag-drop:before {
  content: '\EA0F'; }

.zdv3-icon-djinfo-add:before {
  content: '\EA10'; }

.zdv3-icon-djinfo-close:before {
  content: '\EA11'; }

.zdv3-icon-djinfo-edit:before {
  content: '\EA12'; }

.zdv3-icon-download-manager:before {
  content: '\EA13'; }

.zdv3-icon-download:before {
  content: '\EA14'; }

.zdv3-icon-drop-down:before {
  content: '\EA15'; }

.zdv3-icon-edit:before {
  content: '\EA16'; }

.zdv3-icon-facebook:before {
  content: '\EA17'; }

.zdv3-icon-favourite:before {
  content: '\EA18'; }

.zdv3-icon-filled-downWard-arrow:before {
  content: '\EA19'; }

.zdv3-icon-filled-favourite:before {
  content: '\EA1A'; }

.zdv3-icon-forward:before {
  content: '\EA1B'; }

.zdv3-icon-genre-filter:before {
  content: '\EA1C'; }

.zdv3-icon-genres:before {
  content: '\EA1D'; }

.zdv3-icon-heart:before {
  content: '\EA1E'; }

.zdv3-icon-home:before {
  content: '\EA1F'; }

.zdv3-icon-key-shortcut:before {
  content: '\EA20'; }

.zdv3-icon-keyboard:before {
  content: '\EA21'; }

.zdv3-icon-labels:before {
  content: '\EA22'; }

.zdv3-icon-left-arrow:before {
  content: '\EA23'; }

.zdv3-icon-menu-after:before {
  content: '\EA24'; }

.zdv3-icon-menu:before {
  content: '\EA25'; }

.zdv3-icon-mute:before {
  content: '\EA26'; }

.zdv3-icon-new-releases:before {
  content: '\EA27'; }

.zdv3-icon-next:before {
  content: '\EA28'; }

.zdv3-icon-packs:before {
  content: '\EA29'; }

.zdv3-icon-pause:before {
  content: '\EA2A'; }

.zdv3-icon-play:before {
  content: '\EA2B'; }

.zdv3-icon-player-mute:before {
  content: '\EA2C'; }

.zdv3-icon-player-volume:before {
  content: '\EA2D'; }

.zdv3-icon-previous:before {
  content: '\EA2E'; }

.zdv3-icon-profile:before {
  content: '\EA2F'; }

.zdv3-icon-release-view-btn:before {
  content: '\EA30'; }

.zdv3-icon-reset:before {
  content: '\EA31'; }

.zdv3-icon-right-arrow:before {
  content: '\EA32'; }

.zdv3-icon-search:before {
  content: '\EA33'; }

.zdv3-icon-setting:before {
  content: '\EA34'; }

.zdv3-icon-settings-icon:before {
  content: '\EA35'; }

.zdv3-icon-shortcut:before {
  content: '\EA36'; }

.zdv3-icon-spinvault:before {
  content: '\EA37'; }

.zdv3-icon-star:before {
  content: '\EA38'; }

.zdv3-icon-top-charts:before {
  content: '\EA39'; }

.zdv3-icon-top-downloads:before {
  content: '\EA3A'; }

.zdv3-icon-track-view-btn:before {
  content: '\EA3B'; }

.zdv3-icon-trending-charts:before {
  content: '\EA3C'; }

.zdv3-icon-up-arrow:before {
  content: '\EA3D'; }

.zdv3-icon-vector:before {
  content: '\EA3E'; }

.zdv3-icon-vol:before {
  content: '\EA3F'; }

.zdv3-icon-volume:before {
  content: '\EA40'; }

.zdv3-icon-zdfavourite:before {
  content: '\EA41'; }

.zd-search-genre-btn .nav-item {
  width: 118px !important; }

.zdv3-icon-profile {
  font-size: 24px !important; }

.zd-sm-header-profile {
  display: none; }
  @media (max-width: 750px) {
    .zd-sm-header-profile {
      display: block;
      display: flex;
      align-items: center;
      justify-content: space-between; } }
  .zd-sm-header-profile i {
    font-size: 16px;
    color: #ABB1B8; }
  .zd-sm-header-profile .zd-sm-sidebar-profile-img img {
    width: 32px;
    height: 32px;
    border-radius: 50px; }

.genre {
  display: none; }
  @media (max-width: 750px) {
    .genre {
      display: block !important;
      background: #222327 !important;
      margin-top: -1px !important; } }

.zd-genre_menu {
  align-items: center;
  background-color: #32333A;
  height: 32px;
  padding: 0px 12px 0px 8px;
  padding-left: 8px !important;
  cursor: pointer;
  margin-right: 16px;
  border: 1px solid #32333A; }

.alltag {
  /* position: absolute; */
  top: 100%;
  width: 100%;
  z-index: 20;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 3px 20px 20px;
}

@media screen and (min-width: 1195px) {
  .alltag {
    padding: 20px 20px 20px 24px;
    width: calc(100vw - 100px);
  }
  .alltag__container {
    padding: 4px 0;
  }
  .alltag__container {
    /* height: 220px; */
    padding-top: 5px;
    width: 26%;
  }
  .tag-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-height: calc(100vh - 100px);

    flex-direction: column;
  }
}

@media screen and (min-width: 1440px) {
  .tag-container {
    max-height: calc(100vh - 100px);
  }
}
@media screen and (min-width: 1740px) {
  .tag-container {
    max-height: calc(100vh - 400px);
  }
}

.alltag__heading {
  display: inline;
  padding: 4px 4px;
  font-size: 14px;
  color: #4f7be9;
  font-family: 'Poppins', 'Roboto', sans-serif;
  font-weight: 600;
}
.alltag__tag {
  padding: 3px;
  color: #ddd !important;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
  border: 1px solid #40434c;

  background: #2c2e35;
}
.alltag__tag span {
  color: #ddd !important;
}

.alltag__tag:hover {
  border-color: #4f7be9;
}

.alltag__tag p {
  white-space: nowrap;
  padding: 6px 8px 6px 0px !important;
  font-size: 12px;
  display: inline;
  font-weight: 300;
  /* background: #242529; */
  letter-spacing: 1%;
  font-family: 'Poppins', 'Roboto', sans-serif;
}
.alltag__tag p span span {
  padding-left: 6px;
}
.alltag__tag p:hover {
  color: #4f7be9;
  /* border-color: #4f7be9; */
  /* border: 1px solid #4f7be9; */
}
.septags {
  margin-top: 6px;
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 750px) {
  .alltag {
    background: #1c1e22;
    display: none;
  }
  .tag-container {
    height: auto;
    flex-wrap: nowrap;
  }
  .showTag .alltag {
    display: block;
  }
  .showTag .alltag .tag-container {
    height: calc(100vh - 150px);
    overflow-y: scroll;
  }
  .showTag .alltag .tag-container::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  .alltag_sm {
    color: white;
    position: fixed;
    right: 5px;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: calc(100vh - 177px);
  }
  .alltag_az_sm {
    color: rgb(214, 214, 236);
    opacity: 0.5;
    font-size: 12px;
    padding: 2px 0;
  }
  .alltag_az_sm.active {
    color: rgb(59, 150, 235);
    opacity: 1;
  }
  .alltag_az_sm.active p:hover,
  .alltag_az_sm.active p:focus {
    color: rgb(15, 139, 255);
    font-weight: bold;
  }
  .septags {
    /* flex-direction: column; */
  }
  .alltag__tag {
    margin-bottom: 8px;
  }
  .alltag__tag p {
    padding: 6px 8px;
  }
  .alltag__container {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 2000px) and (min-width: 1560px) {
  .alltag__heading {
    font-size: 14px;
  }
}

.bottombar {
  width: calc(100vw - 66px);
  background: #1f2024;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 2;
  /* margin-left: 4px; */
  display: grid;
  margin-top: 4px;
  grid-template-columns: 0.65fr 2fr;
  height: 175px;
}

.bottombar.submenu {
  /* height: calc(100vh - 46px); */
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #242529;
}
.bottombar__subgeners .favourite-search-bar-form .favourite-search-box {
  width: 223px !important;
}
@-moz-document url-prefix() {
  .bottombar__subgeners .favourite-search-bar-form {
    color: #ddd;
  }
  .bottombar__subgeners .favourite-search-bar-form::placeholder:focus {
    color: #ddd;
  }
  .bottombar__subgeners
    .favourite-search-bar-form
    .favourite-search-box::placeholder {
    color: #6b7883;
    opacity: 1;
  }
}

.zdv3-icon-search:hover {
  color: #ffff !important;
}
.favourite-search-box::-webkit-input-placeholder {
  color: #6b7883;
}
.favourite-search-box::placeholder {
  color: #6b7883;
}

.favourite-search-box:hover::-webkit-input-placeholder {
  color: #dddddd;
}

.favourite-search-box:hover::placeholder {
  color: #dddddd;
}
.favourite-search-box:focus::-webkit-input-placeholder {
  color: #dddddd !important;
}
.favourite-search-box:focus::placeholder {
  color: #dddddd !important;
}

from:hover {
  color: #ffff;
}

.bottombar__subgeners {
  display: flex;
  align-items: center;
  padding: 15px;
  padding-left: 24px;
  justify-content: space-between;
  margin-right: 24px;
}
.bottombar__genre {
  margin-right: 24px;
}

.bottombar__subgeners h3 {
  color: #ddd;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.24em;
  font-family: 'Poppins', 'Roboto', sans-serif;
  margin-bottom: 0 !important;
}
.bottombar__subgeners button {
  font-size: 10px;
  background: transparent;
  border: none;
  outline: none;
  color: rgb(79, 123, 233, 1);
  font-family: 'Poppins', 'Roboto', sans-serif;
  cursor: pointer;
  padding: 0 !important;
  transition: 0.4s ease;
  font-weight: 600;
}
.bottombar__subgeners button:hover {
  color: rgb(59, 107, 228);
  text-decoration: underline;
  cursor: pointer;
}
.selector .letter {
  padding-right: 26px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 35px;
}
.selector .letter h5 {
  padding: 7px 9px 0;
  font-size: 14px;
  border-top: 2px solid transparent;
  font-weight: 500;
  font-family: 'Poppins', 'Roboto', sans-serif;

  color: #47484a;
}
.selector .letter h5.hastag:hover {
  border-top: 2px solid#4F7BE9;
  cursor: pointer;
  color: #4f7be9;
  font-weight: 500;
}
.selector .letter h5.active {
  border-top: 2px solid #4f7be9;
  color: #4f7be9;
  font-weight: 500;
}
.bottombar__tags {
  display: flex;
  flex-wrap: wrap;
}
.bottombar__tag p {
  font-size: 12px;
  margin-right: 8px;
  /* padding: 6px 12px; */
  padding: 6px 8px 6px 0px;
  background: #242529;
  color: #d8d8d8;
  font-family: 'Poppins', 'Roboto', sans-serif;
  font-weight: 500;
  border-radius: 2px;
}
.border_bottom_bar_left {
  border-left: 4px solid #4f7be9;
}
.bottombar__tag p span span {
  padding-left: 8px;
}
.bottombar__tag p:hover {
  color: #4f7be9;
}

/* Player Active */

@media screen and (max-width: 1224px) and (min-width: 750px) {
  .bottombar {
    /* position: fixed; */
    /* width: calc(34.5vw - 6px); */
    margin-left: 6px;
    display: block;
    top: 64px;
    /* background: linear-gradient(rgba(34, 35, 39, 0.95), rgba(34, 35, 39, 0.95)); */
    left: unset;
    right: 10px !important;
    height: auto;
    margin-top: 0;
  }
  .bottombar__subgeners {
    padding-left: 15px;
  }
  .alltag {
    display: block;
    padding: 5px 8px;
  }
  .tag-container {
    width: 68%;
    display: flex;
    flex-wrap: wrap;
    max-height: 96rem;
    flex-direction: column;
  }
  .tag-container::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  .alltag_sm {
    position: fixed !important;
    top: 0;
    right: 5px;
    height: calc(100vh - 19vh);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .alltag__container {
    width: 26%;
    padding-top: 5px;
  }
  .alltag__tag p {
    padding: 6px 8px;
    font-size: 10px;
    /* background: #1c1d20; */
  }
  /* .alltag__tag {
    padding: 5px;
  } */
  .septags {
    margin-top: 12px;
  }
  .alltag_az_sm {
    color: rgb(214, 214, 236);
    opacity: 0.5;
    font-size: 14px;
    padding: 0;
    font-weight: bold;
  }
  .alltag_az_sm p {
    margin-bottom: 0 !important;
  }
  .alltag_az_sm.active {
    color: rgb(59, 150, 235);
    opacity: 1;
  }
}
@media screen and (max-width: 1224px) and (min-width: 750px) and (max-height: 1100px) {
  .alltag_sm {
    position: fixed !important;
    top: 0;
    right: 5px;
    height: calc(100vh - 15vh);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}
@media screen and (max-width: 750px) {
  .bottombar__subgeners h3 {
    font-size: 15px;
    letter-spacing: 0.1em;
  }
  .bottombar {
    position: relative;
    width: 100vw;
    top: 0;
    margin-top: 0;
    margin-left: 0px !important;
    height: 64px;
    grid-template-columns: none;
    background-position: center;
    background-size: cover;
    /* background: linear-gradient(
        0deg,
        rgba(34, 35, 39, 0.9),
        rgba(34, 35, 39, 0.9)
      ),
      url('../../assets/img/All-sub-genres.png'); */
  }
  .showTag.bottombar {
    /* background: linear-gradient(rgba(0, 0, 0, 0.322), rgba(0, 0, 0, 0.363)),
      url('../../assets/img/All-sub-genres.png'); */
    position: fixed;
    top: 48px;
    width: 100vw;
    z-index: 120;
    background-size: cover;
    background-position: center;
  }
  .bottombar__subgeners {
    display: block;
    padding: 24px 0;
    padding-left: 20px;
  }
  .alltag_az_sm p {
    margin-bottom: 0 !important;
    font-size: 10px;
  }
}
@media screen and (max-height: 640px) {
  .alltag_az_sm p {
    font-size: 8px;
  }
}
@media screen and (max-width: 1224px) {
  .bottombar__subgeners .favourite-search-bar-form {
    display: none;
  }
  .genre_heading {
    display: none;
  }
}
@media screen and (max-width: 1368px) and (min-width: 1225px) {
  .bottombar__subgeners h3 {
    font-weight: 500;
    font-size: 14px;
    font-style: normal;
    letter-spacing: 0.14em;
  }
  .selector .letter h5 {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
  }
  .bottombar__tag p {
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
  }
  .bottombar {
    height: 125px;
    margin-top: 4px;
    grid-template-columns: 0.62fr 2fr;
  }
  .alltag__tag p {
    font-size: 10px;
  }
  /* .tag-container {
    max-height: 44rem !important;
  } */
  .alltag__heading {
    font-size: 12px;
  }

  /* Player Active */
  .player-active .bottombar {
    height: 130px;
  }
}
@media screen and (min-width: 1368px) {
  .player-active .bottombar {
    height: 140px;
  }

  .player-active .selector .letter {
    margin-bottom: 23px;
  }
}
@media screen and (max-width: 2000px) and (min-width: 1560px) {
  .bottombar__subgeners {
    padding-left: 136px;
  }

  .bottombar {
    grid-template-columns: 0.58fr 2fr;
    height: 220px;
  }
  .bottombar__tag p {
    font-size: 12px;
  }
  .alltag {
    padding: 20px 20px 80px 132px;
  }
  /* .tag-container {
    max-height: 55rem;
  } */
  .selector .letter h5 {
    font-size: 16px;
  }
  .player-active .bottombar {
    grid-template-columns: 0.6fr 2fr;
    height: 220px;
  }
  .player-active .selector .letter {
    margin-bottom: 66px;
  }
}

@media screen and (min-width: 2200px) {
  .bottombar {
    height: 93%;
    display: block;
    width: 39%;
    position: fixed;
    top: 50px;
    right: 5px;
    margin-top: 13px;
  }
  .bottombar__subgeners h3 {
    font-size: 20px;
  }
  .player-active .bottombar {
    height: 88.5%;
  }
  .alltag {
    width: calc(100vw - 61vw);
  }

  .alltag__container {
    width: 35%;
  }
  .alltag__tag {
    margin-bottom: 14px;
  }
  .alltag__tag p {
    font-size: 14px;
  }
  .septags {
    margin-top: 19px;
  }
  .tag-container {
    max-height: 85vh;
  }
  .alltag_sm {
    color: white;
    position: absolute;
    right: 21px;
    top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: calc(100vh - 220px);
  }
  .alltag_az_sm {
    color: rgb(214, 214, 236);
    opacity: 0.7;
    font-size: 16px;
    padding: 2px 0;
  }
  .alltag_az_sm.active {
    color: rgb(59, 150, 235);
    opacity: 1;
  }
  .alltag_az_sm.active p:hover,
  .alltag_az_sm.active p:focus {
    color: rgb(15, 139, 255);
    font-weight: bold;
    cursor: pointer;
  }
  .bottombar__subgeners {
    padding: 25px;
  }
  .player-active .tag-container {
    max-height: 85vh;
  }
}

#mainMega.mainMega-mobile{
 height: calc(100vh - 134px);
}

.megamenu {
  z-index: 10;
  color: white;
  width: 70.5%;
  /* overflow-y: scroll; */
}
.menu {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 18px 20px 18px 24px;
}
.menu:not(:first-child) {
  margin-top: 4px;
}
.description {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 5.5px 0;
}
.menu .title {
  height: 100%;
  display: flex;
  justify-content: left;
  text-decoration: none !important;
  align-items: center;
}
.title h3 {
  margin-bottom: 0 !important;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 0.25em;
  font-weight: 500;
  font-family: 'Poppins', 'Roboto', sans-serif;
  text-decoration: none !important;
  transition: 0.3s ease-in;
  letter-spacing: 0.25em;
}
.title h3 span:hover {
  color: #4f7be9;
  cursor: pointer;
}
.left-main-insider h6,
.right-main-insider h6 {
  padding-bottom: 0px;
  font-size: 12px;
  font-family: 'Poppins', 'Roboto', sans-serif;
  text-transform: uppercase;
  transition: 0.4s ease;
  font-style: normal;
  letter-spacing: 0.01em;
  font-weight: 500;
  margin-bottom: 0px !important;
}
.menu:first-child {
  background: linear-gradient(
      0deg,
      rgba(34, 35, 39, 0.95),
      rgba(34, 35, 39, 0.95)
    ),
    url(/app/static/media/DanceB.6079b680.png);
  transition: 0.3s ease-in-out;
}

.menu:last-child {
  background: linear-gradient(
      0deg,
      rgba(34, 35, 39, 0.95),
      rgba(34, 35, 39, 0.95)
    ),
    url(/app/static/media/bg.0b6096ec.png);
}
.menu:last-child .left-main-insider h6,
.menu:last-child .right-main-insider h6 {
  margin-bottom: 4px !important;
}
.menu:first-child .left-main-insider .left-insider:not(:first-child),
.menu:first-child .right-main-insider .right-insider:not(:first-child) {
  padding-top: 16px !important;
}
.left-main-insider h6 span:hover,
.right-main-insider h6 span:hover {
  color: #4f7be9;
  cursor: pointer;
}

.tag p {
  color: rgb(142, 142, 142);
  transition: 0.4s ease;
  font-weight: 400;
  font-size: 12px;
  font-family: 'Poppins', 'Roboto', sans-serif;
  margin: 0;
  padding: 4px 0;
}
.tag p span:hover {
  color: rgb(47, 137, 255);
}

@media screen and (max-width: 1559px) and (min-width: 1225px) {
  /* Player Avtive */
  .player-active .menu {
    padding: 10.9px 20px 10.9px 24px;
  }

  .player-active
    .menu:first-child
    .left-main-insider
    .left-insider:not(:first-child),
  .player-active
    .menu:first-child
    .right-main-insider
    .right-insider:not(:first-child) {
    padding-top: 10px !important;
  }
  .player-active .menu:nth-child(2) .right-main-insider {
    display: grid;
    grid-template-rows: 110px 105px 85px 95px;
  }
  .player-active .menu:nth-child(2) .left-main-insider {
    display: grid;
    grid-template-rows: 110px 105px 85px 95px;
  }
}
@media screen and (max-width: 1368px) and (min-width: 1225px) {
  .megamenu {
    width: 68.5%;
  }
  .menu {
    padding: 10px 20px 9px 24px;
  }
  .title h3 {
    font-size: 14px;
  }
  .left-main-insider h6,
  .right-main-insider h6 {
    font-size: 10px;
    font-weight: 500;
  }
  .tag p {
    font-size: 12px;
    font-weight: 400;
  }
  .menu:last-child .left-main-insider h6,
  .menu:last-child .right-main-insider h6 {
    margin-bottom: 4px !important;
  }
  .menu:first-child .left-main-insider .left-insider:not(:first-child),
  .menu:first-child .right-main-insider .right-insider:not(:first-child) {
    padding-top: 12px !important;
  }
  .menu:nth-child(2) .right-main-insider {
    display: grid;
    grid-template-rows: 105px 105px 80px 95px;
  }
  .menu:nth-child(2) .left-main-insider {
    display: grid;
    grid-template-rows: 105px 114px 70px 100px;
  }
  .menu:nth-child(2) .left-main-insider .left-insider:last-child {
    grid-row-start: 4;
    grid-row-end: 5;
  }

  /* Player Avtive */
  .player-active .menu {
    padding: 6px 20px 6px 24px;
  }

  .player-active
    .menu:first-child
    .left-main-insider
    .left-insider:not(:first-child),
  .player-active
    .menu:first-child
    .right-main-insider
    .right-insider:not(:first-child) {
    padding-top: 8px !important;
  }
  .player-active .tag p {
    padding: 2px 0;
  }
  .player-active .menu:nth-child(2) .right-main-insider {
    grid-template-rows: 90px 95px 75px 85px;
  }
  .player-active .menu:nth-child(2) .left-main-insider {
    grid-template-rows: 90px 95px 75px 85px;
  }
}
@media screen and (max-width: 1224px) and (min-width: 750px) {
  .megamenu {
    width: calc(64vw - 56px);
    height: auto;
    position: relative;
  }
  .menu {
    padding: 28px 20px 28px 15px;
    grid-template-columns: none;
  }
  .title h3 {
    font-size: 16px;
    font-family: 'Poppins', 'Roboto', sans-serif;
  }
  .right-insider,
  .left-insider {
    padding-top: 20px;
  }
  .left-main-insider h6,
  .right-main-insider h6 {
    padding-top: 6px;
    font-size: 12px;
  }
  .rightbarcomponent {
    padding: 22px 20px;
  }
}

/* Mobile screen */

@media screen and (max-width: 750px) {
  .megamenu {
    position: relative;
    width: 100vw;
    top: 0;
    height: auto;
    left: 0px;
  }
  .title h3 {
    font-size: 15px;
    letter-spacing: 0.1em;
  }
  .menu {
    grid-template-columns: none;
    margin-top: 0;
    padding: 0;
    display: block;
  }
  .sm-megamenu-heading {
    padding: 10px 0;
    padding-left: 10px;
    text-transform: uppercase;
    /* border-bottom: 3px solid #222327; */
  }
  .description {
    grid-template-columns: none;
    background: #1c1e22;
    display: none;
    padding: 10px;
  }
  .activeAcc .description {
    transition: 0.3s ease-in;
    display: block;
  }
  .menu.activeAcc {
    background: #1c1e22;
    width: 100vw;
    /* left: 10px; */
  }
  .left-main-insider h6,
  .right-main-insider h6 {
    font-size: 14px;
    margin-top: 24px;
    font-weight: 500;
    padding-left: 11px;
    line-height: 17.15px;
  }
  .menu:first-child .left-main-insider .left-insider:not(:first-child),
  .menu:first-child .right-main-insider .right-insider:not(:first-child) {
    padding-top: 0px !important;
  }
  .menu.activeAcc .title {
    width: 100vw;
  }
  #Dance {
    background: linear-gradient(
        0deg,
        rgba(34, 35, 39, 0.9),
        rgba(34, 35, 39, 0.9)
      ),
      url(/app/static/media/Dance.90d6aabd.png);
  }
  #House {
    background: linear-gradient(
        0deg,
        rgba(34, 35, 39, 0.9),
        rgba(34, 35, 39, 0.9)
      ),
      url(/app/static/media/House.c3524833.png);
  }
  .activeAcc #Dance {
    background: linear-gradient(rgba(0, 0, 0, 0.171), rgba(0, 0, 0, 0.192)),
      url(/app/static/media/Dance.90d6aabd.png);
    background-size: cover;
  }
  .activeAcc #House {
    background: linear-gradient(rgba(0, 0, 0, 0.192), rgba(0, 0, 0, 0.233)),
      url(/app/static/media/House.c3524833.png);
    background-size: cover;
  }
  .title {
    height: 64px;
    padding: 23px 18px;
  }
  .menu:not(:first-child) {
    margin-top: 0px;
  }
  .tag p {
    padding-left: 11px;
  }
  /* .left-insider,
  .right-insider {
    border-bottom: 3px solid #222327;
  } */
  .title h3 span:hover {
    color: white;
  }
}
@media screen and (min-width: 1366px) {
  .player-active .bottombar.submenu {
    height: 100% !important;
  }
}
@media screen and (min-width: 1367px) {
  .menu:nth-child(2) .right-main-insider {
    display: grid;
    grid-template-rows: 110px 115px 94px 114px;
  }
  .menu:nth-child(2) .left-main-insider {
    display: grid;
    grid-template-rows: 110px 115px 94px 114px;
  }
  .menu:nth-child(2) .left-main-insider .left-insider:last-child {
    grid-row-start: 4;
    grid-row-end: 5;
  }
  .menu:first-child:hover {
    background: linear-gradient(
        0deg,
        rgba(34, 35, 39, 0.91),
        rgba(34, 35, 39, 0.91)
      ),
      url(/app/static/media/DanceB.6079b680.png);
  }
  .menu:nth-child(2):hover {
    background: linear-gradient(
        0deg,
        rgba(34, 35, 39, 0.91),
        rgba(34, 35, 39, 0.91)
      ),
      url(/app/static/media/bg.0b6096ec.png);
  }
}
@media screen and (max-width: 2000px) and (min-width: 1560px) {
  .megamenu {
    width: 72.5%;
  }
  .menu {
    padding: 38px 20px 38px 136px;
    grid-template-columns: 0.7fr 2fr;
  }
  .title h3 {
    font-size: 18px;
  }
  .left-main-insider h6,
  .right-main-insider h6 {
    font-size: 14px;
    font-weight: 500;
  }
  .tag p {
    font-size: 14px;
    font-weight: 400;
  }
  .menu:last-child .left-main-insider h6,
  .menu:last-child .right-main-insider h6 {
    margin-bottom: 6px !important;
  }
  .menu:first-child .left-main-insider .left-insider:not(:first-child),
  .menu:first-child .right-main-insider .right-insider:not(:first-child) {
    padding-top: 18px !important;
  }
  .menu:nth-child(2) .right-main-insider {
    display: grid;
    grid-template-rows: 120px 120px 100px 110px;
  }
  .menu:nth-child(2) .left-main-insider {
    display: grid;
    grid-template-rows: 120px 120px 100px 110px;
  }
  .menu:nth-child(2) .left-main-insider .left-insider:last-child {
    grid-row-start: 4;
    grid-row-end: 5;
  }

  /* Player Active */

  .player-active .menu {
    padding: 27px 20px 26px 130px;
    grid-template-columns: 0.7fr 2fr;
  }
}

@media screen and (min-width: 2200px) {
  .megamenu {
    width: 60%;
  }
  .menu {
    grid-template-columns: 0.64fr 2fr;
  }
  .menu {
    padding: 38px 20px 38px 24px;
  }
  .title h3 {
    font-size: 20px;
    padding-left: 112px;
  }
  .left-main-insider h6,
  .right-main-insider h6 {
    font-size: 16px;
    font-weight: 500;
  }
  .tag p {
    font-size: 14px;
    font-weight: 400;
  }
  .menu:last-child .left-main-insider h6,
  .menu:last-child .right-main-insider h6 {
    margin-bottom: 8px !important;
  }
  .menu:first-child .left-main-insider .left-insider:not(:first-child),
  .menu:first-child .right-main-insider .right-insider:not(:first-child) {
    padding-top: 20px !important;
  }
  .menu:nth-child(2) .right-main-insider {
    display: grid;
    grid-template-rows: 140px 130px 120px 120px;
  }
  .menu:nth-child(2) .left-main-insider {
    display: grid;
    grid-template-rows: 140px 130px 120px 120px;
  }
  .player-active .menu {
    padding: 20px 20px 20px 24px;
  }
}

.megamenu .right-main-insider .right-insider h6 {
  /* border-left: 2px solid #4f7be9; */
}
.megamenu .right-main-insider .right-insider h6 span {
  margin-left: 3px;
}
.megamenu .right-main-insider .tag p span {
  margin-left: 4px;
}
.megamenu .left-main-insider .left-insider h6 {
  /* border-left: 2px solid #4f7be9; */
}
.megamenu .left-main-insider .left-insider h6 span {
  margin-left: 3px;
}
.megamenu .left-main-insider .tag p span {
  margin-left: 4px;
}

.genrecard {
  margin-top: 24px; }
  .genrecard .genrecard__cards {
    display: grid;
    grid-template-columns: repeat(12, minmax(auto, 240px));
    grid-gap: 16px; }
    @media (min-width: 1440px) {
      .genrecard .genrecard__cards {
        grid-template-columns: repeat(15, minmax(auto, 240px)); } }
    @media (min-width: 1920px) {
      .genrecard .genrecard__cards {
        grid-template-columns: repeat(18, minmax(auto, 240px)); } }
    .genrecard .genrecard__cards .genrecard__card {
      grid-column-end: span 3;
      position: relative;
      cursor: pointer; }
      @media (min-width: 1440px) {
        .genrecard .genrecard__cards .genrecard__card {
          grid-column-end: span 3; } }
      @media (max-width: 1224px) {
        .genrecard .genrecard__cards .genrecard__card {
          grid-column-end: span 6; } }
      @media (max-width: 750px) {
        .genrecard .genrecard__cards .genrecard__card {
          grid-column-end: span 12; } }
      .genrecard .genrecard__cards .genrecard__card .genrecard__img {
        width: 100%;
        padding-top: 40%;
        overflow: hidden;
        position: relative; }
        @media (max-width: 1224px) {
          .genrecard .genrecard__cards .genrecard__card .genrecard__img {
            padding-top: 23.5%; } }
        @media (max-width: 750px) {
          .genrecard .genrecard__cards .genrecard__card .genrecard__img {
            padding-top: 23.5%; } }
        .genrecard .genrecard__cards .genrecard__card .genrecard__img img {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
        .genrecard .genrecard__cards .genrecard__card .genrecard__img::after {
          content: '';
          background: #242529e3;
          z-index: 2;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%; }
      .genrecard .genrecard__cards .genrecard__card .genrecard__content span {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        z-index: 3;
        text-align: left;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 21px;
        color: #dddddd;
        padding-left: 24px; }
      .genrecard .genrecard__cards .genrecard__card:hover .genrecard__img::after {
        background: #242529c5; }

.genre_heading {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #dddddd;
  margin-bottom: 18px;
  margin-left: 20px; }
  .genre_heading .default {
    color: #ddd; }
  .genre_heading .title_heading {
    margin-left: -20px; }
  .genre_heading .active {
    color: #4f7be9 !important; }
  .genre_heading span {
    cursor: pointer;
    margin-right: 18px; }
  .genre_heading .alpha {
    color: #ddd; }

.mega_genres {
  padding: 10px;
  margin-bottom: 10px; }
  .mega_genres .genrecard {
    margin-bottom: 40px !important; }

.border_left {
  border-left: 2px solid #4f7be9; }

.megamenu_heading {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #dddddd;
  margin-bottom: 18px;
  margin-top: 14px;
  position: absolute;
  z-index: 999;
  top: 0;
  margin-left: 18px; }
  .megamenu_heading .default {
    margin-right: 18px;
    color: #4f7be9; }
  .megamenu_heading span {
    cursor: pointer; }

.rightbarcomponent {
  padding: 8.8px 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-size: cover !important;
  background-position: center;
}
.rightbarcomponent:not(:last-child) {
  margin-bottom: 4px;
}
@media screen and (max-width: 1559px) and (min-width: 1225px) {
  /* Player Active */
  .player-active .rightbarcomponent {
    padding: 4px 12px;
  }
  .player-active
    .rightbarcomponent:first-child
    .rightbarcomponent__subtitle
    p:first-child {
    padding-top: 9.5px;
  }
  .player-active
    .rightbarcomponent:first-child
    .rightbarcomponent__subtitle
    p:last-child {
    padding-bottom: 9.5px;
  }
  .player-active .rightbarcomponent .rightbarcomponent__subtitle p {
    padding: 6.1px 0;
  }
}
@media screen and (min-width: 1224px) {
  .rightbarcomponent:first-child {
    grid-template-columns: 1fr 1fr;
  }
  .rightbarcomponent:nth-child(2) {
    grid-template-columns: 1fr 1fr;
  }
  .rightbarcomponent:first-child .rightbarcomponent__subtitle p:first-child {
    padding-top: 20px;
  }
  .rightbarcomponent:first-child .rightbarcomponent__subtitle p:last-child {
    padding-bottom: 20px;
  }
  .rightbarcomponent:nth-child(2) .rightbarcomponent__subtitle p:first-child {
    padding-top: 10px;
  }
  .rightbarcomponent:nth-child(2) .rightbarcomponent__subtitle p:first-child {
    padding-bottom: 9px;
  }
}

.rightbarcomponent .rightbarcomponent__title {
  display: flex;
  align-items: center;
  padding-left: 12px;
  color: #ddd;
  align-items: center;
  font-size: 15px;
  letter-spacing: 1.3px;
  text-transform: uppercase;
}
.rightbarcomponent .rightbarcomponent__title h3 {
  padding: 17px 0 17px;
  font-size: 16px;
  letter-spacing: 0.14em;
  font-weight: 500;
  white-space: nowrap;
  font-family: 'Poppins', 'Roboto', sans-serif;
  margin-bottom: 0 !important;
  line-height: 0;
  font-style: normal;
  text-transform: uppercase;
}
.rightbarcomponent .rightbarcomponent__title h3:hover {
  color: #4f7be9;
  cursor: pointer;
}

.rightbarcomponent .rightbarcomponent__subtitle {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.rightbarcomponent .rightbarcomponent__subtitle p {
  padding: 7px 0;
  font-style: normal;
  font-size: 12px;
  font-family: 'Poppins', 'Roboto', sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: #ddd;
  margin-bottom: 0 !important;
  transition: 0.3s ease-in;
}
.rightbarcomponent .rightbarcomponent__subtitle p span:hover {
  color: #4f7be9;
  cursor: pointer;
}
@media screen and (max-width: 1368px) and (min-width: 1225px) {
  .rightbarcomponent .rightbarcomponent__title h3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.1em;
  }
  .rightbarcomponent .rightbarcomponent__subtitle p {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
  }
  .rightbarcomponent {
    padding: 4.93px 12px;
  }
  .rightbarcomponent:first-child .rightbarcomponent__subtitle p:first-child {
    padding-top: 20px;
  }
  .rightbarcomponent:first-child .rightbarcomponent__subtitle p:last-child {
    padding-bottom: 13px;
  }
  .rightbarcomponent:nth-child(2) .rightbarcomponent__subtitle p:first-child {
    padding-top: 6px;
  }
  .rightbarcomponent:nth-child(2) .rightbarcomponent__subtitle p:first-child {
    padding-bottom: 6px;
  }

  /* Player Active */
  .player-active .rightbarcomponent {
    padding: 3px 12px;
  }
  .player-active .rightbarcomponent .rightbarcomponent__subtitle p {
    padding: 5.2px 0;
  }
  .player-active
    .rightbarcomponent:first-child
    .rightbarcomponent__subtitle
    p:first-child {
    padding-top: 10px;
  }
  .player-active
    .rightbarcomponent:first-child
    .rightbarcomponent__subtitle
    p:last-child {
    padding-bottom: 6px;
  }
}
@media screen and (max-width: 2200px) and (min-width: 1560px) {
  .rightbarcomponent {
    padding: 14.4px 12px;
    padding-left: 36px;
  }
  .player-active .rightbarcomponent {
    padding: 11px 12px;
    padding-left: 36px;
  }
  .rightbarcomponent .rightbarcomponent__subtitle p {
    padding: 8px 0 !important;
    font-size: 14px !important;
  }
}
@media screen and (min-width: 1368px) {
  .rightbarcomponent .rightbarcomponent__subtitle p {
    padding: 5.2px 0;
  }
  .menu:not(:first-child) {
    margin-top: 8px;
  }
  .rightbar {
    padding-left: 8px !important;
  }
  .rightbarcomponent:not(:last-child) {
    margin-bottom: 8px;
  }

  .bottombar {
    margin-top: 8px;
  }
  #rightbarUrban:hover {
    background: linear-gradient(
        0deg,
        rgba(34, 35, 39, 0.91),
        rgba(34, 35, 39, 0.91)
      ),
      url(/app/static/media/Urban.93ff78d5.png) !important;
    background-size: cover !important;
  }
  #rightbarLatin:hover {
    background: linear-gradient(
        0deg,
        rgba(34, 35, 39, 0.91),
        rgba(34, 35, 39, 0.91)
      ),
      url(/app/static/media/Latin.b566a180.png) !important;
    background-size: cover !important;
  }
  #rightbarClassics:hover {
    background: linear-gradient(
        0deg,
        rgba(34, 35, 39, 0.91),
        rgba(34, 35, 39, 0.91)
      ),
      url(/app/static/media/Classics.13b3bc4f.png) !important;
    background-size: cover !important;
  }
  #rightbarCountry:hover {
    background: linear-gradient(
        0deg,
        rgba(34, 35, 39, 0.91),
        rgba(34, 35, 39, 0.91)
      ),
      url(/app/static/media/Country.ca5a6287.png) !important;
    background-size: cover !important;
  }
  .rightbarcomponent:last-child:hover {
    background: linear-gradient(
        0deg,
        rgba(34, 35, 39, 0.91),
        rgba(34, 35, 39, 0.91)
      ),
      url(/app/static/media/RockAlternative.1336fde1.png) !important;
    background-size: cover !important;
  }
  #rightbarMiscellaneous:hover {
    background: linear-gradient(
        0deg,
        rgba(34, 35, 39, 0.91),
        rgba(34, 35, 39, 0.91)
      ),
      url(/app/static/media/Miscellaneous.e8a4688c.png) !important;
    background-size: cover !important;
  }
  #rightbarPop:hover {
    background: linear-gradient(
        0deg,
        rgba(34, 35, 39, 0.91),
        rgba(34, 35, 39, 0.91)
      ),
      url(/app/static/media/Pop.e42b6c3c.png) !important;
    background-size: cover !important;
  }
}
.rightbarcomponent__subtitle p span {
  /* border-left: 2px solid #4f7be9; */
  padding-left: 4px;
}

.rightbar {
  width: 29.5%;
  /* margin-right: 6px; */
  padding-left: 4px;
  z-index: 3;
  opacity: 1;
}
@media screen and (max-width: 1368px) and (min-width: 1225px) {
  .rightbar {
    width: 33%;
  }
}
@media screen and (max-width: 1224px) and (min-width: 750px) {
  .rightbar {
    margin-right: 0;
    margin-top: 6px;
    left: 0px;
    top: auto;
    padding-left: 0 !important;
    height: auto;
    width: calc(64vw - 56px);
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto 35px 35px 35px 35px 35px 35px;
    grid-auto-rows: auto;
    grid-column-gap: 6px;
  }

  .rightbarcomponent:not(:last-child) {
    margin-bottom: 6px;
  }
  .rightbarcomponent .rightbarcomponent__subtitle {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .rightbarcomponent {
    grid-template-columns: auto;
  }
  .rightbarcomponent:nth-child(1) {
    grid-column: span 2;
  }
  .rightbarcomponent:nth-child(2) {
    grid-column: span 2;
  }
  .rightbarcomponent:nth-child(3) {
    grid-row: 3 /6;
    align-content: center;
  }
  .rightbarcomponent:nth-child(4) {
    align-content: center;
    grid-row: 3 /5;
  }
  .rightbarcomponent:nth-child(5) {
    padding-top: 10px;
    grid-row: 6 /9;
    align-content: center;
    margin-bottom: 0px !important;
  }
  .rightbarcomponent:nth-child(6) {
    align-content: center;
    grid-row: 5 /7;
  }
  .rightbarcomponent:nth-child(7) {
    align-content: center;
    grid-row: 7 /9;
  }

  .rightbarcomponent .rightbarcomponent__title {
    padding-left: 0;
    margin: auto 0;
  }
}

@media screen and (max-width: 750px) {
  .rightbar {
    position: relative;
    width: 100vw;
    top: 0;
    padding-left: 0;
    height: auto;
    z-index: 10;
  }
  .rightbarcomponent {
    padding: 12px 15px;
  }
  #rightbarLatin {
    background: linear-gradient(
        0deg,
        rgba(34, 35, 39, 0.95),
        rgba(34, 35, 39, 0.95)
      ),
      url(/app/static/media/Latin.b566a180.png);
  }
  #rightbarUrban {
    background: linear-gradient(
        0deg,
        rgba(34, 35, 39, 0.95),
        rgba(34, 35, 39, 0.95)
      ),
      url(/app/static/media/Urban.93ff78d5.png);
  }
  .rightbarcomponent.activeAcc {
    display: flex;
    flex-direction: column;
    background: #1c1e22 !important;
    z-index: 10;
    width: 100vw;
  }
  .rightbarcomponent:not(:last-child) {
    margin-bottom: 0;
  }
  .rightbarcomponent .rightbarcomponent__subtitle {
    display: none;
    padding-left: 4px;
  }
  .rightbarcomponent.activeAcc .rightbarcomponent__subtitle {
    display: block;
    /* background: ; */
  }
  .rightbarcomponent.activeAcc .rightbarcomponent__subtitle p {
    padding-top: 15px;
  }
  .rightbarcomponent .rightbarcomponent__title {
    padding-left: 4px;
    display: block;
  }
  .rightbarcomponent.activeAcc .rightbarcomponent__title {
    background: linear-gradient(
        0deg,
        rgba(34, 35, 39, 0.095),
        rgba(34, 35, 39, 0.095)
      ),
      url('/app/static/media/Urban.93ff78d5.png');
    background-position: center;
    background-size: cover;
  }
  .sm-title {
    padding-top: 15px;
    font-weight: 500;
    text-transform: uppercase;
  }
  .rightbarcomponent .rightbarcomponent__title h3:hover {
    color: white;
  }
  .rightbarcomponent .rightbarcomponent__title h3 {
    font-size: 15px;
    letter-spacing: 0.1em;
  }
}
@media screen and (max-width: 2200px) and (min-width: 1560px) {
  .rightbar {
    width: 37%;
  }
}
@media screen and (min-width: 2200px) {
  .rightbar {
    margin-top: 4px;
    width: 60%;
    display: grid;
    grid-template-columns: 1fr 0.65fr;
    grid-template-rows: 49px 49px 49px 49px 49px 49px 49px 49px 49px 49px;
    grid-row-gap: 6px;
    grid-column-gap: 10px;
  }
  .rightbarcomponent:not(:last-child) {
    margin-bottom: 0px !important;
  }
  .rightbarcomponent:nth-child(1) {
    grid-row: 1 / 8;
    padding-left: 134px;
    grid-template-columns: 0.48fr 1fr;
  }
  .rightbarcomponent:nth-child(2) {
    grid-row: 8 / 11;
    padding-left: 134px;
    grid-template-columns: 0.48fr 1fr;
  }
  .rightbarcomponent:nth-child(3) {
    grid-row: 1 / 3;
  }
  .rightbarcomponent:nth-child(4) {
    grid-row: 3 / 5;
  }
  .rightbarcomponent:nth-child(5) {
    grid-row: 5 / 7;
  }
  .rightbarcomponent:nth-child(6) {
    grid-row: 7 / 9;
  }
  .rightbarcomponent:nth-child(7) {
    grid-row: 9 / 11;
  }
  .rightbarcomponent .rightbarcomponent__subtitle p {
    font-size: 16px;
  }
}

@font-face {
  font-family: 'Gilroy';
  src: url(/app/static/media/Gilroy-SemiBold.05bdf30b.ttf);
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url(/app/static/media/Gilroy-Bold.3cf0ee27.ttf);
  font-weight: bold;
  font-style: normal;
}
.var-container {
  width: calc(100vw - 66px);
  background: #1c1e22, 100%;
  z-index: 1;
}
.main {
  background: rgb(28 29 32);
  -webkit-transform: none !important;
          transform: none !important;
  will-change: unset !important;
  font-family: 'Poppins', 'Roboto', sans-serif;
  /* padding-left: 2px; */
}
::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.var-container__top {
  /* padding-left: 4px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 1224px) and (min-width: 750px) {
  .var-container {
    position: fixed;
    overflow: hidden;
    overflow-y: scroll;
    width: calc(100vw - 56px);
    padding-left: 8px;
  }
  .var-container::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  .var-container__top {
    height: calc(100vh - 64px);
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: calc(100vw - 56px);
  }
  .main {
    top: 0;
    left: 0;
    width: 90vw;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .var-container-tablet {
    height: 87.4vh !important;
  }

  .alltag_sm-tablet {
    height: 80vh !important;
  }

  .tag-container-tablet {
    height: 80vh !important;
    overflow-x: hidden !important;
  }
}

@media screen and (max-width: 750px) {
  .var-container {
    position: relative;
    width: 100vw;
    left: 0;
    background: #1c1e22;
  }
  .var-container__top {
    padding-left: 0;
    flex-direction: column;
  }
  .main {
    position: fixed;
    width: 100vw;
    height: calc(100vh - 48px);
    top: 48px;
    left: 0px;
    overflow-y: scroll;
    padding-left: 0 !important;
  }
  .main::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  .zd-primary-dropdown {
    margin-left: 25px;
  }
}

body.body-overflow-hidden {
  overflow: hidden !important;
}
body.body-overflow-hidden .cr-header {
  padding-right: 30px !important;
}
@media screen and (max-width: 750px) {
  body.body-overflow-hidden .cr-header {
    padding-right: 0px !important;
  }
}
.zdv3-icon-close {
  font-size: 16px;
  color: #8e8e8e;
  padding: 3px;
}
@media screen and (min-width: 2200px) {
  .var-container__top {
    display: block;
  }
}

.genre_cont {
  display: flex;
  height: 100vh;
  /* Adjusts container height to the full screen height */
  max-width: 100% !important;
  background-color: #1f2126;
  padding-bottom: 8vh; }
  .genre_cont .favourite-search-bar-form .favourite-search-label .favourite-search-box {
    width: 192px;
    height: 29px;
    position: absolute;
    color: #ddd;
    background-color: #242529;
    border: none;
    padding-bottom: 2px; }
  .genre_cont .favourite-search-bar-form .favourite-search-label {
    height: 0px;
    background-color: #242529; }
  .genre_cont .favourite-search-bar-form {
    width: 252px;
    height: 32px;
    margin-bottom: 12px !important; }

.left-column {
  flex-basis: 20%;
  border-right: 1px solid #32333a;
  padding: 15px; }

.right-column {
  flex-basis: 80%;
  padding: 15px; }

.zd-v4-genere-search {
  border: 1px solid #32333a; }

.zd-main-genre {
  border-bottom: 1px solid #32333a;
  padding: 10px 10px 10px 0px;
  color: #dddddd; }
  .zd-main-genre span {
    font-size: 13px;
    line-height: 21px;
    font-weight: 500;
    cursor: pointer; }
    .zd-main-genre span:hover {
      color: #4f7be9; }

.genre_cont .zd-v4-genere-search {
  background-color: #2e2f34 !important; }

.genre_cont .favourite-search-box {
  background-color: #2e2f34 !important; }

.zd-cat {
  border-bottom: 1px solid #32333a;
  font-size: 13px;
  padding: 2px 0px 10px 0px;
  color: #4978ef; }

.zd-bot-card {
  margin-top: 25px;
  border-radius: 5px;
  padding: 10px;
  background-color: #32333a; }
  .zd-bot-card h5 {
    font-size: 16px;
    color: #dddd; }
  .zd-bot-card p {
    font-size: 12px;
    margin-top: 10px;
    color: #ddd; }
    .zd-bot-card p span {
      color: #4978ef; }
  .zd-bot-card a {
    color: #4978ef !important; }

.genre_cont {
  height: auto; }

.containes {
  display: flex;
  flex-wrap: wrap; }
  .containes span {
    cursor: pointer; }
    .containes span:hover {
      color: #4f7be9; }

.column {
  margin-right: 25px;
  color: #ddd;
  flex-basis: 28%;
  box-sizing: border-box;
  padding: 12px 10px 12px 0px;
  border-bottom: 1px solid #32333a; }

@media (min-width: 1440px) {
  .column {
    margin-right: 20px;
    color: #ddd;
    flex-basis: 23%;
    box-sizing: border-box;
    padding: 12px 10px 12px 0px;
    border-bottom: 1px solid #32333a; } }

@media (max-width: 1440px) and (min-width: 1240px) {
  .column {
    margin-right: 20px;
    color: #ddd;
    flex-basis: 28%;
    box-sizing: border-box;
    padding: 12px 10px 12px 0px;
    border-bottom: 1px solid #32333a; } }

@media (max-width: 1240px) {
  .column {
    margin-right: 20px;
    color: #ddd;
    flex-basis: 29%;
    box-sizing: border-box;
    padding: 12px 10px 12px 0px;
    border-bottom: 1px solid #32333a; } }

@media (max-width: 750px) {
  .left-column {
    border-right: none; }
  .right-column div p {
    display: block;
    border-bottom: 1px solid #32333a;
    margin-bottom: 0;
    padding-bottom: 10px;
    color: #4978ef; }
  .genre_cont {
    padding-bottom: 312vh;
    display: block !important; }
    .genre_cont .zd-v4-genere-search {
      display: block !important;
      margin-left: 0; }
    .genre_cont .column {
      margin-right: 20px;
      color: #ddd;
      flex-basis: 100%;
      box-sizing: border-box;
      padding: 12px 10px 12px 0px;
      border-bottom: 1px solid #32333a; } }

.react-jinke-music-player-main ul,
.react-jinke-music-player-main li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.react-jinke-music-player-main * {
  box-sizing: border-box;
}
.react-jinke-music-player-main .text-center {
  text-align: center;
}
.react-jinke-music-player-main .hidden {
  display: none !important;
}
.react-jinke-music-player-main .loading {
  display: inline-flex;
  -webkit-animation: audioLoading 1s linear infinite;
          animation: audioLoading 1s linear infinite;
}
.react-jinke-music-player-main .loading svg {
  font-size: 24px;
  color: #fff;
}
.react-jinke-music-player-main .translate {
  -webkit-animation: translate 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
          animation: translate 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
.react-jinke-music-player-main .scale {
  -webkit-animation: scaleTo 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
          animation: scaleTo 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
@-webkit-keyframes playing {
  to {
    -webkit-transform: rotateX(360deg);
            transform: rotateX(360deg);
  }
}
@keyframes playing {
  to {
    -webkit-transform: rotateX(360deg);
            transform: rotateX(360deg);
  }
}
@-webkit-keyframes audioLoading {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes audioLoading {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes scale {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    opacity: 0.6;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(2);
            transform: scale(2);
  }
}
@keyframes scale {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    opacity: 0.6;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(2);
            transform: scale(2);
  }
}
@-webkit-keyframes scaleTo {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scaleTo {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes scaleFrom {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }
}
@keyframes scaleFrom {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }
}
@-webkit-keyframes imgRotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes imgRotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes fromTo {
  from {
    -webkit-transform: scale(1) translate3d(0, 110%, 0);
            transform: scale(1) translate3d(0, 110%, 0);
  }
  to {
    -webkit-transform: scale(1) translate3d(0, 0, 0);
            transform: scale(1) translate3d(0, 0, 0);
  }
}
@keyframes fromTo {
  from {
    -webkit-transform: scale(1) translate3d(0, 110%, 0);
            transform: scale(1) translate3d(0, 110%, 0);
  }
  to {
    -webkit-transform: scale(1) translate3d(0, 0, 0);
            transform: scale(1) translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fromOut {
  from {
    -webkit-transform: scale(1) translate3d(0, 0, 0);
            transform: scale(1) translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: scale(1) translate3d(0, 110%, 0);
            transform: scale(1) translate3d(0, 110%, 0);
  }
}
@keyframes fromOut {
  from {
    -webkit-transform: scale(1) translate3d(0, 0, 0);
            transform: scale(1) translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: scale(1) translate3d(0, 110%, 0);
            transform: scale(1) translate3d(0, 110%, 0);
  }
}
@-webkit-keyframes fromDown {
  from {
    -webkit-transform: scale(1) translate3d(0, -110%, 0);
            transform: scale(1) translate3d(0, -110%, 0);
  }
  to {
    -webkit-transform: scale(1) translate3d(0, 0, 0);
            transform: scale(1) translate3d(0, 0, 0);
  }
}
@keyframes fromDown {
  from {
    -webkit-transform: scale(1) translate3d(0, -110%, 0);
            transform: scale(1) translate3d(0, -110%, 0);
  }
  to {
    -webkit-transform: scale(1) translate3d(0, 0, 0);
            transform: scale(1) translate3d(0, 0, 0);
  }
}
@-webkit-keyframes translate {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes translate {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes remove {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }
}
@keyframes remove {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }
}
.react-jinke-music-player-main .img-rotate-pause {
  -webkit-animation-play-state: paused !important;
          animation-play-state: paused !important;
}
.react-jinke-music-player-mobile {
  width: 100%;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #131213;
}
.react-jinke-music-player-mobile > .group {
  flex: 1 1 auto;
}
.react-jinke-music-player-mobile .show {
  -webkit-animation: mobile-bg-show 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
          animation: mobile-bg-show 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
.react-jinke-music-player-mobile .hide {
  -webkit-animation: mobile-bg-hide 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
          animation: mobile-bg-hide 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
.react-jinke-music-player-mobile-play-model-tip {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 35px;
  line-height: 35px;
  background-color: #3b49af;
  color: #fff;
  padding: 0 20px;
  z-index: 1000;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(0, -105%, 0);
          transform: translate3d(0, -105%, 0);
  transition: -webkit-transform 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
  transition: transform 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
  transition: transform 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1), -webkit-transform 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-mobile-play-model-tip .title {
  margin-right: 12px;
}
.react-jinke-music-player-mobile-play-model-tip .title svg {
  -webkit-animation: none !important;
          animation: none !important;
  vertical-align: text-bottom !important;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player-mobile-play-model-tip .title svg {
    font-size: 19px;
    color: #fff !important;
  }
}
.react-jinke-music-player-mobile-play-model-tip .text {
  font-size: 14px;
}
.react-jinke-music-player-mobile-play-model-tip.show {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.react-jinke-music-player-mobile-header {
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  -webkit-animation: fromDown 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
          animation: fromDown 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
.react-jinke-music-player-mobile-header .title {
  flex-basis: 80%;
  text-align: center;
  font-size: 20px;
  transition: color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.react-jinke-music-player-mobile-header .item {
  display: inline-flex;
  width: 50px;
}
.react-jinke-music-player-mobile-header .right {
  justify-content: flex-end;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
}
.react-jinke-music-player-mobile-header .right svg {
  font-size: 25px;
}
.react-jinke-music-player-mobile-singer {
  -webkit-animation: fromDown 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
          animation: fromDown 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
  padding: 12px 0;
}
@media screen and (max-width: 320px) {
  .react-jinke-music-player-mobile-singer {
    padding: 0;
  }
}
.react-jinke-music-player-mobile-singer .name {
  position: relative;
  font-size: 14px;
  transition: color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-mobile-singer .name:before,
.react-jinke-music-player-mobile-singer .name:after {
  content: '';
  position: absolute;
  width: 16px;
  height: 1px;
  top: 9px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.6);
  transition: background-color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-mobile-singer .name:after {
  left: -25px;
}
.react-jinke-music-player-mobile-singer .name:before {
  right: -25px;
}
.react-jinke-music-player-mobile-cover {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 5px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1px 3px rgba(0, 0, 0, 0.1);
  margin: 15px auto;
  -webkit-animation: fromTo 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
          animation: fromTo 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
  overflow: hidden;
  transition: box-shadow, border 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
@media screen and (max-width: 320px) {
  .react-jinke-music-player-mobile-cover {
    width: 230px;
    height: 230px;
    margin: 10px auto;
  }
}
.react-jinke-music-player-mobile-cover .cover {
  width: 100%;
  -webkit-animation: imgRotate 30s linear infinite;
          animation: imgRotate 30s linear infinite;
}
.react-jinke-music-player-mobile-progress {
  justify-content: space-around;
  display: flex;
  align-items: center;
}
.react-jinke-music-player-mobile-progress .current-time,
.react-jinke-music-player-mobile-progress .duration {
  display: inline-flex;
  width: 55px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  transition: color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-mobile-progress .duration {
  justify-content: flex-end;
}
.react-jinke-music-player-mobile-progress .progress-bar {
  flex: 1 1 auto;
}
.react-jinke-music-player-mobile-progress .rc-slider-rail {
  background-color: rgba(255, 255, 255, 0.6);
}
.react-jinke-music-player-mobile-switch {
  -webkit-animation: fromDown 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
          animation: fromDown 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
.react-jinke-music-player-mobile-toggle {
  padding: 17px 0;
}
.react-jinke-music-player-mobile-toggle .group {
  cursor: pointer;
}
.react-jinke-music-player-mobile-toggle .group svg {
  font-size: 40px;
}
.react-jinke-music-player-mobile-toggle .play-btn {
  padding: 0 40px;
}
.react-jinke-music-player-mobile-toggle .play-btn svg {
  font-size: 60px;
}
@media screen and (max-width: 320px) {
  .react-jinke-music-player-mobile-toggle {
    padding: 10px 0;
  }
  .react-jinke-music-player-mobile-toggle > .group svg {
    font-size: 32px;
  }
  .react-jinke-music-player-mobile-toggle .play-btn svg {
    font-size: 50px;
  }
}
.react-jinke-music-player-mobile-toggle,
.react-jinke-music-player-mobile-progress {
  -webkit-animation: fromTo 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
          animation: fromTo 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
.react-jinke-music-player-mobile-operation {
  -webkit-animation: fromTo 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
          animation: fromTo 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
.react-jinke-music-player-mobile-operation .items {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.react-jinke-music-player-mobile-operation .items .item {
  cursor: pointer;
  flex: 1 1;
  text-align: center;
}
.react-jinke-music-player-mobile-operation .items .item svg {
  color: rgba(255, 255, 255, 0.6);
  font-size: 25px;
}
@media screen and (max-width: 320px) {
  .react-jinke-music-player-mobile-operation .items .item svg {
    font-size: 20px;
  }
}
@-webkit-keyframes mobile-bg-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes mobile-bg-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes mobile-bg-hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes mobile-bg-hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.audio-lists-panel-drag-line {
  border: 1px solid #3b49af !important;
}
.audio-lists-panel {
  display: block;
  overflow: hidden;
  position: fixed;
  right: 33px;
  bottom: 56px;
  z-index: 999;
  width: 480px;
  height: 410px;
  color: rgba(255, 255, 255, 0.8);
  -webkit-transform-origin: right bottom;
          transform-origin: right bottom;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transform: scale(0);
          transform: scale(0);
  display: none\9;
  transition: background-color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.audio-lists-panel svg {
  font-size: 24px;
}
.audio-lists-panel.show {
  -webkit-animation: scaleTo 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
          animation: scaleTo 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
  display: block\9;
}
.audio-lists-panel.hide {
  -webkit-animation: scaleFrom 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
          animation: scaleFrom 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
  display: none\9;
}
@media screen and (max-width: 767px) {
  .audio-lists-panel {
    width: 100% !important;
    top: 64px;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto !important;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    z-index: 98;
    background: #1b1b1e;
  }
  body .hot-keys-panel {
    background: #1b1b1e;
  }
  .audio-lists-panel.show {
    -webkit-animation: fromTo 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
            animation: fromTo 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
    display: block\9;
  }
  .audio-lists-panel.hide {
    -webkit-animation: fromOut 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
            animation: fromOut 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
    display: none\9;
  }
}
@media screen and (max-width: 767px) {
  .audio-lists-panel .audio-item {
    background-color: rgba(64, 68, 75, 0.5) !important;
  }
  .audio-lists-panel .audio-item.playing {
    background-color: rgba(64, 68, 75, 0.75) !important;
  }
}
.audio-lists-panel-header {
  border-bottom: 1px solid rgba(3, 3, 3, 0.7);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  transition: background-color,
    border-bottom 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.audio-lists-panel-header .close-btn,
.audio-lists-panel-header .delete-btn {
  float: right;
  cursor: pointer;
}
.audio-lists-panel-header .delete-btn svg {
  font-size: 21px;
}
@media screen and (max-width: 768px) {
  .audio-lists-panel-header .delete-btn svg {
    font-size: 19px;
  }
}
.audio-lists-panel-header .close-btn:hover svg {
  -webkit-animation: imgRotate 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
          animation: imgRotate 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.audio-lists-panel-header .line {
  width: 1px;
  height: inherit;
  background: #fff;
  float: right;
  height: 20px;
  margin: 18px 10px 0 10px;
}
.audio-lists-panel-header .title {
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  font-size: 16px;
  margin: 0;
  text-align: left;
  font-weight: 500;
  transition: color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.audio-lists-panel-content {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 60px);
}
@media screen and (max-width: 767px) {
  .audio-lists-panel-content {
    width: 100% !important;
    height: calc(100% - 60) !important;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
  }
}
.audio-lists-panel-content.no-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.audio-lists-panel-content .no-data {
  margin-left: 10px;
}
.audio-lists-panel-content .audio-item {
  background-color: #40444b;
  border-bottom: #40444b;
  padding: 3px 20px;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.audio-lists-panel-content .audio-item.playing {
  background-color: #454951;
}
.audio-lists-panel-content .audio-item.playing svg {
  color: #fff;
}
.audio-lists-panel-content .audio-item.remove {
  -webkit-animation: remove 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
          animation: remove 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
.audio-lists-panel-content .audio-item .player-icons {
  display: inline-flex;
  width: 30px;
}
.audio-lists-panel-content .audio-item .player-icons .loading {
  -webkit-animation: audioLoading 0.9s linear infinite;
          animation: audioLoading 0.9s linear infinite;
}
.audio-lists-panel-content .audio-item .player-delete:hover svg {
  -webkit-animation: imgRotate 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
          animation: imgRotate 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.audio-lists-panel-content .audio-item:hover,
.audio-lists-panel-content .audio-item:active {
  background-color: #454951;
}
.audio-lists-panel-content
  .audio-item:hover
  .group:not([class='.player-delete'])
  svg,
.audio-lists-panel-content
  .audio-item:active
  .group:not([class='.player-delete'])
  svg {
  color: #3b49af;
}
.audio-lists-panel-content .audio-item .group {
  display: inline-flex;
}
.audio-lists-panel-content .audio-item .player-name {
  flex: 1 1 auto;
  padding: 0 20px 0 10px;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.audio-lists-panel-content .audio-item .player-time {
  font-size: 12px;
  color: rgba(217, 217, 217, 0.45);
  transition: color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.audio-lists-panel-content .audio-item .player-delete {
  padding-left: 2%;
}
.react-jinke-music-player-main {
  touch-action: none;
  font-family: inherit;
}
.react-jinke-music-player-main ::-webkit-scrollbar-thumb {
  height: 20px;
  background-color: #fff;
  opacity: 0.5;
}
.react-jinke-music-player-main ::-webkit-scrollbar {
  width: 10px;
  background-color: #f7f8fa;
}
.react-jinke-music-player-main .lyric-btn-active {
  color: #3b49af;
}
.react-jinke-music-player-main .music-player-lyric {
  position: fixed;
  color: #3b49af;
  font-size: 36px;
  cursor: move;
  z-index: 999;
  bottom: 100px;
  padding: 20px;
  width: 100%;
  left: 0;
  text-align: center;
  transition: box-shadow 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
  background: transparent;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.05);
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player-main .music-player-lyric {
    font-size: 16px;
  }
  .react-jinke-music-player-main .music-player-lyric:hover {
    box-shadow: 2px 2px 20px #d9d9d9;
  }
}
.react-jinke-music-player-main .play-mode-title {
  display: none;
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  right: 72px;
  bottom: 80px;
  padding: 5px 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  line-height: 1.5;
  text-align: center;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
  -webkit-transform-origin: bottom center;
          transform-origin: bottom center;
  z-index: 88;
  opacity: 0;
  transition: all 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main .play-mode-title.play-mode-title-visible {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.react-jinke-music-player-main .glass-bg-container {
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 300%;
  height: 300%;
  left: 0;
  top: 0;
  -webkit-filter: blur(80px);
          filter: blur(80px);
  z-index: -1;
}
.react-jinke-music-player-main .glass-bg {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.react-jinke-music-player-main svg {
  font-size: 24px;
  transition: color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main svg:hover,
.react-jinke-music-player-main svg:active {
  color: #3b49af;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player-main svg {
    font-size: 22px;
  }
}
.react-jinke-music-player-main .react-jinke-music-player-play-icon,
.react-jinke-music-player-main .react-jinke-music-player-pause-icon {
  -webkit-animation: imgRotate 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
          animation: imgRotate 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main .react-jinke-music-player-mode-icon {
  -webkit-animation: scaleTo 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
          animation: scaleTo 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main .music-player-panel {
  box-shadow: 0 0 3px #403f3f;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  color: #fff;
  height: 80px;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  transition: background-color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main .music-player-panel .panel-content {
  height: 100%;
  padding: 0 30px;
  position: relative;
  /* overflow: hidden; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-jinke-music-player-main .music-player-panel .panel-content .img-content {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  background-size: 100%;
  background-repeat: no-repeat;
  box-shadow: 0 0 10px rgba(0, 34, 77, 0.05);
}
@media screen and (max-width: 768px) {
  .react-jinke-music-player-main
    .music-player-panel
    .panel-content
    .img-content {
    width: 40px;
    height: 40px;
  }
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .img-content
  img {
  width: 100%;
}
.react-jinke-music-player-main .music-player-panel .panel-content .img-rotate {
  -webkit-animation: imgRotate 15s linear infinite;
          animation: imgRotate 15s linear infinite;
}
.react-jinke-music-player-main .music-player-panel .panel-content .hide-panel,
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .upload-music {
  flex-basis: 10%;
  cursor: pointer;
  margin-left: 15px;
}
@media screen and (max-width: 768px) {
  .react-jinke-music-player-main
    .music-player-panel
    .panel-content
    .progress-bar-content {
    /* display: none !important; */
  }
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content {
  flex: 1 1;
  padding: 0 20px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .audio-title {
  transition: color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  max-width: 400px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .audio-main {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  margin-top: 6px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .audio-main
  .current-time,
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .audio-main
  .duration {
  font-size: 12px;
  flex-basis: 5%;
  transition: color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .progress-bar {
  flex: 1 1 auto;
  width: 100%;
  margin: 2px 20px 0 5px;
  position: relative;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .progress-bar
  .progress {
  position: absolute;
  display: inline-block;
  height: 5px;
  left: 0;
  top: 0;
  transition: width 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 31%,
    rgba(0, 0, 0, 0.05) 33%,
    rgba(0, 0, 0, 0.05) 67%,
    rgba(0, 0, 0, 0) 69%
  );
  background-color: #3b49af;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .progress-bar
  .progress
  .progress-change {
  position: absolute;
  width: 10px;
  height: 10px;
  right: 0;
  bottom: -2px;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
  background-color: #fff;
  cursor: pointer;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .progress-bar
  .progress-load-bar {
  position: absolute;
  width: 0%;
  height: 4px;
  background-color: rgba(0, 0, 0, 0.06);
  transition: width, background-color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
  left: 0;
  top: 5px;
  z-index: 77;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .progress-bar
  .rc-slider-track {
  z-index: 78;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .progress-bar
  .rc-slider-handle {
  z-index: 79;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content {
  padding-left: 2%;
  display: inline-flex;
  align-items: center;
  align-content: center;
  flex-basis: 15%;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  > .group {
  text-align: center;
  flex: 1 1;
  margin: 0 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  > .group,
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  > .group
  > svg {
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player-main
    .music-player-panel
    .panel-content
    .player-content
    > .group {
    margin: 0 6px;
  }
}
@media screen and (max-width: 320px) {
  .react-jinke-music-player-main
    .music-player-panel
    .panel-content
    .player-content
    > .group {
    margin: 0 4px;
  }
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  > .group
  > i {
  cursor: pointer;
  color: #fff;
  font-size: 20px;
  vertical-align: middle;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .theme-switch
  .rc-switch {
  background-color: transparent;
}
@media screen and (max-width: 768px) {
  .react-jinke-music-player-main
    .music-player-panel
    .panel-content
    .player-content
    .play-sounds {
    /* display: none !important; */
  }
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .prev-audio
  svg,
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .next-audio
  svg {
  font-size: 35px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .play-btn {
  padding: 0 18px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .play-btn
  svg {
  font-size: 26px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .loop-btn.active {
  color: #3b49af;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .play-sounds {
  align-items: center;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .play-sounds
  svg {
  font-size: 28px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .play-sounds
  .sounds-icon {
  margin-right: 15px;
  flex: 1 1 auto;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .play-sounds
  .sound-operation {
  width: 100px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .audio-lists-btn {
  -webkit-user-select: none;
  user-select: none;
  min-width: 60px;
  height: 23px;
  border-radius: 40px;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 0 10px;
  box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.02);
  position: relative;
  transition: color, background-color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .audio-lists-btn
  > .group:hover,
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .audio-lists-btn
  > .group:hover
  > svg {
  color: #3b49af;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .audio-lists-btn
  .audio-lists-num {
  margin-left: 8px;
}
.react-jinke-music-player-main .music-player-panel .rc-switch-inner svg {
  font-size: 13px;
}
.react-jinke-music-player-main .rc-slider-rail {
  background-color: #626162 !important;
  transition: background-color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main.light-theme svg {
  color: #3b49af;
}
.react-jinke-music-player-main.light-theme svg:hover,
.react-jinke-music-player-main.light-theme svg:active {
  color: #3ece89;
}
.react-jinke-music-player-main.light-theme .rc-slider-rail {
  background-color: rgba(0, 0, 0, 0.09) !important;
}
.react-jinke-music-player-main.light-theme .music-player-panel {
  box-shadow: 0 1px 2px 0 rgba(0, 34, 77, 0.05);
  background-color: #fff;
  color: #7d7d7d;
}
.react-jinke-music-player-main.light-theme .music-player-panel .img-content {
  box-shadow: 0 0 10px #dcdcdc;
}
.react-jinke-music-player-main.light-theme .rc-switch {
  color: #fff;
}
.react-jinke-music-player-main.light-theme .rc-switch::after {
  background-color: #fff;
}
.react-jinke-music-player-main.light-theme .rc-switch-checked {
  background-color: #3b49af !important;
  border: 1px solid #3b49af;
}
.react-jinke-music-player-main.light-theme .rc-switch-inner {
  color: #fff;
}
.react-jinke-music-player-main.light-theme .audio-lists-btn {
  background-color: #f7f8fa !important;
}
.react-jinke-music-player-main.light-theme .audio-lists-btn:hover,
.react-jinke-music-player-main.light-theme .audio-lists-btn:active {
  color: #444;
  background-color: #fdfdfe;
}
.react-jinke-music-player-main.light-theme .audio-lists-btn > .group:hover,
.react-jinke-music-player-main.light-theme
  .audio-lists-btn
  > .group:hover
  > svg {
  color: #444;
}
.react-jinke-music-player-main.light-theme .audio-lists-panel {
  color: #444;
  background-color: #fff;
  box-shadow: 0 0 2px #dcdcdc;
}
.react-jinke-music-player-main.light-theme .audio-lists-panel .audio-item {
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player-main.light-theme .audio-lists-panel .audio-item {
    background-color: #fff !important;
  }
  .react-jinke-music-player-main.light-theme
    .audio-lists-panel
    .audio-item.playing {
    background-color: #fafafa !important;
  }
  .react-jinke-music-player-main.light-theme
    .audio-lists-panel
    .audio-item.playing
    svg {
    color: #3b49af;
  }
}
.react-jinke-music-player-main.light-theme .audio-lists-panel-header {
  color: #444;
  background-color: #fff;
  border-bottom: 1px solid #f4f4f7;
}
.react-jinke-music-player-main.light-theme .audio-lists-panel-header .line {
  background-color: #f4f4f7;
}
.react-jinke-music-player-main.light-theme .audio-item {
  box-shadow: 0 0 2px transparent !important;
  border-bottom: 1px solid rgba(220, 220, 220, 0.26);
  background-color: rgba(64, 68, 75, 0.65);
}
.react-jinke-music-player-main.light-theme .audio-item:hover,
.react-jinke-music-player-main.light-theme .audio-item:active {
  background-color: #fafafa !important;
}
.react-jinke-music-player-main.light-theme .audio-item:hover svg,
.react-jinke-music-player-main.light-theme .audio-item:active svg {
  color: #3b49af;
}
.react-jinke-music-player-main.light-theme .audio-item.playing {
  background-color: #fafafa !important;
}
.react-jinke-music-player-main.light-theme .audio-item.playing svg {
  color: #3b49af;
}
.react-jinke-music-player-main.light-theme .player-time {
  color: rgba(162, 162, 162, 0.45) !important;
}
.react-jinke-music-player-main.light-theme .react-jinke-music-player-mobile {
  background-color: #fff;
  color: #444;
}
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile-cover {
  border: 5px solid transparent;
  box-shadow: 0 0 30px 2px rgba(0, 0, 0, 0.2);
}
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile
  .current-time,
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile
  .duration {
  color: #444;
}
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile
  .rc-slider-rail {
  background-color: #e9e9e9;
}
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile-operation
  svg {
  color: #444;
}
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile-tip
  svg {
  color: #fff !important;
}
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile-singer
  .name {
  color: #444;
  transition: color 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile-singer
  .name::before,
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile-singer
  .name::after {
  background-color: #444;
}
.react-jinke-music-player-main.light-theme .play-mode-title {
  background-color: #fff;
  color: #3b49af;
}
.react-jinke-music-player {
  position: fixed;
  width: 80px;
  height: 80px;
  z-index: 999;
}
.react-jinke-music-player .music-player {
  cursor: pointer;
  position: relative;
  width: 80px;
  height: 80px;
}
.react-jinke-music-player .music-player-audio {
  display: none !important;
}
.react-jinke-music-player .music-player-controller {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid #fff;
  background-color: #fff;
  color: #3b49af;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 20px;
  text-align: center;
  transition: all 0.3s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  z-index: 99;
  padding: 10px;
  position: fixed;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-jinke-music-player
  .music-player-controller.music-player-playing::before {
  content: '';
  position: absolute;
  width: 80px;
  height: 80px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  z-index: 88;
  -webkit-animation: scale 5s linear infinite;
          animation: scale 5s linear infinite;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player .music-player-controller,
  .react-jinke-music-player .music-player-controller::before {
    width: 60px;
    height: 60px;
  }
}
.react-jinke-music-player .music-player-controller i {
  font-size: 28px;
}
.react-jinke-music-player .music-player-controller:active {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}
.react-jinke-music-player .music-player-controller:hover {
  font-size: 16px;
}
.react-jinke-music-player
  .music-player-controller:hover
  .music-player-controller-setting {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.react-jinke-music-player .music-player-controller .controller-title {
  font-size: 14px;
}
@media screen and (max-width: 768px) {
  .react-jinke-music-player .music-player-controller i {
    font-size: 20px;
  }
  .react-jinke-music-player .music-player-controller:hover,
  .react-jinke-music-player .music-player-controller:active {
    font-size: 12px;
  }
  .react-jinke-music-player
    .music-player-controller:hover
    .music-player-controller-setting,
  .react-jinke-music-player
    .music-player-controller:active
    .music-player-controller-setting {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.react-jinke-music-player
  .music-player-controller
  .music-player-controller-setting {
  width: 100%;
  height: 100%;
  background: rgba(49, 194, 124, 0.3);
  -webkit-transform: scale(0);
          transform: scale(0);
  border-radius: 50%;
  transition: all 0.4s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-jinke-music-player .audio-circle-process-bar {
  strokewidth: 3px;
  strokelinejoin: round;
  pointer-events: none;
  width: 80px;
  height: 80px;
  position: absolute;
  left: 0;
  top: -80px;
  z-index: 100;
  -webkit-animation: scaleTo 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
          animation: scaleTo 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player .audio-circle-process-bar circle[class='bg'] {
  stroke: #fff;
}
.react-jinke-music-player .audio-circle-process-bar circle[class='stroke'] {
  stroke: #3b49af;
}
.react-jinke-music-player .audio-circle-process-bar,
.react-jinke-music-player .audio-circle-process-bar circle {
  transition: stroke-dasharray 350ms cubic-bezier(0.43, -0.1, 0.16, 1.1);
  -webkit-transform: matrix(0, -1, 1, 0, 0, 80);
          transform: matrix(0, -1, 1, 0, 0, 80);
}
@media screen and (max-width: 768px) {
  .react-jinke-music-player .audio-circle-process-bar,
  .react-jinke-music-player .audio-circle-process-bar circle {
    -webkit-transform: matrix(0, -1, 1, 0, 0, 60);
            transform: matrix(0, -1, 1, 0, 0, 60);
  }
}

/*colors used in genre class*/
/*colors used in zipd-packs*/
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #375a7f;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #e74c3c;
  --orange: #fd7e14;
  --yellow: #f39c12;
  --green: #00bc8c;
  --teal: #20c997;
  --cyan: #3498db;
  --white: #dddddd;
  --gray: #999;
  --gray-dark: #202225;
  --primary: #4d7ce8;
  --secondary: #7685b3;
  --success: #45b649;
  --info: #00c9ff;
  --warning: #f8c283;
  --danger: #f85032;
  --light: #999;
  --dark: #202225;
  --grey: #d8d8d8;
  --grey2: #575f66;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.72rem;
  font-weight: 400;
  line-height: 1.5;
  color: #dddddd;
  text-align: left;
  background-color: #1d1b21; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #5764c6;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #3b49af;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #999;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2rem; }

h2, .h2 {
  font-size: 1.5rem; }

h3, .h3 {
  font-size: 1rem; }

h4, .h4 {
  font-size: 1.08rem; }

h5, .h5 {
  font-size: 0.9rem; }

h6, .h6 {
  font-size: 0.72rem; }

.lead {
  font-size: 0.9rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 0.9rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #999; }
  .blockquote-footer::before {
    content: "\2014\A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #1d1b21;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #999; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #dddddd;
  background-color: #1d1b21;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: inherit; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #dddddd; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #444; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #444; }
  .table tbody + tbody {
    border-top: 2px solid #444; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #444; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #444; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #202225; }

.table-hover tbody tr:hover {
  color: #dddddd;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b5c2e0; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #92abe3; }

.table-hover .table-primary:hover {
  background-color: #a3b3d8; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a3b3d8; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #c0c4d1; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #a7afc7; }

.table-hover .table-secondary:hover {
  background-color: #b1b6c6; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #b1b6c6; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b2d2b4; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8ec990; }

.table-hover .table-success:hover {
  background-color: #a2c9a4; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #a2c9a4; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #9fd7e7; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #6ad3ef; }

.table-hover .table-info:hover {
  background-color: #8bcfe2; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #8bcfe2; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #e5d5c4; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ebcfae; }

.table-hover .table-warning:hover {
  background-color: #ddc8b2; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ddc8b2; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #e5b6ad; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #eb9484; }

.table-hover .table-danger:hover {
  background-color: #dfa59a; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #dfa59a; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #cacaca; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #bababa; }

.table-hover .table-light:hover {
  background-color: #bdbdbd; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #bdbdbd; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #a8a9a9; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #7b7c7d; }

.table-hover .table-dark:hover {
  background-color: #9b9c9c; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #9b9c9c; }

.table-grey,
.table-grey > th,
.table-grey > td {
  background-color: gainsboro; }

.table-grey th,
.table-grey td,
.table-grey thead th,
.table-grey tbody + tbody {
  border-color: #dadada; }

.table-hover .table-grey:hover {
  background-color: #cfcfcf; }
  .table-hover .table-grey:hover > td,
  .table-hover .table-grey:hover > th {
    background-color: #cfcfcf; }

.table-grey2,
.table-grey2 > th,
.table-grey2 > td {
  background-color: #b7babc; }

.table-grey2 th,
.table-grey2 td,
.table-grey2 thead th,
.table-grey2 tbody + tbody {
  border-color: #979b9f; }

.table-hover .table-grey2:hover {
  background-color: #aaadb0; }
  .table-hover .table-grey2:hover > td,
  .table-hover .table-grey2:hover > th {
    background-color: #aaadb0; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #dddddd;
  background-color: #202225;
  border-color: #32353a; }

.table .thead-light th {
  color: #444;
  background-color: #ebebeb;
  border-color: #444; }

.table-dark {
  color: #dddddd;
  background-color: #202225; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32353a; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(221, 221, 221, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #dddddd;
    background-color: rgba(221, 221, 221, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.72rem;
  font-weight: 400;
  line-height: 1.5;
  color: #444;
  background-color: #dddddd;
  background-clip: padding-box;
  border: 1px solid #1d1b21;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #444;
    background-color: #dddddd;
    border-color: #becff7;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(77, 124, 232, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #999;
    opacity: 1; }
  .form-control::placeholder {
    color: #999;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #ebebeb;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #444;
  background-color: #dddddd; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 0.9rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.63rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #dddddd;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.63rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #999; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #45b649; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.63rem;
  line-height: 1.5;
  color: #dddddd;
  background-color: rgba(69, 182, 73, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #45b649;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2345b649' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #45b649;
    box-shadow: 0 0 0 0.2rem rgba(69, 182, 73, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #45b649;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23202225' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2345b649' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #dddddd no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #45b649;
    box-shadow: 0 0 0 0.2rem rgba(69, 182, 73, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #45b649; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #45b649; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #45b649; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #68c66b;
  background: #68c66b linear-gradient(180deg, #5dac60, #68c66b) repeat-x; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(69, 182, 73, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #45b649; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #45b649; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #45b649;
  box-shadow: 0 0 0 0.2rem rgba(69, 182, 73, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f85032; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.63rem;
  line-height: 1.5;
  color: #dddddd;
  background-color: rgba(248, 80, 50, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #f85032;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f85032' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f85032' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #f85032;
    box-shadow: 0 0 0 0.2rem rgba(248, 80, 50, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #f85032;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23202225' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f85032' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f85032' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #dddddd no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #f85032;
    box-shadow: 0 0 0 0.2rem rgba(248, 80, 50, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #f85032; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #f85032; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #f85032; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #fa7a63;
  background: #fa7a63 linear-gradient(180deg, #d96c59, #fa7a63) repeat-x; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(248, 80, 50, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #f85032; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #f85032; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #f85032;
  box-shadow: 0 0 0 0.2rem rgba(248, 80, 50, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #dddddd;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.72rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #dddddd;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(77, 124, 232, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #dddddd;
  background: #4d7ce8 linear-gradient(180deg, #466dca, #4d7ce8) repeat-x;
  border-color: #4d7ce8; }
  .btn-primary:hover {
    color: #dddddd;
    background: #2b63e4 linear-gradient(180deg, #2958c6, #2b63e4) repeat-x;
    border-color: #205be2; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(99, 139, 230, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #dddddd;
    background-color: #4d7ce8;
    border-color: #4d7ce8;
    background-image: none; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #dddddd;
    background-color: #205be2;
    background-image: none;
    border-color: #1c55d9; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(99, 139, 230, 0.5); }

.btn-secondary {
  color: #dddddd;
  background: #7685b3 linear-gradient(180deg, #69759d, #7685b3) repeat-x;
  border-color: #7685b3; }
  .btn-secondary:hover {
    color: #dddddd;
    background: #5d6fa5 linear-gradient(180deg, #546291, #5d6fa5) repeat-x;
    border-color: #58699e; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(133, 146, 185, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #dddddd;
    background-color: #7685b3;
    border-color: #7685b3;
    background-image: none; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #dddddd;
    background-color: #58699e;
    background-image: none;
    border-color: #536496; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(133, 146, 185, 0.5); }

.btn-success {
  color: #dddddd;
  background: #45b649 linear-gradient(180deg, #3f9f43, #45b649) repeat-x;
  border-color: #45b649; }
  .btn-success:hover {
    color: #dddddd;
    background: #3a9a3e linear-gradient(180deg, #36873a, #3a9a3e) repeat-x;
    border-color: #37913a; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(92, 188, 95, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #dddddd;
    background-color: #45b649;
    border-color: #45b649;
    background-image: none; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #dddddd;
    background-color: #37913a;
    background-image: none;
    border-color: #338836; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(92, 188, 95, 0.5); }

.btn-info {
  color: #dddddd;
  background: #00c9ff linear-gradient(180deg, #04afde, #00c9ff) repeat-x;
  border-color: #00c9ff; }
  .btn-info:hover {
    color: #dddddd;
    background: #00abd9 linear-gradient(180deg, #0495bd, #00abd9) repeat-x;
    border-color: #00a1cc; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 204, 250, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #dddddd;
    background-color: #00c9ff;
    border-color: #00c9ff;
    background-image: none; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #dddddd;
    background-color: #00a1cc;
    background-image: none;
    border-color: #0097bf; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(33, 204, 250, 0.5); }

.btn-warning {
  color: #1d1b21;
  background: #f8c283 linear-gradient(180deg, #d7a974, #f8c283) repeat-x;
  border-color: #f8c283; }
  .btn-warning:hover {
    color: #1d1b21;
    background: #f6b05f linear-gradient(180deg, #d59a56, #f6b05f) repeat-x;
    border-color: #f5aa53; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(215, 169, 116, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #1d1b21;
    background-color: #f8c283;
    border-color: #f8c283;
    background-image: none; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #1d1b21;
    background-color: #f5aa53;
    background-image: none;
    border-color: #f5a447; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(215, 169, 116, 0.5); }

.btn-danger {
  color: #dddddd;
  background: #f85032 linear-gradient(180deg, #d7482f, #f85032) repeat-x;
  border-color: #f85032; }
  .btn-danger:hover {
    color: #dddddd;
    background: #f7300d linear-gradient(180deg, #d62d10, #f7300d) repeat-x;
    border-color: #ef2b08; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 101, 76, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #dddddd;
    background-color: #f85032;
    border-color: #f85032;
    background-image: none; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #dddddd;
    background-color: #ef2b08;
    background-image: none;
    border-color: #e32908; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(244, 101, 76, 0.5); }

.btn-light {
  color: #dddddd;
  background: #999 linear-gradient(180deg, #868687, #999) repeat-x;
  border-color: #999; }
  .btn-light:hover {
    color: #dddddd;
    background: #868686 linear-gradient(180deg, #767677, #868686) repeat-x;
    border-color: gray; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(163, 163, 163, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #dddddd;
    background-color: #999;
    border-color: #999;
    background-image: none; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #dddddd;
    background-color: gray;
    background-image: none;
    border-color: #797979; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(163, 163, 163, 0.5); }

.btn-dark {
  color: #dddddd;
  background: #202225 linear-gradient(180deg, #202124, #202225) repeat-x;
  border-color: #202225; }
  .btn-dark:hover {
    color: #dddddd;
    background: #0e0f10 linear-gradient(180deg, #101113, #0e0f10) repeat-x;
    border-color: #08090a; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(60, 62, 65, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #dddddd;
    background-color: #202225;
    border-color: #202225;
    background-image: none; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #dddddd;
    background-color: #08090a;
    background-image: none;
    border-color: #020303; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(60, 62, 65, 0.5); }

.btn-grey {
  color: #1d1b21;
  background: #d8d8d8 linear-gradient(180deg, #bcbcbd, #d8d8d8) repeat-x;
  border-color: #d8d8d8; }
  .btn-grey:hover {
    color: #1d1b21;
    background: #c5c5c5 linear-gradient(180deg, #acabac, #c5c5c5) repeat-x;
    border-color: #bfbfbf; }
  .btn-grey:focus, .btn-grey.focus {
    box-shadow: 0 0 0 0.2rem rgba(188, 188, 189, 0.5); }
  .btn-grey.disabled, .btn-grey:disabled {
    color: #1d1b21;
    background-color: #d8d8d8;
    border-color: #d8d8d8;
    background-image: none; }
  .btn-grey:not(:disabled):not(.disabled):active, .btn-grey:not(:disabled):not(.disabled).active,
  .show > .btn-grey.dropdown-toggle {
    color: #1d1b21;
    background-color: #bfbfbf;
    background-image: none;
    border-color: #b8b8b8; }
    .btn-grey:not(:disabled):not(.disabled):active:focus, .btn-grey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-grey.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(188, 188, 189, 0.5); }

.btn-grey2 {
  color: #dddddd;
  background: #575f66 linear-gradient(180deg, #4e555c, #575f66) repeat-x;
  border-color: #575f66; }
  .btn-grey2:hover {
    color: #dddddd;
    background: #454c51 linear-gradient(180deg, #3f444a, #454c51) repeat-x;
    border-color: #40454a; }
  .btn-grey2:focus, .btn-grey2.focus {
    box-shadow: 0 0 0 0.2rem rgba(107, 114, 120, 0.5); }
  .btn-grey2.disabled, .btn-grey2:disabled {
    color: #dddddd;
    background-color: #575f66;
    border-color: #575f66;
    background-image: none; }
  .btn-grey2:not(:disabled):not(.disabled):active, .btn-grey2:not(:disabled):not(.disabled).active,
  .show > .btn-grey2.dropdown-toggle {
    color: #dddddd;
    background-color: #40454a;
    background-image: none;
    border-color: #3a3f44; }
    .btn-grey2:not(:disabled):not(.disabled):active:focus, .btn-grey2:not(:disabled):not(.disabled).active:focus,
    .show > .btn-grey2.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(107, 114, 120, 0.5); }

.btn-outline-primary {
  color: #4d7ce8;
  border-color: #4d7ce8; }
  .btn-outline-primary:hover {
    color: #dddddd;
    background-color: #4d7ce8;
    border-color: #4d7ce8; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 124, 232, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #4d7ce8;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #dddddd;
    background-color: #4d7ce8;
    border-color: #4d7ce8; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(77, 124, 232, 0.5); }

.btn-outline-secondary {
  color: #7685b3;
  border-color: #7685b3; }
  .btn-outline-secondary:hover {
    color: #dddddd;
    background-color: #7685b3;
    border-color: #7685b3; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(118, 133, 179, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #7685b3;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #dddddd;
    background-color: #7685b3;
    border-color: #7685b3; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(118, 133, 179, 0.5); }

.btn-outline-success {
  color: #45b649;
  border-color: #45b649; }
  .btn-outline-success:hover {
    color: #dddddd;
    background-color: #45b649;
    border-color: #45b649; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(69, 182, 73, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #45b649;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #dddddd;
    background-color: #45b649;
    border-color: #45b649; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(69, 182, 73, 0.5); }

.btn-outline-info {
  color: #00c9ff;
  border-color: #00c9ff; }
  .btn-outline-info:hover {
    color: #dddddd;
    background-color: #00c9ff;
    border-color: #00c9ff; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 201, 255, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #00c9ff;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #dddddd;
    background-color: #00c9ff;
    border-color: #00c9ff; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 201, 255, 0.5); }

.btn-outline-warning {
  color: #f8c283;
  border-color: #f8c283; }
  .btn-outline-warning:hover {
    color: #1d1b21;
    background-color: #f8c283;
    border-color: #f8c283; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 194, 131, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #f8c283;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #1d1b21;
    background-color: #f8c283;
    border-color: #f8c283; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 194, 131, 0.5); }

.btn-outline-danger {
  color: #f85032;
  border-color: #f85032; }
  .btn-outline-danger:hover {
    color: #dddddd;
    background-color: #f85032;
    border-color: #f85032; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 80, 50, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #f85032;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #dddddd;
    background-color: #f85032;
    border-color: #f85032; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 80, 50, 0.5); }

.btn-outline-light {
  color: #999;
  border-color: #999; }
  .btn-outline-light:hover {
    color: #dddddd;
    background-color: #999;
    border-color: #999; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #999;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #dddddd;
    background-color: #999;
    border-color: #999; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5); }

.btn-outline-dark {
  color: #202225;
  border-color: #202225; }
  .btn-outline-dark:hover {
    color: #dddddd;
    background-color: #202225;
    border-color: #202225; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 34, 37, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #202225;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #dddddd;
    background-color: #202225;
    border-color: #202225; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(32, 34, 37, 0.5); }

.btn-outline-grey {
  color: #d8d8d8;
  border-color: #d8d8d8; }
  .btn-outline-grey:hover {
    color: #1d1b21;
    background-color: #d8d8d8;
    border-color: #d8d8d8; }
  .btn-outline-grey:focus, .btn-outline-grey.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 216, 216, 0.5); }
  .btn-outline-grey.disabled, .btn-outline-grey:disabled {
    color: #d8d8d8;
    background-color: transparent; }
  .btn-outline-grey:not(:disabled):not(.disabled):active, .btn-outline-grey:not(:disabled):not(.disabled).active,
  .show > .btn-outline-grey.dropdown-toggle {
    color: #1d1b21;
    background-color: #d8d8d8;
    border-color: #d8d8d8; }
    .btn-outline-grey:not(:disabled):not(.disabled):active:focus, .btn-outline-grey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-grey.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 216, 216, 0.5); }

.btn-outline-grey2 {
  color: #575f66;
  border-color: #575f66; }
  .btn-outline-grey2:hover {
    color: #dddddd;
    background-color: #575f66;
    border-color: #575f66; }
  .btn-outline-grey2:focus, .btn-outline-grey2.focus {
    box-shadow: 0 0 0 0.2rem rgba(87, 95, 102, 0.5); }
  .btn-outline-grey2.disabled, .btn-outline-grey2:disabled {
    color: #575f66;
    background-color: transparent; }
  .btn-outline-grey2:not(:disabled):not(.disabled):active, .btn-outline-grey2:not(:disabled):not(.disabled).active,
  .show > .btn-outline-grey2.dropdown-toggle {
    color: #dddddd;
    background-color: #575f66;
    border-color: #575f66; }
    .btn-outline-grey2:not(:disabled):not(.disabled):active:focus, .btn-outline-grey2:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-grey2.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(87, 95, 102, 0.5); }

.btn-link {
  font-weight: 400;
  color: #5764c6;
  text-decoration: none; }
  .btn-link:hover {
    color: #3b49af;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #999;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.63rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.72rem;
  color: #dddddd;
  text-align: left;
  list-style: none;
  background-color: #1d1b21;
  background-clip: padding-box;
  border: 1px solid #444;
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #444; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #dddddd;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #dddddd;
    text-decoration: none;
    background: #375a7f linear-gradient(180deg, #335171, #375a7f) repeat-x; }
  .dropdown-item.active, .dropdown-item:active {
    color: #dddddd;
    text-decoration: none;
    background: #4d7ce8 linear-gradient(180deg, #466dca, #4d7ce8) repeat-x; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #999;
    pointer-events: none;
    background-color: transparent;
    background-image: none; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.63rem;
  color: #999;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #dddddd; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.72rem;
  font-weight: 400;
  line-height: 1.5;
  color: #adb5bd;
  text-align: center;
  white-space: nowrap;
  background-color: #444;
  border: 1px solid #1d1b21;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.63rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.08rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #dddddd;
    border-color: #4d7ce8;
    background: #4d7ce8 linear-gradient(180deg, #466dca, #4d7ce8) repeat-x; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(77, 124, 232, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #becff7; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #dddddd;
    background-color: #ebf0fc;
    border-color: #ebf0fc; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #999; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #ebebeb; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.04rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #dddddd;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.04rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23dddddd' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #4d7ce8;
  background: #4d7ce8 linear-gradient(180deg, #466dca, #4d7ce8) repeat-x; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23dddddd' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(77, 124, 232, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(77, 124, 232, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23dddddd'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(77, 124, 232, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.04rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #dddddd;
    -webkit-transform: translateX(0.75rem);
            transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(77, 124, 232, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.72rem;
  font-weight: 400;
  line-height: 1.5;
  color: #444;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23202225' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #dddddd;
  border: 1px solid #1d1b21;
  border-radius: 0.25rem;
  -webkit-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #becff7;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(77, 124, 232, 0.25); }
    .custom-select:focus::-ms-value {
      color: #444;
      background-color: #dddddd; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #999;
    background-color: #ebebeb; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.63rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 0.9rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #becff7;
    box-shadow: 0 0 0 0.2rem rgba(77, 124, 232, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #ebebeb; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #adb5bd;
  background-color: #dddddd;
  border: 1px solid #1d1b21;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #adb5bd;
    content: "Browse";
    background: #444 linear-gradient(180deg, #3e3e3f, #444) repeat-x;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #1d1b21, 0 0 0 0.2rem rgba(77, 124, 232, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #1d1b21, 0 0 0 0.2rem rgba(77, 124, 232, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #1d1b21, 0 0 0 0.2rem rgba(77, 124, 232, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background: #4d7ce8 linear-gradient(180deg, #466dca, #4d7ce8) repeat-x;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background: #ebf0fc linear-gradient(180deg, #ccd0dc, #ebf0fc) repeat-x; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background: #4d7ce8 linear-gradient(180deg, #466dca, #4d7ce8) repeat-x;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background: #ebf0fc linear-gradient(180deg, #ccd0dc, #ebf0fc) repeat-x; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background: #4d7ce8 linear-gradient(180deg, #466dca, #4d7ce8) repeat-x;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background: #ebf0fc linear-gradient(180deg, #ccd0dc, #ebf0fc) repeat-x; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 2rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #adb5bd;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #444; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #444 #444 transparent; }
    .nav-tabs .nav-link.disabled {
      color: #adb5bd;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #dddddd;
    background-color: #1d1b21;
    border-color: #444 #444 transparent; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #dddddd;
  background-color: #4d7ce8; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.365rem;
  padding-bottom: 0.365rem;
  margin-right: 1rem;
  font-size: 0.9rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 0.9rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: #dddddd; }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: #dddddd; }

.navbar-light .navbar-nav .nav-link {
  color: #dddddd; }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: #5764c6; }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(221, 221, 221, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #dddddd; }

.navbar-light .navbar-toggler {
  color: #dddddd;
  border-color: rgba(221, 221, 221, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='%23dddddd' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: #dddddd; }
  .navbar-light .navbar-text a {
    color: #dddddd; }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: #dddddd; }

.navbar-dark .navbar-brand {
  color: #dddddd; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #dddddd; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(221, 221, 221, 0.6); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: #dddddd; }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(221, 221, 221, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #dddddd; }

.navbar-dark .navbar-toggler {
  color: rgba(221, 221, 221, 0.6);
  border-color: rgba(221, 221, 221, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(221, 221, 221, 0.6)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(221, 221, 221, 0.6); }
  .navbar-dark .navbar-text a {
    color: #dddddd; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #dddddd; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #202225;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #444;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #999;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #999; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: 0;
  line-height: 1.25;
  color: #dddddd;
  background-color: #00bc8c;
  border: 0 solid transparent; }
  .page-link:hover {
    z-index: 2;
    color: #dddddd;
    text-decoration: none;
    background-color: #00efb2;
    border-color: transparent; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(77, 124, 232, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #dddddd;
  background-color: #00efb2;
  border-color: transparent; }

.page-item.disabled .page-link {
  color: #dddddd;
  pointer-events: none;
  cursor: auto;
  background-color: #b7b7b7;
  border-color: transparent; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 0.9rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.63rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #dddddd;
  background-color: #4d7ce8; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #dddddd;
    background-color: #205be2; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(77, 124, 232, 0.5); }

.badge-secondary {
  color: #dddddd;
  background-color: #7685b3; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #dddddd;
    background-color: #58699e; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(118, 133, 179, 0.5); }

.badge-success {
  color: #dddddd;
  background-color: #45b649; }
  a.badge-success:hover, a.badge-success:focus {
    color: #dddddd;
    background-color: #37913a; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(69, 182, 73, 0.5); }

.badge-info {
  color: #dddddd;
  background-color: #00c9ff; }
  a.badge-info:hover, a.badge-info:focus {
    color: #dddddd;
    background-color: #00a1cc; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 201, 255, 0.5); }

.badge-warning {
  color: #1d1b21;
  background-color: #f8c283; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #1d1b21;
    background-color: #f5aa53; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 194, 131, 0.5); }

.badge-danger {
  color: #dddddd;
  background-color: #f85032; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #dddddd;
    background-color: #ef2b08; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 80, 50, 0.5); }

.badge-light {
  color: #dddddd;
  background-color: #999; }
  a.badge-light:hover, a.badge-light:focus {
    color: #dddddd;
    background-color: gray; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5); }

.badge-dark {
  color: #dddddd;
  background-color: #202225; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #dddddd;
    background-color: #08090a; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(32, 34, 37, 0.5); }

.badge-grey {
  color: #1d1b21;
  background-color: #d8d8d8; }
  a.badge-grey:hover, a.badge-grey:focus {
    color: #1d1b21;
    background-color: #bfbfbf; }
  a.badge-grey:focus, a.badge-grey.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(216, 216, 216, 0.5); }

.badge-grey2 {
  color: #dddddd;
  background-color: #575f66; }
  a.badge-grey2:hover, a.badge-grey2:focus {
    color: #dddddd;
    background-color: #40454a; }
  a.badge-grey2:focus, a.badge-grey2.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(87, 95, 102, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #202225;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3.58rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #284079;
  background: #c0cadf linear-gradient(180deg, #a8b0c3, #c0cadf) repeat-x;
  border-color: #b5c2e0; }
  .alert-primary hr {
    border-top-color: #a3b3d8; }
  .alert-primary .alert-link {
    color: #1b2c53; }

.alert-secondary {
  color: #3d455d;
  background: #c8cbd5 linear-gradient(180deg, #aeb1ba, #c8cbd5) repeat-x;
  border-color: #c0c4d1; }
  .alert-secondary hr {
    border-top-color: #b1b6c6; }
  .alert-secondary .alert-link {
    color: #292e3e; }

.alert-success {
  color: #245f26;
  background: #bfd5bf linear-gradient(180deg, #a7b9a7, #bfd5bf) repeat-x;
  border-color: #b2d2b4; }
  .alert-success hr {
    border-top-color: #a2c9a4; }
  .alert-success .alert-link {
    color: #163a17; }

.alert-info {
  color: #006985;
  background: #b1d9e4 linear-gradient(180deg, #9bbdc7, #b1d9e4) repeat-x;
  border-color: #9fd7e7; }
  .alert-info hr {
    border-top-color: #8bcfe2; }
  .alert-info .alert-link {
    color: #004152; }

.alert-warning {
  color: #816544;
  background: #e2d8cb linear-gradient(180deg, #c4bcb2, #e2d8cb) repeat-x;
  border-color: #e5d5c4; }
  .alert-warning hr {
    border-top-color: #ddc8b2; }
  .alert-warning .alert-link {
    color: #604b32; }

.alert-danger {
  color: #812a1a;
  background: #e2c1bb linear-gradient(180deg, #c4a8a4, #e2c1bb) repeat-x;
  border-color: #e5b6ad; }
  .alert-danger hr {
    border-top-color: #dfa59a; }
  .alert-danger .alert-link {
    color: #571c11; }

.alert-light {
  color: #505050;
  background: #cfcfcf linear-gradient(180deg, #b4b4b5, #cfcfcf) repeat-x;
  border-color: #cacaca; }
  .alert-light hr {
    border-top-color: #bdbdbd; }
  .alert-light .alert-link {
    color: #373737; }

.alert-dark {
  color: #111213;
  background: #b7b8b8 linear-gradient(180deg, #a0a0a1, #b7b8b8) repeat-x;
  border-color: #a8a9a9; }
  .alert-dark hr {
    border-top-color: #9b9c9c; }
  .alert-dark .alert-link {
    color: black; }

.alert-grey {
  color: #707070;
  background: gainsboro linear-gradient(180deg, #bfbfc0, gainsboro) repeat-x;
  border-color: gainsboro; }
  .alert-grey hr {
    border-top-color: #cfcfcf; }
  .alert-grey .alert-link {
    color: #575757; }

.alert-grey2 {
  color: #2d3135;
  background: #c2c4c5 linear-gradient(180deg, #a9abac, #c2c4c5) repeat-x;
  border-color: #b7babc; }
  .alert-grey2 hr {
    border-top-color: #aaadb0; }
  .alert-grey2 .alert-link {
    color: #161819; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 0.625rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 0.625rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 0.625rem;
  overflow: hidden;
  font-size: 0.625rem;
  background-color: #444;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #dddddd;
  text-align: center;
  white-space: nowrap;
  background-color: #4d7ce8;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(221, 221, 221, 0.15) 25%, transparent 25%, transparent 50%, rgba(221, 221, 221, 0.15) 50%, rgba(221, 221, 221, 0.15) 75%, transparent 75%, transparent);
  background-size: 0.625rem 0.625rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #444;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #444;
    text-decoration: none;
    background-color: #444; }
  .list-group-item-action:active {
    color: #dddddd;
    background-color: #ebebeb; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #202225;
  border: 1px solid #444; }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #999;
    pointer-events: none;
    background-color: #202225; }
  .list-group-item.active {
    z-index: 2;
    color: #dddddd;
    background-color: #4d7ce8;
    border-color: #4d7ce8; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #284079;
  background-color: #b5c2e0; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #284079;
    background-color: #a3b3d8; }
  .list-group-item-primary.list-group-item-action.active {
    color: #dddddd;
    background-color: #284079;
    border-color: #284079; }

.list-group-item-secondary {
  color: #3d455d;
  background-color: #c0c4d1; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #3d455d;
    background-color: #b1b6c6; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #dddddd;
    background-color: #3d455d;
    border-color: #3d455d; }

.list-group-item-success {
  color: #245f26;
  background-color: #b2d2b4; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #245f26;
    background-color: #a2c9a4; }
  .list-group-item-success.list-group-item-action.active {
    color: #dddddd;
    background-color: #245f26;
    border-color: #245f26; }

.list-group-item-info {
  color: #006985;
  background-color: #9fd7e7; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #006985;
    background-color: #8bcfe2; }
  .list-group-item-info.list-group-item-action.active {
    color: #dddddd;
    background-color: #006985;
    border-color: #006985; }

.list-group-item-warning {
  color: #816544;
  background-color: #e5d5c4; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #816544;
    background-color: #ddc8b2; }
  .list-group-item-warning.list-group-item-action.active {
    color: #dddddd;
    background-color: #816544;
    border-color: #816544; }

.list-group-item-danger {
  color: #812a1a;
  background-color: #e5b6ad; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #812a1a;
    background-color: #dfa59a; }
  .list-group-item-danger.list-group-item-action.active {
    color: #dddddd;
    background-color: #812a1a;
    border-color: #812a1a; }

.list-group-item-light {
  color: #505050;
  background-color: #cacaca; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #505050;
    background-color: #bdbdbd; }
  .list-group-item-light.list-group-item-action.active {
    color: #dddddd;
    background-color: #505050;
    border-color: #505050; }

.list-group-item-dark {
  color: #111213;
  background-color: #a8a9a9; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #111213;
    background-color: #9b9c9c; }
  .list-group-item-dark.list-group-item-action.active {
    color: #dddddd;
    background-color: #111213;
    border-color: #111213; }

.list-group-item-grey {
  color: #707070;
  background-color: gainsboro; }
  .list-group-item-grey.list-group-item-action:hover, .list-group-item-grey.list-group-item-action:focus {
    color: #707070;
    background-color: #cfcfcf; }
  .list-group-item-grey.list-group-item-action.active {
    color: #dddddd;
    background-color: #707070;
    border-color: #707070; }

.list-group-item-grey2 {
  color: #2d3135;
  background-color: #b7babc; }
  .list-group-item-grey2.list-group-item-action:hover, .list-group-item-grey2.list-group-item-action:focus {
    color: #2d3135;
    background-color: #aaadb0; }
  .list-group-item-grey2.list-group-item-action.active {
    color: #dddddd;
    background-color: #2d3135;
    border-color: #2d3135; }

.close {
  float: right;
  font-size: 1.08rem;
  font-weight: 700;
  line-height: 1;
  color: #dddddd;
  text-shadow: none;
  opacity: .5; }
  .close:hover {
    color: #dddddd;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
          appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(221, 221, 221, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #999;
  background-color: rgba(221, 221, 221, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #202225;
  background-clip: padding-box;
  border: 1px solid #444;
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #444;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #444;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.63rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #dddddd;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.63rem;
  word-wrap: break-word;
  background-color: #202225;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #202225; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #202225; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #202225; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #444; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #202225; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.72rem;
  background-color: #444;
  border-bottom: 1px solid #373737;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #dddddd; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #dddddd;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #dddddd;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.001)); }

.carousel-control-next {
  right: 0;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.001)); }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dddddd' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dddddd' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #dddddd;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #dddddd;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
          animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
          animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #4d7ce8 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #205be2 !important; }

.bg-secondary {
  background-color: #7685b3 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #58699e !important; }

.bg-success {
  background-color: #45b649 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #37913a !important; }

.bg-info {
  background-color: #00c9ff !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #00a1cc !important; }

.bg-warning {
  background-color: #f8c283 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #f5aa53 !important; }

.bg-danger {
  background-color: #f85032 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ef2b08 !important; }

.bg-light {
  background-color: #999 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: gray !important; }

.bg-dark {
  background-color: #202225 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #08090a !important; }

.bg-grey {
  background-color: #d8d8d8 !important; }

a.bg-grey:hover, a.bg-grey:focus,
button.bg-grey:hover,
button.bg-grey:focus {
  background-color: #bfbfbf !important; }

.bg-grey2 {
  background-color: #575f66 !important; }

a.bg-grey2:hover, a.bg-grey2:focus,
button.bg-grey2:hover,
button.bg-grey2:focus {
  background-color: #40454a !important; }

.bg-gradient-primary {
  background: #4d7ce8 linear-gradient(180deg, #466dca, #4d7ce8) repeat-x !important; }

.bg-gradient-secondary {
  background: #7685b3 linear-gradient(180deg, #69759d, #7685b3) repeat-x !important; }

.bg-gradient-success {
  background: #45b649 linear-gradient(180deg, #3f9f43, #45b649) repeat-x !important; }

.bg-gradient-info {
  background: #00c9ff linear-gradient(180deg, #04afde, #00c9ff) repeat-x !important; }

.bg-gradient-warning {
  background: #f8c283 linear-gradient(180deg, #d7a974, #f8c283) repeat-x !important; }

.bg-gradient-danger {
  background: #f85032 linear-gradient(180deg, #d7482f, #f85032) repeat-x !important; }

.bg-gradient-light {
  background: #999 linear-gradient(180deg, #868687, #999) repeat-x !important; }

.bg-gradient-dark {
  background: #202225 linear-gradient(180deg, #202124, #202225) repeat-x !important; }

.bg-gradient-grey {
  background: #d8d8d8 linear-gradient(180deg, #bcbcbd, #d8d8d8) repeat-x !important; }

.bg-gradient-grey2 {
  background: #575f66 linear-gradient(180deg, #4e555c, #575f66) repeat-x !important; }

.bg-white {
  background-color: #dddddd !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #43484e !important; }

.border-top {
  border-top: 1px solid #43484e !important; }

.border-right {
  border-right: 1px solid #43484e !important; }

.border-bottom {
  border-bottom: 1px solid #43484e !important; }

.border-left {
  border-left: 1px solid #43484e !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #4d7ce8 !important; }

.border-secondary {
  border-color: #7685b3 !important; }

.border-success {
  border-color: #45b649 !important; }

.border-info {
  border-color: #00c9ff !important; }

.border-warning {
  border-color: #f8c283 !important; }

.border-danger {
  border-color: #f85032 !important; }

.border-light {
  border-color: #999 !important; }

.border-dark {
  border-color: #202225 !important; }

.border-grey {
  border-color: #d8d8d8 !important; }

.border-grey2 {
  border-color: #575f66 !important; }

.border-white {
  border-color: #dddddd !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #dddddd !important; }

.text-primary {
  color: #4d7ce8 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #1b51ce !important; }

.text-secondary {
  color: #7685b3 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #4f5e8e !important; }

.text-success {
  color: #45b649 !important; }

a.text-success:hover, a.text-success:focus {
  color: #307f33 !important; }

.text-info {
  color: #00c9ff !important; }

a.text-info:hover, a.text-info:focus {
  color: #008db3 !important; }

.text-warning {
  color: #f8c283 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #f49e3b !important; }

.text-danger {
  color: #f85032 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #d62707 !important; }

.text-light {
  color: #999 !important; }

a.text-light:hover, a.text-light:focus {
  color: #737373 !important; }

.text-dark {
  color: #202225 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: black !important; }

.text-grey {
  color: #d8d8d8 !important; }

a.text-grey:hover, a.text-grey:focus {
  color: #b2b2b2 !important; }

.text-grey2 {
  color: #575f66 !important; }

a.text-grey2:hover, a.text-grey2:focus {
  color: #34393d !important; }

.text-body {
  color: #dddddd !important; }

.text-muted {
  color: #999 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(221, 221, 221, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #dddddd !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #444; }
  .table .thead-dark th {
    color: inherit;
    border-color: #444; } }

.Cal__Day__root {
  display: inline-block;
  box-sizing: border-box;
  width: 14.28571%;
  list-style: none;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none; }

.Cal__Day__root.Cal__Day__enabled.Cal__Day__highlighted, .Cal__Day__root.Cal__Day__enabled:active, .Cal__Day__root.Cal__Day__enabled:hover {
  position: relative;
  z-index: 1; }

.Cal__Day__root.Cal__Day__enabled.Cal__Day__highlighted:before, .Cal__Day__root.Cal__Day__enabled:active:before, .Cal__Day__root.Cal__Day__enabled:hover:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 52px;
  height: 52px;
  margin-top: -26px;
  margin-left: -26px;
  border-radius: 50%;
  background-color: #EFEFEF;
  z-index: -1; }

.Cal__Day__root.Cal__Day__enabled:hover:before {
  opacity: 0.5; }

.Cal__Day__root.Cal__Day__enabled.Cal__Day__highlighted:before, .Cal__Day__root.Cal__Day__enabled:active:before {
  opacity: 1; }

.Cal__Day__root:first-child {
  position: relative; }

.Cal__Day__root.Cal__Day__today {
  position: relative;
  z-index: 2; }

.Cal__Day__root.Cal__Day__today > span {
  color: #3d3d3d; }

.Cal__Day__root.Cal__Day__today.Cal__Day__disabled > span {
  color: #AAA; }

.Cal__Day__root.Cal__Day__today:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 52px;
  height: 52px;
  margin-top: -26px;
  margin-left: -26px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px;
  z-index: -1; }

.Cal__Day__root.Cal__Day__today.Cal__Day__disabled:before {
  box-shadow: inset 0 0 0 1px #BBB; }

.Cal__Day__root.Cal__Day__selected {
  position: relative; }

.Cal__Day__root.Cal__Day__selected > .Cal__Day__month, .Cal__Day__root.Cal__Day__selected > .Cal__Day__year {
  display: none; }

.Cal__Day__root.Cal__Day__selected:before {
  display: none; }

.Cal__Day__root.Cal__Day__selected .Cal__Day__selection {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 52px;
  height: 52px;
  margin-top: -26px;
  margin-left: -26px;
  border-radius: 50%;
  line-height: 56px;
  z-index: 2; }

.Cal__Day__root.Cal__Day__selected .Cal__Day__selection .Cal__Day__month {
  top: 9px; }

.Cal__Day__root.Cal__Day__selected .Cal__Day__selection .Cal__Day__day {
  position: relative;
  top: 5px;
  font-weight: bold;
  font-size: 18px; }

.Cal__Day__root.Cal__Day__disabled {
  color: #AAA;
  cursor: not-allowed; }

.Cal__Day__month, .Cal__Day__year {
  position: absolute;
  left: 0;
  right: 0;
  font-size: 12px;
  line-height: 12px;
  text-transform: capitalize; }

.Cal__Day__month {
  top: 5px; }

.Cal__Day__year {
  bottom: 5px; }

/*
 * Range selection styles
 */
.Cal__Day__range.Cal__Day__selected.Cal__Day__start:after, .Cal__Day__range.Cal__Day__selected.Cal__Day__end:after {
  content: '';
  position: absolute;
  top: 50%;
  width: 50%;
  height: 52px;
  margin-top: -26px;
  box-shadow: inset 56px 56px; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__disabled .Cal__Day__selection.Cal__Day__selection {
  background-color: #EEE !important; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__disabled .Cal__Day__selection.Cal__Day__selection .Cal__Day__day, .Cal__Day__range.Cal__Day__selected.Cal__Day__disabled .Cal__Day__selection.Cal__Day__selection .Cal__Day__month {
  color: #AAA;
  font-weight: 300; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__start .Cal__Day__selection {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__start:after {
  right: 0; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__start.Cal__Day__end:after {
  display: none; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__betweenRange .Cal__Day__selection {
  left: 0;
  right: 0;
  width: 100%;
  margin-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__betweenRange .Cal__Day__day {
  top: 0;
  font-size: 16px; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__betweenRange .Cal__Day__month {
  display: none; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__end:after {
  left: 0; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__end .Cal__Day__selection {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  color: inherit !important;
  background-color: #FFF !important;
  border: 2px solid;
  box-sizing: border-box; }

.Cal__Day__range.Cal__Day__selected.Cal__Day__end .Cal__Day__selection .Cal__Day__day {
  top: 4px; }

.Cal__Container__root {
  position: relative;
  display: table;
  z-index: 1;
  font: 14px 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.4em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  outline: none;
  text-align: left; }

.Cal__Container__root.Cal__Container__landscape {
  display: flex;
  flex-direction: row; }

.Cal__Container__root.Cal__Container__landscape .Cal__Container__wrapper {
  position: relative;
  flex-grow: 1;
  overflow: hidden;
  z-index: 1;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px; }

.Cal__Container__listWrapper {
  position: relative;
  overflow: hidden;
  background-color: #FFF; }

.Cal__Header__root {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  min-height: 98px;
  padding: 20px;
  line-height: 1.3;
  font-weight: 400;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }

.Cal__Header__root.Cal__Header__landscape {
  align-items: flex-start;
  min-width: 200px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 3px; }

.Cal__Header__root.Cal__Header__landscape .Cal__Header__dateWrapper.Cal__Header__day {
  flex-grow: 1;
  height: 76px; }

.Cal__Header__wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  cursor: pointer; }

.Cal__Header__wrapper.Cal__Header__blank {
  height: 58px;
  line-height: 58px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
  cursor: default; }

.Cal__Header__dateWrapper {
  position: relative;
  display: block;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.5);
  transition: color 0.3s ease; }

.Cal__Header__dateWrapper.Cal__Header__active {
  color: white; }

.Cal__Header__dateWrapper.Cal__Header__day {
  height: 38px;
  font-size: 36px;
  line-height: 36px;
  text-transform: capitalize; }

.Cal__Header__dateWrapper.Cal__Header__year {
  height: 20px;
  font-size: 18px;
  line-height: 18px; }

.Cal__Header__date {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.Cal__Header__range {
  display: flex;
  flex-grow: 1; }

.Cal__Header__range .Cal__Header__dateWrapper {
  overflow: visible; }

.Cal__Header__range .Cal__Header__wrapper:first-child:before, .Cal__Header__range .Cal__Header__wrapper:first-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 0;
  margin-top: -50px;
  margin-left: -50px;
  border-top: 100px solid transparent;
  border-bottom: 100px solid transparent;
  border-left: 60px solid; }

.Cal__Header__range .Cal__Header__wrapper:first-child:before {
  color: rgba(255, 255, 255, 0.15);
  -webkit-transform: translateX(1px);
          transform: translateX(1px); }

.Cal__Header__range .Cal__Header__wrapper:last-child {
  margin-left: 60px; }

.Cal__Header__range .Cal__Header__wrapper .Cal__Header__date {
  white-space: nowrap;
  z-index: 1; }

.Cal__Today__root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 6px;
  box-sizing: border-box;
  -webkit-transform: translate3d(0, -100%, 0);
          transform: translate3d(0, -100%, 0);
  font-weight: 500;
  line-height: 0;
  z-index: 10;
  cursor: pointer;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  transition-delay: 0.3s; }

.Cal__Today__root.Cal__Today__show {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  transition-delay: 0s; }

.Cal__Today__root.Cal__Today__show .Cal__Today__chevron {
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease; }

.Cal__Today__root .Cal__Today__chevron {
  position: absolute;
  top: 50%;
  margin-top: -6px;
  margin-left: 5px;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease; }

.Cal__Today__root.Cal__Today__chevronUp .Cal__Today__chevron {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.Cal__Today__root.Cal__Today__chevronDown .Cal__Today__chevron {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg); }

.Cal__MonthList__root {
  width: 100% !important;
  background-color: #FFF;
  -webkit-overflow-scrolling: touch; }

.Cal__MonthList__root.Cal__MonthList__scrolling > div {
  pointer-events: none; }

.Cal__MonthList__root.Cal__MonthList__scrolling label {
  opacity: 1; }

.Cal__Weekdays__root {
  position: relative;
  z-index: 5;
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.04); }

.Cal__Weekdays__day {
  padding: 15px 0;
  flex-basis: 14.28571%;
  flex-grow: 1;
  font-weight: 500;
  text-align: center; }

.Cal__Years__root {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F9F9F9; }

.Cal__Years__root:before, .Cal__Years__root:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 50px;
  pointer-events: none;
  z-index: 1; }

.Cal__Years__root:before {
  top: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%); }

.Cal__Years__root:after {
  bottom: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 100%); }

.Cal__Years__list {
  box-sizing: border-box; }

.Cal__Years__list.Cal__Years__center {
  display: flex;
  align-items: center;
  justify-content: center; }

.Cal__Years__year {
  display: flex;
  padding: 0 20px;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  box-sizing: border-box; }

.Cal__Years__year.Cal__Years__withMonths {
  border-bottom: 1px solid #E9E9E9; }

.Cal__Years__year.Cal__Years__withMonths label {
  height: 88px;
  padding-top: 12px;
  box-sizing: border-box; }

.Cal__Years__year label {
  flex-basis: 42%; }

.Cal__Years__year label span {
  flex-shrink: 0;
  color: #333; }

.Cal__Years__year ol {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  font-size: 14px; }

.Cal__Years__year ol li {
  display: flex;
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  list-style: none;
  border-radius: 50%;
  box-sizing: border-box;
  color: #444;
  font-weight: 400;
  text-transform: capitalize; }

.Cal__Years__year ol li.Cal__Years__currentMonth {
  border: 1px solid; }

.Cal__Years__year ol li.Cal__Years__selected {
  position: relative;
  z-index: 1;
  background-color: blue;
  color: #FFF !important;
  border: 0; }

.Cal__Years__year ol li.Cal__Years__disabled {
  cursor: not-allowed;
  color: #AAA; }

.Cal__Years__year ol li.Cal__Years__disabled:hover {
  background-color: inherit; }

.Cal__Years__year ol li:hover {
  background-color: #EFEFEF; }

.Cal__Years__year:hover label > span, .Cal__Years__year.Cal__Years__active label > span {
  color: inherit; }

.Cal__Years__year:hover, .Cal__Years__year.Cal__Years__active {
  position: relative;
  z-index: 2; }

.Cal__Years__year.Cal__Years__active {
  font-size: 32px; }

.Cal__Years__year.Cal__Years__currentYear {
  position: relative; }

.Cal__Years__year.Cal__Years__currentYear label > span {
  min-width: 50px;
  padding-bottom: 5px;
  border-bottom: 3px solid; }

.Cal__Years__year.Cal__Years__currentYear.Cal__Years__active label > span {
  min-width: 85px; }

.Cal__Years__year.Cal__Years__first {
  padding-top: 40px; }

.Cal__Years__year.Cal__Years__last {
  padding-bottom: 40px; }

.Cal__Animation__enter {
  opacity: 0;
  -webkit-transform: translate3d(0, -100%, 0);
          transform: translate3d(0, -100%, 0);
  transition: 0.25s ease; }

.Cal__Animation__enter.Cal__Animation__enterActive {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }

.Cal__Animation__leave {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  transition: 0.25s ease; }

.Cal__Animation__leave.Cal__Animation__leaveActive {
  opacity: 0;
  -webkit-transform: translate3d(0, 100%, 0);
          transform: translate3d(0, 100%, 0); }

.Cal__Slider__root, .Cal__Slider__slide {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.Cal__Slider__root {
  overflow: hidden; }

.Cal__Slider__slide {
  padding: 20px 65px; }

.Cal__Slider__slide:first-child {
  padding-left: 20px; }

.Cal__Slider__wrapper {
  height: 100%;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease; }

.Cal__Slider__arrow {
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 100%;
  opacity: 0.7;
  cursor: pointer;
  border-left: 1px solid rgba(255, 255, 255, 0.1); }

.Cal__Slider__arrow svg {
  width: 15px; }

.Cal__Slider__arrow:hover {
  opacity: 1; }

.Cal__Slider__arrowRight {
  right: 0; }

.Cal__Slider__arrowLeft {
  left: 0;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1); }

.Cal__transition__enter {
  opacity: 0; }

.Cal__transition__enterActive {
  opacity: 1;
  transition: opacity 0.3s ease; }

.Cal__transition__leave {
  opacity: 1; }

.Cal__transition__leaveActive {
  opacity: 0;
  transition: opacity 0.3s ease; }

.Cal__Month__rows {
  position: relative;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.05) 100%); }

.Cal__Month__row {
  padding: 0;
  margin: 0; }

.Cal__Month__row:first-child {
  text-align: right; }

.Cal__Month__row:first-child li {
  background-color: #FFF;
  box-shadow: 0 -1px 0 #E9E9E9; }

.Cal__Month__row:nth-child(2) {
  box-shadow: 0 -1px 0 #E9E9E9; }

.Cal__Month__row.Cal__Month__partial:first-child li:first-child {
  box-shadow: 0px -1px 0 #E9E9E9, inset 1px 0 0 #E9E9E9; }

.Cal__Month__row.Cal__Month__partial:last-of-type li {
  position: relative;
  z-index: 1; }

.Cal__Month__label {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  font-size: 30px;
  font-weight: 500;
  z-index: 3;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.6);
  opacity: 0;
  transition: opacity 0.3s ease; }

.Cal__Month__label > span {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize; }

.Cal__Month__label.Cal__Month__partialFirstRow {
  top: 56px; }

/* Font Smoothing */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
td,
button,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.display-1,
.display-2,
.display-3,
.display-4,
.navbar,
.brand,
.alert {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: unset;
  font-family: "Poppins", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 400; }

.cr-app {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto; }

.clearfix {
  clear: both; }

.btn {
  border-radius: 0px !important; }

body .form-control {
  border-radius: 0px !important; }

.navbar-nav .cr-search-form .form-control {
  background: #242529;
  color: #dddddd;
  border-radius: 4px !important; }
  .navbar-nav .cr-search-form .form-control:focus {
    box-shadow: none !important;
    border-bottom: 1px solid #4f7be9 !important; }
  .navbar-nav .cr-search-form .form-control.cr-search-form__input {
    min-width: 232px;
    width: 290px;
    height: 32px;
    border: 1px solid #32333a; }
    .navbar-nav .cr-search-form .form-control.cr-search-form__input::-webkit-input-placeholder {
      color: #6b7883;
      font-size: 12px; }
    .navbar-nav .cr-search-form .form-control.cr-search-form__input::placeholder {
      color: #6b7883;
      font-size: 12px; }
  .navbar-nav .cr-search-form .form-control:hover.cr-search-form__input::-webkit-input-placeholder {
    color: #dddddd; }
  .navbar-nav .cr-search-form .form-control:hover.cr-search-form__input::placeholder {
    color: #dddddd; }
  .navbar-nav .cr-search-form .form-control.cr-search-form__input::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 8px;
    width: 8px;
    background-image: url(/app/static/media/close.4f511af3.svg);
    cursor: pointer; }
    .navbar-nav .cr-search-form .form-control.cr-search-form__input::-webkit-search-cancel-button:hover {
      background-image: url(/app/static/media/closehover.112a8845.svg); }

.navbar-nav .cr-search-form .cr-search-form__icon-search {
  color: #6b7883; }

.navbar-nav .cr-search-form:hover .cr-search-form__icon-search {
  color: #dddddd !important; }

.navbar-nav .cr-search-form:active .cr-search-form__icon-search {
  color: #dddddd !important; }

.navbar-nav.searchbar-focused .cr-search-form .form-control.cr-search-form__input {
  background-color: #292a2f;
  line-height: 0; }
  .navbar-nav.searchbar-focused .cr-search-form .form-control.cr-search-form__input::-webkit-input-placeholder {
    color: #dddddd !important;
    font-size: 12px;
    line-height: 0; }
  .navbar-nav.searchbar-focused .cr-search-form .form-control.cr-search-form__input::placeholder {
    color: #dddddd !important;
    font-size: 12px;
    line-height: 0; }

.nav-item .nav-link {
  display: flex;
  align-items: center; }
  .nav-item .nav-link.active {
    color: #dddddd;
    background: rgba(255, 255, 255, 0.23);
    opacity: 1; }

.sidebar .nav a {
  transition: all 150ms ease-in; }

.card-header {
  text-transform: uppercase;
  background-color: #1b1b1d; }

.badge:empty {
  display: inline-block; }

.list-item {
  list-style-type: none;
  border-radius: 5px;
  background: #25272e;
  margin: 20px 0;
  padding: 2px 6px; }

.track {
  margin-left: -40px; }

.track-list-item {
  list-style-type: none; }

.breadcrumb {
  background-color: transparent; }

.breadcrumb-item {
  font-size: 0.8rem; }

.accordion-question {
  line-height: 16px;
  margin-bottom: 0;
  color: #fff;
  padding: 10px;
  background-color: #212226;
  border-radius: 5px; }

.hidden-card {
  background: transparent;
  border: none; }

.search-heading {
  font-size: 1.1rem;
  margin-top: 15px;
  text-transform: capitalize;
  color: #dddddd !important; }
  .search-heading .zd-cards.zd-artist-card {
    margin-bottom: 0 !important; }

.sub-genre-packs {
  margin: 15px 0 0 15px;
  width: 300px;
  height: 100px;
  border-radius: 10px;
  transition: all 0.1s ease; }

.sub-genre-ul {
  padding: 5px; }

.sub-genre-pack-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  cursor: pointer; }

.sub-genre-packs:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px); }

.dj-chart-list-item {
  width: 100%;
  display: flex;
  list-style: none;
  border-bottom: 1px solid #2c2d31; }
  .dj-chart-list-item:hover {
    background-color: #33343a; }

.left-list {
  align-self: center;
  padding-right: 15px; }

.center-list {
  flex-grow: 1;
  text-align: left;
  max-width: 180px; }

.download-center-list {
  flex-grow: 1;
  align-self: center;
  text-align: left; }

.sub-genre-release-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.right-list {
  align-self: center; }

.sub-genre-track-text {
  padding-bottom: 5px;
  font-size: 0.7rem; }

.tab-head {
  padding: 0 15px 5px 0;
  cursor: pointer; }
  .tab-head:hover {
    border-color: transparent !important; }

.tab-active .tab-text {
  border-bottom: 2px solid #4d7ce8; }

.tab-container {
  border: none; }

.new-releases-card {
  border-radius: 10px !important;
  transition: all 0.2s ease !important;
  height: 100%; }
  .new-releases-card:hover {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px); }
  .new-releases-card .release-name {
    height: 100%; }
  .new-releases-card .release-image {
    height: 100%; }

.genre-item {
  list-style-type: none;
  background: #25272e;
  border-radius: 5px;
  padding: 2px 0; }
  .genre-item .subgenre-item {
    display: inline-block;
    /* border-radius: 6px; */
    color: #ddd;
    padding: 3px 10px;
    border-right: 1px solid #4d4d4a;
    cursor: pointer; }
  .genre-item .subgenre-list {
    padding: 3px 10px;
    border-right: 1px solid white; }
    .genre-item .subgenre-list a {
      color: white; }

.genretag-item {
  list-style-type: none;
  background: #25272e;
  border-radius: 5px;
  padding: 2px 6px; }
  .genretag-item .tag-item {
    display: inline-block;
    /*color: #9ac2ff;*/
    color: #7685b3;
    padding: 3px 10px;
    /*border: 1px solid;
    border-radius: 50px;*/
    border-radius: 0px;
    background: #2a3043;
    margin: 0.3rem 0.2rem;
    cursor: pointer; }
  .genretag-item .subgenre-list {
    padding: 3px 10px;
    border-right: 1px solid white; }
    .genretag-item .subgenre-list a {
      color: white; }

.pagination-lg .page-link {
  padding: 5px 10px;
  font-size: 13px;
  line-height: 20px; }
  .pagination-lg .page-link:hover {
    color: #dddddd !important; }

.pagination {
  padding-top: 12px !important; }
  .pagination .transparent {
    background-color: transparent !important; }
  .pagination .goto-text {
    width: 60px !important;
    height: 30px !important;
    padding: 1px 4px;
    background-color: #2e2e2e;
    color: #b2b2b2;
    border: 0.1rem solid #2e2e2e;
    border-radius: 0.2rem; }

.zd-revert-classic {
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end; }
  .zd-revert-classic-message {
    padding-right: 20px;
    display: flex;
    align-items: center; }
    .zd-revert-classic-message:hover {
      text-decoration: none;
      color: white !important; }

.zd-processing {
  opacity: 0.7; }

.genre-separator:after {
  content: '';
  display: block;
  margin: 0;
  width: 100%;
  padding-top: 20px;
  border-bottom: 1px solid #373737; }

.genre-view-name {
  color: #c8c8c8;
  font-size: 17px !important; }

.zd-genre-view-genre {
  width: 95% !important; }

.zd-genre-view-genre-child {
  width: 75% !important; }

.zd-all-genres {
  margin-left: 5px !important; }
  .zd-all-genres:first-child {
    margin-left: 0px !important; }

.zd-genre-view-title {
  width: 65% !important; }

.zd-playing-row tbody tr {
  background-color: #303139 !important; }

.zd-genre-heading {
  color: #7483b0 !important; }

.search-genre {
  z-index: 999; }
  @media (max-width: 1224px) {
    .search-genre {
      margin-left: 30%; } }

.zd-revert-classic-message {
  color: #7685b3 !important; }

.zd-revert-classic-message:hover,
.zd-genre-heading:hover {
  color: #acbdf3 !important; }

.zd-clear-btn {
  background-color: #2a3043; }

.zd-white-font {
  color: #eaeaea; }

@font-face {
  font-family: icomoon;
  src: url(/app/static/media/icomoon.c7494e30.eot);
  src: url(/app/static/media/icomoon.c7494e30.eot#iefix) format("embedded-opentype"), url(/app/static/media/icomoon.8a344c59.ttf) format("truetype"), url(/app/static/media/icomoon.4b9bb1ac.woff) format("woff"), url(/app/static/media/icomoon.f8481e5b.svg#icomoon) format("svg");
  font-weight: 400;
  font-style: normal; }

[class*=" ico-"], [class^=ico-] {
  font-family: icomoon !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.ico-music-16:before {
  content: "\E900"; }

.ico-music-15:before {
  content: "\E901"; }

.ico-commerce:before {
  content: "\E902"; }

.ico-shopping:before {
  content: "\E903"; }

.ico-new:before {
  content: "\E904"; }

.ico-music-14:before {
  content: "\E905"; }

.ico-multimedia:before {
  content: "\E906"; }

.ico-music-13:before {
  content: "\E907"; }

.ico-music-12:before {
  content: "\E908"; }

.ico-music-11:before {
  content: "\E909"; }

.ico-music-10:before {
  content: "\E90A"; }

.ico-music-9:before {
  content: "\E90B"; }

.ico-music-8:before {
  content: "\E90C"; }

.ico-music-7:before {
  content: "\E90D"; }

.ico-music-6:before {
  content: "\E90E"; }

.ico-music-5:before {
  content: "\E90F"; }

.ico-music-4:before {
  content: "\E910"; }

.ico-music-3:before {
  content: "\E911"; }

.ico-music-2:before {
  content: "\E912"; }

.ico-music-1:before {
  content: "\E913"; }

.ico-music:before {
  content: "\E914"; }

.ico-users:before {
  content: "\E915"; }

.ico-user-1:before {
  content: "\E916"; }

.ico-user:before {
  content: "\E917"; }

.ico-avatar:before {
  content: "\E918"; }

.ico-mail:before {
  content: "\E919"; }

.ico-envelope:before {
  content: "\E91A"; }

.ico-music-player:before {
  content: "\E91B"; }

.ico-alarm-1:before {
  content: "\E91C"; }

.ico-alarm:before {
  content: "\E91D"; }

.ico-speedometer:before {
  content: "\E91E"; }

.ico-dashboard:before {
  content: "\E91F"; }

.ico-arrow-download:before {
  content: "\E920"; }

.ico-keyboard:before {
  content: "\E921"; }

.ico-playlist:before {
  content: "\E922"; }

.ico-add:before {
  content: "\E923"; }

.ico-comment:before {
  content: "\E924"; }

.ico-delete:before {
  content: "\E925"; }

.ico-downloads:before {
  content: "\E926"; }

.ico-drop-menu-down .path1:before {
  content: "\E927";
  color: #fff; }

.ico-drop-menu-down .path2:before {
  content: "\E928";
  margin-left: -1em;
  color: #000; }

.ico-favorite:before {
  content: "\E929"; }

.ico-flag:before {
  content: "\E92A"; }

.ico-info:before {
  content: "\E92B"; }

.ico-maximize:before {
  content: "\E92C"; }

.ico-maximize-single:before {
  content: "\E92D"; }

.ico-new2:before {
  content: "\E92E"; }

.ico-pagination-left:before {
  content: "\E92F"; }

.ico-pagination-right:before {
  content: "\E930"; }

.ico-play-music:before {
  content: "\E931"; }

.ico-repeat-music:before {
  content: "\E932"; }

.ico-tag:before {
  content: "\E933"; }

.ico-arrow-down:before {
  content: "\E934"; }

.ico-arrow-left:before {
  content: "\E935"; }

.ico-arrow-right:before {
  content: "\E936"; }

.ico-arrow-up:before {
  content: "\E937"; }

.ico-music-mac:before {
  content: "\E938"; }

.ico-mute:before {
  content: "\E939"; }

.ico-pause:before {
  content: "\E93A"; }

.ico-play:before {
  content: "\E93B"; }

.ico-playlist2:before {
  content: "\E93C"; }

.ico-playlist-option:before {
  content: "\E93D"; }

.ico-playlist-repeat:before {
  content: "\E93E"; }

.ico-playlist-shuffle:before {
  content: "\E93F"; }

.ico-volume-full:before {
  content: "\E940"; }

.ico-volume-low:before {
  content: "\E941"; }

.ico-download-manager:before {
  content: "\E942"; }

.ico-labels:before {
  content: "\E943"; }

.ico-menu:before {
  content: "\E944"; }

.ico-music-charts:before {
  content: "\E945"; }

.ico-new-releases:before {
  content: "\E946"; }

.ico-radar:before {
  content: "\E947"; }

.ico-release-archive:before {
  content: "\E948"; }

.ico-top-downloads:before {
  content: "\E949"; }

.ico-zipdj-packs:before {
  content: "\E94A"; }

.ico-download-controller:before {
  content: "\E94B"; }

.ico-search:before {
  content: "\E94C"; }

@font-face {
  font-family: "zipdj";
  src: url(/app/static/media/zipdj.6a6d54ec.eot);
  src: url(/app/static/media/zipdj.6a6d54ec.eot?#iefix) format("embedded-opentype"), url(/app/static/media/zipdj.ebd492df.woff) format("woff"), url(/app/static/media/zipdj.77b1a928.ttf) format("truetype"), url(/app/static/media/zipdj.9df3a7be.svg#zipdj) format("svg");
  font-weight: normal;
  font-style: normal; }

[class*=" zd-icon-"], [class^=zd-icon-] {
  font-family: "zipdj" !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased; }

[data-icon]:before {
  font-family: "zipdj" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  -webkit-font-feature-settings: normal !important;
          font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="zd-icon-"]:before,
[class*=" zd-icon-"]:before {
  font-family: "zipdj" !important;
  font-style: normal !important;
  font-weight: normal !important;
  -webkit-font-feature-settings: normal !important;
          font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.zd-icon-menu:before {
  content: "\E944"; }

.zd-icon-next-track:before {
  content: "\E902";
  color: #fff; }

.zd-icon-prev-track:before {
  content: "\E903";
  color: #fff; }

.zd-icon-maximize-single:before {
  content: "a"; }

.zd-icon-shuffle:before {
  content: "b"; }

.zd-icon-list-thumbnails:before {
  content: "c"; }

.zd-icon-list-number:before {
  content: "d"; }

.zd-icon-pause:before {
  content: "e"; }

.zd-icon-loop:before {
  content: "f"; }

.zd-icon-download:before {
  content: "g"; }

.zd-icon-loop-1:before {
  content: "h"; }

.zd-icon-shuffle-1:before {
  content: "i"; }

.zd-icon-download-1:before {
  content: "j"; }

.zd-icon-download-2:before {
  content: "N"; }

.zd-icon-dropbox:before {
  content: "k"; }

.zd-icon-android-sync:before {
  content: "l"; }

.zd-icon-001-interface:before {
  content: "m"; }

.zd-icon-002-download-arrow:before {
  content: "n"; }

.zd-icon-003-download-button:before {
  content: "o"; }

.zd-icon-downloadmanager:before {
  content: "p"; }

.zd-icon-downloadmanager-1:before {
  content: "r"; }

.zd-icon-android-bulb:before {
  content: "q"; }

.zd-icon-facebook:before {
  content: "s"; }

.zd-icon-linkedin:before {
  content: "t"; }

.zd-icon-twitter-alt:before {
  content: "u"; }

.zd-icon-ios-trash-outline:before {
  content: "v"; }

.zd-icon-002-rubbish-bin:before {
  content: "w"; }

.zd-icon-001-draw:before {
  content: "x"; }

.zd-icon-zipdjpacks:before {
  content: "y"; }

.zd-icon-favourite:before {
  content: "z"; }

.zd-icon-info:before {
  content: "A"; }

.zd-icon-labels:before {
  content: "B"; }

.zd-icon-new-releases:before {
  content: "C"; }

.zd-icon-pauseicon:before {
  content: "D"; }

.zd-icon-playlist:before {
  content: "E"; }

.zd-icon-print:before {
  content: "F"; }

.zd-icon-radar:before {
  content: "G"; }

.zd-icon-release-archive:before {
  content: "H"; }

.zd-icon-shuffle-2:before {
  content: "I"; }

.zd-icon-tag:before {
  content: "J"; }

.zd-icon-top-downloads:before {
  content: "K"; }

.zd-icon-downloadicon:before {
  content: "L"; }

.zd-icon-download-manager:before {
  content: "M"; }

.zd-icon-download-2:before {
  content: "N"; }

.zd-icon-delete:before {
  content: "O"; }

.zd-icon-delete-2:before {
  content: "\2715"; }

.zd-icon-comment:before {
  content: "P"; }

.zd-icon-charts:before {
  content: "Q"; }

.zd-icon-angle-down:before {
  content: "R";
  color: #fff; }

.genre_title .zd-icon-angle-down:before {
  color: #507de5; }

.zd-icon-angle-left:before {
  content: "S"; }

.zd-icon-angle-right:before {
  content: "T"; }

.zd-icon-angle-up:before {
  content: "U"; }

.zd-icon-refresh:before,
.zd-icon-repeat-track:before {
  content: "V"; }

.zd-icon-pause-1:before {
  content: "W"; }

.zd-icon-play:before {
  content: "X"; }

.zd-icon-playback-play:before {
  content: "Y"; }

.zd-icon-playback-pause:before {
  content: "Z"; }

.zd-icon-genres:before {
  content: "\E900"; }

.zd-icon-step-backward:before {
  content: "1"; }

.zd-icon-step-forward:before {
  content: "2"; }

.zd-icon-jump-right:before {
  content: "3"; }

.zd-icon-jump-left:before {
  content: "4"; }

@font-face {
  font-family: 'zd-player-icon';
  src: url(/app/static/media/zd-player-icon.cb1000f1.eot);
  src: url(/app/static/media/zd-player-icon.cb1000f1.eot#iefix) format("embedded-opentype"), url(/app/static/media/zd-player-icon.2024b045.ttf) format("truetype"), url(/app/static/media/zd-player-icon.90d6ad52.woff) format("woff"), url(/app/static/media/zd-player-icon.762e1783.svg#zd-player-icon) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^='zd-player-icon-'],
[class*=' zd-player-icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'zd-player-icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.zd-player-icon-volume:before {
  content: '\E900';
  color: #fff; }

.zd-player-icon-skip-previous:before {
  content: '\E901';
  color: #fff; }

.zd-player-icon-skip-next:before {
  content: '\E902';
  color: #fff; }

.zd-player-icon-shuffle-tracks:before {
  content: '\E903';
  color: #fff; }

.zd-player-icon-repeat-track:before {
  content: '\E904';
  color: #fff; }

.zd-player-icon-playlist-add:before {
  content: '\E905';
  color: #fff; }

.zd-player-icon-keyboard:before {
  content: '\E906';
  color: #fff; }

.zd-player-icon-play:before {
  content: '\E907';
  color: #fff; }

.zd-player-icon-pause:before {
  content: '\E908';
  color: #fff; }

.zd-player-icon-download:before {
  content: '\E909';
  color: #6b7883; }

.zd-player-icon-download-newhomepage:before {
  content: "\E909";
  color: #575F66; }

.zd-player-feedback-icon-download:before {
  content: '\E909';
  color: #757372 !important; }

* {
  --megaWidth: calc(100vw - 54px); }

.zd-dropdown {
  font-weight: 500;
  line-height: 1.2rem !important;
  border-color: transparent;
  border-radius: 0px !important;
  letter-spacing: 0.04rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Poppins'; }
  .zd-dropdown:hover, .zd-dropdown:focus, .zd-dropdown:active {
    border-color: transparent !important;
    box-shadow: none !important;
    color: #dddddd; }
  .zd-dropdown .zip-angle-down::after {
    content: 'R';
    color: #dddddd;
    font-family: 'zipdj' !important;
    font-style: normal !important;
    font-weight: normal !important;
    -webkit-font-feature-settings: normal !important;
            font-feature-settings: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #7685b3 !important;
    border: none;
    font-size: 20px; }

.zd-default-list.text-uppercase .dropdown-item {
  text-transform: uppercase; }

.dropdown-menu {
  background: #242529;
  border: none;
  color: #6b7883;
  border-radius: 0px !important;
  min-width: 100%; }
  .dropdown-menu .dropdown-item {
    color: #6b7883;
    padding-left: 12px !important;
    height: 32px;
    border-radius: 2px;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer; }
    .dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item:focus, .dropdown-menu .dropdown-item:active {
      background: #28292e;
      color: #dddddd;
      outline: none; }

.zd-dropdown-crate .crate-icon {
  width: 15px; }

.zd-dropdown-crate .dropdown-menu {
  background: #2e3035 !important;
  border: none;
  color: #6b7883;
  border-radius: 0px !important;
  min-width: 100%;
  padding: 0;
  margin: 0; }
  .zd-dropdown-crate .dropdown-menu .dropdown-item {
    color: #6b7883;
    padding-left: 12px !important;
    height: 32px;
    border-radius: 2px;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer; }
    .zd-dropdown-crate .dropdown-menu .dropdown-item:hover, .zd-dropdown-crate .dropdown-menu .dropdown-item:focus, .zd-dropdown-crate .dropdown-menu .dropdown-item:active {
      background: #28292e;
      color: #dddddd;
      outline: none; }
      .zd-dropdown-crate .dropdown-menu .dropdown-item:hover i, .zd-dropdown-crate .dropdown-menu .dropdown-item:focus i, .zd-dropdown-crate .dropdown-menu .dropdown-item:active i {
        color: #dddddd; }
  .zd-dropdown-crate .dropdown-menu .dropdown-divider {
    margin: 0;
    border-top: 1px solid #1f2024; }

.zd-nested-dropdown .all-packs {
  font-weight: 500 !important; }

.zd-key-title {
  margin-left: 17px !important; }

.zd-key-drop-menu {
  width: 196px;
  height: 447px;
  -webkit-transform: translate3d(-123px, 37px, 0px) !important;
          transform: translate3d(-123px, 37px, 0px) !important; }
  .zd-key-drop-menu .form-checkbox {
    padding: 7px 2px; }
  .zd-key-drop-menu .form-icon-pre {
    margin: 6px 16px; }
  .zd-key-drop-menu .right_col {
    border-left: 1px solid #242529; }
  .zd-key-drop-menu .zd-key-reset {
    color: #6b7883 !important;
    padding-right: 8px; }
  .zd-key-drop-menu .zd-label-left {
    padding-left: 36px;
    width: 100%;
    border-radius: 2px;
    margin-bottom: 0 !important; }
    .zd-key-drop-menu .zd-label-left:hover {
      background-color: #28292e !important; }
  .zd-key-drop-menu .zd-icon-left {
    margin-left: 17px !important; }

.zd-genre-filter-drop-menu {
  width: 306px;
  height: 447px;
  -webkit-transform: translate3d(-191px, 37px, 0px) !important;
          transform: translate3d(-191px, 37px, 0px) !important;
  background-color: #191b1f;
  border: 1px solid #32333a; }
  @media (max-width: 750px) {
    .zd-genre-filter-drop-menu {
      -webkit-transform: translate3d(0px, 38px, 0px) !important;
              transform: translate3d(0px, 38px, 0px) !important; } }

.zd-genre-packs-drop {
  background: #191b1f !important;
  border-radius: 4px;
  border: 1px solid #32333a;
  border: 1px solid var(--Secondary-Border, #32333a);
  background: #191b1f;
  background: var(--Secondary-Background, #191b1f); }

.zd-primary-dropdown {
  color: #7685b3;
  background: #25262a;
  font-weight: 600;
  font-family: 'Poppins', 'Roboto', sans-serif;
  color: #6b7883; }
  .zd-primary-dropdown:hover {
    color: #dddddd !important; }
    .zd-primary-dropdown:hover .zip-angle-down::after {
      color: #dddddd !important; }
    .zd-primary-dropdown:hover .chart-genre-text {
      color: #dddddd !important; }

.zd-all-packs-filter-button {
  margin-right: 32px !important; }
  .zd-all-packs-filter-button .zd-primary-dropdown {
    max-width: 158px !important;
    width: 158px !important; }
    .zd-all-packs-filter-button .zd-primary-dropdown .right-icon {
      font-size: 10px !important; }

.zd-primary-dropdown-disabled {
  color: #374571;
  background: #2a3143;
  font-weight: 900; }
  .zd-primary-dropdown-disabled:hover, .zd-primary-dropdown-disabled:focus {
    background: #23262d !important; }
  .zd-primary-dropdown-disabled .zip-angle-down {
    color: #374571 !important; }
    .zd-primary-dropdown-disabled .zip-angle-down::after {
      color: #374571 !important; }

.zd-secondary-dropdown {
  color: #6b7883;
  background: #25262a;
  font-size: 12px;
  font-weight: 500;
  font-style: normal; }
  .zd-secondary-dropdown:hover, .zd-secondary-dropdown:focus {
    background: #25262a !important; }

@media (max-width: 750px) {
  .zd_v5_top_filter {
    display: none; } }

.zd-dropdown.zd-text-ellipsis {
  height: 100%; }
  .zd-dropdown.zd-text-ellipsis .zip-angle-down {
    top: 9px;
    right: 10px;
    font-size: 12px; }

.zd-nested-dropdown {
  height: 100% !important;
  display: flex;
  align-items: center; }
  .zd-nested-dropdown .zd-primary-dropdown .zip-angle-down {
    top: 17px !important; }

.zd-nested-dropdown .zd-primary-dropdown,
.zd-nested-dropdown .zd-search-genre {
  line-height: 1rem !important;
  border: none; }
  .zd-nested-dropdown .zd-primary-dropdown .zdv3-icon-genre-filter,
  .zd-nested-dropdown .zd-search-genre .zdv3-icon-genre-filter {
    font-size: 14px;
    padding-right: 9px;
    padding-left: 4px; }
  @media (min-width: 1224px) {
    .zd-nested-dropdown .zd-primary-dropdown,
    .zd-nested-dropdown .zd-search-genre {
      max-width: 150px;
      max-height: 32px;
      height: 32px;
      border-top-left-radius: 2px !important;
      border-bottom-left-radius: 2px !important; } }
  @media (max-width: 1224px) {
    .zd-nested-dropdown .zd-primary-dropdown,
    .zd-nested-dropdown .zd-search-genre {
      padding: 6px 7px;
      width: 150px;
      border-top-left-radius: 2px !important;
      border-bottom-left-radius: 2px !important; } }
  .zd-nested-dropdown .zd-primary-dropdown .zip-angle-down,
  .zd-nested-dropdown .zd-search-genre .zip-angle-down {
    top: 6px !important;
    right: 6px !important; }
  @media (max-width: 750px) {
    .zd-nested-dropdown .zd-primary-dropdown,
    .zd-nested-dropdown .zd-search-genre {
      position: fixed;
      height: 36px;
      background: #25262a;
      border: 1px solid #32333a !important;
      border: 1px solid var(--Secondary-Border, #32333a) !important;
      border-radius: 4px !important;
      width: 36px !important;
      right: 85px; }
      .zd-nested-dropdown .zd-primary-dropdown svg path,
      .zd-nested-dropdown .zd-search-genre svg path {
        fill: #4d7ce8 !important; } }
  @media (min-width: 1224px) {
    .zd-nested-dropdown .zd-primary-dropdown .zdv3-icon-drop-down,
    .zd-nested-dropdown .zd-primary-dropdown .zdv3-icon-up-arrow,
    .zd-nested-dropdown .zd-search-genre .zdv3-icon-drop-down,
    .zd-nested-dropdown .zd-search-genre .zdv3-icon-up-arrow {
      padding-right: 9px;
      padding-left: 17px; } }

.zd-secondary-header .zd-nested-dropdown .zd-dropdown {
  border-radius: 0 !important; }

.zd-secondary-header .zd-nested-dropdown .zd-parent-menu {
  margin-top: -1px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 0;
  height: calc(100vh - 150px); }
  .zd-secondary-header .zd-nested-dropdown .zd-parent-menu .dropdown-item {
    padding: 1px 5px; }

.zd-header-search-wrap.w-50 {
  width: 240px !important; }
  @media (min-width: 1224px) {
    .zd-header-search-wrap.w-50 {
      margin-right: 24px; } }
  @media (min-width: 750px) {
    .zd-header-search-wrap.w-50 {
      margin-right: 85px; } }

.zd-logo-margin {
  margin-right: auto; }

.zd-bpm-dropdown {
  -webkit-transform: translate3d(-240px, 37px, 0px) !important;
          transform: translate3d(-240px, 37px, 0px) !important;
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */ }
  @media (max-width: 750px) {
    .zd-bpm-dropdown {
      -webkit-transform: translate3d(-150px, 38px, 0px) !important;
              transform: translate3d(-150px, 38px, 0px) !important; } }
  .zd-bpm-dropdown input::-webkit-outer-spin-button,
  .zd-bpm-dropdown input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .zd-bpm-dropdown input[type='number'] {
    -moz-appearance: textfield; }

.key-btn-clr {
  background-color: transparent !important; }

.btn-drop:hover {
  background-color: transparent !important; }

/* Mega Menu Style */
.zd-megagenre-parent::-webkit-scrollbar {
  display: block; }

body .zd-megagenre-parent {
  border-radius: 0;
  top: 64px !important;
  padding-bottom: 0 !important;
  color: #fcfefd;
  background: #1b1c20;
  position: fixed;
  width: calc(100vw - 56px) !important;
  height: calc(100vh - 41px);
  left: 56px;
  overflow-y: scroll; }
  @media (max-width: 1224px) {
    body .zd-megagenre-parent {
      overflow: hidden;
      top: 64px !important;
      left: 56px !important; } }
  @media (max-width: 750px) {
    body .zd-megagenre-parent {
      overflow-y: hidden !important;
      height: calc(100vh - 48px) !important;
      top: 48px !important;
      width: 100vw !important;
      left: 0 !important; } }
  body .zd-megagenre-parent .zd-megamenu-row {
    height: calc(100vh - 56px); }
    @media (max-width: 1300px) {
      body .zd-megagenre-parent .zd-megamenu-row {
        max-width: 1150px; } }
  body .zd-megagenre-parent [class^='col-'] {
    padding: 0;
    margin-bottom: 0; }
  body .zd-megagenre-parent .zd-megagenre-house {
    padding-left: 15px;
    padding-right: 30px; }
  body .zd-megagenre-parent .zd-megagenre-house .list-group .zd-megagenre-innre-ul {
    display: flex;
    flex-flow: wrap column;
    max-height: 500px;
    width: 50%; }
  body .zd-megagenre-parent .zd-megagenre-house {
    border-left: 1px solid #2d3133;
    border-right: 1px solid #2d3133; }
  body .zd-megagenre-parent .urban-section,
  body .zd-megagenre-parent .latin-section,
  body .zd-megagenre-parent .country-section {
    padding-left: 20px;
    padding-bottom: 10px; }
  body .zd-megagenre-parent .classics-section {
    border-top: 1px solid #2d3133;
    padding-top: 10px;
    height: 100%; }
  body .zd-megagenre-parent .country-section {
    border-top: 1px solid #2d3133;
    padding-top: 10px;
    height: 100%; }
  body .zd-megagenre-parent .latin-section {
    border-bottom: 1px solid #2d3133;
    padding-bottom: 10px; }
  body .zd-megagenre-parent .megamenu-logo-section {
    height: 250px; }
    body .zd-megagenre-parent .megamenu-logo-section img {
      height: 225px; }
  body .zd-megagenre-parent .megamenu-logo-icon {
    height: 30px !important; }
  body .zd-megagenre-parent .zd-megamenu-genre-title-a {
    font-size: 16px;
    font-weight: 600;
    color: #eaeaea !important; }
  body .zd-megagenre-parent .zd-megamenu-genre-title-a.zd-megamenu-tag-modal {
    color: #4f7be9 !important; }
  body .zd-megagenre-parent .zd-megamenu-subgenre-title-a {
    font-size: 13px;
    color: #eaeaea !important; }
  body .zd-megagenre-parent .zd-megamenu-tag-title-a {
    background-color: #2c3343;
    color: #eaeaea !important;
    padding: 3px 8px;
    border-radius: 0px !important;
    margin-left: 10px;
    margin-top: 0;
    display: inline-block;
    font-size: 11px; }
  body .zd-megagenre-parent .list-group-item {
    border: none !important;
    padding: 5px 0px 5px 18px !important;
    background-color: transparent !important; }
  body .zd-megagenre-parent .list-group {
    padding-top: 2px !important; }
    body .zd-megagenre-parent .list-group a:hover {
      opacity: 0.7; }
  body .zd-megagenre-parent .zd-megamenu-subgenre-title-a:hover,
  body .zd-megagenre-parent .zd-megamenu-genre-title-a:hover,
  body .zd-megagenre-parent .zd-megamenu-tag-title-a:hover {
    color: #dddddd;
    outline: none;
    text-decoration: underline; }
  body .zd-megagenre-parent .zd-megamenu-tag-title-a h3 span {
    cursor: pointer; }

body.player-active .zd-megagenre-parent {
  margin-bottom: 56px;
  height: calc(100vh - 118px);
  overflow-x: hidden; }

.zd-tertiary-header .zd-nested-dropdown .zd-primary-dropdown .zip-angle-down {
  top: 8px !important; }

@media (max-width: 750px) {
  .zd-tertiary-header .zd-nested-dropdown .zd-primary-dropdown {
    margin-left: 25px; } }

.close-Text-Mega {
  color: #4f7be9 !important;
  position: relative;
  bottom: 3px; }

#megamenuclose {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000; }
  #megamenuclose i {
    color: #4f7be9 !important; }
  @media (max-width: 1224px) {
    #megamenuclose svg {
      stroke-width: 70 !important; } }
  @media (max-width: 1224px) {
    #megamenuclose {
      top: 18px;
      right: 12px; } }
  @media (max-width: 750px) {
    #megamenuclose {
      top: 12px;
      right: 11px;
      z-index: 1010; } }

@media (max-width: 750px) {
  .zd-search-open .zd-search-genre {
    display: none !important; } }

@media (max-width: 750px) {
  #zd-lg-filter {
    display: none !important; } }

@media (min-width: 1224px) {
  #zd-sm-filter {
    display: none; } }

body.player-active .zd-megagenre-parent .zd-megamenu-row {
  height: calc(100vh - 140px); }

.body-overflow-hidden .zdv3-icon-drop-down {
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

@media (min-width: 750px) {
  .body-overflow-hidden .zd-mega-dropdown {
    color: #ddd !important;
    background-color: #4f7be9; }
    .body-overflow-hidden .zd-mega-dropdown:hover {
      color: #ddd !important;
      background-color: #2d5dc8; } }

#search-genres .filter-applied {
  color: #ddd !important; }

#search-genres .zd-search-genre:hover .filter-applied {
  color: #ddd !important; }

#search-genres .zdv3-icon-reset {
  font-size: 16px;
  align-self: center; }
  @media (max-width: 750px) {
    #search-genres .zdv3-icon-reset {
      margin-right: 24px !important; } }
  #search-genres .zdv3-icon-reset:hover {
    color: #ddd; }

.show .genre-preference-menu {
  display: grid;
  grid-template-columns: auto auto;
  margin-top: 5px;
  grid-gap: 4px;
  padding: 6px; }
  .show .genre-preference-menu .dropdown-item {
    border: 1px solid;
    padding-right: 6px; }

.zd-history-drop-down {
  top: -4px !important;
  border-radius: 2px !important; }
  .zd-history-drop-down .zd-key-reset {
    cursor: pointer; }
  .zd-history-drop-down .zd-bpm-alert {
    background: none;
    padding-left: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    color: #4f7be9; }
  .zd-history-drop-down .row {
    flex-wrap: inherit !important; }
    .zd-history-drop-down .row .left_col {
      padding-right: 0; }
      .zd-history-drop-down .row .left_col div {
        color: #4f7be9; }
      .zd-history-drop-down .row .left_col .form-checkbox:hover {
        background-color: #28292e; }
    .zd-history-drop-down .row .right_col {
      padding-left: 0; }
      .zd-history-drop-down .row .right_col div {
        color: #4f7be9; }

.zdv3-icon-filled-downWard-arrow:before {
  content: '\EA19';
  font-size: 14px; }

.zd-bpm-reset {
  cursor: pointer; }
  .zd-bpm-reset:hover {
    color: #ddd !important; }

.bpm_dropdown {
  min-width: 308px !important;
  padding: 10px; }
  .bpm_dropdown .form-control {
    display: block;
    width: 97px;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.3rem 0.5rem;
    font-size: 0.7rem;
    font-weight: 400;
    line-height: 1.5;
    color: #ddd;
    background-color: #242529;
    background-clip: padding-box;
    border: 1px solid #47484a;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

.zd-top-dropdown {
  color: #6b7883;
  border: 1px solid #32333a;
  background: #25262a;
  font-size: 12px;
  font-weight: 500;
  font-style: normal; }
  .zd-top-dropdown:hover, .zd-top-dropdown:focus {
    background: #25262a; }

.zd-text-genre-filter {
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 400;
  padding-left: 8px; }

.zd-filter-dates .zd-nested-dropdown .zd-dropdown {
  color: #eaeaea; }
  .zd-filter-dates .zd-nested-dropdown .zd-dropdown:hover {
    color: #4f7be9; }

.zd-dropp {
  margin-right: 14px !important; }

.zd_new_top_genre {
  color: #ddd !important; }

.zd-icon-mr {
  margin-right: 16px !important; }

.crate-profile-header {
  margin-bottom: 15px;
  margin-left: 41px; }
  .crate-profile-header h2 {
    font-size: 16px;
    padding-bottom: 2px;
    display: inline-block; }

.crateModal {
  width: 360px; }
  @media (max-width: 750px) {
    .crateModal {
      width: 96%; } }
  .crateModal .modal-content {
    height: 253px;
    background: #242529 !important; }
    .crateModal .modal-content .crate-label {
      position: absolute;
      height: 24px;
      left: 109px;
      top: 54px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0; }
    .crateModal .modal-content .crate-text {
      width: 280px;
      height: 40px;
      position: absolute;
      top: 97px;
      left: 40px;
      background: #1c1e22;
      border-radius: 2px !important;
      color: white; }
    .crateModal .modal-content .modalErrorText {
      position: absolute;
      top: 148px;
      left: 40px;
      color: #ff4433; }
    .crateModal .modal-content .modal-cancel-button {
      position: absolute;
      left: 40px;
      top: 184px;
      width: 91px;
      height: 38px;
      background: #242529;
      border-color: #242529;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 12px; }
    .crateModal .modal-content .modal-create-button {
      position: absolute;
      top: 184px;
      left: 230px;
      width: 91px;
      height: 38px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 12px; }

.show-child {
  margin-top: -28px;
  left: -161px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

#child_dropdown__2 {
  left: -175px !important; }

.zd-tertiary-header {
  margin-top: 12px; }
  .zd-tertiary-header .zd-primary-dropdown:hover {
    background: #25262a !important; }

.all-packs-crate .zd-release-play {
  display: table-cell !important; }

@media (max-width: 750px) {
  .all-packs-crate .zd-release-crates {
    width: 24px !important; } }

.zd-release-crates {
  padding-top: 8px;
  vertical-align: -webkit-baseline-middle !important;
  width: 24px;
  height: 20px; }
  .zd-release-crates i {
    color: #6b7883; }
  .zd-release-crates .crate-dropdown-menu {
    position: absolute;
    width: 161px;
    top: 20px !important;
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px !important;
    -webkit-filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));
            filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));
    -webkit-transform: translate3d(-129px, 7px, 0px) !important;
            transform: translate3d(-129px, 7px, 0px) !important; }
    .zd-release-crates .crate-dropdown-menu .dropdown-item .dropdown-right-icon {
      font-size: 10px !important; }
    .zd-release-crates .crate-dropdown-menu .dropdown-item .artist-arrow {
      padding-left: 54px; }
    .zd-release-crates .crate-dropdown-menu .dropdown-item .playlist-arrow {
      padding-left: 41px; }
    .zd-release-crates .crate-dropdown-menu .follow-crate-dropdown {
      padding: 0px 0px !important;
      -webkit-transform: translate3d(-165px, 0px, 0px) !important;
              transform: translate3d(-165px, 0px, 0px) !important; }
    .zd-release-crates .crate-dropdown-menu .crate-playlist-dropdown {
      width: 161px;
      max-height: 200px;
      overflow-y: auto;
      -webkit-transform: translate3d(-165px, -2px, 0px) !important;
              transform: translate3d(-165px, -2px, 0px) !important; }
      .zd-release-crates .crate-dropdown-menu .crate-playlist-dropdown .dropdown-playlist {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; }
      .zd-release-crates .crate-dropdown-menu .crate-playlist-dropdown .dropdown-add-icon {
        font-size: 11px !important; }
  .zd-release-crates .bottom-crate {
    position: absolute;
    will-change: transform;
    top: -112px !important; }
    .zd-release-crates .bottom-crate .crate-playlist-dropdown {
      -webkit-transform: translate3d(-165px, -97px, 0px) !important;
              transform: translate3d(-165px, -97px, 0px) !important; }

@media (max-width: 750px) {
  .zd-megagenre-parent .favourite-search-bar-form {
    display: none; } }

.favourite-search-bar-form {
  width: 240px;
  height: 32px;
  margin-bottom: 17px; }
  @media (max-width: 750px) {
    .favourite-search-bar-form {
      display: block;
      margin-left: auto;
      margin-right: 33px; } }
  .favourite-search-bar-form .favourite-search-label {
    height: 32px;
    background-color: #242529; }
    .favourite-search-bar-form .favourite-search-label div {
      display: inline-block;
      margin: 3px 8px 0px 9px; }
      .favourite-search-bar-form .favourite-search-label div i {
        color: #6b7883 !important;
        font-size: 16px; }
    .favourite-search-bar-form .favourite-search-label .favourite-search-box {
      width: 240px;
      height: 32px;
      position: absolute;
      color: #ddd;
      background-color: #242529;
      border: none;
      padding-bottom: 2px; }
    .favourite-search-bar-form .favourite-search-label .favourite-search-box:focus {
      background-color: #242529;
      color: white; }
  .favourite-search-bar-form .favourite-search-label_packs {
    height: 32px;
    background-color: #242529; }
    .favourite-search-bar-form .favourite-search-label_packs div {
      display: inline-block;
      margin: 3px 8px 0px 9px; }
      .favourite-search-bar-form .favourite-search-label_packs div i {
        color: #6b7883 !important;
        font-size: 16px; }
    .favourite-search-bar-form .favourite-search-label_packs .favourite-search-box {
      width: 208px;
      height: 32px;
      position: absolute;
      color: #ddd;
      background-color: #242529;
      border: none;
      padding-bottom: 2px; }
    .favourite-search-bar-form .favourite-search-label_packs .favourite-search-box:focus {
      background-color: #242529;
      color: white; }

.favourite-search-bar-form:hover .favourite-search-box {
  color: #ddd; }

.favourite-search-bar-form:hover .zdv3-icon-search {
  color: #ddd !important; }

.favourite-search-bar-form:focus-within .favourite-search-box {
  color: #ddd; }

.favourite-search-bar-form:focus-within .zdv3-icon-search {
  color: #ddd !important; }

.zd-album-table {
  border-spacing: 0 2px;
  text-align: left;
  width: 100%;
  table-layout: fixed; }
  .zd-album-table .zd-small-album-cell {
    border-radius: 2px !important; }
  .zd-album-table.zd-table-hover {
    border-right: 2px solid transparent; }
    .zd-album-table.zd-table-hover tbody tr:hover {
      background-color: #28292e; }
      .zd-album-table.zd-table-hover tbody tr:hover .zd-item-play {
        color: #dddddd; }
        .zd-album-table.zd-table-hover tbody tr:hover .zd-item-play .zd-icon-repeat-track {
          color: #dddddd; }
    .zd-album-table.zd-table-hover .zd-favourite-history .zd-album-favourite i {
      color: #4f7be9 !important; }
    .zd-album-table.zd-table-hover .zd-table-hover-color .zd-selected-row {
      background-color: #303139 !important;
      border-bottom: 0px !important; }
    .zd-album-table.zd-table-hover .zd-table-hover-color tbody tr {
      background-color: #3a3c3e87; }
      .zd-album-table.zd-table-hover .zd-table-hover-color tbody tr .zd-item-play {
        color: #4d7ce8 !important; }
        .zd-album-table.zd-table-hover .zd-table-hover-color tbody tr .zd-item-play .zd-icon-repeat-track {
          color: #666; }
    .zd-album-table.zd-table-hover .zd-table-hover-color tbody tr:hover {
      background-color: #28292e; }
      .zd-album-table.zd-table-hover .zd-table-hover-color tbody tr:hover .zd-item-play {
        color: #dddddd !important; }
        .zd-album-table.zd-table-hover .zd-table-hover-color tbody tr:hover .zd-item-play .zd-icon-repeat-track {
          color: #dddddd; }
  .zd-album-table .zd-single-release-hover:hover {
    background-color: #303139; }
    .zd-album-table .zd-single-release-hover:hover .zd-item-play {
      color: #dddddd; }
      .zd-album-table .zd-single-release-hover:hover .zd-item-play .zd-icon-repeat-track {
        color: #dddddd; }
  .zd-album-table tr {
    display: table-row; }
    .zd-album-table tr:focus {
      outline: none; }
  .zd-album-table td {
    display: table-cell; }
  .zd-album-table td,
  .zd-album-table th {
    border-top: none;
    padding: 0 0.45rem;
    vertical-align: middle; }
  .zd-album-table thead {
    background-color: #1b1b1d; }
    .zd-album-table thead th,
    .zd-album-table thead td {
      border: none; }
    .zd-album-table thead th {
      text-transform: uppercase;
      padding: 1.15rem 0.75rem;
      vertical-align: top; }
  .zd-album-table tbody tr {
    background-color: #242529;
    border-bottom: 1px solid #1c1e22;
    transition: background-color 0.02s ease-in-out; }
    .zd-album-table tbody tr td a {
      vertical-align: middle;
      cursor: pointer; }
  .zd-album-table tbody .zd-top-download-count {
    margin-right: 0;
    display: inline-block;
    color: #919191;
    display: block;
    min-width: 20px;
    text-align: center; }
  .zd-album-table tbody .zd-count {
    margin-right: 0;
    display: inline-block;
    color: #919191;
    float: left;
    display: block;
    min-width: 20px;
    text-align: center;
    display: none; }
  .zd-album-table tbody .zd-item-play {
    color: #4d7ce8;
    font-size: 1.4rem;
    line-height: 0rem;
    float: left;
    display: block; }
    .zd-album-table tbody .zd-item-play .zd-icon-repeat-track {
      color: #666; }
    .zd-album-table tbody .zd-item-play:hover {
      color: #dddddd !important;
      text-decoration: none; }
      .zd-album-table tbody .zd-item-play:hover .zd-icon-repeat-track {
        color: #666; }
  .zd-album-table tbody .zd-icons a {
    color: #dddddd; }
    .zd-album-table tbody .zd-icons a:hover {
      text-decoration: none; }
  .zd-album-table tbody .zd-double-icons {
    text-align: center;
    padding-right: 17px; }
    .zd-album-table tbody .zd-double-icons span {
      padding-right: 4px; }
    .zd-album-table tbody .zd-double-icons a {
      font-size: 24px; }
      @media (min-width: 750px) {
        .zd-album-table tbody .zd-double-icons a {
          padding: 10px 6px; } }
    .zd-album-table tbody .zd-double-icons a:hover i {
      color: #dddddd; }
  .zd-album-table tbody .zd-tag-btn {
    font-size: 16px;
    color: #dddddd;
    display: none; }
    .zd-album-table tbody .zd-tag-btn:hover {
      color: #4d7ce8 !important; }
  @media (max-width: 1024px) and (min-width: 0px) {
    .zd-album-table tbody .zd-download-btn {
      font-size: 1rem;
      line-height: 1rem;
      padding-right: 0px; } }
  @media (min-width: 1024px) {
    .zd-album-table tbody .zd-download-btn {
      font-size: 1rem;
      line-height: 1rem;
      padding-right: 13px; } }
  .zd-album-table tbody .zd-item-favourite {
    color: #dddddd;
    font-size: 0.9rem;
    line-height: 1.5rem;
    float: left;
    display: block; }
    .zd-album-table tbody .zd-item-favourite:hover {
      color: #dddddd;
      text-decoration: none; }
  .zd-album-table tbody .zd-item-plus {
    color: #dddddd;
    font-size: 0.9rem;
    line-height: 1.5rem;
    float: left;
    display: block; }
    .zd-album-table tbody .zd-item-plus:hover {
      color: #dddddd;
      text-decoration: none; }
  .zd-album-table tbody .zd-album-title {
    padding-left: 10px;
    font-weight: 400;
    padding: 0;
    line-height: 1.6rem;
    color: #eaeaea;
    display: block;
    cursor: default; }
    .zd-album-table tbody .zd-album-title span {
      cursor: pointer;
      padding-right: 2px; }
    .zd-album-table tbody .zd-album-title:hover {
      text-decoration: none; }
  .zd-album-table tbody .text-muted {
    color: #909090 !important; }
  .zd-album-table tbody .zd-filter-text {
    cursor: pointer; }
    .zd-album-table tbody .zd-filter-text:hover {
      text-decoration: underline; }

@media (max-width: 414px) {
  .zd-new-newreleases-table .zd-album-table .zd-album-title {
    margin-left: 6px; } }

.zd-album-column {
  overflow: hidden;
  padding: 0 !important; }

.zd-display-table {
  display: table; }

.zd-fixed-table {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
  width: 100%;
  table-layout: fixed;
  display: table;
  margin: 0; }
  .zd-fixed-table tbody tr {
    transition: background-color 0.02s ease-in-out;
    display: table-row;
    border: none; }
    .zd-fixed-table tbody tr td {
      display: table-cell;
      border-top: none;
      padding: 0 0.5rem;
      vertical-align: middle;
      border-collapse: collapse; }

.zd-tag-label {
  color: #7685b3;
  padding: 1px 6px;
  background: #2a3043;
  margin: 0.5rem 0.2rem 0.5rem 0;
  cursor: pointer; }

.zd-selected {
  background-color: #303139 !important; }

.zd-selected-row .zd-album-table tbody tr {
  background-color: #303139 !important; }

.zd-selected-row.zd-single-release-hover {
  background-color: #303139 !important; }

.zd-selected-row.zd-parent-table {
  background-color: #303139 !important; }

.zd-selected-row .zd-item-play,
.zd-selected-row .zd-item-plus .zd-item-favourite {
  color: #dddddd; }
  .zd-selected-row .zd-item-play .zd-icon-repeat-track,
  .zd-selected-row .zd-item-plus .zd-item-favourite .zd-icon-repeat-track {
    color: #dddddd; }

.dmw-table .zd-inner-table tbody tr td,
.zd-trending-table .zd-inner-table tbody tr td {
  padding: 0.05rem 0.45rem; }
  .dmw-table .zd-inner-table tbody tr td:first-child,
  .zd-trending-table .zd-inner-table tbody tr td:first-child {
    padding-left: 15px; }

.zd-table-head {
  background: #1d1b21; }
  .zd-table-head .zd-fixed-table tbody tr td {
    vertical-align: top; }
  .zd-table-head .zd-album-column .zd-album-table {
    background: #1b1b1d; }
    .zd-table-head .zd-album-column .zd-album-table tbody tr {
      background: #1d1b21; }
      .zd-table-head .zd-album-column .zd-album-table tbody tr td {
        padding: 0.5rem 0.45rem;
        color: #dddddd; }
        .zd-table-head .zd-album-column .zd-album-table tbody tr td.zd-pl-88 {
          padding-left: 88px; }
        .zd-table-head .zd-album-column .zd-album-table tbody tr td.zd-pl-78 {
          padding-left: 62px; }
        .zd-table-head .zd-album-column .zd-album-table tbody tr td.zd-pl-18 {
          padding-left: 18px; }
        .zd-table-head .zd-album-column .zd-album-table tbody tr td .zd-filter-text {
          cursor: pointer; }
          .zd-table-head .zd-album-column .zd-album-table tbody tr td .zd-filter-text:hover {
            text-decoration: none;
            cursor: pointer; }
        .zd-table-head .zd-album-column .zd-album-table tbody tr td a {
          color: #4a535a;
          padding: 0;
          font-weight: 500; }
          .zd-table-head .zd-album-column .zd-album-table tbody tr td a:hover {
            text-decoration: none; }
          .zd-table-head .zd-album-column .zd-album-table tbody tr td a.sort-active {
            border-bottom: 2px solid #303139;
            padding-bottom: 9px;
            margin-bottom: -11px; }

.zd-inner-table {
  margin: 0; }

.zd-album-table {
  font-size: 12px;
  font-weight: 300; }

.zd-text-d8d8d8 {
  color: #d8d8d8 !important; }

.zd-btn-4f7be9 {
  background-color: #4f7be9 !important;
  color: #ddd; }
  .zd-btn-4f7be9:hover {
    color: #ddd !important; }

.zt-btn-25262a {
  background-color: #25262a !important; }

.zd-text-6b7883 {
  color: #768492 !important; }

.zd-text-4f7be9 {
  color: #4f7be9 !important; }

#zd-datelist-span {
  margin-left: 8px;
  margin-top: 3px; }

.zd-text-47484a {
  color: #47484a !important; }

.zd-text-434b52 {
  color: #434b52 !important; }

.zd-text-dddddd {
  color: #dddddd !important; }

.zd-text-8e8e8e {
  color: #768492 !important; }

.zd-text-4d7ce8 {
  color: #4d7ce8 !important; }

.zd-text-ffffff {
  color: #ffffff !important; }

.release-name {
  color: #dddddd;
  font-weight: 500 !important; }

.zd-breadcrumb {
  margin-top: -6px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px; }

body .zd-pl-120 {
  padding-left: 120px !important; }

body .zd-pl-50 {
  padding-left: 50px !important; }

body .zd-pl-40 {
  padding-left: 40px !important; }

body .zd-pl-30 {
  padding-left: 30px !important; }

body .zd-pl-25 {
  padding-left: 25px !important; }

body .zd-pl-20 {
  padding-left: 20px !important; }

body .zd-pl-15 {
  padding-left: 15px !important; }

body .zd-pl-10 {
  padding-left: 10px !important; }

.zd-font-10 {
  font-size: 10px; }

.zd-font-12 {
  font-size: 12px; }

.zd-font-15 {
  font-size: 15px; }

.zd-font-18 {
  font-size: 18px; }

.zd-font-22 {
  font-size: 22px; }

.zd-font-24 {
  font-size: 24px; }

.zdv3-icon-play {
  font-size: 1.4rem; }

.zd-favourite-blue {
  color: blue !important; }

.zdv3-icon-download:hover {
  color: #dddddd !important; }

.zd-body-row-table {
  background-color: #1d1b22; }
  .zd-body-row-table tbody tr td {
    height: 40px; }

.zd-packs-page .zd-fixed {
  position: inherit; }

.zd-fixed {
  top: 185px !important;
  line-height: normal;
  padding: 0;
  z-index: 999; }

.zd-no-header .zd-fixed {
  top: 94px !important; }

.download-manager-page .zd-table-head,
.download-manager-page .zd-table-head .zd-album-column .zd-album-table tbody tr {
  background: #1c1e22; }

.download-manager-page .zd-content-head.zd-fixed-header {
  padding-bottom: 2px; }

.zd-packs-page .zd-content-head.zd-fixed-header {
  padding-bottom: 35px; }

.zd-packs-page .zd-content-head.zd-fixed-header {
  padding-bottom: 2px; }

/* Release View  */
.zd-releases-view .zd-newreleases-table .zd-border-10 {
  border-bottom: 8px solid #1c1e22; }

.zd-releases-view .zd-newreleases-table .zd-selected-row {
  background-color: #303139 !important; }
  .zd-releases-view .zd-newreleases-table .zd-selected-row .zd-item-play,
  .zd-releases-view .zd-newreleases-table .zd-selected-row .zd-item-plus,
  .zd-releases-view .zd-newreleases-table .zd-selected-row .zd-item-favourite {
    color: #dddddd; }
    .zd-releases-view .zd-newreleases-table .zd-selected-row .zd-item-play .zd-icon-repeat-track,
    .zd-releases-view .zd-newreleases-table .zd-selected-row .zd-item-plus .zd-icon-repeat-track,
    .zd-releases-view .zd-newreleases-table .zd-selected-row .zd-item-favourite .zd-icon-repeat-track {
      color: #dddddd; }

.zd-releases-view .zd-newreleases-table tbody tr:hover,
.zd-releases-view .zd-newreleases-table tbody tr {
  background-color: transparent; }
  .zd-releases-view .zd-newreleases-table tbody tr:hover .zd-item-play,
  .zd-releases-view .zd-newreleases-table tbody tr .zd-item-play {
    color: #4d7ce8; }
    .zd-releases-view .zd-newreleases-table tbody tr:hover .zd-item-play .zd-icon-repeat-track,
    .zd-releases-view .zd-newreleases-table tbody tr .zd-item-play .zd-icon-repeat-track {
      color: #666; }
      .zd-releases-view .zd-newreleases-table tbody tr:hover .zd-item-play .zd-icon-repeat-track:hover,
      .zd-releases-view .zd-newreleases-table tbody tr .zd-item-play .zd-icon-repeat-track:hover {
        color: #dddddd; }

.zd-releases-view .zd-text-47484A {
  color: #47484a !important; }

.zd-releases-view .zd-float-icons {
  position: relative; }

.zd-releases-view .zd-action-list {
  position: absolute;
  top: 0;
  right: 26px;
  height: 100%;
  padding: 3px 7px;
  display: flex;
  align-items: center;
  visibility: hidden; }
  .zd-releases-view .zd-action-list a.feedback,
  .zd-releases-view .zd-action-list a.favourite {
    padding-right: 5px; }
    .zd-releases-view .zd-action-list a.feedback .active,
    .zd-releases-view .zd-action-list a.favourite .active {
      color: #5764c6 !important; }

.zd-releases-view .zd-toggle-table {
  padding-top: 2px;
  vertical-align: middle;
  display: flex;
  align-items: center; }

.zd-table-hover .zd-group-tr:hover .zd-group-table tbody tr,
.zd-table-hover .zd-group-tr:hover .zd-group-items-table tbody tr {
  background-color: #28292e !important; }
  .zd-table-hover .zd-group-tr:hover .zd-group-table tbody tr.zd-selected-row,
  .zd-table-hover .zd-group-tr:hover .zd-group-items-table tbody tr.zd-selected-row {
    background-color: #303139 !important; }

.zd-table-hover .zd-group-tr .zd-group-single-row:hover {
  background-color: #303139 !important; }

.zd-table-hover .zd-group-tr .zd-group-items-table .zd-table-body-row {
  border-right: 4px solid transparent; }
  .zd-table-hover .zd-group-tr .zd-group-items-table .zd-table-body-row:hover {
    border-right: 4px solid #4f7be9;
    background-color: #303139 !important; }
    .zd-table-hover .zd-group-tr .zd-group-items-table .zd-table-body-row:hover .zd-play-col a i {
      color: #dddddd !important; }

.zd-table-hover .zd-group-tr:hover .zd-group-single-row:hover {
  background-color: #303139 !important; }

.zd-group-table {
  background-color: #242529; }
  .zd-group-table tbody tr {
    background-color: #242529 !important;
    height: 48px;
    border-radius: 20px !important;
    overflow: hidden !important; }
    .zd-group-table tbody tr:hover .zd-item-play {
      color: #dddddd !important; }
      .zd-group-table tbody tr:hover .zd-item-play .zd-icon-repeat-track {
        color: #dddddd; }
    .zd-group-table tbody tr:hover .zd-action-list {
      visibility: visible; }
    .zd-group-table tbody tr td {
      border-bottom: 0px solid #1c1e22; }

.zd-group-table tbody .album-title .zd-album-title {
  font-weight: 700; }

.zd-group-table tbody .album-title .zd-album-title-lm-0 {
  font-weight: 700; }

.zd-group-table tbody .album-name {
  color: #b2b2b2; }

.zd-group-table tbody .zd-album-name {
  margin-left: 10px;
  font-weight: 400;
  color: #b2b2b2;
  width: calc(100% - 88px);
  float: left;
  display: block;
  cursor: default; }
  .zd-group-table tbody .zd-album-name span {
    cursor: pointer; }

.zd-group-items-table tbody tr {
  height: 40px; }

.zd-album-table .zd-info-row {
  background-color: #2c2c2e !important;
  transition: all 2s linear; }
  .zd-album-table .zd-info-row .zd-info-cell {
    padding: 10px 15px; }
  .zd-album-table .zd-info-row .track-info-block {
    display: flex;
    padding: 5px 0; }
    .zd-album-table .zd-info-row .track-info-block .track-info-img {
      width: 200px; }
      .zd-album-table .zd-info-row .track-info-block .track-info-img img {
        width: 100%; }
    .zd-album-table .zd-info-row .track-info-block .track-info-desc {
      width: calc(100% - 200px);
      padding: 5px 0 5px 15px; }
      .zd-album-table .zd-info-row .track-info-block .track-info-desc .album-title {
        color: #dddddd;
        font-weight: 700;
        cursor: pointer; }
        .zd-album-table .zd-info-row .track-info-block .track-info-desc .album-title:hover {
          color: #5764c6; }
      .zd-album-table .zd-info-row .track-info-block .track-info-desc .album-name {
        margin-top: 8px;
        color: #b2b2b2; }
      .zd-album-table .zd-info-row .track-info-block .track-info-desc .album-description {
        white-space: normal;
        margin-top: 8px;
        line-height: normal;
        color: #919191; }

.zd-action-visible {
  visibility: visible !important; }
  .zd-action-visible a.zd-info-toggle {
    color: #5764c6 !important; }

.zd-open-row {
  display: table-row !important; }

.zd-child-album-art {
  opacity: 0.5;
  border-radius: 2px; }

body .zd-album-column .zd-rank-column {
  font-size: 14px;
  color: #6b7883;
  text-align: center;
  font-weight: 400; }

body .tracking {
  position: relative; }
  body .tracking .zd-album-art {
    position: relative; }
  body .tracking .zd-player-thumb {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 44px;
    color: #dddddd;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease; }
    body .tracking .zd-player-thumb svg {
      margin-top: -10px; }
  body .tracking .zd-release-thumb {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 25px;
    color: #dddddd;
    max-height: 40px;
    min-height: 40px;
    width: 40px;
    z-index: 1;
    background: rgba(0, 0, 0, 0.6);
    transition: all 0.3s ease; }
    body .tracking .zd-release-thumb svg {
      margin-top: 0; }
  body .tracking:hover .zd-player-thumb,
  body .tracking:hover .zd-release-thumb {
    opacity: 1; }

body .zd-player-tr:hover .zd-player-thumb,
body .zd-player-tr:hover .zd-release-thumb {
  opacity: 1; }

.zd-otherinfo-table {
  border-spacing: 0px 2px;
  border-collapse: separate; }
  .zd-otherinfo-table .zdv4-djinfo-icons i {
    font-size: 10px;
    color: #6b7883; }
  .zd-otherinfo-table .zdv4-djinfo-icons span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #6b7883; }
  .zd-otherinfo-table .zd-djinfo-action {
    padding: 12px 12px 12px 0px; }
    .zd-otherinfo-table .zd-djinfo-action div {
      margin-right: 10px; }
  .zd-otherinfo-table td {
    border-top: unset; }
  .zd-otherinfo-table .table-head {
    background: transparent !important; }
    .zd-otherinfo-table .table-head td {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #434b52; }
  .zd-otherinfo-table .table-content {
    background: #242529 !important; }
    .zd-otherinfo-table .table-content td:first-child, .zd-otherinfo-table .table-content td:last-child {
      border-radius: 2px 0px 0px 2px; }
    .zd-otherinfo-table .table-content .zd-djinfo-name,
    .zd-otherinfo-table .table-content .zd-djinfo-type,
    .zd-otherinfo-table .table-content .zd-djinfo-city,
    .zd-otherinfo-table .table-content .zd-djinfo-radio-type,
    .zd-otherinfo-table .table-content .zd-djinfo-show-name,
    .zd-otherinfo-table .table-content .zd-djinfo-url {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #dddddd; }
    .zd-otherinfo-table .table-content .zd-djinfo-date,
    .zd-otherinfo-table .table-content .zd-djinfo-nights,
    .zd-otherinfo-table .table-content .zd-djinfo-capacity,
    .zd-otherinfo-table .table-content .zd-djinfo-radio-station,
    .zd-otherinfo-table .table-content .zd-djinfo-show-type {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #6b7883; }
  .zd-otherinfo-table .zd-djinfo-name,
  .zd-otherinfo-table .zd-djinfo-radio-type {
    width: 45%; }
  .zd-otherinfo-table .zd-djinfo-nights,
  .zd-otherinfo-table .zd-djinfo-type,
  .zd-otherinfo-table .zd-djinfo-show-name,
  .zd-otherinfo-table .zd-djinfo-radio-station {
    width: 15%; }
  .zd-otherinfo-table .zd-djinfo-columnist,
  .zd-otherinfo-table .zd-djinfo-reviewer {
    width: 16.5%; }
  .zd-otherinfo-table .zd-djinfo-city,
  .zd-otherinfo-table .zd-djinfo-capacity,
  .zd-otherinfo-table .zd-djinfo-url,
  .zd-otherinfo-table .zd-djinfo-show-type {
    width: 10%; }
  @media (max-width: 768px) {
    .zd-otherinfo-table .zd-hide-mob {
      display: none; } }

body .zd-info-table {
  background-color: transparent; }
  body .zd-info-table .table-head {
    background: #191b1f !important; }
    body .zd-info-table .table-head td,
    body .zd-info-table .table-head th {
      border-top: none; }
  body .zd-info-table tr {
    background: transparent; }
    body .zd-info-table tr:hover {
      background: #252831; }
  body .zd-info-table .table-footer {
    border-bottom: 1px solid #444; }
  body .zd-info-table .zd-update,
  body .zd-info-table .zd-delete {
    font-size: 14px;
    float: right;
    margin-right: 28%;
    margin-top: -22px; }
  body .zd-info-table .zd-add-btn {
    font-size: 14px;
    line-height: 20px;
    padding-right: 10px; }
  body .zd-info-table .zd-print-icon-link {
    font-size: 24px;
    line-height: 20px;
    cursor: pointer; }

.zd-track-info-block {
  display: flex;
  padding: 5px 0; }
  .zd-track-info-block .zd-track-info-img {
    width: 170px;
    height: 170px; }
    .zd-track-info-block .zd-track-info-img img {
      width: 100%;
      height: 100%; }
  .zd-track-info-block .zd-track-info-desc {
    width: calc(100% - 170px);
    padding: 0px 0 5px 15px; }
    .zd-track-info-block .zd-track-info-desc .artistName,
    .zd-track-info-block .zd-track-info-desc .labelName {
      cursor: pointer; }
      .zd-track-info-block .zd-track-info-desc .artistName:hover,
      .zd-track-info-block .zd-track-info-desc .labelName:hover {
        text-decoration: underline; }
  .zd-track-info-block .zd-back-url {
    color: #dddddd;
    position: relative;
    cursor: pointer;
    margin-right: 5px;
    float: right;
    font-size: 18px; }
    .zd-track-info-block .zd-back-url:hover {
      color: #4d7ce8; }

@media (min-width: 1440px) {
  .zd-track-view.zd-search-track-view {
    padding-top: 1rem !important; } }

@media (max-width: 750px) {
  .zd-track-view .all-packs-crate .zd-dropdown-crate {
    margin-left: 17px; } }

@media (max-width: 750px) {
  .zd-track-view .zd-album-table .zd-table-head .zd-filter-text {
    display: none; }
  .zd-track-view .zd-album-table .zd-table-head .zd-artist {
    display: none; } }

@media (min-width: 1440px) {
  .zd-chrome-track-header .zd-release-space,
  .sk-results-list .zd-release-space,
  .release-view-page .zd-release-space {
    margin-top: 3.5px !important; } }

.zd-track-view-row:hover {
  background-color: #303139 !important; }

.zd-search-table {
  background-color: transparent !important; }

.zd-tr-color {
  background: #191b1f !important; }

.zd-search-minimal .zd-search-layout {
  margin-bottom: 10px; }

.zd-search-minimal table.zd-minimal-table {
  margin: 10px 0px; }
  .zd-search-minimal table.zd-minimal-table tbody tr {
    border-bottom: none; }
    .zd-search-minimal table.zd-minimal-table tbody tr td {
      padding: 0px;
      height: 40px;
      border-bottom: none; }
  .zd-search-minimal table.zd-minimal-table .zd-icon {
    font-size: 0.7em;
    line-height: 1.43em; }
  .zd-search-minimal table.zd-minimal-table .zd-player-icon-download {
    font-size: 16px;
    line-height: 22px; }
  .zd-search-minimal table.zd-minimal-table .zd-album-table tbody .zd-album-title {
    width: calc(100% - 32px);
    margin-left: 6px; }
  .zd-search-minimal table.zd-minimal-table .zd-album-table tbody .zd-icons {
    width: 24px !important; }
    @media (max-width: 750px) {
      .zd-search-minimal table.zd-minimal-table .zd-album-table tbody .zd-icons {
        width: 100% !important;
        display: table-cell !important; } }
    @media (max-width: 750px) {
      .zd-search-minimal table.zd-minimal-table .zd-album-table tbody .zd-icons .zd-download-btn {
        margin-top: -5px; } }
  .zd-search-minimal table.zd-minimal-table .zd-album-table tbody tr {
    border-bottom: none; }
  .zd-search-minimal table.zd-minimal-table .zd-album-table tbody tr:hover {
    background-color: #242529; }
  @media (max-width: 750px) {
    .zd-search-minimal table.zd-minimal-table .zd-album-table tbody .zd-release-favourite {
      display: none !important; } }

.zd-genre-container {
  margin-bottom: 40px !important;
  padding-left: 13px !important;
  padding-right: 13px !important;
  border-left: 3px solid #4d7ce8; }
  .zd-genre-container .zd-border-10:last-child {
    border: none !important; }

.zd-table-no-bg {
  background-color: transparent !important; }

.zd-table-hover-color .zd-selected-row {
  background-color: #303139 !important; }

.zd-table-hover-color tbody tr {
  background-color: #28292e !important; }

.zd-table-hover-color tbody tr:hover {
  background-color: #28292e !important; }
  .zd-table-hover-color tbody tr:hover .zd-item-play {
    color: #dddddd !important; }
    .zd-table-hover-color tbody tr:hover .zd-item-play .zd-icon-repeat-track {
      color: #dddddd; }

.zd-feedback-row {
  border-bottom: 2px solid #303139 !important;
  background-color: #28292e !important; }
  .zd-feedback-row.zd-table-body-row {
    background-color: rgba(58, 60, 62, 0.45) !important; }
    .zd-feedback-row.zd-table-body-row:hover {
      background-color: rgba(58, 60, 62, 0.45) !important;
      border-right: 2px solid #303139 #4f7be9; }
  .zd-feedback-row:hover {
    background-color: rgba(58, 60, 62, 0.45) !important; }

.zd-selected-row .zd-album-table tbody .zd-feedback-row {
  border-bottom: 2px solid #303139 !important;
  background-color: #28292e !important; }
  .zd-selected-row .zd-album-table tbody .zd-feedback-row.zd-table-body-row {
    background-color: #28292e !important; }
  .zd-selected-row .zd-album-table tbody .zd-feedback-row:hover {
    background-color: #28292e !important; }

.zd-feedback-release-highlight-border {
  border-top: none !important; }

.zd-pack-feedback-release-highlight-border {
  border: 2px solid #303139 !important;
  border-top: none !important; }

.zd-track-view .zd-play-col {
  width: 40px;
  padding-left: 10px;
  padding-right: 10px; }

.zd-track-view .zd-track-cover-col {
  width: 40px; }

.zd-track-view .trending-chart-album {
  max-width: 83px !important;
  width: 83px !important; }

.zd-track-view .zd-new-newreleases-table .zd-tr-color .zd-release-play {
  display: none !important; }

.zd-track-view .zd-new-newrelease-track-view .zd-tr-color .zd-play-col {
  display: table-cell; }
  @media (max-width: 961px) {
    .zd-track-view .zd-new-newrelease-track-view .zd-tr-color .zd-play-col {
      display: table-cell !important; } }

@media (max-width: 414px) {
  .zd-track-view .zd-new-newrelease-track-view .zd-title {
    width: 47% !important; } }

.zd-track-view .zd-trendingchart-tbody .zd-tr-color .zd-play-col {
  display: none; }
  @media (max-width: 961px) {
    .zd-track-view .zd-trendingchart-tbody .zd-tr-color .zd-play-col {
      display: none !important; } }

.zd-track-view .zd-top-charts-table .zd-tr-color .zd-play-col {
  display: table-cell; }

.zd-track-view .zd-top-charts-table .zd-width-48 {
  width: 48px; }

.zd-track-view .zd-release-cover-col {
  padding: 0;
  background-color: #242529; }

.zd-track-view .zd-topdownloads-count {
  width: 40px;
  text-align: center;
  background-color: #242529; }

.zd-release-play {
  width: 48px; }

.zd-width-48 {
  width: 73px; }

.zd-width-40 {
  width: 40px; }

.top-downloads-filter-btn {
  padding-left: 8px !important;
  padding-right: 8px !important; }
  .top-downloads-filter-btn.zd_v5 {
    padding-left: 12px !important; }
  .top-downloads-filter-btn.dropdown_key {
    padding-left: 12px !important; }

.zd-release-play {
  padding-left: 14px !important; }

.zd-filter-text {
  cursor: pointer; }
  .zd-filter-text:hover {
    text-decoration: underline; }

.zd-album-table .zd-table-body-row {
  background-color: #242529;
  border-bottom: 2px solid #1c1e22 !important;
  transition: background-color 0.02s ease-in-out; }

.zd-album-table tbody tr {
  border-bottom: 0px; }

@media (min-width: 750px) and (max-width: 960px) {
  .zd-album-table .zd-topchart-list .zd-double-icons a {
    padding: 0 20px;
    font-size: 24px; } }

.zd-single-release-page-view tbody tr .zd-single-release-favourite {
  width: 24px;
  height: 24px; }
  .zd-single-release-page-view tbody tr .zd-single-release-favourite i {
    display: none; }
  .zd-single-release-page-view tbody tr .zd-single-release-favourite .zd-single-release-favourite .zdv3-icon-filled-favourite {
    display: block;
    color: #4d7ce8 !important; }

.zd-single-release-page-view tbody tr:hover .zd-single-release-favourite a i {
  display: inline-block; }

.zd-single-release-page-view tbody tr .zd-single-release-favourite .zdv3-icon-filled-favourite {
  display: block;
  color: #4d7ce8 !important; }

.zd-singlepack-tbody tr .zd-single-release-favourite i {
  display: none; }

.zd-singlepack-tbody tr:hover i {
  display: block; }

tbody tr .zd-singlepack-tbody .zd-single-release-favourite .zdv3-icon-filled-favourite {
  display: block;
  color: #4d7ce8 !important; }

table.zd-new-newreleases-page tbody tr td table.zd-new-newreleases-page-main-table {
  position: relative;
  background-color: transparent !important; }
  table.zd-new-newreleases-page tbody tr td table.zd-new-newreleases-page-main-table tr {
    background-color: transparent !important; }
    table.zd-new-newreleases-page tbody tr td table.zd-new-newreleases-page-main-table tr td {
      background-color: #242529 !important; }
      table.zd-new-newreleases-page tbody tr td table.zd-new-newreleases-page-main-table tr td:first-child {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        overflow: hidden; }
      table.zd-new-newreleases-page tbody tr td table.zd-new-newreleases-page-main-table tr td:last-child {
        position: relative;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        overflow: hidden; }
        table.zd-new-newreleases-page tbody tr td table.zd-new-newreleases-page-main-table tr td:last-child::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: 4px;
          background-color: transparent;
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px; }
  table.zd-new-newreleases-page tbody tr td table.zd-new-newreleases-page-main-table:hover tr {
    background-color: transparent !important; }
    table.zd-new-newreleases-page tbody tr td table.zd-new-newreleases-page-main-table:hover tr .zd-toggle-table a i {
      color: #dddddd !important; }
    table.zd-new-newreleases-page tbody tr td table.zd-new-newreleases-page-main-table:hover tr td {
      background-color: #303139 !important; }
      table.zd-new-newreleases-page tbody tr td table.zd-new-newreleases-page-main-table:hover tr td:last-child::after {
        background-color: #4f7be9; }

table.zd-new-newreleases-page tbody tr td .zd-new-newreleases-page-child-table {
  background-color: transparent !important;
  border-collapse: separate;
  border-spacing: 0 2px; }
  table.zd-new-newreleases-page tbody tr td .zd-new-newreleases-page-child-table tr {
    background-color: transparent !important;
    border-right: none !important;
    border-bottom: 0 !important; }
    table.zd-new-newreleases-page tbody tr td .zd-new-newreleases-page-child-table tr td {
      background-color: #242529; }
      table.zd-new-newreleases-page tbody tr td .zd-new-newreleases-page-child-table tr td:first-child {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        overflow: hidden; }
      table.zd-new-newreleases-page tbody tr td .zd-new-newreleases-page-child-table tr td.zd-release-favourite {
        width: 24px;
        height: 24px; }
        table.zd-new-newreleases-page tbody tr td .zd-new-newreleases-page-child-table tr td.zd-release-favourite i {
          display: none; }
      table.zd-new-newreleases-page tbody tr td .zd-new-newreleases-page-child-table tr td.zd-release-favourite .zdv3-icon-filled-favourite {
        display: block;
        color: #4d7ce8 !important; }
      table.zd-new-newreleases-page tbody tr td .zd-new-newreleases-page-child-table tr td:last-child {
        position: relative;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px; }
        table.zd-new-newreleases-page tbody tr td .zd-new-newreleases-page-child-table tr td:last-child::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: 4px;
          background-color: transparent;
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px; }
    table.zd-new-newreleases-page tbody tr td .zd-new-newreleases-page-child-table tr:hover .zd-play a i {
      color: #dddddd !important; }
    table.zd-new-newreleases-page tbody tr td .zd-new-newreleases-page-child-table tr:hover td {
      background-color: #303139 !important; }
      table.zd-new-newreleases-page tbody tr td .zd-new-newreleases-page-child-table tr:hover td:last-child::after {
        background-color: #4f7be9; }
    table.zd-new-newreleases-page tbody tr td .zd-new-newreleases-page-child-table tr:hover .zd-release-play a i {
      color: #dddddd !important; }
    table.zd-new-newreleases-page tbody tr td .zd-new-newreleases-page-child-table tr:hover .zd-release-favourite a i {
      display: inline-block;
      color: #6b7883 !important; }
    table.zd-new-newreleases-page tbody tr td .zd-new-newreleases-page-child-table tr:hover .zd-release-favourite .zdv3-icon-filled-favourite {
      display: block;
      color: #4d7ce8 !important; }
    table.zd-new-newreleases-page tbody tr td .zd-new-newreleases-page-child-table tr:hover .zd-release-crates .zdv3-icon-Crates {
      color: #dddddd; }

table.zd-new-newreleases-page tbody tr td:hover table.zd-new-newreleases-page-main-table tr td {
  background-color: #28292e !important; }

table.zd-new-newreleases-page tbody tr td:hover table.zd-new-newreleases-page-main-table:hover tr td {
  background-color: #303139 !important; }

table.zd-new-newreleases-page tbody tr td:hover .zd-new-newreleases-page-child-table tr td {
  background-color: #28292e; }

table.zd-new-newreleases-page tbody tr td:hover .zd-new-newreleases-page-child-table tr:hover td {
  background-color: #303139 !important; }

table.zd-new-newreleases-page tbody tr td.zd-feedback-release-highlight table tbody tr td {
  background-color: #28292e !important; }
  table.zd-new-newreleases-page tbody tr td.zd-feedback-release-highlight table tbody tr td:last-child::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 4px;
    background-color: #4f7be9;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px; }

table.zd-new-newreleases-page tbody tr td.zd-feedback-release-highlight table tbody tr:hover {
  background-color: #28292e !important; }

table.zd-new-newreleases-page tbody tr td.zd-feedback-release-highlight table tbody tr.zd-feedback-row td {
  background-color: #28292e !important; }
  table.zd-new-newreleases-page tbody tr td.zd-feedback-release-highlight table tbody tr.zd-feedback-row td:last-child::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 4px;
    background-color: transparent !important;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px; }

table.zd-new-newreleases-page tbody tr td.zd-feedback-release-highlight table tbody tr.zd-feedback-row:hover {
  background-color: #28292e !important; }

.zd-new-top-downloads-main-table {
  background: transparent;
  border-right: 0 !important; }
  .zd-new-top-downloads-main-table tbody tr {
    background: transparent; }
    .zd-new-top-downloads-main-table tbody tr td {
      background: transparent !important;
      border: 0 !important; }
      .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-parent-table {
        border-collapse: separate;
        border-spacing: 0 1.5px;
        background-color: transparent;
        cursor: pointer; }
        .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-parent-table tbody tr {
          border-bottom: 0 !important; }
          .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-parent-table tbody tr .zd-topdownloads-count {
            background-color: transparent; }
          .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-parent-table tbody tr td {
            background-color: #242529 !important; }
            .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-parent-table tbody tr td:first-child {
              border-top-left-radius: 2px;
              border-bottom-left-radius: 2px;
              overflow: hidden; }
            .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-parent-table tbody tr td:last-child {
              position: relative;
              border-top-right-radius: 2px;
              border-bottom-right-radius: 2px;
              overflow: hidden; }
              .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-parent-table tbody tr td:last-child::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: 4px;
                background-color: transparent;
                border-top-right-radius: 2px;
                border-bottom-right-radius: 2px; }
            .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-parent-table tbody tr td .zd-album-favourite {
              width: 24px; }
              .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-parent-table tbody tr td .zd-album-favourite i {
                display: none; }
            .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-parent-table tbody tr td .zd-album-favourite .zdv3-icon-filled-favourite {
              display: block;
              color: #4d7ce8 !important; }
        .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-parent-table tbody:hover td {
          background-color: #303139 !important; }
          .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-parent-table tbody:hover td span .crate-icon i {
            color: #dddddd !important; }
          .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-parent-table tbody:hover td:last-child::after {
            background-color: #4f7be9; }
          .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-parent-table tbody:hover td .zd-album-favourite i {
            display: block;
            color: #6b7883 !important; }
          .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-parent-table tbody:hover td .zd-album-favourite .zdv3-icon-filled-favourite {
            display: block;
            color: #4d7ce8 !important; }
      .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-child-table {
        background-color: transparent !important;
        border-collapse: separate;
        border-spacing: 0 2px;
        margin-top: -1px;
        margin-bottom: -1px; }
        .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-child-table tbody tr {
          background-color: transparent !important;
          -webkit-user-select: none;
                  user-select: none; }
          .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-child-table tbody tr .zd-topdownloads-count {
            background-color: transparent; }
            @media (max-width: 750px) {
              .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-child-table tbody tr .zd-topdownloads-count {
                display: none !important; } }
          .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-child-table tbody tr td {
            background-color: #242529 !important; }
            .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-child-table tbody tr td:first-child {
              border-top-left-radius: 2px;
              border-bottom-left-radius: 2px;
              overflow: hidden; }
            .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-child-table tbody tr td:last-child {
              position: relative;
              border-top-right-radius: 2px;
              border-bottom-right-radius: 2px;
              overflow: hidden; }
              .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-child-table tbody tr td:last-child::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: 4px;
                background-color: transparent;
                border-top-right-radius: 2px;
                border-bottom-right-radius: 2px; }
            .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-child-table tbody tr td .zd-album-favourite {
              width: 24px; }
              .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-child-table tbody tr td .zd-album-favourite i {
                display: none; }
            .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-child-table tbody tr td .zd-album-favourite .zdv3-icon-filled-favourite {
              display: block;
              color: #4d7ce8 !important; }
          .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-child-table tbody tr:hover td {
            background-color: #303139 !important; }
            .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-child-table tbody tr:hover td:last-child::after {
              background-color: #4f7be9; }
            .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-child-table tbody tr:hover td .zd-album-favourite i {
              display: block;
              color: #6b7883 !important; }
            .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-child-table tbody tr:hover td .zd-album-favourite .zdv3-icon-filled-favourite {
              display: block;
              color: #4d7ce8 !important; }
      .zd-new-top-downloads-main-table tbody tr td:hover table.zd-new-top-downloads-parent-table tr td {
        background-color: #28292e !important; }
      .zd-new-top-downloads-main-table tbody tr td:hover table.zd-new-top-downloads-parent-table:hover tr td {
        background-color: #303139 !important; }
      .zd-new-top-downloads-main-table tbody tr td:hover .zd-new-top-downloads-child-table tr td {
        background-color: #28292e !important; }
      .zd-new-top-downloads-main-table tbody tr td:hover .zd-new-top-downloads-child-table tr:hover td {
        background-color: #303139 !important; }
      .zd-new-top-downloads-main-table tbody tr td:hover .zd-new-top-downloads-child-table tr:hover .zd-child-album-art {
        opacity: 1 !important; }
    .zd-new-top-downloads-main-table tbody tr td.zd-feedback-row-highlight-border table tbody tr td {
      background-color: #303139 !important; }
      .zd-new-top-downloads-main-table tbody tr td.zd-feedback-row-highlight-border table tbody tr td:last-child::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 4px;
        background-color: #4f7be9;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px; }
    .zd-new-top-downloads-main-table tbody tr td.zd-feedback-row-highlight-border table tbody tr:hover {
      background-color: #303139 !important; }
    .zd-new-top-downloads-main-table tbody tr td.zd-feedback-row-highlight-border table tbody tr.zd-feedback-row td {
      background-color: #303139 !important; }
      .zd-new-top-downloads-main-table tbody tr td.zd-feedback-row-highlight-border table tbody tr.zd-feedback-row td:last-child::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 4px;
        background-color: transparent !important;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px; }
    .zd-new-top-downloads-main-table tbody tr td.zd-feedback-row-highlight-border table tbody tr.zd-feedback-row:hover {
      background-color: #303139 !important; }

.zd-new-newrelease-track-view {
  border-right: 0 !important;
  background-color: transparent; }
  .zd-new-newrelease-track-view tbody tr.zd-table-body-row {
    background-color: transparent !important;
    border-bottom: 0 !important; }

table.zd-new-newreleases-page tbody tr td .zd-new-newreleases-page-child-table tr.currently-playing-track,
table.dualtrack__table tbody tr.currently-playing-track,
table tbody tr.hotkey-bg.currently-playing-track,
table tbody tr.currently-playing-track {
  background-color: #394671 !important; }
  table.zd-new-newreleases-page tbody tr td .zd-new-newreleases-page-child-table tr.currently-playing-track td,
  table.dualtrack__table tbody tr.currently-playing-track td,
  table tbody tr.hotkey-bg.currently-playing-track td,
  table tbody tr.currently-playing-track td {
    background-color: #394671 !important; }
  table.zd-new-newreleases-page tbody tr td .zd-new-newreleases-page-child-table tr.currently-playing-track:hover,
  table.dualtrack__table tbody tr.currently-playing-track:hover,
  table tbody tr.hotkey-bg.currently-playing-track:hover,
  table tbody tr.currently-playing-track:hover {
    background-color: #878b96 !important; }
    table.zd-new-newreleases-page tbody tr td .zd-new-newreleases-page-child-table tr.currently-playing-track:hover td,
    table.dualtrack__table tbody tr.currently-playing-track:hover td,
    table tbody tr.hotkey-bg.currently-playing-track:hover td,
    table tbody tr.currently-playing-track:hover td {
      background-color: #354c85 !important; }

.zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-parent-table tbody tr.currently-playing-track,
table.dualtrack__table tbody tr.currently-playing-track {
  background-color: #394671 !important; }
  .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-parent-table tbody tr.currently-playing-track td,
  table.dualtrack__table tbody tr.currently-playing-track td {
    background-color: #394671 !important; }
  .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-parent-table tbody tr.currently-playing-track:hover,
  table.dualtrack__table tbody tr.currently-playing-track:hover {
    background-color: #354c85 !important; }
    .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-parent-table tbody tr.currently-playing-track:hover td,
    table.dualtrack__table tbody tr.currently-playing-track:hover td {
      background-color: #354c85 !important; }

.zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-child-table tbody tr.currently-playing-track,
table.dualtrack__table tbody tr.currently-playing-track {
  background-color: #394671 !important; }
  .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-child-table tbody tr.currently-playing-track td,
  table.dualtrack__table tbody tr.currently-playing-track td {
    background-color: #394671 !important; }
  .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-child-table tbody tr.currently-playing-track:hover,
  table.dualtrack__table tbody tr.currently-playing-track:hover {
    background-color: #354c85 !important; }
    .zd-new-top-downloads-main-table tbody tr td table.zd-new-top-downloads-child-table tbody tr.currently-playing-track:hover td,
    table.dualtrack__table tbody tr.currently-playing-track:hover td {
      background-color: #354c85 !important; }

.zd-new-newrelease-track-view .zd-new-newreleases-page-child-table {
  border-spacing: 0 1px !important; }

.currently-playing-track .zd-album-title .release-name {
  color: #dddddd !important; }

.currently-playing-track .zd-artist-name {
  color: #dddddd !important; }

.currently-playing-track .trendingcharttrack__bpm span {
  color: #8e8e8e !important; }

.currently-playing-track .trendingcharttrack__key span {
  color: #8e8e8e !important; }

.currently-playing-track .trendingcharttrack__release span {
  color: #8e8e8e !important; }

.currently-playing-track .zd-text-6b7883 {
  color: #8e8e8e !important; }

.zd-inner-table.zd-new-newreleases-page-child-table tbody tr.zd-row-newrelease.hotkey-bg {
  background-color: #303139 !important; }
  .zd-inner-table.zd-new-newreleases-page-child-table tbody tr.zd-row-newrelease.hotkey-bg td {
    background-color: #303139 !important; }

.zd-album-table.zd-inner-table.zd-new-newreleases-page-child-table tbody tr.hotkey-bg.currently-playing-track td {
  background: #394671 !important; }
  .zd-album-table.zd-inner-table.zd-new-newreleases-page-child-table tbody tr.hotkey-bg.currently-playing-track td:hover {
    background-color: #354c85; }

.zd-album-table.zd-new-newrelease-track-view.zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr.zd-row-newrelease.hotkey-bg,
.zd-album-table.zd-new-newrelease-track-view.zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr.zd-selectable-row.hotkey-bg {
  background-color: #303139 !important; }
  .zd-album-table.zd-new-newrelease-track-view.zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr.zd-row-newrelease.hotkey-bg td,
  .zd-album-table.zd-new-newrelease-track-view.zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr.zd-selectable-row.hotkey-bg td {
    background-color: #303139 !important; }

.zd-album-table.zd-new-newrelease-track-view.zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr.zd-row-newrelease.hotkey-bg.currently-playing-track td,
.zd-album-table.zd-new-newrelease-track-view.zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr.zd-selectable-row.hotkey-bg.currently-playing-track td {
  background: #394671 !important; }
  .zd-album-table.zd-new-newrelease-track-view.zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr.zd-row-newrelease.hotkey-bg.currently-playing-track td:hover,
  .zd-album-table.zd-new-newrelease-track-view.zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr.zd-selectable-row.hotkey-bg.currently-playing-track td:hover {
    background-color: #354c85; }

.zd-new-top-downloads-parent-table.zd-topdownloads-table .zd-selectable-row.zd-row-topdownload.hotkey-bg {
  background-color: #303139 !important; }
  .zd-new-top-downloads-parent-table.zd-topdownloads-table .zd-selectable-row.zd-row-topdownload.hotkey-bg td {
    background-color: #303139 !important; }

.zd-new-top-downloads-parent-table.zd-topdownloads-table .zd-selectable-row.zd-row-topdownload.hotkey-bg.currently-playing-track td {
  background: #394671 !important; }
  .zd-new-top-downloads-parent-table.zd-topdownloads-table .zd-selectable-row.zd-row-topdownload.hotkey-bg.currently-playing-track td:hover {
    background-color: #354c85; }

.zd-new-top-downloads-child-table.zd-topdownloads-table.top_charts_child_visible .zd-single-release-hover.zd-selectable-row.zd-row-topdownload.hotkey-bg {
  background-color: #303139 !important; }
  .zd-new-top-downloads-child-table.zd-topdownloads-table.top_charts_child_visible .zd-single-release-hover.zd-selectable-row.zd-row-topdownload.hotkey-bg td {
    background-color: #303139 !important; }

.zd-new-top-downloads-child-table.zd-topdownloads-table.top_charts_child_visible .zd-single-release-hover.zd-selectable-row.zd-row-topdownload.hotkey-bg.currently-playing-track td {
  background: #394671 !important; }
  .zd-new-top-downloads-child-table.zd-topdownloads-table.top_charts_child_visible .zd-single-release-hover.zd-selectable-row.zd-row-topdownload.hotkey-bg.currently-playing-track td:hover {
    background-color: #354c85; }

.zd-new-top-downloads-parent-table.zd-trending-table .zd-row-trendingcharts.zd-parent-table.hotkey-bg {
  background-color: #303139 !important; }
  .zd-new-top-downloads-parent-table.zd-trending-table .zd-row-trendingcharts.zd-parent-table.hotkey-bg td {
    background-color: #303139 !important; }

.zd-new-top-downloads-parent-table.zd-trending-table .zd-row-trendingcharts.zd-parent-table.hotkey-bg.currently-playing-track td {
  background: #394671 !important; }
  .zd-new-top-downloads-parent-table.zd-trending-table .zd-row-trendingcharts.zd-parent-table.hotkey-bg.currently-playing-track td:hover {
    background-color: #354c85; }

.zd-new-newreleases-page-child-table.top_charts_child_visible .zd-selectable-row.zd-row-topcharts.hotkey-bg {
  background-color: #303139 !important; }
  .zd-new-newreleases-page-child-table.top_charts_child_visible .zd-selectable-row.zd-row-topcharts.hotkey-bg td {
    background-color: #303139 !important; }

.zd-new-newreleases-page-child-table.top_charts_child_visible .zd-selectable-row.zd-row-topcharts.hotkey-bg.currently-playing-track {
  background: #394671 !important; }
  .zd-new-newreleases-page-child-table.top_charts_child_visible .zd-selectable-row.zd-row-topcharts.hotkey-bg.currently-playing-track:hover {
    background-color: #354c85; }
  .zd-new-newreleases-page-child-table.top_charts_child_visible .zd-selectable-row.zd-row-topcharts.hotkey-bg.currently-playing-track td {
    background: #394671 !important; }
    .zd-new-newreleases-page-child-table.top_charts_child_visible .zd-selectable-row.zd-row-topcharts.hotkey-bg.currently-playing-track td:hover {
      background-color: #354c85; }

.zd-display-table.zd-album-table .zd-row-downloadhistory.zd-selectable-row.hotkey-bg {
  background-color: #303139 !important; }
  .zd-display-table.zd-album-table .zd-row-downloadhistory.zd-selectable-row.hotkey-bg td {
    background-color: #303139 !important; }

.zd-display-table.zd-album-table .zd-row-downloadhistory.zd-selectable-row.hotkey-bg.currently-playing-track {
  background: #394671 !important; }
  .zd-display-table.zd-album-table .zd-row-downloadhistory.zd-selectable-row.hotkey-bg.currently-playing-track:hover {
    background-color: #354c85; }
  .zd-display-table.zd-album-table .zd-row-downloadhistory.zd-selectable-row.hotkey-bg.currently-playing-track td {
    background: #394671 !important; }
    .zd-display-table.zd-album-table .zd-row-downloadhistory.zd-selectable-row.hotkey-bg.currently-playing-track td:hover {
      background-color: #354c85; }

.zd-display-table.zd-album-table .zd-row-singlerelease.hotkey-bg {
  background-color: #303139 !important; }
  .zd-display-table.zd-album-table .zd-row-singlerelease.hotkey-bg td {
    background-color: #303139 !important; }

.zd-display-table.zd-album-table .zd-row-singlerelease.hotkey-bg.currently-playing-track {
  background: #394671 !important; }
  .zd-display-table.zd-album-table .zd-row-singlerelease.hotkey-bg.currently-playing-track:hover {
    background-color: #354c85; }
  .zd-display-table.zd-album-table .zd-row-singlerelease.hotkey-bg.currently-playing-track td {
    background: #394671 !important; }
    .zd-display-table.zd-album-table .zd-row-singlerelease.hotkey-bg.currently-playing-track td:hover {
      background-color: #354c85; }

.zd-display-table.zd-album-table .zd-row-singlepacks-list.hotkey-bg {
  background-color: #303139 !important; }
  .zd-display-table.zd-album-table .zd-row-singlepacks-list.hotkey-bg td {
    background-color: #303139 !important; }

.zd-display-table.zd-album-table .zd-row-singlepacks-list.hotkey-bg.currently-playing-track {
  background: #394671 !important; }
  .zd-display-table.zd-album-table .zd-row-singlepacks-list.hotkey-bg.currently-playing-track:hover {
    background-color: #354c85; }
  .zd-display-table.zd-album-table .zd-row-singlepacks-list.hotkey-bg.currently-playing-track td {
    background: #394671 !important; }
    .zd-display-table.zd-album-table .zd-row-singlepacks-list.hotkey-bg.currently-playing-track td:hover {
      background-color: #354c85; }

.zd-display-table.zd-album-table .zd-row-trending-packs.hotkey-bg {
  background-color: #303139 !important; }
  .zd-display-table.zd-album-table .zd-row-trending-packs.hotkey-bg td {
    background-color: #303139 !important; }

.zd-display-table.zd-album-table .zd-row-trending-packs.hotkey-bg.currently-playing-track {
  background: #394671 !important; }
  .zd-display-table.zd-album-table .zd-row-trending-packs.hotkey-bg.currently-playing-track:hover {
    background-color: #354c85; }
  .zd-display-table.zd-album-table .zd-row-trending-packs.hotkey-bg.currently-playing-track td {
    background: #394671 !important; }
    .zd-display-table.zd-album-table .zd-row-trending-packs.hotkey-bg.currently-playing-track td:hover {
      background-color: #354c85; }

.zd-display-table.zd-album-table .zd-row-track-search-result.hotkey-bg {
  background-color: #303139 !important; }
  .zd-display-table.zd-album-table .zd-row-track-search-result.hotkey-bg td {
    background-color: #303139 !important; }

.zd-display-table.zd-album-table .zd-row-track-search-result.hotkey-bg.currently-playing-track {
  background: #394671 !important; }
  .zd-display-table.zd-album-table .zd-row-track-search-result.hotkey-bg.currently-playing-track:hover {
    background-color: #354c85; }
  .zd-display-table.zd-album-table .zd-row-track-search-result.hotkey-bg.currently-playing-track td {
    background: #394671 !important; }
    .zd-display-table.zd-album-table .zd-row-track-search-result.hotkey-bg.currently-playing-track td:hover {
      background-color: #354c85; }

.zd-display-table.zd-album-table .zd-row-search-result.hotkey-bg {
  background-color: #303139 !important; }
  .zd-display-table.zd-album-table .zd-row-search-result.hotkey-bg td {
    background-color: #303139 !important; }

.zd-display-table.zd-album-table .zd-row-search-result.hotkey-bg.currently-playing-track {
  background: #394671 !important; }
  .zd-display-table.zd-album-table .zd-row-search-result.hotkey-bg.currently-playing-track:hover {
    background-color: #354c85; }
  .zd-display-table.zd-album-table .zd-row-search-result.hotkey-bg.currently-playing-track td {
    background: #394671 !important; }
    .zd-display-table.zd-album-table .zd-row-search-result.hotkey-bg.currently-playing-track td:hover {
      background-color: #354c85; }

.zd-new-top-downloads-child-table.zd-trending-table.trending_charts_child_visible .zd-single-release-hover.zd-row-trendingcharts.hotkey-bg {
  background-color: #303139 !important; }
  .zd-new-top-downloads-child-table.zd-trending-table.trending_charts_child_visible .zd-single-release-hover.zd-row-trendingcharts.hotkey-bg td {
    background-color: #303139 !important; }

.zd-new-top-downloads-child-table.zd-trending-table.trending_charts_child_visible .zd-single-release-hover.zd-row-trendingcharts.hotkey-bg.currently-playing-track {
  background: #394671 !important; }
  .zd-new-top-downloads-child-table.zd-trending-table.trending_charts_child_visible .zd-single-release-hover.zd-row-trendingcharts.hotkey-bg.currently-playing-track:hover {
    background-color: #354c85; }
  .zd-new-top-downloads-child-table.zd-trending-table.trending_charts_child_visible .zd-single-release-hover.zd-row-trendingcharts.hotkey-bg.currently-playing-track td {
    background: #394671 !important; }
    .zd-new-top-downloads-child-table.zd-trending-table.trending_charts_child_visible .zd-single-release-hover.zd-row-trendingcharts.hotkey-bg.currently-playing-track td:hover {
      background-color: #354c85; }

.top_charts_child_invisible,
.top_downloads_child_invisible,
.trending_charts_child_invisible {
  display: none; }

.top_charts_child_visible,
.top_downloads_child_visible,
.trending_charts_child_visible {
  display: table; }

.zd-feedback-release-highlight .hide-active-border-p .zd-feedback-active-border {
  right: 0 !important; }

@media (max-width: 750px) {
  .zd-home-sm-hide {
    display: none !important; } }

@media (max-width: 1023px) {
  .zd-home-tab-hide {
    display: none !important; } }

@media (min-width: 750px) {
  .zd-home-lg-hide {
    display: none !important; } }

.zd-md-show {
  display: none; }
  @media (min-width: 768px) and (max-width: 1224px) {
    .zd-md-show {
      display: block; } }

@media (min-width: 768px) and (max-width: 1224px) {
  .zd-md-hide {
    display: none; } }

@media only screen and (min-width: 0px) and (max-width: 1224px) {
  .zd-track-view .zd-title-new {
    width: 40%; } }

.zd-profile-icon {
  font-size: 20px;
  margin-right: 12px;
  color: #6b7883; }

.zdv3-icon-Instagram {
  font-size: 20px;
  margin-right: 12px;
  color: #6b7883; }

.zdv3-icon-SoundClord {
  font-size: 20px;
  margin-right: 12px;
  color: #6b7883; }

.zdv3-icon-Tweeter {
  font-size: 20px;
  margin-right: 12px;
  color: #6b7883; }

.zdv3-icon-Linkedin {
  font-size: 20px;
  margin-right: 12px;
  color: #6b7883; }

.zdv3-icon-Web {
  font-size: 20px;
  margin-right: 12px;
  color: #6b7883; }

.zdv3-icon-Youtube {
  font-size: 20px;
  margin-right: 12px;
  color: #6b7883; }

.zd-pre-page {
  display: flex; }
  .zd-pre-page .form-check {
    margin-right: 30px;
    margin-bottom: 0; }
    .zd-pre-page .form-check .form-check-input {
      margin-top: 0.1rem; }

@media (max-width: 750px) {
  .zd-top-charts-table .zd-new-newreleases-page-header-table .zd-large-album-cell .zd-filter-text {
    display: table-cell !important; } }

.zd-top-charts-table tbody tr .zd-new-newreleases-page-child-table tbody tr .zd-download-favourtie .zd-album-favourite i {
  display: none; }

.zd-top-charts-table tbody tr .zd-new-newreleases-page-child-table tbody tr .zd-download-favourtie .zd-album-favourite .zdv3-icon-filled-favourite {
  display: block;
  color: #4d7ce8 !important; }

.zd-top-charts-table tbody tr .zd-new-newreleases-page-child-table tbody tr:hover .zd-download-favourtie .zd-album-favourite i {
  display: block;
  color: #6b7883 !important; }

.zd-top-charts-table tbody tr .zd-new-newreleases-page-child-table tbody tr:hover .zd-download-favourtie .zd-album-favourite .zdv3-icon-filled-favourite {
  display: block;
  color: #4d7ce8 !important; }

.zd-search-result tbody tr .zd-download-favourtie {
  text-align: center; }
  .zd-search-result tbody tr .zd-download-favourtie .zd-album-favourite i {
    display: none; }
  .zd-search-result tbody tr .zd-download-favourtie .zd-album-favourite .zdv3-icon-filled-favourite {
    display: block;
    color: #4d7ce8 !important; }

.zd-search-result tbody tr:hover .zd-download-favourtie .zd-album-favourite i {
  display: block;
  color: #6b7883 !important; }

.zd-search-result tbody tr:hover .zd-download-favourtie .zd-album-favourite .zdv3-icon-filled-favourite {
  display: block;
  color: #4d7ce8 !important; }

@media (max-width: 750px) {
  .zd-search-page .zd-title-new .zd-v5_tack_name {
    display: none; }
  .zd-search-page .zd-hide {
    display: block !important; } }

.zd-search-page tbody tr .zd-download-favourtie {
  text-align: center; }
  .zd-search-page tbody tr .zd-download-favourtie .zd-album-favourite i {
    display: none; }
  .zd-search-page tbody tr .zd-download-favourtie .zd-album-favourite .zdv3-icon-filled-favourite {
    display: block;
    color: #4d7ce8 !important; }

.zd-search-page tbody tr:hover .zd-download-favourtie .zd-album-favourite i {
  display: block;
  color: #6b7883 !important; }

.zd-search-page tbody tr:hover .zd-download-favourtie .zd-album-favourite .zdv3-icon-filled-favourite {
  display: block;
  color: #4d7ce8 !important; }

.zd-downloadhistory-table tbody tr .zd-release-favourite {
  text-align: center; }
  .zd-downloadhistory-table tbody tr .zd-release-favourite .zd-album-favourite i {
    display: none; }
  .zd-downloadhistory-table tbody tr .zd-release-favourite .zd-album-favourite .zdv3-icon-filled-favourite {
    display: block;
    color: #4d7ce8 !important; }

.zd-downloadhistory-table tbody tr:hover .zd-release-favourite .zd-album-favourite i {
  display: block;
  color: #6b7883 !important; }

.zd-downloadhistory-table tbody tr:hover .zd-release-favourite .zd-album-favourite .zdv3-icon-filled-favourite {
  display: block;
  color: #4d7ce8 !important; }

.zd-tracklist-dropdown-box {
  position: absolute; }
  .zd-tracklist-dropdown-box .zd-dropdown-crate {
    margin-top: 8px; }
    @media (max-width: 750px) {
      .zd-tracklist-dropdown-box .zd-dropdown-crate {
        margin-top: 13px; } }

.zd-download-history {
  position: absolute; }
  .zd-download-history .zd-dropdown-crate {
    margin-top: 13px; }
  @media (min-width: 740px) and (max-width: 950px) {
    .zd-download-history .zd-dropdown-crate {
      margin-top: 8px; } }

.zd-topdownload-dropdown-box {
  position: absolute; }
  .zd-topdownload-dropdown-box .zd-dropdown-crate {
    margin-top: 19px; }
    @media (max-width: 750px) {
      .zd-topdownload-dropdown-box .zd-dropdown-crate {
        margin-top: 11px; } }

.zd-trending-chart-dropdown-box {
  position: absolute; }
  .zd-trending-chart-dropdown-box .zd-dropdown-crate {
    margin-top: 13px; }

.zd-top-chart-dropdown-box {
  position: absolute; }
  .zd-top-chart-dropdown-box .zd-dropdown-crate {
    margin-top: 8px; }

.zd-home-trending-dropdown-box {
  position: absolute; }
  .zd-home-trending-dropdown-box .zd-dropdown-crate {
    margin-top: 8px; }
    @media (max-width: 750px) {
      .zd-home-trending-dropdown-box .zd-dropdown-crate {
        margin-top: 11px; } }

@media (max-width: 750px) {
  .zd-singlepack-tbody .zd-release-crates .zd-dropdown-crate {
    margin-top: 7px; } }

@media (max-width: 750px) and (min-width: 400px) {
  .zd-singlepack-tbody .zd-double-icons {
    padding-right: 8px !important; } }

.zd-album-table .zd-trendingcharts-tbody .zd-double-icons a {
  padding: 4px 4px;
  font-size: 24px; }

.zd-album-table .zd-trendingcharts-tbody .zd-download-favourtie .zd-album-favourite {
  display: block !important;
  margin-bottom: -4px !important; }

.newrelease .zd-home-trending-dropdown-box {
  position: absolute; }
  @media (max-width: 750px) {
    .newrelease .zd-home-trending-dropdown-box .zd-dropdown-crate {
      margin-top: 8px; } }

@media (min-width: 0px) and (max-width: 750px) {
  .zd-favourite-dropdown-box {
    position: absolute; }
    .zd-favourite-dropdown-box .zd-dropdown-crate {
      margin-top: 9px; } }

.zd-favourite-dropdown-box .zd-dropdown-crate {
  position: absolute; }

.zd-favourite-dropdown-box .zd-dropdown-crate .crate-dropdown-menu {
  -webkit-transform: translate3d(-129px, 7px, 0px);
          transform: translate3d(-129px, 7px, 0px); }

@media (min-width: 750px) and (max-width: 10000px) {
  .zd-favourite-dropdown-box {
    position: absolute; }
    .zd-favourite-dropdown-box .zd-dropdown-crate {
      margin-top: 13px; } }
  @media (min-width: 750px) and (max-width: 10000px) and (min-width: 750px) and (max-width: 950px) {
    .zd-favourite-dropdown-box .zd-dropdown-crate {
      margin-top: 8px; } }

@media (min-width: 0px) and (max-width: 740px) {
  .zd-crate-dropdown-box {
    position: absolute;
    right: 18px; }
    .zd-crate-dropdown-box .zd-dropdown-crate {
      margin-top: 13px; } }

@media (min-width: 740px) and (max-width: 10000px) {
  .zd-crate-dropdown-box {
    position: absolute;
    right: 30px; }
    .zd-crate-dropdown-box .zd-dropdown-crate {
      margin-top: 13px; } }
  @media (min-width: 740px) and (max-width: 10000px) and (min-width: 740px) and (max-width: 950px) {
    .zd-crate-dropdown-box .zd-dropdown-crate {
      margin-top: 8px; } }

.deleting_playlist_modal {
  width: 360px;
  height: 130px; }
  .deleting_playlist_modal .modal-footer {
    background: none !important; }

.zd-favourite {
  display: table-cell !important; }

.zd-v5-single-packs .head-line {
  display: flex;
  justify-content: space-between; }
  .zd-v5-single-packs .head-line .zd-slick-navs {
    margin-right: 33px; }

.zd-v5-single-packs-inner .zd-release-back {
  margin-right: 56px !important; }

@media (max-width: 750px) {
  .zd-topdownloads-count {
    display: none !important; } }

.react-jinke-music-player-main h1,
.react-jinke-music-player-main h2,
.react-jinke-music-player-main h3,
.react-jinke-music-player-main h4,
.react-jinke-music-player-main h5,
.react-jinke-music-player-main h6,
.react-jinke-music-player-main p,
.react-jinke-music-player-main a,
.react-jinke-music-player-main td,
.react-jinke-music-player-main button,
.react-jinke-music-player-main span,
.react-jinke-music-player-main .h1,
.react-jinke-music-player-main .h2,
.react-jinke-music-player-main .h3,
.react-jinke-music-player-main .h4,
.react-jinke-music-player-main .h5,
.react-jinke-music-player-main .h6 {
  font-family: 'Poppins', sans-serif !important; }

body.player-active .cr-page {
  padding-bottom: 50px; }

body.player-active .zd-download-manager-sidebar .zd-download-footer {
  bottom: 85px; }

body .react-jinke-music-player-main .music-player-panel {
  box-shadow: none;
  background-color: #1f2024;
  height: 64px;
  z-index: 1018;
  display: none !important; }
  body .react-jinke-music-player-main .music-player-panel.active {
    display: block !important; }
  body .react-jinke-music-player-main .music-player-panel svg,
  body .react-jinke-music-player-main .music-player-panel i {
    color: #6b7883; }
  body .react-jinke-music-player-main .music-player-panel svg:active {
    color: #4f7be9 !important; }
  body .react-jinke-music-player-main .music-player-panel .duration,
  body .react-jinke-music-player-main .music-player-panel .reload-btn {
    display: none !important; }
  body .react-jinke-music-player-main .music-player-panel .current-time {
    align-self: center;
    padding-left: 1rem;
    font-weight: 300;
    font-size: 12px; }
  body .react-jinke-music-player-main .music-player-panel .panel-content {
    padding: 0; }
    body .react-jinke-music-player-main .music-player-panel .panel-content .img-content {
      -webkit-animation: unset;
              animation: unset;
      width: 64px;
      height: 64px;
      border-radius: 0;
      padding: 4px 0;
      border-radius: 2px !important; }
    body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content {
      padding: 10px 20px 10px 20px;
      height: 100%; }
      @media (max-width: 750px) {
        body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content {
          padding: 10px 5px 10px 10px; } }
      body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content .audio-title {
        display: block;
        float: left;
        padding-top: 2px;
        max-width: 184px;
        width: 184px; }
        @media (max-width: 750px) {
          body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content .audio-title {
            max-width: 180px;
            width: 180px; } }
        @media (max-width: 450px) {
          body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content .audio-title {
            max-width: 150px;
            width: 150px; } }
        @media (max-width: 400px) {
          body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content .audio-title {
            max-width: 120px;
            width: 120px; } }
        @media (max-width: 375px) {
          body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content .audio-title {
            max-width: 85px;
            width: 85px; } }
        @media (max-width: 330px) {
          body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content .audio-title {
            max-width: 50px;
            width: 50px; } }
        @media (max-width: 330px) {
          body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content .audio-title {
            display: none; } }
        body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content .audio-title .the-name {
          display: block;
          color: #dddddd;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          overflow: hidden;
          text-overflow: ellipsis; }
        body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content .audio-title .the-mix {
          display: block; }
          body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content .audio-title .the-mix em {
            font-style: normal;
            color: #757575;
            line-height: 12.1px; }
        body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content .audio-title .the-artist {
          font-size: 12px;
          font-weight: 300;
          color: #ffffff !important; }
          body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content .audio-title .the-artist em {
            font-style: normal;
            line-height: 12.1px; }
      body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content .audio-main {
        float: right;
        width: calc(100% - 200px);
        margin-top: 0;
        height: 100%; }
        @media (max-width: 750px) {
          body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content .audio-main {
            float: none; } }
      body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content .progress-bar {
        height: 100%;
        width: 100%;
        padding-top: 2px;
        padding-bottom: 2px;
        background-repeat: no-repeat;
        background-position: left center;
        background-size: contain;
        background-color: transparent;
        margin-right: 0;
        margin-top: 0; }
        body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content .progress-bar .zd-manage-track-player {
          margin-left: 24px;
          margin-right: 24px; }
          @media (max-width: 750px) {
            body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content .progress-bar .zd-manage-track-player {
              margin-left: 10px;
              margin-right: 10px; } }
        body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content .progress-bar .zd-wave-preview {
          height: 100%;
          width: 100%;
          max-width: none;
          position: absolute;
          transition-property: visibility, opacity, -webkit-transform;
          transition-property: visibility, opacity, transform;
          transition-property: visibility, opacity, transform, -webkit-transform;
          transition-duration: 0.3s;
          transition-timing-function: ease-out;
          -moz-transition-property: visibility, opacity, transform;
          -moz-transition-duration: 0.3s;
          -moz-transition-timing-function: ease-out;
          -webkit-transition-property: visibility, opacity, transform;
          -webkit-transition-duration: 0.3s;
          -webkit-transition-timing-function: ease-out;
          -o-transition-property: visibility, opacity, transform;
          -o-transition-duration: 0.3s;
          -o-transition-timing-function: ease-out;
          -ms-transition-property: visibility, opacity, transform;
          -ms-transition-duration: 0.3s;
          -ms-transition-timing-function: ease-out; }
        body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content .progress-bar .progress-load-bar {
          display: none; }
    body .react-jinke-music-player-main .music-player-panel .panel-content .player-content {
      padding-left: 20px; }
      body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .play-sounds {
        margin-left: 0px; }
        body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .play-sounds .sounds-icon {
          margin-right: 10px; }
          @media (max-width: 1224px) and (min-width: 750px) {
            body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .play-sounds .sounds-icon {
              flex: none; } }
          body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .play-sounds .sounds-icon i {
            font-size: 22px; }
        body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .play-sounds .sound-operation {
          width: 80px; }
          body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .play-sounds .sound-operation .rc-slider-handle {
            width: 12px;
            height: 12px;
            background-color: #909090 !important; }
            body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .play-sounds .sound-operation .rc-slider-handle:focus {
              box-shadow: none;
              border-color: none; }
      body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .player-close {
        max-width: 20px;
        margin-right: 32px; }
        @media (max-width: 750px) {
          body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .player-close {
            margin-left: 20px; } }
        @media (max-width: 750px) {
          body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .player-close {
            margin-left: 10px; } }
      body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .group {
        padding: 0; }
      body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .volume-icon {
        font-size: 24px !important; }
      body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .heart-icon {
        padding-right: 20px; }
      body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .play-btn {
        padding: 0 12px;
        margin: 0 10px; }
        body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .play-btn svg {
          font-size: 40px; }
      body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .audio-keys-btn {
        max-width: 30px;
        margin-right: 20px; }
      body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .audio-keys-icon i {
        font-size: 24px; }
      body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .audio-keys-icon svg {
        font-size: 22px;
        margin-top: 3px; }
      body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .audio-lists-btn {
        min-width: unset;
        margin-right: 10px !important;
        margin-left: 15px !important;
        box-shadow: none !important;
        background-color: transparent !important; }
      body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .audio-download {
        margin-right: 8px; }
        body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .audio-download i {
          color: #dddddd;
          font-size: 16px; }
      body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .prev-audio svg,
      body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .prev-audio i,
      body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .next-audio svg,
      body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .next-audio i,
      body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .audio-download svg,
      body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .audio-download i,
      body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .audio-lists-icon svg,
      body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .audio-lists-icon i,
      body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .play-sounds svg,
      body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .play-sounds i {
        font-size: 16px;
        opacity: 1; }

body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .play-sounds .sound-operation .rc-slider-track {
  background-color: #4f7be9 !important; }

body .react-jinke-music-player-main .rc-slider-track,
body .react-jinke-music-player-main .rc-slider-rail {
  height: 4px; }

body .hot-keys-panel {
  background-color: #1f2024;
  height: 370px;
  width: 380px; }

body .zd-media-panel-content {
  padding: 10px;
  padding-top: 0px;
  color: #919191;
  font-size: 13px;
  line-height: 20px; }
  body .zd-media-panel-content .panel-content-bg {
    background-color: #242529;
    padding: 10px; }

.zd-hot-keys-content h4 {
  font-size: 16px;
  color: #e7e7e7;
  margin: 0 0 10px 0; }

.zd-hot-keys-content ul {
  margin: 0 0 20px 0; }

body .react-jinke-music-player-main .react-jinke-music-player-mobile {
  top: auto;
  padding: 15px; }
  body .react-jinke-music-player-main .react-jinke-music-player-mobile .react-jinke-music-player-mobile-play-model-tip {
    display: none !important; }
  body .react-jinke-music-player-main .react-jinke-music-player-mobile .play-btn svg {
    color: #dddddd; }
  body .react-jinke-music-player-main .react-jinke-music-player-mobile .audio-download {
    color: #6b7883;
    font-size: 20px; }
  body .react-jinke-music-player-main .react-jinke-music-player-mobile .audio-download-icon {
    color: #6b7883 !important; }
  body .react-jinke-music-player-main .react-jinke-music-player-mobile svg,
  body .react-jinke-music-player-main .react-jinke-music-player-mobile a,
  body .react-jinke-music-player-main .react-jinke-music-player-mobile i {
    color: #6b7883; }
  body .react-jinke-music-player-main .react-jinke-music-player-mobile svg:hover,
  body .react-jinke-music-player-main .react-jinke-music-player-mobile svg:active,
  body .react-jinke-music-player-main .react-jinke-music-player-mobile a:hover,
  body .react-jinke-music-player-main .react-jinke-music-player-mobile i:hover {
    color: #b7b7b7 !important; }
  body .react-jinke-music-player-main .react-jinke-music-player-mobile .rc-slider-handle:focus {
    box-shadow: none;
    border-color: none;
    background-color: #4f7be9; }
  body .react-jinke-music-player-main .react-jinke-music-player-mobile .rc-slider-track,
  body .react-jinke-music-player-main .react-jinke-music-player-mobile .rc-slider-handle {
    background-color: #909090 !important; }

body .react-jinke-music-player-main .react-jinke-music-player-mobile-toggle {
  padding: 2px 0 0 0; }
  body .react-jinke-music-player-main .react-jinke-music-player-mobile-toggle .play-btn {
    padding: 0 20px; }

body .audio-lists-panel-content .audio-item .player-time {
  overflow: hidden;
  line-height: 17px; }

.circle {
  width: 32px;
  height: 32px;
  border: 1px solid #2e2e2e;
  border-radius: 50%; }

.clear-all {
  color: #5c656d;
  font-size: 12px;
  cursor: pointer;
  padding-left: 5px; }

.vertical-sound {
  padding-left: 13px !important;
  position: relative;
  margin-right: 0px !important; }

.vertical-sound:hover .vertical-sound-bar {
  display: block; }

@media (min-width: 1224px) {
  .vertical-sound-bar {
    position: absolute;
    display: none;
    bottom: 32px;
    right: 10px;
    height: 90px;
    width: 18px;
    background-color: #1f2024; }
    .vertical-sound-bar:hover {
      display: inline-block; }
  .vertical-sound-bar:hover {
    display: block; }
  .horaizonal-sound-bar {
    display: none; } }

@media only screen and (min-width: 767px) and (max-width: 1224px) {
  .horaizonal-sound-bar {
    position: relative;
    bottom: 0px;
    right: 0px;
    height: 12px;
    width: 100px;
    background-color: #1f2024; }
    .horaizonal-sound-bar:hover {
      display: inline-block; } }

.rc-slider-vertical .rc-slider-handle {
  margin-left: -4px !important; }

.audio-lists-panel {
  transition: max-height 1s;
  background: #1f2024; }
  .audio-lists-panel .audio-lists-panel-header {
    border: none;
    box-shadow: none; }
  .audio-lists-panel .audio-lists-panel-content {
    padding-left: 20px;
    padding-right: 20px; }
    .audio-lists-panel .audio-lists-panel-content .audio-item {
      background: #242529 !important;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 2px;
      height: 48px;
      line-height: 18px !important; }
      .audio-lists-panel .audio-lists-panel-content .audio-item .audio-item-song-details {
        flex-grow: 1;
        font-size: 12px; }
        .audio-lists-panel .audio-lists-panel-content .audio-item .audio-item-song-details .audio-item-name {
          color: #ffffff;
          font-weight: 500; }
        .audio-lists-panel .audio-lists-panel-content .audio-item .audio-item-song-details .audio-item-singer {
          color: #6b7883;
          font-weight: 400; }
      .audio-lists-panel .audio-lists-panel-content .audio-item .player-icons {
        color: #4f7be9; }
        .audio-lists-panel .audio-lists-panel-content .audio-item .player-icons i {
          font-size: 18px !important; }
      .audio-lists-panel .audio-lists-panel-content .audio-item.playing {
        background: #394671 !important; }
        .audio-lists-panel .audio-lists-panel-content .audio-item.playing .audio-item-singer {
          color: #8e8e8e; }
        .audio-lists-panel .audio-lists-panel-content .audio-item.playing .player-delete svg {
          color: #8e8e8e !important; }
    .audio-lists-panel .audio-lists-panel-content .player-delete {
      color: #6b7883; }

.audio-lists-panel.expand {
  min-height: 80vh;
  transition: min-height 1s; }

.mouse-interactions i:hover,
.mouse-interactions svg:hover {
  color: #dddddd !important; }

.mouse-interactions i:active,
.mouse-interactions svg:active {
  color: #4f7be9 !important; }

.mouse-interactions i:focus,
.mouse-interactions svg:focus {
  color: #4f7be9 !important; }

.mouse-interactions i:disabled,
.mouse-interactions svg:disabled {
  color: #47484a !important; }

.circle-play {
  position: relative;
  height: 40px;
  width: 40px; }
  .circle-play .play-icons {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .circle-play .play-icons .zdv3-icon-play {
      margin-left: 3px; }

.expand-arrow {
  color: #4f7be9;
  -webkit-transform: scaleY(-1);
          transform: scaleY(-1); }

.zdv3-icon-close-player {
  font-size: 16px !important;
  color: #6b7883 !important; }

.circle-play .zdv3-icon-play {
  font-size: 1.2rem !important; }

.circle-play .zdv3-icon-pause {
  font-size: 1rem !important; }

.zd-player-section-2 {
  margin-right: -13px;
  margin-left: -15px;
  padding-left: 22px !important; }
  @media (max-width: 750px) {
    .zd-player-section-2 {
      padding-left: 10px !important;
      margin-right: -4px; } }

.modal {
  z-index: 1111; }
  .modal.show {
    display: flex;
    display: -webkit-flex;
    opacity: 1; }
  .modal.fade .modal-dialog {
    transition: none !important; }
  .modal .modal-dialog {
    -webkit-animation: none !important;
    animation: none !important; }
    .modal .modal-dialog .modal-header {
      background: #222327;
      padding: 1rem;
      border: none; }
      .modal .modal-dialog .modal-header .modal-title {
        color: #eaeaea;
        font-size: 1rem;
        margin: 0;
        font-weight: bold; }
      .modal .modal-dialog .modal-header .close {
        color: #eaeaea;
        opacity: 1;
        -webkit-transform: scale(1.5);
        transform: scale(1.5); }
    .modal .modal-dialog .modal-footer {
      background: #222327;
      padding: 1rem;
      border: none; }
    .modal .modal-dialog .modal-body {
      max-height: none;
      overflow: hidden;
      padding: 1.1rem; }
  .modal .modal-content {
    background-color: #1c1e23;
    border: none;
    letter-spacing: 0.05em;
    color: #dddddd;
    overflow: hidden; }
    .modal .modal-content .zd-tag-list-wrap .zd-head-info-wrap {
      background: #1c1e23;
      position: absolute;
      top: 10px;
      width: 92%;
      margin-bottom: 10px;
      z-index: 2;
      min-height: 300px; }
    .modal .modal-content .zd-tags-group-content {
      padding-top: 40px;
      overflow: hidden; }
    .modal .modal-content .content {
      max-height: 70vh;
      overflow-y: auto;
      overflow-x: hidden; }

.modal-backdrop.show {
  background: rgba(60, 59, 59, 0.94);
  opacity: 0.96; }

.zd-flash-modal {
  position: fixed;
  display: none;
  top: 50%;
  left: calc(50% + 56px);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  margin-left: -25px;
  width: 300px;
  text-align: center;
  padding: 45px 20px;
  color: #eaeaea;
  font-weight: bold;
  background: #0d0d0d;
  z-index: 99999;
  font-size: 110%;
  background: #19191a;
  box-shadow: 0 3px 6px #171717;
  border: 1px solid #232323; }
  .zd-flash-modal.zd-open-modal {
    display: block !important; }
  .zd-flash-modal i {
    display: block;
    font-size: 50px;
    color: #4d7ce8; }
  .zd-flash-modal span.zd-message {
    margin-top: 15px;
    display: block;
    font-size: 14px;
    font-weight: normal;
    color: #e1e1e1; }

@media (min-width: 576px) {
  .modal-sm {
    max-width: 320px; } }

.modal .zd-modal-form-error-msg {
  line-height: 1.1em;
  margin: 8px 0px 10px; }

.zd-modal-content {
  height: 80px; }

.zd-feedback-modal-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) !important;
          transform: translate(-50%, -50%) !important; }
  .zd-feedback-modal-content .zd-feedback-modal-body-content .zd-modal-content {
    height: 80px;
    padding: 0 20px;
    line-height: 2rem; }
  .zd-feedback-modal-content i {
    display: block;
    font-size: 50px;
    color: #4d7ce8; }
  .zd-feedback-modal-content span.zd-message {
    margin-top: 15px;
    display: block;
    font-size: 14px;
    font-weight: normal;
    color: #e1e1e1; }
  .zd-feedback-modal-content .form-group {
    margin-bottom: 0.5rem; }
  .zd-feedback-modal-content .zd-reviews-star-hover1 {
    color: red; }
  .zd-feedback-modal-content .zd-reviews-star-hover2 {
    color: orange; }
  .zd-feedback-modal-content .zd-reviews-star-hover3 {
    color: yellow; }
  .zd-feedback-modal-content .zd-reviews-star-hover4 {
    color: green; }
  .zd-feedback-modal-content .zd-reviews-star-hover5 {
    color: #4f7be9; }
  .zd-feedback-modal-content .zd-feedback-span-message {
    line-height: 1.1em;
    min-height: 2.2em;
    font-size: 10px; }
  .zd-feedback-modal-content .zd-feedback-span-message1,
  .zd-feedback-modal-content .zd-feedback-span-message2,
  .zd-feedback-modal-content .zd-feedback-span-message3,
  .zd-feedback-modal-content .zd-feedback-span-message4,
  .zd-feedback-modal-content .zd-feedback-span-message5 {
    display: none; }
  .zd-feedback-modal-content .active-span {
    color: white;
    display: block;
    margin: 8px 0px 0px; }
  .zd-feedback-modal-content .form-label {
    color: #eaeaea; }

span.zd-message {
  margin-top: 15px;
  display: block;
  font-size: 14px;
  font-weight: normal;
  color: #e1e1e1; }

.form-group {
  margin-bottom: 0.5rem; }

.zdv3-icon-star {
  color: #6b7883;
  padding-left: 0.2rem !important; }

.zd-reviews-star-hover {
  color: #4f7be9 !important; }

.zd-feedback-rating-comment-section {
  width: 56%; }

.zd-feedback-form .form-group {
  margin-bottom: 0; }

.zd-feedback-form .form-control {
  background-color: #1f2024;
  /* border-color: #212226; */
  border: none;
  border-radius: 2px !important; }

.zd-change-icon {
  color: #4f7be9 !important; }

.zd-feedback-comment {
  width: 100% !important;
  padding-left: 16px;
  position: relative; }
  .zd-feedback-comment input {
    height: 32px !important; }
  .zd-feedback-comment input::-webkit-input-placeholder {
    color: #6b7883; }
  .zd-feedback-comment input::placeholder {
    color: #6b7883; }
  .zd-feedback-comment i {
    position: absolute;
    top: 6px;
    right: 8px;
    color: #303139;
    font-size: 14px; }
  .zd-feedback-comment:hover i {
    color: #dddddd; }
  .zd-feedback-comment input:hover,
  .zd-feedback-comment input:focus {
    cursor: pointer;
    color: #dddddd;
    box-shadow: none; }
    .zd-feedback-comment input:hover::-webkit-input-placeholder, .zd-feedback-comment input:focus::-webkit-input-placeholder {
      color: #dddddd; }
    .zd-feedback-comment input:hover::placeholder,
    .zd-feedback-comment input:focus::placeholder {
      color: #dddddd; }

.zd-feedback-dropdown-btn {
  height: 32px;
  width: 200px;
  float: right;
  border-radius: 2px !important;
  padding-left: 12px !important;
  padding-right: 8px !important;
  padding-top: 7px;
  padding-bottom: 7px;
  color: #6b7883;
  background: #1f2024;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.2rem !important;
  border-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: left;
  font-family: 'Poppins'; }
  .zd-feedback-dropdown-btn .zd-feedback-favourite-mix-value {
    text-align: left;
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis; }
  .zd-feedback-dropdown-btn.btn-secondary:hover, .zd-feedback-dropdown-btn.btn-secondary:focus {
    border: none;
    background: #1f2024;
    color: #dddddd;
    box-shadow: 0 0 0 0; }
  .zd-feedback-dropdown-btn.btn-secondary:focus .zdv3-icon-drop-down {
    color: #4f7be9 !important; }
  .zd-feedback-dropdown-btn.btn-secondary:not(:disabled):not(.disabled):active {
    border: none;
    background: #303139;
    color: #dddddd;
    box-shadow: 0 0 0 0; }

.zd-feedback-release-highlight .zd-feedback-active-border {
  right: 17px !important; }

.zd-feedback-active-border {
  position: absolute;
  background: #4f7be9;
  height: 80px;
  width: 4px;
  right: 0;
  border-radius: 0 2px 2px 0; }

.zd-feedback-drop-down {
  top: -1px !important;
  background: #242529;
  border: none;
  color: #6b7883;
  padding: 0;
  min-width: 200px; }
  .zd-feedback-drop-down button {
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis; }
    .zd-feedback-drop-down button :hover {
      background-color: #303139; }

@media (min-width: 1366px) {
  .zd-feedback-drop-down.dropdown-menu.show {
    position: absolute !important;
    will-change: transform !important;
    overflow: hidden scroll !important;
    height: 200px !important;
    width: 200px !important;
    left: 78px !important;
    pointer-events: all;
    -webkit-transform: translate3d(49px, 31px, 0px) !important;
            transform: translate3d(49px, 31px, 0px) !important; }
    .zd-feedback-drop-down.dropdown-menu.show button {
      pointer-events: all; } }

@media (min-width: 1230px) and (max-width: 1366px) {
  .zd-feedback-drop-down.dropdown-menu.show {
    -webkit-transform: translate3d(81px, 31px, 0px) !important;
            transform: translate3d(81px, 31px, 0px) !important; }
    .zd-feedback-drop-down.dropdown-menu.show button {
      pointer-events: all; } }

@media (min-width: 750px) and (max-width: 1224px) {
  .zd-feedback-drop-down.dropdown-menu.show {
    position: absolute !important;
    will-change: transform !important;
    overflow: hidden scroll !important;
    height: 192px !important;
    width: 200px !important;
    left: 19px !important;
    -webkit-transform: translate3d(-4px, 32px, 0px) !important;
            transform: translate3d(-4px, 32px, 0px) !important; } }

@media (min-width: 0px) and (max-width: 750px) {
  .zd-feedback-drop-down.dropdown-menu.show {
    position: absolute !important;
    will-change: transform !important;
    overflow: hidden scroll !important;
    height: 200px !important;
    width: 200px !important;
    left: 0px !important;
    -webkit-transform: unset !important;
            transform: unset !important; } }

.zd-feedback-favourite-mix-dropdown {
  position: absolute !important;
  padding-left: 7%; }

.zd-feedback-favorite-mix-section {
  width: 24% !important;
  position: unset; }
  .zd-feedback-favorite-mix-section .zd-feedback-favorite-mix {
    display: inline-block;
    margin-left: 10px;
    width: 65%;
    height: 32px;
    color: #6b7883;
    padding-left: 12px; }
    .zd-feedback-favorite-mix-section .zd-feedback-favorite-mix:focus {
      box-shadow: none; }
  .zd-feedback-favorite-mix-section .form-label {
    padding-top: 7px; }

.zd-feedback-header {
  padding-top: 12px;
  padding-bottom: 12px; }

.zd-feedback-rating-section .form-label {
  padding-top: 7px; }

.zd-feedback-rating-section .zd-feedback-rating {
  padding-top: 4px;
  margin-left: 8px; }

.zd-feedback-span-message {
  line-height: 1.1em;
  min-height: 2.2em;
  font-size: 10px; }

.zd-feedback-button .zd-feedback-submit-button {
  padding-left: 12px; }

.zd-feedback-button .zd-cancel-btn,
.zd-feedback-button .zd-submit-btn {
  height: 32px; }

.zd-feedback-span-message1,
.zd-feedback-span-message2,
.zd-feedback-span-message3,
.zd-feedback-span-message4,
.zd-feedback-span-message5 {
  display: none; }

.active-span {
  color: white;
  display: block;
  margin: 8px 0px 0px; }

.form-label {
  color: #eaeaea; }

.modal-dialog.zd-feedback-modal-content {
  width: 450px !important; }

.modal-content {
  border-radius: 0px !important; }

.small-screen-center .btn {
  font-size: 12px;
  padding: 7px 16px;
  height: 32px; }

.zd-cancel-btn,
.zd-cancel-btn:hover {
  border: 2px solid #414249;
  box-sizing: border-box;
  border-radius: 2px !important;
  background: transparent;
  color: #dddddd; }

.zd-submit-btn {
  background: #4f7be9;
  border-radius: 2px !important;
  color: #dddddd; }

.zd-submit-btn:hover {
  background: #2d5dc8; }

/* My DJ information CSS */
.zdv4-djinfo-inner-content .nav-tabs {
  border-bottom: 0px !important; }

.zdv4-djinfo-inner-content .zd-line-tab {
  margin-bottom: 10px;
  float: right; }

.zdv4-djinfo-inner-content .btn-secondary:not(:disabled):not(.disabled):active,
.zdv4-djinfo-inner-content .btn-secondary:not(:disabled):not(.disabled).active,
.zdv4-djinfo-inner-content .show > .btn-secondary.dropdown-toggle {
  background-color: #4f7be9;
  border-color: #303139; }

.zdv4-djinfo-inner-content .btn {
  color: #ffffff;
  background: transparent;
  font-family: 'Poppins';
  height: 32px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border-radius: 2px !important;
  border: 1px solid #303139;
  margin-left: 20px; }
  .zdv4-djinfo-inner-content .btn.active {
    background: #4f7be9; }
  .zdv4-djinfo-inner-content .btn:focus {
    box-shadow: unset !important; }

.zdv4-djinfo-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) !important;
          transform: translate(-50%, -50%) !important; }
  .zdv4-djinfo-modal .col {
    padding: 0px; }
  .zdv4-djinfo-modal .row {
    margin-right: 0px;
    margin-left: 0px; }
  .zdv4-djinfo-modal .form-control:focus {
    color: #dddddd;
    border-color: #1c1e22;
    outline: 0;
    box-shadow: unset; }
  .zdv4-djinfo-modal.zdv4-djinfo-club-modal {
    max-width: 900px !important; }
    .zdv4-djinfo-modal.zdv4-djinfo-club-modal .modal-content {
      width: 900px;
      background-color: #242529; }
      @media (max-width: 991px) {
        .zdv4-djinfo-modal.zdv4-djinfo-club-modal .modal-content {
          width: 728px; } }
      @media (max-width: 767px) {
        .zdv4-djinfo-modal.zdv4-djinfo-club-modal .modal-content {
          width: 343px; } }
    .zdv4-djinfo-modal.zdv4-djinfo-club-modal .zd-club-night-col,
    .zdv4-djinfo-modal.zdv4-djinfo-club-modal .zd-club-type-col {
      padding: 0px; }
      @media (min-width: 991px) {
        .zdv4-djinfo-modal.zdv4-djinfo-club-modal .zd-club-night-col,
        .zdv4-djinfo-modal.zdv4-djinfo-club-modal .zd-club-type-col {
          margin-right: 27px; } }
    .zdv4-djinfo-modal.zdv4-djinfo-club-modal .zd-club-week {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(89px, 1fr)); }
      @media (max-width: 834px) {
        .zdv4-djinfo-modal.zdv4-djinfo-club-modal .zd-club-week {
          grid-template-columns: repeat(auto-fill, minmax(110px, 1fr)); } }
      @media (max-width: 767px) {
        .zdv4-djinfo-modal.zdv4-djinfo-club-modal .zd-club-week {
          grid-template-columns: repeat(auto-fill, minmax(89px, 1fr)); } }
    @media (min-width: 768px) {
      .zdv4-djinfo-modal.zdv4-djinfo-club-modal .zd-club-type-sub-col {
        margin-right: 1.5rem; } }
    .zdv4-djinfo-modal.zdv4-djinfo-club-modal div {
      padding: 0px; }
    .zdv4-djinfo-modal.zdv4-djinfo-club-modal .zd-djinfo-second-col {
      justify-content: flex-end; }
  .zdv4-djinfo-modal.zdv4-djinfo-magazine-modal {
    max-width: 700px !important; }
    .zdv4-djinfo-modal.zdv4-djinfo-magazine-modal .modal-content {
      width: 700px;
      background-color: #242529; }
      @media (max-width: 991px) {
        .zdv4-djinfo-modal.zdv4-djinfo-magazine-modal .modal-content {
          width: 700px; } }
      @media (max-width: 767px) {
        .zdv4-djinfo-modal.zdv4-djinfo-magazine-modal .modal-content {
          width: 343px; } }
    .zdv4-djinfo-modal.zdv4-djinfo-magazine-modal div {
      padding: 0px; }
    .zdv4-djinfo-modal.zdv4-djinfo-magazine-modal .zd-magazine-type-col {
      padding: 0px; }
      @media (min-width: 991px) {
        .zdv4-djinfo-modal.zdv4-djinfo-magazine-modal .zd-magazine-type-col {
          margin-right: 40px; } }
      .zdv4-djinfo-modal.zdv4-djinfo-magazine-modal .zd-magazine-type-col .form-label {
        white-space: nowrap; }
      .zdv4-djinfo-modal.zdv4-djinfo-magazine-modal .zd-magazine-type-col.zd-magazine-published .form-checkbox {
        margin-right: 20px; }
  .zdv4-djinfo-modal.zdv4-djinfo-radio-modal {
    max-width: 900px !important; }
    .zdv4-djinfo-modal.zdv4-djinfo-radio-modal .modal-content {
      width: 900px;
      background-color: #242529; }
      @media (max-width: 991px) {
        .zdv4-djinfo-modal.zdv4-djinfo-radio-modal .modal-content {
          width: 728px; } }
      @media (max-width: 767px) {
        .zdv4-djinfo-modal.zdv4-djinfo-radio-modal .modal-content {
          width: 343px; } }
    .zdv4-djinfo-modal.zdv4-djinfo-radio-modal .col {
      display: flex; }
    .zdv4-djinfo-modal.zdv4-djinfo-radio-modal .zd-djinfo-second-col {
      justify-content: flex-end; }
    .zdv4-djinfo-modal.zdv4-djinfo-radio-modal .zd-radio-type-col {
      padding: 0px; }
      @media (min-width: 991px) {
        .zdv4-djinfo-modal.zdv4-djinfo-radio-modal .zd-radio-type-col {
          margin-right: 40px; } }
      .zdv4-djinfo-modal.zdv4-djinfo-radio-modal .zd-radio-type-col .form-label {
        white-space: nowrap; }
    .zdv4-djinfo-modal.zdv4-djinfo-radio-modal div {
      padding: 0px; }
  .zdv4-djinfo-modal .modal-header {
    background-color: #242529 !important;
    padding-left: 32px !important;
    padding-top: 24px !important; }
    .zdv4-djinfo-modal .modal-header .close {
      display: none; }
      @media (max-width: 767px) {
        .zdv4-djinfo-modal .modal-header .close {
          display: block; } }
    .zdv4-djinfo-modal .modal-header .modal-title {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      color: #dddddd !important; }
  .zdv4-djinfo-modal .modal-body {
    padding: 24px 32px !important; }
  .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .form-group {
    margin-bottom: 15px !important; }
  .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .form-label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #dddddd; }
    .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .form-label .req {
      color: #4f7be9; }
  .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .form-input {
    height: 40px;
    left: 400px;
    top: 384px;
    background: #1c1e22;
    border-radius: 2px !important; }
  .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .zdv4-cancel-btn {
    width: 78px;
    height: 38px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    border-radius: 2px !important;
    border: 1px solid #303139;
    margin-right: 24px; }
  .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .zdv4-save-btn {
    padding-right: 32px;
    width: 78px;
    height: 38px;
    background: #4f7be9;
    border-radius: 2px !important;
    padding: 10px 24px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff; }
  .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .zd-magazine-published .form-checkbox {
    margin-right: 20px; }
  .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .form-checkbox {
    margin-right: 25px; }
    .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .form-checkbox .form-icon {
      width: 16px;
      height: 16px;
      border: 1px solid #6b7883;
      box-sizing: border-box;
      border-radius: 2px; }
  .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .form-radio .form-icon {
    width: 16px;
    height: 16px;
    border: 1px solid #6b7883;
    box-sizing: border-box;
    border-radius: 50%; }
  .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .zdv4-checkbox-label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #6b7883;
    padding-left: 14px;
    text-align: center;
    vertical-align: middle; }
  .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .zd-club-form-label,
  .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .zd-radio-form-label,
  .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .zd-magazine-form-label {
    white-space: nowrap; }
    @media (min-width: 991px) {
      .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .zd-club-form-label,
      .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .zd-radio-form-label,
      .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .zd-magazine-form-label {
        margin-right: 40px; } }
    .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .zd-club-form-label .ws-wrap,
    .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .zd-radio-form-label .ws-wrap,
    .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .zd-magazine-form-label .ws-wrap {
      white-space: normal; }
  .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .zd-club-form-input,
  .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .zd-radio-form-input,
  .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .zd-magazine-form-input {
    margin-right: 27px; }
  .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .zdv4-club-btn-container {
    margin-right: 27px; }
    @media (max-width: 767px) {
      .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .zdv4-club-btn-container {
        justify-content: space-between !important; } }
  .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .zd-radio-type {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(118px, 1fr)); }
    @media (max-width: 834px) {
      .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .zd-radio-type {
        grid-template-columns: repeat(auto-fill, minmax(110px, 1fr)); } }
    @media (max-width: 767px) {
      .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .zd-radio-type {
        grid-template-columns: repeat(auto-fill, minmax(90px, 1fr)); } }
  .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .zd-is-md {
    margin-right: 27px; }
  .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .zd-magazine-type {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(118px, 1fr)); }
    @media (max-width: 834px) {
      .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .zd-magazine-type {
        grid-template-columns: repeat(auto-fill, minmax(110px, 1fr)); } }
    @media (max-width: 767px) {
      .zdv4-djinfo-modal .zdv4-djinfo-modal-content .zdv4-djinfo-modal-form .zd-magazine-type {
        grid-template-columns: repeat(auto-fill, minmax(90px, 1fr)); } }

.zd-download-manager-sidebar {
  width: 0;
  padding: 20px 10px 10px;
  position: fixed;
  right: -250px;
  top: 60px;
  bottom: 0;
  height: calc(100vh - 60px);
  background: #202225;
  transition: all 0.2s ease;
  overflow: hidden;
  z-index: 91; }
  .zd-download-manager-sidebar span.no-data {
    color: #919191;
    display: block; }
  .zd-download-manager-sidebar h5.zd-download-manager-title {
    margin: -20px -10px 0;
    padding: 10px;
    background: #1c1e22;
    font-size: 12px;
    color: #adb5bd; }
  .zd-download-manager-sidebar .zd-info-msg {
    padding: 0 10px; }

.zd-download-manager-tags .nav-tabs {
  border-bottom: 0;
  margin-bottom: 20px;
  align-items: center;
  display: flex;
  display: -webkit-flex;
  -ms-flex-align: center;
  flex-wrap: wrap; }
  .zd-download-manager-tags .nav-tabs .nav-item {
    margin-left: 5px;
    margin-right: 5px;
    padding: 0; }
    .zd-download-manager-tags .nav-tabs .nav-item .nav-link {
      border: 0;
      text-transform: none;
      font-weight: 600;
      padding: 0;
      cursor: pointer;
      border-radius: 0; }
      .zd-download-manager-tags .nav-tabs .nav-item .nav-link.active {
        opacity: 0.6;
        background-color: transparent; }
    .zd-download-manager-tags .nav-tabs .nav-item.zd-tags-tab .nav-link {
      color: #7685b3;
      padding: 1px 6px;
      background: #2a3043;
      text-transform: uppercase; }
      .zd-download-manager-tags .nav-tabs .nav-item.zd-tags-tab .nav-link.active {
        background-color: #2a3043; }

.zd-slide-open .zd-download-manager-sidebar {
  opacity: 1;
  right: 0;
  width: 250px;
  max-width: 250px; }

.zd-download-manager-sidebar .zd-download-widget {
  margin-top: 10px;
  height: calc(100vh - 165px);
  max-height: calc(100vh - 165px);
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column; }
  .zd-download-manager-sidebar .zd-download-widget .tag-list {
    margin-bottom: 10px; }
    .zd-download-manager-sidebar .zd-download-widget .tag-list label.zd-tag {
      color: #7685b3;
      padding: 1px 6px;
      background: #2a3043;
      margin: 0 6px 3px 0;
      height: 20px;
      cursor: pointer; }
      .zd-download-manager-sidebar .zd-download-widget .tag-list label.zd-tag:hover {
        opacity: 0.8; }
  .zd-download-manager-sidebar .zd-download-widget .zd-download-widget-section {
    margin: 0;
    padding: 0;
    list-style: none;
    border: none;
    flex: 1 1 100%;
    background: none; }
    .zd-download-manager-sidebar .zd-download-widget .zd-download-widget-section.card-collapsed {
      flex: auto; }
    .zd-download-manager-sidebar .zd-download-widget .zd-download-widget-section .zd-primary-dropdown:hover {
      color: #4f7be9 !important; }
    .zd-download-manager-sidebar .zd-download-widget .zd-download-widget-section .zd-primary-dropdown svg {
      font-size: 18px; }
    .zd-download-manager-sidebar .zd-download-widget .zd-download-widget-section .card-header {
      padding: 10px;
      font-size: 12px;
      line-height: 16px;
      text-transform: none;
      border-bottom: 1px solid #25262a;
      cursor: pointer;
      color: #eaeaea; }
    .zd-download-manager-sidebar .zd-download-widget .zd-download-widget-section .zd-download-background {
      background: #4f7be9 !important;
      color: #eaeaea; }
      .zd-download-manager-sidebar .zd-download-widget .zd-download-widget-section .zd-download-background:hover {
        color: #eaeaea !important; }
      .zd-download-manager-sidebar .zd-download-widget .zd-download-widget-section .zd-download-background svg {
        font-size: 18px; }
    .zd-download-manager-sidebar .zd-download-widget .zd-download-widget-section .card-body {
      padding: 10px 0; }
    .zd-download-manager-sidebar .zd-download-widget .zd-download-widget-section.close {
      display: none; }
    .zd-download-manager-sidebar .zd-download-widget .zd-download-widget-section li {
      position: relative;
      min-height: 27px;
      overflow: hidden;
      margin-bottom: 17px;
      margin-top: 0;
      border-radius: 0; }
    .zd-download-manager-sidebar .zd-download-widget .zd-download-widget-section .album-image {
      width: 27px;
      height: 27px;
      display: inline-block;
      position: absolute;
      left: 5px;
      cursor: pointer; }
      .zd-download-manager-sidebar .zd-download-widget .zd-download-widget-section .album-image a {
        line-height: 27px;
        font-size: 15px;
        color: #4d7ce8; }
        .zd-download-manager-sidebar .zd-download-widget .zd-download-widget-section .album-image a .zd-icon-repeat-track {
          color: #666; }
          .zd-download-manager-sidebar .zd-download-widget .zd-download-widget-section .album-image a .zd-icon-repeat-track:hover {
            color: #dddddd; }
        .zd-download-manager-sidebar .zd-download-widget .zd-download-widget-section .album-image a:hover {
          color: #dddddd; }
    .zd-download-manager-sidebar .zd-download-widget .zd-download-widget-section .album-title {
      padding-left: 30px;
      padding-right: 60px;
      display: block;
      font-size: 12px;
      cursor: pointer;
      line-height: 15px; }
      .zd-download-manager-sidebar .zd-download-widget .zd-download-widget-section .album-title span.track-name {
        color: #aeaeaf;
        display: block; }
      .zd-download-manager-sidebar .zd-download-widget .zd-download-widget-section .album-title span.artist-name {
        color: #DDDDDD !important;
        display: block; }
    .zd-download-manager-sidebar .zd-download-widget .zd-download-widget-section .zd-actions {
      right: 12px;
      position: absolute;
      top: 0;
      display: block;
      flex-direction: unset;
      list-style: none;
      border: none; }
      .zd-download-manager-sidebar .zd-download-widget .zd-download-widget-section .zd-actions li {
        display: inline-block;
        margin: 0 2px;
        border-radius: 0; }
        .zd-download-manager-sidebar .zd-download-widget .zd-download-widget-section .zd-actions li a {
          font-size: 22px;
          color: #dddddd;
          text-decoration: none;
          display: inline-block;
          cursor: pointer; }
  .zd-download-manager-sidebar .zd-download-widget .zd-batch-reset-items {
    transition: all 1s ease; }
    .zd-download-manager-sidebar .zd-download-widget .zd-batch-reset-items.open {
      display: block; }
    .zd-download-manager-sidebar .zd-download-widget .zd-batch-reset-items li {
      cursor: default; }

.zd-download-manager-sidebar .zd-download-footer {
  background-color: #242529;
  padding: 5px 0 10px;
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 5px;
  font-size: 12px;
  transition: all 0.2s ease; }
  .zd-download-manager-sidebar .zd-download-footer .zd-cart-size {
    min-width: 35%;
    display: inline-block;
    color: #dddddd; }
  .zd-download-manager-sidebar .zd-download-footer .zd-batch-download-btn {
    letter-spacing: 0.03em;
    float: right;
    font-size: 12px; }
  .zd-download-manager-sidebar .zd-download-footer .zd-batch-reset-btn {
    margin-bottom: 10px; }
    .zd-download-manager-sidebar .zd-download-footer .zd-batch-reset-btn span {
      cursor: pointer; }
      .zd-download-manager-sidebar .zd-download-footer .zd-batch-reset-btn span:hover {
        color: #3b49af; }
      .zd-download-manager-sidebar .zd-download-footer .zd-batch-reset-btn span:after {
        font-family: "zipdj" !important;
        font-style: normal !important;
        font-weight: normal !important;
        -webkit-font-feature-settings: normal !important;
                font-feature-settings: normal !important;
        font-variant: normal !important;
        text-transform: none !important;
        padding-right: 3px;
        content: 'R'; }
    .zd-download-manager-sidebar .zd-download-footer .zd-batch-reset-btn.open span {
      color: #3b49af; }
      .zd-download-manager-sidebar .zd-download-footer .zd-batch-reset-btn.open span:hover {
        color: #dddddd; }
      .zd-download-manager-sidebar .zd-download-footer .zd-batch-reset-btn.open span:after {
        content: 'U'; }

.zd-batch-download-section {
  position: unset;
  bottom: 80px;
  left: 70px;
  right: 16px;
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #25262a;
  z-index: 35;
  transition: all 0.2s ease; }
  .zd-batch-download-section span {
    color: #dddddd;
    float: left;
    display: inline-block; }
  .zd-batch-download-section button {
    float: right;
    font-size: 12px;
    background-color: #507de5;
    color: #dddddd; }

.download-manager-page .infinite-scroll-component {
  min-height: 350px !important; }

body.zd-sidebar-open .zd-batch-download-section {
  left: 240px; }

body.zd-slide-open .zd-batch-download-section {
  left: 70px;
  right: 266px; }

body.zd-sidebar-open.zd-slide-open .zd-batch-download-section {
  left: 240px;
  right: 266px; }

body.player-active .zd-download-manager-sidebar .zd-download-widget {
  max-height: calc(100vh - 290px); }

.zd-genre-list-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  overflow: visible;
  padding: 0; }
  .zd-genre-list-wrap .zd-genre-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-top: 0;
    min-width: 165px; }
  .zd-genre-list-wrap .zd-genre-first-col {
    width: 75%;
    flex-wrap: wrap; }
    @media (max-width: 692px) {
      .zd-genre-list-wrap .zd-genre-first-col {
        width: 100%; } }
  .zd-genre-list-wrap .zd-genre-last-col {
    width: 25%;
    flex-wrap: wrap; }
    @media (max-width: 692px) {
      .zd-genre-list-wrap .zd-genre-last-col {
        width: 100%; } }
  .zd-genre-list-wrap .zd-genre-block {
    width: 33.333%;
    padding: 1.5%;
    border-radius: 0;
    border: none;
    background-color: transparent; }
  .zd-genre-list-wrap .zd-genre-inner {
    background: #222326;
    overflow: hidden;
    height: 100%;
    flex: unset;
    padding: 0; }
    .zd-genre-list-wrap .zd-genre-inner .title {
      background: #252933;
      color: #7099f8;
      text-align: center;
      padding: 15px;
      text-transform: uppercase;
      display: block;
      margin-bottom: 0;
      cursor: pointer; }
      .zd-genre-list-wrap .zd-genre-inner .title:hover, .zd-genre-list-wrap .zd-genre-inner .title:focus {
        background: #2c303b;
        text-decoration: none; }
      .zd-genre-list-wrap .zd-genre-inner .title.btn {
        background: #4360A3;
        color: #FFF;
        border: none; }
        .zd-genre-list-wrap .zd-genre-inner .title.btn.btn:focus, .zd-genre-list-wrap .zd-genre-inner .title.btn.btn:hover {
          background: #4d7ce8;
          border: none; }
    .zd-genre-list-wrap .zd-genre-inner .list {
      padding: 9px;
      text-align: center;
      min-height: 320px; }
      .zd-genre-list-wrap .zd-genre-inner .list a {
        display: table;
        color: #dddddd;
        margin: 15px auto;
        transition: .3s ease;
        padding: 0; }
        .zd-genre-list-wrap .zd-genre-inner .list a:first-child {
          margin-top: 10px; }
        .zd-genre-list-wrap .zd-genre-inner .list a:hover {
          color: #6c93ef;
          text-decoration: none; }
  .zd-genre-list-wrap .zd-genre-last-col .zd-genre-block {
    width: 100%;
    padding: 16px 10px; }
  .zd-genre-list-wrap .zd-genre-last-col .title {
    border-radius: 0px !important; }

.zd-genre-card {
  padding-bottom: 0px !important; }

/* Genre Home */
.zd-genres-list .orange-gradient {
  background: linear-gradient(180deg, #cb6e26 0%, #c02e41 100%), #c4c4c4; }
  .zd-genres-list .orange-gradient:hover {
    background: #c9612e; }

.zd-genres-list .pink-gradient {
  background: linear-gradient(180deg, #ca3a89 0%, #9a3b8d 100%), #c4c4c4; }
  .zd-genres-list .pink-gradient:hover {
    background: #ad4670; }

.zd-genres-list .grey-blue-gradient {
  background: linear-gradient(180deg, #5a93c8 0%, #757bc7 100%), #c4c4c4; }
  .zd-genres-list .grey-blue-gradient:hover {
    background: #5f8fc8; }

.zd-genres-list .baby-pink-gradient {
  background: linear-gradient(180deg, #dc9a72 0%, #cb6f76 100%), #c4c4c4; }
  .zd-genres-list .baby-pink-gradient:hover {
    background: #d78d73; }

.zd-genres-list .blue-gradient {
  background: linear-gradient(180deg, #5c5ba0 0%, #2e5692 100%), #c4c4c4; }
  .zd-genres-list .blue-gradient:hover {
    background: #585a9e; }

.zd-genres-list .sky-blue-gradient {
  background: linear-gradient(180deg, #22a8b3 0%, #117eaa 100%), #c4c4c4; }
  .zd-genres-list .sky-blue-gradient:hover {
    background: #23a1b2; }

.zd-genres-list .style_box {
  color: #dddddd;
  font-style: normal;
  font-weight: normal;
  border-radius: 5px;
  margin-right: 16px;
  width: 100%;
  max-width: 128px;
  min-height: 60px;
  font-size: 10px;
  line-height: 14px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  .zd-genres-list .style_box a {
    width: 100%;
    max-width: 128px;
    min-height: 60px;
    display: flex;
    padding: 0 10px;
    justify-content: center;
    align-items: center; }

.zd-genres-list .style_content {
  background: #1f242f;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  width: 100%;
  min-height: 60px;
  min-width: 128px;
  flex: 0 0 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  color: #dddddd;
  border-radius: 5px;
  padding: 0 10px;
  margin-right: 6px;
  cursor: pointer;
  word-break: break-all; }
  .zd-genres-list .style_content:hover {
    background: #282e3c; }
  .zd-genres-list .style_content a {
    width: 100%;
    max-width: 128px;
    min-height: 60px;
    display: flex;
    flex: 0 0 128px;
    padding: 0 10px;
    justify-content: center;
    align-items: center;
    word-break: break-all; }

.zd-genres-list .margin_third {
  margin-right: 16px; }

.zd-genres-list a {
  color: #dddddd;
  font-size: 10px; }

.zd-genres-list a:hover {
  color: #dddddd !important;
  text-decoration: none; }

.zd-genres-list .margin_fourth {
  margin-right: 16px; }

body .zd-allpack-lists .zd-width-48 {
  width: 48px; }

.pack-wrap {
  z-index: 333; }
  .pack-wrap .p {
    margin: 0 0 1rem;
    -webkit-margin-before: 1em;
            margin-block-start: 1em;
    -webkit-margin-after: 1em;
            margin-block-end: 1em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px; }

.link-style {
  color: #4978ef !important;
  font-size: 12px;
  background-color: transparent;
  cursor: pointer; }

.link-style:hover {
  color: #2d5dc8 !important; }

.parent-pack-wrap {
  padding: 15px 0px; }
  @media only screen and (max-width: 750px) {
    .parent-pack-wrap .zipdj-packs_new {
      margin-right: 0 !important; } }
  .parent-pack-wrap .card-body {
    padding: 0px; }
  .parent-pack-wrap .title-style {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #dddddd; }
  .parent-pack-wrap .content-head {
    right: 0;
    left: 56px;
    top: 60px;
    z-index: 999;
    padding-bottom: 48px; }
  .parent-pack-wrap .head-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 17px; }
  .parent-pack-wrap .head-line .title-links {
    font-style: normal;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 1.6rem;
    line-height: 16px;
    display: inline;
    align-items: center;
    margin-bottom: 0; }
    @media only screen and (max-width: 750px) {
      .parent-pack-wrap .head-line .title-links {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 54%; }
        .parent-pack-wrap .head-line .title-links .zipdj-packs_new {
          margin-right: 0 !important; } }
  .parent-pack-wrap .title-style {
    text-transform: none; }
  .parent-pack-wrap .line {
    content: '';
    background: #373737;
    width: 100%;
    height: 1px;
    position: absolute;
    top: 27px;
    box-sizing: border-box;
    margin: 0px;
    border: 0px; }
  .parent-pack-wrap .card-deck-style {
    display: flex;
    margin-top: 22px;
    width: 100%;
    -webkit-user-select: none;
            user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    box-sizing: border-box; }
  .parent-pack-wrap .card-deck-style-one {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    position: relative;
    display: block; }
  .parent-pack-wrap .card-deck-style-two {
    opacity: 1;
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    height: 100%;
    left: 0;
    top: 0;
    display: block;
    -webkit-user-select: none;
            user-select: none;
    -webkit-tap-highlight-color: transparent; }
  .parent-pack-wrap .card-image-text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    align-items: flex-end;
    text-align: center;
    color: #dddddd;
    margin-top: 9px;
    width: 150px; }
  .parent-pack-wrap .parent {
    text-align: center; }
  .parent-pack-wrap .last-image {
    height: 140px;
    width: 140px; }
  .parent-pack-wrap .animate {
    z-index: 9999;
    transition: all 0.2s ease-in; }
  .parent-pack-wrap .animate:hover {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); }
  .parent-pack-wrap .card-gap {
    padding-right: 30px !important;
    float: left;
    border: none; }

.pack-wrap .pack-section-list {
  padding-top: 10px; }

.pack-wrap .zd-packs-featured-name {
  padding: 0px 20px; }

.pack-wrap .slick-list .slick-track {
  min-width: 100%; }

.pack-wrap .slick-list .slick-slide {
  min-width: 164px;
  max-width: 164px; }
  .pack-wrap .slick-list .slick-slide .card-body {
    height: 56px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #242529; }
    .pack-wrap .slick-list .slick-slide .card-body .card-title {
      margin: 0; }

.pack-wrap .zd-slick-navs {
  overflow: hidden;
  line-height: 20px; }
  .pack-wrap .zd-slick-navs i {
    font-size: 10px; }
  @media (max-width: 750px) {
    .pack-wrap .zd-slick-navs {
      padding: 0 !important; } }
  .pack-wrap .zd-slick-navs .link-style {
    margin-right: 14px; }
  .pack-wrap .zd-slick-navs button {
    height: 24px;
    width: 24px;
    text-align: center;
    display: inline-block;
    background-color: #242529;
    color: #6b7883;
    border-radius: 2px; }
    .pack-wrap .zd-slick-navs button:hover {
      background-color: #242529;
      color: #4f7be9;
      display: inline-block; }
    .pack-wrap .zd-slick-navs button.zd-slick-prev {
      margin-right: 10px; }
  .pack-wrap .zd-slick-navs button:focus,
  .pack-wrap .zd-slick-navs button:hover,
  .pack-wrap .zd-slick-navs button:active,
  .pack-wrap .zd-slick-navs button {
    border: none; }

.zd-triangle {
  position: absolute;
  top: -18px;
  right: -2px;
  width: 80px; }

.cpackard-deck-style {
  padding-right: 56px; }
  @media (max-width: 750px) {
    .cpackard-deck-style {
      padding: 0; } }

@media (max-width: 750px) {
  .pack-wrap .head-line {
    padding-right: 0px;
    margin-right: 0; } }

/* Trending packs CSS for ZDV4*/
.zdv4-trending-packs-card {
  margin-right: 13px;
  max-width: 146px;
  min-width: 146px;
  height: 146px;
  background-color: #141414 !important;
  border-radius: 2px !important; }
  @media (min-width: 1440px) {
    .zdv4-trending-packs-card {
      margin-right: 14px;
      max-width: 154px;
      min-width: 154px;
      height: 154px; } }
  @media (min-width: 1920px) {
    .zdv4-trending-packs-card {
      margin-right: 14px;
      max-width: 167px;
      min-width: 167px;
      height: 167px; } }
  @media (min-width: 2560px) {
    .zdv4-trending-packs-card {
      margin-right: 15px;
      max-width: 174px;
      min-width: 174px;
      height: 174px; } }

#mutate {
  margin-right: 50px; }
  #mutate .head-line {
    margin-top: 48px;
    border-bottom: 1px solid #242529; }
  #mutate .cpackard-deck-style.slider-icon {
    padding-right: 0px !important; }

.zd-card-pack {
  height: 212px !important;
  margin-bottom: 0px !important; }

.zd-card-pack-c {
  margin-bottom: 3px !important;
  width: 159px !important; }

.zd-packs-expand {
  background-color: #242529;
  width: Hug 101px;
  height: Fixed 36px;
  padding: 4px 8px 4px 8px !important;
  grid-gap: 8px;
  gap: 8px;
  color: #6d7882;
  margin: 0 0.5rem 0px 0px; }

.zd-pack-dropdown {
  margin-left: 3px;
  color: #757575; }

.zd-packs-expand-parent {
  cursor: pointer; }
  .zd-packs-expand-parent :hover {
    color: #ddd; }

.trending_packs .slick-list {
  margin-right: 0px !important; }

@media only screen and (max-width: 750px) {
  .zipdj-packs_new .trendingpacks .dualtrack__navigation {
    margin-right: 0; } }

.zipdj-packs_new .trendingpacks button {
  padding-top: 3px; }

.zipdj-packs_new .trendingpacks i {
  font-size: 10px;
  color: #6b7883; }
  .zipdj-packs_new .trendingpacks i :hover {
    color: #4f7be9; }

.trendingpacks button {
  padding: 3px 0 0 0 !important; }

.zipdjpacks__essentials i {
  font-size: 10px;
  color: #6b7883; }
  .zipdjpacks__essentials i :hover {
    color: #4f7be9; }

.zipdjpacks__essentials button {
  padding: 3px 0 0 0 !important; }

.zd-column .cpackard-deck-style {
  padding-right: 12px !important; }

.m-48 {
  margin-top: 48px !important; }

.zd-category .zd-card {
  margin-bottom: 0px !important; }

.zd-category .zd-card-c {
  margin-bottom: 0px !important; }

body .zd-profile-page .profile-container {
  margin-top: 10px; }
  body .zd-profile-page .profile-container #zd-cancel-form html.sfm-desktop {
    margin-top: 0px !important; }
  body .zd-profile-page .profile-container .zd-profile-left {
    margin-bottom: 20px; }
  body .zd-profile-page .profile-container .zd-profile-right {
    margin-bottom: 20px; }

body .zd-profile-page .zd-profile-card {
  background: #212226;
  padding: 15px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.36);
  overflow: hidden; }

body .zd-profile-page .zd-profile-top {
  margin-bottom: 42px;
  position: relative; }
  body .zd-profile-page .zd-profile-top .top-profile:hover .side_profile_hover {
    opacity: 1; }
  body .zd-profile-page .zd-profile-top .top-profile:hover .img_cls {
    opacity: 0.4; }
  body .zd-profile-page .zd-profile-top .side_profile_hover {
    position: absolute;
    left: 13%;
    top: 31%;
    display: none;
    height: 42px;
    width: 31px;
    /* right: auto; */
    color: #959aa2; }
    body .zd-profile-page .zd-profile-top .side_profile_hover:hover {
      opacity: 1; }
      body .zd-profile-page .zd-profile-top .side_profile_hover:hover .img_cls {
        opacity: 0.4; }
  body .zd-profile-page .zd-profile-top .zd-profile-thumb {
    height: 105px;
    width: 105px;
    border-radius: 100%;
    margin-right: 15px;
    background-color: #2e2e31;
    cursor: pointer; }
    @media (max-width: 750px) {
      body .zd-profile-page .zd-profile-top .zd-profile-thumb {
        margin: 0 auto;
        height: 80px;
        width: 80px; } }
    body .zd-profile-page .zd-profile-top .zd-profile-thumb:hover .side_profile_hover {
      display: block; }
  @media (max-width: 750px) {
    body .zd-profile-page .zd-profile-top {
      margin-bottom: 32px; } }
  body .zd-profile-page .zd-profile-top .zd-profile-thumb img {
    height: 105px;
    width: 105px;
    object-fit: cover;
    object-position: center; }
    @media (max-width: 750px) {
      body .zd-profile-page .zd-profile-top .zd-profile-thumb img {
        height: 80px;
        width: 80px; } }
  body .zd-profile-page .zd-profile-top .zd-profile-summary {
    color: #b2b2b2;
    overflow: hidden; }
    @media (max-width: 750px) {
      body .zd-profile-page .zd-profile-top .zd-profile-summary {
        text-align: center; } }
    body .zd-profile-page .zd-profile-top .zd-profile-summary .zd-u-name {
      font-family: Poppins;
      margin-top: 8px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #dddddd;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 0; }
    body .zd-profile-page .zd-profile-top .zd-profile-summary .zd-u-web {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      color: #6b7883; }
    body .zd-profile-page .zd-profile-top .zd-profile-summary .zd-profile-button {
      width: 135px;
      height: 25px;
      background: #242529;
      border-radius: 2px;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      text-align: center;
      text-transform: uppercase;
      color: #dddddd;
      border: none;
      outline: none;
      margin-top: 16px;
      cursor: auto; }
      @media (max-width: 750px) {
        body .zd-profile-page .zd-profile-top .zd-profile-summary .zd-profile-button {
          text-transform: uppercase; } }

body .zd-profile-page .zd-profile-middle {
  display: flex;
  padding-bottom: 24px;
  border-bottom: 1px solid #242529; }
  @media (max-width: 750px) {
    body .zd-profile-page .zd-profile-middle {
      justify-content: space-evenly; } }
  body .zd-profile-page .zd-profile-middle .zd-profile-middle-content {
    margin-right: 40px; }
    @media (max-width: 750px) {
      body .zd-profile-page .zd-profile-middle .zd-profile-middle-content {
        margin-right: 0; } }
    body .zd-profile-page .zd-profile-middle .zd-profile-middle-content .zd-u-name {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #dddddd; }
      @media (max-width: 750px) {
        body .zd-profile-page .zd-profile-middle .zd-profile-middle-content .zd-u-name {
          text-align: center; } }
    body .zd-profile-page .zd-profile-middle .zd-profile-middle-content .zd-u-web {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      color: #6b7883; }
      @media (max-width: 750px) {
        body .zd-profile-page .zd-profile-middle .zd-profile-middle-content .zd-u-web {
          text-align: center; } }

body .zd-profile-page .zd-profile-bottom {
  padding: 0;
  margin-top: 40px; }
  @media (max-width: 750px) {
    body .zd-profile-page .zd-profile-bottom {
      margin-top: 32px;
      text-align: center;
      text-transform: uppercase; } }

body .zd-profile-page .zd-profile-links .list-group .list-group-item {
  padding: 0;
  border: 1px solid transparent;
  border-left: none;
  border-left: none;
  background-color: transparent; }
  body .zd-profile-page .zd-profile-links .list-group .list-group-item a {
    border: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #6b7883;
    padding: 6px 0px;
    display: block;
    line-height: normal;
    cursor: pointer;
    text-decoration: none; }
    body .zd-profile-page .zd-profile-links .list-group .list-group-item a.active, body .zd-profile-page .zd-profile-links .list-group .list-group-item a:hover {
      color: #4f7be9; }
    @media (max-width: 750px) {
      body .zd-profile-page .zd-profile-links .list-group .list-group-item a {
        padding: 12px 0px; } }

body .zd-profile-page .zd-profile-content .profile-header {
  margin-bottom: 15px; }
  body .zd-profile-page .zd-profile-content .profile-header h2 {
    font-size: 14px;
    padding-bottom: 2px;
    display: inline-block; }
  body .zd-profile-page .zd-profile-content .profile-header .section-title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #dddddd;
    margin-top: 5px; }
  body .zd-profile-page .zd-profile-content .profile-header .edit-link {
    float: right;
    display: block; }

body .form-group-title {
  display: block;
  text-transform: uppercase;
  color: #dddddd;
  padding: 8px 4px 8px 0;
  margin-bottom: 10px; }

body .form-text {
  font-size: 12px;
  color: #404043;
  padding: 8px 4px; }
  body .form-text.text-muted {
    color: #96969a !important; }

.zdv4-djinfo-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #dddddd;
  padding-top: 50px; }

.zd-profile-form {
  color: #b2b2b2; }
  .zd-profile-form .req {
    color: #4d7ce8; }
  .zd-profile-form .form-group {
    margin-bottom: 0; }
  .zd-profile-form .form-control {
    background-color: #1f2024;
    border-color: #212226;
    color: #b2b2b2; }
  .zd-profile-form .zd-checkbox-row {
    margin-bottom: 15px; }
    .zd-profile-form .zd-checkbox-row .form-check {
      padding-left: 0; }
  .zd-profile-form .text-danger .form-control {
    border-color: #dc3545; }
  .zd-profile-form .text-danger .invalid-feedback {
    font-size: 11px; }
  .zd-profile-form .zd-card-block {
    background: #212226;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.36);
    padding: 15px;
    overflow: hidden; }
  .zd-profile-form .zd-label-block .form-checkbox {
    display: block;
    margin-bottom: 4px;
    color: #6b7883; }
    .zd-profile-form .zd-label-block .form-checkbox:last-child {
      margin-bottom: 12px; }
  .zd-profile-form .zd-profile-thumb img {
    width: 100%; }

.zd-modal-form .form-group {
  margin-bottom: 10px; }

.btn.zd-full {
  width: 100%; }

.form-checkbox,
.form-radio {
  cursor: pointer;
  display: inline-block;
  line-height: 18px;
  padding: 2px 16px;
  position: relative;
  color: #b2b2b2; }

.form-checkbox .form-icon,
.form-radio .form-icon {
  border: 1px solid #ccc;
  display: inline-block;
  font-size: 11px;
  height: 11px;
  left: 0;
  line-height: 24px;
  outline: none;
  padding: 0;
  position: absolute;
  top: 5px;
  transition: all 0.2s ease;
  vertical-align: top;
  width: 11px; }

.form-checkbox .form-icon-pre,
.form-radio .form-icon-pre {
  border: 1px solid #6b7883;
  display: inline-block;
  font-size: 11px;
  height: 11px;
  left: 0;
  line-height: 24px;
  outline: none;
  padding: 0;
  position: absolute;
  top: 5px;
  transition: all 0.2s ease;
  vertical-align: top;
  width: 11px; }

.zd_preferance_page .form-checkbox .form-icon-pre,
.zd_preferance_page .form-checkbox .zdv3-icon-check-box,
.zd_preferance_page .form-radio .form-icon-pre {
  border: 1px solid #6b7883;
  display: inline-block;
  font-size: 11px;
  height: 20px;
  left: 0;
  line-height: 24px;
  outline: none;
  padding: 0;
  position: absolute;
  top: 3px;
  transition: all 0.2s ease;
  vertical-align: top;
  width: 20px; }
  @media (max-width: 1360px) {
    .zd_preferance_page .form-checkbox .form-icon-pre,
    .zd_preferance_page .form-checkbox .zdv3-icon-check-box,
    .zd_preferance_page .form-radio .form-icon-pre {
      height: 14px;
      width: 14px;
      top: 5px; } }

.zd_preferance_page .form-checkbox input:checked + .zdv3-icon-check-box,
.zd_preferance_page .form-radio input:checked + .zdv3-icon-check-box {
  background-color: white;
  border-color: #4f7be9;
  color: #4f7be9;
  line-height: 12px;
  transition: none;
  border: none;
  font-size: 11px; }
  .zd_preferance_page .form-checkbox input:checked + .zdv3-icon-check-box::before,
  .zd_preferance_page .form-radio input:checked + .zdv3-icon-check-box::before {
    font-size: 20px;
    line-height: 21px; }
    @media (max-width: 1360px) {
      .zd_preferance_page .form-checkbox input:checked + .zdv3-icon-check-box::before,
      .zd_preferance_page .form-radio input:checked + .zdv3-icon-check-box::before {
        font-size: 14px !important;
        line-height: 14px !important; } }

.form-checkbox input:checked + .form-icon-pre,
.form-radio input:checked + .form-icon-pre {
  background-color: #4d7ce8;
  border-color: #3d6edd; }

.form-checkbox input:checked + .form-icon,
.form-radio input:checked + .form-icon {
  background-color: #4d7ce8;
  border-color: #3d6edd; }

.zd_genre_title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px; }

.form-checkbox input,
.form-radio input,
.form-switch input {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  position: absolute;
  width: 1px; }

.form-radio .form-icon {
  border-radius: 0.7rem; }

.form-checkbox .form-icon {
  border-radius: 2px; }

.btn-grey {
  background: #35383f; }

.btn-grey:hover,
.btn-grey:focus,
.btn-grey:active {
  background: #35383f;
  opacity: 0.9; }

.zd-label-message {
  padding-top: 20px;
  padding-left: 20px;
  color: #919191;
  text-transform: uppercase; }
  .zd-label-message.blue-box {
    background: #4d7ce8;
    border-color: #4d7ce8;
    color: #dddddd;
    display: block;
    padding: 12px;
    border-radius: 2px;
    text-transform: none; }

.zd-profile-inner-content .nav-tabs {
  border-bottom: 1px solid #6b7783 !important; }

.zd-line-tab {
  display: -webkit-inline-box;
  margin-bottom: 33px; }
  .zd-line-tab .nav-item .nav-link {
    padding-left: 11px !important;
    padding-right: 11px !important;
    margin-right: 0px;
    color: #6b7783;
    font-size: 14px;
    padding: 6px 0px;
    border: none;
    border-bottom: 2px solid transparent;
    cursor: pointer; }
    .zd-line-tab .nav-item .nav-link.active, .zd-line-tab .nav-item .nav-link:hover {
      color: #4f7be9;
      background: transparent;
      border: none;
      border-bottom: 2px solid #4d7ce8; }

body .zd-datepicker {
  position: relative;
  display: inline-block;
  color: #b2b2b2; }
  body .zd-datepicker .datepicker__triangle {
    margin-left: 100px; }
  body .zd-datepicker input {
    background: #242529;
    border-color: transparent;
    color: #6b7883;
    border-radius: 3px;
    width: 135px;
    padding: 7px 14px; }
  body .zd-datepicker span.zd-cal-icon {
    position: absolute;
    top: 8px;
    right: 7px;
    z-index: 1;
    color: #6b7883;
    cursor: pointer; }
    body .zd-datepicker span.zd-cal-icon:hover {
      color: #868585; }
  body .zd-datepicker .zd-last-login-date {
    color: #6b7883 !important; }
  body .zd-datepicker .react-datepicker {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.36); }
  body .zd-datepicker .react-datepicker__tab-loop {
    position: absolute;
    width: 230px;
    top: 0;
    line-height: 24px; }
  body .zd-datepicker .react-datepicker,
  body .zd-datepicker .react-datepicker__header {
    border-color: transparent; }
  body .zd-datepicker .react-datepicker__header,
  body .zd-datepicker .react-datepicker {
    background-color: #25262a;
    color: #e1e1e1; }
  body .zd-datepicker .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
  body .zd-datepicker .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    border-bottom-color: #25262a; }
  body .zd-datepicker .react-datepicker__current-month,
  body .zd-datepicker .react-datepicker-time__header,
  body .zd-datepicker .react-datepicker-year-header,
  body .zd-datepicker .react-datepicker__day-name,
  body .zd-datepicker .react-datepicker__day,
  body .zd-datepicker .react-datepicker__time-name {
    color: #e1e1e1; }
  body .zd-datepicker .react-datepicker__day:hover,
  body .zd-datepicker .react-datepicker__month-text:hover,
  body .zd-datepicker .react-datepicker__quarter-text:hover,
  body .zd-datepicker .react-datepicker__day--selected,
  body .zd-datepicker .react-datepicker__day--in-selecting-range,
  body .zd-datepicker .react-datepicker__day--in-range,
  body .zd-datepicker .react-datepicker__month-text--selected,
  body .zd-datepicker .react-datepicker__month-text--in-selecting-range,
  body .zd-datepicker .react-datepicker__month-text--in-range,
  body .zd-datepicker .react-datepicker__quarter-text--selected,
  body .zd-datepicker .react-datepicker__quarter-text--in-selecting-range,
  body .zd-datepicker .react-datepicker__quarter-text--in-range {
    background-color: #4d7ce8; }

body .zd-custom-radio,
body .zd-custom-checkbox {
  width: 15px;
  height: 15px;
  background: transparent;
  border-width: 2px !important;
  border-color: #4d7ce8;
  border-style: solid;
  border-width: 0;
  padding: 3px;
  margin: 0 10px 0 0;
  -webkit-appearance: none;
  box-sizing: border-box;
  border-style: solid;
  border-width: 0;
  outline: 0;
  vertical-align: text-bottom; }
  body .zd-custom-radio:before,
  body .zd-custom-checkbox:before {
    content: '';
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    display: block; }
  body .zd-custom-radio:checked:before,
  body .zd-custom-checkbox:checked:before {
    background: #4d7ce8;
    transition: all 0.25s linear 0s; }

body .zd-custom-radio,
body .zd-custom-radio:before {
  border-radius: 100%; }

.react-datepicker-popper {
  -webkit-transform: none !important;
          transform: none !important;
  margin-top: 45px !important; }
  .react-datepicker-popper .react-datepicker__triangle {
    margin-left: 100px; }

@media (max-width: 750px) {
  .zd-none {
    display: none !important; } }

@media (max-width: 750px) {
  .zd-none.zd-side-bar {
    display: none !important; } }

@media (max-width: 750px) {
  .zd-none.zd-right-column {
    display: none !important; } }

@media (max-width: 750px) {
  .profile-sm-hide {
    display: none !important; } }

@media (min-width: 750px) {
  .profile-md-hide {
    display: none !important; } }

@media (max-width: 750px) {
  .zd-profile-inner-content {
    margin-top: 12px; } }

.profile-back-button {
  display: flex;
  align-items: center;
  color: #ddd;
  font-size: 12px; }

.zd-side-bar {
  width: 252px;
  padding-right: 10px;
  min-width: 292px; }
  @media (max-width: 750px) {
    .zd-side-bar {
      width: 100%;
      min-width: 100%;
      padding-right: 10px; } }

.zd-left-column {
  width: 252px;
  padding-right: 10px;
  min-width: 292px; }
  @media (max-width: 750px) {
    .zd-left-column {
      width: 100%;
      min-width: 100%; } }

.zd-right-column {
  width: 100%; }

.zd-table-head {
  background-color: #1c1e22 !important;
  color: #434b52 !important; }

.zd-table-body-row {
  background-color: #242529 !important;
  border-bottom: 2px solid #1c1e22 !important;
  transition: background-color 0.02s ease-in-out;
  border-radius: 2px; }
  .zd-table-body-row td {
    color: #ddd;
    border-color: transparent;
    vertical-align: middle; }
  .zd-table-body-row:hover {
    background-color: #28292e !important; }

.zd-table-body-active-plan-row {
  background-color: #2a3143 !important;
  border-bottom: 2px solid #1c1e22 !important;
  transition: background-color 0.02s ease-in-out;
  border-radius: 2px; }
  .zd-table-body-active-plan-row td {
    color: #ddd !important;
    border-color: transparent;
    vertical-align: inherit !important; }
  .zd-table-body-active-plan-row:hover {
    background-color: #28292e !important; }

.zd-blue-faded {
  color: #394671; }

@media only screen and (min-width: 767px) and (max-width: 1224px) {
  body .zd-cancellation-res {
    float: left;
    margin-left: 20px; }
  body .zd-title-width {
    width: 48%; } }

@media only screen and (min-width: 0px) and (max-width: 750px) {
  body .zd-cancellation-res {
    float: left;
    margin-left: 20px; }
  body .zd-title-width {
    width: 58%; } }

@media (max-width: 750px) {
  .zd-home-sm-hide {
    display: none !important; } }

@media (min-width: 750px) {
  .zd-home-lg-hide {
    display: none !important; } }

@media (min-width: 1224px) {
  .zd-home-md-show {
    display: none !important; } }

@media (max-width: 1224px) {
  .zd-home-md-hide {
    display: none !important; } }

.zd-home-sm-show {
  display: block !important; }

.zd-font {
  font-size: 12px; }

.zd_table tbody tr {
  background-color: transparent !important; }
  .zd_table tbody tr td {
    color: #434b52; }

@media only screen and (min-width: 768px) and (max-width: 10000px) {
  .zd-album-table .zd-title-d-h {
    width: 50%; } }

.zd-block {
  display: none !important; }
  @media (max-width: 750px) {
    .zd-block {
      display: block !important; } }

@media (max-width: 750px) {
  .zd_table .zd-title {
    width: 52%; }
  .zd-fixed-table .zd-title {
    width: 56%; } }

@media only screen and (min-width: 0px) and (max-width: 10000px) {
  .zd-track-view .zd-releasee {
    width: 80px !important; } }

@media only screen and (min-width: 0px) and (max-width: 440px) {
  .zd-track-view .zd-title-d-h {
    width: 100% !important; }
  .zd-track-view .zd-icons-d-h {
    width: 48px !important; }
  .zd-track-view .zd-artis-d-h {
    width: 53% !important;
    color: #434b52 !important; } }

@media only screen and (min-width: 440px) and (max-width: 10000px) {
  .zd-track-view .zd-title-d-h {
    width: 100% !important; }
  .zd-track-view .zd-icons-d-h {
    width: 60px !important; }
  .zd-track-view .zd-artis-d-h {
    width: 53% !important;
    color: #434b52 !important; } }

.new-ticket-btn {
  width: 193px;
  height: 28px;
  box-sizing: border-box;
  background: #4f7be9;
  border-radius: 2px;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0px;
  border: 0px; }

.profile-sub-header {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #dddddd; }

.profile-msg {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #6b7883; }

.toggle-btn {
  margin-top: 0.6rem; }

.change-plan-btn {
  background: #4f7be9 !important;
  border-radius: 2px !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px; }

.landing-page-container input[type='radio'] {
  display: none; }

.landing-page-container input[type='radio'] + *::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 0.9rem;
  height: 0.9rem;
  margin-right: 0.3rem;
  border-radius: 50%;
  border-style: solid;
  border: 1px solid #6b7883; }

.landing-page-container .child-cls input[type='radio'] + *::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 0.7rem;
  height: 0.7rem;
  margin-right: 0.3rem;
  border-radius: 50%;
  border-style: solid;
  border: 1px solid #6b7883; }

.landing-page-container input[type='radio']:checked + * {
  color: #4d7ce8; }

.landing-page-container input[type='radio']:checked + *::before {
  background: #4d7ce8;
  border-color: #4d7ce8; }

.landing-page-container input[type='radio'] + * {
  display: inline-block; }

@media only screen and (max-width: 480px) {
  .landing-page-container .landing-page-layout {
    grid-template-columns: 1fr -1fr;
    display: grid;
    grid-gap: 7px; }
    .landing-page-container .landing-page-layout label {
      margin-right: 0px;
      padding-left: 0px; }
      .landing-page-container .landing-page-layout label i {
        cursor: pointer; }
      .landing-page-container .landing-page-layout label span {
        margin-left: 5px;
        font-size: 12px;
        line-height: 18px;
        color: #ddd;
        cursor: pointer; } }

@media only screen and (min-width: 480px) and (max-width: 882px) {
  .landing-page-container .landing-page-layout {
    grid-template-columns: 0.7fr 0.5fr;
    display: grid;
    grid-gap: 15px; }
    .landing-page-container .landing-page-layout label {
      margin-right: 0px;
      padding-left: 0px; }
      .landing-page-container .landing-page-layout label i {
        cursor: pointer; }
      .landing-page-container .landing-page-layout label span {
        margin-left: 5px;
        font-size: 12px;
        line-height: 18px;
        color: #ddd;
        cursor: pointer; } }

@media only screen and (min-width: 883px) {
  .landing-page-container .landing-page-layout {
    grid-template-columns: 1fr 1fr;
    display: grid;
    grid-gap: 15px; }
    .landing-page-container .landing-page-layout label {
      margin-right: 0px;
      padding-left: 0px; }
      .landing-page-container .landing-page-layout label i {
        cursor: pointer; }
      .landing-page-container .landing-page-layout label span {
        margin-left: 5px;
        font-size: 12px;
        line-height: 18px;
        color: #ddd;
        cursor: pointer; } }

.zd_fav_artist_parent,
.zd_fav_label_parent {
  cursor: pointer; }
  .zd_fav_artist_parent .zd_cross,
  .zd_fav_label_parent .zd_cross {
    display: flex;
    justify-content: flex-end;
    color: #1c1e22;
    margin-right: 14px; }
  @media only screen and (max-width: 768px) {
    .zd_fav_artist_parent .zd_cross_mob,
    .zd_fav_label_parent .zd_cross_mob {
      display: block !important;
      cursor: pointer;
      justify-content: flex-end;
      color: #4f7be9 !important;
      position: absolute;
      z-index: 2;
      position: absolute;
      right: 0;
      top: 0; }
    .zd_fav_artist_parent .zd_cross,
    .zd_fav_label_parent .zd_cross {
      display: none; } }
  .zd_fav_artist_parent:hover .zd_cross,
  .zd_fav_label_parent:hover .zd_cross {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    color: #4f7be9 !important;
    margin-right: 8px; }

@media only screen and (max-width: 350px) {
  .zd_fav_artist_parent .zd_cross {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    color: #4f7be9 !important;
    font-size: 18px;
    position: absolute;
    z-index: 2;
    margin-left: 270px;
    margin-top: 1px; } }

.zd_cross_mob {
  display: none !important; }

.zd_fav_card {
  cursor: pointer;
  width: 138px;
  margin-right: 16px;
  margin-bottom: 16px;
  text-align: center;
  color: #ddd; }
  .zd_fav_card:hover .zd_cross {
    display: block; }
  .zd_fav_card .card .card-body {
    display: flex;
    margin: 0 auto;
    height: 64px;
    padding: 14px 11px; }
    .zd_fav_card .card .card-body span {
      display: inline-flex;
      align-items: center;
      margin-bottom: 0; }

.zd-fav-label {
  width: 143px;
  height: 199px;
  margin-right: 16px;
  margin-bottom: 16px; }
  .zd-fav-label .cardody {
    padding: 0 !important; }
  .zd-fav-label .card-footer {
    text-align: center;
    color: #ddd;
    background-color: #242529;
    padding: 14px 10px;
    height: 56px; }

.page-link:hover {
  z-index: 2;
  color: #dddddd;
  text-decoration: none;
  background-color: #4f7be9;
  border-color: transparent; }

.page-item.active .page-link {
  z-index: 1;
  color: #dddddd;
  background-color: #4f7be9;
  border-color: transparent; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: 0;
  line-height: 1.25;
  color: #dddddd;
  background-color: #1c1e22;
  border: 0 solid transparent; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.profilemodal {
  width: 413px; }
  .profilemodal .update-profile-header .update-profile-header-text {
    padding-top: 40px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #dddddd; }
  .profilemodal .update-profile-body {
    padding-top: 25px !important;
    text-align: center; }
    .profilemodal .update-profile-body .update-profile-body-section {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      height: 250px; }
      .profilemodal .update-profile-body .update-profile-body-section .update-profile-body-image {
        text-align: center; }
        .profilemodal .update-profile-body .update-profile-body-section .update-profile-body-image .rounded-circle {
          width: 150px;
          cursor: pointer; }
    .profilemodal .update-profile-body input[type='file'] {
      display: none; }
    .profilemodal .update-profile-body .custom-file-upload-section {
      display: flex;
      justify-content: center; }
    .profilemodal .update-profile-body .custom-file-upload {
      display: flex;
      justify-content: center;
      width: 106px;
      height: 40px;
      background: #4f7be9;
      cursor: pointer;
      border-radius: 2px; }
      .profilemodal .update-profile-body .custom-file-upload span {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #ffffff;
        padding-top: 11px; }
  .profilemodal .update-profile-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 40px; }
    .profilemodal .update-profile-footer .modal-remove-button,
    .profilemodal .update-profile-footer .modal-cancel-button {
      width: 140px;
      height: 38px;
      border: 1px solid #303139;
      background: transparent;
      box-sizing: border-box;
      border-radius: 2px !important;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      cursor: pointer; }
    .profilemodal .update-profile-footer .modal-create-button {
      width: 78px;
      height: 38px;
      background: #4f7be9;
      border-radius: 2px !important;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      cursor: pointer; }
  .profilemodal .modal-header {
    justify-content: center; }
  .profilemodal .editor-canvas {
    width: 150px !important;
    border-radius: 50%;
    height: 150px !important; }
  @media (max-width: 750px) {
    .profilemodal {
      width: 96%; } }
  .profilemodal .modal-content {
    height: 490px;
    background: #242529 !important; }
  .profilemodal .top {
    top: unset !important; }

.zd_text_47484A {
  color: #47484a; }

.zd_text_6F747 {
  color: #6f747e; }

.zd-border-right-303239 {
  border-right: 1px solid #303239; }
  @media (max-width: 750px) {
    .zd-border-right-303239 {
      border-right: none; } }

#zd_download_view .infinite-scroll-component {
  min-height: 500px !important; }

.zd-top-track-list {
  max-height: calc(100% - 0px); }

.chart-list {
  background: #222327;
  height: 100%;
  box-shadow: 1.5px 2.6px 11.52px 4.48px rgba(0, 0, 0, 0.039); }
  .chart-list .chart-list-block {
    padding: 1rem; }

.inline-block {
  display: inline-block; }

.btn.btn-link {
  background: 0 0;
  border-color: transparent; }

.header-list button {
  padding: 0; }
  .header-list button i {
    font-size: 16px;
    color: #dddddd; }
    .header-list button i:hover {
      color: #3b49af; }
  .header-list button:hover {
    text-decoration: none; }

.chart-list [class*=' icon-'],
.chart-list [class^='icon-'] {
  font-family: icomoon !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.chart-list .icon-pagination-right:before {
  content: '\E930'; }

.chart-list .icon-playlist:before {
  content: '\E922'; }

.zd-top-track-list .chart-list .chart-list-block.dj-chart-list-wrap {
  max-height: calc(100% - 78px);
  overflow: hidden;
  padding: 0; }

.zd-top-track-list .chart-list .chart-list-block {
  padding: 10px;
  color: #9b9b9c; }

.zd-top-track-list .chart-list .chart-list-block .dj-chart-list {
  margin: 0;
  counter-reset: b;
  padding: 0; }

.flex {
  display: flex;
  display: -webkit-flex; }

.zd-top-track-list .chart-list .chart-list-block .dj-chart-list .dj-chart-list-item {
  border-bottom: 1px solid rgba(204, 204, 204, 0.07);
  margin: 0;
  padding: 1rem;
  position: relative;
  counter-increment: b;
  flex-wrap: wrap;
  cursor: pointer;
  padding-bottom: 1rem; }
  .zd-top-track-list .chart-list .chart-list-block .dj-chart-list .dj-chart-list-item:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background: #3b59b0;
    opacity: 0; }
  .zd-top-track-list .chart-list .chart-list-block .dj-chart-list .dj-chart-list-item:hover {
    background: #2c303b; }
    .zd-top-track-list .chart-list .chart-list-block .dj-chart-list .dj-chart-list-item:hover:before {
      opacity: 1; }
  .zd-top-track-list .chart-list .chart-list-block .dj-chart-list .dj-chart-list-item .left {
    width: 40px;
    position: relative; }
    .zd-top-track-list .chart-list .chart-list-block .dj-chart-list .dj-chart-list-item .left:before {
      content: counter(b);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 24px;
      text-indent: -1rem;
      opacity: 1;
      visibility: visible;
      transition: all 0.2s ease-in; }

.zd-top-track-list .chart-list .chart-list-block .dj-chart-list .dj-chart-list-item .btn {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0);
  transform: scale(0);
  transition: all 0.2s ease-in;
  padding-left: 3px;
  padding-right: 3px;
  width: 30px; }
  .zd-top-track-list .chart-list .chart-list-block .dj-chart-list .dj-chart-list-item .btn.icon-playlist {
    bottom: 0;
    position: absolute;
    left: 0; }

.zd-top-track-list .chart-list .chart-list-block .dj-chart-list .dj-chart-list-item:hover .left:before, .zd-top-track-list .chart-list .chart-list-block .dj-chart-list .dj-chart-list-item.dj-selected .left:before {
  opacity: 0; }

.zd-top-track-list .chart-list .chart-list-block .dj-chart-list .dj-chart-list-item:hover .btn, .zd-top-track-list .chart-list .chart-list-block .dj-chart-list .dj-chart-list-item.dj-selected .btn {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  visibility: visible; }

.zd-top-track-list .chart-list .chart-list-block .dj-chart-list .dj-chart-list-item:hover:hover, .zd-top-track-list .chart-list .chart-list-block .dj-chart-list .dj-chart-list-item.dj-selected:hover {
  color: #dddddd; }

.zd-top-track-list .chart-list .chart-list-block .dj-chart-list .dj-chart-list-item .center {
  width: calc(100% - 78px); }

.zd-top-track-list .chart-list .chart-list-block .dj-chart-list .dj-chart-list-item div {
  position: relative; }

.zd-top-track-list .chart-list .chart-list-block .dj-chart-list .dj-chart-list-item .album-name,
.zd-top-track-list .chart-list .chart-list-block .dj-chart-list .dj-chart-list-item .album-title {
  display: block;
  width: 100%; }

.zd-top-track-list .chart-list .chart-list-block .dj-chart-list .dj-chart-list-item .album-name {
  margin-top: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.zd-top-track-list .chart-list .chart-list-block .dj-chart-list .dj-chart-list-item .label-extra {
  display: block;
  margin-top: 5px; }

.zd-top-track-list .chart-list .chart-list-block .dj-chart-list .dj-chart-list-item .right {
  width: 30px;
  text-align: right; }
  .zd-top-track-list .chart-list .chart-list-block .dj-chart-list .dj-chart-list-item .right .btn {
    text-decoration: none;
    top: 40%;
    position: absolute;
    width: auto;
    padding: 0;
    right: 0;
    color: #4d7ce8; }

.chart-list-content {
  overflow: hidden; }
  .chart-list-content .album-details {
    color: #919191;
    vertical-align: middle;
    vertical-align: top;
    border: none; }
    .chart-list-content .album-details tr {
      border: none !important; }
    .chart-list-content .album-details .table-cell {
      padding: 10px 5px;
      border-collapse: collapse;
      border: none !important; }
  .chart-list-content .album-table > tbody > tr:not(.table-head) {
    border-bottom: 20px solid #1c1e22;
    border-bottom: 1px solid #1c1e22; }
  .chart-list-content .track-info-block {
    display: flex;
    padding: 5px 0; }
    .chart-list-content .track-info-block .track-info-img {
      width: 200px; }
      .chart-list-content .track-info-block .track-info-img img {
        width: 100%; }
    .chart-list-content .track-info-block .track-info-desc {
      width: calc(100% - 200px);
      padding: 5px 0 5px 15px; }
  .chart-list-content .album-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
    color: #dddddd;
    font-weight: 700;
    cursor: pointer; }
    .chart-list-content .album-title:hover {
      color: #4d7ce8; }
  .chart-list-content .album-description {
    white-space: normal;
    margin-top: 8px;
    line-height: normal; }

.chart-list-content .card-wrap .card-block {
  width: 20%;
  padding: 10px 5px; }

.chart-list-content .card-wrap .card {
  background: #222327;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  transition: box-shadow 0.5s ease; }

.chart-list-content .card-wrap .card-header {
  background: #222327; }

.chart-list-content .card-wrap .card-image {
  position: relative;
  overflow: hidden;
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: pointer; }
  .chart-list-content .card-wrap .card-image .card-image-style {
    width: 100%; }
    .chart-list-content .card-wrap .card-image .card-image-style:hover {
      opacity: 0.8; }

.chart-list-content .card-wrap .card-header {
  padding: 1rem 0.6rem; }
  .chart-list-content .card-wrap .card-header .album-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
    color: #dddddd;
    font-weight: 700;
    cursor: pointer;
    margin-bottom: 0; }
    .chart-list-content .card-wrap .card-header .album-title a {
      color: #dddddd; }
      .chart-list-content .card-wrap .card-header .album-title a:hover {
        color: #4d7ce8;
        text-decoration: none; }
  .chart-list-content .card-wrap .card-header .album-name {
    margin-top: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #b2b2b2;
    text-transform: capitalize; }

body .zd-label-search-header {
  padding: 0 0px 24px;
  background: transparent !important; }
  body .zd-label-search-header .sk-search-box:hover .cr-search-form__input::-webkit-input-placeholder {
    color: #ddd; }
  body .zd-label-search-header .sk-search-box:hover .cr-search-form__input::placeholder {
    color: #ddd; }
  body .zd-label-search-header .sk-search-box:hover .cr-search-form__icon-search {
    color: #ddd !important; }
  body .zd-label-search-header .cr-search-form__input {
    width: 240px;
    background: #2d2e33;
    box-shadow: none;
    border: 0;
    color: #ddd;
    border-radius: 0px !important; }
    body .zd-label-search-header .cr-search-form__input::-webkit-input-placeholder {
      color: #6b7883; }
    body .zd-label-search-header .cr-search-form__input::placeholder {
      color: #6b7883; }
    body .zd-label-search-header .cr-search-form__input .cr-search-form__icon-search {
      color: #6b7883 !important; }
    body .zd-label-search-header .cr-search-form__input:hover::-webkit-input-placeholder {
      color: #dddd; }
    body .zd-label-search-header .cr-search-form__input:hover::placeholder {
      color: #dddd; }
    body .zd-label-search-header .cr-search-form__input:focus::-webkit-input-placeholder {
      color: #dddd; }
    body .zd-label-search-header .cr-search-form__input:focus::placeholder {
      color: #dddd; }
  body .zd-label-search-header .cr-search-form__icon-search {
    color: #6b7883 !important; }

body .zd-new-indicator {
  color: #4d7ce8;
  padding-left: 10px; }

@media only screen and (min-width: 0px) and (max-width: 414px) {
  .chart-list-content .card-wrap .card-block {
    width: 40%;
    padding: 10px 5px; } }

@media only screen and (min-width: 415px) and (max-width: 766px) {
  .chart-list-content .card-wrap .card-block {
    width: 33.3333%;
    padding: 10px 5px; } }

.zd-wave-loader {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center; }

svg.zd-wave-loader-relative {
  position: relative;
  top: auto;
  left: auto;
  display: inline-block; }

svg.zd-wave-loader-iframe {
  position: absolute;
  top: auto;
  left: 0;
  margin: 50%;
  display: block;
  right: 0;
  z-index: 0; }

body svg.zd-wave-loader-small {
  width: 20px;
  height: 20px;
  position: relative;
  top: auto;
  left: auto;
  display: inline-block; }

body .zd-active-loader {
  position: relative;
  margin: 0px;
  height: 32px;
  width: 32px;
  padding: 6px;
  flex-basis: 32px; }

body .zd-active-loader svg.zd-wave-loader-small {
  position: relative;
  left: 10px;
  top: 10px;
  display: block; }

body .sk-results-list .zd-card .card-img {
  border-radius: 0; }

body .zd-cards-hits .card-img {
  border-radius: 0; }

body div.zd-square-image-wrap {
  width: 100%;
  padding-top: 100%;
  position: relative;
  line-height: 0px;
  overflow: hidden; }
  body div.zd-square-image-wrap img.card-img {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    height: 100%;
    width: 100%;
    border-radius: 0px !important; }

body .zd-card {
  border-radius: 0px !important;
  overflow: hidden; }

.zd-cards .zd-card-c .card.zd_v5_card_search {
  flex-direction: column !important; }

.zd-cards .zd-card-c {
  width: 12.5%;
  float: left;
  transition: all 0.1s ease-out; }
  @media (max-width: 1224px) {
    .zd-cards .zd-card-c {
      margin-bottom: 16px; } }
  .zd-cards .zd-card-c:hover .customslider__playbtn span i {
    color: #ddd !important; }
  .zd-cards .zd-card-c:hover .card-body {
    background-color: #28292e; }
  .zd-cards .zd-card-c .zd-card {
    text-decoration: none;
    color: #ddd;
    margin: 0 0.5rem 16px 0px;
    height: calc(100% - 2.8rem);
    border-color: #222224;
    transition: box-shadow 0.5s ease;
    transition: 0.6s; }
    @media (max-width: 1224px) {
      .zd-cards .zd-card-c .zd-card {
        margin: 0 8px; } }
    .zd-cards .zd-card-c .zd-card .zd-square-image-wrap {
      border-radius: 2px !important; }
  .zd-cards .zd-card-c .zd-label-card:hover {
    opacity: 1;
    visibility: visible;
    cursor: pointer; }
  .zd-cards .zd-card-c.zd-essential-card .zd-card {
    position: relative; }
    .zd-cards .zd-card-c.zd-essential-card .zd-card .card-body {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      height: 101%;
      width: 100%;
      background-color: rgba(93, 101, 136, 0.166);
      transition: 0.3s ease-in; }
      .zd-cards .zd-card-c.zd-essential-card .zd-card .card-body:hover {
        background-color: rgba(101, 112, 159, 0.086); }

.scroller {
  height: 628px;
  width: 224px;
  overflow-y: auto; }
  @media (min-width: 0px) and (max-width: 820px) {
    .scroller {
      height: 126px; } }

.scroller::-webkit-scrollbar {
  width: 8px; }

/* Track */
.scroller::-webkit-scrollbar-track {
  background: #28292e;
  background-clip: content-box;
  border-radius: 4px; }

/* Handle */
.scroller::-webkit-scrollbar-thumb {
  background: #6c6e71;
  border: 2px solid #28292e;
  border-radius: 4px; }

/* Handle on hover */
.scroller::-webkit-scrollbar-thumb:hover {
  background: #555; }

.crate-vector {
  color: #4f7be9 !important; }
  .crate-vector .playlist-icon {
    display: inline-block !important; }

.zd-profile-content .zd-labels .zd-card-c {
  width: 25%;
  float: left; }

.zd-labels .card-img {
  height: 170px;
  min-height: 100px;
  border-radius: 0px !important; }

.zd-label-card:hover {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
  -webkit-transform: translateY(-0.8rem);
  transform: translateY(-0.8rem); }

.zd-cards-hits .zd-cards {
  height: 200px; }
  .zd-cards-hits .zd-cards .zd-card-c {
    width: 12.5%; }
    .zd-cards-hits .zd-cards .zd-card-c .card-body {
      height: 50px !important;
      padding-top: 1rem !important; }

.zd-cards-hits .sk-hits-grid {
  height: auto; }

.mini-tabs {
  align-items: self-start; }
  .mini-tabs .nav-item {
    max-width: 110px;
    flex: 1 1;
    overflow: hidden; }
    .mini-tabs .nav-item .tab-text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%; }

/* Lables Style */
@media only screen and (min-width: 991px) and (max-width: 1200px) {
  body .zd-cards .zd-card-c {
    width: 16.66666%; }
  .zd-cards-hits .zd-cards .zd-card-c {
    width: 16.66666%; } }

/* Lables Style */
@media only screen and (min-width: 768px) and (max-width: 990px) {
  body .zd-cards .zd-card-c {
    width: 20%; }
  .zd-cards-hits .zd-cards {
    height: 260px; }
    .zd-cards-hits .zd-cards .zd-card-c {
      width: 20%; }
  .zd-cards-hits .sk-hits-grid {
    height: auto; } }

@media only screen and (min-width: 660px) and (max-width: 767px) {
  body .zd-cards .zd-card-c {
    width: 25%; }
  .zd-cards-hits .zd-cards .zd-card-c {
    width: 25%; } }

@media only screen and (min-width: 541px) and (max-width: 660px) {
  body .zd-cards .zd-card-c {
    width: 33.333%; }
  .zd-cards-hits .zd-cards .zd-card-c {
    width: 33.333%; } }

@media only screen and (min-width: 0) and (max-width: 540px) {
  body .zd-cards .zd-card-c {
    width: 50%; }
    body .zd-cards .zd-card-c .card-img {
      height: 100%;
      border-radius: 0px !important; }
  .zd-cards-hits .zd-cards .zd-card-c {
    width: 50%; } }

@media only screen and (min-width: 0px) and (max-width: 414px) {
  .zd-cards-hits .zd-cards .zd-card-c {
    width: 50%; }
    .zd-cards-hits .zd-cards .zd-card-c .zd-card {
      margin: 5px; } }

#zdv4-labels-search-result .sk-layout__body {
  margin: 0 -13px; }
  @media (max-width: 1224px) {
    #zdv4-labels-search-result .sk-layout__body {
      margin: 0 -9px; } }
  @media (max-width: 750px) {
    #zdv4-labels-search-result .sk-layout__body {
      margin: 0 -13px; } }

#zdv4-labels-search-result .zd-cards .zd-card-c {
  margin-bottom: 26px; }
  @media (max-width: 1224px) {
    #zdv4-labels-search-result .zd-cards .zd-card-c {
      margin-bottom: 16px; } }
  @media (max-width: 540px) {
    #zdv4-labels-search-result .zd-cards .zd-card-c {
      width: 100%; } }
  #zdv4-labels-search-result .zd-cards .zd-card-c .zd-card {
    margin: 0 13px; }
    @media (max-width: 1224px) {
      #zdv4-labels-search-result .zd-cards .zd-card-c .zd-card {
        margin: 0 8px; } }
    @media (max-width: 540px) {
      #zdv4-labels-search-result .zd-cards .zd-card-c .zd-card {
        margin: 0 12px; } }
    @media (max-width: 540px) {
      #zdv4-labels-search-result .zd-cards .zd-card-c .zd-card.card {
        flex-direction: row;
        height: 80px;
        align-items: center; } }
    @media (max-width: 540px) {
      #zdv4-labels-search-result .zd-cards .zd-card-c .zd-card.card .zd-square-image-wrap {
        height: 100%;
        width: auto;
        padding-top: 0; } }
    @media (max-width: 540px) {
      #zdv4-labels-search-result .zd-cards .zd-card-c .zd-card.card .zd-square-image-wrap img.card-img {
        position: static;
        height: 100%;
        width: auto; } }
    @media (max-width: 540px) {
      #zdv4-labels-search-result .zd-cards .zd-card-c .zd-card.card .card-body {
        padding: 0 0 0 24px; } }
    #zdv4-labels-search-result .zd-cards .zd-card-c .zd-card.card .card-body .card-title {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      text-align: center;
      line-height: 18px;
      color: #dddddd !important; }
      @media (max-width: 540px) {
        #zdv4-labels-search-result .zd-cards .zd-card-c .zd-card.card .card-body .card-title {
          text-align: left !important; } }

#zd-search-h-artists .zd-card,
#zd-search-h-labels .zd-card {
  margin: 0 0.5rem 10px 0px; }

@media (max-width: 1224px) {
  #zd-search-h-artists .zd-cards .zd-card-c {
    margin-bottom: 0; } }

@media (max-width: 1224px) {
  #zd-search-h-releases .sk-layout__results {
    margin: 0 -8px; } }

@media (max-width: 540px) {
  #zd-search-h-releases .sk-layout__results .zd-cards .zd-card-c {
    width: 100%; } }

@media (max-width: 540px) and (max-width: 540px) {
  #zd-search-h-releases .sk-layout__results .zd-cards .zd-card-c .zd-card.card {
    flex-direction: row;
    height: 80px;
    align-items: center; } }

@media (max-width: 540px) and (max-width: 540px) {
  #zd-search-h-releases .sk-layout__results .zd-cards .zd-card-c .zd-card.card .zd-square-image-wrap {
    height: 100%;
    width: 100px;
    padding-top: 0; }
  #zd-search-h-releases .sk-layout__results .zd-text_search-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
    max-width: 190px; } }

@media (max-width: 540px) and (max-width: 540px) {
  #zd-search-h-releases .sk-layout__results .zd-cards .zd-card-c .zd-card.card .card-body {
    padding: 14px 24px; } }

@media (max-width: 540px) and (max-width: 540px) {
  #zd-search-h-releases .sk-layout__results .zd-cards .zd-card-c .zd-card.card .zd-square-image-wrap img.card-img {
    position: static;
    height: 100%;
    width: auto; }
  #zd-search-h-releases .sk-layout__results .card-body div {
    text-align: left !important; } }

.crate-playlist-view {
  width: 224px !important;
  margin-left: 17px; }
  .crate-playlist-view .playlist-heading {
    font-size: 12px;
    color: #434b52;
    font-style: normal;
    font-family: 'Poppins';
    margin-bottom: 16px; }
  .crate-playlist-view .crate-main-btn {
    width: 224px;
    height: 40px;
    background-color: #303139;
    border-radius: 2px;
    color: #8e8e8e;
    font-size: 12px;
    border: #303139;
    margin-bottom: 4px; }
    .crate-playlist-view .crate-main-btn i {
      font-size: 11px; }
  .crate-playlist-view .playlist-container {
    margin-bottom: 2px;
    border: none;
    background: #1c1e22 !important; }
    .crate-playlist-view .playlist-container .card-body {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      background: #242529;
      color: #8e8e8e;
      cursor: pointer; }
      .crate-playlist-view .playlist-container .card-body .main-crate {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center; }
        .crate-playlist-view .playlist-container .card-body .main-crate .playlist-value {
          padding-left: 10px;
          width: 137px;
          top: 10px;
          font-size: 12px !important;
          height: 18px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden; }
      .crate-playlist-view .playlist-container .card-body .playlist-icon {
        display: none;
        position: absolute;
        left: 168px; }
      .crate-playlist-view .playlist-container .card-body .playlist-drag-drop {
        display: none; }
      .crate-playlist-view .playlist-container .card-body .crate-icon {
        padding-left: 15px; }
      .crate-playlist-view .playlist-container .card-body:hover .playlist-drag-drop {
        display: inline-block; }

.demotest {
  display: inline-block !important; }

.zd_crate_table .zd-album-column .zd-release-favourite i {
  display: none; }

.zd_crate_table .zd-album-column .zd-release-favourite:hover i {
  display: block; }

.zd_crate_table .zd-album-column .zd-release-favourite .zdv3-icon-filled-favourite {
  display: block;
  color: #4d7ce8 !important; }

.zd_crate_table .zd-release-crates .crate-dropdown-menu {
  -webkit-transform: translate3d(-129px, 7px, 0px) !important;
          transform: translate3d(-129px, 7px, 0px) !important;
  width: 171px;
  left: -14px !important; }

/* width */
::-webkit-scrollbar {
  width: 10px; }

/*!* Track *!*/
::-webkit-scrollbar-track {
  background: #131213; }

/**/
/*!* Handle *!*/
::-webkit-scrollbar-thumb {
  background: #33343a; }

/*!* Handle on hover *!*/
::-webkit-scrollbar-thumb:hover {
  background: #33343a; }

body.zd-profile {
  background: #18181b; }
  body.zd-profile .cr-page {
    background-color: #18181b; }

.zd-blue {
  color: #7685b3 !important; }
  .zd-blue:hover {
    color: #dddddd !important;
    opacity: 1 !important; }

.zd-blue-active {
  color: #4f7be9 !important; }
  .zd-blue-active:hover {
    color: #dddddd !important;
    opacity: 1 !important; }

.zd-key-blue {
  color: #4f7be9 !important;
  padding-right: 8px; }
  .zd-key-blue:hover {
    color: #dddddd !important;
    opacity: 1 !important;
    cursor: pointer; }

.zd-red {
  color: #ff0000 !important; }

:focus,
button:focus {
  outline: none; }

.zd-show {
  display: block !important; }

.zd-hide,
.is-hidden {
  display: none !important; }

.zd-hide-spinner {
  display: none;
  margin-right: 3px; }

.infinite-scroll-component {
  min-height: 200px !important; }

.zd-cursor-pointer {
  cursor: pointer; }

.zd-loader,
.cr-page-spinner {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; }

.zd-page-loader {
  display: block;
  position: fixed;
  z-index: 99999;
  background-color: #191b1f;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  transition: all 0.6s ease; }

.track-info-block {
  position: relative; }

.zd-info-loader {
  display: block;
  position: absolute;
  z-index: 99999;
  background-color: rgba(34, 34, 34, 0.88);
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  transition: all 0.6s ease; }

.zd-widget-loader {
  position: absolute;
  top: -10%; }

.zd-vspace-20 {
  height: 20px; }

.zd-vspace-40 {
  height: 40px; }

.zd-width-200 {
  width: 200px; }

.zd-width-300 {
  width: 300px; }

.zd-infinite-loader {
  position: unset;
  margin-top: 70px; }

body .zd-init-loader {
  background-color: transparent; }

.zd-infinite-loader {
  position: relative;
  margin-top: 0px;
  padding: 20px 0;
  height: auto; }

.zd-trending-loader {
  margin-top: 50px;
  height: 100px; }

.zd-tag-selected,
.zd-menu-tag-selected {
  background: orange !important;
  color: black !important; }

.zd-text-muted {
  color: #909090 !important; }

.zd-section-title {
  font-size: 0.72rem; }
  .zd-section-title span {
    border-bottom: 2px solid #4d7ce8;
    padding-bottom: 2px; }

.release-data .zd-section-title {
  margin: 15px 10px 5px 10px; }

table .zd-small-album-cell {
  width: 40px;
  max-width: 40px;
  padding: 0;
  overflow: hidden;
  margin: 0;
  vertical-align: top; }
  table .zd-small-album-cell .zd-album-art {
    width: 40px;
    max-height: 40px;
    min-height: 40px;
    border-radius: 2px;
    cursor: pointer; }
    table .zd-small-album-cell .zd-album-art:hover {
      opacity: 1; }

table .zd-medium-album-cell {
  width: 48px;
  max-width: 48px; }
  table .zd-medium-album-cell .zd-album-art {
    border-radius: 2px;
    width: 48px; }

table .zd-large-album-cell {
  max-width: 48px;
  width: 48px;
  vertical-align: top; }
  table .zd-large-album-cell .zd-album-art {
    width: 48px;
    max-height: 48px;
    border-radius: 2px;
    min-height: 48px; }

.zd-trending-tags-lists-btn {
  display: block; }
  .zd-trending-tags-lists-btn .list-group-item {
    text-align: center;
    border: 1px solid #323743;
    border-radius: 0px !important;
    transition: all 0.3s ease;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 4px 8px;
    width: 18%;
    display: block;
    float: left;
    cursor: pointer;
    color: #eaeaea !important; }
    .zd-trending-tags-lists-btn .list-group-item:hover,
    .zd-trending-tags-lists-btn .list-group-item :focus {
      background-color: #3b49af; }

.zd-tags-lists-btn {
  display: block; }
  .zd-tags-lists-btn .list-group-item {
    border: 1px solid #323743;
    transition: all 0.3s ease;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 3px 2px;
    width: 23.9%;
    display: block;
    float: left;
    cursor: pointer;
    color: #eaeaea !important; }
    .zd-tags-lists-btn .list-group-item:hover,
    .zd-tags-lists-btn .list-group-item :focus {
      background-color: #3b49af; }
    .zd-tags-lists-btn .list-group-item:first-child {
      border-radius: 0px !important; }
    .zd-tags-lists-btn .list-group-item:last-child {
      border-radius: 0px !important; }

.text-muted {
  color: #909090 !important; }

.zd-text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  color: #6b7883 !important; }

.zd-trending-text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden; }

.zd-artist-list .zd-artist-name:after {
  content: ', '; }

.zd-artist-list .zd-artist-name:last-child:after {
  display: none; }

.zd-cursor {
  cursor: pointer; }

.zd-cursor-default {
  cursor: default; }

.zd-search-track-view {
  position: relative; }

body .zd-section-loader {
  position: fixed; }

body .zd-player-icon-download {
  font-size: 22px; }

body .zd-player-icon-download-newhomepage {
  color: #575f66 !important; }

body .zd-error-row:hover,
body .zd-error-row {
  background-color: transparent !important;
  border-bottom: none !important; }
  body .zd-error-row:hover td,
  body .zd-error-row td {
    padding: 20px; }

body .zd-reprot-modal {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) !important;
          transform: translate(-50%, -50%) !important; }
  body .zd-reprot-modal .modal-content {
    width: 360px;
    margin-left: auto;
    margin-right: auto; }

.zd-btn-disable:hover,
.zd-btn-disable:focus,
.zd-btn-disable {
  opacity: 0.6;
  cursor: auto;
  background: #6a7fb1;
  border-color: #6a7fb1; }

.past-download {
  background-color: #242529; }

.zd-download-title {
  font-size: 0.72rem;
  align-self: center; }
  .zd-download-title span {
    border-bottom: 2px solid #4d7ce8;
    padding-bottom: 2px; }

.zd-search-back {
  color: #dddddd;
  border-radius: 0px !important;
  cursor: pointer;
  margin-right: 8px;
  float: left;
  height: 31px; }
  .zd-search-back:hover {
    color: #4d7ce8; }

.zd-text-434B52 {
  color: #434b52 !important; }

.zd-release-back {
  color: #dddddd !important;
  cursor: pointer;
  float: right;
  margin-left: 20px; }
  .zd-release-back:hover {
    color: #4d7ce8; }

.player-close svg {
  font-size: 18px; }

.zd-exceeded-msg {
  color: #fff !important; }
  .zd-exceeded-msg:hover {
    color: #fff; }

.zd-sub-genre-track-view .zd-track-view,
.zd-sub-genre-track-view .zd-search-track-view {
  padding-top: 0px !important;
  padding-left: 20px !important;
  padding-right: 20px !important; }

.zd-rotate {
  transition: all 0.2s linear; }

.zd-rotate.up {
  color: #4f7be9;
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg); }

.zd-modal-index-color {
  transition: all 0.1s linear;
  font-size: 12px; }
  .zd-modal-index-color:hover {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    color: #4f7be9; }
  .zd-modal-index-color.index-selected {
    color: #4f7be9; }

body {
  position: relative; }
  body .zd-email-popup {
    position: absolute;
    min-width: 100vw !important;
    left: 0; }

.cancel-btn {
  background: #808080; }
  .cancel-btn:hover {
    background: #8d8d8d; }

.zd_trending_packs_card_svg {
  background-color: '#141414';
  height: 100%;
  width: 100%; }
  .zd_trending_packs_card_svg .randersvg {
    margin-left: 70px;
    position: relative;
    top: -19px; }
    .zd_trending_packs_card_svg .randersvg svg {
      height: 90px;
      width: 100px; }
  .zd_trending_packs_card_svg p {
    width: 138px;
    font-size: 12px !important; }

.zd_v5 {
  white-space: nowrap; }

.zd_new_header {
  display: flex;
  justify-content: space-between; }
  .zd_new_header .zd_v5_tab {
    padding-top: 24px;
    padding-bottom: 6px;
    cursor: pointer; }
    .zd_new_header .zd_v5_tab span {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      color: #6d7882;
      padding: 0px 6px 0 6px; }
  .zd_new_header .active {
    color: #4978ef !important;
    border-bottom: 1px solid #4978ef !important; }
    .zd_new_header .active span {
      color: #4978ef; }

.zd_v5_tab:hover {
  color: #ddd !important;
  border-bottom: 1px solid #ddd !important; }
  .zd_v5_tab:hover span {
    color: #ddd !important; }

.zd_v5_filter {
  background-color: #32333a;
  border: 1px solid #4a4b54;
  padding: 5.5px; }

@media (max-width: 750px) {
  .zd-new_filter_v5 {
    top: 90px !important; } }

.trendingpacks__title {
  font-size: 14px;
  color: #ddd;
  font-weight: 500; }

.zd-new-newreleases-page .zd-v5-artist span {
  margin-left: 3px; }

.zd-v5_Artist {
  color: #6b7883 !important; }
  @media (min-width: 750px) {
    .zd-v5_Artist {
      display: none !important; } }

.zd-v5-home-text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inherit !important; }

.zd-v5-trnd-artist {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inherit !important; }

.zd-v5-artist {
  margin-top: -2px; }
  .zd-v5-artist.zd-text-ellipsis {
    display: block; }
  @media (min-width: 750px) {
    .zd-v5-artist {
      display: none !important; } }
  @media screen and (max-width: 416px) {
    .zd-v5-artist {
      margin-left: 0px; } }
  .zd-v5-artist .zd-text-dddddd {
    color: #6d7882 !important; }
  .zd-v5-artist .zd-artist-name {
    font-size: 12px;
    color: #6d7882; }

@media (max-width: 750px) {
  .zd-track-view .zd-album-table tbody .zd-album-title {
    line-height: 1.4; }
  .zd-track-view .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-small-album-cell img {
    width: 68px;
    height: 68px;
    max-height: 68px;
    min-height: 68px;
    max-width: 68px;
    min-width: 68px; }
  .zd-track-view .zd-double-icons {
    width: 8%; }
  .zd-track-view .zd-new-top-downloads-parent-table tbody tr td.zd-small-album-cell {
    width: 68px !important;
    min-width: 68px !important; }
  .zd-track-view .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr td.zd-release-crates {
    width: 4%; }
    .zd-track-view .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr td.zd-release-crates .zd-dropdown-crate {
      margin-top: 22px !important; }
  .zd-track-view .zd-play-col {
    width: 56px !important;
    padding-left: 16px;
    padding-right: 16px; } }

@media (max-width: 750px) {
  .zd-new-top-downloads-parent-table .zd-title {
    width: 100% !important; } }

@media (max-width: 750px) {
  .top-downloads-page .zd-body-row-table tbody tr td.zd-double-icons {
    height: 68px !important; }
    .top-downloads-page .zd-body-row-table tbody tr td.zd-double-icons.zd-icons {
      display: table-cell !important; }
      .top-downloads-page .zd-body-row-table tbody tr td.zd-double-icons.zd-icons a {
        margin-top: 0; } }

@media (max-width: 750px) {
  .top-downloads-page .zd-body-row-table tbody tr td.zd-double-icons.zd-icons {
    display: table-cell !important; }
    .top-downloads-page .zd-body-row-table tbody tr td.zd-double-icons.zd-icons a {
      margin-top: 0;
      display: table-cell !important; } }

.track-name {
  cursor: inherit !important; }

.zd_v5_cursor_hide {
  color: red !important; }

.zd-hide-cursor {
  cursor: inherit !important; }
  .zd-hide-cursor a {
    cursor: inherit !important; }

body,
body a {
  -webkit-font-smoothing: unset;
  text-rendering: optimizeLegibility; }

a {
  color: #5764c6; }

.gray-text {
  color: #b7b7b7; }

.bg-primary .navbar-nav .active > .nav-link {
  color: #dddddd !important; }

.bg-light.navbar {
  background-color: #000 !important; }

.bg-light.navbar-light .navbar-nav .nav-link:focus,
.bg-light.navbar-light .navbar-nav .nav-link:hover,
.bg-light.navbar-light .navbar-nav .active > .nav-link {
  color: #dddddd !important; }

.blockquote-footer {
  color: #999; }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #375a7f; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #444; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #999; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #202225; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #00bc8c; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #3498db; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #e74c3c; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #f39c12; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-primary:hover,
.table-hover .table-primary:hover > th,
.table-hover .table-primary:hover > td {
  background-color: #2f4d6d; }

.table-hover .table-secondary:hover,
.table-hover .table-secondary:hover > th,
.table-hover .table-secondary:hover > td {
  background-color: #373737; }

.table-hover .table-light:hover,
.table-hover .table-light:hover > th,
.table-hover .table-light:hover > td {
  background-color: #8c8c8c; }

.table-hover .table-dark:hover,
.table-hover .table-dark:hover > th,
.table-hover .table-dark:hover > td {
  background-color: #141517; }

.table-hover .table-success:hover,
.table-hover .table-success:hover > th,
.table-hover .table-success:hover > td {
  background-color: #00a379; }

.table-hover .table-info:hover,
.table-hover .table-info:hover > th,
.table-hover .table-info:hover > td {
  background-color: #258cd1; }

.table-hover .table-danger:hover,
.table-hover .table-danger:hover > th,
.table-hover .table-danger:hover > td {
  background-color: #e43725; }

.table-hover .table-warning:hover,
.table-hover .table-warning:hover > th,
.table-hover .table-warning:hover > td {
  background-color: #e08e0b; }

.table-hover .table-active:hover,
.table-hover .table-active:hover > th,
.table-hover .table-active:hover > td {
  background-color: rgba(0, 0, 0, 0.075); }

.input-group-addon {
  color: #dddddd; }

.nav-tabs .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link.active:focus,
.nav-tabs .nav-link.active:hover,
.nav-tabs .nav-item.open .nav-link,
.nav-tabs .nav-item.open .nav-link:focus,
.nav-tabs .nav-item.open .nav-link:hover,
.nav-pills .nav-link,
.nav-pills .nav-link.active,
.nav-pills .nav-link.active:focus,
.nav-pills .nav-link.active:hover,
.nav-pills .nav-item.open .nav-link,
.nav-pills .nav-item.open .nav-link:focus,
.nav-pills .nav-item.open .nav-link:hover {
  color: #dddddd; }

.breadcrumb a {
  color: #dddddd; }

.pagination a:hover {
  text-decoration: none; }

.close {
  opacity: 0.4; }
  .close:hover, .close:focus {
    opacity: 1; }

.alert {
  border: none;
  color: #dddddd; }
  .alert a,
  .alert .alert-link {
    color: #dddddd;
    text-decoration: underline; }
  .alert-primary {
    background: #4d7ce8 linear-gradient(180deg, #638be6, #4d7ce8) repeat-x; }
  .alert-secondary {
    background: #7685b3 linear-gradient(180deg, #8592b9, #7685b3) repeat-x; }
  .alert-success {
    background: #45b649 linear-gradient(180deg, #5cbc5f, #45b649) repeat-x; }
  .alert-info {
    background: #00c9ff linear-gradient(180deg, #21ccfa, #00c9ff) repeat-x; }
  .alert-warning {
    background: #f8c283 linear-gradient(180deg, #f4c691, #f8c283) repeat-x; }
  .alert-danger {
    background: #f85032 linear-gradient(180deg, #f4654c, #f85032) repeat-x; }
  .alert-light {
    background: #999 linear-gradient(180deg, #a3a3a3, #999) repeat-x; }
  .alert-dark {
    background: #202225 linear-gradient(180deg, #3c3e41, #202225) repeat-x; }
  .alert-grey {
    background: #d8d8d8 linear-gradient(180deg, #d9d9d9, #d8d8d8) repeat-x; }
  .alert-grey2 {
    background: #575f66 linear-gradient(180deg, #6b7278, #575f66) repeat-x; }

.list-group-item-action {
  color: #dddddd; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    background-color: #444;
    color: #dddddd; }
  .list-group-item-action .list-group-item-heading {
    color: #dddddd; }

.card {
  border-radius: 4px !important;
  background: #292A2F; }
  .card .list-group:last-child .list-group-item:last-child {
    border-radius: 0px !important; }

.zd-trending-packs-view-cover-text {
  padding-bottom: 9px !important;
  border-bottom: 2px solid;
  font-size: 8px !important;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  border-image-slice: 2;
  line-height: 9px !important; }

.zd-trending-pack-tags {
  padding-bottom: 5px;
  border-bottom: 2px solid;
  font-size: 12px;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  border-image-slice: 2; }

.zd-feedback-blue-text {
  color: #4f7be9 !important; }

.feedback-td {
  width: 100% !important;
  height: 100% !important; }

.zd-icon.zdv3-icon-play.zd-icon-repeat-track {
  color: #666; }

@media only screen and (min-width: 1281px) and (max-width: 10000px) {
  body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content .progress-bar {
    background-size: cover; } }

@media only screen and (min-width: 961px) and (max-width: 10000px) {
  .zd-track-view .zd-title {
    width: 100% !important; }
  .zd-track-view .playlist-container .zd-dropdown-crate {
    margin-top: 0; }
  .zd-track-view .zd-title.zd-chart-title {
    width: 80% !important; }
  .zd-track-view .zd-label {
    width: 30% !important; }
  .zd-track-view .zd-genre {
    width: 40% !important; }
  .zd-track-view .zd-genre-view-genre {
    width: 100% !important; }
  .zd-track-view .zd-rotate-icons {
    width: 25px !important; }
  .zd-track-view .zd-time {
    width: 60px !important; }
  .zd-track-view .zd-rating {
    width: 60px !important; }
  .zd-track-view .zd-bpm {
    width: 60px !important;
    color: #768492; }
  .zd-track-view .zd-key {
    width: 60px !important;
    color: #768492; }
  .zd-track-view .zd-release {
    width: 80px !important; }
  .zd-track-view .zd-tag {
    width: 100px !important; }
    .zd-track-view .zd-tag .zd-tag-label {
      max-width: 96px; }
  .zd-track-view .zd-favourite {
    width: 24px; }
  .zd-track-view .zd-icons {
    width: 60px !important; }
  .zd-track-view .zd-crate {
    width: 24px !important; }
  .zd-track-view .zd-dropdown-crate {
    margin-top: 8px; }
  .zd-track-view .zd-last-row {
    width: 4px !important;
    vertical-align: -webkit-baseline-middle; }
  .zd-track-view .zd-artist {
    width: 53% !important;
    color: #ddd !important; }
  .zd-track-view .zd-position {
    width: 28% !important; } }

@media (max-width: 340px) {
  .zd_fav_card {
    width: 290px !important; } }

@media (max-width: 750px) {
  .zd-release-icon {
    width: 48px !important; }
  table .zd-small-album-cell .zd-album-art {
    width: 50px;
    max-height: 50px;
    min-height: 50px;
    border-radius: 2px;
    cursor: pointer; }
  table .zd-small-album-cell {
    width: 50px;
    max-width: 50px;
    padding: 0;
    overflow: hidden;
    margin: 0;
    vertical-align: top; } }

@media (max-width: 416px) {
  .zd-line-tab .nav-item .nav-link {
    padding-left: 4px !important;
    padding-right: 4px !important;
    font-size: 12px; } }

@media (min-width: 750px) and (max-width: 960px) {
  .zd-new-newreleases-page-child-table .zd-new-newreleases-page-child-table-tbody .zd-release-time {
    width: 48px; }
  .zd-new-newreleases-page-child-table .zd-new-newreleases-page-child-table-tbody .zd-release-icon {
    width: 10%; }
  .zd-new-newreleases-page-child-table .zd-new-newreleases-page-child-table-tbody .zd-favourite {
    width: 24px !important; }
  .zd-new-newreleases-page-child-table .zd-new-newreleases-page-child-table-tbody .zd-double-icons a {
    padding: 0 24px;
    font-size: 24px; } }

@media (min-width: 0px) and (max-width: 1224px) {
  .zd-release-time {
    width: 48px; }
  .zd-release-icon {
    width: 16%; }
  .zd-favourite {
    width: 24px !important; } }

@media only screen and (min-width: 750px) and (max-width: 1224px) {
  body .zd-feedback-comment input {
    height: 97px !important; }
  body .zd-feedback-active-border {
    height: 216px; }
  body .zd-feedback-comment {
    padding-left: 94px; } }

@media (max-width: 750px) {
  .zd-feedback-active-border {
    height: 342px; }
  .feedback-sm-show {
    display: block; }
  .zd-feedback-favourite-mix-dropdown {
    position: absolute !important;
    padding-left: 0%; }
  .zd-feedback-rating-section .zd-feedback-rating {
    margin-left: 0px; }
  .zd-feedback-comment {
    padding-left: 0px; }
  .zd-feedback-comment input {
    height: 116px !important; }
  .zd-favourite {
    width: 34px !important; } }

@media only screen and (min-width: 1200px) {
  .zd-top-track-list > .row > div:first-child {
    width: 30%;
    flex: 0 0 30%;
    max-width: 30%;
    padding-right: 0; }
  .zd-top-track-list > .row > div:last-child {
    width: 70%;
    flex: 0 0 70%;
    max-width: 70%;
    padding-left: 0; } }

/* Table Style */
@media only screen and (max-width: 1024px) {
  body .zd-album-table .zd-album-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; } }

@media only screen and (max-width: 960px) and (min-width: 750px) {
  .zd-album-column .zd-new-top-downloads-header-table .zd-new-top-downloads-header-tbody tr .zd-title {
    width: 38% !important; } }

@media only screen and (min-width: 0px) and (max-width: 750px) {
  .zd-new-top-downloads-child-table .zd-trending-pack-child-tbody .zd-favourite {
    padding-top: 0px !important; } }

@media only screen and (max-width: 960px) and (min-width: 750px) {
  body .zd-album-table .zd-singlepack-header-tbody .zd-rating {
    display: none; }
  body .zd-album-table .zd-singlepack-tbody .zd-last-row {
    width: 0px; }
  body .zd-album-table .zd-singlepack-tbody .zd-double-icons a {
    padding: 0px 12px;
    font-size: 24px; }
  body .zd-album-table .zd-singlepack-tbody .zd-release {
    white-space: nowrap; }
  body .zd-new-top-downloads-parent-table .zd-gener-new-release-tbody .zd-last-row {
    width: 10px; }
  body .zd-new-top-downloads-parent-table .zd-gener-new-release-tbody .zd-double-icons a {
    padding: 0px 20px;
    font-size: 24px; }
  body .zd-new-top-downloads-parent-table .zd-gener-new-release-tbody .zd-favourite {
    width: 14px !important; }
  body .zd-new-top-downloads-parent-table .zd-gener-new-release-tbody .zd-tracklist-dropdown-box .zd-dropdown-crate {
    margin-top: 12px; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-header-table tbody tr td {
    display: none; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-header-table tbody tr td:last-child,
  body .zd-new-newreleases-table .zd-new-newreleases-page-header-table tbody tr .zd-width-48,
  body .zd-new-newreleases-table .zd-new-newreleases-page-header-table tbody tr .zd-title,
  body .zd-new-newreleases-table .zd-new-newreleases-page-header-table tbody tr .zd-genre,
  body .zd-new-newreleases-table .zd-new-newreleases-page-header-table tbody tr .zd-rotate-icons,
  body .zd-new-newreleases-table .zd-new-newreleases-page-header-table tbody tr .zd-favourite,
  body .zd-new-newreleases-table .zd-new-newreleases-page-header-table tbody tr .zd-crate,
  body .zd-new-newreleases-table .zd-new-newreleases-page-header-table tbody tr .zd-icons,
  body .zd-new-newreleases-table .zd-new-newreleases-page-header-table tbody tr .zd-time {
    display: table-cell; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-header-table tbody tr .zd-rotate-icons {
    width: 20px; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-header-table tbody tr .zd-title {
    width: 42% !important; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-header-table tbody tr .zd-genre {
    width: 19% !important; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-main-table tbody tr td {
    display: none; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-main-table tbody tr .zd-genre {
    width: 25% !important; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-main-table tbody tr td:last-child,
  body .zd-new-newreleases-table .zd-new-newreleases-page-main-table tbody tr .zd-title,
  body .zd-new-newreleases-table .zd-new-newreleases-page-main-table tbody tr .zd-rotate-icons,
  body .zd-new-newreleases-table .zd-new-newreleases-page-main-table tbody tr .zd-large-album-cell,
  body .zd-new-newreleases-table .zd-new-newreleases-page-main-table tbody tr .zd-release-play,
  body .zd-new-newreleases-table .zd-new-newreleases-page-main-table tbody tr .zd-time,
  body .zd-new-newreleases-table .zd-new-newreleases-page-main-table tbody tr .zd-favourite,
  body .zd-new-newreleases-table .zd-new-newreleases-page-main-table tbody tr .zd-icons,
  body .zd-new-newreleases-table .zd-new-newreleases-page-main-table tbody tr .zd-crate,
  body .zd-new-newreleases-table .zd-new-newreleases-page-main-table tbody tr .zd-genre {
    display: table-cell; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-main-table tbody tr td {
    align-items: center; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-main-table tbody tr .zd-release-title span.zd-hide {
    display: block !important; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-main-table tbody tr .zd-float-icons {
    width: 20px; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-main-table tbody tr .zd-title {
    width: 42.4% !important; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-child-table .zd-dropdown-crate {
    margin-top: 8px !important; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-child-table tbody tr .zd-dropdown-crate {
    margin-top: 1px !important; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-child-table tbody tr td {
    padding: 10px 0.25rem;
    background-color: #242529;
    align-items: center;
    display: none; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-child-table tbody tr td:last-child,
  body .zd-new-newreleases-table .zd-new-newreleases-page-child-table tbody tr .zd-title,
  body .zd-new-newreleases-table .zd-new-newreleases-page-child-table tbody tr .zd-rotate-icons,
  body .zd-new-newreleases-table .zd-new-newreleases-page-child-table tbody tr .zd-large-album-cell,
  body .zd-new-newreleases-table .zd-new-newreleases-page-child-table tbody tr .zd-release-play,
  body .zd-new-newreleases-table .zd-new-newreleases-page-child-table tbody tr .zd-time,
  body .zd-new-newreleases-table .zd-new-newreleases-page-child-table tbody tr .zd-release-favourite,
  body .zd-new-newreleases-table .zd-new-newreleases-page-child-table tbody tr .zd-release-crates,
  body .zd-new-newreleases-table .zd-new-newreleases-page-child-table tbody tr .zd-release-icon,
  body .zd-new-newreleases-table .zd-new-newreleases-page-child-table tbody tr .zd-genre {
    display: table-cell; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-child-table tbody tr .zd-title {
    width: 39% !important; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-child-table tbody tr .zd-float-icons {
    width: 20px; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-child-table tbody tr .zd-genre {
    width: 19% !important;
    padding-left: 8px; }
  body .zd-top-charts-table .zd-new-newreleases-page-header-table tbody tr td {
    display: none; }
  body .zd-top-charts-table .zd-new-newreleases-page-header-table tbody tr td:last-child,
  body .zd-top-charts-table .zd-new-newreleases-page-header-table tbody tr .zd-width-48,
  body .zd-top-charts-table .zd-new-newreleases-page-header-table tbody tr .zd-title,
  body .zd-top-charts-table .zd-new-newreleases-page-header-table tbody tr .zd-genre,
  body .zd-top-charts-table .zd-new-newreleases-page-header-table tbody tr .zd-large-album-cell,
  body .zd-top-charts-table .zd-new-newreleases-page-header-table tbody tr .zd-play-col,
  body .zd-top-charts-table .zd-new-newreleases-page-header-table tbody tr .zd-icons,
  body .zd-top-charts-table .zd-new-newreleases-page-header-table tbody tr .zd-label {
    display: table-cell; }
  body .zd-top-charts-table .zd-new-newreleases-page-header-table tbody tr .zd-float-icons {
    width: 20px; }
  body .zd-top-charts-table .zd-new-newreleases-page-header-table tbody tr .zd-title {
    width: 25% !important; }
  body .zd-top-charts-table .zd-new-newreleases-page-header-table tbody tr .zd-genre,
  body .zd-top-charts-table .zd-new-newreleases-page-header-table tbody tr .zd-label {
    width: 15% !important; }
  body .zd-top-charts-table .zd-new-newreleases-page-main-table tbody tr td {
    display: none; }
  body .zd-top-charts-table .zd-new-newreleases-page-main-table tbody tr .zd-genre,
  body .zd-top-charts-table .zd-new-newreleases-page-main-table tbody tr .zd-label {
    width: 15% !important; }
  body .zd-top-charts-table .zd-new-newreleases-page-main-table tbody tr td:last-child,
  body .zd-top-charts-table .zd-new-newreleases-page-main-table tbody tr .zd-width-48,
  body .zd-top-charts-table .zd-new-newreleases-page-main-table tbody tr .zd-title,
  body .zd-top-charts-table .zd-new-newreleases-page-main-table tbody tr .zd-genre,
  body .zd-top-charts-table .zd-new-newreleases-page-main-table tbody tr .zd-large-album-cell,
  body .zd-top-charts-table .zd-new-newreleases-page-main-table tbody tr .zd-play-col,
  body .zd-top-charts-table .zd-new-newreleases-page-main-table tbody tr .zd-icons,
  body .zd-top-charts-table .zd-new-newreleases-page-main-table tbody tr .zd-label,
  body .zd-top-charts-table .zd-new-newreleases-page-main-table tbody tr .zd-time {
    display: table-cell; }
  body .zd-top-charts-table .zd-new-newreleases-page-main-table tbody tr td {
    align-items: center; }
  body .zd-top-charts-table .zd-new-newreleases-page-main-table tbody tr .zd-title span.zd-hide {
    display: block !important; }
  body .zd-top-charts-table .zd-new-newreleases-page-main-table tbody tr .zd-float-icons {
    width: 20px; }
  body .zd-top-charts-table .zd-new-newreleases-page-main-table tbody tr .zd-title {
    width: 38% !important; }
  body .zd-top-charts-table .zd-new-newreleases-page-child-table tbody tr td {
    align-items: center;
    display: none; }
  body .zd-top-charts-table .zd-new-newreleases-page-child-table tbody tr td:last-child,
  body .zd-top-charts-table .zd-new-newreleases-page-child-table tbody tr .zd-width-48,
  body .zd-top-charts-table .zd-new-newreleases-page-child-table tbody tr .zd-title,
  body .zd-top-charts-table .zd-new-newreleases-page-child-table tbody tr .zd-genre,
  body .zd-top-charts-table .zd-new-newreleases-page-child-table tbody tr .zd-large-album-cell,
  body .zd-top-charts-table .zd-new-newreleases-page-child-table tbody tr .zd-play-col,
  body .zd-top-charts-table .zd-new-newreleases-page-child-table tbody tr .zd-icons,
  body .zd-top-charts-table .zd-new-newreleases-page-child-table tbody tr .zd-label,
  body .zd-top-charts-table .zd-new-newreleases-page-child-table tbody tr .zd-crate {
    display: table-cell; }
  body .zd-top-charts-table .zd-new-newreleases-page-child-table tbody tr .zd-genre,
  body .zd-top-charts-table .zd-new-newreleases-page-child-table tbody tr .zd-label {
    width: 15% !important; }
  body .zd-top-charts-table .zd-new-newreleases-page-child-table tbody tr .zd-float-icons {
    width: 20px; }
  body .zd-top-charts-table .zd-new-newreleases-page-child-table tbody tr .zd-title {
    width: 25% !important; } }

@media only screen and (max-width: 960px) and (min-width: 750px) and (min-width: 751px) and (max-width: 960px) {
  body .zd-top-charts-table .zd-new-newreleases-page-child-table tbody tr .zd-favourite {
    width: 0px !important;
    padding-left: 32px; }
  body .zd-top-charts-table .zd-new-newreleases-page-child-table tbody tr .zd-last-row {
    width: 14px; } }

@media only screen and (max-width: 960px) and (min-width: 750px) {
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-header-table tbody tr td {
    display: none; }
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-header-table tbody tr td:last-child,
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-header-table tbody tr .zd-track-cover-col,
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-header-table tbody tr .zd-title,
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-header-table tbody tr .zd-genre,
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-header-table tbody tr .zd-rotate-icons,
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-header-table tbody tr .zd-small-album-cell,
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-header-table tbody tr .zd-time,
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-header-table tbody tr .zd-favourite,
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-header-table tbody tr .zd-crate,
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-header-table tbody tr .zd-icons,
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-header-table tbody tr .zd-play-col {
    display: table-cell; }
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-header-table tbody tr .zd-rotate-icons {
    width: 20px; }
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-header-table tbody tr .zd-title {
    width: 30% !important; }
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-header-table tbody tr .zd-genre,
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-header-table tbody tr .zd-artist {
    width: 15% !important; }
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-header-table tbody tr .zd-small-album-cell {
    width: 48px; }
  body .zd-new-top-downloads-main-table .zd-zipdj-pack-header-tbody .zd-width-48 {
    width: 140px; }
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table .zd-zipdj-pack-tbody tr .zd-title {
    width: 48% !important; }
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table .zd-zipdj-pack-tbody tr .zd-title {
    width: 40% !important; }
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table .zd-zipdj-pack-tbody tr .zd-crate .zd-tracklist-dropdown-box .zd-dropdown-crate {
    margin-top: 13px; }
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table .zd-zipdj-pack-tbody tr .zd-release-icon {
    width: 10%; }
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table .zd-zipdj-pack-tbody tr .zd-genre {
    width: 15% !important; }
  body .zd-new-top-downloads-main-table .zd-album-table .zd-trending-pack-header-tbody .zd-title {
    width: 26% !important; }
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table .zd-trending-pack-parent-tbody .zd-title,
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table .zd-trending-pack-child-tbody .zd-title {
    width: 42% !important; }
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table .zd-trending-pack-child-tbody .zd-release-favourite {
    padding-left: 30px; }
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table .zd-trending-pack-child-tbody .zd-double-icons {
    text-align: center;
    padding-right: 32px; }
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table .zd-trending-pack-child-tbody .zd-dropdown-crate {
    margin-top: 13px; }
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr,
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr {
    height: 48px !important; }
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr td,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr td {
      display: none; }
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr td:last-child,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-topdownloads-count,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-small-album-cell,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-play-col,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-title,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-genre,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-favourite,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-crate,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-icons,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-rotate-icons,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-time,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr td:last-child,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-topdownloads-count,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-small-album-cell,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-play-col,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-title,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-genre,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-favourite,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-crate,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-icons,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-rotate-icons,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-time {
      display: table-cell; }
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-rotate-icons,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-rotate-icons {
      width: 20px; }
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-title,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-title {
      width: 30% !important; }
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-genre,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-artist,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-genre,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-artist {
      width: 15% !important; }
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-small-album-cell,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-small-album-cell {
      width: 48px; }
      body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-small-album-cell img,
      body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-small-album-cell .zd-release-thumb,
      body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-small-album-cell img,
      body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-small-album-cell .zd-release-thumb {
        max-height: 48px;
        min-height: 48px;
        max-width: 48px;
        min-width: 48px; }
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-title a.zd-hide,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-title a.zd-hide {
      display: block !important; }
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-title a .track-name,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-title a .track-name {
      display: none !important; }
  body .zd-new-top-downloads-main-table .zd-trending-table tbody tr .zd-title,
  body .zd-new-top-downloads-main-table .zd-topdownloads-table tbody tr .zd-title {
    width: 37% !important; }
  body .zd-new-top-downloads-main-table .zd-trending-table tbody tr .zd-genre,
  body .zd-new-top-downloads-main-table .zd-topdownloads-table tbody tr .zd-genre {
    width: 25%; }
  body .zd-new-top-downloads-main-table .zd-trending-table tbody tr .zd-artist,
  body .zd-new-top-downloads-main-table .zd-topdownloads-table tbody tr .zd-artist {
    padding-left: 0px; }
  body .zd-new-top-downloads-main-table .zd-trending-table tbody tr .zd-last-row,
  body .zd-new-top-downloads-main-table .zd-topdownloads-table tbody tr .zd-last-row {
    width: 1%; }
  body .zd-tertiary-header .zd-second-col,
  body .zd-tertiary-header .zd-third-col {
    display: none; }
  body .zd-table-head .zd-album-column .zd-album-table tbody tr td.zd-pl-78 {
    padding-left: 0; }
  body .zd-table-head .zd-album-column .zd-album-table .zd-album-tbody tr td {
    padding: 0.5rem 1.85rem; }
  body .zd-profile-dropdown .zd-down-arrow {
    display: none; }
  body .zd-nested-dropdown .zd-parent-menu {
    min-width: 220px; }
  body .zd-genre-view-title {
    width: calc(100% - 46px) !important; } }

.mr_12 {
  margin-right: 12px; }

@media only screen and (max-width: 750px) and (min-width: 0px) {
  .vertical-sound {
    display: none !important; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-header-table tbody tr td {
    display: none; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-header-table tbody tr td:last-child,
  body .zd-new-newreleases-table .zd-new-newreleases-page-header-table tbody tr .zd-width-48,
  body .zd-new-newreleases-table .zd-new-newreleases-page-header-table tbody tr .zd-title,
  body .zd-new-newreleases-table .zd-new-newreleases-page-header-table tbody tr .zd-rotate-icons {
    display: table-cell; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-header-table tbody tr .zd-rotate-icons {
    width: 20px; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-header-table tbody tr .zd-title {
    width: 45% !important; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-main-table tbody tr td {
    display: none; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-main-table tbody tr .zd-release-title span.zd-hide {
    display: block !important; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-main-table tbody tr .zd-title,
  body .zd-new-newreleases-table .zd-new-newreleases-page-main-table tbody tr .zd-rotate-icons,
  body .zd-new-newreleases-table .zd-new-newreleases-page-main-table tbody tr .zd-large-album-cell {
    display: table-cell; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-main-table tbody tr td {
    align-items: center; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-main-table tbody tr .zd-rotate-icons {
    width: 10%; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-main-table tbody tr .zd-title {
    width: 70% !important; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-child-table tbody tr td {
    padding: 10px 0.25rem;
    background-color: #242529;
    align-items: center;
    display: none; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-child-table tbody tr td:last-child,
  body .zd-new-newreleases-table .zd-new-newreleases-page-child-table tbody tr .zd-title,
  body .zd-new-newreleases-table .zd-new-newreleases-page-child-table tbody tr .zd-release-icon,
  body .zd-new-newreleases-table .zd-new-newreleases-page-child-table tbody tr .zd-release-crates,
  body .zd-new-newreleases-table .zd-new-newreleases-page-child-table tbody tr .zd-favourite,
  body .zd-new-newreleases-table .zd-new-newreleases-page-child-table tbody tr .zd-icons,
  body .zd-new-newreleases-table .zd-new-newreleases-page-child-table tbody tr .zd-release-play {
    display: table-cell; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-child-table tbody tr td:last-child {
    width: 10%; }
    body .zd-new-newreleases-table .zd-new-newreleases-page-child-table tbody tr td:last-child .zd-tag-btn {
      display: none; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-child-table tbody tr .zd-double-icons a {
    padding-left: 0px !important;
    padding-right: 18px !important; }
  body .zd-new-newreleases-table .zd-new-newreleases-page-child-table tbody tr .zd-title {
    width: 70% !important; }
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-header-table tbody tr td {
    display: none; }
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-header-table tbody tr td:last-child,
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-header-table tbody tr .zd-track-cover-col,
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-header-table tbody tr .zd-title,
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-header-table tbody tr .zd-rotate-icons,
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-header-table tbody tr .zd-small-album-cell,
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-header-table tbody tr .zd-release-crates,
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-header-table tbody tr .zd-favourite,
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-header-table tbody tr .zd-icons,
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-header-table tbody tr .zd-play-col {
    display: table-cell; }
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-header-table tbody tr .zd-rotate-icons {
    width: 20px; }
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-header-table tbody tr .zd-title {
    width: 30% !important; }
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-header-table tbody tr .zd-small-album-cell {
    width: 48px; }
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr,
  body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr {
    height: 48px !important; }
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr td,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr td {
      display: none; }
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr td:last-child,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-topdownloads-count,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-small-album-cell,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-play-col,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-favourite,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-release-crates,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-icons,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-rotate-icons,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-title,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr td:last-child,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-topdownloads-count,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-small-album-cell,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-play-col,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-favourite,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-release-crates,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-icons,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-rotate-icons,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-title {
      display: table-cell; }
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-title,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-title {
      width: 60%; }
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-rotate-icons,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-rotate-icons {
      width: 10%; } }
    @media only screen and (max-width: 750px) and (min-width: 0px) and (max-width: 375px) {
      body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-rotate-icons,
      body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-rotate-icons {
        width: 7px; } }

@media only screen and (max-width: 750px) and (min-width: 0px) {
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-small-album-cell,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-small-album-cell {
      width: 48px; }
      body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-small-album-cell img,
      body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-small-album-cell .zd-release-thumb,
      body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-small-album-cell img,
      body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-small-album-cell .zd-release-thumb {
        max-height: 48px;
        min-height: 48px;
        max-width: 48px;
        min-width: 48px; }
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-title a.zd-hide,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-title a.zd-hide {
      display: block !important; }
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-parent-table tbody tr .zd-title a .track-name,
    body .zd-new-top-downloads-main-table .zd-new-top-downloads-child-table tbody tr .zd-title a .track-name {
      display: none !important; }
  body .zd-new-top-downloads-main-table .zd-trending-table tbody tr .zd-title {
    width: 53%; }
  body .zd-new-top-downloads-main-table .zd-topdownloads-table tbody tr .zd-title {
    width: 45%; }
  body .zd-top-charts-table .zd-new-newreleases-page-header-table tbody tr td {
    display: none; }
  body .zd-top-charts-table .zd-new-newreleases-page-header-table tbody tr td:last-child,
  body .zd-top-charts-table .zd-new-newreleases-page-header-table tbody tr .zd-width-48,
  body .zd-top-charts-table .zd-new-newreleases-page-header-table tbody tr .zd-title,
  body .zd-top-charts-table .zd-new-newreleases-page-header-table tbody tr .zd-large-album-cell,
  body .zd-top-charts-table .zd-new-newreleases-page-header-table tbody tr .zd-play-col,
  body .zd-top-charts-table .zd-new-newreleases-page-header-table tbody tr .zd-icons {
    display: table-cell; }
  body .zd-top-charts-table .zd-new-newreleases-page-header-table tbody tr .zd-float-icons {
    width: 20px; }
  body .zd-top-charts-table .zd-new-newreleases-page-header-table tbody tr .zd-title {
    width: 25% !important; }
  body .zd-top-charts-table .zd-new-newreleases-page-main-table tbody tr td {
    display: none; }
  body .zd-top-charts-table .zd-new-newreleases-page-main-table tbody tr .zd-width-48,
  body .zd-top-charts-table .zd-new-newreleases-page-main-table tbody tr .zd-title,
  body .zd-top-charts-table .zd-new-newreleases-page-main-table tbody tr .zd-large-album-cell,
  body .zd-top-charts-table .zd-new-newreleases-page-main-table tbody tr .zd-play-col,
  body .zd-top-charts-table .zd-new-newreleases-page-main-table tbody tr .zd-icons {
    display: table-cell; }
  body .zd-top-charts-table .zd-new-newreleases-page-main-table tbody tr td {
    align-items: center; }
  body .zd-top-charts-table .zd-new-newreleases-page-main-table tbody tr .zd-title span.zd-hide {
    display: block !important; }
  body .zd-top-charts-table .zd-new-newreleases-page-main-table tbody tr .zd-float-icons {
    width: 10%; }
  body .zd-top-charts-table .zd-new-newreleases-page-main-table tbody tr .zd-title {
    width: 45% !important; } }

@media only screen and (max-width: 750px) and (min-width: 0px) and (max-width: 750px) and (min-width: 375px) {
  body .zd-top-charts-table .zd-new-newreleases-page-child-table tbody .zd-double-icons a {
    padding: 0 0px;
    font-size: 24px; } }

@media only screen and (max-width: 750px) and (min-width: 0px) {
  body .zd-top-charts-table .zd-new-newreleases-page-child-table tbody tr td {
    align-items: center;
    display: none; }
  body .zd-top-charts-table .zd-new-newreleases-page-child-table tbody tr td:last-child,
  body .zd-top-charts-table .zd-new-newreleases-page-child-table tbody tr .zd-title,
  body .zd-top-charts-table .zd-new-newreleases-page-child-table tbody tr .zd-play-col,
  body .zd-top-charts-table .zd-new-newreleases-page-child-table tbody tr .zd-icons,
  body .zd-top-charts-table .zd-new-newreleases-page-child-table tbody tr .zd-crate {
    display: table-cell; }
  body .zd-top-charts-table .zd-new-newreleases-page-child-table tbody tr .zd-title {
    padding-left: 8px !important; }
  body .zd-top-charts-table .zd-new-newreleases-page-child-table tbody tr .zd-title {
    width: 55% !important; }
  body .zd-top-charts-table .zd-new-newreleases-page-child-table tbody tr .zd-album-favourite i {
    margin-top: 4px; } }

@media only screen and (max-width: 750px) and (min-width: 0px) and (max-width: 750px) {
  .top-downloads-page .zd-title {
    width: 38% !important; } }

@media only screen and (max-width: 750px) and (min-width: 0px) {
  .top-downloads-page .zd-icons {
    display: block !important; }
    .top-downloads-page .zd-icons a {
      margin-top: 14px;
      margin-left: -6px; }
  .top-downloads-page .zd-body-row-table tbody tr td {
    height: 48px !important; }
  .top-downloads-page .zd-download-favourtie .zd-album-favourite {
    display: block !important; }
  .zd-row-trendingcharts .zd-download-btn {
    margin-left: 14px !important; }
  .zd_release_view_tab .zd-new-newreleases-page-main-table .zd-album-column .zd-favourite span {
    display: table-cell !important; } }

@media only screen and (min-width: 768px) and (max-width: 1030px) {
  body .zd-profile-dropdown a .zd-username-menu {
    max-height: 40px;
    overflow: hidden; } }

@media only screen and (min-width: 768px) {
  .zd-slide-open .cr-page {
    width: calc(100% - 250px);
    transition: all 0.2s ease-in-out;
    position: relative; } }

/* Mega Menu Style Start */
@media only screen and (min-width: 0) and (max-width: 750px) {
  body .zd-float {
    float: left !important; }
  body .zd-justify-content {
    justify-content: flex-start !important; }
  body .zd-megagenre-parent {
    pointer-events: auto;
    height: 100vh; }
    body .zd-megagenre-parent [class^='col-'] {
      padding: 0;
      margin-bottom: 15px; }
    body .zd-megagenre-parent .zd-megamenu-row {
      max-width: unset; }
    body .zd-megagenre-parent .zd-megagenre-dance {
      padding-right: 10px;
      height: 100%; }
    body .zd-megagenre-parent .zd-megagenre-house .list-group .zd-megagenre-innre-ul {
      max-height: unset;
      width: auto; }
    body .zd-megagenre-parent .country-section,
    body .zd-megagenre-parent .latin-section,
    body .zd-megagenre-parent .urban-section,
    body .zd-megagenre-parent .zd-megagenre-house {
      padding-left: 0; }
  body .zd-search-genre-btn {
    margin-left: 0 !important;
    padding-left: 0 !important;
    width: 32px; }
  body .zd-header-profile-name {
    color: #6b7883;
    display: none; }
  body .zd-hide-mobile {
    display: none !important; }
  body .zd-hide-mobile.d-flex {
    display: none !important; }
  body .zd-show-mobile {
    display: block !important; }
  body .zd-page-title-wrap {
    width: 0 !important; }
  body .cr-header .navbar-nav a.zd-nav-search-btn {
    display: inline-block; }
  body.player-active .zd-megagenre-parent {
    margin-bottom: 56px;
    height: calc(100vh - 50px); }
  body .zd-header-search-wrap {
    display: none !important; }
  body.zd-search-open .zd-header-search-wrap {
    display: block !important;
    z-index: 5;
    box-shadow: 2px -2px 6px 0 rgba(0, 0, 0, 0.42);
    position: absolute;
    margin: 1px 0 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    top: 100%;
    height: 40px;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    right: 10px;
    left: 52px;
    width: calc(100% - 60px) !important; } }
  @media only screen and (min-width: 0) and (max-width: 750px) and (min-width: 0) and (max-width: 750px) {
    body.zd-search-open .zd-header-search-wrap {
      z-index: 1000 !important; } }

@media only screen and (min-width: 0) and (max-width: 750px) {
    body.zd-search-open .zd-header-search-wrap li {
      position: absolute;
      left: 0;
      right: 0; }
    body.zd-search-open .zd-header-search-wrap .zd-search-form {
      width: 100%;
      position: relative; }
      body.zd-search-open .zd-header-search-wrap .zd-search-form .cr-search-form__icon-search {
        left: 61px;
        color: #ddd;
        display: block;
        z-index: 1;
        font-size: 15px;
        top: 7px; } }

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .zd-hide-mobileTab {
    display: none !important; }
  .zd-hide-mobileTab.d-flex {
    display: none !important; } }

@media only screen and (min-width: 0) and (max-width: 576px) {
  body .zd-megagenre-parent .zd-megagenre-dance,
  body .zd-megagenre-parent .zd-megagenre-house {
    border: none; }
  .small-screen-center {
    text-align: center;
    margin-top: 5px; }
  .zd-revert-classic-message {
    display: none !important; }
  .zd-electronic-tab {
    display: none; } }

@media screen and (min-height: 900px) {
  body .zd-megagenre-parent {
    height: auto; } }

@media screen and (min-width: 416px) and (max-width: 768px) {
  .zd-line-tab .nav-item .nav-link {
    padding-left: 8px !important;
    padding-right: 8px !important; } }

/* Mega Menu Style  END */
@media only screen and (max-width: 750px) {
  body .react-jinke-music-player-main .music-player-panel {
    height: 88px !important; } }

.w-11 {
  width: 12%; }

@media only screen and (max-width: 1224px) {
  .playlist-container .zd-dropdown-crate {
    margin-top: 0px !important; }
  body .zd-waves {
    display: block; }
  body .zd-volume-slide-hide {
    display: none !important; }
  body .player-close {
    margin-right: 0 !important; }
  body .react-jinke-music-player-main .music-player-panel {
    height: 68px;
    padding-top: 12px; }
    body .react-jinke-music-player-main .music-player-panel .panel-content {
      position: static;
      max-width: 100vw !important;
      padding-right: 20px;
      justify-content: space-between; }
      body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content .audio-title {
        padding-top: 0 !important; }
      body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content .progress-bar {
        position: static; }
        body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content .progress-bar .player-content .zd-waves {
          position: absolute;
          top: 0;
          left: 0;
          width: 100% !important;
          padding-top: 7px; }
          body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content .progress-bar .player-content .zd-waves #waveform > wave {
            height: 12px !important; }
            body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content .progress-bar .player-content .zd-waves #waveform > wave > wave {
              border-right: none !important;
              position: relative; }
              body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content .progress-bar .player-content .zd-waves #waveform > wave > wave::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0 !important;
                height: 12px;
                width: 12px;
                border-radius: 12px;
                background-color: #4f7be9; }
              body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content .progress-bar .player-content .zd-waves #waveform > wave > wave::before {
                content: '';
                position: absolute;
                top: 4px;
                left: 0 !important;
                height: 4px;
                width: 4px;
                background-color: #4f7be9;
                width: 100%; }
            body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content .progress-bar .player-content .zd-waves #waveform > wave canvas {
              opacity: 0 !important; }
            body .react-jinke-music-player-main .music-player-panel .panel-content .progress-bar-content .progress-bar .player-content .zd-waves #waveform > wave::after {
              content: '';
              position: absolute;
              top: 4px;
              left: 0 !important;
              right: 0 !important;
              height: 4px;
              width: 4px;
              width: 100%;
              background-color: #47484a;
              border-radius: 2px; }
      body .react-jinke-music-player-main .music-player-panel .panel-content .player-content {
        padding-left: 5px; }
        body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .group {
          padding: 0; }
        body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .audio-lists-icon {
          padding: 0 3px; }
        body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .play-btn {
          padding: 0; }
          body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .play-btn svg {
            font-size: 42px; } }

.w-0 {
  width: 0 !important; }

@media only screen and (min-width: 0px) and (max-width: 750px) {
  .playlist-container .zd-dropdown-crate {
    margin-top: 0px !important; }
  body .zd-revert-classic-message {
    font-size: 9px; }
  body .cr-app {
    min-height: unset; }
  body .cr-header {
    height: 48px; }
  body .cr-header > .mr-2.navbar-nav {
    width: 90px;
    margin-right: 4px !important; }
  body .cr-header.navbar-light .navbar-brand {
    width: 85px;
    margin-right: 0 !important; }
    body .cr-header.navbar-light .navbar-brand img {
      width: 85px; }
  body .cr-header .form-control.cr-search-form__input {
    background: #222327;
    height: 64px;
    font-size: 16px;
    width: 78vw; }
    body .cr-header .form-control.cr-search-form__input:focus {
      border-color: #000; }
  body .zd-genre-list-wrap .zd-genre-block {
    width: 100%; }
  body.zd-search-open .cr-header .zd-header-search-wrap {
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    z-index: 7;
    margin: 0;
    width: 100% !important;
    overflow: visible; }
    body.zd-search-open .cr-header .zd-header-search-wrap > li {
      position: relative;
      margin: 0px !important;
      padding: 0px !important; }
      body.zd-search-open .cr-header .zd-header-search-wrap > li .react-autosuggest__container {
        position: relative;
        width: 100%; }
  body.zd-search-open .cr-header .react-autosuggest__suggestions-container--open {
    height: auto;
    position: absolute;
    top: 40px;
    width: 100%;
    left: 0;
    right: 0; }
    body.zd-search-open .cr-header .react-autosuggest__suggestions-container--open .react-autosuggest__section-container {
      position: relative; }
      body.zd-search-open .cr-header .react-autosuggest__suggestions-container--open .react-autosuggest__section-container .react-autosuggest__suggestion {
        position: relative; }
  body .zd-hide-mobile {
    display: none; }
  body .zd-hide-mobile.d-flex {
    display: none !important; }
  body .zd-show-mobile {
    display: block; }
  body .cr-header .navbar-nav a.zd-nav-search-btn {
    display: inline-block; }
  body .zd-profile-dropdown {
    padding-left: 0.5rem; }
    body .zd-profile-dropdown a {
      display: block;
      text-align: center; }
      body .zd-profile-dropdown a .zd-username-menu {
        padding: 0;
        width: 40px;
        padding-top: 7px;
        margin: auto; }
      body .zd-profile-dropdown a .zd-header-profile-name {
        display: none; }
      body .zd-profile-dropdown a:hover {
        color: #dddddd; }
  body .zd-header-search-wrap {
    display: none !important; }
  body.zd-search-open .zd-header-search-wrap {
    display: block !important;
    z-index: 5;
    box-shadow: 2px -2px 6px 0 rgba(0, 0, 0, 0.42);
    position: absolute;
    margin: 1px 0 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    top: 100%;
    height: 50px;
    border-bottom: 1px solid #3c3d41;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    right: 10px;
    left: 52px;
    width: calc(100% - 60px) !important; }
    body.zd-search-open .zd-header-search-wrap li {
      position: absolute;
      left: 0;
      right: 0; }
    body.zd-search-open .zd-header-search-wrap .zd-search-form {
      width: 100%;
      position: relative;
      background-color: #222327 !important;
      margin-top: 15px; } }
    @media only screen and (min-width: 0px) and (max-width: 750px) and (max-width: 750px) {
      body.zd-search-open .zd-header-search-wrap .zd-search-form {
        margin-top: 8px; } }

@media only screen and (min-width: 0px) and (max-width: 750px) {
  body .zd-content-head .zd-secondary-header .nav-item .nav-link {
    padding: 5px 13px; }
  body .zd-profile-page .profile-container .zd-profile-right {
    padding: 0; }
  body .zd-megagenre-parent {
    height: calc(100vh - 56px); } }

@media only screen and (min-width: 414px) and (max-width: 766px) {
  .zd-m-hide {
    display: none; } }

@media only screen and (min-width: 0px) and (max-width: 414px) {
  body .zd-sm-hide {
    display: none; }
  body .zd-album-table tbody .zd-count {
    display: none; }
  body .zd-head-feedback-text {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  body .zd-content-head.zd-fixed-header {
    padding-bottom: 10px; }
  body .zd-new-newreleases-page .zd-fixed-table .zd-fixed {
    display: none; }
  body .zd-new-newreleases-page .zd-fixed-table .zd-zipdj-pack-tbody .zd-favourite {
    padding-top: 0px;
    display: block; }
    body .zd-new-newreleases-page .zd-fixed-table .zd-zipdj-pack-tbody .zd-favourite i {
      margin-top: 3px; }
  body .zd-new-newreleases-page .zd-fixed-table .zd-favourite {
    padding-top: 9px;
    display: block; }
    body .zd-new-newreleases-page .zd-fixed-table .zd-favourite i {
      margin-top: 3px; }
  body .zd-new-newreleases-page .zd-fixed-table .zd-release-icon {
    width: 40px !important; }
  body .zd-table-head .zd-album-column .zd-album-table tbody tr td.zd-pl-88 {
    padding-left: 65px; }
  body .zd-label-search-header .cr-search-form__input,
  body .zd-label-search-header .react-autosuggest__container {
    width: 100%; } }

@media only screen and (min-width: 0px) and (max-width: 414px) and (max-width: 750px) {
  .zd-top-charts-table .zd-favourite {
    padding-left: 0 !important;
    padding-top: 0 !important; }
    .zd-top-charts-table .zd-favourite i {
      margin-top: 0 !important; } }

@media only screen and (min-width: 0px) and (max-width: 414px) {
  .zd-row-topdownload .zd-fixed-table .zd-favourite {
    padding-left: 0 !important; } }

@media only screen and (max-width: 450px) {
  body .zd-body-row-table tbody tr td {
    padding: 0px 0.25rem; } }

.is-secondary-header .zd-release-title {
  padding-left: 1rem !important; }

.zd-float {
  float: right; }

.zd-justify-content {
  justify-content: flex-end; }

.zd-right {
  right: 0 !important; }

@media (min-width: 1480px) {
  .zd-packs_artist {
    padding-left: 26px !important; }
  .zd-packs_genre {
    padding-left: 15px !important; } }

@media (max-width: 750px) {
  body .react-jinke-music-player-main .music-player-panel .panel-content .img-content {
    width: 40px !important;
    height: 40px !important;
    margin-bottom: 7px;
    margin-left: 7px; }
  body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .audio-download,
  body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .audio-keys-btn,
  body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .audio-lists-btn {
    display: none !important; } }

@media (max-width: 350px) {
  body .react-jinke-music-player-main .music-player-panel .panel-content .img-content {
    display: none; }
  body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .zd-manage-track-player {
    margin-right: 2px;
    margin-left: 2px; }
  body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .audio-download,
  body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .audio-keys-btn,
  body .react-jinke-music-player-main .music-player-panel .panel-content .player-content .audio-lists-btn {
    display: none !important; } }

@media (max-width: 750px) {
  .zd-head-margin {
    margin-top: 1.5rem !important; } }

@media (max-width: 750px) {
  .sub-genre-td {
    width: auto !important;
    height: 48px !important;
    padding-top: 2px !important; } }

.slider-icon .slick-prev,
.slider-icon .slick-next {
  display: none !important; }

.zd-newreleases-table .zd-body-row-table tr:hover {
  background-color: #303139 !important; }

.zd-album-column .zd-body-row-table tr:hover {
  background-color: #303139 !important; }

@media (max-width: 750px) {
  .zd-social-icon {
    text-align: center; } }

.zd_over_unset {
  overflow: unset !important; }

@media only screen and (max-width: 750px) and (min-width: 0px) {
  .zd_track_view .zd-dropdown-crate {
    margin-top: 12px !important; }
  .zd-album-favourite i {
    display: block !important; }
  .zd-single-head .zd-tr-color .zd-release-play {
    display: table-cell; }
  .zd-new-newrelease-track-view .zd-tr-color .zd-title .zd-fixed {
    display: table-cell; }
  @-moz-document url-prefix() {
    .zd-new-newrelease-track-view .zd-dropdown-crate {
      margin-top: -11px !important; }
    .zd-single-release-page-view .zd-dropdown-crate {
      margin-top: -11px !important; } }
  .zd-single-release-page-view tbody tr .zd-last-row {
    display: table-cell; }
  .zd-single-release-page-view tbody tr .zd-title-new {
    width: 66% !important; }
  .zd-single-release-page-view tbody tr .zd-dropdown-crate {
    margin-top: 7px; } }

@media only screen and (max-width: 441px) and (min-width: 0px) {
  .zd-single-release-page-view tbody tr .zd-title-new {
    width: 48% !important; } }
  @media only screen and (max-width: 441px) and (min-width: 0px) and (max-width: 750px) {
    .zd-single-release-page-view tbody tr .zd-title-new {
      width: 60% !important; } }

@-moz-document url-prefix() {
  .zd-home-trending-dropdown-box .zd-dropdown-crate {
    margin-top: -12px !important; }
  @media screen and (min-width: 750px) and (max-width: 960px) {
    .zd-track-view .zd-dropdown-crate {
      margin-top: -11px !important; } }
  .zd-topdownload-dropdown-box .zd-dropdown-crate {
    margin-top: -13px; }
  .zd-track-view .zd-dropdown-crate {
    margin-top: -11px; }
  .zd-release-res .zd-dropdown-crate {
    margin-top: 4px; } }

.zd-nowrap {
  white-space: nowrap !important; }

@media (max-width: 750px) {
  .zd-fav-crate {
    padding-top: 4px !important; }
  .zd-last-row {
    width: 10px !important; }
  .zd-favourite {
    display: table-cell !important; }
  .zd-release-crates {
    width: 10px; }
  .zd-title {
    width: 43px; }
  .zd-release-crates .crate-dropdown-menu {
    -webkit-transform: translate3d(-137px, 7px, 0px) !important;
            transform: translate3d(-137px, 7px, 0px) !important; } }

@media (max-width: 500px) {
  .zd-singlepack-tbody .zd-title {
    width: 60% !important; }
  .zd-title {
    width: 50% !important; } }

@media (max-width: 414px) {
  .top-downloads-page .zd-title {
    width: 96px !important; } }

@media (max-width: 960px) and (min-width: 750px) {
  .homemain .zd-topdownload-dropdown-box .zd-dropdown-crate {
    margin-top: 19px; }
  .homemain .zd-home-trending-dropdown-box .zd-dropdown-crate {
    margin-top: 12px; }
  .zd-release-crates .crate-dropdown-menu {
    -webkit-transform: translate3d(-141px, 7px, 0px) !important;
            transform: translate3d(-141px, 7px, 0px) !important; }
  .zd-topdownload-dropdown-box .zd-dropdown-crate {
    margin-top: 12px; }
  .zd-row-topdownload .zd-last-row {
    width: 10px !important; } }

.zd-eleps {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left; }

@media (max-width: 560px) {
  .crate-item-main-container {
    display: block; }
    .crate-item-main-container #zd_release_view {
      margin-top: 20px; }
    .crate-item-main-container .zd-favourite-dropdown-box .zd-dropdown-crate {
      margin-top: 13px; } }

@media (max-width: 750px) and (min-width: 0px) {
  .zd-crete-feedback .zd-desktop {
    display: none !important; } }

@media (max-width: 1366px) {
  .zd-crete-feedback .zd-desktop {
    display: none !important; }
  .zd-crete-feedback .zd-tablet {
    display: none !important; }
  .zd-crete-feedback .zd-mobile {
    display: block !important; } }

@media (min-width: 1366px) {
  .zd-crete-feedback .zd-desktop {
    display: block !important; }
  .zd-crete-feedback .zd-tablet {
    display: none !important; } }

@media (max-width: 1366px) {
  .zd-wave_loader .zd-wave-loader {
    top: 33%;
    left: 70%; } }

@media (max-width: 750px) {
  .zd-wave_loader .zd-wave-loader {
    top: 33%;
    left: 50%; } }

@media (max-width: 1225px) {
  .zd-dmw-table .zd-title {
    width: 33% !important; }
  .zd-dmw-table .zd-genre {
    width: 33%; }
  .zd-dmw-table .zd-time {
    width: 8%; } }

@media (max-width: 750px) {
  .zd-dmw-table .zd-title {
    width: 52% !important; } }

.zd-icon-delete-2 {
  font-size: 16px; }

.zd_category_search {
  display: flex;
  justify-content: space-between;
  margin-right: 51px; }
  @media (max-width: 750px) {
    .zd_category_search {
      margin-right: 0; } }

.zd_v5_cursor_hide span a {
  cursor: inherit !important; }

.card-img-left {
  border-radius: 0px !important; }

.card-img-right {
  border-radius: 0px !important; }

/* LOADER */
svg.zd-wave-loader {
  height: 40px;
  width: 40px;
  fill: #dddddd; }
  svg.zd-wave-loader path {
    fill: #dddddd; }
    svg.zd-wave-loader path.zd-wave-loader-l1 {
      -webkit-animation: pulse 1s infinite;
      animation: pulse 1s infinite;
      -webkit-animation-delay: 0.15s;
      animation-delay: 0.15s; }
    svg.zd-wave-loader path.zd-wave-loader-l2 {
      -webkit-animation: pulse 1s infinite;
      animation: pulse 1s infinite;
      -webkit-animation-delay: 0.3s;
      animation-delay: 0.3s; }
    svg.zd-wave-loader path.zd-wave-loader-l3 {
      -webkit-animation: pulse 1s infinite;
      animation: pulse 1s infinite;
      -webkit-animation-delay: 0.45s;
      animation-delay: 0.45s; }
    svg.zd-wave-loader path.zd-wave-loader-l4 {
      -webkit-animation: pulse 1s infinite;
      animation: pulse 1s infinite;
      -webkit-animation-delay: 0.6s;
      animation-delay: 0.6s; }
    svg.zd-wave-loader path.zd-wave-loader-l5 {
      -webkit-animation: pulse 1s infinite;
      animation: pulse 1s infinite;
      -webkit-animation-delay: 0.75s;
      animation-delay: 0.75s; }
    svg.zd-wave-loader path.zd-wave-loader-l6 {
      -webkit-animation: pulse 1s infinite;
      animation: pulse 1s infinite;
      -webkit-animation-delay: 0.9s;
      animation-delay: 0.9s; }
    svg.zd-wave-loader path.zd-wave-loader-l7 {
      -webkit-animation: pulse 1s infinite;
      animation: pulse 1s infinite;
      -webkit-animation-delay: 1.05s;
      animation-delay: 1.05s; }
    svg.zd-wave-loader path.zd-wave-loader-l8 {
      -webkit-animation: pulse 1s infinite;
      animation: pulse 1s infinite;
      -webkit-animation-delay: 1.2s;
      animation-delay: 1.2s; }
    svg.zd-wave-loader path.zd-wave-loader-l9 {
      -webkit-animation: pulse 1s infinite;
      animation: pulse 1s infinite;
      -webkit-animation-delay: 1.35s;
      animation-delay: 1.35s; }

/* LOADER */
@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
            transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
            transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
            transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
            transform: rotate3d(0, 0, 1, -5deg); }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
            transform: rotate3d(0, 0, 1, 0deg); } }
@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
            transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
            transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
            transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
            transform: rotate3d(0, 0, 1, -5deg); }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
            transform: rotate3d(0, 0, 1, 0deg); } }

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%; }
  50% {
    -webkit-transform: scaleY(0.7);
    transform: scaleY(0.7);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%; }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%; } }

@keyframes pulse {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%; }
  50% {
    -webkit-transform: scaleY(0.7);
    transform: scaleY(0.7);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%; }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%; } }

.swing {
  -webkit-transform-origin: top center;
          transform-origin: top center;
  -webkit-animation-name: swing;
          animation-name: swing; }

.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.rubberBand {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1; }

@-webkit-keyframes rubberBand {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  30% {
    -webkit-transform: scaleX(1.25) scaleY(0.75);
            transform: scaleX(1.25) scaleY(0.75); }
  40% {
    -webkit-transform: scaleX(0.75) scaleY(1.25);
            transform: scaleX(0.75) scaleY(1.25); }
  60% {
    -webkit-transform: scaleX(1.15) scaleY(0.85);
            transform: scaleX(1.15) scaleY(0.85); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes rubberBand {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  30% {
    -webkit-transform: scaleX(1.25) scaleY(0.75);
            transform: scaleX(1.25) scaleY(0.75); }
  40% {
    -webkit-transform: scaleX(0.75) scaleY(1.25);
            transform: scaleX(0.75) scaleY(1.25); }
  60% {
    -webkit-transform: scaleX(1.15) scaleY(0.85);
            transform: scaleX(1.15) scaleY(0.85); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

.can-click {
  cursor: pointer; }

.overflow-hidden {
  overflow: hidden; }

@media (min-width: 1200px) {
  .hidden-xl {
    display: none; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .hidden-lg {
    display: none; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .hidden-md {
    display: none; } }

@media (min-width: 576px) and (max-width: 767.98px) {
  .hidden-sm {
    display: none; } }

@media (max-width: 575.98px) {
  .hidden-xs {
    display: none; } }

.modal-backdrop-light {
  background: #999;
  opacity: 1; }
  .modal-backdrop-light.modal-backdrop.show {
    opacity: 1; }

.cr-header {
  border-bottom: 1px solid #242529;
  margin-bottom: 0;
  position: fixed;
  top: 0;
  left: 54px;
  right: 0px;
  padding: 11.5px 20px;
  margin-left: 0;
  z-index: 999;
  background-color: #191b1f !important; }
  @media (max-width: 750px) {
    .cr-header {
      height: 48px;
      left: 0;
      padding: 0px 0px;
      background-color: #191b1f !important; } }
  .cr-header__nav-right {
    flex-direction: row;
    justify-content: flex-end; }
  .cr-header .zd-page-title-wrap {
    width: 160px; }
    .cr-header .zd-page-title-wrap h1 {
      margin-top: 10px;
      font-size: 11.5px;
      font-weight: 600;
      letter-spacing: 0.03em;
      text-transform: uppercase; }
  .cr-header.navbar-light .navbar-brand {
    width: 130px; }
  .cr-header.navbar-light .navbar-brand img {
    width: 120px; }
  .cr-header .cr-search-form__input {
    background-color: #1d1b21;
    color: #dddddd; }
    @media (max-width: 750px) {
      .cr-header .cr-search-form__input {
        width: calc(100% - 112px) !important;
        display: block;
        margin-left: 52px; } }
    @media (max-width: 350px) {
      .cr-header .cr-search-form__input {
        margin-left: 10vw; } }
  .cr-header .cr-search-form__icon-search {
    color: #6b7883 !important;
    font-size: 14px;
    padding: 10px 0;
    cursor: pointer; }
    @media (max-width: 750px) {
      .cr-header .cr-search-form__icon-search {
        padding: 0 !important;
        margin-left: 2px; } }
  .cr-header .searchbar-focused .cr-search-form__icon-search {
    color: #dddddd !important; }
  .cr-header .navbar-nav {
    align-items: center; }
    @media (max-width: 750px) {
      .cr-header .navbar-nav a.zd-v5-mob-search {
        border: 1px solid #32333a !important;
        border: 1px solid var(--Secondary-Border, #32333a) !important;
        border-radius: 4px;
        background: #25262a;
        height: 36px;
        width: 36px;
        display: flex !important;
        padding-left: 6px !important; } }
    .cr-header .navbar-nav a.zd-nav-search-btn {
      color: #ced4da;
      font-size: 24px;
      margin-top: -2px;
      cursor: pointer;
      display: none; }
      .cr-header .navbar-nav a.zd-nav-search-btn:hover {
        color: #dddddd !important; }
      @media (max-width: 750px) {
        .cr-header .navbar-nav a.zd-nav-search-btn {
          margin-top: 0px; }
          .cr-header .navbar-nav a.zd-nav-search-btn svg {
            fill: #6b7883; } }

body .zd-header-search-wrap {
  position: relative;
  top: 0;
  z-index: 99;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  background-color: #2a3143;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-radius: 5px; }
  @media (max-width: 750px) {
    body .zd-header-search-wrap {
      margin-left: 0 !important;
      width: 100%;
      background: #222327;
      box-shadow: none !important; } }

.release-head-page .zd-tertiary-header {
  margin-top: 12px;
  margin-bottom: 0; }
  @media (max-width: 750px) {
    .release-head-page .zd-tertiary-header .list-group {
      display: none !important; } }

.release-head-page #zd-lg-filter {
  margin-top: -8px; }

.release-head-page .zd-breadcrumb {
  margin-top: 10px; }
  @media (max-width: 750px) {
    .release-head-page .zd-breadcrumb {
      display: none; } }

@media (max-width: 450px) {
  .zd_v5_w {
    max-width: 80%; } }

.text-nowarp {
  white-space: nowrap; }

.top-downloads-page .zd-home-breadcrumb {
  margin-top: 7px; }

.top-downloads-page .zd-tertiary-header {
  margin-top: 12px;
  margin-bottom: 0; }
  @media (max-width: 750px) {
    .top-downloads-page .zd-tertiary-header .list-group .dropdown_key {
      display: none !important; } }
  @media (max-width: 750px) {
    .top-downloads-page .zd-tertiary-header .list-group .zd-tag-reset {
      display: none !important; } }

.trending-breadcrumb .zd-tertiary-header {
  margin-top: 8px;
  margin-bottom: 0; }

body .zd-tertiary-text {
  color: #dddddd !important; }

.zd-content-head {
  background: #191b1f; }
  .zd-content-head.zd-fixed-header {
    position: fixed;
    top: 64px;
    z-index: 2;
    left: 76px;
    right: 20px;
    padding-bottom: 2px; }
    .zd-content-head.zd-fixed-header.is-small-header {
      padding-bottom: 20px; }
  .zd-content-head .zd-secondary-header .nav-item {
    margin: 0; }
    .zd-content-head .zd-secondary-header .nav-item .nav-link {
      padding: 5px 13px;
      background: #28292e;
      color: #6d7882;
      font-size: 12px;
      font-style: normal;
      font-weight: 400; }
      .zd-content-head .zd-secondary-header .nav-item .nav-link:hover {
        color: #ddd;
        background-color: #303139;
        border-bottom: 1px solid #ddd; }
      .zd-content-head .zd-secondary-header .nav-item .nav-link.active {
        background: #4f7be9; }
  .zd-content-head .zd-tertiary-header {
    background: #25262a; }

.allpacks-search-bar {
  margin-bottom: 0px; }
  @media (max-width: 750px) {
    .allpacks-search-bar {
      display: none; } }

.zd-search-allpacks .favourite-search-box {
  width: 200px !important; }

.zd-filter-category-btn {
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center; }
  .zd-filter-category-btn .btn {
    flex: 1 1;
    margin: 0px 2px;
    text-align: center;
    max-width: 70px;
    background: #27282d;
    color: #eaeaea !important;
    border-radius: 0px !important;
    border-color: #27282d !important;
    padding-bottom: 0.5rem !important;
    font-size: 11px;
    line-height: 11px;
    letter-spacing: 0.05em;
    -webkit-font-smoothing: auto; }
    .zd-filter-category-btn .btn.active {
      background-color: #1b1c1e !important;
      border-color: transparent !important;
      border-bottom-color: #4d7ce8 !important;
      border-bottom-width: 2px !important; }
    .zd-filter-category-btn .btn.zd-btn-action {
      flex: none;
      padding: 3px 0 4px;
      width: 24px;
      background: #5764c6;
      border-color: #27282d !important;
      color: #dddddd; }
    .zd-filter-category-btn .btn.disabled {
      visibility: hidden; }
    .zd-filter-category-btn .btn:first-child {
      margin-left: 0; }
    .zd-filter-category-btn .btn:last-child {
      margin-right: 0; }
    .zd-filter-category-btn .btn:active {
      outline: none; }
    .zd-filter-category-btn .btn:focus {
      outline: none !important;
      box-shadow: none !important; }
    .zd-filter-category-btn .btn:hover {
      background: #5764c6;
      border-color: #4452c0;
      color: #dddddd; }

.zd-trend-tab-btn {
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  height: 28px; }
  @media (max-width: 440px) {
    .zd-trend-tab-btn {
      margin-top: 30px; } }
  .zd-trend-tab-btn .btn {
    flex: 1 1;
    margin: 2px 6px;
    text-align: center;
    background: #27282d;
    color: #dddddd !important;
    border-radius: 2px !important;
    border-color: transparent !important;
    font-size: 12px;
    line-height: 0;
    font-weight: 500;
    height: 28px;
    -webkit-font-smoothing: auto; }
    @media (max-width: 440px) {
      .zd-trend-tab-btn .btn {
        margin: 2px 2px;
        font-size: 10px;
        padding: 0.2rem 0.5rem; } }
    .zd-trend-tab-btn .btn.active {
      background-color: #4f7be9 !important;
      border-color: transparent !important;
      border-radius: 2px !important; }
    .zd-trend-tab-btn .btn.zd-btn-action {
      flex: none;
      width: 24px;
      background: #5764c6;
      border-color: #27282d !important;
      color: #dddddd; }
    .zd-trend-tab-btn .btn.disabled {
      visibility: hidden; }
    .zd-trend-tab-btn .btn:first-child {
      margin-left: 0; }
    .zd-trend-tab-btn .btn:last-child {
      margin-right: 0; }
    .zd-trend-tab-btn .btn:active {
      outline: none; }
    .zd-trend-tab-btn .btn:focus {
      outline: none !important;
      box-shadow: none !important; }
    .zd-trend-tab-btn .btn:hover {
      background: #4f7be9; }

.zd-reset-btn .btn {
  flex: 1 1;
  padding: 3px 0 4px;
  margin: 0px 4px;
  text-align: center;
  max-width: 70px;
  background: #27282d;
  color: #7685b3;
  border-radius: 0.2rem;
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 0.05em;
  -webkit-font-smoothing: auto; }
  .zd-reset-btn .btn.active {
    background-color: #4d7ce8 !important; }
  .zd-reset-btn .btn.zd-btn-action {
    flex: none;
    width: 24px;
    background: #5764c6;
    border-color: #4452c0;
    color: #dddddd; }
  .zd-reset-btn .btn.disabled {
    visibility: hidden; }
  .zd-reset-btn .btn:first-child {
    margin-left: 0; }
  .zd-reset-btn .btn:last-child {
    margin-right: 0; }
  .zd-reset-btn .btn:hover {
    background: #5764c6;
    border-color: #4452c0;
    color: #dddddd; }

.zd-filter-dates .dropdown {
  border: 0;
  border-radius: 3px;
  position: relative; }

.zd-filter-dates #zd-lg-filter {
  background-color: #25262a; }
  .zd-filter-dates #zd-lg-filter .zdv3-icon-genre-filter {
    display: none !important; }
  .zd-filter-dates #zd-lg-filter .zd-text-ellipsis {
    margin-left: 6px; }
  .zd-filter-dates #zd-lg-filter .zdv3-icon-drop-down {
    padding-right: 4px;
    padding-left: 8px; }
    .zd-filter-dates #zd-lg-filter .zdv3-icon-drop-down:before {
      content: '\EA19'; }

.zd-default-list li {
  padding: 0;
  border: none;
  background-color: transparent !important; }

.zd-notification-popover {
  box-shadow: rgba(0, 0, 0, 0.5) -2px 2px 8px 0; }

.zd-profile-popover {
  border-radius: 4px; }
  .zd-profile-popover .card-body #zd-popover-body-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #dddddd; }
  .zd-profile-popover .card-body #zd-popover-body-subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #dddddd; }
  .zd-profile-popover .arrow {
    display: none; }
  .zd-profile-popover .list-group {
    font-size: 14px; }
    .zd-profile-popover .list-group .list-group-item {
      padding: 0;
      border: 0;
      background: #292a2f;
      border: 1px solid #444; }
      .zd-profile-popover .list-group .list-group-item .nav-link {
        padding: 11px 23px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #6b7883; }
        .zd-profile-popover .list-group .list-group-item .nav-link:hover {
          color: #dddddd;
          background-color: #303139; }
  .zd-profile-popover .card-body {
    padding: 0.8rem 23px; }

.zd-profile-dropdown a .zd-username-menu {
  display: flex;
  color: #abb1b8; }
  .zd-profile-dropdown a .zd-username-menu img.rounded-circle {
    margin-right: 5px; }

.zd-profile-dropdown a .zd-down-arrow:after {
  content: 'R';
  font-family: 'zipdj' !important;
  font-style: normal !important;
  font-weight: normal !important;
  -webkit-font-feature-settings: normal !important;
          font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  padding-left: 7px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #e6e6e6; }

.zd-profile-dropdown a:hover .zd-username-menu {
  color: #dddddd; }

@media (max-width: 750px) {
  .zd-profile-dropdown {
    display: none; } }

.zd-download-manager-btn {
  font-size: 22px;
  color: #dddddd;
  position: relative;
  cursor: pointer; }
  .zd-download-manager-btn a:hover {
    color: #dddddd !important; }
    .zd-download-manager-btn a:hover .rubberBand {
      -webkit-animation-name: rubberBand;
      animation-name: rubberBand; }
  .zd-download-manager-btn.zd-animate a .rubberBand {
    -webkit-animation-name: rubberBand;
    animation-name: rubberBand; }
  .zd-download-manager-btn .badge {
    left: 3px;
    color: #dddddd;
    top: 0px;
    background: #4d7ce8;
    padding: 2px 4px;
    border-radius: 2px;
    font-size: 12px; }
  .zd-download-manager-btn .zd_v5_spinner_wrapper {
    background-color: #4d7ce8;
    width: 17px;
    height: 17px;
    position: absolute;
    top: 18%;
    left: 32%;
    margin-top: -8px;
    margin-left: -8px;
    border-radius: 2px; }
  .zd-download-manager-btn .zd-header-download-size {
    padding-top: 0 !important;
    font-size: 12px;
    left: 0px;
    white-space: nowrap;
    text-align: center;
    color: #6b7883;
    font-weight: 400;
    font-family: 'Poppins', 'Roboto', sans-serif; }
    .zd-download-manager-btn .zd-header-download-size:hover {
      color: #dddddd; }
      .zd-download-manager-btn .zd-header-download-size:hover svg path {
        fill: #dddddd !important; }

@media (max-width: 750px) {
  .cr-sidebar {
    top: 50px !important; } }

.cr-sidebar .zd-download-manager-btn .badge {
  left: 21px;
  right: auto;
  top: 2px;
  z-index: 33; }

body.zd-slide-open .zd-tertiary-header .zd-second-col {
  flex: 3 0 25.33333%;
  max-width: 25.33333%; }

body.zd-slide-open .zd-tertiary-header .zd-third-col {
  flex: 3 0 18.33333%; }

body .zd-parent-menu {
  padding: 10px;
  max-height: unset;
  height: auto;
  overflow-x: hidden;
  overflow-y: hidden;
  min-width: 320px;
  font-size: 11px;
  text-transform: unset;
  line-height: 20px; }

body .zd-parent-menu.show .zd-submenu {
  position: relative !important;
  -webkit-transform: unset !important;
          transform: unset !important;
  font-size: 11px;
  color: #adadad;
  padding-top: 0; }

.zd-parent-menu .text-uppercase .zd-submenu,
.zd-parent-menu .zd-submenu .text-uppercase .zd-submenu {
  text-transform: capitalize; }

body .zd-parent-menu .zd-submenu {
  padding-left: 20px;
  width: 100%; }

body .zd-home-breadcrumb {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  padding-top: 0;
  cursor: default; }
  body .zd-home-breadcrumb .zd-refresh-breadcrumb:hover {
    color: #acbdf3; }

body .zd-breadcrumb-title {
  color: #acbdf3; }

.zd-parent-menu .zd-submenu:before {
  content: '';
  position: absolute;
  left: 2px;
  top: 0;
  height: 100%;
  width: 2px;
  background: #424242; }

.zd-tab-view-action a {
  color: #adadad;
  border-bottom: 2px solid transparent;
  padding-bottom: 4px;
  display: inline-block;
  font-size: 11px !important; }
  .zd-tab-view-action a.active, .zd-tab-view-action a:hover {
    padding-top: 6px;
    text-decoration: none;
    color: #dddddd;
    border-bottom: 2px solid #4d7ce8; }

#zd-release-tab-view {
  background: #191b1f; }
  #zd-release-tab-view i {
    padding: 3px 6px;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    border: 1px solid #32333a;
    border: 1px solid var(--Secondary-Border, #32333a); }

#zd-release-tab-view-track {
  background: #191b1f; }
  #zd-release-tab-view-track i {
    padding: 3px 6px;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    border: 1px solid #32333a;
    border: 1px solid var(--Secondary-Border, #32333a); }

#zd-track-tab-view.active i {
  background: #242529; }

#zd-release-tab-view.active i {
  background: #242529; }

.dropdown button {
  border-radius: 4px;
  border: 1px solid #32333a;
  border: 1px solid var(--Secondary-Border, #32333a); }

.zd-v5-filter-reset {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4d7ce8;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline; }
  .zd-v5-filter-reset:hover {
    color: #2b59c2; }
  @media (max-width: 750px) {
    .zd-v5-filter-reset {
      order: 4;
      margin-left: 0.5rem; } }

@media screen and (max-width: 750px) {
  .zd-reset-after {
    display: flex !important;
    margin-left: 4px; } }

#zd-track-tab-view {
  background: #191b1f; }
  #zd-track-tab-view i {
    padding: 3px 6px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid #32333a;
    border: 1px solid var(--Secondary-Border, #32333a); }

.zd-newrelease-tab-view-action {
  float: right;
  height: 36px; }
  .zd-newrelease-tab-view-action #zd-release-tab-view {
    margin-left: 10px; }
  .zd-newrelease-tab-view-action a {
    color: #6d7882 !important;
    display: inline-block;
    font-size: 24px !important; }
    .zd-newrelease-tab-view-action a.active, .zd-newrelease-tab-view-action a:hover {
      text-decoration: none;
      color: #4f7be9 !important; }

.zd-electronic-tab a {
  color: #909090 !important;
  border-bottom: 3px solid transparent;
  display: inline-block; }
  .zd-electronic-tab a.electronic-tab {
    margin-right: 85px !important; }
  .zd-electronic-tab a.airplay-tab {
    margin-right: 85px !important; }
  .zd-electronic-tab a.active, .zd-electronic-tab a:hover {
    text-decoration: none;
    color: #dddddd;
    border-bottom: 3px solid #4d7ce8; }

body .zd-content-head .zd-secondary-search-header .nav-item {
  background: none; }

body .zd-content-head .zd-secondary-search-header .zd-primary-form {
  padding: 9px 20px; }
  body .zd-content-head .zd-secondary-search-header .zd-primary-form .zd-primary-search {
    width: 250px;
    background: #2d2e33;
    box-shadow: none;
    border: 0;
    border-radius: 0 !important; }
  body .zd-content-head .zd-secondary-search-header .zd-primary-form .input-group-btn {
    border-radius: 0 5px 5px 0;
    margin-left: -0.1rem; }

.zd-header-profile-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 2px; }

.zd-header-profile-icon {
  padding-top: 1px; }

.zd-header-notification-icon {
  padding: 2px 15px 0; }

.zd-header-download-size {
  padding-bottom: 2px; }

.download-size {
  position: absolute;
  top: 34px;
  right: 2px;
  font-size: 9px; }

.zd-download-manager {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 12px; }

.zd-header-link {
  font-size: 12px;
  color: #6b7883 !important;
  font-weight: 500;
  text-decoration: none !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding: 0 !important;
  margin: 0 10px;
  position: relative; }
  @media (min-width: 1300px) {
    .zd-header-link {
      margin: 0 12px; }
      .zd-header-link :first-child {
        margin-left: 12px !important; }
      .zd-header-link:last-child {
        margin-left: 26px; }
      .zd-header-link:last-child::before {
        margin-left: 0 !important; } }
  .zd-header-link.zd-header-link:hover, .zd-header-link.zd-header-link:focus {
    color: #4f7be9 !important; }
  .zd-header-link.active {
    color: #4f7be9 !important; }
    .zd-header-link.active::before {
      content: '';
      position: absolute;
      top: -23px;
      left: 0;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      height: 4px;
      width: 100%;
      background-color: #4f7be9; }
  .zd-header-link.current-active {
    color: #4f7be9 !important; }
    .zd-header-link.current-active::before {
      content: '';
      position: absolute;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      top: -23px;
      left: 0;
      height: 4px;
      width: 100%;
      background-color: #4f7be9;
      margin-left: 6px; }

@media (max-width: 1224px) {
  .zd-middle-nav-items {
    display: none; } }

.zd-mega-dropdown {
  background-color: #2a3143;
  color: #6e7da5 !important;
  padding: 12px 0px 12px 8px;
  font-weight: 500;
  font-size: 12px; }
  .zd-mega-dropdown span.no-filter-applied {
    max-width: 75px;
    min-width: 44px; }
    @media (max-width: 1224px) {
      .zd-mega-dropdown span.no-filter-applied {
        max-width: 75px;
        min-width: 52px; } }
  @media (max-width: 1224px) {
    .zd-mega-dropdown .zdv3-icon-up-arrow,
    .zd-mega-dropdown .zdv3-icon-drop-down {
      padding-left: 19px; } }
  .zd-mega-dropdown span.filter-applied {
    max-width: 75px;
    min-width: 44px; }
    @media (max-width: 1224px) {
      .zd-mega-dropdown span.filter-applied {
        max-width: 75px;
        min-width: 52px; } }
  .zd-mega-dropdown:hover {
    color: #ddd !important; }
    .zd-mega-dropdown:hover .filter-applied {
      color: #ddd !important; }
  @media (max-width: 1224px) {
    .zd-mega-dropdown {
      background: #2a3043;
      color: #7685b3; }
      .zd-mega-dropdown:hover {
        color: #5d6b91;
        background: rgba(79, 123, 233, 0.22); } }
  @media (max-width: 750px) {
    .zd-mega-dropdown {
      background: #202225; }
      .zd-mega-dropdown path {
        fill: #6b7883; }
      .zd-mega-dropdown:hover {
        color: #8997be;
        background: #202225; } }

.nav-link.zd-header-link.active {
  background: transparent !important;
  color: white; }

.zd-nav-brand {
  position: fixed;
  left: 24px;
  top: 22px;
  z-index: 1010; }
  @media (max-width: 750px) {
    .zd-nav-brand {
      position: fixed;
      top: 16px;
      left: auto;
      margin-left: 25%;
      margin-right: 23px;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); } }

@media (max-width: 750px) {
  .zd-search-open .zd-nav-brand {
    z-index: 900; } }

@media (max-width: 750px) {
  .cr-header .zd-download-manager-btn {
    display: none !important; } }

#zd-header-profile-image {
  height: 32px !important;
  width: 32px !important; }

#search-genres {
  background: transparent;
  height: 36px; }
  @media (max-width: 1300px) {
    #search-genres {
      margin-left: auto !important; } }

@media (min-width: 1224px) {
  .zd-filter-middle {
    margin-left: 0px !important; } }

#Popover2 {
  padding-right: 0px !important;
  padding-top: 0px;
  padding-bottom: 0px; }
  #Popover2 .zdv3-icon-profile {
    font-size: 27px; }
  #Popover2:hover #zd-header-user-name {
    color: #dddddd; }
  #Popover2 .zd-username-menu.active {
    color: #4f7be9; }
    #Popover2 .zd-username-menu.active:hover {
      color: #4471e0 !important; }

@media (min-width: 1224px) {
  .zd-header-link:first-child {
    margin-left: 144px; } }

.zdv3-icon-drop-down,
.zdv3-icon-up-arrow {
  font-size: 10px; }

.dropdown_key {
  height: 32px;
  width: auto !important;
  float: right;
  border-radius: 2px !important;
  padding: 4px;
  background-color: #25262a; }
  .dropdown_key .drop_icon {
    margin-left: 4px; }

.top-downloads-filter-btn {
  height: 32px;
  max-width: 186px;
  float: right;
  border-radius: 4px !important; }

@media (max-width: 750px) {
  .cr-header__nav-right {
    margin-left: 0 !important; } }

.zd-new-header-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #dddddd; }
  @media (max-width: 440px) {
    .zd-new-header-title {
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      white-space: nowrap; } }

.zd-search-genre-btn .zd-primary-dropdown-disabled {
  background-color: #2a3143;
  color: #6e7da5 !important;
  padding: 12px 0px 12px 8px;
  font-weight: 500;
  font-size: 12px; }
  .zd-search-genre-btn .zd-primary-dropdown-disabled span.no-filter-applied {
    max-width: 75px;
    min-width: 43px;
    line-height: 18px; }
  @media (max-width: 1220px) {
    .zd-search-genre-btn .zd-primary-dropdown-disabled {
      padding: 6px 7px;
      width: 150px; } }
  @media (max-width: 1220px) {
    .zd-search-genre-btn .zd-primary-dropdown-disabled .zdv3-icon-drop-down {
      padding-left: 15px; } }

.zd-slide-open .zd-header-download-size svg path {
  fill: #4f7be9 !important; }

.zd-slide-open .zd-header-download-size:hover svg path {
  fill: #2d5dc8 !important; }

.zd-mega-dropdown.applied-filter-button {
  background-color: #4f7be9 !important;
  color: #ddd !important; }
  .zd-mega-dropdown.applied-filter-button:hover {
    color: #ddd !important;
    background-color: #2d5dcb !important; }

.zd-search-back-new {
  display: flex;
  justify-content: space-between; }
  .zd-search-back-new .cr-search-form {
    height: 31px; }
  .zd-search-back-new .combobox {
    height: 40px; }

.zd-v5-secondary-header .nav-item .nav-link {
  background: transparent !important;
  padding: 7px 12px !important; }

.zd-v5-secondary-header .nav-link.active {
  color: #4f7be9 !important;
  padding: 7px 12px !important;
  border-bottom: 1px solid #4f7be9; }

.zd-content-head {
  border-bottom: 1px solid #242529; }
  .zd-content-head .favourite-search-bar-form {
    margin-bottom: 0px !important; }

.zd-v5-search-mobile-close {
  display: none; }
  @media (max-width: 750px) {
    .zd-v5-search-mobile-close {
      font-weight: 500;
      display: block;
      position: fixed;
      right: 0;
      top: 6px;
      padding: 10px;
      color: #dddddd;
      font-size: 14px;
      cursor: pointer;
      background-color: transparent;
      outline: none;
      border: none; }
      .zd-v5-search-mobile-close:hover {
        color: #dddddd; } }

span.zd-v5_spinner {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid transparent;
  border-top-color: #fff;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  margin-top: 4px; }
  span.zd-v5_spinner.v5_mr {
    margin-right: 8px;
    width: 16px;
    height: 16px; }
    @media (max-width: 950px) {
      span.zd-v5_spinner.v5_mr {
        margin-right: 26px; } }
    @media (max-width: 750px) {
      span.zd-v5_spinner.v5_mr {
        margin-right: 20px; } }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.cr-sidebar {
  position: fixed;
  top: 64px;
  bottom: 0;
  left: 0;
  z-index: 1004;
  width: 56px;
  font-weight: 200;
  color: #dddddd;
  background-position: center center;
  background-size: cover;
  background: #191b1f;
  border-right: 1px solid #242529;
  transition: width 0.1s ease-in;
  overflow: visible; }
  .cr-sidebar .zd-hamburger-menu-parent {
    display: block;
    text-align: center; }
  .cr-sidebar .zd-hamburger-menu {
    margin-left: 2px;
    margin-top: 5px;
    border: none;
    outline: none;
    box-shadow: none;
    border-radius: 0;
    background: none !important; }
    .cr-sidebar .zd-hamburger-menu:active, .cr-sidebar .zd-hamburger-menu:focus, .cr-sidebar .zd-hamburger-menu:hover, .cr-sidebar .zd-hamburger-menu:not(:disabled):not(.disabled):active:focus {
      border: none;
      outline: none;
      background: none !important;
      color: #dddddd !important;
      box-shadow: none; }
      .cr-sidebar .zd-hamburger-menu:active .icon-ico, .cr-sidebar .zd-hamburger-menu:focus .icon-ico, .cr-sidebar .zd-hamburger-menu:hover .icon-ico, .cr-sidebar .zd-hamburger-menu:not(:disabled):not(.disabled):active:focus .icon-ico {
        color: #3b49af; }
    .cr-sidebar .zd-hamburger-menu:active .icon-ico, .cr-sidebar .zd-hamburger-menu:focus .icon-ico {
      color: #4d7ce8; }
    @media (min-width: 751px) {
      .cr-sidebar .zd-hamburger-menu.mobile {
        display: none; } }
    @media (max-width: 750px) {
      .cr-sidebar .zd-hamburger-menu {
        margin-left: 0px !important;
        margin-top: -1px !important;
        position: fixed;
        top: 0;
        left: 0; }
        .cr-sidebar .zd-hamburger-menu.desktop {
          display: none; } }
  .cr-sidebar .navbar {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    margin: 0; }
    .cr-sidebar .navbar .navbar-brand {
      padding: 0; }
  @media (max-width: 750px) {
    .cr-sidebar {
      width: 0; } }
  .cr-sidebar + .cr-content {
    transition: margin 0.1s ease-in;
    margin-left: 56px; }
    @media (max-width: 750px) {
      .cr-sidebar + .cr-content {
        margin-left: 0; } }
  .cr-sidebar::after, .cr-sidebar::before {
    display: none; }
  .cr-sidebar .zd-logo {
    margin: 3px auto; }
  .cr-sidebar::after {
    z-index: 3;
    background: #fff;
    opacity: 1; }
  .cr-sidebar[data-image]:after {
    opacity: 0.77; }
  .cr-sidebar__content {
    position: relative;
    z-index: 4;
    height: 100vh;
    overflow: visible;
    scrollbar-width: none;
    -ms-overflow-style: none; }
    .cr-sidebar__content::-webkit-scrollbar {
      width: 0; }
    .cr-sidebar__content .navbar {
      margin-bottom: 0.5rem;
      border-bottom: 1px solid #43484e;
      overflow: hidden; }
  .cr-sidebar__background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
    display: none; }
  .cr-sidebar__nav-item-collapse {
    display: flex;
    justify-content: space-between;
    cursor: pointer; }
  .cr-sidebar__nav-item-icon {
    width: 32px;
    min-width: 32px;
    height: 32px;
    min-height: 32px;
    padding-left: 0;
    padding-right: 0;
    margin-left: 8px;
    margin-right: 16px;
    color: #4d7ce8; }
  @media (max-width: 750px) {
    .cr-sidebar .nav {
      display: none; } }
  .cr-sidebar .nav .nav-item {
    margin: 0;
    color: #202225;
    border-radius: 4px;
    opacity: 1;
    font-size: 12px;
    white-space: pre;
    margin-top: 24px; }
    @media (min-width: 1800px) {
      .cr-sidebar .nav .nav-item {
        margin-top: 24px; } }
    @media (min-width: 1368px) {
      .cr-sidebar .nav .nav-item {
        margin-top: 22px; } }
    @media (max-width: 1368px) {
      .cr-sidebar .nav .nav-item {
        margin-top: 14px; } }
    @media (max-width: 1200px) {
      .cr-sidebar .nav .nav-item {
        margin-top: 22px; } }
    @media (max-width: 750px) {
      .cr-sidebar .nav .nav-item {
        margin-top: 0px; }
        .cr-sidebar .nav .nav-item:last-child {
          display: none; } }
  .cr-sidebar .nav .nav-link {
    padding: 3px 12px 3px 6px;
    color: #6b7883;
    width: 54px;
    overflow: hidden; }
    .cr-sidebar .nav .nav-link .zd-sidebar-icon {
      font-size: 23px;
      vertical-align: middle;
      margin-left: 10px;
      margin-right: 12px;
      color: #6b7883; }
    @media (max-width: 750px) {
      .cr-sidebar .nav .nav-link {
        padding: 10px 4px 10px 4px; } }
    .cr-sidebar .nav .nav-link > span {
      visibility: hidden;
      opacity: 0;
      font-weight: 500; }
    .cr-sidebar .nav .nav-link:hover {
      background: #303139;
      width: auto;
      overflow: visible;
      border-radius: 2px; }
      @media (max-width: 750px) {
        .cr-sidebar .nav .nav-link:hover {
          width: 100%;
          background-color: transparent; } }
      .cr-sidebar .nav .nav-link:hover .zd-sidebar-icon {
        color: #d8d8d8; }
      .cr-sidebar .nav .nav-link:hover > span {
        color: #d8d8d8;
        visibility: visible;
        opacity: 1; }
    .cr-sidebar .nav .nav-link.active {
      background: #222327;
      position: relative;
      overflow: hidden; }
      .cr-sidebar .nav .nav-link.active .zd-sidebar-icon {
        color: #4f7be9; }
        @media (max-width: 750px) {
          .cr-sidebar .nav .nav-link.active .zd-sidebar-icon {
            color: #fff; } }
      @media (min-width: 750px) {
        .cr-sidebar .nav .nav-link.active::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 4px;
          background: #4d7ce8;
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px; } }
      .cr-sidebar .nav .nav-link.active:hover {
        background: #303139 !important;
        color: #4f7be9;
        overflow: visible; }
        .cr-sidebar .nav .nav-link.active:hover .zd-sidebar-icon {
          color: #4f7be9; }
        @media (max-width: 750px) {
          .cr-sidebar .nav .nav-link.active:hover {
            color: #4f7be9;
            background: transparent; } }
      .cr-sidebar .nav .nav-link.active .zd-home-icon,
      .cr-sidebar .nav .nav-link.active .zd-icon {
        color: #4d7ce8; }
      .cr-sidebar .nav .nav-link.active span {
        color: #4d7ce8; }
      .cr-sidebar .nav .nav-link.active .cr-sidebar__nav-item-icon {
        color: #dddddd; }
      @media (max-width: 750px) {
        .cr-sidebar .nav .nav-link.active {
          background: transparent; }
          .cr-sidebar .nav .nav-link.active svg path {
            stroke: #d8d8d8; }
          .cr-sidebar .nav .nav-link.active span {
            color: #4f7be9; } }
  .cr-sidebar .cr-sidebar__nav-item .active {
    background: linear-gradient(90deg, rgba(73, 120, 239, 0.09) 0.84%, rgba(73, 120, 239, 0.03) 55.66%, rgba(73, 120, 239, 0) 99.46%) !important; }
  .cr-sidebar .nav .nav-link .zd-home-icon {
    overflow: visible; }
  .cr-sidebar .icon-ico,
  .cr-sidebar .nav .nav-link .zd-home-icon,
  .cr-sidebar .nav .nav-link .zd-icon {
    font-size: 26px;
    color: #575f66;
    opacity: 0.8;
    vertical-align: middle;
    margin-left: 11px;
    margin-right: 12px;
    transition: color 0.4s ease;
    z-index: 31;
    position: relative; }
  .cr-sidebar .nav .nav-link:hover .zd-home-icon,
  .cr-sidebar .nav .nav-link:hover .zd-icon {
    color: #d8d8d8; }
  .cr-sidebar .icon-ico {
    margin-left: 0;
    margin-right: 0; }
  .cr-sidebar .zd-logo-46 {
    display: block; }
  .cr-sidebar .zd-logo-200 {
    display: none; }
  .cr-sidebar--open {
    left: 0;
    width: 208px;
    transition: left 0.2s ease-in; }
    @media (max-width: 750px) {
      .cr-sidebar--open {
        width: 100vw;
        height: calc(100vh - 48px); } }
    .cr-sidebar--open + .cr-content {
      margin: 0 0 0 208px;
      transition: margin 0.2s ease-in; }
      .cr-sidebar--open + .cr-content .cr-header {
        margin-left: 0; }
      .cr-sidebar--open + .cr-content .zd-content-head.zd-fixed-header {
        left: 208px; }
    .cr-sidebar--open .nav .nav-link {
      width: auto; }
      .cr-sidebar--open .nav .nav-link > span {
        visibility: visible;
        font-size: 12px;
        font-family: 'Poppins', 'Roboto', sans-serif;
        font-weight: 500;
        opacity: 1;
        color: #6b7883; }
        @media (max-width: 750px) {
          .cr-sidebar--open .nav .nav-link > span {
            color: #6b7883; } }
    .cr-sidebar--open .zd-logo-46 {
      display: none; }
    .cr-sidebar--open .zd-logo-200 {
      display: block; }

body .zd-footer {
  padding-left: 20px; }
  @media (max-width: 750px) {
    body .zd-footer {
      justify-content: center; } }
  body .zd-footer span {
    margin-left: 16px; }

.zd-is-safari .cr-sidebar .nav .nav-item .nav-link:hover {
  min-width: 140px; }

.zd-is-safari .cr-sidebar .nav .nav-item.zd-download-manager-btn .nav-link:hover {
  min-width: 210px; }

.zd-is-safari .cr-sidebar .nav .nav-item.zipdjpacks .nav-link:hover {
  min-width: 160px; }

.zd-is-safari .cr-sidebar .nav .nav-item.top-downloads .nav-link:hover {
  min-width: 190px; }

@media (max-width: 750px) {
  .zd-hamburger-menu {
    margin-top: 7px !important; } }

@media (max-width: 750px) {
  .cr-sidebar--open.cr-sidebar .nav {
    display: block !important; } }

@media (min-width: 750px) {
  .cr-sidebar--open.cr-sidebar .zd-hamburger-menu {
    transition: all 0.2s ease-in;
    margin-left: 158px; } }

.zd-sm-sidebar-profile {
  display: none; }
  @media (max-width: 750px) {
    .zd-sm-sidebar-profile {
      display: block;
      height: 104px;
      display: flex;
      align-items: center;
      justify-content: space-between; } }
  .zd-sm-sidebar-profile .zd-sm-sidebar-profile-img {
    padding: 4px;
    width: 60vw;
    text-align: center; }
    .zd-sm-sidebar-profile .zd-sm-sidebar-profile-img i {
      font-size: 80px;
      border: 1px solid #ddd;
      border-radius: 40px;
      color: #4f7be9; }
  .zd-sm-sidebar-profile .zd-sm-sidebar-profile-img img {
    width: 80px;
    height: 80px;
    border-radius: 80px; }
  .zd-sm-sidebar-profile .zd-sm-sidebar-profile-name {
    padding-left: 12px; }
  .zd-sm-sidebar-profile .zd-sm-sidebar-download,
  .zd-sm-sidebar-profile .zd-sm-sidebar-profile-setting {
    width: 20vw; }
    .zd-sm-sidebar-profile .zd-sm-sidebar-download a,
    .zd-sm-sidebar-profile .zd-sm-sidebar-profile-setting a {
      text-decoration: none !important; }
    .zd-sm-sidebar-profile .zd-sm-sidebar-download i,
    .zd-sm-sidebar-profile .zd-sm-sidebar-profile-setting i {
      font-size: 20px;
      color: #c4c4c4; }
  .zd-sm-sidebar-profile .zd-sm-sidebar-download {
    padding-left: 50px; }
  .zd-sm-sidebar-profile .zd-sm-sidebar-profile-setting {
    padding-right: 50px;
    text-align: right; }

.zd-sm-sidebar-profile-name {
  display: none; }
  @media (max-width: 750px) {
    .zd-sm-sidebar-profile-name {
      display: block;
      text-align: center; }
      .zd-sm-sidebar-profile-name .zd-sm-sidebar-profile-name h3 {
        line-height: 0;
        font-size: 16px;
        margin-top: 17px;
        color: #d8d8d8;
        font-weight: 500; }
      .zd-sm-sidebar-profile-name .zd-sm-sidebar-profile-artist {
        margin-top: 9px;
        font-size: 12px;
        color: #47484a;
        margin-bottom: 50px; } }

.zd-hamburger-menu i {
  color: #6b7883;
  font-size: 28px;
  text-align: center; }
  @media screen and (min-width: 750px) {
    .zd-hamburger-menu i {
      background-color: #242529 !important; } }

.zd-hamburger-menu:hover i {
  color: #dddddd; }

.zd-hamburger-menu:hover svg path {
  stroke: #dddddd; }

@media (max-width: 750px) {
  .zd-lg-sidebar-nav-item {
    display: none !important; } }

@media (min-width: 751px) {
  .zd-sm-sidebar-nav-item {
    display: none !important; } }

.cr-content {
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  padding-top: 62px; }
  @media (max-width: 750px) {
    .cr-content {
      padding-top: 48px; } }

.cr-app {
  min-height: 450px;
  background-color: #191b1f !important; }

.zd-px-3 {
  padding-left: 20px;
  padding-right: 20px; }
  @media (max-width: 750px) {
    .zd-px-3 {
      padding-left: 16px;
      padding-right: 16px; } }

.cr-page {
  min-height: 100vh;
  background: #191b1f;
  z-index: 1;
  position: relative;
  transition: all 0.2s ease-in-out; }
  .cr-page__header {
    display: flex;
    align-items: flex-end;
    align-self: center; }
    @media (max-width: 767.98px) {
      .cr-page__header {
        flex-direction: column;
        align-items: flex-start; } }
  .cr-page__title {
    padding-right: 0.5rem;
    margin-bottom: 1rem; }
    @media (max-width: 767.98px) {
      .cr-page__title {
        margin-bottom: 0; } }
  .cr-page__breadcrumb {
    align-self: flex-end;
    padding: 0;
    text-transform: capitalize;
    background: #202225; }
    @media (max-width: 767.98px) {
      .cr-page__breadcrumb {
        align-self: flex-start; } }
  .cr-page .row > [class^='col-'],
  .cr-page .row .col {
    margin-bottom: 1rem; }

.zd-packs-page .zd-content-wrap.is-secondary-header {
  padding-top: 10px !important; }

.zd-packs-page .zd-content-wrap.zd-content-wrap {
  padding-top: 25px !important; }

.zd-content-wrap {
  padding-top: 0; }
  .zd-content-wrap.is-secondary-header {
    padding-top: 70px; }
  .zd-content-wrap.zd-no-header {
    padding-top: 1.5%; }
    .zd-content-wrap.zd-no-header.zd-dm-no-header {
      padding-top: 2.7% !important; }
  .zd-content-wrap.zd-feature-no-header {
    padding-top: 10px !important; }
  .zd-content-wrap.is-secondary-label-header {
    padding-top: 10px; }

body.zd-player-open .zd-batch-download-section {
  bottom: 80px; }

.zd-cards {
  overflow: hidden; }

.zd-cards .card-body {
  padding-top: 1.25rem;
  padding-left: 10px;
  padding-right: 10px; }

.zd-cards .card .zd-card-controls {
  position: absolute;
  top: 35%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 90px;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.1s ease, visibility 0.3s ease;
  border-radius: 10px;
  overflow: hidden; }

.icon-info labelviewName {
  font-family: icomoon !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.zd-cards .card .zd-card-controls a {
  color: #fff;
  text-decoration: none;
  font-size: 1.3rem;
  display: inline-block;
  background: rgba(51, 51, 51, 0.8);
  float: left;
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  cursor: pointer;
  transition: background 0.3s ease; }

.zd-cards .card .zd-card-card-controls a:hover {
  background: #525253; }

body .zd-cards .card:hover .zd-card-controls {
  opacity: 1;
  visibility: visible; }

.zd-card .card-img {
  object-fit: cover;
  background: #2c303b;
  border-radius: 0px !important; }

.zd-chart-updated {
  line-height: 2.4rem; }

.zd-chart-title-number {
  width: 15px;
  font-size: x-large;
  font-weight: 150; }

.zd-track-position span {
  padding: 0 5px;
  color: #fff; }

.zd-search-layout .sk-results-list .zd-card {
  cursor: pointer;
  border-radius: 0px !important; }
  .zd-search-layout .sk-results-list .zd-card .card-body {
    height: 70px;
    padding: 14px 10px; }
    .zd-search-layout .sk-results-list .zd-card .card-body .card-title {
      margin-bottom: 0.35rem;
      color: #ddd !important; }

.zd-search-layout .sk-results-list .zd-artist-card .card-body {
  display: flex;
  margin: 0 auto; }
  .zd-search-layout .sk-results-list .zd-artist-card .card-body .card-title {
    display: inline-flex;
    align-items: center;
    width: 100%;
    text-align: center;
    justify-content: center;
    margin-bottom: 0; }

.zd-footer {
  background: #191b1f;
  bottom: 0px;
  z-index: 1;
  right: 0px;
  left: 0px;
  padding: 16px; }

.zd-clear-genre-filter-btn {
  color: #7685b3 !important;
  border: 0px;
  background: none !important; }

.zd-report-issue-btn {
  color: #7685b3 !important;
  background: none !important;
  border: none !important; }
  .zd-report-issue-btn:hover {
    color: #acbdf3 !important; }
  .zd-report-issue-btn:focus {
    box-shadow: none !important; }

body .search-results-page hr {
  margin-left: 13px; }

.scrollableComponents {
  height: calc(100vh - 60px);
  position: fixed;
  top: 60px;
  overflow-y: scroll;
  background: #1c1e22;
  width: auto;
  width: calc(100vw - 60px); }
  @media (max-width: 750px) {
    .scrollableComponents {
      width: 100vw; } }

.cr-widget {
  display: flex;
  flex-direction: row; }
  .cr-widget__icon {
    flex: 0 0 auto;
    align-items: center;
    justify-content: center; }

.react-autosuggest__container {
  position: static; }
  @media (max-width: 768px) {
    .react-autosuggest__container {
      background: transparent; } }

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.react-autosuggest__suggestions-container {
  display: none; }

.react-autosuggest__suggestions-container--open {
  display: block !important;
  position: absolute;
  top: 35px;
  width: 100%;
  border: none;
  background-color: #292a2f;
  font-size: 0.7rem;
  line-height: 1.5;
  border-radius: 4px;
  z-index: 20000000;
  padding: 0 0; }
  .react-autosuggest__suggestions-container--open .react-autosuggest__section-container {
    border-bottom: 1px solid #1f2024; }

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.react-autosuggest__suggestion {
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  padding: 7px 12px 7px;
  color: #6b7883; }

.react-autosuggest__suggestion--highlighted {
  background-color: #303139 !important;
  color: #dddddd; }

.react-autosuggest__section-container {
  border-top: 1px solid #27282d; }

.react-autosuggest__section-container--first {
  border-top: 0; }

.react-autosuggest__section-title {
  padding: 10px 12px 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #dddddd;
  text-transform: capitalize; }
  .react-autosuggest__section-title strong {
    font-weight: 500; }

.cr-search-form {
  position: relative; }
  .cr-search-form__icon-search {
    position: absolute;
    left: 10px; }
  .cr-search-form__input {
    /* stylelint-disable-next-line */
    padding-left: 30px !important;
    padding-right: 8px !important; }
    @media screen and (max-width: 750px) {
      .cr-search-form__input {
        padding-left: 34px !important;
        padding-right: 8px !important; } }

.sk-action-button, .sk-no-hits__step-action, .sk-range-input__submit {
  border: 1px solid #444;
  color: #375a7f;
  border-radius: 3px;
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 0.72rem;
  outline: none; }
  .sk-action-button:hover, .sk-no-hits__step-action:hover, .sk-range-input__submit:hover {
    text-decoration: underline; }
  .sk-action-button:active, .sk-no-hits__step-action:active, .sk-range-input__submit:active {
    color: black; }

.sk-spinning-loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  border-right: 2px solid rgba(0, 0, 0, 0.2);
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  border-left: 2px solid #000;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-animation: spinning-loader 1.1s infinite linear;
          animation: spinning-loader 1.1s infinite linear; }

.sk-spinning-loader,
.sk-spinning-loader:after {
  border-radius: 50%;
  width: 20px;
  height: 20px; }

@-webkit-keyframes spinning-loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spinning-loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.sk-search-box {
  flex: auto; }
  .sk-search-box form {
    display: flex;
    margin: 0;
    position: relative; }
  .sk-search-box__icon {
    flex: 0 1 20px;
    margin: 10px 0 0 10px;
    opacity: 0.3; }
    .sk-search-box__icon:before {
      content: "";
      background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KCgo8IS0tIFRoZSBpY29uIGNhbiBiZSB1c2VkIGZyZWVseSBpbiBib3RoIHBlcnNvbmFsIGFuZCBjb21tZXJjaWFsIHByb2plY3RzIHdpdGggbm8gYXR0cmlidXRpb24gcmVxdWlyZWQsIGJ1dCBhbHdheXMgYXBwcmVjaWF0ZWQuIApZb3UgbWF5IE5PVCBzdWItbGljZW5zZSwgcmVzZWxsLCByZW50LCByZWRpc3RyaWJ1dGUgb3Igb3RoZXJ3aXNlIHRyYW5zZmVyIHRoZSBpY29uIHdpdGhvdXQgZXhwcmVzcyB3cml0dGVuIHBlcm1pc3Npb24gZnJvbSBpY29ubW9uc3RyLmNvbSAtLT4KCgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgoKPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoKCSB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4KCjxwYXRoIGlkPSJtYWduaWZpZXItMi1pY29uIiBkPSJNNDYwLjM1NSw0MjEuNTlMMzUzLjg0NCwzMTUuMDc4YzIwLjA0MS0yNy41NTMsMzEuODg1LTYxLjQzNywzMS44ODUtOTguMDM3CgoJQzM4NS43MjksMTI0LjkzNCwzMTAuNzkzLDUwLDIxOC42ODYsNTBDMTI2LjU4LDUwLDUxLjY0NSwxMjQuOTM0LDUxLjY0NSwyMTcuMDQxYzAsOTIuMTA2LDc0LjkzNiwxNjcuMDQxLDE2Ny4wNDEsMTY3LjA0MQoKCWMzNC45MTIsMCw2Ny4zNTItMTAuNzczLDk0LjE4NC0yOS4xNThMNDE5Ljk0NSw0NjJMNDYwLjM1NSw0MjEuNTl6IE0xMDAuNjMxLDIxNy4wNDFjMC02NS4wOTYsNTIuOTU5LTExOC4wNTYsMTE4LjA1NS0xMTguMDU2CgoJYzY1LjA5OCwwLDExOC4wNTcsNTIuOTU5LDExOC4wNTcsMTE4LjA1NmMwLDY1LjA5Ni01Mi45NTksMTE4LjA1Ni0xMTguMDU3LDExOC4wNTZDMTUzLjU5LDMzNS4wOTcsMTAwLjYzMSwyODIuMTM3LDEwMC42MzEsMjE3LjA0MQoKCXoiLz4KCjwvc3ZnPgoK) no-repeat top left;
      background-size: contain;
      height: 20px;
      width: 20px;
      display: block; }
  .sk-search-box input.sk-search-box__text {
    padding: 10px 20px;
    width: 100%;
    flex: 3 1;
    font-size: 18px;
    border: none;
    height: initial;
    line-height: initial; }
    .sk-search-box input.sk-search-box__text:focus {
      outline: 0; }
  .sk-search-box__action {
    height: 40px;
    visibility: hidden; }
  .sk-search-box__loader {
    flex: 0 1 20px;
    align-self: flex-end;
    margin: 10px; }
    .sk-search-box__loader.is-hidden {
      display: none; }

.sk-hits-stats__info {
  font-size: 0.72rem;
  color: #777; }

.sk-action-button, .sk-no-hits__step-action, .sk-range-input__submit {
  border: 1px solid #444;
  color: #375a7f;
  border-radius: 3px;
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 0.72rem;
  outline: none; }
  .sk-action-button:hover, .sk-no-hits__step-action:hover, .sk-range-input__submit:hover {
    text-decoration: underline; }
  .sk-action-button:active, .sk-no-hits__step-action:active, .sk-range-input__submit:active {
    color: black; }

.sk-no-hits {
  text-align: center;
  margin-top: 60px;
  color: #dddddd; }
  .sk-no-hits__info {
    font-size: 0.72rem; }
  .sk-no-hits__steps {
    margin-top: 20px; }

.sk-filter-group-items {
  display: inline-block; }
  .sk-filter-group-items__title, .sk-filter-group-items__list, .sk-filter-group-items__value {
    display: inline-block; }
  .sk-filter-group-items__title {
    font-weight: bold; }
    .sk-filter-group-items__title:after {
      content: ": "; }
  .sk-filter-group-items__value {
    padding-left: 4px; }
    .sk-filter-group-items__value:hover {
      text-decoration: line-through;
      cursor: pointer;
      color: #ff0000; }
    .sk-filter-group-items__value:after {
      display: inline-block;
      content: ", "; }
    .sk-filter-group-items__value:last-child:after {
      content: ""; }

.sk-filter-group__remove-action {
  display: inline-block;
  cursor: pointer;
  font-weight: 700; }

.sk-action-bar .sk-filter-groups {
  display: flex;
  flex-flow: row;
  margin: 0 10px 10px 0;
  color: #375a7f; }

.sk-action-bar .sk-filter-group {
  border: none;
  background: #1d1b21;
  display: inline-block;
  padding: 0 0 0 10px;
  font-size: 0.72rem;
  line-height: 20px;
  border-radius: 3px;
  margin-right: 10px; }
  .sk-action-bar .sk-filter-group-items__title {
    font-weight: 300; }
  .sk-action-bar .sk-filter-group-items__list {
    padding: 10px 0; }
  .sk-action-bar .sk-filter-group__remove-action {
    border-left: 1px solid #ddd;
    padding: 0 15px 0 10px;
    margin-left: 10px;
    font-size: 14px; }
    .sk-action-bar .sk-filter-group__remove-action:hover {
      color: #ff0000; }

.sk-layout__filters .sk-filter-groups {
  display: block;
  width: 100%;
  margin-right: 10px;
  color: #666; }

.sk-layout__filters .sk-filter-group {
  border: none;
  display: block;
  font-size: 0.72rem;
  line-height: 20px; }
  .sk-layout__filters .sk-filter-group__remove-action {
    padding-right: 10px; }

.sk-item-list.is-disabled {
  display: none; }

.sk-item-list-option {
  position: relative;
  cursor: pointer;
  padding: 0px 0;
  line-height: 22px;
  display: flex;
  font-weight: 300; }
  .sk-item-list-option input.sk-item-list-option__checkbox {
    margin: 5px 5px 0 1px;
    flex: 0 0 15px; }
  .sk-item-list-option.is-active .sk-item-list-option__text {
    font-weight: 700;
    color: #000; }
  .sk-item-list-option.is-selected .sk-item-list-option__text {
    font-weight: 700;
    color: #000; }
  .sk-item-list-option__text {
    flex: auto;
    font-size: 0.72rem;
    color: #666; }
  .sk-item-list-option__count {
    font-size: 0.612rem;
    text-align: right;
    color: #2c2d31; }
  .sk-item-list-option.is-histogram {
    z-index: 1; }
  .sk-item-list-option.is-histogram .sk-item-list-option__count {
    padding-right: 4px;
    color: #999; }
  .sk-item-list-option__bar {
    position: absolute; }
  .sk-item-list-option__bar {
    display: inline-block;
    position: absolute;
    background: #e7e7e7;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 8px; }
    .sk-item-list-option__bar-container {
      position: absolute;
      right: 0;
      top: 3px;
      bottom: 3px;
      width: 40%;
      max-width: 60px;
      display: inline-block;
      z-index: -1;
      background: #f4f4f4;
      border-radius: 8px;
      overflow: hidden; }

.sk-select {
  position: relative;
  cursor: pointer;
  max-height: 30px;
  display: inline-block; }
  .sk-select.is-disabled {
    display: none; }
  .sk-select select {
    -webkit-appearance: none;
    appearance: none;
    background: #1d1b21;
    padding: 6px 10px;
    padding-right: 30px;
    border: 1px solid #2c2d31;
    outline: 0;
    font-weight: 700;
    font-size: 0.72rem;
    border-radius: 3px;
    color: #dddddd;
    height: initial;
    line-height: initial; }
  .sk-select:after {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 10px;
    width: 0;
    height: 0;
    border-top: 6px solid #666;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    pointer-events: none; }

.sk-tabs {
  width: 100%;
  border-bottom: 1px solid #444;
  list-style: none;
  padding: 0;
  margin: 4px 0; }
  .sk-tabs.is-disabled {
    display: none; }
  .sk-tabs-option {
    cursor: pointer;
    display: inline-block;
    font-weight: 500;
    font-size: 0.72rem;
    color: #375a7f;
    padding: 10px 15px;
    text-decoration: none;
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-radius: 3px 3px 0 0; }
    .sk-tabs-option.is-active {
      background-color: white;
      color: #666;
      border-color: #375a7f;
      cursor: default;
      border: 1px solid #444;
      border-bottom-color: transparent; }
    .sk-tabs-option.is-disabled {
      cursor: initial;
      color: #444; }
    .sk-tabs-option__text, .sk-tabs-option__count {
      display: inline; }
    .sk-tabs-option__count:before {
      content: " ("; }
    .sk-tabs-option__count:after {
      content: ")"; }

.sk-tag-cloud {
  text-align: justify;
  word-wrap: break-word;
  line-height: 16px;
  font-size: 10px; }
  .sk-tag-cloud.is-disabled {
    display: none; }
  .sk-tag-cloud-option {
    display: inline;
    cursor: pointer;
    font-weight: 300;
    color: #666;
    padding: 4px 2px;
    border-right: none;
    text-decoration: none; }
    .sk-tag-cloud-option__text, .sk-tag-cloud-option__count {
      display: inline; }
    .sk-tag-cloud-option:before {
      content: ' ';
      display: inline; }
    .sk-tag-cloud-option:after {
      content: ' ';
      display: inline; }
    .sk-tag-cloud-option__count:before {
      content: ' ('; }
    .sk-tag-cloud-option__count:after {
      content: ')'; }
    .sk-tag-cloud-option.is-active .sk-tag-cloud-option__text {
      color: #000;
      font-weight: 500; }
    .sk-tag-cloud-option.is-disabled {
      cursor: initial;
      color: rgba(221, 221, 221, 0.3); }

.sk-toggle {
  display: flex;
  align-content: center;
  text-align: center;
  border-radius: 3px;
  max-height: 30px; }
  .sk-toggle.is-disabled {
    display: none; }
  .sk-toggle-option {
    flex: 1 1;
    background-color: #1d1b21;
    border: 1px solid #2c2d31;
    cursor: pointer;
    font-weight: 500;
    font-size: 0.72rem;
    color: #dddddd;
    padding: 5px 10px;
    border-right: none;
    text-decoration: none;
    white-space: nowrap; }
    .sk-toggle-option:first-child {
      border-radius: 3px 0 0 3px; }
    .sk-toggle-option:last-child {
      border-radius: 0 3px 3px 0;
      border-right: 1px solid #2c2d31; }
    .sk-toggle-option.is-active {
      background-color: #375a7f;
      color: #dddddd;
      border-color: #375a7f; }
    .sk-toggle-option.is-disabled {
      cursor: initial;
      color: rgba(221, 221, 221, 0.3); }
    .sk-toggle-option__text, .sk-toggle-option__count {
      display: inline; }
    .sk-toggle-option__count:before {
      content: " ("; }
    .sk-toggle-option__count:after {
      content: ")"; }

.sk-range-input {
  width: 100%;
  align-content: center; }
  .sk-range-input__input {
    display: inline-block;
    width: 68px;
    border-radius: 3px;
    border: 1px solid #444;
    height: 30px;
    padding: 0 10px; }
    .sk-range-input__input.is-error {
      border-color: #ff0000; }
  .sk-range-input__submit {
    display: inline-block;
    background: white;
    height: 30px;
    padding: 0 10px;
    margin-left: 8px; }
  .sk-range-input__to-label {
    display: inline-block;
    line-height: 30px;
    margin: 0 8px;
    color: #dddddd;
    font-size: 0.72rem; }

.sk-range-histogram {
  height: 50px;
  flex-flow: row nowrap;
  justify-content: space-around;
  display: flex;
  align-items: flex-end; }
  .sk-range-histogram__bar {
    background: rgba(156, 162, 153, 0.5);
    flex: 1 1; }
    .sk-range-histogram__bar.is-out-of-bounds {
      background: rgba(156, 162, 153, 0.3); }

.rc-tooltip {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.sk-range-slider {
  padding-bottom: 16px; }
  .sk-range-slider .rc-slider-handle {
    border-color: #444; }
  .sk-range-slider .rc-slider-track {
    background-color: rgba(156, 162, 153, 0.5); }
  .sk-range-slider .rc-slider-mark-text-active {
    color: #2c2d31; }
  .sk-range-slider .rc-slider-mark-text {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: #2c2d31; }

.sk-hierarchical-menu-list.is-disabled {
  display: none; }

.sk-hierarchical-menu-list__header {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 0.72rem;
  color: #dddddd; }

.sk-hierarchical-menu-list__root {
  margin-left: -10px; }

.sk-hierarchical-menu-list__hierarchical-options {
  margin-left: 10px; }

.sk-hierarchical-menu-option {
  position: relative;
  cursor: pointer;
  padding: 0px 0;
  line-height: 22px;
  display: flex;
  font-weight: 300; }
  .sk-hierarchical-menu-option input.sk-hierarchical-menu-option__checkbox {
    margin: 5px 5px 0 1px;
    flex: 0 0 15px; }
  .sk-hierarchical-menu-option.is-active .sk-hierarchical-menu-option__text {
    font-weight: 700;
    color: #000; }
  .sk-hierarchical-menu-option.is-selected .sk-hierarchical-menu-option__text {
    font-weight: 700;
    color: #000; }
  .sk-hierarchical-menu-option__text {
    flex: auto;
    font-size: 0.72rem;
    color: #666; }
  .sk-hierarchical-menu-option__count {
    font-size: 0.612rem;
    text-align: right;
    color: #2c2d31; }

.sk-hierarchical-refinement-list__header {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 0.72rem; }

.sk-hierarchical-refinement-list__root {
  margin-left: -10px; }

.sk-hierarchical-refinement-list__hierarchical-options {
  margin-left: 10px; }

.sk-hierarchical-refinement-list.is-disabled {
  display: none; }

.sk-hierarchical-refinement-option {
  position: relative;
  cursor: pointer;
  padding: 0px 0;
  line-height: 22px;
  display: flex;
  font-weight: 300; }
  .sk-hierarchical-refinement-option input.sk-hierarchical-refinement-option__checkbox {
    margin: 5px 5px 0 1px;
    flex: 0 0 15px; }
  .sk-hierarchical-refinement-option.is-active .sk-hierarchical-refinement-option__text {
    font-weight: 700;
    color: #000; }
  .sk-hierarchical-refinement-option.is-selected .sk-hierarchical-refinement-option__text {
    font-weight: 700;
    color: #000; }
  .sk-hierarchical-refinement-option__text {
    flex: auto;
    font-size: 0.72rem;
    color: #666; }
  .sk-hierarchical-refinement-option__count {
    font-size: 0.612rem;
    text-align: right;
    color: #2c2d31; }

.sk-input-filter {
  flex: auto;
  border-radius: 3px;
  border: 1px solid #444; }
  .sk-input-filter form {
    display: flex;
    margin: 0;
    position: relative; }
  .sk-input-filter__icon {
    flex: 0 1 20px;
    margin-top: 6px;
    margin-left: 10px;
    opacity: 0.3; }
    .sk-input-filter__icon:before {
      content: "";
      background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KCgo8IS0tIFRoZSBpY29uIGNhbiBiZSB1c2VkIGZyZWVseSBpbiBib3RoIHBlcnNvbmFsIGFuZCBjb21tZXJjaWFsIHByb2plY3RzIHdpdGggbm8gYXR0cmlidXRpb24gcmVxdWlyZWQsIGJ1dCBhbHdheXMgYXBwcmVjaWF0ZWQuIApZb3UgbWF5IE5PVCBzdWItbGljZW5zZSwgcmVzZWxsLCByZW50LCByZWRpc3RyaWJ1dGUgb3Igb3RoZXJ3aXNlIHRyYW5zZmVyIHRoZSBpY29uIHdpdGhvdXQgZXhwcmVzcyB3cml0dGVuIHBlcm1pc3Npb24gZnJvbSBpY29ubW9uc3RyLmNvbSAtLT4KCgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgoKPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoKCSB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4KCjxwYXRoIGlkPSJtYWduaWZpZXItMi1pY29uIiBkPSJNNDYwLjM1NSw0MjEuNTlMMzUzLjg0NCwzMTUuMDc4YzIwLjA0MS0yNy41NTMsMzEuODg1LTYxLjQzNywzMS44ODUtOTguMDM3CgoJQzM4NS43MjksMTI0LjkzNCwzMTAuNzkzLDUwLDIxOC42ODYsNTBDMTI2LjU4LDUwLDUxLjY0NSwxMjQuOTM0LDUxLjY0NSwyMTcuMDQxYzAsOTIuMTA2LDc0LjkzNiwxNjcuMDQxLDE2Ny4wNDEsMTY3LjA0MQoKCWMzNC45MTIsMCw2Ny4zNTItMTAuNzczLDk0LjE4NC0yOS4xNThMNDE5Ljk0NSw0NjJMNDYwLjM1NSw0MjEuNTl6IE0xMDAuNjMxLDIxNy4wNDFjMC02NS4wOTYsNTIuOTU5LTExOC4wNTYsMTE4LjA1NS0xMTguMDU2CgoJYzY1LjA5OCwwLDExOC4wNTcsNTIuOTU5LDExOC4wNTcsMTE4LjA1NmMwLDY1LjA5Ni01Mi45NTksMTE4LjA1Ni0xMTguMDU3LDExOC4wNTZDMTUzLjU5LDMzNS4wOTcsMTAwLjYzMSwyODIuMTM3LDEwMC42MzEsMjE3LjA0MQoKCXoiLz4KCjwvc3ZnPgoK) no-repeat top left;
      background-size: contain;
      height: 20px;
      width: 20px;
      display: block; }
  .sk-input-filter__text {
    padding: 7px;
    width: 100%;
    flex: 3 1;
    font-size: 14px;
    background: transparent;
    border: none;
    color: #dddddd;
    height: initial;
    line-height: initial; }
    .sk-input-filter__text:focus {
      outline: 0; }
  .sk-input-filter__action {
    height: 30px;
    visibility: hidden;
    display: none; }
  .sk-input-filter__remove:before {
    flex: 0 1 20px;
    align-self: flex-end;
    padding: 5px 10px;
    line-height: 30px;
    color: #dddddd;
    content: "x";
    cursor: pointer; }
  .sk-input-filter__remove.is-hidden:before {
    display: none; }

.sk-numeric-refinement-list__header {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 0.72rem; }

.sk-numeric-refinement-list.is-disabled {
  display: none; }

.sk-numeric-refinement-list-option {
  position: relative;
  cursor: pointer;
  padding: 0px 0;
  line-height: 22px;
  display: flex;
  font-weight: 300; }
  .sk-numeric-refinement-list-option input.sk-numeric-refinement-list-option__checkbox {
    margin: 5px 5px 0 1px;
    flex: 0 0 15px; }
  .sk-numeric-refinement-list-option.is-active .sk-numeric-refinement-list-option__text {
    font-weight: 700;
    color: #000; }
  .sk-numeric-refinement-list-option.is-selected .sk-numeric-refinement-list-option__text {
    font-weight: 700;
    color: #000; }
  .sk-numeric-refinement-list-option__text {
    flex: auto;
    font-size: 0.72rem;
    color: #666; }
  .sk-numeric-refinement-list-option__count {
    font-size: 0.612rem;
    text-align: right;
    color: #2c2d31; }

.sk-refinement-list__header {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 0.72rem; }

.sk-refinement-list.is-disabled {
  display: none; }

.sk-refinement-list__view-more-action {
  font-size: 0.612rem;
  color: #375a7f;
  cursor: pointer;
  margin-top: 5px; }

.sk-refinement-list-option {
  position: relative;
  cursor: pointer;
  padding: 0px 0;
  line-height: 22px;
  display: flex;
  font-weight: 300; }
  .sk-refinement-list-option input.sk-refinement-list-option__checkbox {
    margin: 5px 5px 0 1px;
    flex: 0 0 15px; }
  .sk-refinement-list-option.is-active .sk-refinement-list-option__text {
    font-weight: 700;
    color: #000; }
  .sk-refinement-list-option.is-selected .sk-refinement-list-option__text {
    font-weight: 700;
    color: #000; }
  .sk-refinement-list-option__text {
    flex: auto;
    font-size: 0.72rem;
    color: #666; }
  .sk-refinement-list-option__count {
    font-size: 0.612rem;
    text-align: right;
    color: #2c2d31; }

.sk-selected-filters {
  display: flex;
  flex-flow: row wrap;
  margin-right: 10px; }
  .sk-selected-filters__item {
    flex: 0 0 auto;
    margin: 0 10px 10px 0; }
  .sk-selected-filters-option {
    background: #1d1b21;
    border: none;
    display: flex;
    padding: 10px 0 10px 10px;
    font-size: 0.72rem;
    line-height: 20px;
    border-radius: 3px; }
    .sk-selected-filters-option__name {
      flex: auto;
      color: #375a7f; }
    .sk-selected-filters-option__remove-action {
      flex: auto;
      padding: 0 10px;
      cursor: pointer;
      font-size: 16px;
      line-height: 18px;
      color: #375a7f;
      font-weight: 700;
      border-left: 1px solid #ddd;
      padding: 0 15px 0 10px;
      margin-left: 10px; }
      .sk-selected-filters-option__remove-action:hover {
        color: #ff0000; }

.sk-range-filter__header {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 0.72rem; }

.sk-range-filter.is-disabled {
  display: none; }

.sk-range-filter.is-no-histogram .bar-chart {
  margin-top: 15px; }

.sk-range-filter-value-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 5px; }
  .sk-range-filter-value-labels__min {
    align-self: flex-start;
    font-size: 0.612rem; }
  .sk-range-filter-value-labels__max {
    align-self: flex-end;
    font-size: 0.612rem; }

.bar-chart {
  height: 50px;
  flex-flow: row nowrap;
  justify-content: space-around;
  display: flex;
  align-items: flex-end; }
  .bar-chart__bar {
    background: rgba(156, 162, 153, 0.5);
    flex: 1 1; }
    .bar-chart__bar.is-out-of-bounds {
      background: rgba(156, 162, 153, 0.3); }

.sk-reset-filters {
  text-align: center;
  border-radius: 3px;
  padding: 10px 20px;
  cursor: pointer;
  color: #375a7f; }
  .sk-reset-filters.is-disabled {
    cursor: default;
    color: rgba(221, 221, 221, 0.3); }
  .sk-reset-filters__text {
    font-size: 0.612rem; }

.sk-menu-list__header {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 0.72rem; }

.sk-menu-list-option {
  position: relative;
  cursor: pointer;
  padding: 0px 0;
  line-height: 22px;
  display: flex;
  font-weight: 300; }
  .sk-menu-list-option input.sk-menu-list-option__checkbox {
    margin: 5px 5px 0 1px;
    flex: 0 0 15px; }
  .sk-menu-list-option.is-active .sk-menu-list-option__text {
    font-weight: 700;
    color: #000; }
  .sk-menu-list-option.is-selected .sk-menu-list-option__text {
    font-weight: 700;
    color: #000; }
  .sk-menu-list-option__text {
    flex: auto;
    font-size: 0.72rem;
    color: #666; }
  .sk-menu-list-option__count {
    font-size: 0.612rem;
    text-align: right;
    color: #2c2d31; }

.sk-tag-filter {
  cursor: pointer; }

.sk-tag-filter-list {
  display: inline; }
  .sk-tag-filter-list .sk-tag-filter {
    display: inline; }
    .sk-tag-filter-list .sk-tag-filter:after {
      content: ", ";
      cursor: initial; }
    .sk-tag-filter-list .sk-tag-filter:last-child:after {
      content: ""; }

.sk-pagination-navigation .sk-toggle-option:first-child,
.sk-pagination-navigation .sk-toggle-option:last-child {
  flex: 4 1; }

/* TODO : This style is copied from PureCSS for testing purpose, please come back and update !! */
.sk-table {
  /* Remove spacing between table cells (from Normalize.css) */
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #cbcbcb;
  /* Consider removing this next declaration block, as it causes problems when
  there's a rowspan on the first cell. Case added to the tests. issue#432 */
  /*
  striping:
    even - #fff (white)
    odd  - #f2f2f2 (light gray)
  */
  /* nth-child selector for modern browsers */
  /* BORDERED TABLES */
  /* HORIZONTAL BORDERED TABLES */ }
  .sk-table caption {
    color: #000;
    font: italic 85%/1 arial, sans-serif;
    padding: 1em 0;
    text-align: center; }
  .sk-table td,
  .sk-table th {
    border-left: 1px solid #cbcbcb;
    /*  inner column border */
    border-width: 0 0 0 1px;
    font-size: inherit;
    margin: 0;
    overflow: visible;
    /*to make ths where the title is really long work*/
    padding: 0.5em 1em;
    /* cell padding */ }
  .sk-table td:first-child,
  .sk-table th:first-child {
    border-left-width: 0; }
  .sk-table thead {
    background-color: #e0e0e0;
    color: #000;
    text-align: left;
    vertical-align: bottom; }
  .sk-table td {
    background-color: transparent; }
  .sk-table-odd td {
    background-color: #f2f2f2; }
  .sk-table-striped tr:nth-child(2n-1) td {
    background-color: #f2f2f2; }
  .sk-table-bordered td {
    border-bottom: 1px solid #cbcbcb; }
  .sk-table-bordered tbody > tr:last-child > td {
    border-bottom-width: 0; }
  .sk-table-horizontal td,
  .sk-table-horizontal th {
    border-width: 0 0 1px 0;
    border-bottom: 1px solid #cbcbcb; }
  .sk-table-horizontal tbody > tr:last-child > td {
    border-bottom-width: 0; }

.sk-layout__filters .sk-panel {
  margin-bottom: 16px; }

.sk-action-bar .sk-panel__header {
  display: none; }

.sk-panel {
  width: 100%; }
  .sk-panel__header {
    color: #dddddd;
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 0.72rem; }
    .sk-panel__header.is-collapsable {
      cursor: pointer;
      -webkit-user-select: none;
              user-select: none;
      padding-left: 15px;
      position: relative; }
      .sk-panel__header.is-collapsable:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        margin-top: -3px;
        left: -1px;
        width: 0;
        height: 0;
        border-top: 5px solid #666;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent; }
      .sk-panel__header.is-collapsable.is-collapsed:after {
        width: 0;
        height: 0;
        border-bottom: 5px solid transparent;
        border-top: 5px solid transparent;
        border-left: 5px solid #666;
        margin-top: -5px;
        left: 0; }
  .sk-panel__content.is-collapsed {
    display: none; }
  .sk-panel.is-disabled {
    display: none; }

.cr-content .sk-pagination-navigation > .sk-toggle {
  display: block;
  text-align: center; }

.cr-content .sk-pagination-navigation .sk-toggle-option.sk-toggle__item.is-active {
  pointer-events: none !important; }

.cr-content .sk-pagination-navigation .sk-toggle-option {
  flex: none;
  display: inline-block;
  background-color: transparent;
  color: #909090;
  border: none;
  padding: 5px 10px;
  font-size: 13px;
  line-height: 20px; }
  @media (max-width: 750px) {
    .cr-content .sk-pagination-navigation .sk-toggle-option {
      padding: 5px 7px; } }
  .cr-content .sk-pagination-navigation .sk-toggle-option > .sk-toggle-option__text {
    position: inherit;
    display: inline;
    padding: 0;
    margin: 0;
    line-height: inherit;
    color: inherit;
    border: none;
    background: transparent; }
  .cr-content .sk-pagination-navigation .sk-toggle-option.sk-toggle-input-text {
    padding: 5px 0px; }
  .cr-content .sk-pagination-navigation .sk-toggle-option > input.goto-text {
    height: 20px !important;
    width: 50px;
    padding: 1px 2px;
    font-size: 13px !important;
    line-height: 20px !important;
    background: transparent;
    color: #909090;
    border: 1px solid #444;
    text-align: center;
    -moz-appearance: textfield; }
    .cr-content .sk-pagination-navigation .sk-toggle-option > input.goto-text::-webkit-input-placeholder {
      font-size: 10px !important;
      line-height: 20px !important;
      padding: 0px;
      text-align: center;
      vertical-align: middle; }
    .cr-content .sk-pagination-navigation .sk-toggle-option > input.goto-text::placeholder {
      font-size: 10px !important;
      line-height: 20px !important;
      padding: 0px;
      text-align: center;
      vertical-align: middle; }
    .cr-content .sk-pagination-navigation .sk-toggle-option > input.goto-text::-webkit-outer-spin-button, .cr-content .sk-pagination-navigation .sk-toggle-option > input.goto-text::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
  .cr-content .sk-pagination-navigation .sk-toggle-option[data-key="previous"] {
    background-color: #242529;
    padding: 3px 8px;
    color: #6B7883;
    margin-right: 18px; }
    @media (max-width: 750px) {
      .cr-content .sk-pagination-navigation .sk-toggle-option[data-key="previous"] {
        margin-right: 7px; } }
  .cr-content .sk-pagination-navigation .sk-toggle-option[data-key="next"] {
    background-color: #242529;
    padding: 3px 8px;
    color: #6B7883;
    margin-left: 18px; }
    @media (max-width: 750px) {
      .cr-content .sk-pagination-navigation .sk-toggle-option[data-key="next"] {
        margin-left: 0; } }
  .cr-content .sk-pagination-navigation .sk-toggle-option.is-disabled {
    padding: 3px 8px;
    pointer-events: none;
    color: #6B7883; }
    .cr-content .sk-pagination-navigation .sk-toggle-option.is-disabled > .sk-toggle-option__text {
      cursor: default; }
    .cr-content .sk-pagination-navigation .sk-toggle-option.is-disabled:hover {
      color: #909090; }
  .cr-content .sk-pagination-navigation .sk-toggle-option.is-active, .cr-content .sk-pagination-navigation .sk-toggle-option:hover {
    color: #dddddd; }
  .cr-content .sk-pagination-navigation .sk-toggle-option.is-active {
    background-color: #4F7BE9;
    border-radius: 2px; }

.chart-modal-body {
  background-color: #242529 !important; }
  .chart-modal-body .chart-modal-container .chart-modal-header {
    display: flex;
    justify-content: space-between; }
    .chart-modal-body .chart-modal-container .chart-modal-header .chart-input-title {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #dddddd;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px; }
    .chart-modal-body .chart-modal-container .chart-modal-header .chart-genre-dropdown {
      width: 118px; }
      .chart-modal-body .chart-modal-container .chart-modal-header .chart-genre-dropdown .zd-dropdown {
        width: 118px;
        height: 40px;
        background: #2a3143;
        border-radius: 2px; }
        @media (max-width: 1024px) {
          .chart-modal-body .chart-modal-container .chart-modal-header .chart-genre-dropdown .zd-dropdown {
            height: 31px; } }
    .chart-modal-body .chart-modal-container .chart-modal-header .chart-searchbox {
      width: 60%; }
      .chart-modal-body .chart-modal-container .chart-modal-header .chart-searchbox input {
        height: 40px;
        background: #303139;
        border-radius: 2px;
        color: #8e8e8e;
        box-shadow: none;
        border: none; }
      @media (max-width: 1024px) {
        .chart-modal-body .chart-modal-container .chart-modal-header .chart-searchbox {
          width: 50%; }
          .chart-modal-body .chart-modal-container .chart-modal-header .chart-searchbox input {
            height: 31px; } }
      @media (max-width: 768px) {
        .chart-modal-body .chart-modal-container .chart-modal-header .chart-searchbox {
          width: 40%; } }
    .chart-modal-body .chart-modal-container .chart-modal-header .chart-reset-button {
      width: 100px; }
      .chart-modal-body .chart-modal-container .chart-modal-header .chart-reset-button .chart-btns {
        width: 100px;
        height: 40px;
        background: #303139;
        border-radius: 2px !important;
        box-shadow: none;
        border: none; }
        @media (max-width: 1024px) {
          .chart-modal-body .chart-modal-container .chart-modal-header .chart-reset-button .chart-btns {
            height: 31px; } }
  .chart-modal-body .chart-modal-container .chart-modal-instruction {
    padding-top: 16px; }
    @media (max-width: 768px) {
      .chart-modal-body .chart-modal-container .chart-modal-instruction {
        padding-top: 20px; } }
    @media (max-width: 1024px) {
      .chart-modal-body .chart-modal-container .chart-modal-instruction {
        padding-top: 30px; } }
    .chart-modal-body .chart-modal-container .chart-modal-instruction span {
      font-family: 'Poppins';
      font-style: normal;
      font-size: 14px;
      color: #8e8e8e; }
  @media (max-width: 1366px) {
    .chart-modal-body .chart-modal-container .chart-modal-instruction span {
      font-size: 12px; } }
  .chart-modal-body .chart-modal-container .chart-modal-release-container {
    padding-top: 16px;
    display: flex;
    justify-content: space-between; }
    @media (max-width: 768px) {
      .chart-modal-body .chart-modal-container .chart-modal-release-container {
        padding-top: 20px; } }
    @media (max-width: 1024px) {
      .chart-modal-body .chart-modal-container .chart-modal-release-container {
        padding-top: 30px; } }
    .chart-modal-body .chart-modal-container .chart-modal-release-container .chart-container {
      width: 45%; }
      .chart-modal-body .chart-modal-container .chart-modal-release-container .chart-container .chart-header {
        background-color: #242529;
        border: 1px solid #303139;
        box-sizing: border-box;
        border-radius: 4px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center; }
        @media (max-width: 1024px) {
          .chart-modal-body .chart-modal-container .chart-modal-release-container .chart-container .chart-header {
            height: 54px; } }
        .chart-modal-body .chart-modal-container .chart-modal-release-container .chart-container .chart-header span {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: #dddddd; }
      .chart-modal-body .chart-modal-container .chart-modal-release-container .chart-container .chart-item-container {
        background-color: #303139;
        width: 100%; }
        .chart-modal-body .chart-modal-container .chart-modal-release-container .chart-container .chart-item-container .chart-list-item {
          display: flex;
          align-items: center;
          background-color: #222327;
          font-family: 'Poppins';
          font-size: 12px;
          color: #dddddd;
          list-style-type: none;
          text-overflow: ellipsis;
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden; }
          .chart-modal-body .chart-modal-container .chart-modal-release-container .chart-container .chart-item-container .chart-list-item.chart-disable {
            background-color: #28292e !important;
            cursor: not-allowed; }
            .chart-modal-body .chart-modal-container .chart-modal-release-container .chart-container .chart-item-container .chart-list-item.chart-disable .zd_chart_add {
              color: #28292e !important; }
          .chart-modal-body .chart-modal-container .chart-modal-release-container .chart-container .chart-item-container .chart-list-item .zd-chart-input-release-text {
            font-size: 12px;
            color: #6b7883;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-left: 10px !important; }
          .chart-modal-body .chart-modal-container .chart-modal-release-container .chart-container .chart-item-container .chart-list-item .zd-chart-input-drag-drop {
            padding: 0px 5px;
            margin-left: auto !important;
            margin-right: 10px !important; }
            .chart-modal-body .chart-modal-container .chart-modal-release-container .chart-container .chart-item-container .chart-list-item .zd-chart-input-drag-drop .zdv3-icon-crate-drag-drop {
              color: #dddddd;
              font-size: 12px; }
          .chart-modal-body .chart-modal-container .chart-modal-release-container .chart-container .chart-item-container .chart-list-item .zd-chart-input-artist-text {
            max-width: 50%;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 12px;
            color: #dddddd; }
    .chart-modal-body .chart-modal-container .chart-modal-release-container .top-chart-container {
      width: 52%; }
      .chart-modal-body .chart-modal-container .chart-modal-release-container .top-chart-container .top-chart-container-tab {
        display: flex;
        width: 100%;
        height: 48px;
        background-color: #242529;
        border: 1px solid #303139;
        box-sizing: border-box;
        border-radius: 4px; }
        @media (max-width: 1024px) {
          .chart-modal-body .chart-modal-container .chart-modal-release-container .top-chart-container .top-chart-container-tab {
            height: 54px; } }
        .chart-modal-body .chart-modal-container .chart-modal-release-container .top-chart-container .top-chart-container-tab .chart-header {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: 'Poppins';
          font-weight: 500;
          font-size: 16px;
          cursor: pointer;
          color: #dddddd; }
          @media (max-width: 768px) {
            .chart-modal-body .chart-modal-container .chart-modal-release-container .top-chart-container .top-chart-container-tab .chart-header {
              font-size: 14px;
              text-align: center; } }
          @media (max-width: 1024px) {
            .chart-modal-body .chart-modal-container .chart-modal-release-container .top-chart-container .top-chart-container-tab .chart-header {
              text-align: center; } }
          @media (max-width: 1366px) {
            .chart-modal-body .chart-modal-container .chart-modal-release-container .top-chart-container .top-chart-container-tab .chart-header {
              font-size: 14px; } }
          .chart-modal-body .chart-modal-container .chart-modal-release-container .top-chart-container .top-chart-container-tab .chart-header.zd-active {
            background-color: #4f7be9; }
      .chart-modal-body .chart-modal-container .chart-modal-release-container .top-chart-container .top-chart-container-data {
        width: 100%;
        background-color: #303139; }
        .chart-modal-body .chart-modal-container .chart-modal-release-container .top-chart-container .top-chart-container-data .top-chart-containers {
          width: 100%; }
          .chart-modal-body .chart-modal-container .chart-modal-release-container .top-chart-container .top-chart-container-data .top-chart-containers .chart-item-right-container {
            background-color: #303139; }
            .chart-modal-body .chart-modal-container .chart-modal-release-container .top-chart-container .top-chart-container-data .top-chart-containers .chart-item-right-container .zd-text-ellipsis {
              list-style-type: none; }
            .chart-modal-body .chart-modal-container .chart-modal-release-container .top-chart-container .top-chart-container-data .top-chart-containers .chart-item-right-container .chart-list-item {
              background-color: #28292e;
              font-family: 'Poppins';
              font-size: 12px;
              color: #dddddd; }
        .chart-modal-body .chart-modal-container .chart-modal-release-container .top-chart-container .top-chart-container-data .last-chart-containers {
          width: 100%; }
          .chart-modal-body .chart-modal-container .chart-modal-release-container .top-chart-container .top-chart-container-data .last-chart-containers .chart-item-right-container {
            background-color: #303139; }
            .chart-modal-body .chart-modal-container .chart-modal-release-container .top-chart-container .top-chart-container-data .last-chart-containers .chart-item-right-container .zd-text-ellipsis {
              list-style-type: none; }
            .chart-modal-body .chart-modal-container .chart-modal-release-container .top-chart-container .top-chart-container-data .last-chart-containers .chart-item-right-container .chart-list-item {
              background-color: #222327;
              font-family: 'Poppins';
              font-size: 12px;
              color: #dddddd; }
  .chart-modal-body .chart-modal-container .chart-footer {
    padding-top: 28px;
    display: flex;
    justify-content: space-between; }
    @media (max-width: 1024px) {
      .chart-modal-body .chart-modal-container .chart-footer {
        padding-top: 30px; } }
    @media (max-width: 768px) {
      .chart-modal-body .chart-modal-container .chart-footer {
        padding-top: 20px; } }
    .chart-modal-body .chart-modal-container .chart-footer .chart-logout-btn {
      padding-left: 32px;
      font-family: 'Poppins';
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
      -webkit-text-decoration-line: underline;
              text-decoration-line: underline;
      color: #8e8e8e;
      cursor: pointer; }
    .chart-modal-body .chart-modal-container .chart-footer .chart-skip-btn {
      font-family: 'Poppins';
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
      -webkit-text-decoration-line: underline;
              text-decoration-line: underline;
      color: #8e8e8e;
      cursor: pointer; }
    .chart-modal-body .chart-modal-container .chart-footer .chart-submit-btn {
      width: 90px;
      height: 40px;
      background: #4f7be9;
      border-radius: 2px; }
      @media (max-width: 1024px) {
        .chart-modal-body .chart-modal-container .chart-footer .chart-submit-btn {
          height: 31px; } }

.chart-modal {
  height: 80vh !important;
  max-width: 80% !important; }
  @media (max-width: 1024px) {
    .chart-modal {
      max-width: 90% !important;
      height: 90vh !important; } }
  @media (max-width: 768px) {
    .chart-modal {
      max-width: 90% !important;
      height: 95vh !important; } }

.chart-container {
  background-color: #222327;
  width: 100%; }

.chart-item-container,
.chart-right-containers {
  max-height: 55vh;
  overflow-y: auto;
  white-space: nowrap;
  width: inherit; }
  @media (max-width: 1024px) {
    .chart-item-container,
    .chart-right-containers {
      height: 900px !important; } }
  @media (max-width: 1366px) {
    .chart-item-container,
    .chart-right-containers {
      height: 375px !important; } }
  @media (max-width: 768px) {
    .chart-item-container,
    .chart-right-containers {
      height: 660px !important; } }

.chart-item-right-container {
  height: 580px;
  overflow-y: auto;
  white-space: nowrap;
  width: inherit; }
  @media (max-width: 1024px) {
    .chart-item-right-container {
      height: 900px !important; } }
  @media (max-width: 768px) {
    .chart-item-right-container {
      height: 660px !important; } }

.zd-cursor-move {
  cursor: move; }

.zd_pointer {
  cursor: pointer; }

.zd_plus_icon:hover {
  color: #b9b3f0; }

.lastchart-disable {
  background-color: #28292e !important; }
  .lastchart-disable .zd_chart_add {
    color: #28292e !important; }

.bg-gradient-theme {
  background: linear-gradient(to bottom, #4d7ce8, #7685b3); }

.bg-gradient-theme-top {
  background: linear-gradient(to top, #4d7ce8, #7685b3); }

.bg-gradient-theme-left {
  background: linear-gradient(to left, #4d7ce8, #7685b3); }

.bg-gradient-theme-right {
  background: linear-gradient(to right, #4d7ce8, #7685b3); }

.ButtonPage .btn {
  margin: 4px; }

.ButtonPage .btn-group .btn {
  margin: 0; }

.TablePage .card-body {
  overflow: hidden; }

