// page

.cr-app {
  min-height: 450px;
  background-color: #191b1f !important;
}

.zd-px-3 {
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 750px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.cr-page {
  min-height: 100vh;
  // background: $gray-1400;
  background: #191b1f;
  z-index: 1;
  position: relative;
  transition: all 0.2s ease-in-out;

  &__header {
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;
    }
    display: flex;
    align-items: flex-end;
    align-self: center;
  }

  &__title {
    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }
    padding-right: 0.5rem;
    margin-bottom: 1rem;
  }

  &__breadcrumb {
    @include media-breakpoint-down(sm) {
      align-self: flex-start;
    }
    align-self: flex-end;
    padding: 0;
    text-transform: capitalize;
    background: theme-color('dark');
  }

  .row {
    // stylelint-disable-next-line
    > [class^='col-'],
    .col {
      margin-bottom: 1rem;
    }
  }
}

// Added new css for header scroll by pramod
.zd-packs-page {
  .zd-content-wrap {
    &.is-secondary-header {
      padding-top: 10px !important;
    }
    &.zd-content-wrap {
      padding-top: 25px !important;
    }
  }
}

.zd-content-wrap {
  padding-top: 0;

  &.is-secondary-header {
    padding-top: 70px; //Comment by Pramod 132px;
  }

  &.zd-no-header {
    &.zd-dm-no-header {
      padding-top: 2.7% !important; // New Css for All genres dropdown
    }
    padding-top: 1.5%;
  }

  &.is-small-header {
    // padding-top: 60px; //80px;
  }

  &.zd-feature-no-header {
    padding-top: 10px !important;
  }

  &.is-secondary-label-header {
    padding-top: 10px;
  }
}

body {
  &.zd-player-open {
    .zd-batch-download-section {
      bottom: 80px;
    }
  }
}
//labels Page
.zd-cards {
  overflow: hidden;
}

.zd-cards .card-body {
  padding-top: 1.25rem;
  padding-left: 10px;
  padding-right: 10px;
}

.zd-cards .card .zd-card-controls {
  position: absolute;
  top: 35%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 90px;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.1s ease, visibility 0.3s ease;
  border-radius: 10px;
  overflow: hidden;
}

.icon-info labelviewName {
  font-family: icomoon !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.zd-cards .card .zd-card-controls a {
  color: #fff;
  text-decoration: none;
  font-size: 1.3rem;
  display: inline-block;
  background: rgba(51, 51, 51, 0.8);
  float: left;
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.zd-cards .card .zd-card-card-controls a:hover {
  background: #525253;
}

body .zd-cards .card:hover .zd-card-controls {
  opacity: 1;
  visibility: visible;
}

.zd-card .card-img {
  object-fit: cover;
  background: $gray-850;
  border-radius: 0px !important;
}

.zd-chart-updated {
  line-height: 2.4rem;
}

.zd-chart-title-number {
  width: 15px;
  font-size: x-large;
  font-weight: 150;
}

.zd-track-position span {
  padding: 0 5px;
  color: #fff;
}

.zd-search-layout {
  .sk-results-list {
    .zd-card {
      cursor: pointer;
      border-radius: 0px !important;
      .card-body {
        height: 70px;
        padding: 14px 10px;
        // background-color: #222327;
        .card-title {
          margin-bottom: 0.35rem;
          color: #ddd !important;
        }
      }
    }

    .zd-artist-card {
      .card-body {
        display: flex;
        margin: 0 auto;

        .card-title {
          display: inline-flex;
          align-items: center;
          width: 100%;
          text-align: center;
          justify-content: center;
          margin-bottom: 0;
        }
      }
    }
  }
}

.zd-footer {
  // background: $gray-1400;
  background: #191b1f;
  // position: absolute;
  bottom: 0px;
  z-index: 1;
  right: 0px;
  left: 0px;
  padding: 16px;
}

.zd-clear-genre-filter-btn {
  color: #7685b3 !important;
  border: 0px;
  background: none !important;
}

.zd-report-issue-btn {
  color: #7685b3 !important;
  background: none !important;
  border: none !important;

  &:hover {
    // border-color:#7685b3 !important;
    color: #acbdf3 !important;
  }
  &:focus {
    box-shadow: none !important;
  }
}

body .search-results-page {
  .search-heading {
    // padding: 0px 13px !important;
  }
  hr {
    margin-left: 13px;
  }
}
.scrollableComponents {
  height: calc(100vh - 60px);
  position: fixed;
  top: 60px;
  overflow-y: scroll;
  background: #1c1e22;
  width: auto;
  width: calc(100vw - 60px);
  @media (max-width: 750px) {
    width: 100vw;
  }
}
