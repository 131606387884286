.genrecard {
  margin-top: 24px;
  .genrecard__cards {
    display: grid;
    grid-template-columns: repeat(12, minmax(auto, 240px));
    grid-gap: 16px;
    @media (min-width: 1440px) {
      grid-template-columns: repeat(15, minmax(auto, 240px));
    }
    @media (min-width: 1920px) {
      grid-template-columns: repeat(18, minmax(auto, 240px));
    }
    .genrecard__card {
      grid-column-end: span 3;
      position: relative;
      cursor: pointer;
      @media (min-width: 1440px) {
        grid-column-end: span 3;
      }
      @media (max-width: 1224px) {
        grid-column-end: span 6;
      }
      @media (max-width: 750px) {
        grid-column-end: span 12;
      }
      .genrecard__img {
        width: 100%;
        padding-top: 40%;
        overflow: hidden;
        position: relative;
        @media (max-width: 1224px) {
          padding-top: 23.5%;
        }
        @media (max-width: 750px) {
          padding-top: 23.5%;
        }
        img {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        &::after {
          content: '';
          background: #242529e3;
          z-index: 2;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }
      }
      .genrecard__content {
        span {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 3;
          text-align: left;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          // font-size: 14px;
          line-height: 21px;
          color: #dddddd;
          padding-left: 24px;
        }
      }
      &:hover {
        .genrecard__img {
          &::after {
            background: #242529c5;
          }
        }
      }
    }
  }
}
.genre_heading {
  .default {
    color: #ddd;
  }
  .title_heading{
    margin-left: -20px;
  }

  .active {
    color: #4f7be9 !important;
  }
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #dddddd;
  margin-bottom: 18px;
  margin-left: 20px;
  span {
    cursor: pointer;
    margin-right: 18px;
  }
  .alpha {
    color: #ddd;
  }
}
.mega_genres {
  padding: 10px;
  margin-bottom: 10px;
  .genrecard {
    margin-bottom: 40px !important;
  }
}
.border_left {
  border-left: 2px solid #4f7be9;
}
.megamenu_heading {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #dddddd;
  margin-bottom: 18px;
  margin-top: 14px;
  position: absolute;
  z-index: 999;
  top: 0;
  margin-left: 18px;
  .default {
    margin-right: 18px;
    color: #4f7be9;
  }
  span {
    cursor: pointer;
  }
}
