body .form-control {
  border-radius: 0px !important;
}
.navbar-nav {
  .cr-search-form {
    .form-control {
      background: #242529;
      color: $white;
      border-radius: 4px !important;
      &:focus {
        // border: none !important;
        box-shadow: none !important;
        // border-top: none !important;
        // border-left: none !important;
        // border-right: none !important;
        // border-bottom-color: #becff7;
        // border-bottom-width: 1px;
        border-bottom: 1px solid #4f7be9 !important;
      }
      &.cr-search-form__input {
        min-width: 232px;
        width: 290px;
        height: 32px;
        // border: none;
        border: 1px solid #32333a;

        &::placeholder {
          color: #6b7883;
          font-size: 12px;
        }
      }

      &:hover {
        &.cr-search-form__input {
          &::placeholder {
            color: #dddddd;
          }
        }
      }
      &.cr-search-form__input::-webkit-search-cancel-button {
        -webkit-appearance: none;
        height: 8px;
        width: 8px;
        background-image: url('../assets/img/close.svg');
        cursor: pointer;
        &:hover {
          background-image: url('../assets/img/closehover.svg');
        }
      }
    }
    .cr-search-form__icon-search {
      color: #6b7883;
    }
    &:hover {
      .cr-search-form__icon-search {
        color: #dddddd !important;
      }
    }
    &:active {
      .cr-search-form__icon-search {
        color: #dddddd !important;
      }
    }
  }
  &.searchbar-focused {
    .cr-search-form {
      .form-control {
        &.cr-search-form__input {
          background-color: #292a2f;
          line-height: 0;
          &::placeholder {
            color: #dddddd !important;
            font-size: 12px;
            line-height: 0;
          }
        }
      }
    }
  }
}

.nav-item {
  .nav-link {
    display: flex;
    align-items: center;

    &.active {
      color: $white;
      background: rgba(255, 255, 255, 0.23);
      opacity: 1;
    }
  }
}

.sidebar .nav a {
  transition: all 150ms ease-in;
}

.card-header {
  text-transform: uppercase;
  background-color: #1b1b1d;
}

.badge:empty {
  display: inline-block;
}

.list-item {
  list-style-type: none;
  border-radius: 5px;
  background: #25272e;
  margin: 20px 0;
  padding: 2px 6px;
}

.track {
  margin-left: -40px;
}

.track-list-item {
  list-style-type: none;
}

.breadcrumb {
  background-color: transparent;
}

.breadcrumb-item {
  font-size: 0.8rem;
}

.accordion-question {
  line-height: 16px;
  margin-bottom: 0;
  color: #fff;
  padding: 10px;
  background-color: #212226;
  border-radius: 5px;
}

.hidden-card {
  background: transparent;
  border: none;
}

.zd-search-layout {
  // margin-bottom: 40px;
}

.search-heading {
  font-size: 1.1rem;
  margin-top: 15px;
  text-transform: capitalize;
  color: $white !important;
  .zd-cards.zd-artist-card{
    margin-bottom: 0 !important;
  }
}

.sub-genre-packs {
  margin: 15px 0 0 15px;
  width: 300px;
  height: 100px;
  border-radius: 10px;
  transition: all 0.1s ease;
}

.sub-genre-ul {
  padding: 5px;
}

.sub-genre-pack-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  cursor: pointer;
}

.sub-genre-packs:hover {
  transform: translateY(-10px);
}

.dj-chart-list-item {
  width: 100%;
  display: flex;
  list-style: none;
  border-bottom: 1px solid #2c2d31;

  &:hover {
    background-color: #33343a;
  }
}

.left-list {
  align-self: center;
  padding-right: 15px;
}

.center-list {
  flex-grow: 1;
  text-align: left;
  max-width: 180px;
}
.download-center-list {
  flex-grow: 1;
  align-self: center;
  text-align: left;
}
.sub-genre-release-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.right-list {
  align-self: center;
}

.sub-genre-track-text {
  padding-bottom: 5px;
  font-size: 0.7rem;
}

.tab-head {
  padding: 0 15px 5px 0;
  cursor: pointer;

  &:hover {
    border-color: transparent !important;
  }
}

.tab-active {
  .tab-text {
    border-bottom: 2px solid #4d7ce8;
  }
}

.tab-container {
  border: none;
}

.new-releases-card {
  border-radius: 10px !important;
  transition: all 0.2s ease !important;
  height: 100%;

  &:hover {
    transform: translateY(-5px);
  }

  .release-head {
    // border-radius: 0 0 10px 10px !important;
  }

  .release-name {
    height: 100%;
    // border-radius: 10px 10px 0 0 !important;
  }

  .release-image {
    height: 100%;
    // border-radius: 10px !important;
  }
}

.genre-item {
  //display: flex;
  list-style-type: none;
  background: #25272e;
  // justify-content: space-between;
  border-radius: 5px;
  padding: 2px 0;

  .subgenre-item {
    display: inline-block;
    // color: #9ac2ff;
    // padding: 3px 10px;
    // border-right: 1px solid #4d4d4a;
    /* border-radius: 6px; */
    // padding: 3px 10px;
    // background: #2a3043;
    // margin: 0.5rem 0.2rem;
    // cursor: pointer;
    // color: #9ac2ff;
    // color: #7685b3;
    color: #ddd;
    padding: 3px 10px;
    border-right: 1px solid #4d4d4a;
    // //border: 1px solid;
    // //border-radius: 50px;
    // border-radius: 5px;
    // padding: 3px 10px;
    // background: #2a3043;
    // margin: 0.5rem 0.2rem 0.5rem 0.2rem;
    cursor: pointer;
  }

  .subgenre-list {
    padding: 3px 10px;
    border-right: 1px solid white;

    a {
      color: white;
    }
  }
}

.genretag-item {
  //display: flex;
  list-style-type: none;
  background: #25272e;
  // justify-content: space-between;
  border-radius: 5px;
  padding: 2px 6px;

  .tag-item {
    display: inline-block;
    /*color: #9ac2ff;*/
    color: #7685b3;
    padding: 3px 10px;
    // border-right: 1px solid white;
    /*border: 1px solid;
    border-radius: 50px;*/
    border-radius: 0px;
    // padding: 3px 10px;
    background: #2a3043;
    margin: 0.3rem 0.2rem;
    cursor: pointer;
  }

  .subgenre-list {
    padding: 3px 10px;
    border-right: 1px solid white;

    a {
      color: white;
    }
  }
}

.pagination-lg .page-link {
  padding: 5px 10px;
  font-size: 13px;
  line-height: 20px;

  &:hover {
    color: $white !important;
  }
}

.pagination {
  padding-top: 12px !important;

  .transparent {
    background-color: transparent !important;
  }

  .goto-text {
    width: 60px !important;
    height: 30px !important;
    padding: 1px 4px;
    background-color: #2e2e2e;
    color: #b2b2b2;
    border: 0.1rem solid #2e2e2e;
    border-radius: 0.2rem;
  }
}

.zd-revert-classic {
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;

  &-message {
    padding-right: 20px;
    display: flex;
    // justify-content: center;
    align-items: center;

    &:hover {
      text-decoration: none;
      color: white !important;
    }
  }
}

.zd-processing {
  opacity: 0.7;
}

.genre-separator:after {
  content: '';
  display: block;
  margin: 0;
  width: 100%;
  padding-top: 20px;
  border-bottom: 1px solid #373737;
}

.genre-view-name {
  color: #c8c8c8;
  // font-weight: bold;
  font-size: 17px !important ;
}

.zd-genre-view-genre {
  width: 95% !important;
}
.zd-genre-view-genre-child {
  width: 75% !important;
}
.zd-all-genres {
  margin-left: 5px !important;
  &:first-child {
    margin-left: 0px !important;
  }
}

.zd-genre-view-title {
  width: 65% !important;
}
.zd-release-view-title {
  // padding: 0 10px !important;
}

.zd-playing-row tbody tr {
  background-color: $zd_playing_row !important;
}

.zd-genre-heading {
  color: #7483b0 !important;
}

.search-genre {
  z-index: 999;
  @media (max-width: 1224px) {
    margin-left: 30%;
  }
}
.zd-revert-classic-message {
  color: #7685b3 !important;
}
.zd-revert-classic-message:hover,
.zd-genre-heading:hover {
  color: #acbdf3 !important;
}

.zd-clear-btn {
  background-color: #2a3043;
}

.zd-white-font {
  color: #eaeaea;
}

@import 'icons/_icomoon_icon.scss';
@import 'icons/_zd_icon.scss';
@import 'icons/_zd_player_icon.scss';
@import 'components/_zd_dropdowns.scss';
@import 'components/_zd_table.scss';
@import 'components/_zd_media_player.scss';
@import 'components/_zd_modal.scss';
@import 'components/_zd_download_manager_sidebar.scss';
@import 'components/genres.scss';
@import 'components/packset.scss';
@import 'components/_zd_profile.scss';
@import 'components/_zd_labels.scss';
@import 'components/_zd_wave_loader.scss';
@import 'components/_zd_cards.scss';
@import '_zd_common.scss';
@import '_zipdj_custom.scss';
@import '_zipdj_responsive.scss';
