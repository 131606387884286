.zd-top-track-list {
  max-height: calc(100% - 0px);
}

.chart-list {
  background: #222327;
  height: 100%;
  box-shadow: 1.5px 2.6px 11.52px 4.48px rgba(0, 0, 0, 0.039);
  .chart-list-block {
    padding: 1rem;
  }
}

.inline-block {
  display: inline-block;
}
.btn.btn-link {
  background: 0 0;
  border-color: transparent;
}
.header-list button {
  padding: 0;
  i {
    font-size: 16px;
    color: $white;
    &:hover {
      color: #3b49af;
    }
  }
  &:hover {
    text-decoration: none;
  }
}

.chart-list {
  [class*=' icon-'],
  [class^='icon-'] {
    font-family: icomoon !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .icon-pagination-right:before {
    content: '\e930';
  }
  .icon-playlist:before {
    content: '\e922';
  }
}

.zd-top-track-list .chart-list .chart-list-block.dj-chart-list-wrap {
  max-height: calc(100% - 78px);
  overflow: hidden;
  padding: 0;
}
.zd-top-track-list .chart-list .chart-list-block {
  padding: 10px;
  color: #9b9b9c;
}
.zd-top-track-list .chart-list .chart-list-block .dj-chart-list {
  margin: 0;
  counter-reset: b;
  padding: 0;
}
.flex {
  display: -webkit-box;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
}
.zd-top-track-list {
  .chart-list {
    .chart-list-block {
      .dj-chart-list {
        .dj-chart-list-item {
          border-bottom: 1px solid rgba(204, 204, 204, 0.07);
          margin: 0;
          padding: 1rem;
          position: relative;
          counter-increment: b;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          cursor: pointer;
          padding-bottom: 1rem;
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 2px;
            height: 100%;
            background: #3b59b0;
            opacity: 0;
          }
          &:hover {
            background: #2c303b;
            &:before {
              opacity: 1;
            }
          }
          .left {
            width: 40px;
            position: relative;
            &:before {
              content: counter(b);
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              margin: auto;
              width: 30px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              font-size: 24px;
              text-indent: -1rem;
              opacity: 1;
              visibility: visible;
              transition: all 0.2s ease-in;
            }
          }
        }

        .dj-chart-list-item {
          .btn {
            opacity: 0;
            visibility: hidden;
            -webkit-transform: scale(0);
            transform: scale(0);
            transition: all 0.2s ease-in;
            padding-left: 3px;
            padding-right: 3px;
            width: 30px;
            &.icon-playlist {
              bottom: 0;
              position: absolute;
              left: 0;
            }
          }

          &:hover,
          &.dj-selected {
            .left {
              &:before {
                opacity: 0;
              }
            }
            .btn {
              -webkit-transform: scale(1);
              transform: scale(1);
              opacity: 1;
              visibility: visible;
            }
            &:hover {
              color: $white;
            }
          }
          .center {
            width: calc(100% - 78px);
          }
          div {
            position: relative;
          }
          .album-name,
          .album-title {
            display: block;
            width: 100%;
          }
          .album-name {
            margin-top: 6px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .label-extra {
            display: block;
            margin-top: 5px;
          }
          .right {
            width: 30px;
            text-align: right;
            .btn {
              text-decoration: none;
              top: 40%;
              position: absolute;
              width: auto;
              padding: 0;
              right: 0;
              color: map-get($map: $theme-colors, $key: primary);
            }
          }
        }
      }
    }
  }
}

.chart-list-content {
  overflow: hidden;
  .album-details {
    color: #919191;
    vertical-align: middle;
    vertical-align: top;
    border: none;
    tr {
      border: none !important;
    }
    .table-cell {
      padding: 10px 5px;
      border-collapse: collapse;
      border: none !important;
    }
  }
  .album-table > tbody > tr:not(.table-head) {
    border-bottom: 20px solid #1c1e22; //#1a1a1c;
    border-bottom: 1px solid #1c1e22; //#1a1a1c;
  }
  .track-info-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 5px 0;
    .track-info-img {
      width: 200px;
      img {
        width: 100%;
      }
    }
    .track-info-desc {
      width: calc(100% - 200px);
      padding: 5px 0 5px 15px;
    }
  }
  .album-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
    color: $white;
    font-weight: 700;
    cursor: pointer;
    &:hover {
      color: map-get($map: $theme-colors, $key: primary);
    }
  }
  .album-description {
    white-space: normal;
    margin-top: 8px;
    line-height: normal;
  }
}

.chart-list-content {
  .card-wrap {
    .card-block {
      width: 20%;
      padding: 10px 5px;
    }
    .card {
      background: #222327;
      -webkit-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
      -webkit-transition: -webkit-box-shadow 0.5s ease;
      transition: -webkit-box-shadow 0.5s ease;
      transition: box-shadow 0.5s ease;
      transition: box-shadow 0.5s ease, -webkit-box-shadow 0.5s ease;
    }
    .card-header {
      background: #222327;
    }
    .card-image {
      position: relative;
      overflow: hidden;
      // padding: 100% 0 0;
      background-size: 100%;
      background-repeat: no-repeat;
      cursor: pointer;
      .card-image-style {
        width: 100%;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .card-header {
      padding: 1rem 0.6rem;
      .album-title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: left;
        color: $white;
        font-weight: 700;
        cursor: pointer;
        margin-bottom: 0;
        a {
          color: $white;
          &:hover {
            color: map-get($map: $theme-colors, $key: primary);
            text-decoration: none;
          }
        }
      }
      .album-name {
        margin-top: 6px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #b2b2b2;
        text-transform: capitalize;
      }
    }
  }
}

body .zd-label-search-header { //label search
  padding: 0 0px 24px;
  background: transparent !important;
  .sk-search-box{

    &:hover{
      .cr-search-form__input::placeholder {
        color: #ddd;
      }
      .cr-search-form__icon-search{
        color: #ddd !important;
      }
  
    }
    
  }

  .cr-search-form__input {
    width: 240px;
    // width: 293px;
    background: #2d2e33;
    box-shadow: none;
    border: 0;
    color: #ddd;
    border-radius: 0px !important;
    &::placeholder {
      color: #6b7883;
    }
    .cr-search-form__icon-search {
      color: #6b7883 !important;
    }
    &:hover {
      &::placeholder {
        color: #dddd;
      }
    }
    &:focus {
      &::placeholder {
        color: #dddd;
      }
    }
  }
  .cr-search-form__icon-search{
    color: #6b7883 !important;
  }
}

body .zd-new-indicator {
  color: #4d7ce8;
  padding-left: 10px;
}

@media only screen and (min-width: 0px) and (max-width: 414px) {
  .chart-list-content {
    .card-wrap {
      .card-block {
        width: 40%;
        padding: 10px 5px;
      }
    }
  }
}

@media only screen and (min-width: 415px) and (max-width: 766px) {
  .chart-list-content {
    .card-wrap {
      .card-block {
        width: 33.3333%;
        padding: 10px 5px;
      }
    }
  }
}
