.alltag {
  /* position: absolute; */
  top: 100%;
  width: 100%;
  z-index: 20;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 3px 20px 20px;
}

@media screen and (min-width: 1195px) {
  .alltag {
    padding: 20px 20px 20px 24px;
    width: calc(100vw - 100px);
  }
  .alltag__container {
    padding: 4px 0;
  }
  .alltag__container {
    /* height: 220px; */
    padding-top: 5px;
    width: 26%;
  }
  .tag-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-height: calc(100vh - 100px);

    flex-direction: column;
  }
}

@media screen and (min-width: 1440px) {
  .tag-container {
    max-height: calc(100vh - 100px);
  }
}
@media screen and (min-width: 1740px) {
  .tag-container {
    max-height: calc(100vh - 400px);
  }
}

.alltag__heading {
  display: inline;
  padding: 4px 4px;
  font-size: 14px;
  color: #4f7be9;
  font-family: 'Poppins', 'Roboto', sans-serif;
  font-weight: 600;
}
.alltag__tag {
  padding: 3px;
  color: #ddd !important;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
  border: 1px solid #40434c;

  background: #2c2e35;
}
.alltag__tag span {
  color: #ddd !important;
}

.alltag__tag:hover {
  border-color: #4f7be9;
}

.alltag__tag p {
  white-space: nowrap;
  padding: 6px 8px 6px 0px !important;
  font-size: 12px;
  display: inline;
  font-weight: 300;
  /* background: #242529; */
  letter-spacing: 1%;
  font-family: 'Poppins', 'Roboto', sans-serif;
}
.alltag__tag p span span {
  padding-left: 6px;
}
.alltag__tag p:hover {
  color: #4f7be9;
  /* border-color: #4f7be9; */
  /* border: 1px solid #4f7be9; */
}
.septags {
  margin-top: 6px;
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 750px) {
  .alltag {
    background: #1c1e22;
    display: none;
  }
  .tag-container {
    height: auto;
    flex-wrap: nowrap;
  }
  .showTag .alltag {
    display: block;
  }
  .showTag .alltag .tag-container {
    height: calc(100vh - 150px);
    overflow-y: scroll;
  }
  .showTag .alltag .tag-container::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  .alltag_sm {
    color: white;
    position: fixed;
    right: 5px;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: calc(100vh - 177px);
  }
  .alltag_az_sm {
    color: rgb(214, 214, 236);
    opacity: 0.5;
    font-size: 12px;
    padding: 2px 0;
  }
  .alltag_az_sm.active {
    color: rgb(59, 150, 235);
    opacity: 1;
  }
  .alltag_az_sm.active p:hover,
  .alltag_az_sm.active p:focus {
    color: rgb(15, 139, 255);
    font-weight: bold;
  }
  .septags {
    /* flex-direction: column; */
  }
  .alltag__tag {
    margin-bottom: 8px;
  }
  .alltag__tag p {
    padding: 6px 8px;
  }
  .alltag__container {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 2000px) and (min-width: 1560px) {
  .alltag__heading {
    font-size: 14px;
  }
}
