.zd-search-genre-btn{
    .nav-item{
        width:118px !important;
    }
}

.zdv3-icon-profile {
    font-size: 24px !important;
}

// .zd-header-profile-icon{
//     font-size: 24px !important;
// }
// .genrecard__img{
//     width: 394px;
//     height: 158px;
// }

.zd-sm-header-profile {
    display: none;
    @media (max-width: 750px) {
      display: block;
      // height: 104px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  
      i {
        font-size: 16px;
        color: #ABB1B8;
        // margin-left: 8px;
        // margin-right: 8px;
      }
    
    .zd-sm-sidebar-profile-img img {
      width: 32px;
      height: 32px;
      border-radius: 50px;
    }   
}

.genre{
    display: none;
    @media (max-width: 750px) { 
      
      display: block !important;
      background: #222327 !important;
      margin-top: -1px !important;
    }
 }

 .zd-genre_menu{
  align-items: center;
  background-color: #32333A;
  height: 32px;
  padding: 0px 12px 0px 8px;
  padding-left: 8px !important;
  cursor: pointer;
  margin-right: 16px;
  border: 1px solid #32333A;
 }