.zd-genre-list-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    overflow: visible;
    padding: 0;
    .zd-genre-list {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin-top: 0;
        min-width: 165px;
    }
    .zd-genre-first-col {
        width: 75%;
        flex-wrap: wrap;
        @media (max-width:692px) {
          width: 100%;
        }
    }
    .zd-genre-last-col {
        width: 25%;
        flex-wrap: wrap;
        @media (max-width:692px) {
          width: 100%;
        }
    }
    .zd-genre-block {
        width: 33.333%;
        padding: 1.5%;
        border-radius: 0;
        border: none;
        background-color: transparent;
    }
    .zd-genre-inner {
        // border-radius: 10px;
        background: #222326;
        overflow: hidden;
        height: 100%;
        flex: unset;
        padding: 0;
        .title {
            background: #252933;
            color: #7099f8;
            text-align: center;
            padding: 15px;
            text-transform: uppercase;
            display: block;
            margin-bottom: 0;
            cursor: pointer;
            &:hover,
            &:focus {
                background: #2c303b;
                text-decoration: none;
            }
            &.btn {
                background: #4360A3;
                color: #FFF;
                border: none;
                &.btn:focus,
                &.btn:hover {
                    background: #4d7ce8;
                    border: none;
                }
            }
        }
        .list {
            padding: 9px;
            text-align: center;
            min-height: 320px;
            a {
                display: table;
                color: $white;
                margin: 15px auto;
                transition: .3s ease;
                padding: 0;
                &:first-child {
                    margin-top: 10px;
                }
                &:hover {
                    color: #6c93ef;
                    text-decoration: none;
                }
            }

        }
    }
    .zd-genre-last-col {
        .zd-genre-block {
            width: 100%;
            padding: 16px 10px;
        }
        .title {
          border-radius: 0px !important;
        }
    }

}

.zd-genre-card{
  padding-bottom: 0px !important;
}

/* Genre Home */
.zd-genres-list {
  .orange-gradient {
    background: $orange-gr;
    &:hover {
        background: #c9612e;
    }
  }
  .pink-gradient {
    background: $pink-gr;
    &:hover {
        background: #ad4670;
    }
  }
  .grey-blue-gradient {
    background: $grey-blue-gr;
    &:hover {
        background: #5f8fc8;
    }
  }
  .baby-pink-gradient {
    background: $baby-pink-gr;
    &:hover {
        background: #d78d73;
    }
  }

  .blue-gradient {
    background: $blue-gr;
    &:hover {
        background: #585a9e;
    }
  }
  .sky-blue-gradient {
    background: $sky-blue-gr;
    &:hover {
        background: #23a1b2;
    }
  }
  .style_box {
    color: $white;
    font-style: normal;
    font-weight: normal;
    border-radius: 5px;
    margin-right: 16px;
    width: 100%;
    max-width: 128px;
    min-height: 60px;
    font-size: 10px;
    line-height: 14px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    a {
        width: 100%;
        max-width: 128px;
        min-height: 60px;
        display: flex;
        padding: 0 10px;
        justify-content: center;
        align-items: center;
    }
  }
  .style_content {
    background: #1f242f;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    width: 100%;
    min-height: 60px;
    min-width: 128px;
    flex: 0 0 128px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    color: $white;
    border-radius: 5px;
    padding: 0 10px;
    margin-right: 6px;
    cursor: pointer;
    word-break: break-all;
    &:hover {
        background: #282e3c;
    }
    a {
        width: 100%;
        max-width: 128px;
        min-height: 60px;
        display: flex;
        flex: 0 0 128px;
        padding: 0 10px;
        justify-content: center;
        align-items: center;
        word-break: break-all;
    }
  }
  .margin_third {
    margin-right: 16px;
  }

  a {
    color: $white;
    font-size: 10px;
  }
  a:hover {
    color: $white !important;
    text-decoration: none;
  }
  .margin_fourth {
    margin-right: 16px;
  }
}
